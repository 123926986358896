import { ApolloClient, DefaultOptions, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import errorLink from './errorLink';
import authLink from './authLink';
import cache from './cache';

// eslint-disable-next-line new-cap
const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_SAT_API_URL}/graphql`,
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
};

const apiClient = new ApolloClient({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  link: from([errorLink, authLink, uploadLink]),
  cache,
  credentials: 'include',
  defaultOptions,
});

export default apiClient;
