import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
  border: 1px solid #d7d6d6;
  border-radius: 8px;
  padding: 12px;

  h3 {
    margin-bottom: 4px;
  }
  .no-info {
    color: var(--grey-scale);
  }

  .question {
    width: 50%;
    word-wrap: break-word;
  }

  .answer {
    margin-left: 4px;
    width: 50%;

    .p-field-radiobutton + .p-field-radiobutton {
      margin-top: 4px;
    }

    .p-field-radiobutton {
      display: flex;
      align-items: center;
      margin-bottom: none !important;

      label {
        margin-left: 4px;
      }
    }
  }
`;
