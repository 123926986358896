/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { saveAs } from 'file-saver';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTable,
  DataTableMultiSortMetaType,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortParams,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import { Container } from './styles';

import { useRefHook } from '../../../hooks/useRefHook';

import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import { gridConstants } from '../../../components/Grid/constants';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import useTitle from '../../../hooks/useTitle';
import ILazyParams from '../../../services/lazyParams';
import ToastLife from '../../../shared/enums/toastLife';
import { clientQuotationRoles } from '../../../shared/roles/clientQuotation';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';
import {
  ClientQuotationStatus,
  IClientQuotation,
} from '../ClientQuotation/interfaces';
import { gridColumnsData } from './constants';

interface IClientQuotationsLazyParams extends ILazyParams {
  [key: string]: number | string | DataTableMultiSortMetaType | undefined;
  idCq?: number;
  cqNumber?: string;
  status?: string;
  mainProduct?: string;
  responsibleUser?: string;
  category?: string;
  clientCompanyName?: string;
  countryOrigin?: string;
  importer?: string;
  exporter?: string;
  sellingIncoterm?: string;
  portOfLoading?: string;
  portOfDischarge?: string;
  createdBy?: string;
}

const pageTitle = 'List of Client Quotations';

const ClientQuotations: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:clientQuotationsGridColumns';

  const lazyParamsName = '@SAT:clientQuotationListLazyParams';

  const advancedFiltersName = '@SAT:clientQuotationListAdvancedFilters';

  // URL params
  const location = useLocation();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia a grid
  const gridRef = useRef<DataTable>(null);

  // Redirect
  const history = useHistory();

  // Colunas da grid
  const columns = useMemo(() => {
    return (
      Object.entries(gridColumnsData)
        // .filter(column => fieldsPermissions[column[1].field].view)
        .map(column => column[1])
    );
  }, []);

  // Armazena os client quotations selecionados
  const [selectedClientQuotations, setSelectedClientQuotations] = useState([]);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Estado de scrollHeight da grid
  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  // ID da entity de SAT
  const { idEntity } = clientQuotationRoles;

  // Permissions de Client Quotation
  const {
    idPermissionCreateClientQuotation,
    idPermissionDeleteClientQuotation,
    idPermissionExportListClientQuotation,
  } = clientQuotationRoles.permissions;

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  const [lazyParams, setLazyParams] = useState<IClientQuotationsLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  const saveLazyParams = useCallback(
    (newLazyParams: IClientQuotationsLazyParams) => {
      localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
      updateLocalStorageInDb(lazyParamsName, newLazyParams);
    },
    [],
  );

  const changeLazyParams = useCallback(
    (newLazyParams: IClientQuotationsLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  // Query para deletar client quotations
  const queryToDeleteClientQuotations = gql`
    mutation DeleteClientQuotationsMutation($clientQuotationIds: [Int]!) {
      deleteClientQuotations(clientQuotationIds: $clientQuotationIds)
    }
  `;
  // cria método para chamar a mutation
  const [deleteClientQuotations] = useMutation(queryToDeleteClientQuotations);

  const queryToListClientQuotations = gql`
    query listAllClientQuotationsQuery(
      $listAllClientQuotationsInput: ListAllClientQuotationsInput!
    ) {
      listAllClientQuotations(
        listAllClientQuotationsInput: $listAllClientQuotationsInput
      ) {
        data {
          idCq
          cqNumber
          idStatus
          idStatus2 {
            description
          }
          mainProduct
          idCountryOrigin
          idCountryOrigin2 {
            name
          }
          idResponsibleUser
          idResponsibleUser2 {
            firstName
          }
          idCategory
          idCategory2 {
            name
          }
          clientCompanyName
          idImporter
          idImporter2 {
            name
          }
          idExporter
          idExporter2 {
            name
          }
          idSellingIncoterm
          idSellingIncoterm2 {
            description
          }
          idPortOfLoading
          idPortOfLoading2 {
            name
          }
          idPortOfDischarge
          idPortOfDischarge2 {
            name
          }
          createdBy
          createdBy2 {
            firstName
          }
        }
        items
      }
    }
  `;

  const {
    loading: clientQuotationsLoading,
    data: clientQuotationsData,
    error: clientQuotationsError,
    refetch: clientQuotationsRefetch,
  } = useQuery(queryToListClientQuotations, {
    variables: {
      listAllClientQuotationsInput: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        globalSearch: lazyParams.globalFilter,
        cqNumber: lazyParams.cqNumber,
        status: lazyParams.status,
        mainProduct: lazyParams.mainProduct,
        countryOrigin: lazyParams.countryOrigin,
        responsibleUser: lazyParams.responsibleUser,
        category: lazyParams.category,
        clientCompanyName: lazyParams.clientCompanyName,
        importer: lazyParams.importer,
        exporter: lazyParams.exporter,
        sellingIncoterm: lazyParams.sellingIncoterm,
        portOfLoading: lazyParams.portOfLoading,
        portOfDischarge: lazyParams.portOfDischarge,
        createdBy: lazyParams.createdBy,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting client quotations',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedClientQuotations.length} client quotations?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada client
        const idsToDelete = selectedClientQuotations.map(
          (clientQuotation: IClientQuotation) => clientQuotation.idCq,
        );
        try {
          // Delete client quotations
          await deleteClientQuotations({
            variables: {
              clientQuotationIds: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedClientQuotations.length} client quotations`,
            life: ToastLife.SUCCESS,
          });

          // Zera estado de client quotations selecionadas
          setSelectedClientQuotations([]);

          // Atualiza grid de client quotations
          await clientQuotationsRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting client quotations',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;

        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Define as colunas no estado
   */
  useEffect(() => {
    // Busca colunas selecionadas salvas no local storage
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    }
  }, []);

  /**
   * Direciona usuario para pagina do client quotation clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(`/client-quotations/list/${e.data.idCq}`);
  }

  // Ao mudar pagina da tabela, muda os parametros de busca no backend
  // @param event Parametros de paginacao da tabela
  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  // Ao fazer sort de alguma coluna, muda os parametros de busca no backend
  // @param event Parametros de sort da tabela
  function onSort(event: DataTableSortParams) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  const parseStatusColumn = (rowData: any) => {
    return (
      <div className={`status ${ClientQuotationStatus[rowData.idStatus]}`}>
        {rowData.idStatus2.description}
      </div>
    );
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'idStatus2.description':
        return parseStatusColumn;
      default:
        return undefined;
    }
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'mainProduct':
      case 'idPortOfLoading2.name':
      case 'idPortOfDischarge2.name':
        return { width: '210px' };
      case 'idImporter2.name':
        return { width: '190px' };
      case 'idSellingIncoterm2.description':
      case 'idCountryOrigin2.name':
        return { width: '160px' };
      case 'idStatus2.description':
        return { width: '154px', display: 'grid' };
      case 'clientCompanyName':
        return { width: '140px' };
      case 'idResponsibleUser2.firstName':
        return { width: '134px' };
      case 'cqNumber':
      case 'createdBy2.firstName':
        return { width: '130px' };
      case 'idExporter2.name':
      case 'idCategory2.name':
      default:
        return { width: '115px' };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map((col: any) => {
    return (
      <Column
        key={col.field}
        // Valida o body da coluna (caso precise ser um link)
        body={handleColumn(col.field)}
        columnKey={col.field}
        field={col.field}
        // Valida necessidade de icone de filtro no header
        header={handleColumnHeader(col.header)}
        style={handleColumnSize(col.field)}
        sortable={col.field !== 'imageUrl'}
      />
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeParams) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );
    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  /**
   * Salva arquivo como xlsx
   * @param buffer
   * @param fileName Nome do arquivo
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`,
    );
  }

  /**
   * Exporta dados para xlsx
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function exportToXlsx(clientQuotationsExportData: any) {
    // Busca colunas que foram ocultadas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultadas
    clientQuotationsExportData.listAllClientQuotations.data.forEach(
      (clientQuotation: any) => {
        clientQuotation.status = clientQuotation.idStatus2
          ? clientQuotation.idStatus2.description
          : undefined;
        clientQuotation.currentUser = clientQuotation.idResponsibleUser2
          ? clientQuotation.idResponsibleUser2.firstName
          : undefined;
        clientQuotation.countryOrigin = clientQuotation.idCountryOrigin2
          ? clientQuotation.idCountryOrigin2.name
          : undefined;
        clientQuotation.category = clientQuotation.idCategory2
          ? clientQuotation.idCategory2.name
          : undefined;
        clientQuotation.importer = clientQuotation.idImporter2
          ? clientQuotation.idImporter2.name
          : undefined;
        clientQuotation.exporter = clientQuotation.idExporter2
          ? clientQuotation.idExporter2.name
          : undefined;
        clientQuotation.sellingIncoterm = clientQuotation.idSellingIncoterm2
          ? clientQuotation.idSellingIncoterm2.description
          : undefined;
        clientQuotation.portOfLoading = clientQuotation.idPortOfLoading2
          ? clientQuotation.idPortOfLoading2.name
          : undefined;
        clientQuotation.portOfDischarge = clientQuotation.idPortOfDischarge2
          ? clientQuotation.idPortOfDischarge2.name
          : undefined;
        clientQuotation.createdBy = clientQuotation.createdBy2
          ? clientQuotation.createdBy2.firstName
          : undefined;

        columnsToRemove.forEach(column => {
          switch (column.field) {
            case 'idStatus2.description':
              delete clientQuotation.status;
              break;
            case 'idResponsibleUser2.firstName':
              delete clientQuotation.currentUser;
              break;
            case 'idCountryOrigin2.name':
              delete clientQuotation.countryOrigin;
              break;
            case 'idCategory2.name':
              delete clientQuotation.category;
              break;
            case 'idImporter2.name':
              delete clientQuotation.importer;
              break;
            case 'idExporter2.name':
              delete clientQuotation.exporter;
              break;
            case 'idSellingIncoterm2.description':
              delete clientQuotation.sellingIncoterm;
              break;
            case 'idPortOfLoading2.name':
              delete clientQuotation.portOfLoading;
              break;
            case 'idPortOfDischarge2.name':
              delete clientQuotation.portOfDischarge;
              break;
            case 'createdBy2.firstName':
              delete clientQuotation.createdBy;
              break;
            default:
              delete clientQuotation[column.field];
              break;
          }
        });

        delete clientQuotation.idCq;
        delete clientQuotation.idStatus;
        delete clientQuotation.idStatus2;
        delete clientQuotation.idResponsibleUser;
        delete clientQuotation.idResponsibleUser2;
        delete clientQuotation.idCategory;
        delete clientQuotation.idCategory2;
        delete clientQuotation.idImporter;
        delete clientQuotation.idImporter2;
        delete clientQuotation.idExporter;
        delete clientQuotation.idExporter2;
        delete clientQuotation.idCountryOrigin;
        delete clientQuotation.idCountryOrigin2;
        delete clientQuotation.idSellingIncoterm;
        delete clientQuotation.idSellingIncoterm2;
        delete clientQuotation.idPortOfLoading;
        delete clientQuotation.idPortOfLoading2;
        delete clientQuotation.idPortOfDischarge;
        delete clientQuotation.idPortOfDischarge2;
        delete clientQuotation.createdBy2;
      },
    );

    const gridColumns =
      gridRef.current?.state.columnOrder ??
      selectedColumns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: any) =>
        item !== null &&
        item !== 'idClientQuotation' &&
        item !== columnsToRemove.find(a => a.field === item)?.field,
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'idStatus2.description':
          columnsOrder[index] = 'status';
          break;
        case 'idCountryOrigin2.name':
          columnsOrder[index] = 'countryOrigin';
          break;
        case 'idResponsibleUser2.firstName':
          columnsOrder[index] = 'currentUser';
          break;
        case 'idCategory2.name':
          columnsOrder[index] = 'category';
          break;
        case 'idImporter2.name':
          columnsOrder[index] = 'importer';
          break;
        case 'idExporter2.name':
          columnsOrder[index] = 'exporter';
          break;
        case 'idSellingIncoterm2.description':
          columnsOrder[index] = 'sellingIncoterm';
          break;
        case 'idPortOfLoading2.name':
          columnsOrder[index] = 'portOfLoading';
          break;
        case 'idPortOfDischarge2.name':
          columnsOrder[index] = 'portOfDischarge';
          break;
        case 'createdBy2.firstName':
          columnsOrder[index] = 'createdBy';
          break;
        default:
          break;
      }
    });

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(
      clientQuotationsExportData.listAllClientQuotations.data,
      { header: columnsOrder },
    );
    worksheet['!autofilter'] = { ref: 'A1:H1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveAsExcelFile(excelBuffer, 'client-quotations');
  }

  /**
   * Busca client quotations para exportar para XLSX
   */
  const [
    loadClientQuotationsToExport,
    {
      loading: clientQuotationsToExportLoading,
      data: clientQuotationsToExportData,
    },
  ] = useLazyQuery(queryToListClientQuotations, {
    onCompleted: () => {
      exportToXlsx(clientQuotationsToExportData);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while exporting client quotations',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <Container>
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {userHasPermission(
          idPermissionCreateClientQuotation,
          userPermissions.userPermissions,
        ) && (
          <MainButton
            className="mainButton"
            label="New Client Quotation"
            onClick={() => history.push('/client-quotations/list/create')}
          />
        )}

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />

        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {userHasPermission(
          idPermissionDeleteClientQuotation,
          userPermissions.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedClientQuotations.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {userHasPermission(
          idPermissionExportListClientQuotation,
          userPermissions.userPermissions,
        ) && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={clientQuotationsToExportLoading}
            onClick={() => {
              loadClientQuotationsToExport({
                variables: {
                  listAllClientQuotationsInput: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    globalSearch: lazyParams.globalFilter,
                    idCq: lazyParams.idCq,
                    cqNumber: lazyParams.cqNumber,
                    status: lazyParams.status,
                    mainProduct: lazyParams.mainProduct,
                    responsibleUser: lazyParams.responsibleUser,
                    category: lazyParams.category,
                    clientCompanyName: lazyParams.clientCompanyName,
                    importer: lazyParams.importer,
                    exporter: lazyParams.exporter,
                    sellingIncoterm: lazyParams.sellingIncoterm,
                    portOfLoading: lazyParams.portOfLoading,
                    portOfDischarge: lazyParams.portOfDischarge,
                    createdBy: lazyParams.createdBy,
                  },
                },
              });
            }}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a client quotation"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        className="grid"
        name="clientQuotations"
        lazy
        totalRecords={
          !clientQuotationsData || clientQuotationsError
            ? 0
            : clientQuotationsData?.listAllClientQuotations?.items
        }
        value={
          !clientQuotationsData || clientQuotationsError
            ? undefined
            : clientQuotationsData?.listAllClientQuotations?.data
        }
        globalFilter={globalFilter}
        emptyMessage="No client quotation found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={
          !clientQuotationsData || clientQuotationsError
            ? undefined
            : lazyParams.first
        }
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedClientQuotations}
        onSelectionChange={e => setSelectedClientQuotations(e.value)}
      >
        <Column
          columnKey="multiple"
          selectionMode="multiple"
          style={{ width: '3em' }}
          reorderable={false}
        />
        {dynamicColumns}
      </Grid>
      {(pageLoading || clientQuotationsLoading) && <Loading />}
    </Container>
  );
};

export default ClientQuotations;
