import { useLazyQuery } from '@apollo/client';
import React, {
  Dispatch,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';
import { listOtherExpensesTotal } from './queries';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import {
  ForeignTradeSupplierContentReducerAction,
  ForeignTradeSupplierContentReducerActionKind,
  SellerOtherExpensesTotalReducerObject,
} from '../interfaces';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import {
  SatFinancialSellerOtherExpensesTotalFieldsPermissions,
  SatForeignTradeSupplier,
  UserCanObject,
} from '../../interfaces';
import Button from '../../../../../../components/Button';
import { ISatFinancialApportionmentRef } from '../../../FinancialApportionment';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';

export interface ISellerOtherExpensesTotalRef {
  refetch(): void;
}

interface ISellerOtherExpensesTotalProps {
  satForeignTradeSupplier: SatForeignTradeSupplier;
  foreignTradeSupplierContentDispatch: Dispatch<ForeignTradeSupplierContentReducerAction>;
  selected: boolean;
  ref: Ref<ISellerOtherExpensesTotalRef>;
  sellerOtherExpensesTotal?: SellerOtherExpensesTotalReducerObject;
  fieldsPermissions: SatFinancialSellerOtherExpensesTotalFieldsPermissions;
  userCanObjects: UserCanObject;
  apportionmentRef: RefObject<ISatFinancialApportionmentRef>;
  satCurrency?: string;
  loadSellerTotalReceivedData(): void;
}

const SellerOtherExpensesTotal: React.FC<ISellerOtherExpensesTotalProps> =
  React.forwardRef(
    (
      {
        satForeignTradeSupplier,
        foreignTradeSupplierContentDispatch,
        selected,
        sellerOtherExpensesTotal,
        fieldsPermissions,
        userCanObjects,
        apportionmentRef,
        satCurrency,
        loadSellerTotalReceivedData,
      },
      ref,
    ) => {
      const { toastRef } = useRefHook();

      const satCurrencySymbol = getCurrencySymbol(satCurrency);

      const [
        loadData,
        { loading: otherExpensesTotalLoading, data: otherExpensesTotalData },
      ] = useLazyQuery(listOtherExpensesTotal, {
        variables: {
          idSatForeignTradeSupplier:
            satForeignTradeSupplier.idSatForeignTradeSupplier,
          typeIdDomainGroup: DomainGroup.FINANCIAL_SELLER_OTHER_EXPENSES,
        },
        onCompleted: response => {
          if (response.getSumDataFromSatForeignTradeSupplierOtherExpenses) {
            foreignTradeSupplierContentDispatch({
              type: ForeignTradeSupplierContentReducerActionKind.SET_SELLER_OTHER_EXPENSES_TOTAL,
              payload: {
                sellerOtherExpensesTotal:
                  response.getSumDataFromSatForeignTradeSupplierOtherExpenses,
                satForeignTradeSupplier,
              },
            });
          }
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting Seller Other Expenses Total',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });

          foreignTradeSupplierContentDispatch({
            type: ForeignTradeSupplierContentReducerActionKind.CHANGE_SELLER_OTHER_EXPENSES_TOTAL_LOADING,
          });
        },
      });

      const loadOtherExpensesTotalData = useCallback(async () => {
        foreignTradeSupplierContentDispatch({
          type: ForeignTradeSupplierContentReducerActionKind.CHANGE_SELLER_OTHER_EXPENSES_TOTAL_LOADING,
        });

        loadData();
      }, [foreignTradeSupplierContentDispatch, loadData]);

      useImperativeHandle(ref, () => ({
        refetch: () => {
          loadOtherExpensesTotalData();
          loadSellerTotalReceivedData();
        },
      }));

      useEffect(() => {
        if (!otherExpensesTotalData && selected) {
          loadOtherExpensesTotalData();
        }
      }, [
        otherExpensesTotalData,
        selected,
        loadOtherExpensesTotalData,
        foreignTradeSupplierContentDispatch,
      ]);

      return userCanObjects.userCanSeeSellerOtherExpensesTotalBlock ? (
        <div>
          <FormTitle name="Other Expenses Total" />

          <div className="p-grid">
            {fieldsPermissions.totalOtherExpenses.view && (
              <ReadOnlyInput
                className="p-col-3"
                label="Total Other Expenses"
                type="number"
                locale="pt-BR"
                prefix={satCurrencySymbol}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={sellerOtherExpensesTotal?.totalOtherExpenses}
                loading={otherExpensesTotalLoading}
              />
            )}

            {fieldsPermissions.totalOnMargin.view && (
              <ReadOnlyInput
                className="p-col-3"
                label="Total On Margin"
                type="number"
                locale="pt-BR"
                prefix={satCurrencySymbol}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={sellerOtherExpensesTotal?.totalOnMargin}
                loading={otherExpensesTotalLoading}
              />
            )}

            {fieldsPermissions.totalOutOfMargin.view && (
              <ReadOnlyInput
                className="p-col-3"
                label="Total Out Of Margin"
                type="number"
                locale="pt-BR"
                prefix={satCurrencySymbol}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={sellerOtherExpensesTotal?.totalOutOfMargin}
                loading={otherExpensesTotalLoading}
              />
            )}
          </div>

          <div className="p-grid p-align-center">
            {fieldsPermissions.totalCreditToClient.view && (
              <ReadOnlyInput
                className="p-col-3"
                label="Total Credit To Client"
                type="number"
                locale="pt-BR"
                prefix={satCurrencySymbol}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={sellerOtherExpensesTotal?.totalCreditToClient}
                loading={otherExpensesTotalLoading}
              />
            )}

            {fieldsPermissions.totalForApportionment.view && (
              <ReadOnlyInput
                className="p-col-3"
                label="Total For Apportionment"
                type="number"
                locale="pt-BR"
                prefix={satCurrencySymbol}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={sellerOtherExpensesTotal?.totalForApportionment}
                loading={otherExpensesTotalLoading}
              />
            )}

            {userCanObjects.userCanAccessApportionment && (
              <Button
                className="p-ml-3"
                label="Apportionment"
                type="button"
                onClick={() =>
                  apportionmentRef.current?.show(
                    satForeignTradeSupplier.idSatForeignTrade,
                  )
                }
              />
            )}
          </div>
        </div>
      ) : (
        <div />
      );
    },
  );

export default SellerOtherExpensesTotal;
