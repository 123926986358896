import { confirmDialog } from 'primereact/confirmdialog';
import { InputText, InputTextProps } from 'primereact/inputtext';
import React, { useRef } from 'react';

import { Container } from './styles';

interface IGridInputProps extends InputTextProps {
  /**
   * Exibe botao para replicar informacoes para outros inputs
   */
  showReplicateButton?: boolean;

  /**
   * Parametro de referencia do input
   */
  ref?: any;

  /**
   * Nome da grid que utilizara o input
   */
  gridName: string;

  /**
   * Field da coluna onde o input esta localizado
   */
  field: string;

  onReplicate(value: string, field: string): void;
}

const GridInput: React.FC<IGridInputProps> = ({
  showReplicateButton,
  gridName,
  field,
  onReplicate,
  ...rest
}) => {
  // Para evitar bugs ao replicar informacoes para inputs da mesma classe,
  // combina o nome da grid com a classe especificada
  const componentClassName = `s-grid-input ${gridName}-${field}`;

  const ref = useRef<HTMLInputElement>(null);

  const duplicateItems = () => {
    const inputs = document.getElementsByClassName(
      componentClassName,
    ) as HTMLCollectionOf<HTMLInputElement>;
    for (let i = 0; i < inputs.length; i += 1) {
      inputs[i].value = ref.current?.value ?? '';
      // Dispara evento onInput
      inputs[i].dispatchEvent(new Event('input', { bubbles: true }));
    }
    onReplicate(field, ref.current?.value ?? '');
  };

  const handleDuplicateItems = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => {
    e.stopPropagation();
    confirmDialog({
      message:
        'Do you want to replicate this information to the entire column?',
      header: 'Replicate value',
      icon: 'pi pi-question-circle',
      // accept: () => onReplicate(field, ref.current?.value ?? ''),
      accept: () => duplicateItems(),
    });
  };

  return (
    <Container className="p-input-icon-right">
      {showReplicateButton && (
        <i
          className="pi pi-clone"
          role="button"
          aria-label="Replicate for all entries"
          onKeyDown={e => handleDuplicateItems(e)}
          tabIndex={0}
          onClick={e => handleDuplicateItems(e)}
        />
      )}
      <InputText ref={ref} className={componentClassName} {...rest} />
    </Container>
  );
};

export default GridInput;
