import { useLazyQuery, useMutation } from '@apollo/client';
import { Checkbox } from 'primereact/checkbox';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Tooltip } from 'primereact/tooltip';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import Button from '../../../../../../components/Button';
import FormTitle from '../../../../../../components/FormTitle';
import Grid from '../../../../../../components/Grid';
import Input from '../../../../../../components/Input';
import MainButton from '../../../../../../components/MainButton';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { IDomain } from '../../../../../../interfaces/IDomain';
import { RncItemType } from '../../../../../../shared/enums/rncItemType';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { satRncItemsRoles } from '../../../../../../shared/roles/sat';
import getFieldPermission from '../../../../../../utils/getFieldPermission';
import { IUserFieldsAndPermissionsResponse } from '../../../../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../../../../utils/userHasPermission';
import { ISat, ISatRnc } from '../../../interfaces';
import { RncItemsFieldsPermissions } from '../../interfaces';
import AddRncItemModal from './AddRncItemModal';
import { SatRncItem } from './interfaces';
import {
  deleteSatRncItemsQuery,
  listDomainsByGroupIdQuery,
  listSatRncItemsBySatRncIdQuery,
} from './queries';
import { Container, GridActions } from './styles';
import { getAllRowsAsTrue } from '../../../../../../components/Grid/utils';

interface IRncItemsProps {
  sat: ISat;
  satRnc: ISatRnc;
  selected: boolean;
  isReadOnly: boolean;
  userPermissions: IUserFieldsAndPermissionsResponse;
  setItemsToUpdate: React.Dispatch<React.SetStateAction<SatRncItem[]>>;
}

type RncItemTotals = {
  quantity: number | string | undefined;
  totalFob: number | string | undefined;
  quantityContainer: number | string | undefined;
  nationalizedTotal: number | string | undefined;
  quantityComplained: number | string | undefined;
};

const RncItems: React.FC<IRncItemsProps> = ({
  sat,
  satRnc,
  selected,
  isReadOnly,
  userPermissions,
  setItemsToUpdate,
}) => {
  const { toastRef } = useRefHook();

  /**
   * Chave de identificacao de cada linha da grid
   */
  const gridDataKey = 'idSatRncItem';

  const [rncItems, setRncItems] = useState<SatRncItem[]>();

  const allGridRowsAsTrue = getAllRowsAsTrue(gridDataKey, rncItems);

  const [selectedItems, setSelectedItems] = useState<SatRncItem[]>([]);

  const [totals, setTotals] = useState<RncItemTotals>();

  const [itemsBeingDeleted, setItemsBeingDeleted] = useState<number[]>();

  const [types, setTypes] = useState<IDomain[]>();

  const [debitCategories, setDebitCategories] = useState<IDomain[]>();

  const satCurrency = sat.idCurrency2?.abbreviation ?? '';

  const brazilianCurrency = 'R$';

  const [displayAddItemModal, setDisplayAddItemModal] = useState(false);

  const reloadScreen = () => {
    window.location.href = `/commercial/sats/${sat.idSat}?tab=rnc&rncId=${satRnc.idSatRnc}`;
  };

  const showToast = useCallback(
    (severity: string, summary: string, life: number, detail?: string) => {
      toastRef.current?.show({ summary, detail, severity, life });
    },
    [toastRef],
  );

  const showError = useCallback(
    (summary: string, detail?: string) => {
      showToast('error', summary, ToastLife.ERROR, detail);
    },
    [showToast],
  );

  const showSuccess = useCallback(
    (summary: string, detail?: string) => {
      showToast('success', summary, ToastLife.SUCCESS, detail);
    },
    [showToast],
  );

  const fieldsPermissions: RncItemsFieldsPermissions = useMemo(() => {
    return {
      stCode: getFieldPermission(
        satRncItemsRoles.fields.idFieldStCode,
        userPermissions.userFields,
      ),
      name: getFieldPermission(
        satRncItemsRoles.fields.idFieldName,
        userPermissions.userFields,
      ),
      qtyComplained: getFieldPermission(
        satRncItemsRoles.fields.idFieldQtyComplained,
        userPermissions.userFields,
      ),
      qtyBuyed: getFieldPermission(
        satRncItemsRoles.fields.idFieldQtyBuyed,
        userPermissions.userFields,
      ),
      percentage: getFieldPermission(
        satRncItemsRoles.fields.idFieldPercentage,
        userPermissions.userFields,
      ),
      pcsCtn: getFieldPermission(
        satRncItemsRoles.fields.idFieldPcsCtn,
        userPermissions.userFields,
      ),
      qtyCtn: getFieldPermission(
        satRncItemsRoles.fields.idFieldQtyCtn,
        userPermissions.userFields,
      ),
      fobUnit: getFieldPermission(
        satRncItemsRoles.fields.idFieldFobUnit,
        userPermissions.userFields,
      ),
      fobTotal: getFieldPermission(
        satRncItemsRoles.fields.idFieldFobTotal,
        userPermissions.userFields,
      ),
      nationalizedUnit: getFieldPermission(
        satRncItemsRoles.fields.idFieldNationalizedUnit,
        userPermissions.userFields,
      ),
      nationalizedTotal: getFieldPermission(
        satRncItemsRoles.fields.idFieldNationalizedTotal,
        userPermissions.userFields,
      ),
      clientAware: getFieldPermission(
        satRncItemsRoles.fields.idFieldClientAware,
        userPermissions.userFields,
      ),
      type: getFieldPermission(
        satRncItemsRoles.fields.idFieldType,
        userPermissions.userFields,
      ),
      debitCategory: getFieldPermission(
        satRncItemsRoles.fields.idFieldDebitCategory,
        userPermissions.userFields,
      ),
      notes: getFieldPermission(
        satRncItemsRoles.fields.idFieldNotes,
        userPermissions.userFields,
      ),
    };
  }, [userPermissions.userFields]);

  const permissions = useMemo(() => {
    return {
      addItem: userHasPermission(
        satRncItemsRoles.permissions.idPermissionAddItem,
        userPermissions.userPermissions,
      ),
      removeItem: userHasPermission(
        satRncItemsRoles.permissions.idPermissionRemoveItem,
        userPermissions.userPermissions,
      ),
    };
  }, [userPermissions.userPermissions]);

  const [loadTypes] = useLazyQuery(listDomainsByGroupIdQuery, {
    variables: {
      pagination: {
        _page: 0,
        _limit: 0,
      },
      id: 85,
      isActive: true,
    },
    onCompleted: response => {
      if (response.listDomainsByGroupId.data) {
        setTypes(response.listDomainsByGroupId.data);
      }
    },
    onError: errorData => {
      showError('Error while getting types', errorData.message);
    },
  });

  const [loadDebitCategories] = useLazyQuery(listDomainsByGroupIdQuery, {
    variables: {
      pagination: {
        _page: 0,
        _limit: 0,
        _orderBy: 'description',
      },
      id: 86,
      isActive: true,
    },
    onCompleted: response => {
      if (response.listDomainsByGroupId.data) {
        setDebitCategories(response.listDomainsByGroupId.data);
      }
    },
    onError: errorData => {
      showError('Error while getting debit categories', errorData.message);
    },
  });

  const loadComboBoxes = useCallback(() => {
    loadTypes();
    loadDebitCategories();
  }, [loadDebitCategories, loadTypes]);

  const [
    loadRncItemsData,
    { loading: rncItemsLoading, refetch: refetchRncItems },
  ] = useLazyQuery(listSatRncItemsBySatRncIdQuery(fieldsPermissions), {
    variables: {
      idSatRnc: satRnc.idSatRnc,
    },
    onCompleted: response => {
      if (response.listSatRncItemsBySatRncId) {
        setRncItems(response.listSatRncItemsBySatRncId);
      }
    },
    onError: errorData => {
      showError('Error while getting RNC Items', errorData.message);
    },
  });

  function handleGoToNextField(
    e: React.KeyboardEvent<HTMLInputElement>,
    inputId?: string,
  ) {
    if (e.key === 'Enter' && inputId) {
      document.getElementById(inputId)?.focus();
    }
  }

  const getInputId = useCallback(
    (props: any) => {
      return `${satRnc.rncNumber}-${props.field}-${props.rowIndex}`;
    },
    [satRnc.rncNumber],
  );

  const getNextInputId = useCallback(
    (props: any) => {
      return `${satRnc.rncNumber}-${props.field}-${props.rowIndex + 1}`;
    },
    [satRnc.rncNumber],
  );

  const onEditorValueChange = useCallback(
    (props: any, value: any) => {
      if (rncItems) {
        const newRncItems = [...rncItems];
        const newItem = {
          ...newRncItems[props.rowIndex],
          [props.field]: value,
        };
        newRncItems[props.rowIndex] = newItem;
        setRncItems(newRncItems);
        setItemsToUpdate(state => {
          const oldItems = [...state];
          let oldItem = oldItems?.find(
            item => item.idSatRncItem === newItem.idSatRncItem,
          );
          if (oldItem) {
            oldItem = { ...newItem };
            const updatedItems = oldItems.map(item =>
              item.idSatRncItem &&
              oldItem?.idSatRncItem &&
              item.idSatRncItem === oldItem?.idSatRncItem
                ? oldItem
                : item,
            );
            return updatedItems;
          }
          return [...oldItems, newItem];
        });
      }
    },
    [rncItems, setItemsToUpdate],
  );

  const numberEditor = useCallback(
    (props: any, mode?: string) => {
      return (
        <InputNumber
          inputId={getInputId(props)}
          value={props.rowData[props.field]}
          onChange={e => onEditorValueChange(props, e.value)}
          onKeyDown={e => handleGoToNextField(e, getNextInputId(props))}
          mode={mode}
          currency="BRL"
          locale="pt-BR"
          min={0}
          allowEmpty={false}
        />
      );
    },
    [getInputId, getNextInputId, onEditorValueChange],
  );

  const getQtyComplainedValue = useCallback((rowData: SatRncItem) => {
    const isLeftover = rowData.idType2?.idDomain === RncItemType.SOBRA;
    // Se estiver mudando pra Sobra, coloca negativo, se tirar de sobra, coloca positivo
    if (
      rowData.quantityComplained &&
      ((isLeftover && rowData.quantityComplained > 0) ||
        (!isLeftover && rowData.quantityComplained < 0))
    ) {
      return rowData.quantityComplained * -1;
    }

    return rowData.quantityComplained;
  }, []);

  const qtyComplainedEditor = useCallback(
    props => {
      const rowData = props.props.value[props.rowIndex];
      const isLeftover = rowData.idType2?.idDomain === RncItemType.SOBRA;

      return (
        <InputNumber
          inputId={getInputId(props)}
          value={getQtyComplainedValue(rowData)}
          onValueChange={e => onEditorValueChange(props, e.value)}
          onKeyDown={e => handleGoToNextField(e, getNextInputId(props))}
          locale="pt-BR"
          allowEmpty={false}
          inputStyle={isLeftover ? { color: 'red' } : {}}
        />
      );
    },
    [getInputId, getNextInputId, onEditorValueChange, getQtyComplainedValue],
  );

  const checkboxEditor = (options: ColumnEditorOptions) => {
    const rowData = options.props.value[options.rowIndex];
    return (
      <Checkbox
        className="p-mr-2"
        inputId="restrictInfo"
        onChange={e => onEditorValueChange(options, e.checked)}
        checked={rowData[options.field]}
      />
    );
  };

  const dropdownEditor = (
    options: ColumnEditorOptions,
    dropdownOptions: any,
  ) => {
    const rowData = options.props.value[options.rowIndex];
    return (
      <Dropdown
        className="w-full"
        value={rowData[options.field]}
        options={dropdownOptions}
        optionLabel="description"
        onChange={e => onEditorValueChange(options, e.value)}
      />
    );
  };

  const textEditor = useCallback(
    (props: any) => {
      return (
        <Input
          name={props.field}
          id={getInputId(props)}
          className="p-mr-2"
          onChange={e => onEditorValueChange(props, e.target.value)}
          onKeyDown={e => handleGoToNextField(e, getNextInputId(props))}
          value={props.rowData[props.field] ?? undefined}
        />
      );
    },
    [getInputId, getNextInputId, onEditorValueChange],
  );

  const handleEditor = (options: ColumnEditorOptions, permission?: boolean) => {
    const isDomainField =
      options.field === 'idType2' || options.field === 'idDebitCategory2';
    if ((!permission || isReadOnly) && isDomainField) {
      return options.rowData[options.field]?.description;
    }
    if (!permission || isReadOnly) return options.rowData[options.field];

    switch (options.field) {
      case 'quantityComplained':
        return qtyComplainedEditor(options);
      case 'nationalizedUnit':
        return numberEditor(options, 'currency');
      case 'clientAware':
        return checkboxEditor(options);
      case 'idType2':
        return dropdownEditor(options, types);
      case 'idDebitCategory2':
        return dropdownEditor(options, debitCategories);
      case 'notes':
        return textEditor(options);
      default:
        return options.rowData[options.field];
    }
  };

  const getLocaleString = useCallback(
    (value: number | undefined, prefix?: string, sufix?: string) => {
      if (value) {
        return `${prefix ?? ''} ${value.toLocaleString('pt', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}${sufix ?? ''}`;
      }
      return '';
    },
    [],
  );

  const getNameBody = useCallback((rowData: any) => {
    const { name } = rowData.idSatItem2;
    const abbreviation = name?.substring(0, 20);
    return (
      <div>
        <Tooltip target=".tooltip" />
        <span className="tooltip" data-pr-tooltip={name}>
          {abbreviation}
        </span>
      </div>
    );
  }, []);

  const getComplainedPercentageBody = (options: ColumnEditorOptions) => {
    const rowData = options.props.value[options.rowIndex];
    const { quantityComplained } = rowData;
    const { quantity } = rowData.idSatItem2;
    const percentage = (quantityComplained / quantity) * 100;
    return getLocaleString(percentage, undefined, '%');
  };

  const getQtyCtnValue = useCallback((item: any) => {
    const { quantityComplained } = item;
    const { piecesContainer } = item.idSatItem2;
    const complainedContainers = quantityComplained / piecesContainer;
    return complainedContainers;
  }, []);

  const getQtyCtnBody = (options: ColumnEditorOptions) => {
    const rowData = options.props.value[options.rowIndex];
    return getQtyCtnValue(rowData).toLocaleString('pt', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const getFobUnitBody = useCallback(
    (rowData: any) => {
      const fobUnit = rowData.idSatItem2.sunPrice;
      return getLocaleString(fobUnit, satCurrency);
    },
    [getLocaleString, satCurrency],
  );

  const getTotalFobValue = useCallback((rowData: any) => {
    const { quantityComplained } = rowData;
    const { sunPrice } = rowData.idSatItem2;
    const isLeftover = rowData.idType2?.idDomain === RncItemType.SOBRA;
    const complainedContainers = quantityComplained * sunPrice;
    const total = isLeftover ? complainedContainers * -1 : complainedContainers;
    return total;
  }, []);

  const getTotalFobBody = (options: ColumnEditorOptions) => {
    const rowData = options.props.value[options.rowIndex];
    const isLeftover = rowData.idType2?.idDomain === RncItemType.SOBRA;
    const totalFobValue = getTotalFobValue(rowData);
    return (
      <span className={isLeftover ? 'red' : ''}>
        {getLocaleString(totalFobValue, satCurrency)}
      </span>
    );
  };

  const getNationalizedTotalValue = useCallback((rowData: any) => {
    const { quantityComplained, nationalizedUnit, idType2 } = rowData;
    const isLeftover = idType2?.idDomain === RncItemType.SOBRA;
    const complainedContainers = quantityComplained * nationalizedUnit;
    const total = isLeftover ? complainedContainers * -1 : complainedContainers;
    return total;
  }, []);

  const getNationalizedTotalBody = (options: ColumnEditorOptions) => {
    const rowData = options.props.value[options.rowIndex];
    const isLeftover = rowData.idType2?.idDomain === RncItemType.SOBRA;
    const nationalizedTotalValue = getNationalizedTotalValue(rowData);
    return (
      <span className={isLeftover ? 'red' : ''}>
        {getLocaleString(nationalizedTotalValue, brazilianCurrency)}
      </span>
    );
  };

  const sumArrayOfNumberValues = useCallback(
    (values: (number | undefined)[]) => {
      return values?.reduce(
        (previous, current) => (previous || 0) + (current || 0),
      );
    },
    [],
  );

  // Atualiza os valores totais da grid a cada alteração dos valores da grid
  useEffect(() => {
    if (rncItems && rncItems.length) {
      const qtyComplainedMap = rncItems.map(item =>
        getQtyComplainedValue(item),
      );
      const quantityMap = rncItems.map(item => item.idSatItem2?.quantity);
      const qtyContainerMap = rncItems.map(item => getQtyCtnValue(item));
      const totalFobMap = rncItems.map(item => getTotalFobValue(item));
      const nationalizedTotalMap = rncItems.map(item =>
        getNationalizedTotalValue(item),
      );
      const qtyComplainedSum = sumArrayOfNumberValues(qtyComplainedMap);
      const quantitySum = sumArrayOfNumberValues(quantityMap);
      const qtyContainerSum = sumArrayOfNumberValues(qtyContainerMap);
      const totalFobSum = sumArrayOfNumberValues(totalFobMap);
      const nationalizedTotalSum = sumArrayOfNumberValues(nationalizedTotalMap);

      setTotals({
        quantityComplained: qtyComplainedSum,
        quantity: quantitySum,
        quantityContainer: getLocaleString(qtyContainerSum),
        totalFob: getLocaleString(totalFobSum, satCurrency),
        nationalizedTotal: getLocaleString(
          nationalizedTotalSum,
          brazilianCurrency,
        ),
      });
    }
  }, [
    getLocaleString,
    getNationalizedTotalValue,
    getQtyCtnValue,
    getTotalFobValue,
    rncItems,
    satCurrency,
    sumArrayOfNumberValues,
    getQtyComplainedValue,
  ]);

  const onAddItemClick = useCallback(() => {
    setDisplayAddItemModal(true);
  }, []);

  const [deleteSatRncItemsMutation] = useMutation(deleteSatRncItemsQuery);

  const deleteItems = async (satRncItemIds: number[]) => {
    setItemsBeingDeleted(satRncItemIds);
    try {
      await deleteSatRncItemsMutation({
        variables: {
          satRncItemIds,
        },
      });
      reloadScreen();
      showSuccess('SAT RNC Item(s) deleted');
    } catch (error) {
      showError('Error while deleting SAT RNC Item(s)', error.message);
    } finally {
      setItemsBeingDeleted(undefined);
    }
  };

  const confirmDeleteItems = (satRncItemIds: number[]) => {
    confirmDialog({
      message: 'Are you sure you want to delete this item?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => {
        deleteItems(satRncItemIds);
      },
    });
  };

  const handleDeleteItem = async (idSatRncItem: number) => {
    confirmDeleteItems([idSatRncItem]);
  };

  const gridActions = (rowData: SatRncItem) => {
    const isBeingDeleted =
      rowData.idSatRncItem && itemsBeingDeleted?.includes(rowData.idSatRncItem);

    return (
      <GridActions>
        <button
          type="button"
          className="trash-button"
          onClick={() => {
            if (rowData.idSatRncItem) {
              handleDeleteItem(rowData.idSatRncItem);
            }
          }}
          disabled={
            (!!rowData.idSatRncItem &&
              !!itemsBeingDeleted?.includes(rowData.idSatRncItem)) ||
            isReadOnly
          }
        >
          {isBeingDeleted ? (
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: '1.43rem' }}
            />
          ) : (
            <FiTrash2 size={20} />
          )}
        </button>
      </GridActions>
    );
  };

  const onDeleteSelectedClick = () => {
    const satRncItemsIds = selectedItems.map(item => item.idSatRncItem);
    const definedIds = satRncItemsIds.filter(
      id => id !== undefined,
    ) as number[];
    confirmDeleteItems(definedIds);
  };

  useEffect(() => {
    if (!rncItems && selected) {
      loadRncItemsData();
      loadComboBoxes();
    }
  }, [loadComboBoxes, loadRncItemsData, rncItems, selected]);

  return (
    <Container>
      <Dialog
        header="Add RNC Item"
        visible={displayAddItemModal}
        onHide={() => setDisplayAddItemModal(false)}
      >
        <AddRncItemModal
          idSat={satRnc.idSat}
          idSatRnc={satRnc.idSatRnc}
          setDisplayModal={setDisplayAddItemModal}
          rncItemsRefetch={refetchRncItems}
        />
      </Dialog>
      <FormTitle className="p-col-12 p-md-12 p-mt-5" name="RNC Items">
        {permissions.addItem && (
          <MainButton
            type="button"
            label="Add Item"
            onClick={() => onAddItemClick()}
            disabled={isReadOnly}
          />
        )}
        {permissions.removeItem && (
          <Button
            type="button"
            label="Delete selected"
            onClick={() => onDeleteSelectedClick()}
            icon={itemsBeingDeleted ? 'pi pi-spin pi-spinner' : 'pi pi-trash'}
            severity="danger"
            disabled={isReadOnly}
          />
        )}
      </FormTitle>
      <Grid
        className="s-internal-datatable"
        scrollable
        emptyMessage="No items found"
        loading={rncItemsLoading}
        value={rncItems}
        paginator={false}
        selectionMode="checkbox"
        selection={selectedItems}
        onSelectionChange={e => setSelectedItems(e.value)}
        editMode="row"
        editingRows={allGridRowsAsTrue}
        dataKey={gridDataKey}
        onRowEditChange={() => ''}
        selectionAutoFocus={false}
        lazy
      >
        <Column
          columnKey="multiple"
          selectionMode="multiple"
          style={{ width: '3em' }}
        />
        {permissions.removeItem && (
          <Column
            field="actions"
            style={{ width: '4rem' }}
            editor={options => gridActions(options.rowData)}
          />
        )}
        {fieldsPermissions.stCode.view && (
          <Column
            field="idSatItem2.stCode"
            header="ST Code"
            style={{ width: '6rem' }}
          />
        )}
        {fieldsPermissions.name.view && (
          <Column
            field="idSatItem2.name"
            header="Name (EN)"
            style={{ width: '15rem' }}
            editor={options => getNameBody(options.rowData)}
          />
        )}
        {fieldsPermissions.qtyComplained.view && (
          <Column
            field="quantityComplained"
            header="Qty Complained"
            style={{ width: '10rem' }}
            editor={e => handleEditor(e, fieldsPermissions.qtyComplained.edit)}
            footer={() => totals?.quantityComplained}
          />
        )}

        {fieldsPermissions.qtyBuyed.view && (
          <Column
            field="idSatItem2.quantity"
            header="Qty Buyed"
            style={{ width: '8rem' }}
            footer={() => totals?.quantity}
          />
        )}

        {fieldsPermissions.qtyBuyed.view && (
          <Column
            field="percentage"
            header="%"
            style={{ width: '7rem' }}
            editor={getComplainedPercentageBody}
          />
        )}

        {fieldsPermissions.pcsCtn.view && (
          <Column
            field="idSatItem2.piecesContainer"
            header="PCS/CTN"
            style={{ width: '6rem' }}
          />
        )}

        {fieldsPermissions.qtyCtn.view && (
          <Column
            field="qtyCtn"
            header="Qty CTN"
            style={{ width: '6rem' }}
            editor={getQtyCtnBody}
            footer={() => totals?.quantityContainer}
          />
        )}

        {fieldsPermissions.fobUnit.view && (
          <Column
            field="idSatItem2.sunPrice"
            header="FOB Unit"
            style={{ width: '8rem' }}
            editor={options => getFobUnitBody(options.rowData)}
          />
        )}

        {fieldsPermissions.fobTotal.view && (
          <Column
            field="totalFob"
            header="FOB Total"
            style={{ width: '8rem' }}
            editor={getTotalFobBody}
            footer={() => totals?.totalFob}
          />
        )}

        {fieldsPermissions.nationalizedUnit.view && (
          <Column
            field="nationalizedUnit"
            header="Nationalized Unit"
            style={{ width: '10rem' }}
            editor={e =>
              handleEditor(e, fieldsPermissions.nationalizedUnit.edit)
            }
          />
        )}

        {fieldsPermissions.nationalizedTotal.view && (
          <Column
            field="nationalizedTotal"
            header="Nationalized Total"
            style={{ width: '11rem' }}
            editor={getNationalizedTotalBody}
            footer={() => totals?.nationalizedTotal}
          />
        )}

        {fieldsPermissions.clientAware.view && (
          <Column
            field="clientAware"
            header="Client Aware"
            style={{ width: '8rem' }}
            editor={e => handleEditor(e, fieldsPermissions.clientAware.edit)}
          />
        )}

        {fieldsPermissions.type.view && (
          <Column
            field="idType2"
            header="Type"
            style={{ width: '10rem' }}
            editor={e => handleEditor(e, fieldsPermissions.type.edit)}
          />
        )}
        {fieldsPermissions.debitCategory.view && (
          <Column
            field="idDebitCategory2"
            header="Debit Category"
            style={{ width: '20rem' }}
            editor={e => handleEditor(e, fieldsPermissions.debitCategory.edit)}
          />
        )}
        {fieldsPermissions.notes.view && (
          <Column
            field="notes"
            header="Notes"
            style={{ width: '20rem' }}
            editor={e => handleEditor(e, fieldsPermissions.notes.edit)}
          />
        )}
      </Grid>
    </Container>
  );
};
export default RncItems;
