import React, { Dispatch, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { listOtherExpensesTotal } from './queries';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import {
  ForeignTradeSupplierTotalReducer,
  ForeignTradeSupplierTotalReducerAction,
  ForeignTradeSupplierTotalReducerActionKind,
} from '../interfaces';
import { SatFinancialPurchaseOtherExpensesTotalFieldsPermissions } from '../../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';

interface IPurchaseOtherExpensesTotalProps {
  idSat: number;
  selected: boolean;
  totalDispatch: Dispatch<ForeignTradeSupplierTotalReducerAction>;
  totalState: ForeignTradeSupplierTotalReducer;
  fieldsPermissions: SatFinancialPurchaseOtherExpensesTotalFieldsPermissions;
  satCurrency?: string;
}

const PurchaseOtherExpensesTotal: React.FC<IPurchaseOtherExpensesTotalProps> =
  ({
    idSat,
    selected,
    totalDispatch,
    totalState,
    fieldsPermissions,
    satCurrency,
  }) => {
    const { toastRef } = useRefHook();

    const satCurrencySymbol = getCurrencySymbol(satCurrency);

    const userCanSeeBlock =
      fieldsPermissions.totalOtherExpenses.view ||
      fieldsPermissions.totalOnMargin.view ||
      fieldsPermissions.totalOutOfMargin.view ||
      fieldsPermissions.totalDiscountFromSupplier.view ||
      fieldsPermissions.totalPayToSupplier.view;

    const [
      loadData,
      { loading: otherExpensesTotalLoading, data: otherExpensesTotalData },
    ] = useLazyQuery(listOtherExpensesTotal, {
      variables: {
        idSat,
        typeIdDomainGroup: DomainGroup.FINANCIAL_PURCHASE_OTHER_EXPENSES,
      },
      onCompleted: response => {
        if (
          response.getSumDataFromSatForeignTradeSupplierOtherExpensesByIdSat
        ) {
          totalDispatch({
            type: ForeignTradeSupplierTotalReducerActionKind.SET_PURCHASE_OTHER_EXPENSES_TOTAL,
            payload: {
              purchaseOtherExpensesTotal:
                response.getSumDataFromSatForeignTradeSupplierOtherExpensesByIdSat,
            },
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Total Purchase Other Expenses Total',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });

        totalDispatch({
          type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_PURCHASE_OTHER_EXPENSES_TOTAL_LOADING,
        });
      },
    });

    useEffect(() => {
      if (!otherExpensesTotalData && selected) {
        totalDispatch({
          type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_PURCHASE_OTHER_EXPENSES_TOTAL_LOADING,
        });

        loadData();
      }
    }, [otherExpensesTotalData, selected, loadData, totalDispatch]);

    return userCanSeeBlock ? (
      <div>
        <FormTitle name="Other Expenses Total" />

        <div className="p-grid">
          {fieldsPermissions.totalOtherExpenses.view && (
            <ReadOnlyInput
              className="p-col-3"
              label="Total Other Expenses"
              type="number"
              locale="pt-BR"
              prefix={satCurrencySymbol}
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={totalState.purchaseOtherExpensesTotal?.totalOtherExpenses}
              loading={otherExpensesTotalLoading}
            />
          )}

          {fieldsPermissions.totalOnMargin.view && (
            <ReadOnlyInput
              className="p-col-3"
              label="Total On Margin"
              type="number"
              locale="pt-BR"
              prefix={satCurrencySymbol}
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={totalState.purchaseOtherExpensesTotal?.totalOnMargin}
              loading={otherExpensesTotalLoading}
            />
          )}

          {fieldsPermissions.totalOutOfMargin.view && (
            <ReadOnlyInput
              className="p-col-3"
              label="Total Out Of Margin"
              type="number"
              locale="pt-BR"
              prefix={satCurrencySymbol}
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={totalState.purchaseOtherExpensesTotal?.totalOutOfMargin}
              loading={otherExpensesTotalLoading}
            />
          )}
        </div>

        <div className="p-grid">
          {fieldsPermissions.totalDiscountFromSupplier.view && (
            <ReadOnlyInput
              className="p-col-3"
              label="Total Discount From Supplier"
              type="number"
              locale="pt-BR"
              prefix={satCurrencySymbol}
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={
                totalState.purchaseOtherExpensesTotal?.totalDiscountFromSupplier
              }
              loading={otherExpensesTotalLoading}
            />
          )}

          {fieldsPermissions.totalPayToSupplier.view && (
            <ReadOnlyInput
              className="p-col-3"
              label="Total Pay To Supplier"
              type="number"
              locale="pt-BR"
              prefix={satCurrencySymbol}
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={totalState.purchaseOtherExpensesTotal?.totalPayToSupplier}
              loading={otherExpensesTotalLoading}
            />
          )}
        </div>
      </div>
    ) : (
      <div />
    );
  };

export default PurchaseOtherExpensesTotal;
