import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { InputMask, InputMaskProps } from 'primereact/inputmask';
import { Container } from './styles';

interface FormInputMaskProps extends InputMaskProps {
  name: string;
  label: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
}

export const maskFormats = {
  cnpj: '99.999.999/9999-99',
};

const FormInputMask: React.FC<FormInputMaskProps> = ({
  name,
  required,
  label,
  className,
  disabled,
  onBlur,
  readOnly,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [value, setValue] = useState(defaultValue);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return value;
      },
      setValue: (_, valueToSet: any | undefined) => {
        setValue(valueToSet);
      },
      clearValue: () => {
        setValue('');
      },
    });
  }, [fieldName, registerField, value]);

  return (
    <div className={`form-element formInputNumber ${className ?? ''}`}>
      <Container
        hasError={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        required={required}
        onClick={handleClick}
        disabled={disabled}
        readOnly={readOnly}
      >
        <span>
          {error && <FiAlertCircle color="#c53030" size={15} />}
          <p>{label}:</p>
        </span>

        <InputMask
          inputRef={inputRef}
          onChange={e => setValue(e.value)}
          onFocus={e => {
            const inputPageHeight = e.target.getBoundingClientRect().top + 60;
            const pageHeightTop = window.innerHeight;
            e.target.scrollIntoView({
              behavior: 'smooth',
              block: inputPageHeight > pageHeightTop ? 'center' : 'nearest',
              inline: 'nearest',
            });
            handleInputFocus();
          }}
          onBlur={e => {
            if (onBlur) onBlur(e);
            handleInputBlur();
          }}
          value={value}
          style={{ marginTop: '-6px', marginLeft: '-6px' }}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
        />
      </Container>
      {error && (
        <small id="username2-help" className="p-error p-d-block">
          {error}
        </small>
      )}
    </div>
  );
};

export default FormInputMask;
