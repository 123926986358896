import React, { CSSProperties, SVGAttributes } from 'react';
import { IconContext } from 'react-icons';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'react-error-boundary';

interface IProps {
  icon: string;
  color?: string;
  size?: string;
  className?: string;
  style?: CSSProperties;
  attr?: SVGAttributes<SVGElement>;
  alt?: string;
  title?: string;
}

const DynamicIcon: React.FC<IProps> = ({
  icon,
  attr,
  className,
  color,
  size,
  style,
  alt,
  title,
}) => {
  const [library, iconComponent] = icon.split('/');

  const renderErrorMessage = () => {
    return <small className="p-text-center">Icon not found</small>;
  };

  if (!library || !iconComponent) return renderErrorMessage();

  const lib = library.toLowerCase();

  const Icon =
    lib === 'sat'
      ? loadable.lib(() => import(`../../assets/satIcons/${iconComponent}.svg`))
      : loadable(() => import(`react-icons/${lib}/index.js`), {
          resolveComponent: (el: JSX.Element) =>
            el[iconComponent as keyof JSX.Element],
        });

  const value: IconContext = {
    color,
    size,
    className,
    style,
    attr,
  };

  return (
    <ErrorBoundary fallback={renderErrorMessage()}>
      {lib === 'sat' ? (
        <Icon>
          {(ReactComponent: any) => (
            <img
              src={ReactComponent.default}
              title={title}
              alt={alt ?? title}
              style={{ width: `${size}px` }}
            />
          )}
        </Icon>
      ) : (
        <IconContext.Provider value={value}>
          <Icon />
        </IconContext.Provider>
      )}
    </ErrorBoundary>
  );
};

export default DynamicIcon;
