import React, { useEffect, useRef, useState } from 'react';

import { Column } from 'primereact/column';
import { FiExternalLink } from 'react-icons/fi';
import { useLazyQuery, useMutation } from '@apollo/client';

import { confirmDialog } from 'primereact/confirmdialog';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import Button from '../../../../components/Button';
import AddBrand, { IAddBrandRef } from './AddBrand';
import { IProductBrands } from '../interfaces';
import { ProductBrandLazyParams } from './interfaces';
import pagination from '../../../../config/pagination';
import { deleteProductBrandsQuery, listProductBrandsQuery } from './queries';
import { useRefHook } from '../../../../hooks/useRefHook';
import Grid from '../../../../components/Grid';
import { gridConstants } from '../../../../components/Grid/constants';

interface IBrandingProps extends PageTabContainerProps {
  idProduct: number;
  userCanChangeFields: boolean;
}

const Branding: React.FC<IBrandingProps> = ({
  selected,
  idProduct,
  userCanChangeFields,
}) => {
  const [deleteProductBrandsMutation, { loading: deleteInProgress }] =
    useMutation(deleteProductBrandsQuery);
  const addBrandRef = useRef<IAddBrandRef>(null);
  const { showError, showSuccess } = useRefHook();

  const [lazyParams, setLazyParams] = useState<ProductBrandLazyParams>(
    pagination.initialLazyParams,
  );
  const [productBrands, setProductBrands] = useState<IProductBrands[]>();
  const [selectedBrands, setSelectedBrands] = useState<IProductBrands[]>([]);

  const [
    loadBrandsData,
    { loading: brandsLoading, data: brandsData, refetch: brandsRefetch },
  ] = useLazyQuery(listProductBrandsQuery, {
    // Garante que loading sera atualizado mesmo no refetch
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        idProduct,
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
      },
    },
    onCompleted: response => {
      if (response.listProductBrands) {
        setProductBrands([...response.listProductBrands.data]);
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Product Brands',
        detail: errorData.message,
      });
    },
  });

  const imageBodyTemplate = (rowData: { imageUrl: string | undefined }) => {
    return rowData.imageUrl ? (
      <a
        href={`${rowData.imageUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        className="attachment"
      >
        Open
        <FiExternalLink />
      </a>
    ) : (
      <p> - </p>
    );
  };

  async function handleDeleteSelected() {
    try {
      await deleteProductBrandsMutation({
        variables: {
          idsProductBrands: selectedBrands.map(a => a.idProductBrand),
        },
      });

      showSuccess({
        summary: 'Brands deleted',
        detail: `${selectedBrands.length} brands deleted`,
      });

      setSelectedBrands([]);

      await brandsRefetch();
    } catch (error) {
      showError({
        summary: 'Error while deleting brands',
        detail: error.message,
      });
    }
  }

  function onRowClick(e: any) {
    if (e.originalEvent.target.className.includes('p-checkbox')) {
      return;
    }

    addBrandRef.current?.toggleModal(e.data);
  }

  const gridHeader = (
    <div className="p-d-flex p-jc-between">
      <Button
        label="Add Brand"
        onClick={() => addBrandRef.current?.toggleModal()}
        disabled={!userCanChangeFields}
        type="button"
      />
      <Button
        label="Delete selected"
        severity="danger"
        onClick={() =>
          confirmDialog({
            message: `Are you sure you want to delete ${selectedBrands.length} brands?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-question-circle',
            accept: () => handleDeleteSelected(),
          })
        }
        disabled={!selectedBrands.length || !userCanChangeFields}
        loading={deleteInProgress}
        type="button"
      />
    </div>
  );

  useEffect(() => {
    if (selected && !productBrands) {
      loadBrandsData();
    }
  }, [productBrands, loadBrandsData, selected]);

  return (
    <PageTabContainer selected={selected}>
      <AddBrand
        ref={addBrandRef}
        brandsRefetch={brandsRefetch}
        idProduct={idProduct}
      />
      <Grid
        className="s-internal-datatable"
        value={productBrands}
        header={gridHeader}
        loading={brandsLoading}
        selection={selectedBrands}
        onSelectionChange={e => setSelectedBrands(e.value)}
        emptyMessage="No brands found"
        onRowClick={onRowClick}
        selectionMode="checkbox"
        lazy
        rows={lazyParams.rows}
        first={lazyParams.first}
        totalRecords={brandsData?.listProductBrands?.items ?? 0}
        scrollable
        scrollHeight={gridConstants.internalGridScrollHeight}
        onSort={e => setLazyParams({ ...lazyParams, ...e })}
        onPage={e => setLazyParams({ ...lazyParams, ...e })}
        removableSort
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
      >
        <Column
          selectionMode="multiple"
          style={{ width: '3em' }}
          reorderable={false}
        />
        <Column
          field="idClient2.name"
          header="Client"
          sortable
          headerStyle={{ flex: '1 1' }}
          style={{ flex: '1 1' }}
        />
        <Column
          field="idClientBrand2.brand"
          header="Client Brand"
          sortable
          headerStyle={{ flex: '1 1' }}
          style={{ flex: '1 1' }}
        />
        <Column
          field="code"
          header="Client Item Code"
          sortable
          headerStyle={{ flex: '1 1' }}
          style={{ flex: '1 1' }}
        />
        <Column
          header="Attachment"
          body={imageBodyTemplate}
          headerStyle={{ flex: '1 1' }}
          style={{ flex: '1 1' }}
        />
      </Grid>
    </PageTabContainer>
  );
};

export default Branding;
