import { gql, useLazyQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { confirmDialog } from 'primereact/confirmdialog';
import React, {
  Dispatch,
  Ref,
  SetStateAction,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import FormCheckbox from '../../../../../components/FormCheckbox';
import Loading from '../../../../../components/Loading';
import { useRefHook } from '../../../../../hooks/useRefHook';
import ToastLife from '../../../../../shared/enums/toastLife';
import {
  DifferenceToRefreshClientQuotationItemsResponse,
  IClientQuotationItem,
  IRefreshClientQuotationItemOptions,
} from '../../interfaces';
import {
  ClientQuotationItemsReducerActionKind,
  IClientQuotationItemsReducerAction,
} from '../interfaces';

import { CheckboxBlock, Container } from './styles';

/**
 * Interface de Ref do componente de Refresh Item
 */
export interface IRefreshClientQuotationItemSelectFieldsRef {
  /**
   * Funcao para selecionar todos os registros
   */
  handleSelectAll(): void;

  /**
   * Funcao para submit do formulario
   */
  submitForm(): void;
}

/**
 * Interface de Props do componente de Refresh Item
 */
interface IRefreshItemSelectFieldsProps {
  /**
   * Client Quotation Is Confection
   */
  indConfection?: boolean;

  /**
   * Referencia do componente de Refresh Item
   */
  ref: Ref<IRefreshClientQuotationItemSelectFieldsRef>;

  /**
   * Estado de todos os fields selecionados
   */
  allFieldsSelected: boolean;

  /**
   * Dispatch do reducer de modal
   */
  modalsDispatch: Dispatch<IClientQuotationItemsReducerAction>;

  /**
   * Define estado de response do refresh de fields selecionados
   */
  setRefreshSelectedFieldsResponse: Dispatch<
    SetStateAction<DifferenceToRefreshClientQuotationItemsResponse[]>
  >;

  /**
   * Client Quotation Items selecionados
   */
  selectedClientQuotationItems: IClientQuotationItem[];

  /**
   * Define estado de fields que deverao ser atualizados em cada item
   */
  setItemsFieldsToUpdate: Dispatch<
    SetStateAction<IRefreshClientQuotationItemOptions | undefined>
  >;
}

const RefreshItemSelectFields: React.FC<IRefreshItemSelectFieldsProps> =
  React.forwardRef(
    (
      {
        indConfection,
        allFieldsSelected,
        modalsDispatch,
        setRefreshSelectedFieldsResponse,
        selectedClientQuotationItems,
        setItemsFieldsToUpdate,
      },
      ref,
    ) => {
      // Referencia ao toast
      const { toastRef } = useRefHook();

      // Query para buscar diferenca entre cada field
      const getDifferenceToRefreshClientQuotationItemsQuery = gql`
        query GetDifferenceToRefreshClientQuotationItems(
          $data: RefreshClientQuotationItemsInput!
        ) {
          getDifferenceToRefreshClientQuotationItems(data: $data) {
            idClientQuotationItem
            stCode
            data {
              property
              label
              oldValue
              newValue
              changed
            }
          }
        }
      `;

      /**
       * Busca dados da Client Quotation
       */
      const [
        loadDifferenceToRefreshClientQuotationItemsData,
        { loading: getDifferenceLoading },
      ] = useLazyQuery(getDifferenceToRefreshClientQuotationItemsQuery, {
        onCompleted: response => {
          if (response.getDifferenceToRefreshClientQuotationItems) {
            setRefreshSelectedFieldsResponse(
              response.getDifferenceToRefreshClientQuotationItems,
            );
          } else {
            toastRef.current?.show({
              severity: 'error',
              summary: 'Data not found',
              life: ToastLife.ERROR,
            });
          }
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting difference data',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      });

      // Referencia ao formulario para atualizar sat item
      const formRef = useRef<FormHandles>(null);

      useImperativeHandle(
        ref,
        () => ({
          handleSelectAll: () => {
            // Nega valor atual de selecao dos fields
            const value = !allFieldsSelected;

            // Busca todos os dados do formulario
            const formData = formRef.current?.getData();

            if (formData) {
              // Define todos os valores do formulario de acordo com a selecao
              // desejada
              Object.keys(formData).forEach(key => {
                // Define valores que nao devem ser alterados
                if ((key === 'colour' || key === 'colourPt') && indConfection) {
                  return;
                }
                if (key === 'artworkTechnique' && !indConfection) return;

                // Atribui valor de selecao
                formData[key] = value;
              });

              // Define novo objeto no formulario
              formRef.current?.setData(formData);

              // Define novo valor de selecao geral
              modalsDispatch({
                type: ClientQuotationItemsReducerActionKind.CHANGE_ALL_FIELDS_SELECTED,
              });
            }
          },
          submitForm: () => {
            formRef.current?.submitForm();
          },
        }),
        [allFieldsSelected, indConfection, modalsDispatch],
      );

      /**
       * Executa request para refresh dos itens
       */
      const callRefreshItems = useCallback(
        (data: IRefreshClientQuotationItemOptions) => {
          // Busca IDs de Client Quotation Items
          const idsClientQuotationItems = selectedClientQuotationItems.map(
            item => item.idCqItem,
          );

          // Chama query
          loadDifferenceToRefreshClientQuotationItemsData({
            variables: {
              data: {
                ...data,
                idsClientQuotationItems,
              },
            },
          });

          // Salva fields que precisam ser atualizados no estado
          setItemsFieldsToUpdate(data);
        },
        [
          loadDifferenceToRefreshClientQuotationItemsData,
          selectedClientQuotationItems,
          setItemsFieldsToUpdate,
        ],
      );

      /**
       * Efetua solicitacao para refresh dos itens
       * @param data Dados do formulario
       */
      const handleRequestRefreshItem = useCallback(
        (data: IRefreshClientQuotationItemOptions) => {
          if (data.sunPrice || data.cotPrice) {
            confirmDialog({
              message:
                'Please note the price will be updated according to MD-Product. Prices updated in the SAT Item will be replaced. Confirm?',
              header: 'Alert',
              icon: 'pi pi-info-circle',
              acceptClassName: 'p-button-danger',
              accept: () => callRefreshItems(data),
            });
          } else {
            callRefreshItems(data);
          }
        },

        [callRefreshItems],
      );

      return (
        <Container>
          <Form ref={formRef} onSubmit={handleRequestRefreshItem}>
            <CheckboxBlock>
              <FormCheckbox
                className="p-m-2"
                name="sunPrice"
                label="Update SUN Price"
              />
              <FormCheckbox
                className="p-m-2"
                name="cotPrice"
                label="Update Cot Price"
              />
              <FormCheckbox
                className="p-m-2"
                name="sunNumber"
                label="SUN Number"
              />
              <FormCheckbox
                className="p-m-2"
                name="moq"
                label="MOQ"
                initialValue
              />
            </CheckboxBlock>
            <CheckboxBlock>
              <FormCheckbox
                className="p-m-2"
                name="model"
                label="Model"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="sku"
                label="SKU"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="netWeight"
                label="Net Weight"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="name"
                label="Name (EN)"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="namePt"
                label="Name (PT)"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="materialComposition"
                label="Material (EN) + Composition (%)"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="materialPt"
                label="Material + Composition (PT)"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="colour"
                label="Colour (EN)"
                disabled={indConfection}
                initialValue={!indConfection}
              />
              <FormCheckbox
                className="p-m-2"
                name="colourPt"
                label="Colour (PT)"
                disabled={indConfection}
                initialValue={!indConfection}
              />
              <FormCheckbox
                className="p-m-2"
                name="description"
                label="Description (EN)"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="descriptionPt"
                label="Description (PT)"
                initialValue
              />
            </CheckboxBlock>
            <CheckboxBlock>
              <FormCheckbox
                className="p-m-2"
                name="dimensions"
                label="Dimensions"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="importantInformation"
                label="Important information"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="package"
                label="Package"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="brand"
                label="Branding"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="artworkTechnique"
                label="Artwork/Technique"
                disabled={!indConfection}
                initialValue={!!indConfection}
              />
              <FormCheckbox className="p-m-2" name="m2Pc" label="SQM Unit" />
            </CheckboxBlock>
            <CheckboxBlock>
              <FormCheckbox
                className="p-m-2"
                name="image"
                label="Image"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="colourImage"
                label="Colour Image"
                initialValue
              />
              <FormCheckbox className="p-m-2" name="m2Pc" label="SQM Unit" />
            </CheckboxBlock>
            <CheckboxBlock>
              <FormCheckbox
                className="p-m-2"
                name="hsCode"
                label="HS Code"
                initialValue
              />
              <FormCheckbox
                className="p-m-2"
                name="ncm"
                label="NCM"
                initialValue
              />
            </CheckboxBlock>
          </Form>
          {getDifferenceLoading && <Loading />}
        </Container>
      );
    },
  );

export default RefreshItemSelectFields;
