import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 28px 25px;
`;

export const Widgets = styled.div`
  margin-top: 23px;
  display: grid;
  //grid-auto-flow: column dense;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  //grid-template-columns: repeat(auto-fill);
  // grid-template-rows: auto;
  grid-template-areas:
    'prc prc'
    '. test';

  .prcWidget {
    grid-area: prc;
  }
`;
