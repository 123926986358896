import React, {
  Dispatch,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { startOfDay } from 'date-fns';
import { Checkbox } from 'primereact/checkbox';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { object, string, ValidationError } from 'yup';

import { IUserFieldsAndPermissionsResponse } from '../../../../utils/getUserFieldsAndPermissionsByEntity';
import {
  AttachmentTypeBox,
  AttachmentTypeContent,
  Container,
  LinkField,
  SatArtworkAttachment,
  SatArtworkFiles,
  SatArtworkGeneralInformation,
  SatArtworkTiming,
  SatArtworkTracking,
  Subtitle,
} from './styles';

import Button from '../../../../components/Button';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import FormDropdown from '../../../../components/FormDropdown';
import FormInput from '../../../../components/FormInput';
import FormInputNumber from '../../../../components/FormInputNumber';
import FormTitle from '../../../../components/FormTitle';
import Loading from '../../../../components/Loading';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { useRefHook } from '../../../../hooks/useRefHook';
import { IDomain } from '../../../../interfaces/IDomain';
import { ISatAttachment } from '../../../../interfaces/ISatAttachment';
import { queryToListDomains } from '../../../../services/querysGql';
import { DomainGroup } from '../../../../shared/enums/domainGroup';
import { Role } from '../../../../shared/enums/role';
import { IAttachment } from '../../../../shared/interfaces/attachment';
import { booleanNumberValueOptions } from '../../../../shared/options/boolean-options';
import { asyncSelectLoadDomains } from '../../../../shared/querys/domain';
import {
  asyncSelectLoadUsersOptions,
  loadUsersByRolesOptions,
} from '../../../../shared/querys/user';
import { satsRoles } from '../../../../shared/roles/sat';
import {
  dateDifferenceInDays,
  getDifferenceBetweenDatesAtStart,
  getUTC,
  removeDaysFromDate,
} from '../../../../utils/dateUtil';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../utils/getValidationErrors';
import userHasPermission from '../../../../utils/userHasPermission';
import { ISat } from '../interfaces';
import {
  createSatAttachmentsQuery,
  deleteSatAttachmentsQuery,
} from '../queries';
import { listSatAttachmentsBySatIdQuery } from '../Updates/queries';
import ArtworkGrid, { ISatArtworkGridRef } from './ArtworkGrid';
import AttachmentUpload from './AttachmentUpload';
import {
  HandleCreateOrUpdateSatArtworkResponse,
  ISatArtwork,
} from './interfaces';
import {
  downloadSatArtworkFilesQuery,
  listSatArtworkBySatIdQuery,
  updateSatArtworkQuery,
} from './queries';
import ReadOnlyInput from '../../../../components/ReadOnlyInput';
import { objectsAreEqual } from '../../../../utils/objectsAreEqual';
import { FileUploadResponse } from '../../../../components/FileUpload/interfaces';

interface ArtworkProps extends PageTabContainerProps {
  sat: ISat;
  ref: Ref<ISatArtworkRef>;
  setPageLoading: Dispatch<SetStateAction<boolean>>;
  userPermissionsSat: IUserFieldsAndPermissionsResponse;
}

export interface ISatArtworkRef {
  handleValidateArtworkForm(): Promise<boolean>;
  handleUpdateArtwork(): Promise<HandleCreateOrUpdateSatArtworkResponse>;
  refetch(): void;
}

type ItemSelection<T> = {
  item: T;
  isSelected: boolean;
};

const Artwork: React.FC<ArtworkProps> = React.forwardRef(
  ({ selected, sat, setPageLoading, userPermissionsSat }, ref) => {
    const userCanUploadArtwork = userHasPermission(
      satsRoles.permissions.idPermissionUploadArtwork,
      userPermissionsSat.userPermissions,
    );

    const userCanDeleteArtwork = userHasPermission(
      satsRoles.permissions.idPermissionDeleteArtwork,
      userPermissionsSat.userPermissions,
    );

    const { showError, showSuccess, showWarn } = useRefHook();

    const formRef = useRef<FormHandles>(null);

    const artworkGridRef = useRef<ISatArtworkGridRef>(null);

    const [satArtwork, setSatArtwork] = useState<ISatArtwork>();

    const [displayUploadModal, setDisplayUploadModal] = useState(false);

    const [attachmentTypes, setAttachmentTypes] = useState<IDomain[]>();

    const [satAttachments, setSatAttachments] =
      useState<ItemSelection<ISatAttachment>[]>();

    const [hasGridChanged, setHasGridChanged] = useState<boolean>(false);

    const [updateSatArtworkMutation] = useMutation(updateSatArtworkQuery);

    const [
      createSatAttachmentsMutation,
      { loading: createArtworkAttachmentLoading },
    ] = useMutation(createSatAttachmentsQuery);
    const [deleteSatAttachmentsMutation] = useMutation(
      deleteSatAttachmentsQuery,
    );

    const calculateDueDate = useCallback((): string | undefined => {
      if (sat.estimatedInspectionDate) {
        const daysToRemove = (sat.productionTime || 0) + 10;

        return removeDaysFromDate(
          daysToRemove,
          sat.estimatedInspectionDate,
          true,
        );
      }
      return undefined;
    }, [sat.estimatedInspectionDate, sat.productionTime]);

    const [loadData, { loading: dataLoading }] = useLazyQuery(
      listSatArtworkBySatIdQuery,
      {
        variables: {
          idSat: sat.idSat,
        },
        onCompleted: response => {
          const artwork: ISatArtwork = response.listSatArtworkBySatId ?? {};
          if (!artwork.dueDate && sat.estimatedInspectionDate) {
            artwork.dueDate = calculateDueDate();
          }
          setSatArtwork(artwork);
        },
        onError: errorData => {
          showError({
            summary: 'Error while getting SAT Artwork',
            detail: errorData.message,
          });
        },
      },
    );

    const [loadAttachments, { loading: loadingAttachments }] = useLazyQuery(
      listSatAttachmentsBySatIdQuery,
      {
        variables: {
          data: {
            idSat: sat.idSat,
            idTypes: attachmentTypes?.map(type => type.idDomain),
          },
        },
        onCompleted: response => {
          if (response.listSatAttachmentsBySatId?.data) {
            setSatAttachments(
              response.listSatAttachmentsBySatId.data.map(
                (item: ISatAttachment) => {
                  return {
                    item,
                    isSelected: false,
                  };
                },
              ),
            );
          }
        },
        onError: errorData => {
          showError({
            summary: 'Error while getting artwork attachments',
            detail: errorData.message,
          });
        },
      },
    );

    const [loadAttachmentTypes, { loading: loadingAttachmentTypes }] =
      useLazyQuery(queryToListDomains, {
        variables: {
          id: DomainGroup.ARTWORK_TYPE_FILES,
          pagination: {
            _page: 0,
            _limit: 0,
          },
        },
        onCompleted: response => {
          if (response.listDomainsByGroupId.data) {
            setAttachmentTypes(response.listDomainsByGroupId.data);
            loadAttachments();
          }
        },
        onError: errorData => {
          showError({
            summary: 'Error while getting Artwork Type Files',
            detail: errorData.message,
          });
        },
      });

    useEffect(() => {
      if (!satArtwork) {
        loadData();
        loadAttachmentTypes();
      }
    }, [loadData, selected, satArtwork, loadAttachmentTypes, loadAttachments]);

    useEffect(() => {
      formRef.current?.setFieldValue('dueDate', calculateDueDate());
    }, [calculateDueDate, sat.estimatedInspectionDate]);

    const handleSubmit = useCallback(async () => {
      const data = formRef.current?.getData();

      if (!data || !satArtwork?.idSatArtwork) return false;

      if (objectsAreEqual(data, satArtwork) && !hasGridChanged) return false;

      const satItems = await artworkGridRef.current?.getSatItemsToUpdate();
      const satItemsToUpdate = satItems?.map(satItem => {
        return {
          ...satItem,
          idProduct: undefined,
          idProduct2: undefined,
        };
      });

      const satReferenceSizeBarcodesToUpdate =
        await artworkGridRef.current?.getSatReferenceSizeBarcodesToUpdate();

      let response;
      try {
        response = await updateSatArtworkMutation({
          variables: {
            data: {
              idSatArtwork: satArtwork?.idSatArtwork,
              idSat: sat.idSat,
              ...data,
              satItemsToUpdate: {
                satItems: satItemsToUpdate,
                satReferenceSizeBarcodes: satReferenceSizeBarcodesToUpdate,
              },
            },
          },
        });
        return response.data.updateSatArtwork;
      } catch (error) {
        showError({
          summary: 'Error while updating artwork items data',
          detail: error.message,
        });

        return error.message;
      }
    }, [
      hasGridChanged,
      sat.idSat,
      satArtwork,
      showError,
      updateSatArtworkMutation,
    ]);

    useImperativeHandle(ref, () => ({
      handleValidateArtworkForm: async () => {
        const formData = formRef.current?.getData();

        if (!formData) return false;

        try {
          formRef.current?.setErrors({});
          const schema = object().shape({
            dueDate: string().nullable().required(requiredFieldErrorMessage),
          });

          await schema.validate(formData, { abortEarly: false });
          return true;
        } catch (error) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          const firstError = error.inner[0];
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);

          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef?.current?.focus) {
            inputWithError.inputRef?.current?.focus();
          }
          showWarn({
            summary: 'Artwork tab',
            detail: 'Please fill all the required fields',
          });

          throw new ValidationError(error);
        }
      },

      handleUpdateArtwork: async () => {
        return handleSubmit() as any;
      },

      refetch: () => {
        setSatArtwork(undefined);
        loadData();
      },
    }));

    const getJoinedSatNumbersFromSatGroup = useCallback(() => {
      const satNumbers = sat.idSatGroup2?.sats.map(s => s.satNumber);
      return satNumbers?.join(', ');
    }, [sat.idSatGroup2?.sats]);

    const updateDateField = useCallback(
      (
        fieldNameToUpdate: string,
        firstDate?: string,
        secondDate?: string | Date,
      ) => {
        if (firstDate && secondDate) {
          const difference = getDifferenceBetweenDatesAtStart(
            firstDate,
            secondDate,
          );
          formRef.current?.setFieldValue(fieldNameToUpdate, difference);
        } else {
          formRef.current?.setFieldValue(fieldNameToUpdate, undefined);
        }
      },
      [],
    );

    const getTotalArtworkTiming = useCallback(() => {
      if (satArtwork?.artworkDone && sat.confirmOrderComercial) {
        const artworkDone = new Date(satArtwork?.artworkDone);
        const diff = dateDifferenceInDays(
          getUTC(artworkDone),
          startOfDay(new Date(sat.confirmOrderComercial)),
        );
        return diff;
      }
      if (sat.confirmOrderComercial) {
        return dateDifferenceInDays(
          startOfDay(new Date()),
          startOfDay(new Date(sat.confirmOrderComercial)),
        );
      }
      return undefined;
    }, [sat.confirmOrderComercial, satArtwork?.artworkDone]);

    const showUploadModal = () => {
      setDisplayUploadModal(true);
    };

    const hideUploadModal = () => {
      setDisplayUploadModal(false);
    };

    async function handleCreateNewAttachments(
      e: FileUploadResponse[],
      idType?: number,
    ): Promise<IAttachment | undefined> {
      try {
        const response = await createSatAttachmentsMutation({
          variables: {
            data: {
              idSat: sat.idSat,
              idType,
              uploadedFiles: e.map(file => file.serverName),
            },
          },
        });

        showSuccess({ summary: 'Artwork attachment uploaded' });
        loadAttachments();
        hideUploadModal();
        return response.data?.createSatAttachment?.idAttachment2;
      } catch (err) {
        showError({
          summary: 'Error while creating attachment',
          detail: err.message,
        });
        return undefined;
      }
    }

    const selectAttachment = useCallback(
      (idSatAttachment: number | undefined, isSelected: boolean) => {
        if (satAttachments && idSatAttachment) {
          const newSatAttachments = [...satAttachments];
          const satAttachment = newSatAttachments.find(
            newSatAttachment =>
              newSatAttachment.item.idSatAttachment === idSatAttachment,
          );

          if (satAttachment) {
            satAttachment.isSelected = isSelected;
          }
          setSatAttachments([...newSatAttachments]);
        }
      },
      [satAttachments],
    );

    const selectAttachmentsFromType = useCallback(
      (idType: number, isSelected: boolean) => {
        if (satAttachments) {
          const newSatAttachments = [...satAttachments];
          const filteredSatAttachments = newSatAttachments.filter(
            newSatAttachment =>
              newSatAttachment.item.idAttachment2?.idType === idType,
          );

          // eslint-disable-next-line no-restricted-syntax
          for (const newSatAttachment of filteredSatAttachments) {
            newSatAttachment.isSelected = isSelected;
          }

          setSatAttachments([...newSatAttachments]);
        }
      },
      [satAttachments],
    );

    const getSelectedAttachments = useCallback(() => {
      return satAttachments?.filter(satAttachment => satAttachment.isSelected);
    }, [satAttachments]);

    const deleteAttachments = useCallback(async () => {
      const selectedAttachments = getSelectedAttachments();
      if (selectedAttachments && selectedAttachments.length) {
        setPageLoading(true);
        try {
          await deleteSatAttachmentsMutation({
            variables: {
              satAttachmentIds: selectedAttachments.map(
                satAttachment => satAttachment.item.idSatAttachment,
              ),
            },
          });
          showSuccess({
            summary: 'Artwork attachments deleted',
          });

          loadAttachments();
        } catch (error) {
          showError({
            summary: 'Error while deleting artwork attachments',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      }
    }, [
      deleteSatAttachmentsMutation,
      getSelectedAttachments,
      loadAttachments,
      setPageLoading,
      showError,
      showSuccess,
    ]);

    const handleDeleteAttachments = useCallback(async () => {
      confirmDialog({
        message: `Are you sure you want to delete ${
          getSelectedAttachments()?.length
        } attachments?`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: async () => {
          deleteAttachments();
        },
      });
    }, [deleteAttachments, getSelectedAttachments]);

    const [downloadSatArtworkFiles] = useLazyQuery(
      downloadSatArtworkFilesQuery,
      {
        onCompleted: response => {
          window.open(response.downloadSatArtworkFiles, '_blank');
          setPageLoading(false);
        },
        onError: error => {
          showError({
            summary: 'Error while downloading artwork attachments',
            detail: error.message,
          });
          setPageLoading(false);
        },
      },
    );

    const handleDownloadAttachments = useCallback(async () => {
      const selectedAttachments = getSelectedAttachments();
      if (
        selectedAttachments &&
        selectedAttachments.length &&
        selectedAttachments[0].item.idAttachment2?.name
      ) {
        setPageLoading(true);

        downloadSatArtworkFiles({
          variables: {
            idsSatAttachments: selectedAttachments.map(
              item => item.item.idSatAttachment,
            ),
          },
        });
      }
    }, [downloadSatArtworkFiles, getSelectedAttachments, setPageLoading]);

    const onNewLayoutValueChange = useCallback((value: any) => {
      if (!value) {
        const startDoing = formRef.current?.getFieldValue('startDoing');
        formRef.current?.setFieldValue('sentLayout', startDoing);
        formRef.current?.setFieldValue('layoutApproved', startDoing);
      }
    }, []);

    function renderFileCreatedAt(createdAt?: Date) {
      if (!createdAt) return undefined;

      return ` - ${new Date(createdAt).toLocaleString([], {
        dateStyle: 'short',
        timeStyle: 'short',
      })}`;
    }

    return (
      <PageTabContainer selected={selected}>
        {!dataLoading && satArtwork && (
          <Form ref={formRef} onSubmit={handleSubmit} initialData={satArtwork}>
            <Dialog
              header="Upload Artwork Files"
              visible={displayUploadModal}
              style={{ width: '40vw' }}
              onHide={() => hideUploadModal()}
              closable
            >
              <AttachmentUpload
                handleCreateNewAttachments={handleCreateNewAttachments}
                uploadInProgress={createArtworkAttachmentLoading}
              />
            </Dialog>
            <Container className="p-d-flex p-flex-wrap">
              <SatArtworkGeneralInformation className="p-d-flex p-flex-wrap">
                <FormTitle
                  className="p-col-12 p-md-12 p-mt-5"
                  name="General Information"
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Artwork Status"
                  value={satArtwork.idStatusArtwork2?.description ?? ''}
                />
                <FormInput
                  className="p-col-12 p-md-3"
                  name="dueDate"
                  label="Due Date"
                  type="date"
                  min={calculateDueDate()}
                  required
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Type of order"
                  value={sat.typeOfOrder2?.description ?? ''}
                />
                <LinkField className="p-col-12 p-md-3">
                  <ReadOnlyInput
                    className="p-col-10 p-md-10"
                    label="Replacement from"
                    value={sat.idSatOrigin2?.satNumber ?? ''}
                  />
                  <Link
                    className="link p-col-2 p-md-2"
                    to={`/commercial/sats/${sat.idSatOrigin}`}
                    target="_blank"
                  >
                    <FiExternalLink size={15} title="Open Supplier" />
                  </Link>
                </LinkField>
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Sat Group"
                  value={getJoinedSatNumbersFromSatGroup()}
                />
                <FormAsyncSelect
                  className="p-col-3"
                  name="idDesignLevel"
                  label="Designer Level"
                  loadOptions={asyncSelectLoadDomains}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  noOptionsMessage={() => 'No incoterms found'}
                  initialValue={satArtwork.idDesignLevel2}
                  additional={{
                    id: 62,
                  }}
                />
                <div className="p-col-12 p-md-3 dropdown-width-ajust">
                  <FormDropdown
                    className="p-col-12 p-md-3"
                    label="New Layout"
                    name="newLayout"
                    optionLabel="label"
                    options={booleanNumberValueOptions}
                    initialValue={satArtwork.newLayout || 0}
                    onValueChange={e => onNewLayoutValueChange(e)}
                  />
                </div>
                <FormInputNumber
                  className="p-col-12 p-md-3"
                  name="artworkQuantity"
                  label="Quantity"
                  readOnly
                />
                <FormInput
                  className="p-col-12 p-md-3"
                  name="activities"
                  label="Activities"
                  readOnly
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Package Notes"
                  value={sat.packagesNotes ?? ''}
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Remarks"
                  value={sat.remarks ?? ''}
                />
                <FormInput
                  className="p-col-12 p-md-6"
                  name="designNotes"
                  label="Design Notes"
                />
              </SatArtworkGeneralInformation>
              <SatArtworkTracking className="p-d-flex p-flex-wrap">
                <FormTitle
                  className="p-col-12 p-md-12 p-mt-5"
                  name="Tracking"
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Artwork Start Date"
                  value={
                    sat.confirmOrderComercial
                      ? new Date(sat.confirmOrderComercial).toLocaleDateString()
                      : undefined
                  }
                />
                <Subtitle className="p-col-12 p-md-12">
                  <span>1. Missing Info</span>
                </Subtitle>
                <FormInput
                  type="date"
                  className="p-col-12 p-md-3"
                  name="internalInfo"
                  label="Internal Info"
                />
                <FormInput
                  type="date"
                  className="p-col-12 p-md-3"
                  name="clientInfo"
                  label="Client Info"
                />
                <FormInput
                  type="date"
                  className="p-col-12 p-md-3"
                  name="hrp"
                  label="HRP"
                  onChange={e => {
                    updateDateField(
                      'HRPTiming',
                      e.target.value,
                      sat.confirmOrderComercial,
                    );
                  }}
                />
                <FormAsyncSelect
                  className="p-col-12 p-md-3"
                  name="idSupportResponsible"
                  label="Support Responsible"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.DESIGN, Role.DESIGN_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={satArtwork.idSupportResponsible2}
                />
                <Subtitle className="p-col-12 p-md-12">
                  <span>2. Doing</span>
                </Subtitle>
                <FormInput
                  type="date"
                  className="p-col-12 p-md-3"
                  name="startDoing"
                  label="Start Doing"
                  onChange={e => {
                    updateDateField(
                      'infoForStartTiming',
                      e.target.value,
                      sat.confirmOrderComercial,
                    );
                    updateDateField(
                      'layoutTiming',
                      formRef.current?.getFieldValue('sentLayout'),
                      e.target.value,
                    );
                    updateDateField(
                      'doingTiming',
                      formRef.current?.getFieldValue('designReview'),
                      e.target.value,
                    );
                  }}
                />
                <FormInput
                  type="date"
                  className="p-col-12 p-md-3"
                  name="sentLayout"
                  label="Sent Layout"
                  onChange={e => {
                    updateDateField(
                      e.target.value,
                      formRef.current?.getFieldValue('startDoing'),
                      'layoutTiming',
                    );
                  }}
                />
                <FormInput
                  type="date"
                  className="p-col-12 p-md-3"
                  name="layoutApproved"
                  label="Layout Approved"
                />
                <FormAsyncSelect
                  className="p-col-12 p-md-3"
                  name="idDesignResponsible"
                  label="Design Responsible"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.DESIGN, Role.DESIGN_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={satArtwork.idDesignResponsible2}
                />
                <Subtitle className="p-col-12 p-md-12">
                  <span>3. Review</span>
                </Subtitle>
                <FormInput
                  className="p-col-12 p-md-3"
                  name="designReview"
                  label="Design Review"
                  type="date"
                  readOnly
                />
                <FormAsyncSelect
                  className="p-col-12 p-md-3"
                  name="idReviewResponsible"
                  label="Review Responsible"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.DESIGN, Role.DESIGN_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={satArtwork.idReviewResponsible2}
                />
                <FormInput
                  className="p-col-12 p-md-3"
                  name="commercialReview"
                  label="Commercial Review"
                  type="date"
                  readOnly
                />
                <FormAsyncSelect
                  className="p-col-12 p-md-3"
                  name="idCommercialReviewResponsible"
                  label="Commercial Review Responsible"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.COMMERCIAL, Role.COMMERCIAL_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={satArtwork.idCommercialReviewResponsible2}
                />
                <Subtitle className="p-col-12 p-md-12">
                  <span>4. Approval</span>
                </Subtitle>
                <FormInput
                  type="date"
                  className="p-col-12 p-md-3"
                  name="sentToClient"
                  label="Sent To Client"
                  onChange={e => {
                    updateDateField(
                      'approvalTiming',
                      formRef.current?.getFieldValue('approvedByClient'),
                      e.target.value,
                    );
                  }}
                />
                <FormInput
                  type="date"
                  className="p-col-12 p-md-3"
                  name="approvedByClient"
                  label="Approved by Client"
                  onChange={e => {
                    updateDateField(
                      'approvalTiming',
                      e.target.value,
                      formRef.current?.getFieldValue('sentToClient'),
                    );
                  }}
                />
                <FormAsyncSelect
                  className="p-col-12 p-md-3"
                  name="idCommercialResponsible"
                  label="Commercial Responsible"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.COMMERCIAL, Role.COMMERCIAL_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={satArtwork.idCommercialResponsible2}
                />
                <FormInput
                  className="p-col-12 p-md-3"
                  name="licensorApproval"
                  label="Licensor Approval"
                  type="date"
                  readOnly
                />
                <Subtitle className="p-col-12 p-md-12">
                  <span>5. Sketches</span>
                </Subtitle>
                <FormInput
                  type="date"
                  className="p-col-12 p-md-3"
                  name="sentFinalArtWork"
                  label="Sent Final Artwork"
                  onChange={e => {
                    updateDateField(
                      'sketchesReplyTiming',
                      formRef.current?.getFieldValue('sketchesReceived'),
                      e.target.value,
                    );
                    updateDateField(
                      'sketchesApprovalTiming',
                      formRef.current?.getFieldValue('artworkDone'),
                      e.target.value,
                    );
                  }}
                />
                <FormAsyncSelect
                  className="p-col-12 p-md-3"
                  name="idFinalArtWorkResponsible"
                  label="Final Artwork Responsible"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.DESIGN, Role.DESIGN_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={satArtwork.idFinalArtWorkResponsible2}
                />
                <FormInput
                  type="date"
                  className="p-col-12 p-md-3"
                  name="sketchesReceived"
                  label="Sketches Received"
                  onChange={e => {
                    updateDateField(
                      'sketchesReplyTiming',
                      e.target.value,
                      formRef.current?.getFieldValue('sentFinalArtWork'),
                    );
                  }}
                />
                <FormAsyncSelect
                  className="p-col-12 p-md-3"
                  name="idSketchesResponsible"
                  label="Sketches Responsible"
                  loadOptions={asyncSelectLoadUsersOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={satArtwork.idSketchesResponsible2}
                />
                <Subtitle className="p-col-12 p-md-12">
                  <span>6. Done</span>
                </Subtitle>
                <FormInput
                  className="p-col-12 p-md-3"
                  name="waitingRevisedSketches"
                  label="Waiting Revised Sketches"
                  type="date"
                />
                <FormInput
                  className="p-col-12 p-md-3"
                  name="artworkDone"
                  label="Artwork Done"
                  type="date"
                  readOnly
                />
              </SatArtworkTracking>
              <SatArtworkTiming className="p-d-flex p-flex-wrap">
                <FormTitle className="p-col-12 p-md-12 p-mt-5" name="Timing" />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Total Artwork Timing"
                  value={getTotalArtworkTiming()}
                  type="number"
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="HRP Timing"
                  value={getDifferenceBetweenDatesAtStart(
                    satArtwork.hrp,
                    sat.confirmOrderComercial,
                  )}
                  type="number"
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Info for Start Timing"
                  value={getDifferenceBetweenDatesAtStart(
                    satArtwork.startDoing,
                    sat.confirmOrderComercial,
                  )}
                  type="number"
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Layout Timing"
                  value={getDifferenceBetweenDatesAtStart(
                    satArtwork.sentLayout,
                    satArtwork.startDoing,
                  )}
                  type="number"
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Doing Timing"
                  value={getDifferenceBetweenDatesAtStart(
                    satArtwork.designReview,
                    satArtwork.startDoing,
                  )}
                  type="number"
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Approval Timing"
                  value={getDifferenceBetweenDatesAtStart(
                    satArtwork.approvedByClient,
                    satArtwork.sentToClient,
                  )}
                  type="number"
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Sketches Reply Timing"
                  value={getDifferenceBetweenDatesAtStart(
                    satArtwork.sketchesReceived,
                    satArtwork.sentFinalArtWork,
                  )}
                  type="number"
                />
                <ReadOnlyInput
                  className="p-col-12 p-md-3"
                  label="Sketches Approval Timing"
                  value={getDifferenceBetweenDatesAtStart(
                    satArtwork.artworkDone,
                    satArtwork.sentFinalArtWork,
                  )}
                  type="number"
                />
              </SatArtworkTiming>
              <SatArtworkFiles className="p-d-flex p-flex-wrap">
                <FormTitle
                  className="p-col-12 p-md-3 p-mt-5"
                  name="Artwork Files"
                />
                <div className="p-col-12 p-md-9 flex buttons">
                  {userCanUploadArtwork && (
                    <Button
                      type="button"
                      label="Upload"
                      icon="pi pi-upload"
                      onClick={() => showUploadModal()}
                    />
                  )}

                  <Button
                    type="button"
                    label="Download"
                    icon="pi pi-download"
                    onClick={() => handleDownloadAttachments()}
                  />
                  {userCanDeleteArtwork && (
                    <Button
                      type="button"
                      label="Delete"
                      severity="danger"
                      onClick={() => handleDeleteAttachments()}
                    />
                  )}
                </div>
                <AttachmentTypeContent className="flex">
                  {!loadingAttachmentTypes &&
                    attachmentTypes?.length &&
                    attachmentTypes?.map(attachmentType => {
                      const filteredAttachments = satAttachments?.filter(
                        satAttachment =>
                          satAttachment?.item.idAttachment2?.idType ===
                          attachmentType.idDomain,
                      );
                      return (
                        <AttachmentTypeBox key={attachmentType.idDomain}>
                          <label
                            className="p-checkbox-label p-ml-auto p-mr-3 attachment-type-title"
                            htmlFor={attachmentType.description.toUpperCase()}
                          >
                            <Checkbox
                              className="p-mr-2"
                              checked={
                                filteredAttachments?.length &&
                                filteredAttachments?.every(
                                  filteredAttachment =>
                                    filteredAttachment.isSelected,
                                )
                              }
                              onChange={e =>
                                selectAttachmentsFromType(
                                  attachmentType.idDomain,
                                  e.checked,
                                )
                              }
                            />
                            {attachmentType.description.toUpperCase()}
                          </label>
                          <hr className="p-my-2" />
                          {!loadingAttachments &&
                            filteredAttachments?.map(filteredAttachment => {
                              return (
                                <SatArtworkAttachment
                                  key={filteredAttachment.item.idSatAttachment}
                                  className="p-mb-2"
                                >
                                  <label
                                    className="p-checkbox-label p-ml-auto p-mr-3"
                                    htmlFor={
                                      filteredAttachment?.item.idAttachment2
                                        ?.nameGivenByUser
                                    }
                                  >
                                    <Checkbox
                                      className="p-mr-2"
                                      checked={filteredAttachment?.isSelected}
                                      onChange={e =>
                                        selectAttachment(
                                          filteredAttachment.item
                                            .idSatAttachment,
                                          e.checked,
                                        )
                                      }
                                    />
                                    {
                                      filteredAttachment?.item.idAttachment2
                                        ?.nameGivenByUser
                                    }
                                    {renderFileCreatedAt(
                                      filteredAttachment?.item.idAttachment2
                                        ?.createdAt,
                                    )}
                                    <a
                                      href={
                                        filteredAttachment?.item.idAttachment2
                                          ?.url
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="attachment"
                                    >
                                      <FiExternalLink className="p-mx-2" />
                                    </a>
                                  </label>
                                </SatArtworkAttachment>
                              );
                            })}
                        </AttachmentTypeBox>
                      );
                    })}
                </AttachmentTypeContent>
              </SatArtworkFiles>
              <ArtworkGrid
                sat={sat}
                selected={selected}
                ref={artworkGridRef}
                setHasGridChanged={setHasGridChanged}
              />
            </Container>
          </Form>
        )}
        {dataLoading && <Loading />}
      </PageTabContainer>
    );
  },
);

export default Artwork;
