import { useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { number, object } from 'yup';
import Button from '../../../../../../components/Button';
import FormAsyncSelect from '../../../../../../components/FormAsyncSelect';
import FormInputNumber from '../../../../../../components/FormInputNumber';
import Loading from '../../../../../../components/Loading';

import MainButton from '../../../../../../components/MainButton';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import {
  FinancialPurchasePaymentType,
  FinancialSellerPaymentType,
} from '../../../../../../shared/enums/domains';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { asyncSelectLoadAllSatForeignTradeSupplier } from '../../../../../../shared/querys/satForeignTradeSupplier';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../../../utils/getValidationErrors';
import { SatForeignTradeSupplier } from '../../interfaces';
import { createSatForeignTradePaymentQuery } from '../../queries';
import { Buttons, Container, Row } from './styles';
import { SatStatus } from '../../../../../../components/SatStatusTag';
import { ISat } from '../../../interfaces';

interface IAddRollingModal {
  sat: ISat;
  paymentType: FinancialPurchasePaymentType | FinancialSellerPaymentType;
  satForeignTradeSupplier: SatForeignTradeSupplier;
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
  refetchForeignTradePaymentsData(): void;
}

const AddRollingModal: React.FC<IAddRollingModal> = ({
  sat,
  paymentType,
  satForeignTradeSupplier,
  setDisplayModal,
  refetchForeignTradePaymentsData,
}) => {
  const formRef = useRef<FormHandles>(null);

  const { toastRef } = useRefHook();

  const [pageLoading, setPageLoading] = useState(false);

  const showToast = useCallback(
    (severity: string, summary: string, life: number, detail?: string) => {
      toastRef.current?.show({ summary, detail, severity, life });
    },
    [toastRef],
  );

  const showSuccess = useCallback(
    (summary: string, detail?: string) => {
      showToast('success', summary, ToastLife.SUCCESS, detail);
    },
    [showToast],
  );

  const showError = useCallback(
    (summary: string, detail?: string) => {
      showToast('error', summary, ToastLife.ERROR, detail);
    },
    [showToast],
  );

  const validateForm = useCallback(
    async (data: any) => {
      formRef.current?.setErrors({});
      const schema = object().shape({
        amount: number().nullable().required(requiredFieldErrorMessage),
        satForeignTradeNumber: object()
          .nullable()
          .required(requiredFieldErrorMessage),
      });

      try {
        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        const firstError = error.inner[0];
        const inputWithError = formRef.current?.getFieldRef(firstError.path);

        if (inputWithError.focus) {
          inputWithError.focus();
        } else if (inputWithError.inputRef?.current?.focus) {
          inputWithError.inputRef?.current?.focus();
        }

        showError('Please fill all the required fields');
        return false;
      }
    },
    [showError],
  );

  const [createSatForeignTradePaymentMutation] = useMutation(
    createSatForeignTradePaymentQuery,
  );

  const savePayment = useCallback(
    async (data: any) => {
      setPageLoading(true);
      try {
        await createSatForeignTradePaymentMutation({
          variables: {
            data: {
              idSat: satForeignTradeSupplier.idSat,
              idSatForeignTrade: satForeignTradeSupplier.idSatForeignTrade,
              idSatForeignTradeSupplier:
                satForeignTradeSupplier.idSatForeignTradeSupplier,
              idType: paymentType,
              amount: data.amount,
              paymentDate: new Date(),
              idSatDestination: data.satForeignTradeNumber.idSat,
              idSatForeignTradeDestination:
                data.satForeignTradeNumber.idSatForeignTrade,
              idSatForeignTradeSupplierDestination:
                data.satForeignTradeNumber.idSatForeignTradeSupplier,
            },
          },
        });
        setPageLoading(false);
        refetchForeignTradePaymentsData();
        setDisplayModal(false);
        showSuccess(`Sat Foreign Trade Payment created`);
      } catch (error) {
        setPageLoading(false);
        showError(
          `Error while creating Sat Foreign Trade Payment`,
          error.message,
        );
      }
    },
    [
      refetchForeignTradePaymentsData,
      setDisplayModal,
      showSuccess,
      createSatForeignTradePaymentMutation,
      satForeignTradeSupplier.idSat,
      satForeignTradeSupplier.idSatForeignTrade,
      satForeignTradeSupplier.idSatForeignTradeSupplier,
      paymentType,
      showError,
    ],
  );

  async function handleSubmit() {
    const data = formRef.current?.getData();
    const isFormValid = await validateForm(data);
    if (isFormValid) {
      savePayment(data);
    }
  }

  function handleCancel() {
    setDisplayModal(false);
  }

  return (
    <Container>
      <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
        <Row className="p-d-flex p-flex-wrap">
          <FormInputNumber
            className="p-col-12"
            name="amount"
            label="Rolling Amount"
            decimalScale={2}
            thousandSeparator="."
            decimalSeparator=","
            required
          />
          <FormAsyncSelect
            className="p-col-12"
            name="satForeignTradeNumber"
            label="SAT Number / Foreign Trade"
            loadOptions={asyncSelectLoadAllSatForeignTradeSupplier}
            getOptionLabel={(option: any) =>
              option.satForeignTradeSupplierNumber
            }
            getOptionValue={(option: any) => option}
            noOptionsMessage={() => 'No option found'}
            additional={
              paymentType === FinancialPurchasePaymentType.ROLLING
                ? {
                    idSupplier: satForeignTradeSupplier.idSupplier,
                    satStatus: SatStatus.ACTIVE,
                    notToConsiderId:
                      satForeignTradeSupplier.idSatForeignTradeSupplier,
                  }
                : {
                    idClient: sat.idClient,
                    satStatus: SatStatus.ACTIVE,
                    notToConsiderId:
                      satForeignTradeSupplier.idSatForeignTradeSupplier,
                  }
            }
            required
          />
        </Row>
        <Buttons
          className="p-mt-3"
          style={{ display: 'flex', placeContent: 'end' }}
        >
          <MainButton
            type="button"
            label="Confirm"
            className="p-mx-2"
            onClick={handleSubmit}
          />
          <Button
            type="button"
            label="Cancel"
            onClick={() => handleCancel()}
            className="p-button-danger"
          />
        </Buttons>
      </Form>
      {pageLoading && <Loading />}
    </Container>
  );
};
export default AddRollingModal;
