import { useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { useState } from 'react';
import Button from '../../../../../../components/Button';
import Loading from '../../../../../../components/Loading';
import MainButton from '../../../../../../components/MainButton';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { ISat } from '../../../interfaces';
import { processArtworkUpdateMissingInfoQuery } from './queries';

interface IArtworkProductInfoForAwUpdateMissingInfoDialogProps {
  statusProcessRefech: () => Promise<void>;
  sat: ISat;
  idSatProcessResult: number;
  displayDialog: boolean;
  setDisplayDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const ArtworkProductInfoForAwUpdateMissingInfoDialog: React.FC<IArtworkProductInfoForAwUpdateMissingInfoDialogProps> =
  ({
    sat,
    displayDialog,
    statusProcessRefech,
    idSatProcessResult,
    setDisplayDialog,
  }) => {
    const [loading, setLoading] = useState(false);

    const { showError, showSuccess } = useRefHook();

    const [comment, setComment] = useState<string>();

    const [processArtworkUpdateMissingInfoMutation] = useMutation(
      processArtworkUpdateMissingInfoQuery,
    );

    const handleSave = async () => {
      setLoading(true);
      try {
        const data = {
          idSat: sat.idSat,
          idSatProcessResult,
          description: comment,
        };

        await processArtworkUpdateMissingInfoMutation({
          variables: { data },
        });

        await statusProcessRefech();
        showSuccess({ summary: 'Product Info For AW Updated Missing Info' });
      } catch (error) {
        showError({
          summary: 'Error while updating Product Info For AW Missing Info',
          detail: error.message,
        });
      } finally {
        setLoading(false);
        setDisplayDialog(false);
      }
    };

    return (
      <Dialog
        header="Artwork - Product Info for AW - Update Missing Info"
        visible={displayDialog}
        style={{ width: '30vw' }}
        onHide={() => setDisplayDialog(false)}
      >
        <InputTextarea
          style={{ boxShadow: 'none' }}
          className="w-full"
          rows={5}
          value={comment}
          onChange={e => setComment(e.target.value)}
          autoResize
        />
        <div style={{ display: 'flex', placeContent: 'end' }}>
          {loading && <Loading />}
          <MainButton
            type="button"
            label="Save"
            className="p-my-2"
            onClick={() => handleSave()}
            disabled={!comment || loading}
          />
          <Button
            type="button"
            label="Cancel"
            className="p-button-danger p-my-2 p-ml-2"
            onClick={() => setDisplayDialog(false)}
          />
        </div>
      </Dialog>
    );
  };

export default ArtworkProductInfoForAwUpdateMissingInfoDialog;
