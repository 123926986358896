/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
import { confirmDialog } from 'primereact/confirmdialog';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SplitButton } from 'primereact/splitbutton';
import { Dialog } from 'primereact/dialog';
import { ValidationError, array, number, object, string } from 'yup';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import PageHeader from '../../../components/PageHeader';
import Image from '../../../components/PageHeader/Image';
import imagePlaceholder from '../../../assets/imagePlaceholder.svg';
import { Container, CrudForm } from './styles';
import { useRefHook } from '../../../hooks/useRefHook';
import PageMenuButton from '../../../components/PageMenuButton';
import GeneralInformation from './GeneralInformation';
import Artwork from './Artwork';
import Attachments from './Attachments';
import Packages from './Packages';
import Pricing from './Pricing';
import ForeignTrade from './ForeignTrade';
import Branding from './Branding';
import Dimension from './Dimension';
import PriceHistory from './PriceHistory';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Tag from '../../../components/Tag';
import Loading from '../../../components/Loading';
import Checklists from './Checklists';
import Sats from './Sats';
import {
  IChecklistItem,
  ICreateProductFormData,
  IProduct,
  IProductChecklist,
  ISelectCategory,
  ProductRouteParams,
} from './interfaces';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../utils/getValidationErrors';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { productRoles } from '../../../shared/roles/product';
import { useAuth } from '../../../hooks/useAuth';
import PageMenu from '../../../components/PageMenu';
import userHasPermission from '../../../utils/userHasPermission';
import { IBulkCheckListResponse } from '../Products/interfaces';
import { ProductStatus } from './enums';
import useTitle from '../../../hooks/useTitle';
import {
  createCheckListBulkQuery,
  createProductQuery,
  duplicateProductQuery,
  getAttachmentDownloadLinkQuery,
  listProductQuery,
  updateProductQuery,
} from './queries';
import MutationResponseSummary, {
  IMutationResponse,
  IMutationResponseSummaryRef,
} from '../../../components/MutationResponseSummary';
import { IProductPackagesRef } from './Packages/interfaces';
import { DefaultProductPackageProvider } from './hooks/useDefaultProductPackage';
import { AttachmentsRef } from './Attachments/interfaces';
import {
  FileUploadRef,
  FileUploadResponse,
} from '../../../components/FileUpload/interfaces';
import FileUpload from '../../../components/FileUpload';
import { FileType } from '../../../shared/enums/fileType';

const Product: React.FC = () => {
  const { roles } = useAuth();

  const history = useHistory();

  const { pathname, search } = useLocation();
  const location = useLocation();

  const [createCheckListBulkMutation] = useMutation(createCheckListBulkQuery);
  const [createProductMutation] = useMutation(createProductQuery);
  const [updateProductMutation] = useMutation(updateProductQuery);
  const [duplicateProductMutation] = useMutation(duplicateProductQuery);

  const productId = parseInt(useParams<ProductRouteParams>().productId, 10);

  const mutationResponseSummaryRef = useRef<IMutationResponseSummaryRef>(null);
  const packagesRef = useRef<IProductPackagesRef>(null);
  const attachmentsRef = useRef<AttachmentsRef>(null);
  const { showError, showSuccess, showWarn, formRef } = useRefHook();

  const urlTabParam = new URLSearchParams(search).get('tab');

  const [bulkCheckListResponse, setBulkCheckListResponse] =
    useState<IBulkCheckListResponse>();
  const [checklistItems, setChecklistItems] = useState<IChecklistItem[]>([]);
  const [displayDialogBulk, setDisplayDialogBulk] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<IProduct>({} as IProduct);
  const [productName, setProductName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<ISelectCategory>();
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    urlTabParam ?? 'GeneralInformation',
  );
  const [uploadImageTempUrl, setUploadImageTempUrl] = useState('');
  const [versionLock, setVersionLock] = useState<number>();
  const [saveProductMutationResponse, setSaveProductMutationResponse] =
    useState<IMutationResponse[]>([]);

  const defaultPageTitle = `${productId ? 'Manage' : 'Create'} Product`;

  useTitle(
    product?.stCode
      ? `${product.stCode} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // ID da entity de usuario
  const { idEntity } = productRoles;

  // Permissoes tela de produto
  const { idPermissionDuplicateProduct, idPermissionMassiveActionChecklist } =
    productRoles.permissions;

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  // Valida se usuario pode criar ou atualizar Produto
  const userCanChange = userHasPermission(
    productId
      ? productRoles.permissions.idPermissionUpdateProduct
      : productRoles.permissions.idPermissionCreateProduct,
    userPermissions.userPermissions,
  );

  // Dados para retornar para listagem de produtos
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Products',
      path:
        initialPage && initialFirst
          ? `/products/list?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/products/list',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  /**
   * Busca dados do Produto
   */
  const [loadProductData, { loading: productLoading, data: productData }] =
    useLazyQuery(listProductQuery, {
      errorPolicy: 'all',
      onCompleted: response => {
        if (response.listProductById) {
          setProduct(response.listProductById);
          setProductName(response.listProductById.stCode);
          setImageUrl(response.listProductById.imageUrl);
          setSelectedCategory({
            idCategory: response.listProductById.idCategory,
            idCheckList: response.listProductById.idCategory2?.idCheckList,
            indConfection: response.listProductById.idCategory2?.indConfection,
          });
          setChecklistItems(
            response.listProductById.productCheckLists?.map(
              (item: IProductChecklist) => {
                return { ...item.idCheckListItem2, value: item.value };
              },
            ),
          );
          setVersionLock(response.listProductById.versionLock);
        } else {
          showError({ summary: 'Product not found' });
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting product',
          detail: errorData.message,
        });
      },
    });

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/products/list'),
    });
  }

  async function validateGeneralInformation() {
    try {
      // Esvazia possíveis erros já existentes no formulário
      formRef.current?.setErrors({});

      // Define requisitos de preenchimento do formulario
      const schema = object().shape({
        netWeight: number().nullable().required(requiredFieldErrorMessage),
        nameEn: string().required(requiredFieldErrorMessage),
        materialEn: string().required(requiredFieldErrorMessage),
        colourEn: !product.indConfection
          ? string().required(requiredFieldErrorMessage)
          : string().nullable().notRequired(),
        idCategory: string().nullable().required(requiredFieldErrorMessage),
        idLicensor: product.licensedProduct
          ? number().nullable().required(requiredFieldErrorMessage)
          : number().nullable().notRequired(),
        royaltiesLicenesed: product.licensedProduct
          ? number()
              .typeError('Royalties must be a number')
              .required(requiredFieldErrorMessage)
          : number().nullable().notRequired(),
        idClient: product.exclusivity
          ? string().required(requiredFieldErrorMessage)
          : string().nullable().notRequired(),
        productSuppliers: array()
          .of(
            object().shape({
              idSupplier: string()
                .nullable()
                .required(requiredFieldErrorMessage),
              dtAging: string().required(requiredFieldErrorMessage),
              sunPrice: string().nullable().required(requiredFieldErrorMessage),
              idSalesIncoterm: number()
                .nullable()
                .required(requiredFieldErrorMessage),
              idSalesCurrency: number()
                .nullable()
                .required(requiredFieldErrorMessage),
              idIncoterm: number().when('cotPrice', {
                is: null,
                then: number().nullable().notRequired(),
                otherwise: number()
                  .nullable()
                  .required(requiredFieldErrorMessage),
              }),
              idPurchaseCurrency: number()
                .nullable()
                .when('cotPrice', {
                  is: null,
                  then: number().nullable().notRequired(),
                  otherwise: number()
                    .nullable()
                    .required(requiredFieldErrorMessage),
                }),
            }),
          )
          .compact(v => !v.isDefault)
          .min(1, 'At least one pricing must be default'),
        productNcms: array().of(
          object().shape({
            idNcm: string().nullable().required(requiredFieldErrorMessage),
          }),
        ),
        productArtworkTechniques: array().of(
          object().shape({
            namePcPt:
              product.productArtworkTechniques?.length > 1
                ? string().required(requiredFieldErrorMessage)
                : string().nullable().notRequired(),
            namePcEn:
              product.productArtworkTechniques?.length > 1
                ? string().required(requiredFieldErrorMessage)
                : string().nullable().notRequired(),
          }),
        ),
        productCheckListNames: array().of(
          object().shape({
            nameEn:
              product.productCheckListNames?.length > 1
                ? string().required(requiredFieldErrorMessage)
                : string().nullable().notRequired(),
            namePt:
              product.productCheckListNames?.length > 1
                ? string().required(requiredFieldErrorMessage)
                : string().nullable().notRequired(),
          }),
        ),
      });

      const formData = formRef.current?.getData();

      // Efetua validação
      await schema.validate(formData, { abortEarly: false });
    } catch (error) {
      // Verifica se são erros de validação
      if (error instanceof ValidationError) {
        // Pega os erros de cada input
        const errors = getValidationErrors(error);

        // Define os erros para cada input
        formRef.current?.setErrors(errors);

        // Busca primeiro erro da lista
        const firstError = error.inner[0];

        // Define guia selecionada de acordo com input com erro
        switch (true) {
          case firstError.path?.includes('nameEn'):
          case firstError.path?.includes('materialEn'):
          case firstError.path === 'colourEn':
          case firstError.path === 'netWeight':
            setSelectedMenuItem('GeneralInformation');
            break;
          case firstError.path?.includes('productPackages'):
          case firstError.path?.includes('productPackageReferences'):
            setSelectedMenuItem('Packages');
            break;
          case firstError.path?.includes('organName'):
            setSelectedMenuItem('ForeignTrade');
            break;
          case firstError.path?.includes('productSuppliers'):
            setSelectedMenuItem('Pricing');
            break;
          case firstError.path?.includes('productArtworkTechniques'):
            setSelectedMenuItem('Artwork');
            break;
          default:
            break;
        }

        // Busca ref do input com erro
        const inputWithError = formRef.current?.getFieldRef(firstError.path!);

        if (inputWithError) {
          // Define foco no input
          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef?.current?.focus) {
            inputWithError.inputRef?.current?.focus();
          }

          showWarn({
            summary: 'Enter all required fields',
            detail: `${error.errors.length} fields with errors`,
          });
        } else {
          showWarn({
            summary: error.message,
          });
        }
      }

      throw error;
    }
  }

  async function validateForms() {
    await validateGeneralInformation();
    await packagesRef.current?.validateForm();
  }

  async function handleCreateGeneralInformation() {
    const productCheckListNames = product.productCheckListNames?.map(pcln => {
      if (typeof pcln.idProductCheckListName === 'string') {
        return {
          ...pcln,
          idProductCheckListName: undefined,
          productCheckLists: pcln.productCheckLists.map(pcl => {
            return {
              ...pcl,
              idCheckListItem2: undefined,
            };
          }),
        };
      }
      return pcln;
    });

    const formData = formRef.current?.getData() as ICreateProductFormData;

    // Transforma objeto de product ncms para o backend
    const parsedProductNcms = formData.productNcms?.map((productNcm, index) => {
      return {
        idProductNcm:
          typeof product.productNcms[index].idProductNcm === 'number'
            ? product.productNcms[index].idProductNcm
            : 0,
        idNcm: productNcm.idNcm,
        idClient: productNcm.idClient,
        foreignTradeReview:
          productNcm.foreignTradeReview !== true &&
          productNcm.foreignTradeReview !== false
            ? null
            : productNcm.foreignTradeReview,
      };
    });

    // Chamada criacao de produto
    const response = await createProductMutation({
      variables: {
        product: {
          verified: formData.verified,
          idCategory: formData.idCategory ? formData.idCategory : undefined,
          idCheckList: selectedCategory?.idCheckList,
          netWeight: formData.netWeight,
          licensedProduct: product.licensedProduct,
          stock: product.stock,
          model: formData.model,
          isM2: product.isM2,
          ownerLicenesed: formData.ownerLicenesed,
          exclusivity: product.exclusivity,
          sku: formData.sku,
          m2Pc: formData.m2Pc,
          royaltiesLicenesed: formData.royaltiesLicenesed,
          idClient: formData.idClient ? formData.idClient : null,
          nameEn: formData.nameEn,
          idFinishing: formData.idFinishing ? formData.idFinishing : null,
          materialEn: formData.materialEn,
          extraInfoEn: formData.extraInfoEn,
          colourEn: formData.colourEn,
          descriptionEn: formData.descriptionEn,
          namePt: formData.namePt,
          detailedDescriptionPt: formData.detailedDescriptionPt,
          materialPt: formData.materialPt,
          colourPt: formData.colourPt,
          extraInfoPt: formData.extraInfoPt,
          descriptionPt: formData.descriptionPt,
          productDimensions: formData.productDimensions,
          productSuppliers: formData.productSuppliers,
          productClients: formData.productClients,
          freightValue: formData.freightValue,
          insurancePer: formData.insurancePer,
          thc: formData.thc,
          iiPer: formData.iiPer,
          ipiPer: formData.ipiPer,
          pisPer: formData.pisPer,
          cofinsPer: formData.cofinsPer,
          siscomex: formData.siscomex,
          icmsPer: formData.icmsPer,
          dumpingDutyValue: formData.dumpingDutyValue,
          blRelease: formData.blRelease,
          blDeconsolidation: formData.blDeconsolidation,
          dropFee: formData.dropFee,
          isps: formData.isps,
          trs: formData.trs,
          containerLifting: formData.containerLifting,
          internalFreight: formData.internalFreight,
          dispatcherFees: formData.dispatcherFees,
          consultingNdiPer: formData.consultingNdiPer,
          freightCurrency: formData.freightCurrency,
          dumpingDutyCurrency: formData.dumpingDutyCurrency,
          idimage: product.idimage,
          hsCode: formData.hsCode,
          registerNumber: formData.registerNumber,
          validityDate: formData.validityDate,
          unitsPerContainer: formData.unitsPerContainer,
          productCheckListNames,
          foreignTradeReview: product.foreignTradeReview,
          productNcms: parsedProductNcms,
          idCertifyingEntity: formData.idCertifyingEntity
            ? formData.idCertifyingEntity
            : null,
          idLicensor: formData.idLicensor ? formData.idLicensor : null,
          indConfection: product.indConfection,
          reference: formData.reference ? formData.reference : null,
          productArtworkTechniques: formData.productArtworkTechniques,
        },
      },
    });

    return response;
  }

  async function handleUpdateGeneralInformation(): Promise<
    IMutationResponse | undefined
  > {
    const formData = formRef.current?.getData() as ICreateProductFormData;

    const productCheckListNames = product.productCheckListNames?.map(pcln => {
      if (typeof pcln.idProductCheckListName === 'string') {
        return {
          ...pcln,
          idProductCheckListName: undefined,
          productCheckLists: pcln.productCheckLists.map(pcl => {
            return {
              ...pcl,
              idCheckListItem2: undefined,
            };
          }),
        };
      }
      return pcln;
    });

    // Transforma objeto de product dimensions para o backend
    const parsedProductDimensions = formData.productDimensions?.map(
      (productDimension, index) => {
        return {
          ...productDimension,
          idProductDimension:
            typeof product.productDimensions[index].idProductDimension ===
            'number'
              ? product.productDimensions[index].idProductDimension
              : undefined,
        };
      },
    );

    // Transforma objeto de product suppliers para o backend
    const parsedProductSuppliers = formData.productSuppliers?.map(
      (productSupplier, index) => {
        return {
          ...productSupplier,
          idProductSupplier:
            typeof product.productSuppliers[index].idProductSupplier ===
            'number'
              ? product.productSuppliers[index].idProductSupplier
              : undefined,
        };
      },
    );

    // Transforma objeto de product ncms para o backend
    const parsedProductNcms = formData.productNcms?.map((productNcm, index) => {
      return {
        idProductNcm:
          typeof product.productNcms[index].idProductNcm === 'number'
            ? product.productNcms[index].idProductNcm
            : 0,
        idNcm: productNcm.idNcm,
        idClient: productNcm.idClient,
        foreignTradeReview:
          productNcm.foreignTradeReview !== true &&
          productNcm.foreignTradeReview !== false &&
          productNcm.foreignTradeReview !== undefined
            ? null
            : productNcm.foreignTradeReview,
      };
    });

    // Transforma objeto de product ArtworkTechinique para o backend
    const parsedProductArtworkTechniques =
      formData.productArtworkTechniques?.map(
        (productArtworkTechnique, index) => {
          return {
            ...productArtworkTechnique,
            idProductArtwTech:
              typeof product.productArtworkTechniques[index]
                .idProductArtwTech === 'number'
                ? product.productArtworkTechniques[index].idProductArtwTech
                : undefined,
          };
        },
      );

    try {
      await updateProductMutation({
        variables: {
          product: {
            idProduct: productId,
            versionLock,
            verified: formData.verified,
            idCheckList: selectedCategory?.idCheckList,
            idCategory: formData.idCategory ? formData.idCategory : undefined,
            netWeight: formData.netWeight,
            licensedProduct: product.licensedProduct,
            stock: product.stock,
            model: formData.model,
            isM2: product.isM2,
            ownerLicenesed: formData.ownerLicenesed,
            exclusivity: product.exclusivity,
            sku: formData.sku,
            m2Pc: formData.m2Pc,
            royaltiesLicenesed: formData.royaltiesLicenesed,
            idClient: formData.idClient ? formData.idClient : null,
            nameEn: formData.nameEn,
            idFinishing: formData.idFinishing ? formData.idFinishing : null,
            materialEn: formData.materialEn,
            extraInfoEn: formData.extraInfoEn,
            colourEn: formData.colourEn,
            descriptionEn: formData.descriptionEn,
            namePt: formData.namePt,
            detailedDescriptionPt: formData.detailedDescriptionPt,
            materialPt: formData.materialPt,
            colourPt: formData.colourPt,
            extraInfoPt: formData.extraInfoPt,
            descriptionPt: formData.descriptionPt,
            productDimensions: parsedProductDimensions,
            productSuppliers: parsedProductSuppliers,
            productClients: formData.productClients,
            freightValue: formData.freightValue,
            insurancePer: formData.insurancePer,
            thc: formData.thc,
            iiPer: formData.iiPer,
            ipiPer: formData.ipiPer,
            pisPer: formData.pisPer,
            cofinsPer: formData.cofinsPer,
            siscomex: formData.siscomex,
            icmsPer: formData.icmsPer,
            dumpingDutyValue: formData.dumpingDutyValue,
            blRelease: formData.blRelease,
            blDeconsolidation: formData.blDeconsolidation,
            dropFee: formData.dropFee,
            isps: formData.isps,
            trs: formData.trs,
            containerLifting: formData.containerLifting,
            internalFreight: formData.internalFreight,
            dispatcherFees: formData.dispatcherFees,
            consultingNdiPer: formData.consultingNdiPer,
            freightCurrency: formData.freightCurrency,
            dumpingDutyCurrency: formData.dumpingDutyCurrency,
            idStatus: formData.inactived
              ? ProductStatus.INACTIVED
              : ProductStatus.ACTIVED,
            idimage: product.idimage,
            hsCode: formData.hsCode,
            registerNumber: formData.registerNumber,
            validityDate: formData.validityDate,
            unitsPerContainer: formData.unitsPerContainer,
            productCheckListNames,
            foreignTradeReview: product.foreignTradeReview,
            productNcms: parsedProductNcms,
            idCertifyingEntity: formData.idCertifyingEntity
              ? formData.idCertifyingEntity
              : null,
            idLicensor: formData.idLicensor ? formData.idLicensor : null,
            indConfection: product.indConfection,
            reference: formData.reference ? formData.reference : null,
            productArtworkTechniques: parsedProductArtworkTechniques,
          },
        },
      });

      return undefined;
    } catch (error) {
      return {
        moduleChanged: 'General Information/Product',
        errors: [error.message],
      };
    }
  }

  async function handleSaveProduct() {
    setLoading(true);

    try {
      if (productId) {
        const mutationsResponses: IMutationResponse[] = [];

        await validateForms();

        const updateProductResponse = await handleUpdateGeneralInformation();

        if (updateProductResponse) {
          mutationsResponses.push(updateProductResponse);
        }

        const updatePackageResponse = await packagesRef.current?.submitForm();

        if (updatePackageResponse) {
          mutationsResponses.push(updatePackageResponse);
        }

        const updateAttachmentResponse =
          await attachmentsRef.current?.handleUpdateAttachments();

        if (updateAttachmentResponse) {
          mutationsResponses.push(updateAttachmentResponse);
        }

        setProduct({} as IProduct);
        await loadProductData({
          variables: {
            idProduct: productId,
          },
        });

        if (mutationsResponses.length) {
          setSaveProductMutationResponse(mutationsResponses);
          mutationResponseSummaryRef.current?.toggleDisplaySummary();
        }
      } else {
        await validateGeneralInformation();

        const response = await handleCreateGeneralInformation();

        showSuccess({
          summary: 'Product Created',
        });

        history.push(`/products/list/${response.data.createProduct.idProduct}`);
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        return;
      }

      showError({
        summary: 'Error while saving product',
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  }
  /**
   * Duplica Produto
   * @param idProduct ID de product
   */
  async function handleDuplicateProduct(idProduct: number) {
    setLoading(true);
    try {
      const response = await duplicateProductMutation({
        variables: {
          idProduct,
        },
      });

      showSuccess({
        summary: 'Duplicated successfully',
        detail: (
          <>
            <p>New Product: </p>
            <Link
              to={`/products/list/${response.data.duplicateProduct.idProduct}`}
              style={{ textDecoration: 'none', color: 'unset' }}
            >
              {response.data.duplicateProduct.stCode}
            </Link>
          </>
        ),
      });
    } catch (error) {
      showError({
        summary: 'Error while duplicating product',
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  const changePropertyValue = (property: string, value: any) => {
    setProduct(prevProduct => ({
      ...prevProduct,
      [property]: value,
    }));
  };

  function validateAccessToImportantInformation(): boolean {
    if (!selectedCategory?.idCategory) {
      showError({ summary: 'You need to select a category first' });
      return false;
    }
    if (!selectedCategory?.idCheckList) {
      showError({
        summary: 'Selected category does not have an important information',
      });
      return false;
    }
    return true;
  }

  const inputImportBulkRef = useRef<FileUploadRef>(null);

  // Carrega arquivo
  const [getBulkTemplateLink, { loading: bulkTemplateLoading }] = useLazyQuery(
    getAttachmentDownloadLinkQuery,
    {
      variables: {
        name: `MASSIVE_ACTION_CHECKLIST_${selectedCategory?.idCheckList}.xlsx`,
      },
      errorPolicy: 'all',
      onCompleted: async response => {
        window.open(response.getAttachmentDownloadLink, '_blank');
      },
      onError: error => {
        showError({
          summary: 'Error while getting template',
          detail: error.message,
        });
      },
    },
  );

  // Itens do botao bulk
  const importBulkButtonItems = [
    {
      label: 'Create/Update',
      command: () => {
        inputImportBulkRef.current?.choose();
      },
    },
    {
      label: 'Download template',
      command: () => {
        getBulkTemplateLink();
      },
    },
  ];

  async function handleImportBulkUpload(e: FileUploadResponse[]) {
    try {
      setLoading(true);

      const response = await createCheckListBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkCheckListResponse({
        ...response.data.createProductCheckListBulk,
      });
    } catch (error) {
      showError({
        summary: 'Error while creating products',
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  // A cada alteracao na bulkResponse, exibe o dialog com os dados
  useEffect(() => {
    if (bulkCheckListResponse) {
      setDisplayDialogBulk(true);
    }
  }, [bulkCheckListResponse]);

  useEffect(() => {
    if (productId) {
      loadProductData({
        variables: {
          idProduct: productId,
        },
      });
    }

    return () => setProduct({} as IProduct);
  }, [loadProductData, productId]);

  return (
    <Container>
      <FileUpload
        ref={inputImportBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleImportBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />

      <Dialog
        header="Create/Update Response"
        visible={displayDialogBulk}
        style={{ width: '50vw' }}
        modal
        onHide={() => setDisplayDialogBulk(false)}
      >
        <p>
          <b>Product CheckList Names found in the spreadsheet:</b>{' '}
          {bulkCheckListResponse?.productCheckListNamesTotal}
        </p>
        <p>
          <b>Product CheckList Names Created:</b>{' '}
          {bulkCheckListResponse?.createdProductCheckListNames}
        </p>
        <p>
          <b>Product CheckList Names Updated:</b>{' '}
          {bulkCheckListResponse?.updatedProductCheckListNames}
        </p>
        {!!bulkCheckListResponse?.productCheckListNamesWithError?.length && (
          <p>
            <b>Product CheckList Names with error:</b>
            <br />
            {bulkCheckListResponse?.productCheckListNamesWithError.map(
              error => (
                <p>{error}</p>
              ),
            )}
          </p>
        )}
        {!!bulkCheckListResponse?.productCheckListWithError?.length && (
          <p>
            <b>Product CheckList with error:</b>
            <br />
            {bulkCheckListResponse?.productCheckListWithError.map(error => (
              <p>{error}</p>
            ))}
          </p>
        )}
      </Dialog>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          productId ? `${defaultPageTitle} - ${productName}` : defaultPageTitle
        }
        minScrollStickyButtons={160}
        hasImage
      >
        {productData ? (
          product.idStatus === ProductStatus.ACTIVED ? (
            <Tag className="tag" value="Active" severity="success" />
          ) : (
            <Tag className="tag" value="Inactive" severity="danger" />
          )
        ) : undefined}

        {!!productId && product.indConfection && (
          <Tag className="tag" value="Fashion Line" severity="warn" />
        )}

        {!!productId && product.taxmissing && (
          <Tag className="tag" value="Tax Missing" />
        )}

        {!!productId && product.outdated && (
          <Tag className="tag" value="Outdated" severity="info" />
        )}

        {!!productId && product.verified && (
          <Tag className="tag" value="Verified" severity="success" />
        )}

        <Image
          className="image"
          src={
            !uploadImageTempUrl
              ? imageUrl || imagePlaceholder
              : uploadImageTempUrl
          }
          alt="Product main image"
        />
        {product.createdAt && !productLoading && (
          <p className="createdAt">
            {`${new Date(product.createdAt).toLocaleString()} by `}
            {`${
              product.createdBy2?.firstName ? product.createdBy2?.firstName : ''
            } ${
              product.createdBy2?.lastName ? product.createdBy2?.lastName : ''
            }`}
          </p>
        )}
        {product.updatedAt && !productLoading && (
          <p className="updatedAt">
            {` ${new Date(product.updatedAt).toLocaleString()} by `}
            {` ${
              product.updatedBy2?.firstName ? product.updatedBy2?.firstName : ''
            } ${
              product.updatedBy2?.lastName ? product.updatedBy2?.lastName : ''
            } `}
          </p>
        )}

        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => handleSaveProduct()}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />

        {userHasPermission(
          idPermissionDuplicateProduct,
          userPermissions.userPermissions,
        ) && (
          <Button
            className="secondaryButton"
            label="Duplicate"
            onClick={() => {
              confirmDialog({
                message: 'Duplicate product?',
                header: 'Duplicate Product',
                icon: 'pi pi-question-circle',
                accept: () => handleDuplicateProduct(product.idProduct),
              });
            }}
            disabled={!productId}
          />
        )}

        {userHasPermission(
          idPermissionMassiveActionChecklist,
          userPermissions.userPermissions,
        ) &&
          selectedMenuItem === 'Checklist' && (
            <SplitButton
              label="Massive Action"
              className="massive-action-button"
              model={importBulkButtonItems}
              icon={bulkTemplateLoading ? 'pi pi-spin pi-spinner' : undefined}
              onClick={() => inputImportBulkRef.current?.choose()}
            />
          )}
      </PageHeader>

      <MutationResponseSummary
        ref={mutationResponseSummaryRef}
        header="Save Product Summary"
        data={saveProductMutationResponse}
      />

      {((productId && !productLoading && product.idStatus !== undefined) ||
        !productId) && (
        <CrudForm>
          <PageMenu menuWidth="195px">
            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('GeneralInformation');
              }}
              selected={selectedMenuItem === 'GeneralInformation'}
              title="General Information"
            />

            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('Dimension');
              }}
              selected={selectedMenuItem === 'Dimension'}
              title="Dimension"
            />

            {!!productId && (
              <PageMenuButton
                onClick={() => {
                  setSelectedMenuItem('Packages');
                }}
                selected={selectedMenuItem === 'Packages'}
                title="Packages"
              />
            )}

            {product.indConfection && (
              <PageMenuButton
                onClick={() => {
                  setSelectedMenuItem('Artwork');
                }}
                selected={selectedMenuItem === 'Artwork'}
                title="Artwork / Technique"
              />
            )}

            <PageMenuButton
              onClick={() => {
                if (validateAccessToImportantInformation()) {
                  setSelectedMenuItem('Checklist');
                }
              }}
              selected={selectedMenuItem === 'Checklist'}
              title="Important Information"
            />

            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('Pricing');
              }}
              selected={selectedMenuItem === 'Pricing'}
              title="Pricing"
            />

            {!!productId && (
              <PageMenuButton
                onClick={() => {
                  setSelectedMenuItem('PriceHistory');
                }}
                selected={selectedMenuItem === 'PriceHistory'}
                title="Price History"
              />
            )}

            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('ForeignTrade');
              }}
              selected={selectedMenuItem === 'ForeignTrade'}
              title="Foreign Trade"
            />

            {!!productId && (
              <>
                <PageMenuButton
                  onClick={() => {
                    setSelectedMenuItem('Branding');
                  }}
                  selected={selectedMenuItem === 'Branding'}
                  title="Branding"
                />
                <PageMenuButton
                  onClick={() => {
                    setSelectedMenuItem('Attachments');
                  }}
                  selected={selectedMenuItem === 'Attachments'}
                  title="Attachments"
                />
                <PageMenuButton
                  onClick={() => {
                    setSelectedMenuItem('SATs');
                  }}
                  selected={selectedMenuItem === 'SATs'}
                  title="SATs"
                />
              </>
            )}
          </PageMenu>

          <DefaultProductPackageProvider>
            {!!productId && (
              <Packages
                ref={packagesRef}
                selected={selectedMenuItem === 'Packages'}
                idProduct={product?.idProduct}
                indConfection={product?.indConfection}
                stCode={product.stCode}
                pageMenuItemKey="Packages"
                setSelectedMenuItem={setSelectedMenuItem}
                roleEntityFields={userPermissions.userFields}
                userCanChangeFields={userCanChange}
              />
            )}
            <Form
              ref={formRef}
              initialData={product}
              onSubmit={() => handleSaveProduct()}
            >
              <GeneralInformation
                selected={selectedMenuItem === 'GeneralInformation'}
                product={product}
                changePropertyValue={changePropertyValue}
                setSelectedCategory={setSelectedCategory}
                setChecklistItems={setChecklistItems}
                selectedCategory={selectedCategory}
                roleEntityFields={userPermissions.userFields}
                userCanChangeFields={userCanChange}
              />

              <Dimension
                selected={selectedMenuItem === 'Dimension'}
                product={product}
                changePropertyValue={changePropertyValue}
                roleEntityFields={userPermissions.userFields}
                userCanChangeFields={userCanChange}
              />
              {product.indConfection && (
                <Artwork
                  selected={selectedMenuItem === 'Artwork'}
                  product={product}
                  changePropertyValue={changePropertyValue}
                  roleEntityFields={userPermissions.userFields}
                  userCanChangeFields={userCanChange}
                />
              )}

              {!!productId && (
                <Attachments
                  ref={attachmentsRef}
                  selected={selectedMenuItem === 'Attachments'}
                  idProduct={product.idProduct}
                  indConfection={product.indConfection}
                  changePropertyValue={changePropertyValue}
                  idMainImage={product.idimage}
                  setUploadImageTempUrl={setUploadImageTempUrl}
                  userCanChangeFields={userCanChange}
                />
              )}

              <Checklists
                selected={selectedMenuItem === 'Checklist'}
                product={product}
                changePropertyValue={changePropertyValue}
                checklistItems={checklistItems}
                setChecklistItems={setChecklistItems}
                selectedCategory={selectedCategory}
                bulkCheckListResponse={bulkCheckListResponse}
                userCanChangeFields={userCanChange}
              />

              <ForeignTrade
                selected={selectedMenuItem === 'ForeignTrade'}
                product={product}
                changePropertyValue={changePropertyValue}
                roleEntityFields={userPermissions.userFields}
                userPermissions={userPermissions.userPermissions}
                userCanChangeFields={userCanChange}
              />

              {!!productId && (
                <Branding
                  selected={selectedMenuItem === 'Branding'}
                  idProduct={productId}
                  userCanChangeFields={userCanChange}
                />
              )}

              <Pricing
                selected={selectedMenuItem === 'Pricing'}
                product={product}
                changePropertyValue={changePropertyValue}
                roleEntityFields={userPermissions.userFields}
                userCanChangeFields={userCanChange}
              />

              {!!productId && (
                <PriceHistory
                  selected={selectedMenuItem === 'PriceHistory'}
                  idProduct={productId}
                  roleEntityFields={userPermissions.userFields}
                />
              )}

              {!!productId && (
                <Sats
                  selected={selectedMenuItem === 'SATs'}
                  idProduct={productId}
                  roleEntityFields={userPermissions.userFields}
                />
              )}
            </Form>
          </DefaultProductPackageProvider>
        </CrudForm>
      )}
      {(productLoading || loading) && <Loading />}
    </Container>
  );
};

export default Product;
