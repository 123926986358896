/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';
import Input from '../../components/Input';
import MainButton from '../../components/MainButton';
import logoSat from '../../assets/logo.svg';
import getValidationErrors from '../../utils/getValidationErrors';
import ToastLife from '../../shared/enums/toastLife';
import useTitle from '../../hooks/useTitle';

interface IForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  useTitle('Forgot Password');

  const [loading, setLoading] = useState(false);

  // Ref do toast
  const toast = useRef<Toast>(null);

  // Referencia ao formulario
  const formRef = useRef<FormHandles>(null);

  // Mutation de sessão
  const forgotPasswordQuery = gql`
    mutation ForgotPassword($email: String!) {
      forgotPassword(email: $email)
    }
  `;

  // cria método para chamar a mutation
  const [forgotPasswordMutation] = useMutation(forgotPasswordQuery);

  // Submit do formulario
  const handleSubmit = useCallback(
    async (data: IForgotPasswordFormData) => {
      setLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          email: Yup.string().email().required('Enter an email'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Chamada recuperação de senha
        await forgotPasswordMutation({
          variables: { email: data.email },
        });

        toast.current?.show({
          severity: 'success',
          summary: 'Recovery email sent',
          detail: 'Check your inbox',
          life: ToastLife.SUCCESS,
        });
        // history.push('/');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toast.current?.show({
            severity: 'error',
            summary: 'Password recovery error',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [forgotPasswordMutation],
  );

  return (
    <>
      <Toast ref={toast} />
      <div>
        <img src={logoSat} alt="SAT" />
        <Card style={{ height: '315px' }}>
          <h3>Password recovery</h3>
          {/* Formulario de reset */}
          <Form ref={formRef} onSubmit={handleSubmit}>
            {/* Email */}
            <div className="p-field">
              <label htmlFor="email1" className="p-d-block">
                Email
              </label>
              <Input
                name="email"
                id="email1"
                aria-describedby="email1-help"
                className="p-d-block"
              />
            </div>

            {/* Botão submit */}
            <MainButton type="submit" label="Recover" loading={loading} />

            {/* Recuperar senha */}
            <Link to="/">Back to login</Link>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default ForgotPassword;
