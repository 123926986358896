import { IDomain } from '../../../../interfaces/IDomain';
import { IFieldPermissionResponse } from '../../../../utils/getFieldPermission';

/* eslint-disable no-shadow */
export enum ForeignTradeSupplierReducerActionKind {
  SET_FOREIGN_TRADE_SUPPLIERS,
  SET_LAZY_PARAMS,
  RESET_STATE,
  SET_ACTIVE_TAB_INDEX,
}

export type ForeignTradeSupplierLazyParams = {
  first: number;
  rows: number;
  page: number;
};

type Domain = {
  idDomain: number;
  description: string;
};

type Supplier = {
  sunNumber: string;
  name: string;
};

type SatForeignTrade = {
  idImportStatus2: Domain;
  realInspectionDate?: string;
  realEtd?: string;
  realEta?: string;
  shipmentStatus?: string;
};

export type SatForeignTradeSupplier = {
  idSatForeignTradeSupplier: number;
  idSat: number;
  idSatForeignTrade: number;
  satForeignTradeSupplierNumber?: string;
  idStatusPurchasePayment?: number;
  idStatusPurchasePayment2?: Domain;
  idSupplier?: Supplier;
  idSupplier2?: Supplier;
  idStatusSellingPayment?: number;
  idStatusSellingPayment2?: Domain;
  exchangeRate?: number;
  idSatForeignTrade2: SatForeignTrade;
  totalCotCiShipment?: number;
  estimatedAdvance?: number;
  estimatedBalance?: number;
  estimatedAdvanceRequest?: Date;
  estimatedBalanceRequest?: Date;
  estimatedTermCondition?: number;
  estimatedTermCondition2?: IDomain;
  sellerEstimatedAdvanceRequest: number;
  sellerEstimatedBalanceRequest: number;
  sellerEstimatedTermCondition?: number;
  sellerEstimatedTermCondition2?: IDomain;
  totalSunCiShipment?: number;
  advancePaymentRequest?: string;
  balancePaymentRequest?: string;
  totalCotPiShipment: number;
  totalSunPiShipment: number;
};

type SatForeignTradeSupplierReducerObject = {
  data: SatForeignTradeSupplier[];
  items: number;
};

export type ForeignTradeSupplierReducer = {
  foreignTradeSuppliers?: SatForeignTradeSupplierReducerObject;
  tabActiveIndex: number;
  lazyParams: ForeignTradeSupplierLazyParams;
};

export type ForeignTradeSupplierReducerAction = {
  type: ForeignTradeSupplierReducerActionKind;
  payload?: {
    foreignTradeSuppliers?: SatForeignTradeSupplierReducerObject;
    tabActiveIndex?: number;
    lazyParams?: ForeignTradeSupplierLazyParams;
  };
};

export type FinancialFormData = {
  idSatForeignTradeSupplier: number;
  idStatusPurchasePayment: number;
};

export type UpdateSatForeignTradeSuppliersResponse = {
  warnings: string[];
  errors: string[];
};

export type FinancialFieldsPermissions = {
  idStatusPurchasePayment: IFieldPermissionResponse;
  sunNumber: IFieldPermissionResponse;
  supplierName: IFieldPermissionResponse;
  idStatusSellingPayment: IFieldPermissionResponse;
  exchangeRate: IFieldPermissionResponse;
  idImportStatus: IFieldPermissionResponse;
  realInspectionDate: IFieldPermissionResponse;
  realEtd: IFieldPermissionResponse;
  realEta: IFieldPermissionResponse;
  purchasePiCurrency: IFieldPermissionResponse;
  totalCotPiShipment: IFieldPermissionResponse;
  totalConvertedCotPi: IFieldPermissionResponse;
  purchaseCiCurrency: IFieldPermissionResponse;
  totalCotCiShipment: IFieldPermissionResponse;
  totalConvertedCotCi: IFieldPermissionResponse;
  estimatedPaymentAdvance: IFieldPermissionResponse;
  estimatedPaymentTotalAdvance: IFieldPermissionResponse;
  estimatedPaymentAdvanceRequest: IFieldPermissionResponse;
  estimatedPaymentBalance: IFieldPermissionResponse;
  estimatedPaymentTotalBalance: IFieldPermissionResponse;
  estimatedPaymentBalanceRequest: IFieldPermissionResponse;
  estimatedPaymentTermCondition: IFieldPermissionResponse;
  estimatedReceiveAdvance: IFieldPermissionResponse;
  estimatedReceiveTotalAdvance: IFieldPermissionResponse;
  estimatedReceiveAdvanceRequest: IFieldPermissionResponse;
  estimatedReceiveBalance: IFieldPermissionResponse;
  estimatedReceiveTotalBalance: IFieldPermissionResponse;
  estimatedReceiveBalanceRequest: IFieldPermissionResponse;
  estimatedReceiveTermCondition: IFieldPermissionResponse;
  sellerPiCurrency: IFieldPermissionResponse;
  totalSunPiShipment: IFieldPermissionResponse;
  totalConvertedSunPi: IFieldPermissionResponse;
  sellerCiCurrency: IFieldPermissionResponse;
  totalSunCiShipment: IFieldPermissionResponse;
  totalConvertedSunCi: IFieldPermissionResponse;
  totalWithApportionment: IFieldPermissionResponse;
};

export type FinancialFieldsCommonWithSatFieldPermissions = {
  idClientIncoterm: IFieldPermissionResponse;
  companyName: IFieldPermissionResponse;
  importerName: IFieldPermissionResponse;
  purchaseIncotermDescription: IFieldPermissionResponse;
  mainProduct: IFieldPermissionResponse;
  countryOriginName: IFieldPermissionResponse;
};

export type UserCanResultsBlock = {
  [key: string]: boolean;
  userCanSeeResultsBlock: boolean;
  userCanSeeResultsConvertedBlock: boolean;
  userCanSeeResultsGrossBlock: boolean;
  userCanSeeResultsOtherExpensesBlock: boolean;
  userCanSeeResultsNetBlock: boolean;
  userCanSeeResultsStatusBlock: boolean;
};

export type UserCanObject = {
  userCanSeePurchaseBlock: boolean;
  userCanSeeSellerBlock: boolean;
  userCanSeePurchasePiBlock: boolean;
  userCanSeePurchaseCiBlock: boolean;
  userCanSeeEstimatedPaymentPI: boolean;
  userCanSeeEstimatedReceive: boolean;
  userCanSeeSellerPiBlock: boolean;
  userCanSeeSellerCiBlock: boolean;
  userCanSeePurchaseOtherExpensesBlock: boolean;
  userCanSeeSellerOtherExpensesBlock: boolean;
  userCanSeePurchaseOtherExpensesTotalBlock: boolean;
  userCanAddPurchaseOtherExpenses: boolean;
  userCanEditPurchaseOtherExpenses: boolean;
  userCanDeletePurchaseOtherExpenses: boolean;
  userCanAddSellerOtherExpenses: boolean;
  userCanEditSellerOtherExpenses: boolean;
  userCanSeeSellerOtherExpensesTotalBlock: boolean;
  userCanDeleteSellerOtherExpenses: boolean;
  userCanSeeTotalPaid: boolean;
  userCanSeeTotalReceived: boolean;
  userCanResultsBlock: UserCanResultsBlock;
  userCanSeeUsersPurchase: boolean;
  userCanSeeUsersSeller: boolean;
  userCanAccessApportionment: boolean;
};

export type SatFinancialSellerOtherExpenseFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  idType: IFieldPermissionResponse;
  idCurrency: IFieldPermissionResponse;
  total: IFieldPermissionResponse;
  dateOtherExpense: IFieldPermissionResponse;
  linkToMargin: IFieldPermissionResponse;
  creditToClient: IFieldPermissionResponse;
  apportionment: IFieldPermissionResponse;
  calculationBasis: IFieldPermissionResponse;
  comment: IFieldPermissionResponse;
};

export type SatFinancialPurchaseOtherExpenseFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  idType: IFieldPermissionResponse;
  idCurrency: IFieldPermissionResponse;
  total: IFieldPermissionResponse;
  dateOtherExpense: IFieldPermissionResponse;
  linkToMargin: IFieldPermissionResponse;
  discountFromSupplier: IFieldPermissionResponse;
  payToSupplier: IFieldPermissionResponse;
  comment: IFieldPermissionResponse;
};

export type SatFinancialAdvancePaidFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  percentage: IFieldPermissionResponse;
  amount: IFieldPermissionResponse;
  paymentDate: IFieldPermissionResponse;
  rollingFrom: IFieldPermissionResponse;
  rollingTo: IFieldPermissionResponse;
  shipment: IFieldPermissionResponse;
};

export type SatFinancialAdvanceReceivedFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  percentage: IFieldPermissionResponse;
  amount: IFieldPermissionResponse;
  paymentDate: IFieldPermissionResponse;
  rollingFrom: IFieldPermissionResponse;
  rollingTo: IFieldPermissionResponse;
  shipment: IFieldPermissionResponse;
};

export type SatFinancialAdvancePaidPermissions = {
  addItem: boolean;
  addRolling: boolean;
  updateItem: boolean;
  removeItem: boolean;
  requestPayment: boolean;
};

export type SatFinancialAdvanceReceivedPermissions = {
  addItem: boolean;
  addRolling: boolean;
  updateItem: boolean;
  removeItem: boolean;
};

export type SatFinancialBalanceFieldsPermissions = {
  percentage: IFieldPermissionResponse;
  amount: IFieldPermissionResponse;
  paymentDate: IFieldPermissionResponse;
  shipment: IFieldPermissionResponse;
};

export type SatFinancialBalancePermissions = {
  addItem: boolean;
  updateItem: boolean;
  removeItem: boolean;
};

export type SatFinancialTotalFieldsPermissions = {
  total: IFieldPermissionResponse;
  totalRemain: IFieldPermissionResponse;
};

export type SatFinancialPurchaseOtherExpensesTotalFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  totalOtherExpenses: IFieldPermissionResponse;
  totalOnMargin: IFieldPermissionResponse;
  totalOutOfMargin: IFieldPermissionResponse;
  totalDiscountFromSupplier: IFieldPermissionResponse;
  totalPayToSupplier: IFieldPermissionResponse;
};

export type SatFinancialSellerOtherExpensesTotalFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  totalOtherExpenses: IFieldPermissionResponse;
  totalOnMargin: IFieldPermissionResponse;
  totalOutOfMargin: IFieldPermissionResponse;
  totalCreditToClient: IFieldPermissionResponse;
  totalForApportionment: IFieldPermissionResponse;
};

export type SatFinancialFilesPermissions = {
  upload: boolean;
  delete: boolean;
};

export type SatFinancialUsersPurchaseFieldsPermissions = {
  idPurchaseUser: IFieldPermissionResponse;
  idComexExportUser: IFieldPermissionResponse;
  idFinancialUser: IFieldPermissionResponse;
  notes: IFieldPermissionResponse;
};

export type SatFinancialUsersSellerFieldsPermissions = {
  idSalesUser: IFieldPermissionResponse;
  idSalesSecondUser: IFieldPermissionResponse;
  idComexImportUser: IFieldPermissionResponse;
  sellerNotes: IFieldPermissionResponse;
};

export type SatFinancialResultsPermissions = {
  mainFieldsPermissions: SatFinancialResultsFieldsPermissions;
  convertedFieldsPermissions: SatFinancialResultsConvertedFieldsPermissions;
  grossFieldsPermissions: SatFinancialResultsGrossFieldsPermissions;
  otherExpensesFieldsPermissions: SatFinancialResultsOtherExpensesFieldsPermissions;
  netFieldsPermissions: SatFinancialResultsNetFieldsPermissions;
  statusFieldsPermissions: SatFinancialResultsStatusFieldsPermissions;
};

type SatFinancialResultsOtherExpensesFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  purchaseTotalOtherExpenses: IFieldPermissionResponse;
  purchaseTotalOnMargin: IFieldPermissionResponse;
  purchaseTotalOutOfMargin: IFieldPermissionResponse;
  sellerTotalOtherExpenses: IFieldPermissionResponse;
  sellerTotalOnMargin: IFieldPermissionResponse;
  sellerTotalOutOfMargin: IFieldPermissionResponse;
  sellerTotalForApportionment: IFieldPermissionResponse;
};

type SatFinancialResultsFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  totalCotPiShipment: IFieldPermissionResponse;
  totalCotCiShipment: IFieldPermissionResponse;
  totalSunPiShipment: IFieldPermissionResponse;
  totalSunCiShipment: IFieldPermissionResponse;
  totalWithApportionment: IFieldPermissionResponse;
};

type SatFinancialResultsConvertedFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  totalConvertedCotPi: IFieldPermissionResponse;
  totalConvertedCotCi: IFieldPermissionResponse;
  totalConvertedSunPi: IFieldPermissionResponse;
  totalConvertedSunCi: IFieldPermissionResponse;
};

type SatFinancialResultsGrossFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  estimatedGrossMargin: IFieldPermissionResponse;
  grossMargin: IFieldPermissionResponse;
  estimatedGrossProfit: IFieldPermissionResponse;
  grossProfit: IFieldPermissionResponse;
};

type SatFinancialResultsNetFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  estimatedNetMargin: IFieldPermissionResponse;
  netMargin: IFieldPermissionResponse;
  estimatedNetProfit: IFieldPermissionResponse;
  netProfit: IFieldPermissionResponse;
};

type SatFinancialResultsStatusFieldsPermissions = {
  [key: string]: IFieldPermissionResponse;
  shipmentStatus: IFieldPermissionResponse;
};
