import { useLazyQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import React, {
  Dispatch,
  Ref,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Form } from '@unform/web';
import { number, object, string } from 'yup';
import { IFileType } from '../../../../../components/FilesByTypes/interfaces';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { ForeignTradeStatus } from '../../../../../shared/enums/domains';
import ToastLife from '../../../../../shared/enums/toastLife';
import {
  ForeignTradeFieldsCommonWithSatFieldsPermissions,
  ISat,
} from '../../interfaces';
import { IAvaillableSatItemsModalRef } from '../AvailableSatItemsModal';
import {
  ForeignTradeContainerItemsPermissions,
  ForeignTradeFieldsPermissions,
  ForeignTradeReducer,
  ForeignTradeReducerAction,
  IUserCanObjects,
  SatForeignTrade,
  SatForeignTradeFormData,
} from '../interfaces';
import Consolidation from './Consolidation';
import ContainerItems, { IContainerItemsRef } from './ContainerItems';
import Files from './Files';
import ForeignTrade from './ForeignTrade';
import {
  IForeignTradeContentRef,
  SatForeignTradeShipmentContainer,
} from './interfaces';
import OriginalDocs from './OriginalDocs';
import Production from './Production';
import {
  listSatForeignTradeContainersQuery,
  listSatForeignTradeQuery,
} from './queries';
import Schedule from './Schedule';
import Shipment from './Shipment';
import ShipmentContainers from './ShipmentContainers';

import { Container } from './styles';
import Timing from './Timing';
import { IUserFieldsAndPermissionsResponse } from '../../../../../utils/getUserFieldsAndPermissionsByEntity';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../../utils/getValidationErrors';
import { objectsAreEqual } from '../../../../../utils/objectsAreEqual';
import { ISatFinancialRef } from '../../Financial';
import SatContainers from './SatContainers';
import Users from './Users';
import { ISatForeignTradeRef } from '..';

interface IForeignTradeContentProps {
  idSatForeignTrade: number;
  ref: Ref<IForeignTradeContentRef>;
  selected: boolean;
  sat: ISat;
  fieldsPermissions: ForeignTradeFieldsPermissions;
  containerItemsPermissions: ForeignTradeContainerItemsPermissions;
  commonWithSatFieldsPermissions: ForeignTradeFieldsCommonWithSatFieldsPermissions;
  foreignTradesDispatch: Dispatch<ForeignTradeReducerAction>;
  foreignTradesState: ForeignTradeReducer;
  addForeignTradeItemModalRef: RefObject<IAvaillableSatItemsModalRef>;
  containerItemsRef: RefObject<IContainerItemsRef>;
  userCanObjects: IUserCanObjects;
  foreignTradeFileTypesData: IFileType[];
  foreignTradeFileTypesLoading: boolean;
  isSatCancelled: boolean;
  userPermissionsSat: IUserFieldsAndPermissionsResponse;
  financialRef: React.RefObject<ISatFinancialRef>;
  foreignTradeRef: React.RefObject<ISatForeignTradeRef>;
}

const ForeignTradeContent: React.FC<IForeignTradeContentProps> =
  React.forwardRef(
    (
      {
        idSatForeignTrade,
        sat,
        fieldsPermissions,
        commonWithSatFieldsPermissions,
        selected,
        foreignTradesDispatch,
        foreignTradesState,
        containerItemsPermissions,
        addForeignTradeItemModalRef,
        containerItemsRef,
        userCanObjects,
        foreignTradeFileTypesData,
        foreignTradeFileTypesLoading,
        isSatCancelled,
        userPermissionsSat,
        financialRef,
        foreignTradeRef,
      },
      ref,
    ) => {
      const { toastRef } = useRefHook();

      const formRef = useRef<FormHandles>(null);

      const [foreignTrade, setForeignTrade] = useState<SatForeignTrade>();

      const [shipmentContainers, setShipmentContainers] =
        useState<SatForeignTradeShipmentContainer[]>();

      const shipmentIsCanceled =
        foreignTrade?.idImportStatus2?.idDomain ===
          ForeignTradeStatus.CANCELED || isSatCancelled;

      const [loadForeignTradeData, { loading: foreignTradeLoading }] =
        useLazyQuery(listSatForeignTradeQuery(fieldsPermissions), {
          variables: {
            idSatForeignTrade,
          },
          onCompleted: response => {
            setForeignTrade(response.listSatForeignTradeById);
          },
          onError: errorData => {
            toastRef.current?.show({
              severity: 'error',
              summary: 'Error while getting Foreign Trade data',
              detail: errorData.message,
              life: ToastLife.ERROR,
            });
          },
        });

      const [
        loadShipmentContainersData,
        {
          loading: shipmentContainersLoading,
          refetch: shipmentContainersRefetch,
        },
      ] = useLazyQuery(listSatForeignTradeContainersQuery, {
        variables: {
          idSatForeignTrade,
        },
        onCompleted: response => {
          if (response.listSatForeignTradeContainersBySatForeignTradeId) {
            setShipmentContainers(
              response.listSatForeignTradeContainersBySatForeignTradeId,
            );
          }
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting Foreign Trade Shipment Containers',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      });

      function onSubmit(formData: SatForeignTradeFormData) {
        if (objectsAreEqual(formData, foreignTrade)) return undefined;

        return formData;
      }

      useImperativeHandle(ref, () => ({
        validateForm: async () => {
          const formData = formRef.current?.getData();

          if (!formData) return false;

          const schema = object().shape({
            realEtd: string().required(requiredFieldErrorMessage),
            realEta: string().required(requiredFieldErrorMessage),
            realArrivalAtClient: string().required(requiredFieldErrorMessage),
            estimatedInspectionDate: string().required(
              requiredFieldErrorMessage,
            ),
            estimatedShipmentDate: string().required(requiredFieldErrorMessage),
            estimatedArrivalDate: string().required(requiredFieldErrorMessage),
            estimatedArrivalClientDate: string().required(
              requiredFieldErrorMessage,
            ),
            idLoadingPort: number()
              .nullable()
              .required(requiredFieldErrorMessage),
            idPort: number().nullable().required(requiredFieldErrorMessage),
            idPurchaseUser: number()
              .nullable()
              .required(requiredFieldErrorMessage),
            idSalesUser: number()
              .nullable()
              .required(requiredFieldErrorMessage),
          });

          try {
            formRef.current?.setErrors({});

            await schema.validate(formData, { abortEarly: false });

            return true;
          } catch (error) {
            const errors = getValidationErrors(error);

            formRef.current?.setErrors(errors);

            const firstError = error.inner[0];

            const inputWithError = formRef.current?.getFieldRef(
              firstError.path,
            );

            if (inputWithError.focus) {
              inputWithError.focus();
            } else if (inputWithError.inputRef?.current?.focus) {
              inputWithError.inputRef?.current?.focus();
            }

            throw error;
          }
        },
        getDataIfChanged: () => {
          const formData =
            formRef.current?.getData() as SatForeignTradeFormData;

          return onSubmit(formData);
        },
        checkIfNoReportFieldIsSelected: () => {
          const currentState = formRef.current?.getFieldValue('noReport');

          return (
            currentState === true && currentState !== foreignTrade?.noReport
          );
        },
        updateWhenNoReportIsSelected: () => {
          formRef.current?.clearField('realInspectionDate');

          const formData =
            formRef.current?.getData() as SatForeignTradeFormData;

          formData.satForeignTradeSuppliers?.forEach((_, index) => {
            formRef.current?.clearField(
              `satForeignTradeSuppliers[${index}].inspectionScheduled`,
            );
            formRef.current?.clearField(
              `satForeignTradeSuppliers[${index}].inspectionApproval`,
            );
          });
        },
      }));

      useEffect(() => {
        if (!shipmentContainers && selected) {
          loadShipmentContainersData();
        }
      }, [loadShipmentContainersData, selected, shipmentContainers]);

      useEffect(() => {
        if (!foreignTrade && selected) {
          loadForeignTradeData();
        }
      }, [loadForeignTradeData, selected, foreignTrade]);

      return (
        <>
          {foreignTrade && (
            <Container>
              <Form
                ref={formRef}
                onSubmit={data => onSubmit(data)}
                initialData={foreignTrade}
              >
                <Schedule
                  foreignTrade={foreignTrade}
                  fieldsPermissions={fieldsPermissions}
                  shipmentIsCanceled={shipmentIsCanceled}
                  commonWithSatFieldsPermissions={
                    commonWithSatFieldsPermissions
                  }
                  formRef={formRef}
                  sat={sat}
                  userPermissionsSat={userPermissionsSat}
                  foreignTradeRef={foreignTradeRef}
                />

                <Production
                  formRef={formRef}
                  satForeignTradeSuppliers={
                    foreignTrade.satForeignTradeSuppliers
                  }
                  fieldsPermissions={fieldsPermissions}
                  shipmentIsCanceled={shipmentIsCanceled}
                  shipmentAuthorized={foreignTrade.shipmentAuthorized}
                  noReport={foreignTrade.noReport}
                />

                <SatContainers
                  idSatForeignTrade={foreignTrade.idSatForeignTrade}
                  selected={selected}
                  satCanceled={isSatCancelled}
                />

                {userCanObjects.userCanSeeShipmentBlock && (
                  <Shipment
                    foreignTrade={foreignTrade}
                    fieldsPermissions={fieldsPermissions}
                    shipmentIsCanceled={shipmentIsCanceled}
                    sat={sat}
                    foreignTradeRef={foreignTradeRef}
                    userPermissionsSat={userPermissionsSat}
                    consolidationData={foreignTradesState.consolidationData}
                  />
                )}

                <Users
                  foreignTrade={foreignTrade}
                  shipmentIsCanceled={shipmentIsCanceled}
                  fieldsPermissions={fieldsPermissions}
                />

                <Consolidation
                  satForeignTrade={foreignTrade}
                  selected={selected}
                  shipmentIsCanceled={shipmentIsCanceled}
                  foreignTradesDispatch={foreignTradesDispatch}
                />

                {userCanObjects.userCanSeeOriginalDocsBlock && (
                  <OriginalDocs
                    foreignTrade={foreignTrade}
                    shipmentIsCanceled={shipmentIsCanceled}
                    fieldsPermissions={fieldsPermissions}
                  />
                )}

                {userCanObjects.userCanSeeForeignTradeBlock && (
                  <ForeignTrade
                    foreignTrade={foreignTrade}
                    fieldsPermissions={fieldsPermissions}
                    shipmentIsCanceled={shipmentIsCanceled}
                  />
                )}

                <Files
                  idSat={sat.idSat}
                  idSatForeignTrade={idSatForeignTrade}
                  foreignTradeFileTypesData={foreignTradeFileTypesData}
                  foreignTradeFileTypesLoading={foreignTradeFileTypesLoading}
                  selected={selected}
                  userCanDeleteFiles={userCanObjects.userCanDeleteFiles}
                  userCanUploadFiles={userCanObjects.userCanUploadFiles}
                  shipmentIsCanceled={shipmentIsCanceled}
                />
              </Form>

              {userCanObjects.userCanSeeTimingBlock && (
                <Timing
                  foreignTrade={foreignTrade}
                  confirmOrderComercial={sat.confirmOrderComercial}
                  fieldsPermissions={fieldsPermissions}
                />
              )}

              {userCanObjects.userCanSeeShipmentContainersBlock && (
                <ShipmentContainers
                  idSatForeignTrade={idSatForeignTrade}
                  selected={selected}
                  shipmentContainers={shipmentContainers}
                  shipmentContainersRefetch={shipmentContainersRefetch}
                  shipmentContainersLoading={shipmentContainersLoading}
                  shipmentIsCanceled={shipmentIsCanceled}
                />
              )}
            </Container>
          )}

          {foreignTradeLoading && (
            <>
              <Skeleton width="10rem" height="2.8rem" />

              {[...Array(4)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="p-d-flex p-mt-4" key={i}>
                  <Skeleton className="p-mr-3" height="3rem" width="16%" />
                  <Skeleton className="p-mr-3" height="3rem" width="16%" />
                  <Skeleton className="p-mr-3" height="3rem" width="16%" />
                  <Skeleton className="p-mr-3" height="3rem" width="16%" />
                  <Skeleton className="p-mr-3" height="3rem" width="16%" />
                </div>
              ))}
            </>
          )}

          {userCanObjects.userCanSeeContainerItemsBlock && (
            <ContainerItems
              ref={containerItemsRef}
              idSatForeignTrade={idSatForeignTrade}
              selected={selected}
              shipmentContainers={shipmentContainers}
              shipmentContainersLoading={shipmentContainersLoading}
              foreignTradesDispatch={foreignTradesDispatch}
              foreignTradesState={foreignTradesState}
              containerItemsPermissions={containerItemsPermissions}
              userCanDeleteContainerItem={
                userCanObjects.userCanDeleteContainerItem
              }
              addForeignTradeItemModalRef={addForeignTradeItemModalRef}
              userCanAddContainerItem={userCanObjects.userCanAddContainerItem}
              shipmentIsCanceled={shipmentIsCanceled}
              financialRef={financialRef}
              indConfection={sat.indConfection}
            />
          )}
        </>
      );
    },
  );

export default ForeignTradeContent;
