import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  .p-datatable {
    // Permite definir largura fixa para as colunas da Grid
    thead th,
    tbody td {
      flex: auto;
    }
  }

  .sunButton {
    display: flex;
    background: none;
    border: none;
    color: var(--blue);
    transition: color 0.2s;

    svg {
      margin-left: 13px;
    }

    &:hover {
      color: ${shade(0.2, '#4C85C8')};
    }
  }
`;
