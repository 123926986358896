import { lighten, shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  & + & {
    margin-top: 30px;
  }

  // Define a estilização do icone do componente FileUpload quando houver a prop chooseOptions
  .grid-file-upload .p-fileupload-choose:focus {
    outline: none;
    box-shadow: none;
  }

  .new-quotation-button {
    margin-top: 34px;
  }

  .prc-quotation-file-column {
    display: flex;
  }

  .file-log-div {
    margin-top: -3px;
    position: absolute;
    margin-left: 60px;
  }

  .grid-file-upload {
    .p-button {
      border: none;
      height: 100%;

      &:hover {
        background-color: unset !important;
      }
    }
  }

  a {
    display: flex;

    text-decoration: none;
    transition: color 0.2s;
    color: var(--blue);
    align-items: center;

    svg {
      margin-left: 12px;
    }
    &:hover {
      color: ${lighten(0.1, '#4C85C8')};
    }
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

export const GridActions = styled.div`
  display: flex;
  button {
    background: none;
    border: none;
    color: #7f7f80;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.4, '#7f7f80')};
    }

    svg {
      margin: 0 8px;
    }
  }

  .trash-button {
    color: var(--red);

    &:hover {
      color: ${shade(0.2, '#FD2D2D')};
    }
  }
`;
