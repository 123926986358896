import React, { useEffect, useState } from 'react';

import { FaTimes } from 'react-icons/fa';
import { CheckboxChangeParams } from 'primereact/checkbox';
import { Container, ArtworkItem } from './styles';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import FormInput from '../../../../components/FormInput';
import { productRoles } from '../../../../shared/roles/product';
import MainButton from '../../../../components/MainButton';
import { IProduct, IProductArtworkTechnique } from '../interfaces';
import generateRandomString from '../../../../utils/generateRandomString';
import { showField } from '../../../../utils/showField';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { definePermissionAndDisableField } from '../../../../utils/disableField';
import FormCheckbox from '../../../../components/FormCheckbox';
import { useDefaultProductPackage } from '../hooks/useDefaultProductPackage';

interface IArtworkProps extends PageTabContainerProps {
  product: IProduct;
  changePropertyValue(property: string, value: any): void;
  roleEntityFields: IRoleEntityField[];
  userCanChangeFields: boolean;
}

const Artwork: React.FC<IArtworkProps> = ({
  selected,
  product,
  changePropertyValue,
  roleEntityFields,
  userCanChangeFields,
}) => {
  const { getDefaultProductPackage } = useDefaultProductPackage();

  const [moreThanOneQtySellingPackage, setMoreThanOneQtySellingPackage] =
    useState<boolean>();

  function addArtworkItem() {
    let productArtworkTechniques: IProductArtworkTechnique[];
    if (product.productArtworkTechniques) {
      productArtworkTechniques = [
        ...product.productArtworkTechniques,
        {
          idProductArtwTech: generateRandomString(4),
        } as IProductArtworkTechnique,
      ];
    } else {
      productArtworkTechniques = [
        {
          idProductArtwTech: generateRandomString(4),
        } as IProductArtworkTechnique,
      ];
    }

    changePropertyValue('productArtworkTechniques', productArtworkTechniques);
  }

  function removeArtworkItem(index: number) {
    const productArtworkTechniques = [...product.productArtworkTechniques];
    productArtworkTechniques.splice(index, 1);
    changePropertyValue('productArtworkTechniques', productArtworkTechniques);
  }

  // Altera o valor dos checkbox para controlar os campos Code
  function changeValueCheckbox(
    name: string,
    e: CheckboxChangeParams,
    index: number,
  ): boolean {
    const newArtwork = [...product.productArtworkTechniques];
    newArtwork[index][name] = e.checked;

    changePropertyValue(`productArtworkTechniques`, newArtwork);
    return true;
  }

  /**
   * Adiciona item de Artwork automaticamente ao criar novo produto
   */
  useEffect(() => {
    if (!product.productArtworkTechniques) {
      const productArtworkTechniques = [
        {
          idProductArtwTech: generateRandomString(4),
        } as IProductArtworkTechnique,
      ];

      // Altera array atual pelo novo no objeto de productArtworkTechnique
      changePropertyValue('productArtworkTechniques', productArtworkTechniques);
    }
  }, [changePropertyValue, product.productArtworkTechniques]);

  useEffect(() => {
    async function getMoreThanOneQtySellingPackage() {
      if (product.productArtworkTechniques?.length && product.idProduct) {
        const defaultPackage = await getDefaultProductPackage(
          product.idProduct,
        );

        if (defaultPackage?.packagePcQuantity) {
          setMoreThanOneQtySellingPackage(defaultPackage.packagePcQuantity > 1);
        }
      } else {
        setMoreThanOneQtySellingPackage(true);
      }
    }

    if (selected && moreThanOneQtySellingPackage === undefined) {
      getMoreThanOneQtySellingPackage();
    }
  }, [
    getDefaultProductPackage,
    moreThanOneQtySellingPackage,
    product.idProduct,
    product.productArtworkTechniques?.length,
    selected,
  ]);

  return (
    <PageTabContainer selected={selected}>
      <Container>
        {product.productArtworkTechniques &&
          product.productArtworkTechniques.map(
            (productArtworkTechnique, index) => {
              return (
                <ArtworkItem key={productArtworkTechnique.idProductArtwTech}>
                  <div className="button-div">
                    <button
                      type="button"
                      onClick={() => removeArtworkItem(index)}
                    >
                      <FaTimes size={18} />
                    </button>
                  </div>
                  <div className="h2-split">
                    <h2>ARTWORKS</h2>
                  </div>
                  <div className="name-input-div">
                    {showField(
                      productRoles.fields.idFieldArtworkTechniqueNamePcEn,
                      roleEntityFields,
                    ) && (
                      <FormInput
                        name={`productArtworkTechniques[${index}].namePcEn`}
                        label="PC NAME (EN)"
                        readOnly={
                          definePermissionAndDisableField(
                            productRoles.fields.idFieldArtworkTechniqueNamePcEn,
                            roleEntityFields,
                            productArtworkTechnique.idProductArtwTech,
                          ) || !userCanChangeFields
                        }
                        required={moreThanOneQtySellingPackage}
                      />
                    )}

                    {showField(
                      productRoles.fields.idFieldArtworkTechniqueNamePcPt,
                      roleEntityFields,
                    ) && (
                      <FormInput
                        name={`productArtworkTechniques[${index}].namePcPt`}
                        label="PC NAME (PT)"
                        readOnly={
                          definePermissionAndDisableField(
                            productRoles.fields.idFieldArtworkTechniqueNamePcPt,
                            roleEntityFields,
                            productArtworkTechnique.idProductArtwTech,
                          ) || !userCanChangeFields
                        }
                        required={moreThanOneQtySellingPackage}
                      />
                    )}
                  </div>
                  <div className="inputs-div">
                    {showField(
                      productRoles.fields.idFieldArtworkTechniqueIndAopArtw,
                      roleEntityFields,
                    ) && (
                      <FormCheckbox
                        name={`productArtworkTechniques[${index}].indAopArtw`}
                        label="AOP"
                        className="p-my-2 activeCheckbox"
                        initialValue={productArtworkTechnique.indAopArtw}
                        onValueChange={e =>
                          changeValueCheckbox('indAopArtw', e, index)
                        }
                        readOnly={
                          definePermissionAndDisableField(
                            productRoles.fields
                              .idFieldArtworkTechniqueIndAopArtw,
                            roleEntityFields,
                            productArtworkTechnique.idProductArtwTech,
                          ) || !userCanChangeFields
                        }
                      />
                    )}
                    {showField(
                      productRoles.fields.idFieldArtworkTechniqueIndAopArtw,
                      roleEntityFields,
                    ) && <div className="h3-label">AOP</div>}

                    {showField(
                      productRoles.fields.idFieldArtworkTechniqueCodeAopArtw,
                      roleEntityFields,
                    ) && (
                      <FormInput
                        name={`productArtworkTechniques[${index}].codeAopArtw`}
                        label="Code"
                        readOnly={
                          !productArtworkTechnique.indAopArtw ||
                          definePermissionAndDisableField(
                            productRoles.fields
                              .idFieldArtworkTechniqueCodeAopArtw,
                            roleEntityFields,
                            productArtworkTechnique.idProductArtwTech,
                          ) ||
                          !userCanChangeFields
                        }
                      />
                    )}
                    {showField(
                      productRoles.fields
                        .idFieldArtworkTechniqueIndLocPrintArtw,
                      roleEntityFields,
                    ) && (
                      <FormCheckbox
                        name={`productArtworkTechniques[${index}].indLocPrintArtw`}
                        label="LOCAL PRINTING"
                        className="p-my-2 activeCheckbox"
                        initialValue={productArtworkTechnique.indLocPrintArtw}
                        onValueChange={e =>
                          changeValueCheckbox('indLocPrintArtw', e, index)
                        }
                        readOnly={
                          definePermissionAndDisableField(
                            productRoles.fields
                              .idFieldArtworkTechniqueIndLocPrintArtw,
                            roleEntityFields,
                            productArtworkTechnique.idProductArtwTech,
                          ) || !userCanChangeFields
                        }
                      />
                    )}
                    {showField(
                      productRoles.fields
                        .idFieldArtworkTechniqueIndLocPrintArtw,
                      roleEntityFields,
                    ) && <div className="h3-label">ESTAMPA LOCALIZADA</div>}
                    {showField(
                      productRoles.fields
                        .idFieldArtworkTechniqueCodeLocPrintArtw,
                      roleEntityFields,
                    ) && (
                      <FormInput
                        name={`productArtworkTechniques[${index}].codeLocPrintArtw`}
                        label="Code"
                        readOnly={
                          !productArtworkTechnique.indLocPrintArtw ||
                          definePermissionAndDisableField(
                            productRoles.fields
                              .idFieldArtworkTechniqueCodeLocPrintArtw,
                            roleEntityFields,
                            productArtworkTechnique.idProductArtwTech,
                          ) ||
                          !userCanChangeFields
                        }
                      />
                    )}
                    {showField(
                      productRoles.fields.idFieldArtworkTechniqueIndEmbPatArtw,
                      roleEntityFields,
                    ) && (
                      <FormCheckbox
                        name={`productArtworkTechniques[${index}].indEmbPatArtw`}
                        label="EMBROIDERY/PATCHWORK"
                        className="p-my-2 activeCheckbox"
                        initialValue={productArtworkTechnique.indEmbPatArtw}
                        onValueChange={e =>
                          changeValueCheckbox('indEmbPatArtw', e, index)
                        }
                        readOnly={
                          definePermissionAndDisableField(
                            productRoles.fields
                              .idFieldArtworkTechniqueIndEmbPatArtw,
                            roleEntityFields,
                            productArtworkTechnique.idProductArtwTech,
                          ) || !userCanChangeFields
                        }
                      />
                    )}
                    {showField(
                      productRoles.fields.idFieldArtworkTechniqueIndEmbPatArtw,
                      roleEntityFields,
                    ) && (
                      <div className="h3-label">BORDADO/APLIQUE BORDADO</div>
                    )}
                    {showField(
                      productRoles.fields.idFieldArtworkTechniqueCodEmbPatArtw,
                      roleEntityFields,
                    ) && (
                      <FormInput
                        name={`productArtworkTechniques[${index}].codEmbPatArtw`}
                        label="Code"
                        readOnly={
                          !productArtworkTechnique.indEmbPatArtw ||
                          definePermissionAndDisableField(
                            productRoles.fields
                              .idFieldArtworkTechniqueCodEmbPatArtw,
                            roleEntityFields,
                            productArtworkTechnique.idProductArtwTech,
                          ) ||
                          !userCanChangeFields
                        }
                      />
                    )}
                  </div>
                  <div className="h2-split">
                    <h2>TECHNIQUE</h2>
                  </div>
                  {showField(
                    productRoles.fields.idFieldArtworkTechniqueIndYarnDyeTech,
                    roleEntityFields,
                  ) && (
                    <div className="checkbox-div">
                      <div className="sub-item">
                        <FormCheckbox
                          name={`productArtworkTechniques[${index}].indYarnDyeTech`}
                          label="YARN DYE"
                          className="p-my-2 activeCheckbox"
                          initialValue={productArtworkTechnique.indYarnDyeTech}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields
                                .idFieldArtworkTechniqueIndYarnDyeTech,
                              roleEntityFields,
                              productArtworkTechnique.idProductArtwTech,
                            ) || !userCanChangeFields
                          }
                        />
                      </div>
                      <div className="h3-label">FIOS TINGIDOS</div>
                    </div>
                  )}
                  {showField(
                    productRoles.fields.idFieldArtworkTechniqueIndRotPrintTech,
                    roleEntityFields,
                  ) && (
                    <div className="checkbox-div">
                      <div className="sub-item">
                        <FormCheckbox
                          name={`productArtworkTechniques[${index}].indRotPrintTech`}
                          label="ROTATIVE PRINTING"
                          className="p-my-2 activeCheckbox"
                          initialValue={productArtworkTechnique.indRotPrintTech}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields
                                .idFieldArtworkTechniqueIndRotPrintTech,
                              roleEntityFields,
                              productArtworkTechnique.idProductArtwTech,
                            ) || !userCanChangeFields
                          }
                        />
                      </div>
                      <div className="h3-label">ESTAMPA ROTATIVA</div>
                    </div>
                  )}
                  {showField(
                    productRoles.fields
                      .idFieldArtworkTechniqueIndSilkScreenTech,
                    roleEntityFields,
                  ) && (
                    <div className="checkbox-div">
                      <div className="sub-item">
                        <FormCheckbox
                          name={`productArtworkTechniques[${index}].indSilkScreenTech`}
                          label="SILK SCREEN"
                          className="p-my-2 activeCheckbox"
                          initialValue={
                            productArtworkTechnique.indSilkScreenTech
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields
                                .idFieldArtworkTechniqueIndSilkScreenTech,
                              roleEntityFields,
                              productArtworkTechnique.idProductArtwTech,
                            ) || !userCanChangeFields
                          }
                        />
                      </div>
                      <div className="h3-label">SERIGRAFIA</div>
                    </div>
                  )}
                  {showField(
                    productRoles.fields.idFieldArtworkTechniqueIndJacquardTech,
                    roleEntityFields,
                  ) && (
                    <div className="checkbox-div">
                      <div className="sub-item">
                        <FormCheckbox
                          name={`productArtworkTechniques[${index}].indJacquardTech`}
                          label="JACQUARD"
                          className="p-my-2 activeCheckbox"
                          initialValue={productArtworkTechnique.indJacquardTech}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields
                                .idFieldArtworkTechniqueIndJacquardTech,
                              roleEntityFields,
                              productArtworkTechnique.idProductArtwTech,
                            ) || !userCanChangeFields
                          }
                        />
                      </div>
                      <div className="h3-label">JACQUARD</div>
                    </div>
                  )}
                  {showField(
                    productRoles.fields
                      .idFieldArtworkTechniqueIndSublimationTech,
                    roleEntityFields,
                  ) && (
                    <div className="checkbox-div">
                      <div className="sub-item">
                        <FormCheckbox
                          name={`productArtworkTechniques[${index}].indSublimationTech`}
                          label="SUBLIMATION"
                          className="p-my-2 activeCheckbox"
                          initialValue={
                            productArtworkTechnique.indSublimationTech
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields
                                .idFieldArtworkTechniqueIndSublimationTech,
                              roleEntityFields,
                              productArtworkTechnique.idProductArtwTech,
                            ) || !userCanChangeFields
                          }
                        />
                      </div>
                      <div className="h3-label">SUBLIMAÇÃO</div>
                    </div>
                  )}
                  {showField(
                    productRoles.fields
                      .idFieldArtworkTechniqueIndEmbroideryTech,
                    roleEntityFields,
                  ) && (
                    <div className="checkbox-div">
                      <div className="sub-item">
                        <FormCheckbox
                          name={`productArtworkTechniques[${index}].indEmbroideryTech`}
                          label="EMBROIDERY"
                          className="p-my-2 activeCheckbox"
                          initialValue={
                            productArtworkTechnique.indEmbroideryTech
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields
                                .idFieldArtworkTechniqueIndEmbroideryTech,
                              roleEntityFields,
                              productArtworkTechnique.idProductArtwTech,
                            ) || !userCanChangeFields
                          }
                        />
                      </div>
                      <div className="h3-label">BORDADO</div>
                    </div>
                  )}
                  {showField(
                    productRoles.fields
                      .idFieldArtworkTechniqueIndSolidColorTech,
                    roleEntityFields,
                  ) && (
                    <div className="checkbox-div">
                      <div className="sub-item">
                        <FormCheckbox
                          name={`productArtworkTechniques[${index}].indSolidColorTech`}
                          label="SOLID COLOR"
                          className="p-my-2 activeCheckbox"
                          initialValue={
                            productArtworkTechnique.indSolidColorTech
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields
                                .idFieldArtworkTechniqueIndSolidColorTech,
                              roleEntityFields,
                              productArtworkTechnique.idProductArtwTech,
                            ) || !userCanChangeFields
                          }
                        />
                      </div>
                      <div className="h3-label">COR SÓLIDA</div>
                    </div>
                  )}
                  {showField(
                    productRoles.fields.idFieldArtworkTechniqueIndOthersTech,
                    roleEntityFields,
                  ) && (
                    <div className="checkbox-div">
                      <FormCheckbox
                        name={`productArtworkTechniques[${index}].indOthersTech`}
                        label="OTHERS"
                        className="p-my-2 activeCheckbox"
                        initialValue={productArtworkTechnique.indOthersTech}
                        onValueChange={e =>
                          changeValueCheckbox('indOthersTech', e, index)
                        }
                        readOnly={
                          definePermissionAndDisableField(
                            productRoles.fields
                              .idFieldArtworkTechniqueIndOthersTech,
                            roleEntityFields,
                            productArtworkTechnique.idProductArtwTech,
                          ) || !userCanChangeFields
                        }
                      />
                      <div className="h3-label">OUTROS</div>
                      {showField(
                        productRoles.fields.idFieldArtworkTechniqueDsOthersTech,
                        roleEntityFields,
                      ) && (
                        <FormInput
                          name={`productArtworkTechniques[${index}].dsOthersTech`}
                          label="Comment Box"
                          readOnly={
                            !productArtworkTechnique.indOthersTech ||
                            definePermissionAndDisableField(
                              productRoles.fields
                                .idFieldArtworkTechniqueDsOthersTech,
                              roleEntityFields,
                              productArtworkTechnique.idProductArtwTech,
                            ) ||
                            !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                  )}
                </ArtworkItem>
              );
            },
          )}

        <MainButton
          label="Add an Item"
          onClick={() => addArtworkItem()}
          type="button"
        />
      </Container>
    </PageTabContainer>
  );
};

export default Artwork;
