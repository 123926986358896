import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';
import { ISat } from '../../../interfaces';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { FinancialTotal } from '../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';
import { FinancialFieldsPermissions } from '../../interfaces';

interface IPurchasePiProps {
  sat: ISat;
  totalData?: FinancialTotal;
  loading: boolean;
  fieldsPermissions: FinancialFieldsPermissions;
}

const PurchasePi: React.FC<IPurchasePiProps> = ({
  sat,
  totalData,
  loading,
  fieldsPermissions,
}) => {
  const userCanSeeBlock =
    fieldsPermissions.purchasePiCurrency.view ||
    fieldsPermissions.totalCotPiShipment.view ||
    fieldsPermissions.totalConvertedCotPi.view;

  const satCurrencySymbol = getCurrencySymbol(sat.idCurrency2?.abbreviation);

  return userCanSeeBlock ? (
    <div className="p-grid">
      <FormTitle className="p-col-12" name="Purchase PI" />

      {fieldsPermissions.purchasePiCurrency.view && (
        <ReadOnlyInput
          className="p-col-2"
          label="Currency"
          value={sat.idCurrency2?.abbreviation}
        />
      )}

      {fieldsPermissions.totalCotPiShipment.view && (
        <ReadOnlyInput
          className="p-col-2"
          label="Total COT PI"
          prefix={satCurrencySymbol}
          value={totalData?.totalCotPiShipment}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
          loading={loading}
        />
      )}

      {fieldsPermissions.totalConvertedCotPi.view && (
        <ReadOnlyInput
          className="p-col-2"
          label="Total Converted COT PI (USD)"
          prefix={getCurrencySymbol('USD')}
          value={totalData?.totalCotPiShipmentInDollar}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
          loading={loading}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default PurchasePi;
