import React, { useEffect, useState } from 'react';
import FormAsyncSelect from '../../../../../../components/FormAsyncSelect';
import Input from '../../../../../../components/FormInput';
import FormInputNumber from '../../../../../../components/FormInputNumber';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { asyncSelectLoadDomains } from '../../../../../../shared/querys/domain';
import {
  FinancialFieldsPermissions,
  SatForeignTradeSupplier,
} from '../../interfaces';
import { PurchaseOtherExpensesTotalReducerObject } from '../interfaces';

import { Container, Row } from './styles';
import { divide, multiply, subtract } from '../../../../../../utils/mathUtil';

interface IEstimatedPaymentPIProps {
  itemIndex: number;
  satForeignTradeSupplier: SatForeignTradeSupplier;
  fieldsPermissions: FinancialFieldsPermissions;
  userCanSeeEstimatedPaymentPI: boolean;
  estimatedTotalAdvance?: number;
  setEstimatedTotalAdvance: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  estimatedTotalBalance?: number;
  setEstimatedTotalBalance: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  purchaseOtherExpensesTotal?: PurchaseOtherExpensesTotalReducerObject;
}

const EstimatedPaymentPI: React.FC<IEstimatedPaymentPIProps> = ({
  itemIndex,
  satForeignTradeSupplier,
  fieldsPermissions,
  userCanSeeEstimatedPaymentPI,
  estimatedTotalAdvance,
  setEstimatedTotalAdvance,
  estimatedTotalBalance,
  setEstimatedTotalBalance,
  purchaseOtherExpensesTotal,
}) => {
  const [estimatedAdvance, setEstimatedAdvance] = useState<number | undefined>(
    satForeignTradeSupplier.estimatedAdvance,
  );
  const [estimatedBalance, setEstimatedBalance] = useState<number>();

  useEffect(() => {
    const totalCotCi = satForeignTradeSupplier.totalCotCiShipment || 0;
    const totalCotPi = satForeignTradeSupplier?.totalCotPiShipment || 0;

    const totalCot = totalCotCi || totalCotPi;

    const estimatedTotalAdvanceValue = divide(
      multiply(totalCot, estimatedAdvance),
      100,
    );
    const totalDiscountFromSupplier =
      purchaseOtherExpensesTotal?.totalDiscountFromSupplier || 0;
    const totalPayToSupplier =
      purchaseOtherExpensesTotal?.totalPayToSupplier || 0;

    setEstimatedTotalAdvance(estimatedTotalAdvanceValue ?? 0);
    setEstimatedBalance(subtract(100, estimatedAdvance) ?? 100);
    setEstimatedTotalBalance(
      totalCot -
        (estimatedTotalAdvanceValue ?? 0) -
        totalDiscountFromSupplier +
        totalPayToSupplier,
    );
  }, [
    estimatedAdvance,
    purchaseOtherExpensesTotal?.totalDiscountFromSupplier,
    purchaseOtherExpensesTotal?.totalPayToSupplier,
    satForeignTradeSupplier.totalCotCiShipment,
    satForeignTradeSupplier?.totalCotPiShipment,
    setEstimatedTotalAdvance,
    setEstimatedTotalBalance,
  ]);

  return userCanSeeEstimatedPaymentPI ? (
    <Container className="p-grid">
      <FormTitle className="p-col-12" name="Estimated Payment (PI)" />
      <Row className="p-d-flex p-flex-wrap w-full">
        {fieldsPermissions.estimatedPaymentAdvance.view && (
          <FormInputNumber
            className="p-col-12 p-md-3"
            name={`data[${itemIndex}].estimatedAdvance`}
            label="Estimated Advanced (%)"
            suffix="%"
            decimalScale={2}
            defaultValue={satForeignTradeSupplier.estimatedAdvance ?? 0}
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={e => setEstimatedAdvance(e.floatValue)}
            max={100}
            min={0}
            disabled={!fieldsPermissions.estimatedPaymentAdvance.edit}
          />
        )}
        {fieldsPermissions.estimatedPaymentTotalAdvance.view && (
          <ReadOnlyInput
            className="p-col-12 p-md-3"
            label="Estimated Total Advance"
            value={estimatedTotalAdvance}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
          />
        )}
        {fieldsPermissions.estimatedPaymentAdvanceRequest.view && (
          <Input
            className="p-col-12 p-md-3"
            name={`data[${itemIndex}].estimatedAdvanceRequest`}
            label="Estimated Advance Payment Request Date"
            disabled={!fieldsPermissions.estimatedPaymentAdvanceRequest.edit}
            type="date"
          />
        )}
      </Row>
      <Row className="p-d-flex p-flex-wrap w-full">
        {fieldsPermissions.estimatedPaymentBalance.view && (
          <ReadOnlyInput
            className="p-col-12 p-md-3"
            label="Estimated Balance (%)"
            value={estimatedBalance}
            suffix="%"
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
          />
        )}
        {fieldsPermissions.estimatedPaymentTotalBalance.view && (
          <ReadOnlyInput
            className="p-col-12 p-md-3"
            label="Estimated Total Balance"
            value={estimatedTotalBalance}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
          />
        )}
        {fieldsPermissions.estimatedPaymentBalanceRequest.view && (
          <Input
            className="p-col-12 p-md-3"
            name={`data[${itemIndex}].estimatedBalanceRequest`}
            label="Estimated Balance Payment Request Date"
            disabled={!fieldsPermissions.estimatedPaymentBalanceRequest.edit}
            type="date"
          />
        )}
        {fieldsPermissions.estimatedPaymentTermCondition.view && (
          <FormAsyncSelect
            className="p-col-12 p-md-3"
            name={`data[${itemIndex}].estimatedTermCondition`}
            label="Payment Term Condition"
            loadOptions={asyncSelectLoadDomains}
            getOptionLabel={(option: any) => option.description}
            getOptionValue={(option: any) => option.idDomain}
            noOptionsMessage={() => 'No option found'}
            initialValue={satForeignTradeSupplier.estimatedTermCondition2}
            additional={{ id: 13 }}
            disabled={!fieldsPermissions.estimatedPaymentTermCondition.edit}
          />
        )}
      </Row>
    </Container>
  ) : (
    <div />
  );
};

export default EstimatedPaymentPI;
