import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Badge } from 'primereact/badge';

interface IsReadBadgeProps {
  isRead: boolean;
}

export const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 64px;
  padding: 16px 0 16px 19px;
  border-bottom: 1px solid #d7d6d6;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  font-weight: 400;

  span {
    display: flex;
    align-items: center;

    button {
      border: none;
      background-color: transparent;
      margin-right: 8px;
      transition: background-color 0.2s;
      padding: 10px;
      border-radius: 10px;
      &:hover {
        background-color: var(--grey);
      }
    }

    a {
      display: flex;
      align-items: center;
      margin-right: 18px;
      transition: background-color 0.2s;
      padding: 10px;
      border-radius: 8px;
      &:hover {
        background-color: var(--grey);
      }
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  button,
  a {
    text-decoration: none;
    color: inherit;
    svg {
      margin-bottom: 9px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;

    height: 58px;
    width: 64px;

    font-weight: 400;
    font-size: 12px;

    border: 0;
    background: #fff;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.5, '#fff')};
    }
  }
`;

export const NotificationButton = styled.div`
  width: 80px !important;
  height: 58px;
  margin-top: 8px;
  cursor: pointer;
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const NotificationText = styled.div`
  margin-top: 5px;
`;
export const NotificationBadge = styled.div`
  height: 20px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const NotificationImage = styled.div`
  position: absolute;
  margin-top: 3px;
`;
export const NotificationBadgeContainer = styled.div`
  position: absolute;
  margin-left: 30px;
`;
export const NotificationBadgeText = styled(Badge)`
  margin: auto !important;
  display: table !important;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  min-width: 0rem;
  line-height: 1rem !important;
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 50%;
`;
export const ContainerAvatar = styled.div`
  display: flex;

  .user-avatar-wrapper {
    margin-right: 8px;

    .round-user-avatar {
      img,
      div {
        width: 32px;
        height: 32px;
        font-size: unset;
      }
    }
  }
`;
export const UserName = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #42526e;
`;
export const RecordTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #73a0d4;
  width: 247px;
`;
export const AddedDate = styled.p`
  font-size: 12px;
`;
export const IsReadBadge = styled.div<IsReadBadgeProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 10px;
  cursor: pointer;
  ${props =>
    css`
      background-color: ${props.isRead ? '#C3C3C3' : '#0559bb'};
      border: 1px solid ${props.isRead ? '#C3C3C3' : '#0559bb'};
    `};
`;
export const Overlay = styled.div`
  padding: 15px;
  display: flex;
  position: absolute;
  background-color: white;
  top: 50px;
  right: 30px;
  width: auto;
  --overlayArrowLeft: 177px;

  .notification-title {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .items-session {
    overflow-y: auto;
    height: 400px;
    padding-right: 15px;
  }
`;
