import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .p-datatable {
    // Coloca as colunas para preencher a grid
    thead th,
    tbody td {
      flex: auto;
    }
  }
`;
