import React, { useState } from 'react';

import FormAsyncSelect from '../../../../../components/FormAsyncSelect';
import { AttachmentTypes } from '../../../../../shared/enums/attachmentType';
import { DomainGroup } from '../../../../../shared/enums/domainGroup';
import { FileType } from '../../../../../shared/enums/fileType';
import { asyncSelectLoadDomains } from '../../../../../shared/querys/domain';

import { Container } from './styles';
import { FileUploadResponse } from '../../../../../components/FileUpload/interfaces';
import FileUpload from '../../../../../components/FileUpload';

interface IAttachmentUploadProps {
  handleCreateNewAttachments(
    attachments: FileUploadResponse[],
    idType?: number,
  ): void;
  uploadInProgress?: boolean;
}

const AttachmentUpload: React.FC<IAttachmentUploadProps> = ({
  handleCreateNewAttachments,
  uploadInProgress,
}) => {
  const [idType, setIdType] = useState<AttachmentTypes>();

  return (
    <Container>
      <FormAsyncSelect
        className="p-col-12"
        name="idType"
        label="Type of Files"
        loadOptions={asyncSelectLoadDomains}
        getOptionLabel={(option: any) => option.description}
        getOptionValue={(option: any) => option.idDomain}
        noOptionsMessage={() => 'No incoterms found'}
        onValueChange={e => setIdType(e.idDomain)}
        additional={{
          id: DomainGroup.ARTWORK_TYPE_FILES,
        }}
        menuPosition="fixed"
      />
      <FileUpload
        multiple
        accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX},${FileType.TXT},${FileType.ZIP}`}
        cancelLabel="Clear All"
        uploadLabel="Confirm"
        disabled={!idType}
        onConfirm={e => handleCreateNewAttachments(e, idType)}
        uploadInProgress={uploadInProgress}
      />
    </Container>
  );
};

export default AttachmentUpload;
