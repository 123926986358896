import styled from 'styled-components';
import { lighten, shade } from 'polished';

interface ButtonsAndGridOptionsProps {
  sidebarVisible: boolean;
  stickyButtons: boolean;
  fixedStickyButtons?: boolean;
}

interface ContainerProps {
  fixedStickyButtons?: boolean;
  sidebarVisible?: boolean;
  stickyButtons?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background-color: var(--grey);
  width: 100%;
  min-height: ${props => (props.fixedStickyButtons ? '58px' : '150px')};
  padding: ${props => (props.fixedStickyButtons ? '0' : '32px 32px 16px 32px')};
  display: flex;
`;

export const Details = styled.div`
  width: 100%;

  h1 {
    font-size: 34px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .mainDetails {
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
    }
  }
`;

export const Tags = styled.div`
  align-items: center;
  .tag {
    margin-right: 9px;
    height: 21px;
  }
  margin-bottom: 12px;
`;

export const DateInfo = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 11px;
  margin-bottom: 10px;
  div {
    display: flex;
    .label {
      color: var(--grey-scale);
      margin-right: 2px;
    }
  }
`;

export const Statuses = styled.div`
  display: flex;
  align-items: center;
  margin-top: -8px;
  div + div {
    margin-left: 8px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  div {
    display: flex;

    .mainButton + .secondaryButton,
    .secondaryButton + .mainButton {
      margin-left: 8px;
    }

    button + button,
    .secondaryButton + .secondaryButton {
      margin-left: 8px;
    }

    .p-splitbutton {
      margin-left: 8px;
      button + button {
        margin-left: 0;
      }
      button {
        background-color: var(--white) !important;
        color: var(--red) !important;
        border-color: var(--red) !important;
        font-weight: 500;
        transition: background-color 0.2s;

        &:hover {
          background-color: ${lighten(0.35, '#FD2D2D')} !important;
        }
      }
    }

    .history-button + .destructive-buttons::before {
      content: '';
      display: block;
      margin: 0 12px;
      width: 1px;
      background-color: var(--c3);
    }
  }
`;

export const ButtonsAndGridOptions = styled.div<ButtonsAndGridOptionsProps>`
  display: flex;
  border-top: solid 1px var(--c3);
  padding-top: 16px;

  ${props =>
    props.stickyButtons || props.fixedStickyButtons
      ? `position: fixed;
        top: 64px;
        width: ${props.sidebarVisible ? 'calc(100% - 197px)' : '100%'};
        left: ${props.sidebarVisible ? '197px' : '0'};
        background-color: var(--grey);
        padding: 12px 32px;
        z-index: 3;
        border-bottom: solid 1px var(--c3);
        `
      : ''};

  .collapseHeader {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: color 0.2s;
    &:hover {
      color: var(--action);
    }
  }
`;

export const GridOptions = styled.div`
  margin-left: 16px;
  padding-left: 12px;
  border-left: 1px solid #var(--c3);
  display: flex;

  .search {
    display: flex;
    align-items: center;

    margin-right: 10px;
    svg {
      color: var(--grey-scale);
      margin-right: 10px;
    }
  }

  .advanced-filters-button {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  .applied-filters-button {
    border-left: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    margin-right: 10px;
  }

  .grid-multiselect-button {
    border: none !important;
    background: none !important;
    color: black !important;
    transition: color 0.2s;
    span {
      font-size: 1.3em;
    }
    &:hover {
      background: none !important;
      color: var(--action) !important;
    }
  }

  .export-xlsx {
    background: var(--xls) !important;
    border-color: var(--xls) !important;
    color: var(--white) !important;
    transition: background-color 0.2s;

    &:hover {
      background-color: ${shade(0.2, '#28a745')} !important;
    }
  }

  .xls-split-button {
    .p-button {
      font-weight: 500;
      background: var(--xls) !important;
      border: 1px solid var(--xls);

      &:hover {
        background-color: ${shade(0.2, '#28a745')} !important;
        border: 1px solid var(--xls);
      }
    }
  }

  .massive-action-button {
    margin-left: 10px;
    .p-button {
      font-weight: 500;
      background: var(--action);
      border: 1px solid var(--action);

      &:hover {
        background-color: ${shade(0.2, '#4C85C8')} !important;
        border: 1px solid var(--action);
      }
    }
  }
`;

export const ContainerTitleAndButtonsHeader = styled.div`
  width: 100%;
  margin-left: 15px;
`;
