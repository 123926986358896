import styled from 'styled-components';

export const Container = styled.div``;

export const DimensionItem = styled.div`
  max-width: 760px;
  padding: 12px;
  border: 2px solid var(--grey);
  border-radius: 8px;
  margin-bottom: 20px;

  .h2-split {
    display: inline-flexbox;
    margin-top: 24px;
  }
  .name-input-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .formInput {
      width: 358px;
    }
  }

  .button-div {
    display: flex;
    align-items: center;

    button {
      float: right;
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;

      &:hover {
        color: var(--red);
      }
    }
  }

  .inputs-div {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 24px;

    .formInputNumber {
      width: 140px;
    }

    .quantity-input,
    .formInput {
      width: 227px;
    }
    .sub-item {
      display: flex;
      .form-dropdown,
      .unit {
        max-width: 87px;
      }
    }

    .sub-item + .sub-item {
      margin-top: 6px;
    }
  }
`;
