import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root {
        --blue: #4C85C8;
        --blue-light: #29ABE2;
        --black: #000000;
        --white: #fff;
        --yellow: #ffeb3c;
        --red: #FD2D2D;
        --orange: #EB8F10;
        --grey: #F4F4F4;
        --grey-scale: #7F7F80;
        --grey-border: #DEE2E6;
        --xls: #28a745;

        --sunasia-red: #B1251B;
        --sunasia-comex: #C1272D;
        --sunasia-export: #6AB7A8;
        --sunasia-design: #662D91;
        --sunasia-purchase: #E51FB2;
        --sunasia-sectors-qualidade: #8BB878;
        --sunasia-port: #E5DA15;
        --sunasia-purchase: #29ABE2;
        --sunasia-hr: #FF9100;
        --sunasia-supplier: #000000;
        --sunasia-adm: #E5DA15;
        --sunasia-client: #D1D1D1;
        --sunasia-commercial: #4D4D4D;
        --sunasia-control: #FF9100;
        --sunasia-financial: #00FF00;

        --c2: #E5E5E5;
        --c3: #D7D6D6;
        --c4: #CAC8C9;
        --c5: #BDBBBB;
        --c6: #B0AEAE;
        --c7: #A3A2A2;
        --c8: #969696;
        --c9: #8A8A8A;

        --action: #4C85C8;
        --error: #FD2D2D;
        --alert: #facb1f;
        --success: #56C300;
        --info: #C2EEFC;
    }

    html,
    body,
    #root {
        height: 100%;
        overflow: hidden;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        font-size:14px;
        /* @media (max-width: 1080px){
            font-size: 93.75%;
        }

        @media (max-width: 720px){
            font-size: 87.5%;
        } */
    }

    body {
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button, .s-readonly-input-content {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 500;
    }

    button {
        cursor: pointer;
    }

    pre {
      font-size: inherit;
      color: inherit;
      border: initial;
      padding: initial;
      font-family: inherit;
    }

    [disabled], [aria-disabled="true"]{
        opacity: 0.6;
        cursor: not-allowed;
    }

    .p-toast {
      opacity: 1;
      z-index: 9999 !important;
    }

    .noselect {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Edge, Opera and Firefox */
    }

    .transparent-link {
      text-decoration: unset;
      color: currentColor;
    }

    .p-button-danger{
      color: var(--red) !important;
      background-color: #fff !important;
      border-color: var(--red) !important;

      &:hover {
        background: #ffe6e6 !important;
      }
    }

    .form-element {
      small {
        margin-top: 2px;
      }
    }

    .w-full {
      width: 100%;
    }

    .p-0 {
      padding: 0;
    }

    .p-5 {
      padding: 2rem;
    }

    .flex {
      display: flex;
    }

    .justify-content-between {
      justify-content: space-between;
    }

    .align-items-center {
      align-items: center;
    }

    .hidden {
      display: none;
    }

    .s-uppercase {
      text-transform: uppercase;
    }

    .s-expanded-datatable {
      .p-datatable-row-expansion > td {
        padding: 0 !important;
      }

      .p-datatable-row-expansion thead {
        display: none;
      }
    }

    .s-internal-datatable {
      @media (max-width: 1600px) {
          max-width: 90vw;
      }
      @media (min-width: 1600px) {
        max-width: 1500px;
      }
    }

    .s-warning-text {
      color: var(--error);
    }

    .s-secondary-info-text {
      color: var(--c8);
    }

    .cursor-pointer {
      cursor: pointer;
    }
`;
