import { useLazyQuery, useMutation } from '@apollo/client';
import React, {
  useCallback,
  useEffect,
  useRef,
  Ref,
  useImperativeHandle,
  Dispatch,
  useReducer,
  useMemo,
  useState,
  RefObject,
} from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { Skeleton } from 'primereact/skeleton';
import { ValidationError } from 'yup';
import { useLocation } from 'react-router-dom';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { useRefHook } from '../../../../hooks/useRefHook';
import ToastLife from '../../../../shared/enums/toastLife';
import {
  ForeignTradeContainerItemsPermissions,
  ForeignTradeFieldsPermissions,
  ForeignTradeReducerActionKind,
  ForeignTradeTab,
  IUserCanObjects,
  SatForeignTrade,
  SatForeignTradeFormData,
  SelectedDivergentData,
  UpdateSatForeignTradesResponse,
} from './interfaces';
import {
  getDivergentConsolidatedForeignTradesQuery,
  listDomainsByGroupIdQuery,
  listSatForeignTradesNumbersQuery,
  updateSatForeignTradeItemsQuery,
  updateSatForeignTradesQuery,
} from './queries';
import { Container } from './styles';
import Empty from '../../../../components/Empty';
import ForeignTradeContent from './ForeignTradeContent';
import {
  ForeignTradeFieldsCommonWithSatFieldsPermissions,
  ISat,
} from '../interfaces';
import {
  foreignTradeReducerInitialState,
  foreignTradesReducer,
} from './reducers';
import { IUserFieldsAndPermissionsResponse } from '../../../../utils/getUserFieldsAndPermissionsByEntity';

import {
  satForeignTradeContainerItemsRoles,
  satForeignTradeRoles,
} from '../../../../shared/roles/sat';
import getFieldPermission from '../../../../utils/getFieldPermission';
import userHasPermission from '../../../../utils/userHasPermission';
import AvailableSatItemsModal, {
  IAvaillableSatItemsModalRef,
} from './AvailableSatItemsModal';
import { IContainerItemsRef } from './ForeignTradeContent/ContainerItems';
import { DomainGroup } from '../../../../shared/enums/domainGroup';
import { IForeignTradeContentRef } from './ForeignTradeContent/interfaces';
import { ISatFinancialRef } from '../Financial';
import DivergentForeignTradesModal, {
  IDivergentForeignTradesModalRef,
} from './DivergentForeignTradesModal';
import { ISatGeneralInformationRef } from '../GeneralInformation';
import { ISatItemsGridRef } from '../SatItems/ItemsGrid';

interface IForeignTradeProps extends PageTabContainerProps {
  sat: ISat;
  ref: Ref<ISatForeignTradeRef>;
  pageMenuItemKey: string;
  setSelectedMenuItem: Dispatch<React.SetStateAction<string>>;
  userPermissions: IUserFieldsAndPermissionsResponse;
  commonWithSatFieldsPermissions: ForeignTradeFieldsCommonWithSatFieldsPermissions;
  isSatCancelled: boolean;
  financialRef: React.RefObject<ISatFinancialRef>;
  handleSaveSat: () => Promise<void>;
  generalInformationRef: RefObject<ISatGeneralInformationRef>;
  satItemsGridRef: RefObject<ISatItemsGridRef>;
}

export interface ISatForeignTradeRef {
  validateForm(): Promise<boolean>;
  submitForm(): Promise<UpdateSatForeignTradesResponse>;
  refetchAndFocus(): Promise<void>;
  refreshForm(): Promise<void>;
  checkForApiIssuesBeforeSave(): Promise<boolean>;
  getTabsWhereNoReportFieldWasChecked(): number[];
  updateTabsWhereNoReportFieldWasChecked(e: number[]): void;
}

const ForeignTrade: React.FC<IForeignTradeProps> = React.forwardRef(
  (
    {
      selected,
      sat,
      setSelectedMenuItem,
      pageMenuItemKey,
      userPermissions,
      commonWithSatFieldsPermissions,
      isSatCancelled,
      financialRef,
      handleSaveSat,
      generalInformationRef,
      satItemsGridRef,
    },
    ref,
  ) => {
    const { toastRef } = useRefHook();

    const divergentForeignTradesRef =
      useRef<IDivergentForeignTradesModalRef>(null);

    const tabsRef = useRef<IForeignTradeContentRef[] | null[]>([]);

    const { search } = useLocation();

    const containerItemsRef = useRef<IContainerItemsRef>(null);

    const addForeignTradeItemModalRef =
      useRef<IAvaillableSatItemsModalRef>(null);

    const [tabs, setTabs] = useState<ForeignTradeTab[]>([]);

    const [foreignTradesState, foreignTradesDispatch] = useReducer(
      foreignTradesReducer,
      foreignTradeReducerInitialState,
    );

    const [getDivergentForeignTrades] = useLazyQuery(
      getDivergentConsolidatedForeignTradesQuery,
    );

    const [updateSatForeignTradesMutation] = useMutation(
      updateSatForeignTradesQuery,
    );

    const [updateSatForeignTradeItemsMutation] = useMutation(
      updateSatForeignTradeItemsQuery,
    );

    const foreignTradeUrlParam = new URLSearchParams(search).get(
      'foreignTradeId',
    );

    const fieldsPermissions: ForeignTradeFieldsPermissions = useMemo(() => {
      return {
        idImportStatus: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdImportStatus,
          userPermissions.userFields,
        ),
        realArrivalAtClient: getFieldPermission(
          satForeignTradeRoles.fields.idFieldRealArrivalAtClient,
          userPermissions.userFields,
        ),
        realEta: getFieldPermission(
          satForeignTradeRoles.fields.idFieldRealEta,
          userPermissions.userFields,
        ),
        realEtd: getFieldPermission(
          satForeignTradeRoles.fields.idFieldRealEtd,
          userPermissions.userFields,
        ),
        realInspectionDate: getFieldPermission(
          satForeignTradeRoles.fields.idFieldRealInspectionDate,
          userPermissions.userFields,
        ),
        idInspectionApprovedBy: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdInspectionApprovedBy,
          userPermissions.userFields,
        ),
        inspectionApproval: getFieldPermission(
          satForeignTradeRoles.fields.idFieldInspectionApproval,
          userPermissions.userFields,
        ),
        inspectionScheduled: getFieldPermission(
          satForeignTradeRoles.fields.idFieldInspectionScheduled,
          userPermissions.userFields,
        ),
        rework: getFieldPermission(
          satForeignTradeRoles.fields.idFieldRework,
          userPermissions.userFields,
        ),
        reworkCost: getFieldPermission(
          satForeignTradeRoles.fields.idFieldReworkCost,
          userPermissions.userFields,
        ),
        reworkDate: getFieldPermission(
          satForeignTradeRoles.fields.idFieldReworkDate,
          userPermissions.userFields,
        ),
        idReworkApprovedBy: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdReworkApprovedBy,
          userPermissions.userFields,
        ),
        authorizationRequested: getFieldPermission(
          satForeignTradeRoles.fields.idFieldAuthorizationRequested,
          userPermissions.userFields,
        ),
        bookingConfirmed: getFieldPermission(
          satForeignTradeRoles.fields.idFieldBookingConfirmed,
          userPermissions.userFields,
        ),
        diNumber: getFieldPermission(
          satForeignTradeRoles.fields.idFieldDiNumber,
          userPermissions.userFields,
        ),
        freightCost: getFieldPermission(
          satForeignTradeRoles.fields.idFieldFreightCost,
          userPermissions.userFields,
        ),
        freightExtraCost: getFieldPermission(
          satForeignTradeRoles.fields.idFieldFreightExtraCost,
          userPermissions.userFields,
        ),
        freightForwarderDestination: getFieldPermission(
          satForeignTradeRoles.fields.idFieldFreightForwarderDestination,
          userPermissions.userFields,
        ),
        freightForwarderOrigin: getFieldPermission(
          satForeignTradeRoles.fields.idFieldFreightForwarderOrigin,
          userPermissions.userFields,
        ),
        importLicenseExpireDate: getFieldPermission(
          satForeignTradeRoles.fields.idFieldImportLicenseExpireDate,
          userPermissions.userFields,
        ),
        importLicenseNumber: getFieldPermission(
          satForeignTradeRoles.fields.idFieldImportLicenseNumber,
          userPermissions.userFields,
        ),
        shipmentAuthorized: getFieldPermission(
          satForeignTradeRoles.fields.idFieldShipmentAuthorized,
          userPermissions.userFields,
        ),
        shipmentConfirmed: getFieldPermission(
          satForeignTradeRoles.fields.idFieldShipmentConfirmed,
          userPermissions.userFields,
        ),
        typeOfImportService: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdTypeOfImportService,
          userPermissions.userFields,
        ),
        exportNotes: getFieldPermission(
          satForeignTradeRoles.fields.idFieldExportNotes,
          userPermissions.userFields,
        ),
        importNotes: getFieldPermission(
          satForeignTradeRoles.fields.idFieldImportNotes,
          userPermissions.userFields,
        ),
        importLevel: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdImportLevel,
          userPermissions.userFields,
        ),
        deliveredToClient: getFieldPermission(
          satForeignTradeRoles.fields.idFieldDeliveredToClient,
          userPermissions.userFields,
        ),
        idCourier: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdCourier,
          userPermissions.userFields,
        ),
        received: getFieldPermission(
          satForeignTradeRoles.fields.idFieldReceived,
          userPermissions.userFields,
        ),
        trakingNumber: getFieldPermission(
          satForeignTradeRoles.fields.idFieldTrakingNumber,
          userPermissions.userFields,
        ),
        startProductionTiming: getFieldPermission(
          satForeignTradeRoles.fields.idFieldStartProductionTiming,
          userPermissions.userFields,
        ),
        realProductionTime: getFieldPermission(
          satForeignTradeRoles.fields.idFieldRealProductionTime,
          userPermissions.userFields,
        ),
        authorizationBookingTiming: getFieldPermission(
          satForeignTradeRoles.fields.idFieldAuthorizationBookingTiming,
          userPermissions.userFields,
        ),
        authorizationShippingTiming: getFieldPermission(
          satForeignTradeRoles.fields.idFieldAuthorizationShippingTiming,
          userPermissions.userFields,
        ),
        bookingShippingTiming: getFieldPermission(
          satForeignTradeRoles.fields.idFieldBookingShippingTiming,
          userPermissions.userFields,
        ),
        preShipmentTiming: getFieldPermission(
          satForeignTradeRoles.fields.idFieldPreShipmentTiming,
          userPermissions.userFields,
        ),
        transitTime: getFieldPermission(
          satForeignTradeRoles.fields.idFieldTransitTime,
          userPermissions.userFields,
        ),
        hblFinalTiming: getFieldPermission(
          satForeignTradeRoles.fields.idFieldHblFinalTiming,
          userPermissions.userFields,
        ),
        originalDocsTiming: getFieldPermission(
          satForeignTradeRoles.fields.idFieldOriginalDocsTiming,
          userPermissions.userFields,
        ),
        deliveryAtClientTiming: getFieldPermission(
          satForeignTradeRoles.fields.idFieldDeliveryAtClientTiming,
          userPermissions.userFields,
        ),
        totalForeignTradeTiming: getFieldPermission(
          satForeignTradeRoles.fields.idFieldTotalForeignTradeTiming,
          userPermissions.userFields,
        ),
        idExportStatus: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdExportStatus,
          userPermissions.userFields,
        ),
        estimatedInspectionDate: getFieldPermission(
          satForeignTradeRoles.fields.idFieldEstimatedInspectionDate,
          userPermissions.userFields,
        ),
        estimatedShipmentDate: getFieldPermission(
          satForeignTradeRoles.fields.idFieldEstimatedShipmentDate,
          userPermissions.userFields,
        ),
        estimatedArrivalDate: getFieldPermission(
          satForeignTradeRoles.fields.idFieldEstimatedArrivalDate,
          userPermissions.userFields,
        ),
        estimatedArrivalClientDate: getFieldPermission(
          satForeignTradeRoles.fields.idFieldEstimatedArrivalClientDate,
          userPermissions.userFields,
        ),
        idLoadingPort: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdLoadingPort,
          userPermissions.userFields,
        ),
        idPort: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdPort,
          userPermissions.userFields,
        ),
        clientOrder: getFieldPermission(
          satForeignTradeRoles.fields.idFieldClientOrder,
          userPermissions.userFields,
        ),
        idPurchaseUser: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdPurchaseUser,
          userPermissions.userFields,
        ),
        idSalesUser: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdSalesUser,
          userPermissions.userFields,
        ),
        idSalesSecondUser: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdSalesSecondUser,
          userPermissions.userFields,
        ),
        idFinancialUser: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdFinancialUser,
          userPermissions.userFields,
        ),
        idComexExportUser: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdComexExportUser,
          userPermissions.userFields,
        ),
        idComexImportUser: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdComexImportUser,
          userPermissions.userFields,
        ),
        idDesignerUser: getFieldPermission(
          satForeignTradeRoles.fields.idFieldIdDesignerUser,
          userPermissions.userFields,
        ),
        arrivalConfirmed: getFieldPermission(
          satForeignTradeRoles.fields.idFieldArrivalConfirmed,
          userPermissions.userFields,
        ),
        shipmentDetails: getFieldPermission(
          satForeignTradeRoles.fields.idFieldShipmentDetails,
          userPermissions.userFields,
        ),
        startProduction: getFieldPermission(
          satForeignTradeRoles.fields.idFieldStartProduction,
          userPermissions.userFields,
        ),
        shipmentRequestNotRequired: getFieldPermission(
          satForeignTradeRoles.fields.idFieldShipmentRequestNotRequired,
          userPermissions.userFields,
        ),
        inspector: getFieldPermission(
          satForeignTradeRoles.fields.idFieldInspector,
          userPermissions.userFields,
        ),
      };
    }, [userPermissions.userFields]);

    const containerItemsPermissions: ForeignTradeContainerItemsPermissions =
      useMemo(() => {
        return {
          quantityCtnr: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldQuantityCtnr,
            userPermissions.userFields,
          ),
          cotPrice: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldCotPrice,
            userPermissions.userFields,
          ),
          quantity: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldQuantity,
            userPermissions.userFields,
          ),
          quantityCi: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldQuantityCi,
            userPermissions.userFields,
          ),
          stCode: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldStCode,
            userPermissions.userFields,
          ),
          sunPrice: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldSunPrice,
            userPermissions.userFields,
          ),
          totalCotCi: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldTotalCotCi,
            userPermissions.userFields,
          ),
          totalSunCi: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldTotalSunCi,
            userPermissions.userFields,
          ),
          piecesContainer: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldPiecesContainer,
            userPermissions.userFields,
          ),
          remainQuantityPiShipment: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldRemainQuantity,
            userPermissions.userFields,
          ),
          grossWeight: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldGrossWeight,
            userPermissions.userFields,
          ),
          netWeight: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldNetWeight,
            userPermissions.userFields,
          ),
          cbm: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldCbm,
            userPermissions.userFields,
          ),
          quantityPiShipment: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldQuantityPiShipment,
            userPermissions.userFields,
          ),
          totalQuantityPiShipment: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldQuantityPiShipment,
            userPermissions.userFields,
          ),
          sizeRange: getFieldPermission(
            satForeignTradeContainerItemsRoles.fields.idFieldSizeRange,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const userCanObjects: IUserCanObjects = {
      userCanSeeShipmentContainersBlock: userHasPermission(
        satForeignTradeRoles.permissions.idPermissionShipmentContainers,
        userPermissions.userPermissions,
      ),
      userCanDeleteContainerItem: userHasPermission(
        satForeignTradeContainerItemsRoles.permissions
          .idPermissionDeleteContainerItem,
        userPermissions.userPermissions,
      ),
      userCanAddContainerItem: userHasPermission(
        satForeignTradeContainerItemsRoles.permissions
          .idPermissionAddContainerItem,
        userPermissions.userPermissions,
      ),
      userCanSeeContainerItemsBlock: Object.keys(
        containerItemsPermissions,
      ).some(
        key =>
          containerItemsPermissions[
            key as keyof ForeignTradeContainerItemsPermissions
          ].view,
      ),
      userCanSeeShipmentBlock:
        fieldsPermissions.authorizationRequested.view ||
        fieldsPermissions.shipmentAuthorized.view ||
        fieldsPermissions.bookingConfirmed.view ||
        fieldsPermissions.shipmentConfirmed.view ||
        fieldsPermissions.freightForwarderOrigin.view ||
        fieldsPermissions.freightForwarderDestination.view ||
        fieldsPermissions.freightCost.view ||
        fieldsPermissions.freightExtraCost.view ||
        fieldsPermissions.importLicenseNumber.view ||
        fieldsPermissions.importLicenseExpireDate.view ||
        fieldsPermissions.diNumber.view ||
        fieldsPermissions.idLoadingPort.view ||
        fieldsPermissions.idPort.view ||
        fieldsPermissions.clientOrder.view ||
        fieldsPermissions.arrivalConfirmed.view ||
        fieldsPermissions.shipmentDetails.view,
      userCanSeeForeignTradeBlock:
        fieldsPermissions.typeOfImportService.view ||
        fieldsPermissions.importLevel.view ||
        fieldsPermissions.exportNotes.view ||
        fieldsPermissions.importNotes.view,
      userCanUploadFiles: userHasPermission(
        satForeignTradeRoles.permissions.idPermissionUploadFile,
        userPermissions.userPermissions,
      ),
      userCanDeleteFiles: userHasPermission(
        satForeignTradeRoles.permissions.idPermissionDeleteFile,
        userPermissions.userPermissions,
      ),
      userCanSeeOriginalDocsBlock:
        fieldsPermissions.trakingNumber.view ||
        fieldsPermissions.idCourier.view ||
        fieldsPermissions.received.view ||
        fieldsPermissions.deliveredToClient.view,
      userCanSeeTimingBlock:
        fieldsPermissions.startProductionTiming.view ||
        fieldsPermissions.realProductionTime.view ||
        fieldsPermissions.authorizationBookingTiming.view ||
        fieldsPermissions.authorizationShippingTiming.view ||
        fieldsPermissions.bookingShippingTiming.view ||
        fieldsPermissions.preShipmentTiming.view ||
        fieldsPermissions.transitTime.view ||
        fieldsPermissions.hblFinalTiming.view ||
        fieldsPermissions.originalDocsTiming.view ||
        fieldsPermissions.deliveryAtClientTiming.view ||
        fieldsPermissions.totalForeignTradeTiming.view,
    };

    const [
      loadForeignTradeFileTypesData,
      {
        loading: foreignTradeFileTypesLoading,
        data: foreignTradeFileTypesData,
      },
    ] = useLazyQuery(listDomainsByGroupIdQuery, {
      variables: {
        listDomainsByGroupIdId: DomainGroup.FOREIGN_TRADE_TYPE_FILES,
        pagination: {
          _limit: 0,
          _page: 1,
        },
        isActive: true,
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Foreign Trade File Types',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

    /**
     * Busca SAT Foreign Trades
     */
    const [loadForeignTradesData, { loading: foreignTradesLoading }] =
      useLazyQuery(listSatForeignTradesNumbersQuery, {
        variables: {
          data: {
            idSat: sat.idSat,
            pagination: {
              _page: 1,
              _limit: 0,
              _sortOrder: 'DESC',
            },
          },
        },
        onCompleted: response => {
          if (response.listSatForeignTradesNumbersBySatId?.items) {
            setTabs(response.listSatForeignTradesNumbersBySatId.data);

            // Se tiver o parametro de Foreign Trade na URL e as abas nunca
            // tiverem sido carregadas, seta a respectiva aba como ativa
            if (foreignTradeUrlParam && !tabs.length) {
              const foreignTradeIndex =
                response.listSatForeignTradesNumbersBySatId.data.findIndex(
                  (ft: SatForeignTrade) =>
                    ft.idSatForeignTrade === parseInt(foreignTradeUrlParam, 10),
                );

              // Se o parametro de Foreign Trade valido
              if (foreignTradeIndex >= 0) {
                foreignTradesDispatch({
                  type: ForeignTradeReducerActionKind.SET_ACTIVE_TAB_INDEX,
                  payload: { tabActiveIndex: foreignTradeIndex },
                });
              }
            }

            if (!foreignTradeFileTypesData) {
              loadForeignTradeFileTypesData();
            }
          }
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting Foreign Trades',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      });

    const handleRefreshForeignTrade = useCallback(
      async (keepCurrentTabOpen?: boolean) => {
        foreignTradesDispatch({
          type: ForeignTradeReducerActionKind.RESET_STATE,
          payload: {
            tabActiveIndex: keepCurrentTabOpen
              ? foreignTradesState.tabActiveIndex
              : undefined,
          },
        });

        loadForeignTradesData();
      },
      [foreignTradesState.tabActiveIndex, loadForeignTradesData],
    );

    async function checkDivergentConsolidatedData(
      data: SatForeignTradeFormData[],
    ) {
      if (!data.length || foreignTradesState.divergentData.length) return false;

      const payload = data.map(ft => {
        return {
          idSatForeignTrade: ft.idSatForeignTrade,
          realArrivalAtClient: ft.realArrivalAtClient,
          realEta: ft.realEta,
          realEtd: ft.realEtd,
          idLoadingPort: ft.idLoadingPort,
          idPort: ft.idPort,
          bookingConfirmed: ft.bookingConfirmed,
          shipmentConfirmed: ft.shipmentConfirmed,
          freightForwarderOrigin: ft.freightForwarderOrigin,
          freightForwarderDestination: ft.freightForwarderDestination,
        };
      });

      const divergentData = await getDivergentForeignTrades({
        variables: {
          data: payload,
        },
      });

      const response =
        divergentData.data?.getDivergentConsolidatedForeignTrades;

      if (response.length) {
        divergentForeignTradesRef.current?.toggle(response);

        return true;
      }

      return false;
    }

    function getItemsToUpdate() {
      const formItemsToUpdate: SatForeignTradeFormData[] = [];

      // Apenas registros que foram modificados deverao ser enviados para
      // serem atualizados
      tabsRef.current.forEach(tab => {
        const tabData = tab?.getDataIfChanged();
        if (tabData) formItemsToUpdate.push(tabData);
      });

      return formItemsToUpdate;
    }

    const handleSubmitForeignTrade = useCallback(async () => {
      const response: UpdateSatForeignTradesResponse = {
        errors: [],
        warnings: [],
      };

      const formItemsToUpdate = getItemsToUpdate();

      const gridItemsToUpdate =
        !!foreignTradesState.itemContainersToUpdate.length ||
        !!foreignTradesState.itemContainersToCreate.length ||
        !!foreignTradesState.itemsToUpdate.length ||
        !!foreignTradesState.satItemReferencesToUpdate.length;

      // Se nao houver nada para ser atualizado, aborta execucao
      if (!formItemsToUpdate.length && !gridItemsToUpdate) {
        return response;
      }

      // Atualiza informacoes que usuario escolheu na tela de divergencias
      // para a respectiva foreign trade
      foreignTradesState.divergentData.forEach(divergentFt => {
        const divergentItemToUpdateIndex = formItemsToUpdate.findIndex(
          item => item.idSatForeignTrade === divergentFt.idSatForeignTrade,
        );

        if (divergentFt.realArrivalAtClient !== undefined) {
          formItemsToUpdate[divergentItemToUpdateIndex].realArrivalAtClient =
            divergentFt.realArrivalAtClient;
        }

        if (divergentFt.realEta !== undefined) {
          formItemsToUpdate[divergentItemToUpdateIndex].realEta =
            divergentFt.realEta;
        }

        if (divergentFt.realEtd !== undefined) {
          formItemsToUpdate[divergentItemToUpdateIndex].realEtd =
            divergentFt.realEtd;
        }

        if (divergentFt.idLoadingPort !== undefined) {
          formItemsToUpdate[divergentItemToUpdateIndex].idLoadingPort =
            divergentFt.idLoadingPort;
        }

        if (divergentFt.idPort !== undefined) {
          formItemsToUpdate[divergentItemToUpdateIndex].idPort =
            divergentFt.idPort;
        }

        if (divergentFt.bookingConfirmed !== undefined) {
          formItemsToUpdate[divergentItemToUpdateIndex].bookingConfirmed =
            divergentFt.bookingConfirmed;
        }

        if (divergentFt.shipmentConfirmed !== undefined) {
          formItemsToUpdate[divergentItemToUpdateIndex].shipmentConfirmed =
            divergentFt.shipmentConfirmed;
        }

        if (divergentFt.freightForwarderOrigin !== undefined) {
          formItemsToUpdate[divergentItemToUpdateIndex].freightForwarderOrigin =
            divergentFt.freightForwarderOrigin;
        }

        if (divergentFt.freightForwarderDestination !== undefined) {
          formItemsToUpdate[
            divergentItemToUpdateIndex
          ].freightForwarderDestination =
            divergentFt.freightForwarderDestination;
        }
      });

      try {
        if (formItemsToUpdate.length) {
          const formResponse = await updateSatForeignTradesMutation({
            variables: {
              data: formItemsToUpdate,
            },
          });

          response.warnings.push(
            ...formResponse.data.updateSatForeignTrades.warnings,
          );
        }

        if (gridItemsToUpdate) {
          const itemsResponse = await updateSatForeignTradeItemsMutation({
            variables: {
              data: {
                itemContainersToCreate:
                  foreignTradesState.itemContainersToCreate.map(item => {
                    return {
                      idSatForeignTradeCtnr: item.idSatForeignTradeCtnr,
                      idSatForeignTradeItem: item.idSatForeignTradeItem,
                      idSatForeignTradeItemReference:
                        item.idSatForeignTradeItemReference,
                      quantityCtnr: item.quantityCtnr,
                      grossWeight: item.grossWeight,
                      netWeight: item.netWeight,
                      cbm: item.cbm,
                    };
                  }),

                itemContainersToUpdate:
                  foreignTradesState.itemContainersToUpdate.map(item => {
                    return {
                      idSatForeignTradeItemCtnr: item.idSatForeignTradeItemCtnr,
                      quantityCtnr: item.quantityCtnr,
                      grossWeight: item.grossWeight,
                      netWeight: item.netWeight,
                      cbm: item.cbm,
                    };
                  }),

                itemsToUpdate: foreignTradesState.itemsToUpdate.map(item => {
                  return {
                    idSatForeignTradeItem: item.idSatForeignTradeItem,
                    quantityPiShipment: item.quantityPiShipment,
                  };
                }),

                satItemReferencesToUpdate:
                  foreignTradesState.satItemReferencesToUpdate.map(item => {
                    return {
                      idSatItemReference: item.idSatItemReference,
                      idSatForeignTradeItem: item.idSatForeignTradeItem,
                      quantityPiShipment: item.quantityPiShipment,
                    };
                  }),
              },
            },
          });

          response.warnings.push(
            ...itemsResponse.data.updateSatForeignTradeItems.warnings,
          );
        }

        handleRefreshForeignTrade(true);

        generalInformationRef.current?.refetchShipmentCount();
        satItemsGridRef.current?.refreshItems();

        return response;
      } catch (error) {
        response.errors.push(error.message);

        return response;
      }
    }, [
      foreignTradesState.itemContainersToUpdate,
      foreignTradesState.itemContainersToCreate,
      foreignTradesState.itemsToUpdate,
      foreignTradesState.satItemReferencesToUpdate,
      foreignTradesState.divergentData,
      handleRefreshForeignTrade,
      generalInformationRef,
      satItemsGridRef,
      updateSatForeignTradesMutation,
      updateSatForeignTradeItemsMutation,
    ]);

    useImperativeHandle(ref, () => ({
      validateForm: async () => {
        let tabBeingValidated = 0;

        try {
          for (
            tabBeingValidated = 0;
            tabBeingValidated < tabsRef.current.length;
            tabBeingValidated += 1
          ) {
            //* Regra do ESLint desabilitada, pois nesse caso ha necessidade
            //* de aguardar a validacao de cada aba para indicar ao usuario
            //* qual aba possui erro
            // eslint-disable-next-line no-await-in-loop
            await tabsRef.current[tabBeingValidated]?.validateForm();
          }

          return true;
        } catch (error) {
          setSelectedMenuItem(pageMenuItemKey);

          if (tabBeingValidated !== undefined) {
            foreignTradesDispatch({
              type: ForeignTradeReducerActionKind.SET_ACTIVE_TAB_INDEX,
              payload: { tabActiveIndex: tabBeingValidated },
            });
          }

          toastRef.current?.show({
            severity: 'warn',
            summary: 'Foreign Trade tab',
            detail: 'Please fill all the required fields',
            life: ToastLife.WARN,
          });

          throw new ValidationError(error);
        }
      },
      submitForm: async () => {
        return handleSubmitForeignTrade();
      },
      refetchAndFocus: async () => {
        setSelectedMenuItem(pageMenuItemKey);

        if (tabs.length) {
          await handleRefreshForeignTrade();
        }
      },
      refreshForm: async () => {
        if (tabs.length) {
          await handleRefreshForeignTrade();
        }
      },
      checkForApiIssuesBeforeSave: async () => {
        const formData = getItemsToUpdate();

        return checkDivergentConsolidatedData(formData);
      },
      getTabsWhereNoReportFieldWasChecked: () => {
        // Valida quais abas o campo No Report foi selecionado e retorna os indices das abas
        const tabsChecked = [];
        for (
          let tabBeingValidated = 0;
          tabBeingValidated < tabsRef.current.length;
          tabBeingValidated += 1
        ) {
          const wasFieldChecked =
            tabsRef.current[
              tabBeingValidated
            ]?.checkIfNoReportFieldIsSelected();
          if (wasFieldChecked) tabsChecked.push(tabBeingValidated);
        }

        return tabsChecked;
      },
      updateTabsWhereNoReportFieldWasChecked: (tabsIndexes: number[]) => {
        tabsIndexes?.map(index => {
          return tabsRef.current[index]?.updateWhenNoReportIsSelected();
        });
      },
    }));

    function handleSubmitDivergentForeignTrades(
      divergentData: SelectedDivergentData[],
    ) {
      foreignTradesDispatch({
        type: ForeignTradeReducerActionKind.SET_DIVERGENT_DATA,
        payload: {
          divergentData,
        },
      });

      handleSaveSat();
    }

    useEffect(() => {
      if (!tabs.length && selected) {
        loadForeignTradesData();
      }
    }, [tabs.length, loadForeignTradesData, selected]);

    return (
      <PageTabContainer selected={selected}>
        <Container>
          {!foreignTradesLoading && !!tabs.length && (
            <TabView
              scrollable
              activeIndex={foreignTradesState.tabActiveIndex}
              onTabChange={e =>
                foreignTradesDispatch({
                  type: ForeignTradeReducerActionKind.SET_ACTIVE_TAB_INDEX,
                  payload: { tabActiveIndex: e.index },
                })
              }
              renderActiveOnly={false}
            >
              {tabs.map((tab: ForeignTradeTab, index: number) => {
                return (
                  <TabPanel
                    key={tab.idSatForeignTrade}
                    header={tab.satForeignTradeNumber}
                  >
                    <ForeignTradeContent
                      ref={el => {
                        tabsRef.current[index] = el;
                      }}
                      idSatForeignTrade={tab.idSatForeignTrade}
                      selected={foreignTradesState.tabActiveIndex === index}
                      sat={sat}
                      fieldsPermissions={fieldsPermissions}
                      commonWithSatFieldsPermissions={
                        commonWithSatFieldsPermissions
                      }
                      foreignTradesDispatch={foreignTradesDispatch}
                      foreignTradesState={foreignTradesState}
                      containerItemsPermissions={containerItemsPermissions}
                      addForeignTradeItemModalRef={addForeignTradeItemModalRef}
                      containerItemsRef={containerItemsRef}
                      userCanObjects={userCanObjects}
                      foreignTradeFileTypesLoading={
                        foreignTradeFileTypesLoading
                      }
                      foreignTradeFileTypesData={
                        foreignTradeFileTypesData?.listDomainsByGroupId.data
                      }
                      isSatCancelled={isSatCancelled}
                      userPermissionsSat={userPermissions}
                      financialRef={financialRef}
                      foreignTradeRef={
                        ref as React.RefObject<ISatForeignTradeRef>
                      }
                    />
                  </TabPanel>
                );
              })}
            </TabView>
          )}

          {foreignTradesLoading && (
            <>
              <Skeleton height="3rem" className="p-mb-3" />

              <Skeleton width="10rem" height="2.8rem" />

              {[...Array(4)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="p-d-flex p-mt-4" key={i}>
                  <Skeleton className="p-mr-3" height="3rem" width="16%" />
                  <Skeleton className="p-mr-3" height="3rem" width="16%" />
                  <Skeleton className="p-mr-3" height="3rem" width="16%" />
                  <Skeleton className="p-mr-3" height="3rem" width="16%" />
                  <Skeleton className="p-mr-3" height="3rem" width="16%" />
                </div>
              ))}
            </>
          )}

          {!foreignTradesLoading && !tabs.length && (
            <Empty message="There is no Foreign Trades for this SAT" />
          )}
        </Container>

        <AvailableSatItemsModal
          ref={addForeignTradeItemModalRef}
          idSat={sat.idSat}
          idSatForeignTrade={
            tabs[foreignTradesState.tabActiveIndex]?.idSatForeignTrade
          }
          containerItemsRef={containerItemsRef}
          financialRef={financialRef}
        />

        <DivergentForeignTradesModal
          ref={divergentForeignTradesRef}
          onSubmit={e => handleSubmitDivergentForeignTrades(e)}
          getDataMode="saveForeignTrade"
        />
      </PageTabContainer>
    );
  },
);

export default ForeignTrade;
