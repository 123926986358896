/* eslint-disable no-return-assign */
import React, { Dispatch, SetStateAction, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Container } from './styles';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import Button from '../../../../components/Button';
import AddContact from './AddContact';
import { ISupplierContact } from '../interfaces';
import generateRandomString from '../../../../utils/generateRandomString';
import { useRefHook } from '../../../../hooks/useRefHook';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { supplierRoles } from '../../../../shared/roles/supplier';
import getFieldPermission from '../../../../utils/getFieldPermission';

interface IContactProps extends PageTabContainerProps {
  supplierContacts: ISupplierContact[];
  setSupplierContacts: Dispatch<SetStateAction<ISupplierContact[]>>;
  roleEntityFields: IRoleEntityField[];
}

const Contact: React.FC<IContactProps> = ({
  selected,
  supplierContacts,
  setSupplierContacts,
  roleEntityFields,
}) => {
  // Referencia ao formulario
  const { formRef } = useRefHook();

  // Contacts selecionados
  const [selectedContacts, setSelectedContacts] = useState<ISupplierContact[]>(
    [],
  );

  // Contact utilizado na modal
  const [newContact, setNewContact] = useState<ISupplierContact>(
    {} as ISupplierContact,
  );

  // Estado para exibir modal
  const [displayImageModal, setDisplayImageModal] = useState(false);

  /**
   * Exibe modal
   */
  const onClick = () => {
    setNewContact({
      ...newContact,
      idSupplierContact: generateRandomString(4),
    });
    setDisplayImageModal(true);
  };

  /**
   * Oculta modal
   */
  const onHide = () => {
    setDisplayImageModal(false);
  };

  function onConfirmDialog() {
    if (!newContact.name) {
      formRef.current?.setFieldError('nameContact', 'Contact name is required');
      return;
    }

    // Busca indice do item editado
    const itemAlreadExists = supplierContacts.findIndex(
      item => item.idSupplierContact === newContact.idSupplierContact,
    );

    // Valida se item ja existe na lista
    if (itemAlreadExists !== -1) {
      // Se existir, atualiza informacoes
      const newItems = [...supplierContacts];
      newItems[itemAlreadExists] = newContact;
      setSupplierContacts(newItems);
    } else {
      // Se nao, adiciona na lista
      setSupplierContacts([...supplierContacts, newContact]);
    }
    setNewContact({} as ISupplierContact);
    onHide();
  }

  /**
   * Footer da modal
   * @returns Footer
   */
  const dialogFooter = () => {
    return (
      <div style={{ display: 'flex', placeContent: 'end' }}>
        <Button
          label="Confirm"
          icon="pi pi-check"
          onClick={() => {
            onConfirmDialog();
          }}
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => {
            setNewContact({} as ISupplierContact);
            onHide();
          }}
          className="p-button-danger"
          severity="danger"
        />
      </div>
    );
  };

  /**
   * Deleta contacts selecionados
   */
  function handleDeleteSelected() {
    const newContacts = supplierContacts.filter(
      contact => !selectedContacts.includes(contact),
    );

    setSupplierContacts(newContacts);
    setSelectedContacts([]);
  }

  /**
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: any) {
    if (e.originalEvent.target.className.includes('p-checkbox')) {
      return;
    }

    setNewContact(e.data);
    setDisplayImageModal(true);
  }

  const gridHeader = (
    <div className="buttons-header">
      <Button label="Add Contact" onClick={() => onClick()} />
      <Button
        label="Delete selected"
        severity="danger"
        onClick={() => handleDeleteSelected()}
        disabled={!selectedContacts.length}
      />
    </div>
  );

  const { idFieldEmail, idFieldIdRole, idFieldTelephone } =
    supplierRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  return (
    <PageTabContainer selected={selected}>
      <Dialog
        header="Add a Contact"
        visible={displayImageModal}
        style={{ width: '40vw' }}
        onHide={() => onHide()}
        closable={false}
        footer={dialogFooter()}
      >
        <AddContact
          setNewContact={setNewContact}
          newContact={newContact}
          roleEntityFields={roleEntityFields}
        />
      </Dialog>
      <Container>
        <DataTable
          value={supplierContacts}
          header={gridHeader}
          selection={selectedContacts}
          onSelectionChange={e => setSelectedContacts(e.value)}
          emptyMessage="No contacts found"
          onRowClick={onRowClick}
          selectionMode="checkbox"
        >
          <Column
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
          {showField(idFieldIdRole) && (
            <Column field="idRole2.description" header="Role" sortable />
          )}
          <Column field="name" header="Name" sortable />
          {showField(idFieldEmail) && (
            <Column field="email" header="E-mail" sortable />
          )}
          {showField(idFieldTelephone) && (
            <Column field="telephone" header="Telephone" sortable />
          )}
        </DataTable>
      </Container>
    </PageTabContainer>
  );
};

export default Contact;
