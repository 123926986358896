import { useLazyQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import FormTitle from '../../../../../../components/FormTitle';
import Grid from '../../../../../../components/Grid';
import MainButton from '../../../../../../components/MainButton';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { satRncCostsRoles } from '../../../../../../shared/roles/sat';
import { IUserFieldsAndPermissionsResponse } from '../../../../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../../../../utils/userHasPermission';
import { ISat, ISatRnc } from '../../../interfaces';
import { GridActions } from '../RncItems/styles';
import AddRncCostModal from './AddRncCostsModal';
import { SatRncCost } from './interfaces';
import {
  deleteSatRncCostQuery,
  listSatRncCostsBySatRncIdQuery,
} from './queries';
import { Container } from './styles';

interface IRncCostsProps {
  sat: ISat;
  satRnc: ISatRnc;
  selected: boolean;
  rncCosts?: SatRncCost[];
  isReadOnly: boolean;
  userPermissions: IUserFieldsAndPermissionsResponse;
  setRncCosts: React.Dispatch<React.SetStateAction<SatRncCost[] | undefined>>;
}

const RncCosts: React.FC<IRncCostsProps> = ({
  sat,
  satRnc,
  selected,
  rncCosts,
  isReadOnly,
  setRncCosts,
  userPermissions,
}) => {
  const { toastRef } = useRefHook();

  const [rncCostToEdit, setRncCostToEdit] = useState<SatRncCost>();

  const [displayAddCostModal, setDisplayAddItemModal] = useState(false);

  const [itemBeingDeleted, setItemBeingDeleted] = useState<number>();

  const showToast = useCallback(
    (severity: string, summary: string, life: number, detail?: string) => {
      toastRef.current?.show({ summary, detail, severity, life });
    },
    [toastRef],
  );

  const showError = useCallback(
    (summary: string, detail?: string) => {
      showToast('error', summary, ToastLife.ERROR, detail);
    },
    [showToast],
  );

  const showSuccess = useCallback(
    (summary: string, detail?: string) => {
      showToast('success', summary, ToastLife.SUCCESS, detail);
    },
    [showToast],
  );

  const permissions = useMemo(() => {
    return {
      addItem: userHasPermission(
        satRncCostsRoles.permissions.idPermissionAddItem,
        userPermissions.userPermissions,
      ),
      editItem: userHasPermission(
        satRncCostsRoles.permissions.idPermissionEditItem,
        userPermissions.userPermissions,
      ),
      removeItem: userHasPermission(
        satRncCostsRoles.permissions.idPermissionRemoveItem,
        userPermissions.userPermissions,
      ),
    };
  }, [userPermissions.userPermissions]);

  const [
    loadRncCostsData,
    { loading: rncCostsLoading, refetch: refetchRncCosts },
  ] = useLazyQuery(listSatRncCostsBySatRncIdQuery, {
    variables: {
      idSatRnc: satRnc.idSatRnc,
    },
    onCompleted: response => {
      if (response.listSatRncCostsBySatRncId) {
        setRncCosts(response.listSatRncCostsBySatRncId);
      }
    },
    onError: errorData => {
      showError('Error while getting RNC Costs', errorData.message);
    },
  });

  useEffect(() => {
    if (!rncCosts && selected) {
      loadRncCostsData();
    }
  }, [loadRncCostsData, rncCosts, selected]);

  const getLocaleString = useCallback(
    (value: number | undefined, prefix?: string, sufix?: string) => {
      if (value) {
        return `${prefix ?? ''} ${value.toLocaleString('pt', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}${sufix ?? ''}`;
      }
      return '';
    },
    [],
  );

  const getCostAmountBody = useCallback(
    (rowData: any) => {
      const costAmount = rowData.amount;
      return getLocaleString(costAmount);
    },
    [getLocaleString],
  );

  const onAddItemClick = useCallback(() => {
    setDisplayAddItemModal(true);
  }, []);

  const handleEditItem = useCallback((satRncCost: SatRncCost) => {
    setRncCostToEdit(satRncCost);
    setDisplayAddItemModal(true);
  }, []);

  const [deleteSatRncCostMutation] = useMutation(deleteSatRncCostQuery);

  const handleDeleteItem = useCallback(
    async (satRncCostId: number) => {
      setItemBeingDeleted(satRncCostId);
      try {
        await deleteSatRncCostMutation({
          variables: {
            satRncCostId,
          },
        });
        showSuccess('SAT RNC Cost deleted');
        refetchRncCosts();
      } catch (error) {
        showError('Error while deleting SAT RNC Cost', error.message);
      } finally {
        setItemBeingDeleted(undefined);
      }
    },
    [deleteSatRncCostMutation, refetchRncCosts, showError, showSuccess],
  );

  const gridActions = (rowData: SatRncCost) => {
    const isBeingDeleted = itemBeingDeleted === rowData.idSatRncCost;

    return (
      <GridActions>
        {permissions.editItem && (
          <button
            type="button"
            onClick={() => {
              if (rowData.idSatRncCost) {
                handleEditItem(rowData);
              }
            }}
            disabled={!!itemBeingDeleted || isReadOnly}
          >
            <FiEdit2 size={20} />
          </button>
        )}
        {permissions.removeItem && (
          <button
            type="button"
            className="trash-button"
            onClick={() =>
              confirmDialog({
                message: 'Do you confirm to remove cost from RNC?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: () => {
                  if (rowData.idSatRncCost) {
                    handleDeleteItem(rowData.idSatRncCost);
                  }
                },
              })
            }
            disabled={!!itemBeingDeleted || isReadOnly}
          >
            {isBeingDeleted ? (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: '1.43rem' }}
              />
            ) : (
              <FiTrash2 size={20} />
            )}
          </button>
        )}
      </GridActions>
    );
  };

  useEffect(() => {
    if (!displayAddCostModal) {
      setRncCostToEdit({});
    }
  }, [displayAddCostModal]);

  return (
    <Container>
      <Dialog
        header="Add RNC Item"
        visible={displayAddCostModal}
        onHide={() => setDisplayAddItemModal(false)}
      >
        <AddRncCostModal
          sat={sat}
          satRnc={satRnc}
          satRncCost={rncCostToEdit}
          rncCostsRefetch={refetchRncCosts}
          setDisplayModal={setDisplayAddItemModal}
        />
      </Dialog>
      <FormTitle className="p-col-12 p-md-12 p-mt-5" name="RNC Costs">
        {permissions.addItem && (
          <MainButton
            type="button"
            label="Add Item"
            onClick={() => onAddItemClick()}
            disabled={isReadOnly}
          />
        )}
      </FormTitle>
      <Grid
        responsiveLayout="scroll"
        emptyMessage="No costs found"
        loading={rncCostsLoading}
        value={rncCosts}
        paginator={false}
        editMode="row"
      >
        {permissions.removeItem && (
          <Column
            field="actions"
            headerStyle={{ width: '4rem' }}
            body={gridActions}
          />
        )}
        <Column
          field="description"
          header="Cost Description"
          style={{ width: '24rem' }}
        />
        <Column
          field="idCurrency2.abbreviation"
          header="Cost Currency"
          style={{ width: '6rem' }}
        />
        <Column
          field="amount"
          header="Cost Amount"
          style={{ width: '6rem' }}
          body={getCostAmountBody}
        />
      </Grid>
    </Container>
  );
};
export default RncCosts;
