import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { InputMask } from 'primereact/inputmask';
import React, { Ref, useImperativeHandle, useReducer, useState } from 'react';
import Button from '../../../../../../../components/Button';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../../shared/enums/toastLife';
import { SatForeignTradeShipmentContainer } from '../../interfaces';
import { ManageForeignTradeContainerReducerActionKind } from './interfaces';
import {
  createSatForeignTradeContainerQuery,
  updateSatForeignTradeContainerQuery,
} from './queries';
import {
  manageForeignTradeContainerInitialState,
  manageForeignTradeContainerReducer,
} from './reducers';

import { Container } from './styles';

export interface IManageContainerRef {
  toggleManageContainerDialog(
    shimpentContainer?: SatForeignTradeShipmentContainer,
  ): void;
}

interface IManageContainerProps {
  ref: Ref<IManageContainerRef>;
  idSatForeignTrade: number;
  shipmentContainersRefetch(): Promise<ApolloQueryResult<any>>;
}

const ManageContainer: React.FC<IManageContainerProps> = React.forwardRef(
  ({ idSatForeignTrade, shipmentContainersRefetch }, ref) => {
    const { toastRef } = useRefHook();

    const [manageContainerReducer, manageContainerDispatch] = useReducer(
      manageForeignTradeContainerReducer,
      manageForeignTradeContainerInitialState,
    );

    const [loading, setLoading] = useState(false);

    const [createSatForeignTradeContainerMutation] = useMutation(
      createSatForeignTradeContainerQuery,
    );

    const [updateSatForeignTradeContainerMutation] = useMutation(
      updateSatForeignTradeContainerQuery,
    );

    useImperativeHandle(ref, () => ({
      toggleManageContainerDialog: shimpentContainer => {
        manageContainerDispatch({
          type: ManageForeignTradeContainerReducerActionKind.OPEN_DIALOG,
          payload: { shimpentContainer },
        });
      },
    }));

    async function handleSaveContainer() {
      setLoading(true);

      try {
        if (manageContainerReducer.idContainerToEdit) {
          await updateSatForeignTradeContainerMutation({
            variables: {
              data: {
                idSatForeignTradeCtnr: manageContainerReducer.idContainerToEdit,
                containerName: manageContainerReducer.containerName,
              },
            },
          });
        } else {
          await createSatForeignTradeContainerMutation({
            variables: {
              data: {
                idSatForeignTrade,
                containerName: manageContainerReducer.containerName,
              },
            },
          });
        }

        await shipmentContainersRefetch();

        toastRef.current?.show({
          severity: 'success',
          summary: `Shipment Container ${
            manageContainerReducer.idContainerToEdit ? 'updated' : 'created'
          }`,
          life: ToastLife.SUCCESS,
        });

        manageContainerDispatch({
          type: ManageForeignTradeContainerReducerActionKind.CLOSE_DIALOG,
        });
      } catch (error) {
        toastRef.current?.show({
          severity: 'error',
          summary: `Error while ${
            manageContainerReducer.idContainerToEdit ? 'updating' : 'creating'
          } Shipment Container`,
          detail: error.message,
          life: ToastLife.ERROR,
        });
      } finally {
        setLoading(false);
      }
    }

    const dialogFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button
            label="Save"
            icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
            onClick={() => handleSaveContainer()}
            disabled={!manageContainerReducer.containerName || loading}
          />
          <Button
            label="Cancel"
            severity="danger"
            icon="pi pi-times"
            onClick={() =>
              manageContainerDispatch({
                type: ManageForeignTradeContainerReducerActionKind.CLOSE_DIALOG,
              })
            }
            disabled={loading}
          />
        </div>
      );
    };

    return (
      <Dialog
        header={
          manageContainerReducer.idContainerToEdit
            ? 'Manage Container'
            : 'Add Container'
        }
        visible={manageContainerReducer.displayDialog}
        style={{ width: '400px' }}
        modal
        onHide={() =>
          manageContainerDispatch({
            type: ManageForeignTradeContainerReducerActionKind.CLOSE_DIALOG,
          })
        }
        footer={dialogFooter()}
        closable={false}
      >
        <Container>
          <InputMask
            mask="aaaa9999999"
            placeholder="XXXX0000000"
            value={manageContainerReducer.containerName}
            onChange={e =>
              manageContainerDispatch({
                type: ManageForeignTradeContainerReducerActionKind.SET_CONTAINER_NAME,
                payload: { containerName: e.value },
              })
            }
            disabled={loading}
          />
        </Container>
      </Dialog>
    );
  },
);

export default ManageContainer;
