/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { Form } from '@unform/web';
import React, {
  Dispatch,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { FaTimes } from 'react-icons/fa';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Container, CtnrBox } from './styles';

import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import FormBooleanRadio from '../../../../components/FormBooleanRadio';
import FormDropdown from '../../../../components/FormDropdown';
import FormInput from '../../../../components/FormInput';
import FormInputMask from '../../../../components/FormInputMask';
import FormInputNumber from '../../../../components/FormInputNumber';
import FormTreeSelectCategory from '../../../../components/FormTreeSelectCategory';
import MainButton from '../../../../components/MainButton';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { useRefHook } from '../../../../hooks/useRefHook';
import { IClient } from '../../../../interfaces/IClient';
import { DomainGroup } from '../../../../shared/enums/domainGroup';
import ToastLife from '../../../../shared/enums/toastLife';
import { asyncSelectLoadDomains } from '../../../../shared/querys/domain';
import generateRandomString from '../../../../utils/generateRandomString';
import {
  IClientQuotation,
  IClientQuotationContainer,
  ISupplier,
} from '../interfaces';
import {
  createClientQuotation,
  createClientQuotationQuery,
  updateClientQuotation,
  updateClientQuotationQuery,
} from '../../../../shared/mutations/clientQuotation';
import getValidationErrors from '../../../../utils/getValidationErrors';
import getFieldPermission from '../../../../utils/getFieldPermission';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { clientQuotationRoles } from '../../../../shared/roles/clientQuotation';

export interface IClientQuotationGeneralInformationRef {
  handleSaveGeneralInformation(): Promise<any>;
  validateGeneralInformationForm(): Promise<void>;
}
interface GeneralInformationProps extends PageTabContainerProps {
  clientQuotation: IClientQuotation;
  changePropertyValue(property: string, value: any): void;
  idCq?: number;
  ref: Ref<IClientQuotationGeneralInformationRef>;
  setSelectedMenuItem: Dispatch<React.SetStateAction<string>>;
  setClientQuotation: Dispatch<React.SetStateAction<IClientQuotation>>;
  pageMenuItemKey: string;
  roleEntityFields: IRoleEntityField[];
}

const GeneralInformation: React.FC<GeneralInformationProps> = React.forwardRef(
  (
    {
      selected,
      clientQuotation,
      changePropertyValue,
      idCq,
      setSelectedMenuItem,
      pageMenuItemKey,
      roleEntityFields,
      setClientQuotation,
    },
    ref,
  ) => {
    const { toastRef } = useRefHook();

    const formRef = useRef<FormHandles>(null);

    const client = useApolloClient();

    const loadClientsOptions: LoadOptions<
      any,
      GroupBase<any>,
      { page: any }
    > = async (search: string, prevOptions: any, pageData) => {
      const res = await client.query({
        query: gql`
          query listAllClientsQuery(
            $listAllClientsInput: ListAllClientsInput!
          ) {
            listAllClients(listAllClientsInput: $listAllClientsInput) {
              data {
                idClient
                name
                companyName
                cnpj
                address
                clientPorts {
                  idPort2 {
                    idPort
                    name
                  }
                }
                paymentAdvance
                paymentBalance
                payment2 {
                  idDomain
                  description
                }
              }
              items
            }
          }
        `,
        variables: {
          listAllClientsInput: {
            pagination: {
              _page: pageData?.page,
              _limit: 25,
            },
            name: search,
            active: true,
          },
        },
      });

      const filteredOptions = res.data.listAllClients.data;
      const quantity = res.data.listAllClients.items;

      const hasMore = quantity > prevOptions.length + 25;

      return {
        options: filteredOptions,
        hasMore,
        additional: {
          page: pageData?.page + 1,
        },
      };
    };

    const loadClientContactsOptions: LoadOptions<
      any,
      GroupBase<any>,
      { page: any }
    > = async (search: string, prevOptions: any, pageData) => {
      const res = await client.query({
        query: gql`
          query listAllClientsQuery(
            $listClientContactsByCliendIdInput: ListClientContactsByCliendIdInput!
          ) {
            listClientContactsByCliendId(
              listClientContactsByCliendIdInput: $listClientContactsByCliendIdInput
            ) {
              data {
                idClientContact
                name
              }
              items
            }
          }
        `,
        variables: {
          listClientContactsByCliendIdInput: {
            idClient: clientQuotation.idClient,
            pagination: {
              _page: pageData?.page,
              _limit: 25,
            },
            name: search,
          },
        },
      });

      const filteredOptions = res.data.listClientContactsByCliendId.data;
      const quantity = res.data.listClientContactsByCliendId.items;

      const hasMore = quantity > prevOptions.length + 25;

      return {
        options: filteredOptions,
        hasMore,
        additional: {
          page: pageData?.page + 1,
        },
      };
    };

    const loadPortsOptions: LoadOptions<
      any,
      GroupBase<any>,
      { page: any; idCountry?: number }
    > = async (search: string, prevOptions: any, pageData) => {
      const res = await client.query({
        query: gql`
          query listAllPortsQuery($listAllPortsInput: ListAllPortsInput!) {
            listAllPorts(listAllPortsInput: $listAllPortsInput) {
              data {
                idPort
                name
                idCountry
                idCountry2 {
                  idCountry
                  name
                }
              }
              items
            }
          }
        `,
        variables: {
          listAllPortsInput: {
            _page: pageData?.page,
            _limit: 25,
            name: search,
            idCountry: pageData?.idCountry,
          },
        },
      });

      const filteredOptions = res.data.listAllPorts.data;
      const quantity = res.data.listAllPorts.items;

      const hasMore = quantity > prevOptions.length + 25;

      return {
        options: filteredOptions,
        hasMore,
        additional: {
          page: pageData?.page + 1,
          idCountry: pageData?.idCountry,
        },
      };
    };

    const loadSuppliersOptions: LoadOptions<
      any,
      GroupBase<any>,
      { page: any }
    > = async (search: string, prevOptions: any, pageData) => {
      const res = await client.query({
        query: gql`
          query listAllSuppliers($data: ListAllSuppliersInput!) {
            listAllSuppliers(data: $data) {
              data {
                idSupplier
                name
                address
                sunNumber
                idCountry
                idCountry2 {
                  idCountry
                  name
                }
                idIncoterm
                idIncotermSecond
              }
              items
            }
          }
        `,
        variables: {
          data: {
            pagination: {
              _page: pageData?.page,
              _limit: 25,
            },
            name: search,
            active: true,
            exporter: true,
          },
        },
      });

      const filteredOptions = res.data.listAllSuppliers.data;
      const quantity = res.data.listAllSuppliers.items;

      const hasMore = quantity > prevOptions.length + 25;

      return {
        options: filteredOptions,
        hasMore,
        additional: {
          page: pageData?.page + 1,
        },
      };
    };

    const listDomainQuery = gql`
      query ListDomainsByGroupIdQuery(
        $id: Int!
        $pagination: Pagination!
        $description: String
      ) {
        listDomainsByGroupId(
          id: $id
          pagination: $pagination
          description: $description
        ) {
          data {
            idDomain
            description
          }
          items
        }
      }
    `;

    /**
     * Busca Incoterm
     */
    const [
      loadIncoterm,
      {
        called: incotermCalled,
        loading: incotermLoading,
        data: incotermData,
        error: incotermError,
      },
    ] = useLazyQuery(listDomainQuery, {
      variables: {
        id: DomainGroup.INCOTERM,
        pagination: {
          _page: 0,
          _limit: 0,
        },
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting incoterm',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

    const showToast = useCallback(
      (severity: string, summary: string, life: number, detail?: string) => {
        toastRef.current?.show({ summary, detail, severity, life });
      },
      [toastRef],
    );

    const showSuccess = useCallback(
      (summary: string, detail?: string) => {
        showToast('success', summary, ToastLife.SUCCESS, detail);
      },
      [showToast],
    );

    const showWarn = useCallback(
      (summary: string, detail?: string) => {
        showToast('warn', summary, ToastLife.WARN, detail);
      },
      [showToast],
    );

    const showError = useCallback(
      (summary: string, detail?: string) => {
        showToast('error', summary, ToastLife.ERROR, detail);
      },
      [showToast],
    );

    function addCtnr(): void {
      let newContainers: IClientQuotationContainer[];
      if (clientQuotation.cqCtnrs) {
        newContainers = [
          ...clientQuotation.cqCtnrs,
          {
            idCqCtnr: generateRandomString(4),
            idCq: clientQuotation.idCq ? clientQuotation.idCq : undefined,
          } as IClientQuotationContainer,
        ];
      } else {
        newContainers = [
          {
            idCqCtnr: generateRandomString(4),
            idCq: clientQuotation.idCq ? clientQuotation.idCq : undefined,
          } as IClientQuotationContainer,
        ];
      }
      changePropertyValue('cqCtnrs', newContainers);
    }

    function removeCtnr(index: number): void {
      if (clientQuotation.cqCtnrs) {
        const newCqCtnrs = [...clientQuotation.cqCtnrs];
        newCqCtnrs.splice(index, 1);
        changePropertyValue('cqCtnrs', newCqCtnrs);
      }
    }

    const onClientChange = useCallback(
      (e: IClient) => {
        formRef.current?.setFieldValue('clientCompanyName', e.companyName);
        formRef.current?.setFieldValue('clientCnpj', e.cnpj);
        formRef.current?.setFieldValue('clientAddress', e.address);
        formRef.current?.clearField('idFirstBuyer');
        formRef.current?.clearField('idSecondBuyer');
        formRef.current?.setFieldValue(
          'idPortOfDischarge',
          e.clientPorts?.length ? e.clientPorts[0].idPort2 : null,
        );
        formRef.current?.setFieldValue('paymentTermAdvance', e.paymentAdvance);
        formRef.current?.setFieldValue('paymentTermBalance', e.paymentBalance);
        formRef.current?.setFieldValue('idPaymentTermCondition', e.payment2);
        changePropertyValue('idClient', e.idClient);
        changePropertyValue(
          'idPortOfDischarge2',
          e.clientPorts?.length ? e.clientPorts[0].idPort2 : null,
        );
      },
      [changePropertyValue],
    );

    const onPurchaseIncotermChange = useCallback(
      (idSelectedIncotem: number) => {
        const cqSuppliersHaveThisIncoterm =
          clientQuotation.suppliersPurchaseIncoterms.find(
            incoterm => incoterm.idDomain === idSelectedIncotem,
          );

        if (!cqSuppliersHaveThisIncoterm) {
          showWarn(
            'Selected Purchase Incoterm is different from the ones informed on Suppliers',
          );
        }
      },

      [clientQuotation.suppliersPurchaseIncoterms, showWarn],
    );

    async function onImporterChange(e: IClient) {
      formRef.current?.setFieldValue('importerCompanyName', e.companyName);
      formRef.current?.setFieldValue('importerCnpj', e.cnpj);
      formRef.current?.setFieldValue('importerAddress', e.address);
    }

    const onExporterChange = useCallback(
      (e: ISupplier) => {
        changePropertyValue('idCountryAcquisition', e.idCountry);
        formRef.current?.setFieldValue('exporterAddress', e.address);
        formRef.current?.setFieldValue(
          'idCountryAcquisition',
          e.idCountry2.name,
        );
      },
      [changePropertyValue],
    );

    async function onPaymentTermAdvanceChange(e: any) {
      const paymentTermBalance = e !== undefined ? 100 - e : 0;
      formRef.current?.setFieldValue('paymentTermBalance', paymentTermBalance);
    }

    function onQtyCtnrChange(e: any, index: number): void {
      const containers = [...clientQuotation.cqCtnrs];
      const container = containers[index];
      container.qtyCtnr = parseInt(e.target.value, 10);
      changePropertyValue('cqCtnrs', containers);
    }

    function onIdTypeCtnrChange(e: any, index: number): void {
      const containers = [...clientQuotation.cqCtnrs];
      const container = containers[index];
      container.idTypeCtnr = parseInt(e.idDomain, 10);
      changePropertyValue('cqCtnrs', containers);
    }

    function onPortOfLoadingChange(e: any): void {
      changePropertyValue('idCountryProcedence', e.idCountry);
      formRef.current?.setFieldValue('idCountryProcedence', e.idCountry2.name);
    }

    const onCategoryChange = (e: any) => {
      if (
        !!clientQuotation.indConfection !== !!e.node.data.indConfection &&
        clientQuotation.indConfection !== undefined
      ) {
        setTimeout(() => {
          formRef.current?.setFieldValue(
            'idCategory',
            clientQuotation.idCategory,
          );
        }, 100);
        showError('This category is not allowed.');
      }
    };

    const [createClientQuotationMutation] = useMutation(
      createClientQuotationQuery,
    );

    const [updateClientQuotationMutation] = useMutation(
      updateClientQuotationQuery,
    );

    const validate = useCallback(
      async (data: any) => {
        try {
          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            mainProduct: Yup.string().required('Required Field'),
            idCategory: Yup.string().required('Required Field'),
            idClient: Yup.number().nullable().required('Required Field'),
            idSellingIncoterm: Yup.number()
              .nullable()
              .required('Required Field'),
            paymentTermAdvance: Yup.number()
              .nullable()
              .required('Required Field'),
            idPaymentTermCondition: Yup.number()
              .nullable()
              .required('Required Field'),
            idPortOfDischarge: Yup.number()
              .nullable()
              .required('Required Field'),
            cqCtnrs: Yup.array().of(
              Yup.object().shape({
                qtyCtnr: Yup.number().nullable().required('Required Field'),
                idTypeCtnr: Yup.number().nullable().required('Required Field'),
              }),
            ),
          });

          await schema.validate(data, { abortEarly: false });
        } catch (error) {
          setSelectedMenuItem(pageMenuItemKey);

          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          const firstError = error.inner[0];
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);
          inputWithError.focus();
          showWarn(error.message);
          throw error;
        }
      },
      [pageMenuItemKey, setSelectedMenuItem, showWarn],
    );

    const handleSubmit = useCallback(
      async (data: any) => {
        try {
          const containers = clientQuotation.cqCtnrs?.map(container => {
            return {
              ...container,
              idCqCtnr:
                typeof container.idCqCtnr === 'number'
                  ? container.idCqCtnr
                  : undefined,
              idTypeCtnr2: undefined,
            };
          });

          const cqData: IClientQuotation = {
            mainProduct: data.mainProduct ? data.mainProduct : undefined,
            idCategory: data.idCategory ? data.idCategory : undefined,
            remarks: data.remarks ? data.remarks : undefined,
            packageNotes: data.packageNotes ? data.packageNotes : undefined,
            indSamples: data.indSamples ? data.indSamples : undefined,
            samplesLeadTime: data.samplesLeadTime
              ? data.samplesLeadTime
              : undefined,
            idClient: data.idClient ? data.idClient : undefined,
            clientCompanyName: data.clientCompanyName
              ? data.clientCompanyName
              : undefined,
            clientCnpj: data.clientCnpj ? data.clientCnpj : undefined,
            clientAddress: data.clientAddress ? data.clientAddress : undefined,
            idFirstBuyer: data.idFirstBuyer ? data.idFirstBuyer : undefined,
            idSecondBuyer: data.idSecondBuyer ? data.idSecondBuyer : undefined,
            idSellingIncoterm: data.idSellingIncoterm
              ? data.idSellingIncoterm
              : undefined,
            paymentTermAdvance:
              data.paymentTermAdvance !== undefined
                ? data.paymentTermAdvance
                : undefined,
            paymentTermBalance: data.paymentTermBalance
              ? data.paymentTermBalance
              : 0,
            idPaymentTermCondition: data.idPaymentTermCondition
              ? data.idPaymentTermCondition
              : undefined,
            idImporter: data.idImporter ? data.idImporter : undefined,
            importerCompanyName: data.importerCompanyName
              ? data.importerCompanyName
              : undefined,
            importerCnpj: data.importerCnpj ? data.importerCnpj : undefined,
            importerAddress: data.importerAddress
              ? data.importerAddress
              : undefined,
            idExporter: data.idExporter ? data.idExporter : undefined,
            exporterAddress: data.exporterAddress
              ? data.exporterAddress
              : undefined,
            idPurchaseIncoterm: data.idPurchaseIncoterm
              ? data.idPurchaseIncoterm
              : undefined,
            productionTime: data.productionTime
              ? data.productionTime
              : undefined,
            idPortOfLoading: data.idPortOfLoading
              ? data.idPortOfLoading
              : undefined,
            idPortOfDischarge: data.idPortOfDischarge
              ? data.idPortOfDischarge
              : undefined,
            idCountryOrigin: clientQuotation.idCountryOrigin
              ? clientQuotation.idCountryOrigin
              : undefined,
            idCountryAcquisition: clientQuotation.idCountryAcquisition
              ? clientQuotation.idCountryAcquisition
              : undefined,
            idCountryProcedence: clientQuotation.idCountryProcedence
              ? clientQuotation.idCountryProcedence
              : undefined,
            estimatedInspection: data.estimatedInspection
              ? data.estimatedInspection
              : undefined,
            estimatedShipment: data.estimatedShipment
              ? data.estimatedShipment
              : undefined,
            estimatedArrival: data.estimatedArrival
              ? data.estimatedArrival
              : undefined,
            estimatedArrivalAtClient: data.estimatedArrivalAtClient
              ? data.estimatedArrivalAtClient
              : undefined,
            cqCtnrs: containers || undefined,
          } as IClientQuotation;

          let response;

          if (!idCq) {
            response = await createClientQuotation(
              createClientQuotationMutation,
              undefined,
              undefined,
              undefined,
              cqData,
            );
          } else {
            const newCqData = { ...cqData, idCq };
            response = await updateClientQuotation(
              updateClientQuotationMutation,
              newCqData,
            );

            setClientQuotation({
              ...clientQuotation,
              ...response.data.updateClientQuotation,
            });
          }

          // Em caso de sucesso exibe toast
          showSuccess(`Client Quotation ${idCq ? 'updated' : 'created'}`);

          return response;
        } catch (error) {
          showError(
            `Error while ${idCq ? 'updating' : 'creating'} client quotation`,
            error.message,
          );

          throw new Error(error);
        }
      },
      [
        clientQuotation,
        createClientQuotationMutation,
        idCq,
        setClientQuotation,
        showError,
        showSuccess,
        updateClientQuotationMutation,
      ],
    );

    useEffect(() => {
      loadIncoterm();
    }, [loadIncoterm]);

    useImperativeHandle(ref, () => ({
      handleSaveGeneralInformation: async () => {
        const formData = formRef.current?.getData();
        return handleSubmit(formData);
      },
      validateGeneralInformationForm: async () => {
        const formData = formRef.current?.getData();
        await validate(formData);
      },
    }));

    const {
      idFieldCqNumber,
      idFieldMainProduct,
      idFieldCategory,
      idFieldRemarks,
      idFieldPackageNotes,
      idFieldIndSamples,
      idFieldSamplesLeadTime,
      idFieldIdClient,
      idFieldClientCompanyName,
      idFieldClientCnpj,
      idFieldClientAddress,
      idFieldIdFirstBuyer,
      idFieldIdSecondBuyer,
      idFieldIdImporter,
      idFieldImporterCompanyName,
      idFieldImporterCnpj,
      idFieldImporterAddress,
      idFieldIdSellingIncoterm,
      idFieldPaymentTermAdvance,
      idFieldPaymentTermBalance,
      idFieldIdPaymentTermCondition,
      idFieldIdExporter,
      idFieldExporterAddress,
      idFieldIdPurchaseIncoterm,
      idFieldProductionTime,
      idFieldIdSupplier,
      idFieldIdPortOfLoading,
      idFieldIdPortOfDischarge,
      idFieldIdCountryOrigin,
      idFieldIdCountryAcquisition,
      idFieldIdCountryProcedence,
      idFieldEstimatedInspection,
      idFieldEstimatedShipment,
      idFieldEstimatedArrival,
      idFieldEtimatedArrivalAtClient,
      idFieldPurchaseUser,
      idFieldSalesUser,
      idFieldApprovedOn,
      idFieldQtyCtnr,
    } = clientQuotationRoles.fields;

    function showField(idField: number): boolean {
      return getFieldPermission(idField, roleEntityFields).view;
    }

    return (
      <PageTabContainer selected={selected}>
        <Container>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={clientQuotation}
          >
            <div className="p-d-flex p-flex-wrap">
              <div className="p-col-12">
                <h1>General Information</h1>
              </div>
            </div>
            <div className="p-d-flex p-flex-wrap w-full">
              {showField(idFieldCqNumber) && (
                <FormInput
                  className="p-md-4"
                  name="cqNumber"
                  label="CQ Number"
                  readOnly
                />
              )}
              {showField(idFieldMainProduct) && (
                <FormInput
                  className="p-md-4"
                  name="mainProduct"
                  label="Main Product"
                  required
                />
              )}
              {showField(idFieldCategory) && (
                <FormTreeSelectCategory
                  className="p-md-4"
                  name="idCategory"
                  label="Category"
                  filter
                  placeholder="Select a Category"
                  required
                  initialValue={clientQuotation.idCategory}
                  onNodeSelect={e => onCategoryChange(e)}
                />
              )}
            </div>
            <div className="p-d-flex p-flex-wrap w-full">
              {showField(idFieldRemarks) && (
                <FormInput className="p-md-4" name="remarks" label="Remarks" />
              )}
              {showField(idFieldPackageNotes) && (
                <FormInput
                  className="p-md-4"
                  name="packageNotes"
                  label="Package Notes"
                />
              )}
            </div>
            <div className="p-d-flex p-flex-wrap w-full">
              <div className="p-md-4">
                {showField(idFieldIndSamples) && (
                  <FormBooleanRadio name="indSamples" label="Samples" />
                )}
              </div>
              <div className="p-md-4">
                {showField(idFieldSamplesLeadTime) && (
                  <FormInputNumber
                    name="samplesLeadTime"
                    label="Samples Lead Time"
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                )}
              </div>
            </div>
            <div className="p-d-flex p-flex-wrap">
              <div className="p-col-12">
                <h1>CTNR</h1>
              </div>
              <div className="w-full">
                {showField(idFieldQtyCtnr) && (
                  <CtnrBox>
                    {clientQuotation.cqCtnrs?.map((container, index) => {
                      return (
                        <div
                          key={container.idCqCtnr}
                          className="p-d-flex p-flex-wrap"
                        >
                          <div className="p-md-5">
                            <FormInputNumber
                              name={`cqCtnrs[${index}].qtyCtnr`}
                              label="Qty of CTNR"
                              required
                              onBlur={e => onQtyCtnrChange(e, index)}
                              defaultValue={
                                clientQuotation.cqCtnrs[index].qtyCtnr
                              }
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          </div>
                          <div className="p-md-5">
                            <FormAsyncSelect
                              name={`cqCtnrs[${index}].idTypeCtnr`}
                              label="Type of CTNR"
                              loadOptions={asyncSelectLoadDomains}
                              debounceTimeout={1000}
                              getOptionLabel={(option: any) =>
                                option.description
                              }
                              getOptionValue={(option: any) => option.idDomain}
                              additional={{
                                page: 1,
                                id: DomainGroup.TYPE_OF_CTNR,
                              }}
                              noOptionsMessage={() => 'No types found'}
                              initialValue={
                                clientQuotation.cqCtnrs[index].idTypeCtnr2
                              }
                              required
                              onValueChange={e => onIdTypeCtnrChange(e, index)}
                            />
                          </div>
                          <div className="p-md-2 button-div">
                            <button
                              type="button"
                              onClick={() => removeCtnr(index)}
                            >
                              <FaTimes size={18} />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </CtnrBox>
                )}
              </div>
            </div>
            <div className="p-d-flex p-flex-wrap p-col-12">
              <MainButton type="button" label="Add" onClick={() => addCtnr()} />
            </div>
            <div className="p-d-flex p-flex-wrap">
              <div className="p-col-12">
                <h1>Client/Notify</h1>
              </div>
              <div className="p-d-flex p-flex-wrap w-full">
                {showField(idFieldIdClient) && (
                  <FormAsyncSelect
                    name="idClient"
                    className="p-md-4"
                    label="Client/Notify"
                    loadOptions={loadClientsOptions}
                    debounceTimeout={1000}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.idClient}
                    additional={{
                      page: 1,
                    }}
                    noOptionsMessage={() => 'No clients found'}
                    required
                    initialValue={clientQuotation.idClient2}
                    onValueChange={onClientChange}
                  />
                )}
                {showField(idFieldClientCompanyName) && (
                  <FormInput
                    className="p-md-4"
                    name="clientCompanyName"
                    label="Company Name"
                    readOnly
                  />
                )}
                <div className="p-md-4">
                  {showField(idFieldClientCnpj) && (
                    <FormInputMask
                      name="clientCnpj"
                      label="Tax ID"
                      mask="99.999.999/9999-99"
                      readOnly
                    />
                  )}
                </div>
                {showField(idFieldClientAddress) && (
                  <FormInput
                    className="p-md-4"
                    name="clientAddress"
                    label="Client Address"
                    readOnly
                  />
                )}
                {showField(idFieldIdFirstBuyer) && (
                  <FormAsyncSelect
                    className="p-md-4"
                    name="idFirstBuyer"
                    label="First Buyer"
                    loadOptions={loadClientContactsOptions}
                    debounceTimeout={1000}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.idClientContact}
                    additional={{
                      page: 1,
                    }}
                    noOptionsMessage={() => 'No contacts found'}
                    initialValue={clientQuotation.idFirstBuyer2}
                    cacheUniqs={[clientQuotation]}
                  />
                )}
                {showField(idFieldIdSecondBuyer) && (
                  <FormAsyncSelect
                    className="p-md-4"
                    name="idSecondBuyer"
                    label="Second Buyer"
                    loadOptions={loadClientContactsOptions}
                    debounceTimeout={1000}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.idClientContact}
                    additional={{
                      page: 1,
                    }}
                    noOptionsMessage={() => 'No contacts found'}
                    initialValue={clientQuotation.idSecondBuyer2}
                    cacheUniqs={[clientQuotation]}
                  />
                )}
              </div>
            </div>

            <div className="p-d-flex p-flex-wrap">
              <div className="p-col-12">
                <h1>Importer/Consignee</h1>
              </div>
              <div className="p-d-flex p-flex-wrap w-full">
                {showField(idFieldIdImporter) && (
                  <FormAsyncSelect
                    name="idImporter"
                    className="p-md-4"
                    label="Importer/Consignee"
                    loadOptions={loadClientsOptions}
                    debounceTimeout={1000}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.idClient}
                    additional={{
                      page: 1,
                    }}
                    noOptionsMessage={() => 'No importers found'}
                    initialValue={clientQuotation.idImporter2}
                    onValueChange={onImporterChange}
                  />
                )}
                {showField(idFieldImporterCompanyName) && (
                  <FormInput
                    className="p-md-4"
                    name="importerCompanyName"
                    label="Company Name"
                    readOnly
                  />
                )}
                <div className="p-md-4">
                  {showField(idFieldImporterCnpj) && (
                    <FormInputMask
                      name="importerCnpj"
                      label="Importer Tax ID"
                      mask="99.999.999/9999-99"
                      readOnly
                    />
                  )}
                </div>
                {showField(idFieldImporterAddress) && (
                  <FormInput
                    className="p-md-4"
                    name="importerAddress"
                    label="Importer Address"
                    readOnly
                  />
                )}
              </div>
            </div>
            <div className="p-d-flex p-flex-wrap">
              <div className="p-col-12">
                <h1>Payment</h1>
              </div>
              <div className="p-d-flex p-flex-wrap w-full">
                {showField(idFieldIdSellingIncoterm) && (
                  <FormAsyncSelect
                    name="idSellingIncoterm"
                    className="p-md-4"
                    label="Selling Incoterm"
                    loadOptions={asyncSelectLoadDomains}
                    debounceTimeout={1000}
                    getOptionLabel={(option: any) => option.description}
                    getOptionValue={(option: any) => option.idDomain}
                    additional={{
                      page: 1,
                      id: DomainGroup.INCOTERM,
                    }}
                    noOptionsMessage={() => 'No incoterms found'}
                    initialValue={clientQuotation.idSellingIncoterm2}
                    required
                  />
                )}
              </div>
              <div className="p-d-flex p-flex-wrap w-full">
                <div className="p-md-4">
                  {showField(idFieldPaymentTermAdvance) && (
                    <FormInputNumber
                      name="paymentTermAdvance"
                      label="Payment Term Advance (%)"
                      decimalScale={0}
                      min={0}
                      max={100}
                      suffix="%"
                      required
                      onValueChange={e =>
                        onPaymentTermAdvanceChange(e.floatValue)
                      }
                    />
                  )}
                </div>
                <div className="p-md-4">
                  {showField(idFieldPaymentTermBalance) && (
                    <FormInputNumber
                      name="paymentTermBalance"
                      label="Payment Term Balance (%)"
                      suffix="%"
                      readOnly
                      thousandSeparator="."
                      decimalSeparator=","
                    />
                  )}
                </div>
                {showField(idFieldIdPaymentTermCondition) && (
                  <FormAsyncSelect
                    className="p-md-4"
                    name="idPaymentTermCondition"
                    label="Payment Term Condition"
                    loadOptions={asyncSelectLoadDomains}
                    debounceTimeout={1000}
                    getOptionLabel={(option: any) => option.description}
                    getOptionValue={(option: any) => option.idDomain}
                    additional={{
                      page: 1,
                      id: DomainGroup.PAYMENT_CONDITIONS,
                    }}
                    noOptionsMessage={() => 'No conditions found'}
                    required
                    initialValue={clientQuotation.idPaymentTermCondition2}
                  />
                )}
              </div>
            </div>
            <div className="p-d-flex p-flex-wrap">
              <div className="p-col-12">
                <h1>Exporter</h1>
              </div>
              <div className="p-d-flex p-flex-wrap w-full">
                {showField(idFieldIdExporter) && (
                  <FormAsyncSelect
                    name="idExporter"
                    className="p-md-4"
                    label="Exporter"
                    loadOptions={loadSuppliersOptions}
                    debounceTimeout={1000}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.idSupplier}
                    additional={{
                      page: 1,
                    }}
                    noOptionsMessage={() => 'No suppliers found'}
                    initialValue={clientQuotation.idExporter2}
                    onValueChange={onExporterChange}
                  />
                )}
                {showField(idFieldExporterAddress) && (
                  <FormInput
                    className="p-md-4"
                    name="exporterAddress"
                    label="Exporter Address"
                    readOnly
                  />
                )}
                <div className="p-md-4 purchase-incoterm">
                  {showField(idFieldIdPurchaseIncoterm) && (
                    <FormDropdown
                      className="w-full"
                      name="idPurchaseIncoterm"
                      label="Purchase Incoterm"
                      placeholder={incotermLoading ? 'Loading' : ''}
                      options={
                        incotermCalled && !incotermLoading && !incotermError
                          ? incotermData.listDomainsByGroupId.data
                          : undefined
                      }
                      optionValue="idDomain"
                      optionLabel="description"
                      initialValue={clientQuotation.idPurchaseIncoterm}
                      filter
                      filterBy="description"
                      showClear
                      onValueChange={e => {
                        onPurchaseIncotermChange(e as any);
                      }}
                    />
                  )}
                </div>
                <div className="p-md-4">
                  {showField(idFieldProductionTime) && (
                    <FormInputNumber
                      name="productionTime"
                      label="Production Time"
                      thousandSeparator="."
                      decimalSeparator=","
                    />
                  )}
                </div>
                {showField(idFieldIdSupplier) && (
                  <FormAsyncSelect
                    name="cqSuppliers"
                    className="p-md-4"
                    label="SUN Number"
                    isMulti
                    loadOptions={asyncSelectLoadDomains}
                    getOptionLabel={(option: any) =>
                      `SUN${option.idSupplier.toString().padStart(5, '0')}`
                    }
                    getOptionValue={(option: any) => option.idSupplier}
                    initialValue={clientQuotation.cqSuppliers}
                    readOnly
                  />
                )}
              </div>
            </div>

            <div className="p-d-flex p-flex-wrap">
              <div className="p-col-12">
                <h1>Logistics</h1>
              </div>
              <div className="p-d-flex p-flex-wrap w-full">
                {showField(idFieldIdPortOfLoading) && (
                  <FormAsyncSelect
                    className="p-md-4"
                    name="idPortOfLoading"
                    label="Port/Airport Of Loading"
                    loadOptions={loadPortsOptions}
                    debounceTimeout={1000}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.idPort}
                    additional={{
                      page: 1,
                      idCountry: clientQuotation.idCountryOrigin,
                    }}
                    noOptionsMessage={() => 'No ports found'}
                    initialValue={clientQuotation.idPortOfLoading2}
                    onValueChange={e => onPortOfLoadingChange(e)}
                  />
                )}
                {showField(idFieldIdPortOfDischarge) && (
                  <FormAsyncSelect
                    className="p-md-4"
                    name="idPortOfDischarge"
                    label="Port/Airport of Discharge"
                    loadOptions={loadPortsOptions}
                    debounceTimeout={1000}
                    additional={{
                      page: 1,
                    }}
                    initialValue={clientQuotation.idPortOfDischarge2}
                    noOptionsMessage={() => 'No ports found'}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.idPort}
                    required
                  />
                )}
              </div>
              <div className="p-d-flex p-flex-wrap w-full">
                {showField(idFieldIdCountryOrigin) && (
                  <FormInput
                    className="p-md-4"
                    name="idCountryOrigin"
                    label="Country of Origin"
                    defaultValue={clientQuotation.idCountryOrigin2?.name}
                    readOnly
                  />
                )}
                {showField(idFieldIdCountryAcquisition) && (
                  <FormInput
                    className="p-md-4"
                    name="idCountryAcquisition"
                    label="Country Acquisition"
                    defaultValue={clientQuotation.idCountryAcquisition2?.name}
                    readOnly
                  />
                )}
                {showField(idFieldIdCountryProcedence) && (
                  <FormInput
                    className="p-md-4"
                    name="idCountryProcedence"
                    label="Country Procedence"
                    defaultValue={clientQuotation.idCountryProcedence2?.name}
                    readOnly
                  />
                )}
              </div>
            </div>
            <div className="p-d-flex p-flex-wrap">
              <div className="p-col-12">
                <h1>Schedule</h1>
              </div>
              <div className="p-d-flex p-flex-wrap w-full">
                {showField(idFieldEstimatedInspection) && (
                  <FormInput
                    className="p-md-4"
                    name="estimatedInspection"
                    label="Estimated Inspection Date"
                    type="date"
                  />
                )}
                {showField(idFieldEstimatedShipment) && (
                  <FormInput
                    className="p-md-4"
                    name="estimatedShipment"
                    label="Estimated Shipment Date"
                    type="date"
                  />
                )}
                {showField(idFieldEstimatedArrival) && (
                  <FormInput
                    className="p-md-4"
                    name="estimatedArrival"
                    label="Estimated Arrival Date"
                    type="date"
                  />
                )}
                {showField(idFieldEtimatedArrivalAtClient) && (
                  <FormInput
                    className="p-md-4"
                    name="estimatedArrivalAtClient"
                    label="Estimated Arrival At Client Date"
                    type="date"
                  />
                )}
              </div>
            </div>
            <div className="p-d-flex p-flex-wrap">
              <div className="p-col-12">
                <h1>User</h1>
              </div>
              <div className="p-d-flex p-flex-wrap w-full">
                {showField(idFieldPurchaseUser) && (
                  <FormInput
                    className="p-md-4"
                    name="idPurchaseUser"
                    label="Purchase User"
                    value={
                      clientQuotation.purchaseUser
                        ? `${clientQuotation.purchaseUser2?.firstName} ${clientQuotation.purchaseUser2?.lastName}`
                        : undefined
                    }
                    readOnly
                  />
                )}
                {showField(idFieldSalesUser) && (
                  <FormInput
                    className="p-md-4"
                    name="idSalesUser"
                    label="Sales User"
                    value={
                      clientQuotation.salesUser
                        ? `${clientQuotation.salesUser2?.firstName} ${clientQuotation.salesUser2?.lastName}`
                        : undefined
                    }
                    readOnly
                  />
                )}
                {showField(idFieldApprovedOn) && (
                  <FormInput
                    className="p-md-4"
                    name="approvedAt"
                    label="Approved At"
                    value={
                      clientQuotation.approvedAt
                        ? new Date(clientQuotation.approvedAt).toLocaleString()
                        : undefined
                    }
                    readOnly
                  />
                )}
              </div>
            </div>
          </Form>
        </Container>
      </PageTabContainer>
    );
  },
);

export default GeneralInformation;
