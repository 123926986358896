import styled from 'styled-components';

export const Container = styled.div`
  .statuses-div {
    display: flex;
    flex-direction: column;
    .p-tag + .p-tag {
      margin-top: 4px;
    }
  }

  .p-row-expanded:hover {
    background-color: unset !important;
    color: unset !important;
  }
`;
