import { Card, CardProps } from 'primereact/card';
import React from 'react';

import { Container } from './styles';

interface IWidgetCardProps extends CardProps {
  name: string;
}

const WidgetCard: React.FC<IWidgetCardProps> = ({
  name,
  children,
  className,
  ...rest
}) => {
  return (
    <Container className={className}>
      <h2>{name}</h2>
      <Card {...rest}>{children}</Card>
    </Container>
  );
};

export default WidgetCard;
