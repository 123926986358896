/* eslint-disable jsx-a11y/label-has-associated-control */
import { Checkbox } from 'primereact/checkbox';
import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FaTimes } from 'react-icons/fa';
import { IUser } from '../../interfaces/IUser';
import { FileType } from '../../shared/enums/fileType';
import { IAttachment } from '../../shared/interfaces/attachment';
import { IComment } from '../../shared/interfaces/comment';
import { ICommentEditorOption } from '../CommentEditor/interfaces';
import MainButton from '../MainButton';
import Mentions from '../Mentions';
import RoundUserAvatar from '../RoundUserAvatar';

import { CommentContent, Container } from './styles';
import FileUpload from '../FileUpload';
import { FileUploadResponse } from '../FileUpload/interfaces';
import { useAuth } from '../../hooks/useAuth';

interface IReplyProps {
  userToReply: IUser;
  isRestrictComment: boolean;
  message?: string;
}

interface IReplyFollowUpProps {
  userToReply: IUser;
  isStatusCfr: boolean;
  message?: string;
}

export interface ICommentAddingRef {
  editComment(oldComment: IComment): void;
  editFollowUp(oldComment: IComment, wasStatusCfr: boolean): void;
  replyComment(data: IReplyProps): void;
  replyFollowUp(data: IReplyFollowUpProps): void;
  addCommentFromAttachment(attachment: IAttachment): void;
  replyAll(data: IReplyProps): void;
  replyAllFollowUp(data: IReplyFollowUpProps): void;
}
interface ICommentAddingProps {
  options?: ICommentEditorOption[];
  selectedComment?: IComment;
  showRestrictInfo?: boolean;
  showStatusCfr?: boolean;
  showStatusRnc?: boolean;
  onSaveComment(e: IComment): void;
  onUploadAttachment(e: FileUploadResponse[]): Promise<IAttachment | undefined>;
  cleanSelected(): void;
  ref?: Ref<ICommentAddingRef>;
  additionalOptions?: React.ReactNode;
}

const CommentAdding: React.FC<ICommentAddingProps> = forwardRef(
  (
    {
      showRestrictInfo,
      showStatusCfr,
      onSaveComment,
      onUploadAttachment,
      cleanSelected,
      additionalOptions,
    },
    ref,
  ) => {
    const { user } = useAuth();

    const userFullName = `${user.firstName} ${user.lastName}`;

    const commentInput = useRef<HTMLTextAreaElement>(null);

    const [hideCommentOptions, setHideCommentOptions] = useState(true);

    const [commentToEdit, setCommentToEdit] = useState<IComment>(
      {} as IComment,
    );

    const [comment, setComment] = useState<string>('');

    const [idAttachment, setIdAttachment] = useState<number>();

    const [isRestrictInfo, setRestrictInfo] = useState<boolean>();

    const [isStatusCfr, setStatusCfr] = useState<boolean>();

    const cleanFields = useCallback(() => {
      cleanSelected();
      setCommentToEdit({} as IComment);
      setRestrictInfo(false);
      setStatusCfr(false);
      setIdAttachment(undefined);
      setComment('');
    }, [cleanSelected]);

    const handleSaveComment = useCallback(async () => {
      onSaveComment({
        ...commentToEdit,
        message: comment,
        isRestrictInfo,
        isStatusCfr,
        createdBy: user.idUser,
        idAttachment,
      });

      setHideCommentOptions(true);
    }, [
      comment,
      commentToEdit,
      idAttachment,
      isRestrictInfo,
      isStatusCfr,
      onSaveComment,
      user.idUser,
    ]);

    useEffect(() => {
      if (hideCommentOptions) {
        cleanFields();
      }
    }, [cleanFields, hideCommentOptions]);

    const setCommentInfoToEdit = useCallback(async (oldComment: IComment) => {
      setRestrictInfo(oldComment.isRestrictInfo);
      setCommentToEdit(oldComment);
      setHideCommentOptions(false);
      setIdAttachment(oldComment.idAttachment);
      setComment(oldComment.message || '');
      commentInput.current?.focus();
    }, []);

    const setCommentInfoToReply = useCallback(async (userToReply: IUser) => {
      setHideCommentOptions(false);
      setComment(`@[${userToReply.username}]@(${userToReply.idUser}) `);
      commentInput.current?.focus();
    }, []);

    const setCommentInfoToReplyAll = async (
      userToReply: IUser,
      message?: string,
    ) => {
      setHideCommentOptions(false);
      let mentionsString = '';
      const userMention = `@[${userToReply.username}]@(${userToReply.idUser})`;
      const idCurrentUserString = `@(${user.idUser})`;
      const commentMentions = message?.match(/@[^)\s]+[)]/g);

      // Remove duplicados (caso o proprio usuario tenha se marcado) e a mencao do usuario que esta respondendo
      if (commentMentions) {
        const uniqueMentions = commentMentions.filter(
          item => item !== userMention && !item.includes(idCurrentUserString),
        );

        mentionsString = uniqueMentions.join(' ');
      }

      setComment(`${userMention} ${mentionsString} `);
      commentInput.current?.focus();
    };

    useImperativeHandle(ref, () => ({
      editComment: (oldComment: IComment) => {
        setCommentInfoToEdit(oldComment);
      },
      editFollowUp: (oldComment: IComment, wasStatusCfr) => {
        setStatusCfr(wasStatusCfr);
        setCommentInfoToEdit(oldComment);
      },
      replyComment: (data: IReplyProps) => {
        setRestrictInfo(data.isRestrictComment);
        setCommentInfoToReply(data.userToReply);
      },
      replyFollowUp: (data: IReplyFollowUpProps) => {
        setStatusCfr(data.isStatusCfr);
        setCommentInfoToReply(data.userToReply);
      },
      addCommentFromAttachment: (attachment: IAttachment) => {
        setIdAttachment(attachment?.idAttachment);
        setHideCommentOptions(false);
        setComment(`<${attachment?.nameGivenByUser}> `);
      },
      replyAll: (data: IReplyProps) => {
        setRestrictInfo(data.isRestrictComment);
        setCommentInfoToReplyAll(data.userToReply, data.message);
      },
      replyAllFollowUp: (data: IReplyFollowUpProps) => {
        setStatusCfr(data.isStatusCfr);
        setCommentInfoToReplyAll(data.userToReply, data.message);
      },
    }));

    async function uploadAttachment(data: FileUploadResponse[]) {
      const attachment = await onUploadAttachment(data);
      setIdAttachment(attachment?.idAttachment);
      setComment(`${comment} <${attachment?.nameGivenByUser}> `);
    }

    const chooseOptions = {
      icon: 'pi pi-paperclip',
      iconOnly: true,
      className: 'paperclip-icon',
    };

    return (
      <Container>
        <span className="user-avatar-wrapper">
          <RoundUserAvatar
            image={user.avatarUrl ?? ''}
            userFullName={userFullName}
            imageAlt={userFullName}
          />
        </span>
        <CommentContent>
          <Mentions
            inputRef={commentInput}
            value={comment}
            onClick={() => setHideCommentOptions(false)}
            onChange={e => setComment(e)}
          />
          <div hidden={hideCommentOptions}>
            <div className="p-col-12 flex justify-content-between">
              <div className="flex align-items-center">
                <MainButton
                  className="mainButton"
                  label="Save"
                  onClick={() => handleSaveComment()}
                  disabled={!comment}
                />
                <div className="button-div">
                  <button
                    type="button"
                    onClick={() => setHideCommentOptions(true)}
                  >
                    <FaTimes size={18} />
                  </button>
                </div>
              </div>
              <div className="flex align-items-center">
                <FileUpload
                  mode="basic"
                  chooseOptions={chooseOptions}
                  accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX}`}
                  auto
                  onConfirm={e => uploadAttachment(e)}
                  disabled={!!idAttachment}
                />
                {showRestrictInfo && (
                  <label
                    className="p-checkbox-label p-ml-auto p-mr-3"
                    htmlFor="restrictInfo"
                  >
                    <Checkbox
                      className="p-mr-2"
                      inputId="restrictInfo"
                      onChange={e => setRestrictInfo(e.checked)}
                      checked={isRestrictInfo}
                    />
                    Restrict Info
                  </label>
                )}
                {showStatusCfr && (
                  <label
                    className="p-checkbox-label p-ml-auto p-mr-3"
                    htmlFor="cfr"
                  >
                    <Checkbox
                      className="p-mr-2"
                      inputId="cfr"
                      onChange={e => setStatusCfr(e.checked)}
                      checked={isStatusCfr}
                    />
                    CFR
                  </label>
                )}
                {additionalOptions}
              </div>
            </div>
          </div>
        </CommentContent>
      </Container>
    );
  },
);

export default CommentAdding;
