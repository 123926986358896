import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  height: 32px;
  width: 100%;
  border: 1px solid #d7d6d6;
  background-color: #fff;
  padding: 8px;
  z-index: 1;
`;

export const Times = styled.div`
  display: flex;
  text-align: center;
  font-size: 11px;
  align-items: center;
  place-content: center;

  p {
    color: #7f7f80;
  }
  span {
    display: flex;
    align-items: center;
    place-content: center;
    margin-right: 8px;

    .label {
      margin-right: 4px;
    }

    .date {
      font-weight: 700;
    }
  }

  p + span {
    margin-left: 8px;
  }
`;

export const Currency = styled.div`
  font-size: 11px;
  display: flex;
  text-align: center;
  align-items: center;
  place-content: center;

  p {
    color: #7f7f80;
  }

  span {
    display: flex;

    img {
      margin-right: 6px;
    }
  }

  p + span,
  span + p,
  span + span {
    margin-left: 8px;
  }
`;
