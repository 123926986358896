/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
import { gql, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTable,
  DataTableMultiSortMetaType,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortParams,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import imagePlaceholder from '../../../../assets/imagePlaceholder.svg';
import Grid from '../../../../components/Grid';
import Loading from '../../../../components/Loading';
import Tag from '../../../../components/Tag';
import ILazyParams from '../../../../services/lazyParams';
import { Container, GridHeader } from './styles';

import AdvancedFiltersPanel from '../../../../components/AdvancedFiltersPanel';
import Button from '../../../../components/Button';
import { gridConstants } from '../../../../components/Grid/constants';
import { ColumnData } from '../../../../components/Grid/interfaces';
import MultiSelect from '../../../../components/Grid/MultiSelect';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import pagination, {
  searchDelayMiliseconds,
} from '../../../../config/pagination';
import { useRefHook } from '../../../../hooks/useRefHook';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { IRoleEntityPermission } from '../../../../interfaces/IRoleEntityPermission';
import ToastLife from '../../../../shared/enums/toastLife';
import {
  createClientQuotation,
  createClientQuotationQuery,
} from '../../../../shared/mutations/clientQuotation';
import { clientQuotationRoles } from '../../../../shared/roles/clientQuotation';
import { productRoles } from '../../../../shared/roles/product';
import getFieldPermission from '../../../../utils/getFieldPermission';
import updateLocalStorageInDb from '../../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../../utils/userHasPermission';
import { ProductStatus } from '../../../Products/Product/enums';
import { ISupplier } from '../interfaces';
import { gridColumnsData } from './constants';
import { filterOnlyAllowedColumnsFromLocalStorage } from '../../../../components/Grid/utils';

interface IGeneralInformationProps extends PageTabContainerProps {
  avatar?: File;
  supplier: ISupplier;
  setSupplier: Dispatch<SetStateAction<ISupplier>>;
  setPageLoading: Dispatch<SetStateAction<boolean>>;
  roleEntityFields: IRoleEntityField[];
  userPermissions: IRoleEntityPermission[];
  userPermissionsClientQuotation: IRoleEntityPermission[];
}

const Products: React.FC<IGeneralInformationProps> = ({
  selected,
  supplier,
  setPageLoading,
  roleEntityFields,
  userPermissions,
  userPermissionsClientQuotation,
}) => {
  // Nome da key de grid columns
  const gridColumnsName = '@SAT:supplierProductsGridColumns';

  const lazyParamsName = '@SAT:supplierProductsLazyParams';

  const advancedFiltersName = '@SAT:supplierProductsAdvancedFilters';

  // Redirect
  const history = useHistory();

  // URL params
  const location = useLocation();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Permissions de Product
  const { idPermissionCreateSat } = productRoles.permissions;

  // Permissions de Client Quotation
  const { idPermissionCreateClientQuotation } =
    clientQuotationRoles.permissions;

  // Referencia a grid
  const gridRef = useRef<DataTable>(null);

  // Estado de loading
  const [pageLoading] = useState(false);

  // Produtos selecionados
  const [selectedProducts, setSelectedProducts] = useState([]);

  // Referencia ao painel de advanced filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  /**
   * Interface dos parametros do backend
   */
  interface IProductsLazyParams extends ILazyParams {
    [key: string]:
      | number
      | string
      | boolean
      | DataTableMultiSortMetaType
      | undefined;
    idStatus?: number;
    stCode?: string;
    outdated?: boolean;
    taxmissing?: boolean;
    verified?: boolean;
    idSupplier?: number;
    category?: string;
    model?: string;
    nameEn?: string;
    namePt?: string;
    colourEn?: string;
    colourPt?: string;
    licensedProduct?: number;
    stock?: boolean;
    exclusivity?: boolean;
    unit?: string;
    sku?: string;
    detailedDescriptionPt?: string;
    materialEn?: string;
    materialPt?: string;
    descriptionEn?: string;
    descriptionPt?: string;
    finishing?: string;
    extraInfoPt?: string;
    checklist?: string;
    foreignTradeReview?: boolean;
    ncm?: number;
    hsCode?: string;
    importLicense?: boolean;
    dumping?: boolean;
    enhance?: string;
    idNcmEnhance?: number;
    purchaseCurrency?: string;
    cotPrice?: string;
    markup?: string;
    aging?: string;
    currency?: string;
    incoterm?: string;
    profit?: string;
    client?: string;
    brand?: string;
  }

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IProductsLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: IProductsLazyParams) => {
    const lazyParamsToSave = {
      ...newLazyParams,
      ...pagination.initialLazyParams,
    };
    localStorage.setItem(lazyParamsName, JSON.stringify(lazyParamsToSave));
    updateLocalStorageInDb(lazyParamsName, lazyParamsToSave);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: IProductsLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortParams) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  const {
    idFieldAging,
    idFieldColourPt,
    idFieldCotPrice,
    idFieldSalesCurrency,
    idFieldDescriptionPt,
    idFieldDumping,
    idFieldExclusivity,
    idFieldExtraInfoPt,
    idFieldTradeReview,
    idFieldHsCode,
    idFieldIdCategory,
    idFieldIdFinishing,
    idFieldIdSupplier,
    idFieldIdStatus,
    idFieldLicensedProduct,
    idFieldMarkup,
    idFieldMaterialPt,
    idFieldModel,
    idFieldNamePt,
    idFieldDetailedDescriptionPt,
    idFieldNcm,
    idFieldProfit,
    idFieldPurchaseCurrency,
    idFieldSku,
    idFieldStCode,
    idFieldStock,
    idFieldVerified,
  } = productRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList: ColumnData[] = [];

    columnList.push(gridColumnsData.imageUrl);

    if (showField(idFieldStCode)) {
      columnList.push(gridColumnsData.stCode);
    }

    if (showField(idFieldIdSupplier)) {
      columnList.push(gridColumnsData.sunNumber);
    }

    if (showField(idFieldIdCategory)) {
      columnList.push(gridColumnsData.category);
    }

    if (showField(idFieldModel)) {
      columnList.push(gridColumnsData.model);
    }

    columnList.push(gridColumnsData.nameEn);

    if (showField(idFieldNamePt)) {
      columnList.push(gridColumnsData.namePt);
    }

    columnList.push(gridColumnsData.colourEn);

    if (showField(idFieldColourPt)) {
      columnList.push(gridColumnsData.colourPt);
    }

    if (showField(idFieldLicensedProduct)) {
      columnList.push(gridColumnsData.licensedProduct);
    }

    if (showField(idFieldStock)) {
      columnList.push(gridColumnsData.stock);
    }

    if (showField(idFieldExclusivity)) {
      columnList.push(gridColumnsData.exclusivity);
    }

    columnList.push(gridColumnsData.unit);

    if (showField(idFieldSku)) {
      columnList.push(gridColumnsData.sku);
    }

    if (showField(idFieldDetailedDescriptionPt)) {
      columnList.push(gridColumnsData.detailedDescriptionPt);
    }

    columnList.push(gridColumnsData.materialEn);

    if (showField(idFieldMaterialPt)) {
      columnList.push(gridColumnsData.materialPt);
    }

    columnList.push(gridColumnsData.descriptionEn);

    if (showField(idFieldDescriptionPt)) {
      columnList.push(gridColumnsData.descriptionPt);
    }

    if (showField(idFieldIdFinishing)) {
      columnList.push(gridColumnsData.finishing);
    }

    if (showField(idFieldExtraInfoPt)) {
      columnList.push(gridColumnsData.extraInfoPt);
    }

    columnList.push(gridColumnsData.checklist);

    if (showField(idFieldTradeReview)) {
      columnList.push(gridColumnsData.foreignTradeReview);
    }

    if (showField(idFieldNcm)) {
      columnList.push(gridColumnsData.ncm);
    }

    if (showField(idFieldHsCode)) {
      columnList.push(gridColumnsData.hsCode);
    }

    columnList.push(gridColumnsData.importLicense);

    columnList.push(gridColumnsData.enhance);

    columnList.push(gridColumnsData.idNcmEnhance);

    if (showField(idFieldDumping)) {
      columnList.push(gridColumnsData.dumping);
    }

    if (showField(idFieldPurchaseCurrency)) {
      columnList.push(gridColumnsData.purchaseCurrency);
    }

    if (showField(idFieldCotPrice)) {
      columnList.push(gridColumnsData.cotPrice);
    }

    if (showField(idFieldMarkup)) {
      columnList.push(gridColumnsData.markup);
    }

    if (showField(idFieldAging)) {
      columnList.push(gridColumnsData.aging);
    }

    if (showField(idFieldSalesCurrency)) {
      columnList.push(gridColumnsData.currency);
    }

    columnList.push(gridColumnsData.incoterm);

    if (showField(idFieldProfit)) {
      columnList.push(gridColumnsData.profit);
    }

    columnList.push(gridColumnsData.client);
    columnList.push(gridColumnsData.brand);

    return columnList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filters = useMemo(() => {
    return [gridColumnsData.idStatus, ...columns];
  }, [columns]);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  // Colunas da grid que possuem filtro aplicado
  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeParams) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );
    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  // Query para listar products
  const listProductsQuery = gql`
    query listAllProductsBySupplierQuery(
      $listAllProductsInput: ListAllProductsInput!
    ) {
      listAllProductsBySupplier(listAllProductsInput: $listAllProductsInput) {
        data {
          ${showField(idFieldIdSupplier) ? 'idSupplier' : ''}
          ${showField(idFieldIdStatus) ? 'idStatus' : ''}
          ${showField(idFieldStCode) ? 'stCode' : ''}
          ${
            showField(idFieldIdCategory)
              ? `idCategory2 {
                  idCategory
                  name
                }`
              : ''
          }
          ${showField(idFieldIdCategory) ? 'idCategory' : ''}
          ${showField(idFieldModel) ? 'model' : ''}
          nameEn
          ${showField(idFieldNamePt) ? 'namePt' : ''}
          colourEn
          ${showField(idFieldColourPt) ? 'colourPt' : ''}
          ${showField(idFieldLicensedProduct) ? 'licensedProduct' : ''}
          ${showField(idFieldStock) ? 'stock' : ''}
          ${showField(idFieldExclusivity) ? 'exclusivity' : ''}
          unit2 {
            description
          }
          ${showField(idFieldSku) ? 'sku' : ''}
          ${
            showField(idFieldDetailedDescriptionPt)
              ? 'detailedDescriptionPt'
              : ''
          }
          materialEn
          ${showField(idFieldMaterialPt) ? 'materialPt' : ''}
          descriptionEn
          ${showField(idFieldDescriptionPt) ? 'descriptionPt' : ''}
          ${
            showField(idFieldIdFinishing)
              ? `idFinishing2 {
                  name
                }`
              : ''
          }
          ${showField(idFieldExtraInfoPt) ? 'extraInfoPt' : ''}
          idCheckList2 {
            name
          }
          ${showField(idFieldTradeReview) ? 'foreignTradeReview' : ''}
          ${showField(idFieldNcm) ? 'ncm' : ''}
          ${showField(idFieldHsCode) ? 'hsCode' : ''}
          importLicense
          enhance
          idNcmEnhance
          ${showField(idFieldDumping) ? 'dumping' : ''}
          ${showField(idFieldPurchaseCurrency) ? 'purchaseCurrency' : ''}
          ${showField(idFieldCotPrice) ? 'cotPrice' : ''}
          ${showField(idFieldMarkup) ? 'markup' : ''}
          indIncoterm2 {
            idDomain
            description
          }
          ${showField(idFieldProfit) ? 'profit' : ''}
          productBrands {
            idProductBrand
            idClientBrand
            idClient
            idClientBrandProductLine
            code
            idAttachment
            idClient2 {
              idClient
              name
              companyName
            }
          }
          imageUrl
          ${showField(idFieldAging) ? 'aging' : ''}
          ${showField(idFieldSalesCurrency) ? 'currency' : ''}
          clientsString
          idProduct
          brandsString
          ${showField(idFieldVerified) ? 'verified' : ''}
          taxmissing
          outdated
        }
        items
      }
    }
  `;

  /**
   * Busca Products
   */
  const {
    loading: productsLoading,
    data: productsData,
    error: productsError,
    refetch: productsRefetch,
  } = useQuery(listProductsQuery, {
    variables: {
      listAllProductsInput: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        idSupplier: supplier.idSupplier,
        globalSearch: lazyParams.globalFilter,
        idStatus: lazyParams.idStatus,
        outdated: lazyParams.outdated,
        taxmissing: lazyParams.taxmissing,
        verified: lazyParams.verified,
        category: lazyParams.category,
        model: lazyParams.model,
        nameEn: lazyParams.nameEn,
        namePt: lazyParams.namePt,
        colourEn: lazyParams.colourEn,
        colourPt: lazyParams.colourPt,
        licensedProduct: lazyParams.licensedProduct,
        stock: lazyParams.stock,
        exclusivity: lazyParams.exclusivity,
        unit: lazyParams.unit,
        sku: lazyParams.sku,
        detailedDescriptionPt: lazyParams.detailedDescriptionPt,
        materialEn: lazyParams.materialEn,
        materialPt: lazyParams.materialPt,
        descriptionEn: lazyParams.descriptionEn,
        descriptionPt: lazyParams.descriptionPt,
        finishing: lazyParams.finishing,
        extraInfoPt: lazyParams.extraInfoPt,
        checklist: lazyParams.checklist,
        foreignTradeReview: lazyParams.foreignTradeReview,
        ncm: lazyParams.ncm,
        hsCode: lazyParams.hsCode,
        importLicense: lazyParams.importLicense,
        enhance: lazyParams.enhance,
        idNcmEnhance: lazyParams.idNcmEnhance,
        dumping: lazyParams.dumping,
        purchaseCurrency: lazyParams.purchaseCurrency,
        cotPrice: lazyParams.cotPrice,
        markup: lazyParams.markup,
        aging: lazyParams.aging,
        currency: lazyParams.currency,
        incoterm: lazyParams.incoterm,
        profit: lazyParams.profit,
        client: lazyParams.client,
        brand: lazyParams.brand,
        stCode: lazyParams.stCode,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting products',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Mutation de criacao de SAT
  const createSatQuery = gql`
    mutation CreateSat($idsProducts: [Int]!) {
      createSat(idsProducts: $idsProducts) {
        idSat
        satNumber
      }
    }
  `;

  // cria método para chamar a mutation
  const [createSatMutation] = useMutation(createSatQuery);
  const [createClientQuotationMutation] = useMutation(
    createClientQuotationQuery,
  );

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Valida se coluna active é true ou false
   * @param rowData Dados da linha
   * @returns Campo com true ou false
   */
  const parseBooleanColumn = (rowData: any, field: any) => {
    if (field.columnKey === 'indVerified') {
      return <p>{rowData.indVerified === 's' ? 'Yes' : 'No'}</p>;
    }
    if (field.columnKey === 'idStatus') {
      return <p>{rowData.idStatus === ProductStatus.ACTIVED ? 'Yes' : 'No'}</p>;
    }
    if (field.columnKey === 'foreignTradeReview') {
      return <p>{rowData.foreignTradeReview ? 'Yes' : 'No'}</p>;
    }
    if (field.columnKey === 'importLicense') {
      return <p>{rowData.importLicense ? 'Yes' : 'No'}</p>;
    }
    if (field.columnKey === 'licensedProduct') {
      return <p>{rowData.licencesProduct ? 'Yes' : 'No'}</p>;
    }
    if (field.columnKey === 'stock') {
      return <p>{rowData.stock ? 'Yes' : 'No'}</p>;
    }
    if (field.columnKey === 'exclusivity') {
      return <p>{rowData.exclusivity ? 'Yes' : 'No'}</p>;
    }
    if (field.columnKey === 'dumping') {
      return <p>{rowData.dumping ? 'Yes' : 'No'}</p>;
    }
    return undefined;
  };

  const parseImageColumn = (rowData: any) => {
    return (
      <img
        src={`${rowData.imageUrl}`}
        onError={e => (e.currentTarget.src = imagePlaceholder)}
        alt={rowData.image}
        className="product-image"
        style={{ width: '90px' }}
      />
    );
  };

  const parseSunColumn = (rowData: any) => {
    return rowData.idSupplier ? (
      <p>{`SUN${rowData.idSupplier.toString().padStart(5, '0')}`}</p>
    ) : undefined;
  };

  const parseStatusColumn = (rowData: any) => {
    return (
      <div className="statuses-div">
        {rowData.idStatus === ProductStatus.ACTIVED ? (
          <Tag value="Active" severity="success" />
        ) : (
          <Tag value="Inactive" severity="danger" />
        )}
        {rowData.taxmissing && <Tag value="Tax Missing" />}
        {rowData.outdated && <Tag value="Outdated" severity="info" />}
        {rowData.verified && <Tag value="Verified" severity="success" />}
      </div>
    );
  };

  /**
   * Retorna coluna STCode com Link para pagina de produtos
   * @param rowData Dados da linha
   * @returns Coluna ST Code com Link para pagina de produtos
   */
  const parseProductColumn = (rowData: any) => {
    return (
      rowData.stCode && (
        <Link
          to={`/products/list/${rowData.stCode.replace('ST', '')}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.stCode}
          <FiExternalLink size={15} />
        </Link>
      )
    );
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'idStatus':
      case 'indVerified':
      case 'foreignTradeReview':
      case 'importLicense':
      case 'licensedProduct':
      case 'stock':
      case 'exclusivity':
      case 'dumping':
        return parseBooleanColumn;
      case 'imageUrl':
        return parseImageColumn;
      case 'idSupplier':
        return parseSunColumn;
      case 'stCode':
        return parseProductColumn;
      default:
        return undefined;
    }
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'active':
        return { width: '100px', overflow: 'hidden' };
      case 'indVerified':
        return { width: '110px', overflow: 'hidden' };
      case 'licensedProduct':
        return { width: '190px', overflow: 'hidden' };
      case 'unit2.description':
        return { width: '110px', overflow: 'hidden' };
      case 'detailedDescriptionPt':
      case 'materialEn':
      case 'materialPt':
      case 'clientsString':
      case 'nameEn':
      case 'namePt':
        return { width: '200px', overflow: 'hidden' };
      case 'descriptionEn':
      case 'descriptionPt':
      case 'brandsString':
        return { width: '300px', overflow: 'hidden' };

      case 'model':
        return { width: '150px', overflow: 'hidden' };

      case 'foreignTradeReview':
        return { width: '160px', overflow: 'hidden' };
      case 'idNcmEnhance':
        return { width: '170px', overflow: 'hidden' };
      default:
        return { width: '140px', overflow: 'hidden' };
    }
  }

  /**
   * Direciona usuario para pagina do porto clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(
      `/products/list/${e.data.idProduct}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Cria SAT com os produtos selecionados
   */
  function handleCreateSatWithSelectedProducts() {
    confirmDialog({
      message: `You have selected (${selectedProducts.length}) records. Confirm create SAT?`,
      header: 'Create SAT confirmation',
      icon: 'pi pi-info-circle',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada produto
        const idsToCreate = selectedProducts.map((a: any) => a.idProduct);
        try {
          // Deleta portos
          const response = await createSatMutation({
            variables: {
              idsProducts: idsToCreate,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'SAT Created',
            detail: (
              <>
                <p>Products added in the new SAT: </p>
                <Link
                  to={`/commercial/sats/${response.data.createSat.idSat}`}
                  style={{ textDecoration: 'none', color: 'unset' }}
                >
                  {response.data.createSat.satNumber}
                </Link>
              </>
            ),
            life: ToastLife.ERROR,
          });

          // Zera estado de portos selecionadas
          setSelectedProducts([]);

          // Atualiza grid de portos
          await productsRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating SAT',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Cria Client Quotation
   */
  function handleCreateClientQuotation() {
    confirmDialog({
      message: `You have selected (${selectedProducts.length}) records. Confirm create CQ?`,
      header: 'Create CQ confirmation',
      icon: 'pi pi-info-circle',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada Produto
        const idsToCreate = selectedProducts.map((a: any) => a.idProduct);
        try {
          // Cria Client Quotation
          const response = await createClientQuotation(
            createClientQuotationMutation,
            undefined,
            undefined,
            idsToCreate,
          );

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'CQ Created',
            detail: (
              <>
                <p>Products added in the new CQ: </p>
                <Link
                  to={`/client-quotations/list/${response.data.createClientQuotation.idCq}`}
                  className="transparent-link"
                >
                  {response.data.createClientQuotation.cqNumber}
                </Link>
              </>
            ),
            life: ToastLife.SUCCESS,
          });

          // Zera estado de products selecionados
          setSelectedProducts([]);
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating CQ',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable={col.header !== 'Image'}
        />
      )
    );
  });

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(gridColumnsData).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  const gridHeader = (
    <GridHeader>
      <div className="buttons-header">
        <Button
          label="Create SAT"
          onClick={() => handleCreateSatWithSelectedProducts()}
          disabled={!selectedProducts.length}
        />
        {userHasPermission(
          idPermissionCreateClientQuotation,
          userPermissionsClientQuotation,
        ) && (
          <Button
            label="Create CQ"
            onClick={() => handleCreateClientQuotation()}
            disabled={!selectedProducts.length}
          />
        )}
      </div>
      <div className="grid-filters">
        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a product"
        />

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={filters}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />
      </div>
    </GridHeader>
  );

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      const allowedColumns = filterOnlyAllowedColumnsFromLocalStorage(
        JSON.parse(localStorageSelectedColumns),
        columns,
      );
      setSelectedColumns(allowedColumns);
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <Grid
          ref={gridRef}
          className="grid"
          name="supplierProducts"
          lazy
          totalRecords={
            !productsData || !productsData.listAllProductsBySupplier
              ? 0
              : productsData.listAllProductsBySupplier.items
          }
          value={
            !productsData || !productsData.listAllProductsBySupplier
              ? undefined
              : productsData.listAllProductsBySupplier.data
          }
          globalFilter={globalFilter}
          emptyMessage="No products found."
          onRowClick={onRowClick}
          reorderableColumns
          removableSort
          scrollable
          rows={lazyParams.rows}
          first={!productsData || productsError ? undefined : lazyParams.first}
          onPage={onPage}
          onSort={onSort}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          onSelectionChange={e => setSelectedProducts(e.value)}
          selection={selectedProducts}
          style={{ width: 'calc(100vw - 315px)' }}
          scrollHeight={gridConstants.internalGridScrollHeight}
          frozenWidth="1px"
          header={
            userHasPermission(idPermissionCreateSat, userPermissions)
              ? gridHeader
              : undefined
          }
          dataKey="idProduct"
        >
          {userHasPermission(idPermissionCreateSat, userPermissions) && (
            <Column
              columnKey="multiple"
              selectionMode="multiple"
              style={{ width: '3em' }}
              reorderable={false}
            />
          )}

          <Column
            columnKey="status"
            header="Status"
            body={parseStatusColumn}
            style={{ width: '8em' }}
          />
          {dynamicColumns}
        </Grid>
        {(pageLoading || productsLoading) && <Loading />}
      </Container>
    </PageTabContainer>
  );
};

export default Products;
