import React, {
  Dispatch,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { array, number, object, string, ValidationError } from 'yup';

import { useLazyQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { omit } from 'lodash';
import { confirmDialog } from 'primereact/confirmdialog';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import { Container, Group } from './styles';

import Card from '../../../../components/Card';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import FormBooleanRadio from '../../../../components/FormBooleanRadio';
import FormInput from '../../../../components/FormInput';
import FormInputMask, {
  maskFormats,
} from '../../../../components/FormInputMask';
import FormInputNumber from '../../../../components/FormInputNumber';
import FormTitle from '../../../../components/FormTitle';
import FormTreeSelectCategory from '../../../../components/FormTreeSelectCategory';
import MainButton from '../../../../components/MainButton';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import pagination from '../../../../config/pagination';
import { useRefHook } from '../../../../hooks/useRefHook';
import client from '../../../../services/apollo/client';
import { DomainGroup } from '../../../../shared/enums/domainGroup';
import { Role } from '../../../../shared/enums/role';
import ToastLife from '../../../../shared/enums/toastLife';
import { ISupplier } from '../../../../shared/interfaces/supplier';
import { asyncSelectLoadClients } from '../../../../shared/querys/client';
import {
  asyncSelectLoadClientContactsDepartmentOptions,
  asyncSelectLoadClientContactsOptions,
} from '../../../../shared/querys/clientContacts';
import { asyncSelectLoadDomains } from '../../../../shared/querys/domain';
import { asyncSelectLoadPortsOptions } from '../../../../shared/querys/port';
import { loadUsersByRolesOptions } from '../../../../shared/querys/user';
import { shouldDisableField } from '../../../../utils/disableField';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../utils/getValidationErrors';
import {
  GeneralInformationFieldsPermissions,
  IClient,
  ISat,
  ISatSupplier,
} from '../interfaces';
import { listAllSatsByClient, listAllSuppliersQuery } from '../queries';
import {
  GeneralInformationFormSubmitResponse,
  ISatScheduleRelatedItems,
  SatCtnrToCreate,
  SatCtnrToUpdate,
  SatGeneralInformationActionKind,
  UpdateSatGeneralInformationFormData,
} from './interfaces';
import {
  countForeignTradesNotCanceledQuery,
  listSatSuppliersBySatIdAndWithDifferentIncoterm,
} from './queries';
import {
  satGeneralInformationReducer,
  satGeneralInformationReducerInitialState,
} from './reducers';
import SatGroup from './SatGroup';
import { SatStatus } from '../../../../components/SatStatusTag';
import isNullOrUndefined from '../../../../utils/isNullOrUndefined';
import { addDaysToDate } from '../../../../utils/dateUtil';
import { objectsAreEqual } from '../../../../utils/objectsAreEqual';
import Loading from '../../../../components/Loading';
import ReadOnlyInput from '../../../../components/ReadOnlyInput';

interface GeneralInformationProps extends PageTabContainerProps {
  sat: ISat;
  setPageLoading: Dispatch<SetStateAction<boolean>>;
  fieldsPermissions: GeneralInformationFieldsPermissions;
  pageMenuItemKey: string;
  setSelectedMenuItem: Dispatch<React.SetStateAction<string>>;
  ref: Ref<ISatGeneralInformationRef>;
  setSat: React.Dispatch<React.SetStateAction<ISat>>;
  isSatCancelled: boolean;
  satRefetch(): Promise<void>;
}

export interface ISatGeneralInformationRef {
  handleValidateGeneralInformationForm(): Promise<boolean>;
  handleUpdateGeneralInformation(): GeneralInformationFormSubmitResponse;
  resetSatCtnrs(): void;
  checkIfDataChanged(): boolean;
  refetchShipmentCount(): void;
}

const GeneralInformation: React.FC<GeneralInformationProps> = React.forwardRef(
  (
    {
      selected,
      sat,
      fieldsPermissions,
      pageMenuItemKey,
      setSelectedMenuItem,
      setSat,
      isSatCancelled,
      setPageLoading,
      satRefetch,
    },
    ref,
  ) => {
    // Referencia ao toast
    const { toastRef } = useRefHook();

    // Referencia do form
    const formRef = useRef<FormHandles>(null);

    const [satGeneralInformationState, satGeneralInformationDispatch] =
      useReducer(satGeneralInformationReducer, {
        ...satGeneralInformationReducerInitialState,
        satCtnrs:
          sat.satCtnrs ?? satGeneralInformationReducerInitialState.satCtnrs,
      });

    const [selectedClient, setSelectedClient] = useState<IClient>({
      idClient: sat.idClient,
      name: sat.idClient2?.name,
      companyName: sat.companyName,
      cnpj: sat.clientCnpj,
      address: sat.clientAddress,
    } as IClient);

    const [selectedImporter, setSelectedImporter] = useState<IClient>({
      idClient: sat.idImporter,
      name: sat.idImporter2?.name,
      address: sat.importerAddress,
      cnpj: sat.importerCnpj,
      companyName: sat.importerName,
    } as IClient);

    const [
      satHasMoreThanOneActiveShipment,
      setSatHasMoreThanOneActiveShipment,
    ] = useState<boolean>();

    const handleSubmit = useCallback(
      (data: UpdateSatGeneralInformationFormData) => {
        const satCtnrsToCreate: SatCtnrToCreate[] = [];
        const satCtnrsToUpdate: SatCtnrToUpdate[] = [];
        const { satCtnrsToRemove } = satGeneralInformationState;

        data.satCtnrs?.forEach(formContainer => {
          if (typeof formContainer.idSatCtnr !== 'number') {
            const parsedId = parseInt(formContainer.idSatCtnr, 10);
            if (Number.isNaN(parsedId)) {
              satCtnrsToCreate.push({
                idTypeCtnr: formContainer.idTypeCtnr,
                qtyCtnr: formContainer.qtyCtnr,
              });
            } else if (
              satGeneralInformationState.satCtnrsToUpdate.includes(parsedId)
            ) {
              satCtnrsToUpdate.push({ ...formContainer, idSatCtnr: parsedId });
            }
          }
        });

        return {
          ...omit(data, [
            'satCtnrs',
            'satSuppliers',
            'idCountryOrigin',
            'idCountryProcedence',
            'paymentTermBalance',
          ]),
          satCtnrsToCreate,
          satCtnrsToUpdate,
          satCtnrsToRemove,
          clientCnpj: selectedClient.cnpj,
          importerCnpj: selectedImporter.cnpj,
        };
      },
      [satGeneralInformationState, selectedClient.cnpj, selectedImporter.cnpj],
    );

    const [
      getCountForeignTradesNotCanceled,
      { loading: foreignTradesCountLoading },
    ] = useLazyQuery(countForeignTradesNotCanceledQuery, {
      variables: {
        idSat: sat.idSat,
      },
      onCompleted: response => {
        setSatHasMoreThanOneActiveShipment(
          response?.countSatForeignTradesNotCanceledBySat > 1,
        );
      },
    });

    useImperativeHandle(ref, () => ({
      handleValidateGeneralInformationForm: async () => {
        const formData = formRef.current?.getData();

        try {
          // Esvazia possíveis erros já existentes no formulário
          formRef.current?.setErrors({});

          // Define requisitos de preenchimento do formulario
          const schema = object().shape({
            idCategory: string().required(requiredFieldErrorMessage),
            idClient: number().nullable().required(requiredFieldErrorMessage),
            typeOfOrder: number()
              .nullable()
              .required(requiredFieldErrorMessage),
            idClientIncoterm: number()
              .nullable()
              .required(requiredFieldErrorMessage),
            idPaymentTermCondition: number()
              .nullable()
              .required(requiredFieldErrorMessage),
            idPort: number().nullable().required(requiredFieldErrorMessage),
            estimatedInspectionDate: string()
              .nullable()
              .required(requiredFieldErrorMessage),
            estimatedShipmentDate: string()
              .nullable()
              .required(requiredFieldErrorMessage),
            estimatedArrivalDate: string()
              .nullable()
              .required(requiredFieldErrorMessage),
            idPurchaseUser: number()
              .nullable()
              .required(requiredFieldErrorMessage),
            idSalesUser: number()
              .nullable()
              .required(requiredFieldErrorMessage),
            satCtnrs: array().of(
              object().shape({
                qtyCtnr: number()
                  .nullable()
                  .required(requiredFieldErrorMessage),
                idTypeCtnr: number()
                  .nullable()
                  .required(requiredFieldErrorMessage),
              }),
            ),
          });

          // Efetua validação
          await schema.validate(formData, { abortEarly: false });

          return true;
        } catch (error) {
          setSelectedMenuItem(pageMenuItemKey);

          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          const firstError = error.inner[0];
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);

          // Define foco no input
          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef?.current?.focus) {
            inputWithError.inputRef?.current?.focus();
          }

          toastRef.current?.show({
            severity: 'warn',
            summary: 'General Information tab',
            detail: 'Please fill all the required fields',
            life: ToastLife.WARN,
          });

          throw new ValidationError(error);
        }
      },
      handleUpdateGeneralInformation: () => {
        const formData = formRef.current?.getData();
        return handleSubmit(formData as UpdateSatGeneralInformationFormData);
      },
      resetSatCtnrs: () => {
        satGeneralInformationDispatch({
          type: SatGeneralInformationActionKind.RESET_CTNRS_STATES,
          payload: { satCtnrs: sat.satCtnrs },
        });
      },
      checkIfDataChanged: () => {
        const formData =
          formRef.current?.getData() as UpdateSatGeneralInformationFormData;

        const dataToCompare = omit(formData, ['satCtnrs', 'satSuppliers']);

        const dataChanged = !objectsAreEqual(dataToCompare, sat);

        if (dataChanged) return true;

        // Formata id dos ctnrs do formulario para nao serem strings
        const formatedFormCtnrs =
          formData.satCtnrs?.map(item => {
            if (typeof item.idSatCtnr !== 'number') {
              const parsedId = parseInt(item.idSatCtnr, 10);
              if (!Number.isNaN(parsedId)) {
                return {
                  ...item,
                  idSatCtnr: parsedId,
                };
              }
            }

            return item;
          }) ?? [];

        const ctnrsChanged = !objectsAreEqual(formatedFormCtnrs, sat.satCtnrs);

        return ctnrsChanged;
      },
      refetchShipmentCount: () => setSatHasMoreThanOneActiveShipment(undefined),
    }));

    const [scheduleRelatedItems, setScheduleRelatedItems] =
      useState<ISatScheduleRelatedItems>({
        indSamples: sat.indSamples,
        samplesLeadTime: sat.samplesLeadTime,
        productionTime: sat.productionTime,
      });

    const [selectedExporter, setSelectedExporter] = useState<ISupplier>({
      idSupplier: sat.idSupplierExporter,
      name: sat.idSupplierExporter2?.name,
      address: sat.exporterAddress,
      idCountry2: sat.idCountryAcquisition2,
    } as ISupplier);

    const loadSuppliersOptions: LoadOptions<
      any,
      GroupBase<any>,
      { page: any }
    > = async (search: string, prevOptions: any, pageData) => {
      const res = await client.query({
        query: listAllSuppliersQuery,
        variables: {
          data: {
            pagination: {
              _page: pageData?.page,
              _limit: pagination.itemsPerPage,
            },
            name: search,
            active: true,
            exporter: true,
          },
        },
      });

      const filteredOptions = res.data.listAllSuppliers.data;
      const quantity = res.data.listAllSuppliers.items;

      const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

      return {
        options: filteredOptions,
        hasMore,
        additional: {
          page: pageData?.page + 1,
        },
      };
    };

    const loadSatsByClientOptions: LoadOptions<
      any,
      GroupBase<any>,
      { page: any }
    > = async (search: string, prevOptions: any, pageData) => {
      const res = await client.query({
        query: listAllSatsByClient,
        variables: {
          listAllSatsByClientInput: {
            pagination: {
              _page: pageData?.page,
              _limit: pagination.itemsPerPage,
            },
            satNumber: search,
            idClient: selectedClient.idClient,
          },
        },
      });

      const filteredOptions = res.data.listAllSatsByClient.data;
      const quantity = res.data.listAllSatsByClient.items;

      const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

      return {
        options: filteredOptions,
        hasMore,
        additional: {
          page: pageData?.page + 1,
        },
      };
    };

    const handleChangeSelectedClient = useCallback(
      (e: IClient) => {
        setSelectedClient(e);

        formRef.current?.setFieldValue('clientCnpj', e.cnpj);

        // Define valor de porto padrao do Client
        if (e.clientPorts?.length) {
          formRef.current?.setFieldValue('idPort', e.clientPorts[0].idPort2);
        }

        const paymentTermAdvance = e.paymentAdvance ?? 0;
        const paymentBalance = e.paymentBalance ?? 100 - paymentTermAdvance;

        formRef.current?.setFieldValue(
          'paymentTermAdvance',
          paymentTermAdvance,
        );
        formRef.current?.setFieldValue('paymentTermBalance', paymentBalance);
        if (e.payment2) {
          formRef.current?.setFieldValue('idPaymentTermCondition', e.payment2);
        } else {
          formRef.current?.clearField('idPaymentTermCondition');
        }

        // Limpa campos relacionados ao Client/Notify
        formRef.current?.clearField('idSatOrigin');
        formRef.current?.clearField('clientDepartment');
        formRef.current?.clearField('idFirstBuyer');
        formRef.current?.clearField('idSecondBuyer');
      },
      [formRef],
    );

    const calcPaymentTermBalance = useCallback(
      (value?: number) => {
        const paymentTermBalance = 100 - (value || 0);

        formRef.current?.setFieldValue(
          'paymentTermBalance',
          paymentTermBalance,
        );
      },

      [formRef],
    );

    // cria método para chamar query
    const [getSuppliersWithoutIncoterm] = useLazyQuery(
      listSatSuppliersBySatIdAndWithDifferentIncoterm,
      {
        onCompleted: response => {
          const suppliersWithError =
            response.listSatSuppliersBySatIdAndWithDifferentIncoterm?.map(
              (supplier: ISatSupplier) => {
                return supplier.idSupplier2.sunNumber;
              },
            );

          if (suppliersWithError?.length) {
            toastRef.current?.show({
              severity: 'warn',
              summary:
                'Purchase Incoterm is not compatible with the following Suppliers',
              detail: suppliersWithError.join(', '),
              life: ToastLife.WARN,
            });
          }
        },
      },
    );

    const handleChangeEstimatedInspectionDate = (e?: string) => {
      if (!e || sat.confirmOrderComercial) return;

      const estimatedShipmentDate = addDaysToDate(15, e, true);
      const estimatedArrivalDate = addDaysToDate(
        40,
        estimatedShipmentDate,
        true,
      );
      const estimatedArrivalAtClientDate = addDaysToDate(
        10,
        estimatedArrivalDate,
        true,
      );

      formRef.current?.setFieldValue(
        'estimatedShipmentDate',
        estimatedShipmentDate,
      );
      formRef.current?.setFieldValue(
        'estimatedArrivalDate',
        estimatedArrivalDate,
      );
      formRef.current?.setFieldValue(
        'estimatedArrivalClientDate',
        estimatedArrivalAtClientDate,
      );

      setSat({
        ...sat,
        estimatedInspectionDate: e,
      });
    };

    const recalculateEstimatedInspectionDate = (
      property: string,
      newValue: any,
    ) => {
      // Nada devera ser feito apos confirm order
      if (sat.confirmOrderComercial) return;

      setScheduleRelatedItems({
        ...scheduleRelatedItems,
        [property]: newValue,
      });

      const considerSamplesLeadTime =
        property === 'indSamples' ? newValue : scheduleRelatedItems.indSamples;

      const samplesLeadTime = considerSamplesLeadTime
        ? formRef.current?.getFieldValue('samplesLeadTime')
        : 0;

      const productionTime = formRef.current?.getFieldValue('productionTime');

      const estimatedInspectionMinimumTermInDays = 10;

      const estimatedInspectionDateFormula =
        productionTime + estimatedInspectionMinimumTermInDays + samplesLeadTime;

      const estimatedInspectionDate = addDaysToDate(
        estimatedInspectionDateFormula,
        new Date(),
        true,
      );

      formRef.current?.setFieldValue(
        'estimatedInspectionDate',
        estimatedInspectionDate,
      );

      handleChangeEstimatedInspectionDate(estimatedInspectionDate);
    };

    const onScheduleFormulaChange = (property: string, value?: boolean) => {
      // Nada devera ser feito apos confirm order
      if (sat.confirmOrderComercial) return;

      const changedPropertyValue =
        property === 'indSamples'
          ? value
          : formRef.current?.getFieldValue(property);

      if (changedPropertyValue !== scheduleRelatedItems[property]) {
        confirmDialog({
          message: 'The schedule fields will be recalculated, confirm?',
          header: 'Attention',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () =>
            recalculateEstimatedInspectionDate(property, changedPropertyValue),
          reject: () => {
            formRef.current?.setFieldValue(
              property,
              scheduleRelatedItems[property],
            );
          },
        });
      }
    };

    const userCanSeeCtnrGroup = useMemo(() => {
      return (
        fieldsPermissions.qtyCtnr.view || fieldsPermissions.idTypeCtnr.view
      );
    }, [fieldsPermissions.idTypeCtnr, fieldsPermissions.qtyCtnr]);

    const onCategoryChange = (e: any) => {
      if (
        !isNullOrUndefined(sat.indConfection) &&
        sat.indConfection !== !!e.node.data.indConfection
      ) {
        setTimeout(() => {
          formRef.current?.setFieldValue('idCategory', sat.idCategory);
        }, 100);
        toastRef.current?.show({
          severity: 'error',
          summary: 'Incompatible Category',
          detail: 'This category is not allowed',
          life: ToastLife.ERROR,
        });
      }
    };

    const parseClientNotifyHeader = () => {
      return sat.idClient ? (
        <h1 className="form-title p-col-12 ">
          <span className="link-span">
            <Link
              to={`/clients/list/${sat.idClient}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
              style={{ justifyContent: 'space-between' }}
            >
              Client/Notify
              <FiExternalLink size={15} />
            </Link>
          </span>
        </h1>
      ) : (
        <FormTitle className="p-col-12" name="Client/Notify" />
      );
    };

    const parseImporterConsigneeHeader = () => {
      return sat.idImporter ? (
        <h1 className="form-title p-col-12">
          <span className="link-span">
            <Link
              to={`/clients/list/${sat.idImporter}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
            >
              Importer/Consignee
              <FiExternalLink size={15} />
            </Link>
          </span>
        </h1>
      ) : (
        <FormTitle className="p-col-12" name="Importer/Consignee" />
      );
    };

    const parseExporterHeader = () => {
      return sat.idSupplierExporter ? (
        <h1 className="form-title p-col-12">
          <span className="link-span">
            <Link
              to={`/suppliers/list/${sat.idSupplierExporter}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
            >
              Exporter
              <FiExternalLink size={15} />
            </Link>
          </span>
        </h1>
      ) : (
        <FormTitle className="p-col-12" name="Exporter" />
      );
    };

    function handleChangeImporter(e: IClient) {
      setSelectedImporter(e);

      formRef.current?.setFieldValue('importerCnpj', e.cnpj);
    }

    const satIsCanceledOrCommercialOrderIsConfirmed =
      isSatCancelled || !!sat.confirmOrderComercial;

    useEffect(() => {
      if (selected && satHasMoreThanOneActiveShipment === undefined) {
        getCountForeignTradesNotCanceled();
      }
    }, [
      satHasMoreThanOneActiveShipment,
      getCountForeignTradesNotCanceled,
      selected,
    ]);

    return (
      <PageTabContainer selected={selected}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={sat}>
          <Container>
            <div className="p-grid group">
              <FormTitle className="p-col-12" name="Order" />

              {fieldsPermissions.mainProduct.view && (
                <FormInput
                  className="p-col-3"
                  name="mainProduct"
                  label="Main Product"
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.mainProduct,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.productLine.view && (
                <FormInput
                  className="p-col-3"
                  name="productLine"
                  label="Product Line"
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.productLine,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.idCategory.view && (
                <FormTreeSelectCategory
                  className="p-col-3"
                  name="idCategory"
                  label="Category"
                  filter
                  placeholder="Select a Category"
                  required
                  initialValue={sat.idCategory}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idCategory,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                  onNodeSelect={e => onCategoryChange(e)}
                />
              )}

              {fieldsPermissions.idTypeOperation.view && (
                <FormAsyncSelect
                  name="idTypeOperation"
                  className="p-col-3"
                  label="Type of Operation"
                  loadOptions={asyncSelectLoadDomains}
                  additional={{
                    id: 9,
                  }}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  noOptionsMessage={() => 'No type of operations found'}
                  initialValue={sat.idTypeOperation2}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idTypeOperation,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.typeOfOrder.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="typeOfOrder"
                  label="Type Of Order"
                  additional={{
                    id: 59,
                  }}
                  loadOptions={asyncSelectLoadDomains}
                  initialValue={sat.typeOfOrder2}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  noOptionsMessage={() => 'No type of orders found'}
                  required
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.typeOfOrder,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.idSatOrigin.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idSatOrigin"
                  label="Replacement From"
                  loadOptions={loadSatsByClientOptions}
                  initialValue={sat.idSatOrigin2}
                  getOptionLabel={(option: any) => option.satNumber}
                  getOptionValue={(option: any) => option.idSat}
                  noOptionsMessage={() => 'No SATs found'}
                  readOnly={
                    !selectedClient.idClient ||
                    shouldDisableField(
                      fieldsPermissions.idSatOrigin,
                      sat.idSat,
                    ) ||
                    isSatCancelled
                  }
                  placeholder={
                    selectedClient.idClient
                      ? undefined
                      : 'First, select a Client/Notify'
                  }
                  cacheUniqs={[selectedClient]}
                />
              )}

              {fieldsPermissions.indSamples.view && (
                <FormBooleanRadio
                  className="p-col-3"
                  name="indSamples"
                  label="Samples"
                  onValueChange={e => {
                    onScheduleFormulaChange('indSamples', e.value);
                  }}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.indSamples,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.samplesLeadTime.view && (
                <FormInputNumber
                  className="p-col-3"
                  name="samplesLeadTime"
                  label="Samples Lead Time"
                  readOnly={
                    !scheduleRelatedItems.indSamples ||
                    shouldDisableField(
                      fieldsPermissions.samplesLeadTime,
                      sat.idSat,
                    ) ||
                    isSatCancelled
                  }
                  onBlur={() => onScheduleFormulaChange('samplesLeadTime')}
                />
              )}

              {fieldsPermissions.packagesNotes.view && (
                <FormInput
                  className="p-col-6"
                  name="packagesNotes"
                  label="General Package Notes"
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.packagesNotes,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.remarks.view && (
                <FormInput
                  className="p-col-6"
                  name="remarks"
                  label="Remarks"
                  readOnly={
                    shouldDisableField(fieldsPermissions.remarks, sat.idSat) ||
                    isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.stock.view && (
                <FormBooleanRadio
                  className="p-col-3"
                  name="stock"
                  label="Stock"
                  readOnly={
                    shouldDisableField(fieldsPermissions.stock, sat.idSat) ||
                    isSatCancelled
                  }
                />
              )}

              <ReadOnlyInput
                className="p-col-3"
                label="SAT Status"
                value={sat.status2?.description}
              />
            </div>

            {userCanSeeCtnrGroup && (
              <div className="container-content group">
                <FormTitle name="CTNR" />

                {satGeneralInformationState.satCtnrs?.map(
                  (container, index) => {
                    const canRemoveCtnr = !(
                      sat.status === SatStatus.STAND_BY ||
                      sat.status === SatStatus.CANCELED
                    );
                    return (
                      <Card
                        key={container.idSatCtnr}
                        onRemove={() =>
                          satGeneralInformationDispatch({
                            type: SatGeneralInformationActionKind.REMOVE_CTNR,
                            payload: { idCtnr: container.idSatCtnr },
                          })
                        }
                        {...{ showRemoveButton: canRemoveCtnr }}
                      >
                        <FormInput
                          name={`satCtnrs[${index}].idSatCtnr`}
                          label=""
                          type="hidden"
                          value={container.idSatCtnr}
                          defaultValue={undefined}
                        />

                        {fieldsPermissions.qtyCtnr.view && (
                          <FormInputNumber
                            className="p-col-6"
                            name={`satCtnrs[${index}].qtyCtnr`}
                            label="Qty of CTNR"
                            required
                            onValueChange={() =>
                              satGeneralInformationDispatch({
                                type: SatGeneralInformationActionKind.SET_CHANGED_CNTR,
                                payload: { idCtnr: container.idSatCtnr },
                              })
                            }
                            readOnly={
                              shouldDisableField(
                                fieldsPermissions.qtyCtnr,
                                sat.idSat,
                              ) || isSatCancelled
                            }
                          />
                        )}

                        {fieldsPermissions.idTypeCtnr.view && (
                          <FormAsyncSelect
                            name={`satCtnrs[${index}].idTypeCtnr`}
                            className="p-col-6"
                            label="Type of CTNR"
                            loadOptions={asyncSelectLoadDomains}
                            additional={{
                              id: 55,
                            }}
                            getOptionLabel={(option: any) => option.description}
                            getOptionValue={(option: any) => option.idDomain}
                            noOptionsMessage={() => 'No type of CTNRs found'}
                            required
                            initialValue={container.idTypeCtnr2}
                            onValueChange={() =>
                              satGeneralInformationDispatch({
                                type: SatGeneralInformationActionKind.SET_CHANGED_CNTR,
                                payload: { idCtnr: container.idSatCtnr },
                              })
                            }
                            readOnly={
                              shouldDisableField(
                                fieldsPermissions.idTypeCtnr,
                                sat.idSat,
                              ) || isSatCancelled
                            }
                          />
                        )}
                      </Card>
                    );
                  },
                )}

                <MainButton
                  className="add-container-button"
                  type="button"
                  label="Add"
                  onClick={() =>
                    satGeneralInformationDispatch({
                      type: SatGeneralInformationActionKind.ADD_CTNR,
                    })
                  }
                  disabled={isSatCancelled}
                />
              </div>
            )}

            <div className="p-grid group">
              {parseClientNotifyHeader()}

              {fieldsPermissions.idClient.view && (
                <FormAsyncSelect
                  name="idClient"
                  className="p-col-3"
                  label="Client/Notify"
                  loadOptions={asyncSelectLoadClients}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.idClient}
                  noOptionsMessage={() => 'No clients found'}
                  required
                  initialValue={sat.idClient2}
                  onValueChange={e => handleChangeSelectedClient(e)}
                  readOnly={
                    shouldDisableField(fieldsPermissions.idClient, sat.idSat) ||
                    isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.companyName.view && (
                <FormInput
                  className="p-col-3"
                  name="companyName"
                  label="Company Name"
                  value={selectedClient?.companyName || ''}
                  defaultValue={undefined}
                  readOnly
                />
              )}

              {fieldsPermissions.clientCnpj.view && (
                <FormInputMask
                  className="p-col-3"
                  name="clientCnpj"
                  label="Tax ID"
                  mask={maskFormats.cnpj}
                  readOnly
                />
              )}

              {fieldsPermissions.clientAddress.view && (
                <FormInput
                  className="p-col-3"
                  name="clientAddress"
                  label="Client Address"
                  value={selectedClient?.address || ''}
                  defaultValue={undefined}
                  readOnly
                />
              )}

              {fieldsPermissions.clientDepartment.view && (
                <FormAsyncSelect
                  name="clientDepartment"
                  className="p-col-3"
                  label="Client Department"
                  loadOptions={asyncSelectLoadClientContactsDepartmentOptions}
                  getOptionLabel={(option: any) => option.department}
                  getOptionValue={(option: any) => option.department}
                  additional={{
                    idClient: selectedClient.idClient,
                  }}
                  noOptionsMessage={() => 'No departments found'}
                  initialValue={{ department: sat.clientDepartment }}
                  cacheUniqs={[selectedClient]}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.clientDepartment,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.idFirstBuyer.view && (
                <FormAsyncSelect
                  name="idFirstBuyer"
                  className="p-col-3"
                  label="First Buyer"
                  loadOptions={asyncSelectLoadClientContactsOptions}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.idClientContact}
                  additional={{
                    idClient: selectedClient.idClient,
                  }}
                  noOptionsMessage={() => 'No contacts found'}
                  initialValue={sat.idFirstBuyer2}
                  cacheUniqs={[selectedClient]}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idFirstBuyer,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.idSecondBuyer.view && (
                <FormAsyncSelect
                  name="idSecondBuyer"
                  className="p-col-3"
                  label="Second Buyer"
                  loadOptions={asyncSelectLoadClientContactsOptions}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.idClientContact}
                  additional={{
                    idClient: selectedClient.idClient,
                  }}
                  noOptionsMessage={() => 'No contacts found'}
                  initialValue={sat.idSecondBuyer2}
                  cacheUniqs={[selectedClient]}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idSecondBuyer,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}
            </div>

            <div className="p-grid group">
              {parseImporterConsigneeHeader()}

              {fieldsPermissions.idImporter.view && (
                <FormAsyncSelect
                  name="idImporter"
                  className="p-col-3"
                  label="Importer/Consignee"
                  loadOptions={asyncSelectLoadClients}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.idClient}
                  noOptionsMessage={() => 'No importers found'}
                  initialValue={selectedImporter}
                  onValueChange={e => handleChangeImporter(e)}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idImporter,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.importerName.view && (
                <FormInput
                  className="p-col-3"
                  name="importerName"
                  label="Company Name"
                  value={selectedImporter?.companyName || ''}
                  defaultValue={undefined}
                  readOnly
                />
              )}

              {fieldsPermissions.importerCnpj.view && (
                <FormInputMask
                  className="p-col-3"
                  name="importerCnpj"
                  label="Importer Tax ID"
                  mask={maskFormats.cnpj}
                  readOnly
                />
              )}
              {fieldsPermissions.importerAddress.view && (
                <FormInput
                  className="p-col-3"
                  name="importerAddress"
                  label="Importer Address"
                  readOnly
                  value={selectedImporter?.address || ''}
                  defaultValue={undefined}
                />
              )}
            </div>

            <div className="p-grid group">
              <FormTitle className="p-col-12" name="Payment" />

              {fieldsPermissions.idClientIncoterm.view && (
                <FormAsyncSelect
                  name="idClientIncoterm"
                  className="p-col-3"
                  label="Client Selling Incoterm"
                  loadOptions={asyncSelectLoadDomains}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    id: 4,
                  }}
                  noOptionsMessage={() => 'No incoterms found'}
                  required
                  initialValue={sat.idClientIncoterm2}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idClientIncoterm,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.paymentTermAdvance.view && (
                <FormInputNumber
                  className="p-col-3"
                  name="paymentTermAdvance"
                  label="Payment Term Advance (%)"
                  decimalScale={0}
                  suffix="%"
                  min={0}
                  max={100}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.paymentTermAdvance,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                  onValueChange={value =>
                    calcPaymentTermBalance(value.floatValue)
                  }
                />
              )}
              {fieldsPermissions.paymentTermBalance.view && (
                <FormInputNumber
                  className="p-col-3"
                  name="paymentTermBalance"
                  suffix="%"
                  label="Payment Term Balance (%)"
                  readOnly
                />
              )}
              {fieldsPermissions.idPaymentTermCondition.view && (
                <FormAsyncSelect
                  name="idPaymentTermCondition"
                  className="p-col-3"
                  label="Payment Term Condition"
                  loadOptions={asyncSelectLoadDomains}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    id: 13,
                  }}
                  noOptionsMessage={() => 'No conditions found'}
                  required
                  initialValue={sat.idPaymentTermCondition2}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idPaymentTermCondition,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}
            </div>

            <div className="p-grid group">
              {parseExporterHeader()}

              {fieldsPermissions.idPurchaseIncoterm.view && (
                <FormAsyncSelect
                  name="idPurchaseIncoterm"
                  className="p-col-3"
                  label="Purchase Incoterm"
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    id: DomainGroup.INCOTERM,
                  }}
                  noOptionsMessage={() => 'No incoterms found'}
                  initialValue={sat.idPurchaseIncoterm2}
                  onValueChange={e =>
                    getSuppliersWithoutIncoterm({
                      variables: { idSat: sat.idSat, idIncoterm: e.idDomain },
                    })
                  }
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idPurchaseIncoterm,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.idSupplierExporter.view && (
                <FormAsyncSelect
                  name="idSupplierExporter"
                  className="p-col-3"
                  label="Exporter"
                  loadOptions={loadSuppliersOptions}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.idSupplier}
                  noOptionsMessage={() => 'No suppliers found'}
                  initialValue={selectedExporter}
                  onValueChange={e => setSelectedExporter(e)}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idSupplierExporter,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}

              {fieldsPermissions.exporterAddress.view && (
                <FormInput
                  name="exporterAddress"
                  className="p-col-3"
                  label="Exporter Address"
                  readOnly
                  value={selectedExporter?.address || ''}
                  defaultValue={undefined}
                />
              )}

              {fieldsPermissions.productionTime.view && (
                <FormInputNumber
                  className="p-col-3"
                  name="productionTime"
                  label="Production Time"
                  decimalScale={0}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.productionTime,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                  onBlur={() => onScheduleFormulaChange('productionTime')}
                />
              )}

              {fieldsPermissions.satSuppliers.view && (
                <FormAsyncSelect
                  name="satSuppliers"
                  className="p-col-3"
                  label="SUN Number"
                  isMulti
                  loadOptions={asyncSelectLoadDomains}
                  getOptionLabel={(option: any) =>
                    `SUN${option.idSupplier.toString().padStart(5, '0')} `
                  }
                  getOptionValue={(option: any) => option.idSatSupplier}
                  initialValue={sat.satSuppliers}
                  readOnly
                />
              )}

              {fieldsPermissions.manufacture.view && (
                <FormInput
                  className="p-col-9"
                  name="manufacture"
                  label="Manufacture"
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.manufacture,
                      sat.idSat,
                    ) || isSatCancelled
                  }
                />
              )}
            </div>

            <div className="p-grid group">
              <FormTitle className="p-col-12" name="Logistics" />

              {fieldsPermissions.idLoadingPort.view && (
                <FormAsyncSelect
                  name="idLoadingPort"
                  className="p-col-3"
                  label="Port/Airport Of Loading"
                  loadOptions={asyncSelectLoadPortsOptions}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.idPort}
                  noOptionsMessage={() => 'No ports found'}
                  initialValue={sat.idLoadingPort2}
                  onValueChange={e =>
                    formRef.current?.setFieldValue(
                      'idCountryProcedence',
                      e.idCountry2,
                    )
                  }
                  additional={{
                    idCountry: sat.idCountryOrigin,
                  }}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idLoadingPort,
                      sat.idSat,
                    ) ||
                    isSatCancelled ||
                    satHasMoreThanOneActiveShipment
                  }
                />
              )}

              {fieldsPermissions.idPort.view && (
                <FormAsyncSelect
                  name="idPort"
                  className="p-col-3"
                  label="Port/Airport Of Discharge"
                  loadOptions={asyncSelectLoadPortsOptions}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.idPort}
                  noOptionsMessage={() => 'No ports found'}
                  required
                  initialValue={sat.idPort2}
                  readOnly={
                    shouldDisableField(fieldsPermissions.idPort, sat.idSat) ||
                    isSatCancelled ||
                    satHasMoreThanOneActiveShipment
                  }
                />
              )}

              <div className="p-col-6" />

              {fieldsPermissions.idCountryOrigin.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idCountryOrigin"
                  label="Country Origin"
                  loadOptions={asyncSelectLoadPortsOptions}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.idCountry}
                  value={sat.idCountryOrigin2}
                  readOnly
                  placeholder=""
                />
              )}

              {fieldsPermissions.idCountryAcquisition.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idCountryAcquisition"
                  label="Country Acquisition"
                  loadOptions={asyncSelectLoadPortsOptions}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.idCountry}
                  value={selectedExporter.idCountry2}
                  readOnly
                  placeholder=""
                />
              )}

              {fieldsPermissions.idCountryProcedence.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idCountryProcedence"
                  label="Country Procedence"
                  loadOptions={asyncSelectLoadPortsOptions}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.idCountry}
                  value={sat.idCountryProcedence2}
                  readOnly
                  placeholder=""
                />
              )}
            </div>

            <div className="p-grid group">
              <FormTitle className="p-col-12" name="Schedule" />

              {fieldsPermissions.estimatedInspectionDate.view && (
                <FormInput
                  className="p-col-3"
                  name="estimatedInspectionDate"
                  label="Estimated Inspection Date"
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.estimatedInspectionDate,
                      sat.idSat,
                    ) ||
                    isSatCancelled ||
                    satHasMoreThanOneActiveShipment
                  }
                  onChange={e =>
                    handleChangeEstimatedInspectionDate(e.target.value)
                  }
                  required
                  type="date"
                />
              )}

              {fieldsPermissions.estimatedShipmentDate.view && (
                <FormInput
                  className="p-col-3"
                  name="estimatedShipmentDate"
                  label="Estimated Shipment Date"
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.estimatedShipmentDate,
                      sat.idSat,
                    ) ||
                    isSatCancelled ||
                    satHasMoreThanOneActiveShipment
                  }
                  required
                  type="date"
                />
              )}

              {fieldsPermissions.estimatedArrivalDate.view && (
                <FormInput
                  className="p-col-3"
                  name="estimatedArrivalDate"
                  label="Estimated Arrival Date"
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.estimatedArrivalDate,
                      sat.idSat,
                    ) ||
                    isSatCancelled ||
                    satHasMoreThanOneActiveShipment
                  }
                  type="date"
                />
              )}

              {fieldsPermissions.estimatedArrivalClientDate.view && (
                <FormInput
                  className="p-col-3"
                  name="estimatedArrivalClientDate"
                  label="Estimated Arrival At Client Date"
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.estimatedArrivalClientDate,
                      sat.idSat,
                    ) ||
                    isSatCancelled ||
                    satHasMoreThanOneActiveShipment
                  }
                  type="date"
                />
              )}
            </div>

            {fieldsPermissions.satGroup.view && (
              <Group>
                <SatGroup
                  sat={sat}
                  isSatCancelled={isSatCancelled}
                  fieldsPermissions={fieldsPermissions}
                  selected={selected}
                  setPageLoading={setPageLoading}
                  satRefetch={satRefetch}
                />
              </Group>
            )}

            <div className="p-grid group">
              <FormTitle className="p-col-12" name="Users" />

              {fieldsPermissions.idPurchaseUser.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idPurchaseUser"
                  label="Purchase User"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.PURCHASE, Role.PURCHASE_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={sat.idPurchaseUser2}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idPurchaseUser,
                      sat.idSat,
                    ) || satIsCanceledOrCommercialOrderIsConfirmed
                  }
                  required
                />
              )}

              {fieldsPermissions.idSalesUser.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idSalesUser"
                  label="1st Commercial User"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.COMMERCIAL, Role.COMMERCIAL_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={sat.idSalesUser2}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idSalesUser,
                      sat.idSat,
                    ) || satIsCanceledOrCommercialOrderIsConfirmed
                  }
                  required
                />
              )}

              {fieldsPermissions.idSalesSecondUser.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idSalesSecondUser"
                  label="2nd Commercial User"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.COMMERCIAL, Role.COMMERCIAL_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={sat.idSalesSecondUser2}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idSalesSecondUser,
                      sat.idSat,
                    ) || satIsCanceledOrCommercialOrderIsConfirmed
                  }
                />
              )}
              {fieldsPermissions.idFinancialUser.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idFinancialUser"
                  label="Financial User"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.FINANCIAL, Role.FINANCIAL_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={sat.idFinancialUser2}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idFinancialUser,
                      sat.idSat,
                    ) || satIsCanceledOrCommercialOrderIsConfirmed
                  }
                />
              )}

              {fieldsPermissions.idComexExportUser.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idComexExportUser"
                  label="Export User"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.COMEX_EXPORT, Role.COMEX_EXPORT_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={sat.idComexExportUser2}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idComexExportUser,
                      sat.idSat,
                    ) || satIsCanceledOrCommercialOrderIsConfirmed
                  }
                />
              )}

              {fieldsPermissions.idComexImportUser.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idComexImportUser"
                  label="Import User"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.COMEX_IMPORT, Role.COMEX_IMPORT_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={sat.idComexImportUser2}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idComexImportUser,
                      sat.idSat,
                    ) || satIsCanceledOrCommercialOrderIsConfirmed
                  }
                />
              )}

              {fieldsPermissions.idDesignerUser.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idDesignerUser"
                  label="Designer User"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  additional={{
                    idsRoles: [Role.DESIGN, Role.DESIGN_KEY_USER],
                  }}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={sat.idDesignerUser2}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idDesignerUser,
                      sat.idSat,
                    ) || satIsCanceledOrCommercialOrderIsConfirmed
                  }
                />
              )}

              {fieldsPermissions.idRegisterUser.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="idRegisterUser"
                  label="Register User"
                  loadOptions={loadUsersByRolesOptions}
                  getOptionLabel={(option: any) =>
                    `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
                  }
                  getOptionValue={(option: any) => option.idUser}
                  noOptionsMessage={() => 'No users found'}
                  initialValue={sat.idRegisterUser2}
                  readOnly={
                    shouldDisableField(
                      fieldsPermissions.idRegisterUser,
                      sat.idSat,
                    ) || satIsCanceledOrCommercialOrderIsConfirmed
                  }
                  additional={{
                    idsRoles: [Role.PRODUCT_REGISTER],
                  }}
                />
              )}
            </div>
          </Container>
        </Form>

        {foreignTradesCountLoading && <Loading />}
      </PageTabContainer>
    );
  },
);

export default GeneralInformation;
