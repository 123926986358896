/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { Column } from 'primereact/column';
import { useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import {
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { confirmDialog } from 'primereact/confirmdialog';
import { Container } from './styles';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import Loading from '../../../components/Loading';
import { useRefHook } from '../../../hooks/useRefHook';
import { useAuth } from '../../../hooks/useAuth';
import { incotermRoles } from '../../../shared/roles/incoterm';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../utils/userHasPermission';
import ToastLife from '../../../shared/enums/toastLife';
import { searchDelayMiliseconds } from '../../../config/pagination';
import useTitle from '../../../hooks/useTitle';
import { gridConstants } from '../../../components/Grid/constants';

/**
 * Interface de Domain
 */
interface IDomain {
  idDomain: number;
  description: string;
}

/**
 * Interface dos parametros do backend
 */
interface ILazyParams {
  first: number;
  rows: number;
  page: number;
  sortField?: string;
  sortOrder?: DataTableSortOrderType;
  globalFilter?: string;
}

const pageTitle = 'List of Incoterms';

const Incoterms: React.FC = () => {
  useTitle(pageTitle);

  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { toastRef } = useRefHook();

  // Roles do usuario
  const { roles } = useAuth();

  // Permissions de Incoterms
  const { idPermissionDeleteIncoterm } = incotermRoles.permissions;

  // Busca permissoes do usuario para a entity
  const userPermissionsIncoterm = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    incotermRoles.idEntity,
  );

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Colunas da grid
  const columns = [{ field: 'description', header: 'Name' }];

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ILazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  // Busca global na grid
  const [globalFilter, setGlobalFilter] = useState('');

  // Incoterms selecionados
  const [selectedIncoterms, setSelectedIncoterms] = useState([]);

  // Estado de scrollHeight da grid
  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );
  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar incoterms
  const listIncotermsQuery = gql`
    query ListDomainsByGroupIdQuery(
      $id: Int!
      $pagination: Pagination!
      $description: String
    ) {
      listDomainsByGroupId(
        id: $id
        pagination: $pagination
        description: $description
      ) {
        data {
          idDomain
          description
        }
        items
      }
    }
  `;

  // Query para deletar incoterms
  const deleteIncotermsQuery = gql`
    mutation Delete($domainsId: [Int]!) {
      deleteDomains(domainsId: $domainsId)
    }
  `;

  // cria método para chamar a mutation
  const [deleteIncoterms] = useMutation(deleteIncotermsQuery);

  /**
   * Busca Incoterms
   */
  const {
    data: incotermsData,
    error: incotermsError,
    loading: incotermsLoading,
    refetch: incotermsRefetch,
  } = useQuery(listIncotermsQuery, {
    variables: {
      id: 4,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting incoterms',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = columns.map(col => {
    return (
      <Column
        key={col.field}
        columnKey={col.field}
        field={col.field}
        // Valida necessidade de icone de filtro no header
        header={col.header}
        style={{ width: '100px', flex: 'auto' }}
        sortable
      />
    );
  });

  /**
   * Direciona usuario para pagina do incoterm clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(`/list/incoterms/${e.data.idDomain}`);
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortParams) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Deleta incoterms selecionados
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedIncoterms.length} incoterms?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada incoterm
        const idsToDelete = selectedIncoterms.map((a: IDomain) => a.idDomain);
        try {
          // Deleta incoterms
          await deleteIncoterms({
            variables: {
              domainsId: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedIncoterms.length} incoterms`,
            life: ToastLife.SUCCESS,
          });

          // Zera estado de incoterms selecionadas
          setSelectedIncoterms([]);

          // Atualiza grid de incoterms
          await incotermsRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting incoterms',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  return (
    <Container>
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar incoterm */}
        <MainButton
          className="mainButton"
          label="New Incoterm"
          onClick={() => history.push('/list/incoterms/create')}
        />

        {/* Botao para excluir incoterms selecionados */}
        {userHasPermission(
          idPermissionDeleteIncoterm,
          userPermissionsIncoterm.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedIncoterms.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for an incoterm"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        className="grid"
        name="incoterms"
        lazy
        totalRecords={
          !incotermsData || incotermsError
            ? 0
            : incotermsData.listDomainsByGroupId.items
        }
        value={
          !incotermsData || incotermsError
            ? undefined
            : incotermsData.listDomainsByGroupId.data
        }
        globalFilter={globalFilter}
        emptyMessage="No incoterms found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={!incotermsData || incotermsError ? undefined : lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedIncoterms}
        onSelectionChange={e => setSelectedIncoterms(e.value)}
      >
        {userHasPermission(
          idPermissionDeleteIncoterm,
          userPermissionsIncoterm.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}

        {dynamicColumns}
      </Grid>
      {(pageLoading || incotermsLoading) && <Loading />}
    </Container>
  );
};
export default Incoterms;
