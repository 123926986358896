/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useCallback } from 'react';

import { CgLogOut } from 'react-icons/cg';
import { useHistory, Link } from 'react-router-dom';
import {
  AiOutlineMenu,
  AiOutlineBell,
  AiFillBell,
  AiOutlineClose,
} from 'react-icons/ai';
import { Divider } from 'primereact/divider';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import Tag from '../Tag';
import logoHeader from '../../assets/logoHeader.svg';
import {
  Container,
  Actions,
  NotificationButton,
  NotificationContainer,
  NotificationText,
  NotificationBadge,
  NotificationImage,
  NotificationBadgeContainer,
  NotificationBadgeText,
  ContainerAvatar,
  UserName,
  RecordTitle,
  AddedDate,
  IsReadBadge,
  Overlay,
} from './styles';
import { useAuth } from '../../hooks/useAuth';
import Button from '../Button';
import RoundUserAvatar from '../RoundUserAvatar';
import { convertDate } from '../../utils/convertDate';

import { useMenu } from '../../hooks/useMenu';
import { satsRoles } from '../../shared/roles/sat';
import { clientQuotationRoles } from '../../shared/roles/clientQuotation';
import { prcRoles } from '../../shared/roles/prc';
import SearchAll from './SearchAll';

interface Notification {
  idNotification: number;
  idComment: number;
  idUserNotified: number;
  isRead: boolean;
  sentEmail: Date;
  createdBy: number;
  createdAt: Date;
  updatedBy: number;
  updatedAt: Date;
  versionLock: number;
  idRecord: number;
  recordTitle: string;
  idSatRnc?: number;
  idSatForeignTrade?: number;
  createdBy2: NotificationUser;
  idComment2: NotificationComment;
  idModule2: NotificationModule;
}

interface NotificationUser {
  idUser: number;
  username: string;
  idAvatar: number;
  namePrefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  nameSuffix: string;
  avatarUrl: string;
}

interface NotificationComment {
  idComment: number;
  idParentComment?: number;
  message: string;
}

interface NotificationModule {
  idModule: number;
  name: string;
  menuIcon: string;
}

const Header: React.FC = () => {
  const { changeVisibility } = useMenu();

  // Dados do usuário
  const [qtyNotification, setQtyNotification] = useState<number>(0);

  // Notification aberto
  const [showNotifications, setShowNotifications] = useState<boolean>(false);

  // Notificações do usuário
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // Função de logout
  const { signOut, idUser } = useAuth();

  // Redirect
  const history = useHistory();

  // Efetua logout
  function handleSignOut() {
    signOut();
    history.push('/');
  }

  function handleSeverityByEnvironment(environment: string): string {
    switch (environment) {
      case 'local':
        return 'success';
      case 'development':
        return 'danger';
      case 'test':
        return 'primary';
      default:
        return '';
    }
  }

  const listUserQuery = gql`
    query listUser($listUserIdUser: Int) {
      listUser(idUser: $listUserIdUser) {
        qtyNotification
      }
    }
  `;
  const listAllNotificationsQuery = gql`
    query listAllNotifications {
      listAllNotifications {
        idNotification
        idComment
        idUserNotified
        isRead
        sentEmail
        createdBy
        createdAt
        updatedBy
        updatedAt
        versionLock
        idRecord
        recordTitle
        createdBy2 {
          idUser
          username
          idAvatar
          namePrefix
          firstName
          middleName
          lastName
          nameSuffix
          avatarUrl
        }
        idComment2 {
          idComment
          idParentComment
          message
        }
        idModule2 {
          idModule
          name
          menuIcon
        }
        idSatRnc
        idSatForeignTrade
      }
    }
  `;
  const markNotificationAsReadQuery = gql`
    mutation MarkNotificationAsRead($idNotification: Int!) {
      markNotificationAsRead(idNotification: $idNotification) {
        idNotification
        idComment
        idUserNotified
        isRead
        sentEmail
        createdBy
        createdAt
        updatedBy
        updatedAt
        versionLock
        idRecord
        recordTitle
        createdBy2 {
          idUser
          username
          idAvatar
          namePrefix
          firstName
          middleName
          lastName
          nameSuffix
          avatarUrl
        }
        idComment2 {
          idComment
          message
        }
        idModule2 {
          idModule
          name
          menuIcon
        }
      }
    }
  `;

  const updateNotificationIsReadValeuQuery = gql`
    mutation UpdateNotificationIsReadValue(
      $idNotification: Int!
      $isRead: Boolean!
    ) {
      updateNotificationIsReadValue(
        idNotification: $idNotification
        isRead: $isRead
      ) {
        isRead
      }
    }
  `;
  const markAllNotificationsAsReadQuery = gql`
    mutation MarkAllNotificationsAsRead($idUser: Int!) {
      markAllNotificationsAsRead(idUser: $idUser) {
        idNotification
        idComment
        idUserNotified
        isRead
        sentEmail
        createdBy
        createdAt
        updatedBy
        updatedAt
        versionLock
        idRecord
        recordTitle
        createdBy2 {
          idUser
          username
          idAvatar
          namePrefix
          firstName
          middleName
          lastName
          nameSuffix
          avatarUrl
        }
        idComment2 {
          idComment
          message
        }
        idModule2 {
          idModule
          name
          menuIcon
        }
      }
    }
  `;
  const [loadUserData] = useLazyQuery(listUserQuery, {
    variables: {
      listUserIdUser: idUser,
    },
    onCompleted: response => {
      setQtyNotification(response.listUser.qtyNotification);
    },
  });
  const [loadNotificationsData] = useLazyQuery(listAllNotificationsQuery, {
    variables: {},
    onCompleted: response => {
      setNotifications(response.listAllNotifications);
    },
  });
  const [markNotificationAsReadMutation] = useMutation(
    markNotificationAsReadQuery,
  );
  const [updateNotificationIsReadValueMutation] = useMutation(
    updateNotificationIsReadValeuQuery,
  );
  const [markAllNotificationsAsReadMutation] = useMutation(
    markAllNotificationsAsReadQuery,
  );

  const handleGoToModule = (
    idComment: number,
    idModule: number,
    idRecord: number,
    idSatRnc?: number,
    idSatForeignTrade?: number,
    idParentComment?: number,
  ) => {
    const commentsIdsArray: string[] = [];

    if (idParentComment) {
      commentsIdsArray.push(`idComment=${idParentComment}`);
      commentsIdsArray.push(`idChildComment=${idComment}`);
    } else {
      commentsIdsArray.push(`idComment=${idComment}`);
    }

    const commentsParams = commentsIdsArray.join('&');

    switch (idModule) {
      case satsRoles.idModule:
        if (idSatRnc) {
          window.open(
            `/commercial/sats/${idRecord}?tab=rnc&rncId=${idSatRnc}`,
            '_blank',
          );
        } else if (idSatForeignTrade) {
          window.open(
            `/commercial/sats/${idRecord}?tab=updates&foreignTradeId=${idSatForeignTrade}&${commentsParams}`,
            '_blank',
          );
        } else {
          window.open(
            `/commercial/sats/${idRecord}?tab=updates&${commentsParams}`,
            '_blank',
          );
        }
        break;
      case clientQuotationRoles.idModule:
        window.open(
          `/client-quotations/list/${idRecord}?tab=updates`,
          '_blank',
        );
        break;
      case prcRoles.idModule:
        window.open(`/prcs/list/${idRecord}?tab=updates`, '_blank');
        break;
      default:
        break;
    }
  };

  async function handleNotificationClicked(notification: Notification) {
    const notifsClone = [...notifications];
    const notifIndex = notifsClone.findIndex(
      not => not.idNotification === notification.idNotification,
    );
    const notif = {
      ...notifsClone.find(
        not => not.idNotification === notification.idNotification,
      ),
      isRead: true,
    };
    notifsClone[notifIndex!] = notif as Notification;
    setNotifications(notifsClone);
    handleGoToModule(
      notification.idComment,
      notification.idModule2.idModule,
      notification.idRecord,
      notification.idSatRnc,
      notification.idSatForeignTrade,
      notification.idComment2.idParentComment,
    );
    setShowNotifications(false);
    const response = await markNotificationAsReadMutation({
      variables: {
        idNotification: notification.idNotification,
      },
    });
    setNotifications(response.data.markNotificationAsRead);
    setQtyNotification(qtyNotification - 1);
  }

  const updateIsReadValue = useCallback(
    (notificationId: number, isRead: boolean) => {
      const notificationsClone = [...notifications];
      const notificationClone = notificationsClone.find(
        notification => notification.idNotification === notificationId,
      );
      if (notificationClone) {
        notificationClone.isRead = isRead;
        setNotifications(notificationsClone);
      }
    },
    [notifications],
  );

  const handleBadgeClick = useCallback(
    async (notification: Notification) => {
      const response = await updateNotificationIsReadValueMutation({
        variables: {
          idNotification: notification.idNotification,
          isRead: !notification.isRead,
        },
      });
      const isRead = response.data.updateNotificationIsReadValue?.isRead;
      setQtyNotification(isRead ? qtyNotification - 1 : qtyNotification + 1);
      updateIsReadValue(notification.idNotification, isRead);
    },
    [updateIsReadValue, updateNotificationIsReadValueMutation, qtyNotification],
  );

  async function handleMarkAllAsReadClicked() {
    let notifsClone = [...notifications];
    notifsClone = notifsClone.map(
      notif =>
        ({
          ...notif,
          isRead: true,
        } as Notification),
    );
    setNotifications(notifsClone);
    const response = await markAllNotificationsAsReadMutation({
      variables: {
        idUser: 0,
      },
    });
    setNotifications(response.data.markAllNotificationsAsRead);
    setQtyNotification(0);
  }

  async function handleUserNotificationClick() {
    setShowNotifications(!showNotifications);
    loadNotificationsData();
    loadUserData();
  }

  const removeHtmlAndMentionCharactersFromNotification = (content: string) => {
    const removeHtmlRegex = /(<([^>]+)>)/gi;
    const removeUserMentionsRegex = /@\(\d+\)/gi;
    return content
      .replace(removeHtmlRegex, '')
      .replace(removeUserMentionsRegex, '');
  };

  useEffect(() => {
    loadUserData();
    const interval = setInterval(() => {
      loadUserData();
    }, 180000);
    return () => clearInterval(interval);
  }, [idUser, loadUserData]);
  function hasNotification(): boolean {
    if (qtyNotification > 0) {
      return true;
    }
    return false;
  }
  function formatNotifications(): string {
    if (qtyNotification > 9) {
      return '9+';
    }
    return qtyNotification.toString() ?? '';
  }

  return (
    <Container>
      <span>
        <button type="button" onClick={changeVisibility}>
          <AiOutlineMenu size={20} />
        </button>
        <Link to="/">
          <img src={logoHeader} alt="SAT" />
        </Link>
        {process.env.REACT_APP_ENV &&
          process.env.REACT_APP_ENV !== 'production' && (
            <Tag
              value={process.env.REACT_APP_ENV.toUpperCase()}
              severity={handleSeverityByEnvironment(process.env.REACT_APP_ENV)}
            />
          )}
      </span>

      <SearchAll />

      <Actions>
        <NotificationButton
          onClick={() => {
            handleUserNotificationClick();
          }}
        >
          <NotificationContainer>
            <NotificationBadge
              style={hasNotification() ? {} : { width: '80px' }}
            >
              <NotificationImage>
                {hasNotification() && <AiFillBell size={15} />}
                {!hasNotification() && <AiOutlineBell size={15} />}
              </NotificationImage>
              <NotificationBadgeContainer
                style={hasNotification() ? {} : { display: 'none' }}
              >
                <NotificationBadgeText
                  value={formatNotifications()}
                  severity="danger"
                />
              </NotificationBadgeContainer>
            </NotificationBadge>
            <NotificationText>Notifications</NotificationText>
          </NotificationContainer>
        </NotificationButton>

        {/* TODO: Implement user page */}
        {/* <Link to="/user">
          <FiUser size={15} />
          User
        </Link> */}

        <button type="button" onClick={handleSignOut}>
          <CgLogOut size={15} />
          Exit
        </button>
      </Actions>
      {showNotifications && (
        <Overlay className="p-overlaypanel p-component overlaypanel-demo p-overlaypanel-enter-done">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <h3 style={{ width: '250px' }}>Notifications</h3>
              <Button
                style={{ marginRight: '30px' }}
                className="advanced-filters-button"
                label="Mark all as read"
                onClick={() => handleMarkAllAsReadClicked()}
              />
              <AiOutlineClose
                size={20}
                onClick={() => setShowNotifications(false)}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <Divider />
            <div className="items-session">
              {notifications.map(notification => (
                <div
                  key={notification.idNotification}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '12px',
                  }}
                >
                  <div
                    onClick={() => handleNotificationClicked(notification)}
                    onKeyDown={e => e.stopPropagation()}
                    role="button"
                    tabIndex={0}
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                    }}
                  >
                    <ContainerAvatar>
                      <span className="user-avatar-wrapper">
                        <RoundUserAvatar
                          image={notification.createdBy2.avatarUrl ?? ''}
                          userFullName={`${notification.createdBy2.firstName} ${notification.createdBy2.lastName}`}
                          imageAlt={`${notification.createdBy2.firstName} ${notification.createdBy2.lastName}`}
                        />
                      </span>
                    </ContainerAvatar>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        key={notification.idNotification}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <span className="notification-title">
                          <UserName>{`${notification.createdBy2.firstName} ${notification.createdBy2.lastName}`}</UserName>
                          <UserName>&nbsp;-&nbsp;</UserName>
                          <RecordTitle>{notification.recordTitle}</RecordTitle>
                        </span>
                      </div>
                      <p
                        style={{
                          width: '340px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {removeHtmlAndMentionCharactersFromNotification(
                          notification.idComment2.message,
                        )}
                      </p>
                      <AddedDate>
                        Added:&nbsp;{convertDate(notification.createdAt)}
                      </AddedDate>
                    </div>
                  </div>
                  <IsReadBadge
                    isRead={notification.isRead}
                    onClick={() => handleBadgeClick(notification)}
                    onKeyDown={e => e.stopPropagation()}
                    role="button"
                  />
                </div>
              ))}
              {notifications.length === 0 && (
                <h3
                  style={{
                    textAlign: 'center',
                    margin: '30px',
                  }}
                >
                  No notifications for you
                </h3>
              )}
            </div>
          </div>
        </Overlay>
      )}
    </Container>
  );
};

export default Header;
