import { gql, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { useHistory } from 'react-router-dom';
import MainButton from '../../../components/MainButton';
import StatusTag from '../../../components/StatusTag';
import { useRefHook } from '../../../hooks/useRefHook';
import ToastLife from '../../../shared/enums/toastLife';
import { parseCqCodeColumn } from '../../../utils/gridColumnsParse';

import { Container } from './styles';

const ClientQuotationWidget: React.FC = () => {
  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Redirect
  const history = useHistory();

  // Query para listar PRCs
  const listClientQuotationsQuery = gql`
    query listClientQuotationsWidgetQuery($pagination: Pagination!) {
      listClientQuotationsWidget(pagination: $pagination) {
        items
        data {
          idCq
          cqNumber
          idStatus
          idStatus2 {
            idDomain
            description
          }
          createdBy2 {
            firstName
            lastName
          }
          createdAt
          idClient
          idClient2 {
            name
            companyName
          }
          idResponsibleUser2 {
            firstName
            lastName
          }
        }
      }
    }
  `;

  /**
   * Busca PRCs
   */
  const { loading: cqsLoading, data: cqsData } = useQuery(
    listClientQuotationsQuery,
    {
      variables: {
        pagination: {
          _page: 1,
          _limit: 5,
        },
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Cqs',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  const cqsSkeleton = Array.from({ length: 5 });

  const bodyTemplate = () => {
    return <Skeleton />;
  };

  return (
    <Container>
      <DataTable
        className="grid"
        value={
          !cqsData ? cqsSkeleton : cqsData.listClientQuotationsWidget?.data
        }
        emptyMessage="No Cqs found."
        reorderableColumns
        removableSort
        responsiveLayout="scroll"
        onRowClick={e =>
          history.push(`/client-quotations/list/${e?.data.idCq}`)
        }
      >
        <Column
          columnKey="cqNumber"
          field="cqNumber"
          className="cqNumberColumn"
          header="CQ Number"
          sortable
          body={e => (cqsLoading || !e ? bodyTemplate() : parseCqCodeColumn(e))}
        />
        <Column
          columnKey="idStatus2.description"
          field="idStatus2.description"
          header="Status"
          sortable
          body={e =>
            cqsLoading || !e ? (
              bodyTemplate()
            ) : (
              <StatusTag
                className="status-tag"
                domain={e?.idStatus2.idDomain}
                status={e?.idStatus2.description}
              />
            )
          }
        />
        <Column
          columnKey="createdBy2.firstName"
          field="createdBy2.firstName"
          header="Created By"
          sortable
          body={e =>
            cqsLoading || !e
              ? bodyTemplate()
              : `${e?.createdBy2.firstName} ${e?.createdBy2.lastName}`
          }
        />
        <Column
          columnKey="createdAt"
          field="createdAt"
          header="Start Date"
          sortable
          body={e =>
            cqsLoading || !e
              ? bodyTemplate()
              : new Date(e?.createdAt).toLocaleDateString()
          }
        />
        <Column
          columnKey="idClient2.name"
          field="idClient2.name"
          header="Client/Notify"
          sortable
          body={e =>
            cqsLoading || !e
              ? bodyTemplate()
              : e?.idClient2 === null
              ? ''
              : `${e?.idClient2.name}`
          }
        />
      </DataTable>

      <MainButton
        label="View All Cqs"
        onClick={() => history.push('/client-quotations/list')}
      />
    </Container>
  );
};

export default ClientQuotationWidget;
