/* eslint-disable no-shadow */
export enum DomainGroup {
  TYPE_OF_PORT = 1,
  SHAPE_OF_OBJECT = 2,
  COURIER = 3,
  INCOTERM = 4,
  CLIENT_STATUS = 5,
  SPECIAL_NEGOTIATION = 6,
  TYPE_OF_BUSINESS = 7,
  NUMBER_OF_STORES = 8,
  OPERATION_TYPE = 9,
  TAX_BENEFITS = 10,
  PACKAGE_DESCRIPTION = 11,
  PANTONES = 12,
  PAYMENT_CONDITIONS = 13,
  PARTNER_CORE = 14,
  TYPE_OF_ATTACHMENTS = 15,
  CLIENT_SERVICES = 16,
  CONTACT_ROLES = 17,
  CHECKLIST_SECTION = 18,
  CATEGORY_TIPS = 19,
  SPECIAL_NEGOTIATION_TAX_TYPES = 20,
  UNITS_OF_PRODUCTS = 22,
  UNITS_OF_DIMENSION = 23,
  CURRENCY = 24,
  TYPE_OF_ASSESMENT = 25,
  RECEIVE_STATUS_SAT = 26,
  DESTINAÇÃO_ICMS = 27,
  BASE_DUMPING = 28,
  LOGISTICS_CALCULATION_BASIS = 29,
  LOGISTIC_OCEAN_FREIGHT_TAXES = 30,
  LOGISTIC_AIR_FREIGHT_TAXES = 31,
  PRC_TYPE = 32,
  PRC_PRIORITY = 33,
  PRC_STATUS = 34,
  PRC_TYPE_FILES = 35,
  LIST_CONFECTION_CATEGORY = 36,
  LIST_VALIDATION = 37,
  SOCKS = 38,
  SHOES_SLIPPERS_FEM = 39,
  SHOES_SLIPPERS_MALE = 40,
  SHOES_SLIPPERS_KIDS = 41,
  SHOES_SLIPPERS_BABY = 42,
  ADULT_FEM = 43,
  ADULT_MALE = 44,
  PLUS_SIZE_FEM = 45,
  PLUS_SIZE_MALE = 46,
  TEEN = 47,
  KIDS = 48,
  BABY_NB = 49,
  ONE_SIZE = 50,
  TYPE_OF_PACKAGE = 51,
  PACKAGE_RESPONSIBLE = 52,
  GENDER = 53,
  CQ_STATUS = 54,
  TYPE_OF_CTNR = 55,
  IMPORTANT_INFORMATION_QUESTION_TYPE = 56,
  IMPORTANT_INFORMATION_LAYOUT_TYPE = 57,
  LOGISTIC_EXPENSES = 58,
  TYPE_OF_ORDER = 59,
  TYPE_OF_UPDATE = 60,
  ARTWORK_TYPE_FILES = 61,
  DESIGN_LEVEL = 62,
  FOREIGN_TRADE_STATUS = 67,
  FOREIGN_TRADE_REWORK = 68,
  FOREIGN_TRADE_TYPE_OF_IMPORT_SERVICE = 69,
  FOREIGN_TRADE_IMPORT_LEVEL = 70,
  FOREIGN_TRADE_TYPE_FILES = 71,
  STATUS_SAT = 72,
  FINANCIAL_STATUS_PURCHASE_PAYMENT = 73,
  FINANCIAL_PURCHASE_OTHER_EXPENSES = 74,
  FINANCIAL_PURCHASE_PAYMENT_TYPES = 75,
  FINANCIAL_STATUS_SELLING_PAYMENT = 76,
  FINANCIAL_SELLER_OTHER_EXPENSES = 77,
  FINANCIAL_SELLER_CALCULATION_BASIS = 78,
  USERS_GROUP = 79,
  QUALITY_USER = 80,
  RNC_PRIORITY = 81,
  RNC_RESPONSIBLE_WAITING_FOR = 82,
  RNC_PROBLEM_TYPE = 83,
  RNC_STATUS = 84,
  RNC_ITEMS_TYPE = 85,
  RNC_ITEMS_DEBIT_NOTE_CATEGORY = 86,
  CAMPO_YES_NO = 87,
  RNC_COST = 88,
  RNC_COST_RESPONSIBLE = 89,
  RNC_CLIENT_DEBIT_NOTE_STATUS = 90,
  RNC_TYPE_CREDIT_DEBIT_NOTE = 91,
  RNC_CREDIT_NOTE_STATUS = 92,
  RNC_DEBIT_NOTE_STATUS = 93,
  RNC_TYPE_FILES = 94,
  FINANCIAL_PURCHASE_TYPE_FILES = 95,
  FINANCIAL_SELLER_PAYMENT_TYPES = 96,
  FINANCIAL_SELLER_TYPE_FILES = 97,
  PRODUCT_STATUS = 99,
  STATUS_ARTWORK = 101,
  EXPORT_STATUS = 102,
  CONFIRM_ORDER_FILES = 103,
  REGISTER_STATUS = 163,
  PRODUCTION_STATUS = 164,
  DOCUMENTS_STATUS = 165,
  SHIPMENT_STATUS = 167,
}

export enum DomainGroupFashion {
  Socks = 38,
  ShoesSlipperFem = 39,
  ShoesSlipperMale = 40,
  ShoesSlipperKids = 41,
  ShoesSlipperBaby = 42,
  AdultFem = 43,
  AdultMale = 44,
  PlusSizeFem = 45,
  PlusSizeMale = 46,
  Teen = 47,
  Kids = 48,
  Baby = 49,
  TamanhoUnico = 50,
  Newborn = 100,
}
