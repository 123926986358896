import React, { useEffect, useReducer } from 'react';
import { Dialog } from 'primereact/dialog';
import { useLazyQuery } from '@apollo/client';
import { Column, ColumnBodyOptions } from 'primereact/column';
import Button from '../Button';
import {
  updateLogArchivesReducer,
  updateLogArchivesReducerInitialState,
} from './reducers';
import {
  UpdateLogArchive,
  UpdateLogArchivesReducerActionKind,
} from './interfaces';
import { listUpdateLogArchivesQuery } from './queries';
import { useRefHook } from '../../hooks/useRefHook';
import Grid from '../Grid';
import { parseDateTimeColumm } from '../../utils/gridColumnsParse';
import isEmptyOrNull from '../../utils/isEmptyOrNull';
import LogCompare from './LogCompare';
import Tag from '../Tag';

// import { Container } from './styles';

interface IUpdateLogArchivesProps {
  className?: string;
  header: string;
  tableUpdated: string;
  idUpdatedRegister: number;
}

const UpdateLogArchives: React.FC<IUpdateLogArchivesProps> = ({
  className,
  header,
  tableUpdated,
  idUpdatedRegister,
}) => {
  const { showError } = useRefHook();

  const [state, stateDispatch] = useReducer(
    updateLogArchivesReducer,
    updateLogArchivesReducerInitialState,
  );

  const [loadLogsData, { loading: logsLoading }] = useLazyQuery(
    listUpdateLogArchivesQuery,
    {
      variables: {
        data: {
          pagination: {
            _page: state.lazyParams.page + 1,
            _limit: state.lazyParams.rows,
            _orderBy: state.lazyParams.sortField,
            _sortOrder: state.lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          tableUpdated,
          idUpdatedRegister,
        },
      },
      onCompleted: response => {
        stateDispatch({
          type: UpdateLogArchivesReducerActionKind.SET_CONTENT,
          payload: {
            content: response.listUpdateLogArchivesByTable,
          },
        });
      },
      onError: errorData => {
        showError({
          summary: `Error while getting ${header}`,
          detail: errorData.message,
        });
      },
    },
  );

  function renderGridDataValue(value: any) {
    if (isEmptyOrNull(value)) return 'empty';

    return value;
  }

  function removeIdsFromChangedObjects(data?: Record<string, unknown>) {
    if (!data) return undefined;

    return Object.entries(data).filter(
      ([key]) => key.endsWith('Description') || !key.startsWith('id'),
    );
  }

  const renderDataColumn = (
    rowData: UpdateLogArchive,
    column: ColumnBodyOptions,
  ) => {
    const { field } = column;

    const filteredData = removeIdsFromChangedObjects(
      rowData[field as keyof UpdateLogArchive] as Record<string, unknown>,
    );

    if (filteredData) {
      return (
        <div className="p-d-flex p-flex-column p-text-nowrap p-text-truncate">
          {filteredData.slice(0, 2).map(([key, value]) => {
            return (
              <span
                key={`${rowData.idUpdateLogArchive}-${field}-${key}`}
                className="p-text-nowrap p-text-truncate"
              >
                <b>{key}: </b>
                {renderGridDataValue(value)}
              </span>
            );
          })}
          {Object.entries(filteredData).length > 2 && <b>...</b>}
        </div>
      );
    }

    return undefined;
  };

  const renderOperationColumn = (rowData: UpdateLogArchive) => {
    switch (rowData.dataOperation) {
      case 'UPDATE':
        return <Tag severity="warn" value="UPDATE" />;
      case 'INSERT':
        return <Tag severity="success" value="CREATE" />;
      case 'DELETE':
        return <Tag severity="error" value="DELETE" />;
      default:
        return rowData.dataOperation;
    }
  };

  const rowExpansionTemplate = (rowData: UpdateLogArchive) => {
    const dataBefore = removeIdsFromChangedObjects(rowData.dataBefore);
    const dataAfter = removeIdsFromChangedObjects(rowData.dataAfter);

    return <LogCompare dataBefore={dataBefore} dataAfter={dataAfter} />;
  };

  useEffect(() => {
    if (state.dialogVisible) {
      loadLogsData();
    }
  }, [state.dialogVisible, loadLogsData]);

  return (
    <span className={`s-update-log-archives ${className ?? ''}`}>
      <Button
        label="History"
        icon="pi pi-history"
        onClick={() =>
          stateDispatch({
            type: UpdateLogArchivesReducerActionKind.OPEN_DIALOG,
          })
        }
      />

      <Dialog
        header={header}
        visible={state.dialogVisible}
        style={{ width: '80vw', maxWidth: '1230px' }}
        modal
        onHide={() =>
          stateDispatch({
            type: UpdateLogArchivesReducerActionKind.CLOSE_DIALOG,
          })
        }
        closable
        closeOnEscape
      >
        <Grid
          dataKey="idUpdateLogArchive"
          lazy
          totalRecords={state.content?.items ?? 0}
          value={state.content?.data}
          emptyMessage="No data found"
          rows={state.lazyParams.rows}
          first={state.lazyParams.first}
          onPage={e =>
            stateDispatch({
              type: UpdateLogArchivesReducerActionKind.CHANGE_PAGE,
              payload: {
                pageParams: e,
              },
            })
          }
          onSort={e =>
            stateDispatch({
              type: UpdateLogArchivesReducerActionKind.CHANGE_SORT,
              payload: {
                sortParams: e,
              },
            })
          }
          sortField={state.lazyParams.sortField}
          sortOrder={state.lazyParams.sortOrder}
          loading={logsLoading}
          scrollable
          scrollHeight="70vh"
          expandedRows={state.expandedRows}
          onRowToggle={e =>
            stateDispatch({
              type: UpdateLogArchivesReducerActionKind.SET_EXPANDED_ROWS,
              payload: {
                rowToggleParams: e,
              },
            })
          }
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: '4rem' }} />
          <Column
            key="dataOperation"
            field="dataOperation"
            header="Operation"
            sortable
            style={{ width: '8rem' }}
            body={renderOperationColumn}
          />
          <Column
            key="dataBefore"
            field="dataBefore"
            header="Before"
            body={renderDataColumn}
            style={{ width: '25rem' }}
          />
          <Column
            key="dataAfter"
            field="dataAfter"
            header="After"
            body={renderDataColumn}
            style={{ width: '25rem' }}
          />
          <Column
            key="updatedBy"
            field="updatedBy2.username"
            header="Updated By"
            sortable
            style={{ width: '11rem' }}
          />
          <Column
            sortable
            key="updatedAt"
            field="updatedAt"
            header="Updated At"
            body={parseDateTimeColumm}
            style={{ width: '10rem' }}
          />
        </Grid>
      </Dialog>
    </span>
  );
};

export default UpdateLogArchives;
