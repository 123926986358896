import styled from 'styled-components';

interface IFormTabContainerProps {
  selected: boolean;
}

export const Container = styled.div<IFormTabContainerProps>`
  display: ${p => (p.selected ? `visible` : `none`)};
  padding: 0 25px;
  width: 100%;
`;
