import React, { RefObject, useEffect, useState } from 'react';
import {
  TreeSelect,
  TreeSelectChangeParams,
  TreeSelectPanelHeaderTemplateOptions,
  TreeSelectSelectionKeys,
} from 'primereact/treeselect';

import TreeNode from 'primereact/treenode';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Container } from './styles';
import { findNodeOnTree } from '../../../utils/findParentsOnTree';
import { ColumnData } from '../interfaces';
import Button from '../../Button';

type MultiSelectGroup = {
  key: string | number;
  label: string;
  data: string[];
  children?: MultiSelectGroup[];
};

export type MultiSelectGroupData = {
  columns: ColumnData[];
  treeSelectedData: any;
};

interface IMultiSelectGroupProps {
  gridRef?: RefObject<DataTable>;
  className: string;
  /**
   * Todas as colunas presentes na grid
   */
  allColumns: ColumnData[];
  /**
   * Grupos de grupos de colunas
   */
  groups: MultiSelectGroup[];
  onSelectionChange(e: MultiSelectGroupData): void;
  showFields?: boolean;
  initialData?: TreeSelectSelectionKeys;
  resetOptionKey?: string;
  onNodeSelect?(e: TreeNode): void;
}

const MultiSelectGroup: React.FC<IMultiSelectGroupProps> = ({
  allColumns,
  groups,
  className,
  onSelectionChange,
  showFields,
  initialData,
  resetOptionKey,
  onNodeSelect,
  gridRef,
}) => {
  const [selectedData, setSelectedData] = useState<any>();

  useEffect(() => {
    setSelectedData(initialData);
  }, [initialData]);

  const getGroupsWithShowFields = () => {
    const showFieldsGroup: MultiSelectGroup = {
      key: 'showFields',
      label: 'Show Fields',
      data: [],
      children: allColumns.map(column => {
        return {
          key: `showFields-${column.field}`,
          label: column.header,
          data: [column.field],
        };
      }),
    };

    return [...groups, showFieldsGroup];
  };

  const columnsGroups = showFields ? getGroupsWithShowFields() : groups;

  const handleChangeSelection = (e: TreeSelectChangeParams) => {
    const response: MultiSelectGroupData = {
      treeSelectedData: e.value,
      columns: [],
    };

    if (e.value) {
      const treeValue = e.value as any;

      const checkedKeys = Object.keys(treeValue).filter(
        key => treeValue[key].checked === true,
      );

      if (checkedKeys.length) {
        const allowedColumnsFields: string[] = [];
        checkedKeys.forEach(checkedKey => {
          const node = findNodeOnTree(columnsGroups, checkedKey);
          if (node) {
            allowedColumnsFields.push(
              ...findNodeOnTree(columnsGroups, checkedKey).data,
            );
          }
        });

        const allowedColumns = allColumns.filter(column =>
          allowedColumnsFields.includes(column.field),
        );

        response.columns = allowedColumns;

        allowedColumns.forEach(column => {
          treeValue[`showFields-${column.field}`] = {
            checked: true,
          };
        });

        setSelectedData(treeValue);
      }
    }

    onSelectionChange(response);
  };

  /**
   * Seleciona todas as colunas para exibicao
   */
  function selectShowFields() {
    const response: MultiSelectGroupData = {
      treeSelectedData: { showFields: { checked: true } },
      columns: [],
    };

    allColumns.forEach(column => {
      response.treeSelectedData[`showFields-${column.field}`] = {
        checked: true,
      };
    });

    setSelectedData(response.treeSelectedData);

    onSelectionChange(response);
  }

  function handleNodeSelect(e: any) {
    const node = e.node as TreeNode;

    if (node.key === resetOptionKey) {
      selectShowFields();
    }

    if (onNodeSelect) {
      onNodeSelect(node);
    }
  }

  function resetColumnPositions() {
    // Define delay para resetar ordem das colunas apenas apos
    // colunas serem renderizadas novamente
    setTimeout(() => {
      gridRef?.current?.resetColumnOrder();
    }, 1);
  }

  const panelHeader = (props: TreeSelectPanelHeaderTemplateOptions) => {
    const { element } = props;

    if (!gridRef) return element;

    return (
      <>
        <Button
          className="p-mb-2 p-ml-auto"
          label="Reset Column Positions"
          severity="danger"
          onClick={() => {
            confirmDialog({
              header: 'Reset Column Positions',
              message:
                'This action will reset the order of the columns to their default positions. Are you sure you want to proceed?',
              icon: 'pi pi-question-circle',
              accept: () => resetColumnPositions(),
            });
          }}
        />
        {element}
      </>
    );
  };

  return (
    <Container className={className}>
      <TreeSelect
        value={selectedData}
        options={columnsGroups}
        selectionMode="checkbox"
        onChange={e => handleChangeSelection(e)}
        dropdownIcon="pi pi-cog"
        panelStyle={{ fontWeight: 'bold', padding: '1.14rem' }}
        filter
        filterInputAutoFocus={false}
        onNodeSelect={e => handleNodeSelect(e)}
        // Adicionado no header pois o footer nao funciona na v7
        // https://github.com/primefaces/primereact/issues/3088
        panelHeaderTemplate={panelHeader}
      />
    </Container>
  );
};

export default MultiSelectGroup;
