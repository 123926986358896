import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import {
  PackageReferenceFieldsPermissions,
  ProductPackageSizeBarcode,
} from '../../../../interfaces';
import { EnumTypeofPackage } from '../../../../../enums';
import PackageTypePack from './PackageTypePack';
import PackageTypeCalculated from './PackageTypeCalculated';
import FormInputNumber from '../../../../../../../../components/FormInputNumber';
import {
  IPackageSizeBarcodeRef,
  PackageSizeBarcodeFormData,
} from './interfaces';
import { objectsAreEqual } from '../../../../../../../../utils/objectsAreEqual';
import { ProductPackageUnitData } from './PackageTypeCalculated/interfaces';

interface IPackageSizeBarcodeProps {
  packageSizeBarcode: ProductPackageSizeBarcode;
  idTypePackage: number;
  ref: Ref<IPackageSizeBarcodeRef>;
  packageUnitData?: ProductPackageUnitData;
  fieldsPermissions: PackageReferenceFieldsPermissions;
}

const PackageSizeBarcode: React.FC<IPackageSizeBarcodeProps> = forwardRef(
  (
    { packageSizeBarcode, idTypePackage, packageUnitData, fieldsPermissions },
    ref,
  ) => {
    const formRef = useRef<FormHandles>(null);

    const [reference, setReference] = useState(packageSizeBarcode.reference);

    const isANewSizeBarcode =
      typeof packageSizeBarcode.idPackageSizeBarcode === 'string';

    function onSubmit(formData: PackageSizeBarcodeFormData) {
      const data = formData;
      // Atribui valor padrao de false para a propriedade AUTO quando esta
      // possuir o valor como undefined. Desta forma, a validacao de objectsAreEqual
      // retornara false quando usuario criar uma reference sem permissao
      // para ver os campos de reference
      data.auto = data.auto || false;

      if (objectsAreEqual(formData, packageSizeBarcode)) return undefined;

      return formData;
    }

    useImperativeHandle(ref, () => ({
      getDataIfChanged: () => {
        const formData =
          formRef.current?.getData() as PackageSizeBarcodeFormData;

        return onSubmit(formData);
      },

      setReferenceNumber: (index: number, stCode: string) => {
        setReference(`${stCode}-${index}`);
      },
    }));

    return (
      <Form
        className="p-formgrid p-grid w-full"
        ref={formRef}
        initialData={packageSizeBarcode}
        onSubmit={data => onSubmit(data)}
      >
        {!isANewSizeBarcode && (
          <FormInputNumber
            name="idPackageSizeBarcode"
            value={packageSizeBarcode.idPackageSizeBarcode}
            label=""
            hidden
          />
        )}

        {idTypePackage === EnumTypeofPackage.PACK && (
          <PackageTypePack
            packageSizeBarcode={packageSizeBarcode}
            reference={reference}
            isANewSizeBarcode={isANewSizeBarcode}
            packageUnit={packageUnitData?.packageUnit}
            fieldsPermissions={fieldsPermissions}
          />
        )}

        {idTypePackage === EnumTypeofPackage.CALCULADO && (
          <PackageTypeCalculated
            packageSizeBarcode={packageSizeBarcode}
            reference={reference}
            isANewSizeBarcode={isANewSizeBarcode}
            packageUnitData={packageUnitData}
            fieldsPermissions={fieldsPermissions}
          />
        )}
      </Form>
    );
  },
);

export default PackageSizeBarcode;
