import styled from 'styled-components';
import { shade } from 'polished';

interface StatusTagProps {
  domain: number;
  statusOnly: boolean;
}

const handleColorType = (tag: StatusTagProps) => {
  switch (tag.domain) {
    case 608:
    case 772:
      return '#C1272D';
    case 616:
    case 617:
      return '#6AB7A8';
    case 770:
    case 771:
    case 777:
      return '#662D91';
    case 766:
    case 767:
    case 775:
    case 776:
      return '#E51FB2';
    case 768:
    case 769:
      return '#FF9100';
    case 609:
    case 773:
      return '#689f38';
    case 610:
    case 774:
      return '#d32f2f';
    default:
      return '#4C85C8';
  }
};

export const Container = styled.div<StatusTagProps>`
  color: #ffffff;
  font-size: 11px;

  .name {
    background-color: ${props => shade(0.2, handleColorType(props))};
    padding: 4px 8px;
    height: 21px;
    border-radius: 12px 0 0 12px;
  }

  .status {
    background-color: ${props => handleColorType(props)};
    padding: 4px 8px;
    height: 21px;
    border-radius: ${props => (props.statusOnly ? '12px' : '0 12px 12px 0')};
    font-weight: 500;
  }
`;
