import React, { Ref, forwardRef, useImperativeHandle, useState } from 'react';

import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { FileType } from '../../../../../shared/enums/fileType';
import { createProductImagesQuery } from './queries';
import { FileUploadResponse } from '../../../../../components/FileUpload/interfaces';
import FileUpload from '../../../../../components/FileUpload';

export interface IAttachmentUploadRef {
  toggleModal(): void;
}

interface IAttachmentUploadProps {
  ref: Ref<IAttachmentUploadRef>;
  idProduct: number;
  attachmentsRefetch(): Promise<ApolloQueryResult<any>>;
}

const AttachmentUpload: React.FC<IAttachmentUploadProps> = forwardRef(
  ({ idProduct, attachmentsRefetch }, ref) => {
    const [createProductAttachmentsMutation, { loading }] = useMutation(
      createProductImagesQuery,
    );

    const { showSuccess, showError } = useRefHook();
    const [displayModal, setDisplayModal] = useState(false);

    useImperativeHandle(ref, () => ({
      toggleModal: () => {
        setDisplayModal(!displayModal);
      },
    }));

    async function handleUploadConfirm(data: FileUploadResponse[]) {
      try {
        const response = await createProductAttachmentsMutation({
          variables: {
            data: {
              idProduct,
              uploadedFiles: data.map(file => file.serverName),
            },
          },
        });

        showSuccess({
          summary: 'Attachments created',
          detail: `${response.data.createProductImages.length} attachments created`,
        });

        attachmentsRefetch();

        setDisplayModal(false);
      } catch (error) {
        showError({
          summary: 'Error while creating attachments',
          detail: error.message,
        });
      }
    }

    return (
      <Dialog
        header="Add an Attachment"
        visible={displayModal}
        style={{ width: '40vw' }}
        onHide={() => setDisplayModal(false)}
        closable={!loading}
      >
        <FileUpload
          multiple
          accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX},${FileType.TXT},${FileType.ZIP}`}
          onConfirm={e => handleUploadConfirm(e)}
          disabled={loading}
          uploadInProgress={loading}
        />
      </Dialog>
    );
  },
);

export default AttachmentUpload;
