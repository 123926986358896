/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { saveAs } from 'file-saver';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTable,
  DataTableMultiSortMetaType,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortParams,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import imagePlaceholder from '../../../assets/imagePlaceholder.svg';
import { Container } from './styles';

import { useRefHook } from '../../../hooks/useRefHook';

import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import { gridConstants } from '../../../components/Grid/constants';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import useTitle from '../../../hooks/useTitle';
import ILazyParams from '../../../services/lazyParams';
import ToastLife from '../../../shared/enums/toastLife';
import { clientRoles } from '../../../shared/roles/client';
import getFieldPermission from '../../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';
import { gridColumnsData } from './contants';
import { ColumnData } from '../../../components/Grid/interfaces';
import { filterOnlyAllowedColumnsFromLocalStorage } from '../../../components/Grid/utils';

interface ICountry {
  name: string;
}

interface ICity {
  name: string;
}

interface IType {
  description: string;
}

interface IClient {
  idClient: number;
  state: string;
  country: ICountry;
  city: ICity;
  type: IType;
}

interface IClientsLazyParams extends ILazyParams {
  [key: string]:
    | number
    | string
    | boolean
    | DataTableMultiSortMetaType
    | undefined;
  globalFilter?: string;
  cltNumber?: string;
  name?: string;
  companyName?: string;
  status?: string;
  active?: boolean;
  cnpj?: string;
  businessType?: string;
  numberOfStores?: string;
  address?: string;
  idCity?: string;
  zipCode?: string;
  paymentAdvance?: string;
  paymentBalance?: string;
  paymentDeadline?: string;
  salesMultiplier?: string;
  costsMultiplier?: string;
  incoterm?: string;
}

const pageTitle = 'List of Clients';

const ClientsList: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:clientsGridColumns';

  const lazyParamsName = '@SAT:clientsLazyParams';

  const advancedFiltersName = '@SAT:prcListAdvancedFilters';

  // URL params
  const location = useLocation();

  // Permissions de clients
  const { idPermissionExportClientList, idPermissionDeleteClient } =
    clientRoles.permissions;

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  // Referencia a grid
  const gridRef = useRef<DataTable>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Redirect
  const history = useHistory();

  // ID da entity de usuario
  const { idEntity } = clientRoles;

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  const {
    idFieldAddress,
    idFieldBusinessType,
    idFieldCnpj,
    idFieldCompanyName,
    idFieldCostsMultiplier,
    idFieldIdCity,
    idFieldIncoterm,
    idFieldNumberOfStores,
    idFieldPaymentAdvance,
    idFieldPaymentBalance,
    idFieldPaymentDeadline,
    idFieldSalesMultiplier,
    idFieldStatus,
    idFieldZipCode,
  } = clientRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, userPermissions.userFields).view;
  }

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList: ColumnData[] = [];

    columnList.push(gridColumnsData.image);
    columnList.push(gridColumnsData.cltNumber);
    columnList.push(gridColumnsData.name);
    if (showField(idFieldCompanyName)) {
      columnList.push(gridColumnsData.companyName);
    }
    if (showField(idFieldStatus)) {
      columnList.push(gridColumnsData.status);
    }
    if (showField(idFieldCnpj)) {
      columnList.push(gridColumnsData.cnpj);
    }
    if (showField(idFieldBusinessType)) {
      columnList.push(gridColumnsData.businessType);
    }
    if (showField(idFieldNumberOfStores)) {
      columnList.push(gridColumnsData.numberOfStores);
    }
    if (showField(idFieldAddress)) {
      columnList.push(gridColumnsData.address);
    }
    if (showField(idFieldIdCity)) {
      columnList.push(gridColumnsData.idCity);
    }
    if (showField(idFieldZipCode)) {
      columnList.push(gridColumnsData.zipCode);
    }
    if (showField(idFieldPaymentAdvance)) {
      columnList.push(gridColumnsData.paymentAdvance);
    }
    if (showField(idFieldPaymentBalance)) {
      columnList.push(gridColumnsData.paymentBalance);
    }
    if (showField(idFieldPaymentDeadline)) {
      columnList.push(gridColumnsData.paymentDeadline);
    }
    if (showField(idFieldSalesMultiplier)) {
      columnList.push(gridColumnsData.salesMultiplier);
    }
    if (showField(idFieldCostsMultiplier)) {
      columnList.push(gridColumnsData.costsMultiplier);
    }
    if (showField(idFieldIncoterm)) {
      columnList.push(gridColumnsData.incoterm);
    }

    return columnList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Armazena os parceiros selecionados
  const [selectedClients, setSelectedClients] = useState([]);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Estado de scrollHeight da grid
  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
    cltNumber: undefined,
    name: undefined,
    companyName: undefined,
    status: undefined,
    active: true,
    cnpj: undefined,
    businessType: undefined,
    numberOfStores: undefined,
    address: undefined,
    idCity: undefined,
    zipCode: undefined,
    paymentAdvance: undefined,
    paymentBalance: undefined,
    paymentDeadline: undefined,
    salesMultiplier: undefined,
    costsMultiplier: undefined,
    incoterm: undefined,
  };

  const [lazyParams, setLazyParams] = useState<IClientsLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: IClientsLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: IClientsLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  // Query para deletar parceiro
  const queryToDeleteClients = gql`
    mutation DeleteClientsMutation($clientsIds: [Int]!) {
      deleteClients(clientsIds: $clientsIds)
    }
  `;
  // cria método para chamar a mutation
  const [deleteClients] = useMutation(queryToDeleteClients);

  // Query clients
  const queryToListClients = gql`
    query listAllClientsQuery($listAllClientsInput: ListAllClientsInput!) {
      listAllClients(listAllClientsInput: $listAllClientsInput) {
        data {
          idClient
          cltNumber
          status
          businessType
          operationType
          numberOfStores
          payment
          courier
          incoterm
          name
          companyName
          cnpj
          idCountry
          idRegion
          idCity
          address
          zipCode
          paymentAdvance
          paymentBalance
          paymentDeadline
          website
          salesMultiplier
          costsMultiplier
          createdBy
          createdAt
          updatedBy
          updatedAt
          cityText
          active
          idLogo
          clientService
          imageUrl
          #createdBy2: UserResponse
          #updatedBy2: UserResponse
          incoterm2 {
            idDomain
            description
          }
          businessType2 {
            idDomain
            description
          }
          #courier2: DomainResponse
          numberOfStores2 {
            idDomain
            description
          }
          #operationType2: DomainResponse
          #payment2: DomainResponse
          #status2: DomainResponse
          #clientService2: DomainResponse
          status2 {
            idDomain
            description
          }
          idRegion2 {
            idRegion
            name
          }
          idCountry2 {
            idCountry
            name
          }
          idCity2 {
            idCity
            name
          }
        }
        items
      }
    }
  `;

  // Query clients
  const {
    loading: clientsLoading,
    data: clientsData,
    error: clientsError,
    refetch: clientsRefetch,
  } = useQuery(queryToListClients, {
    variables: {
      listAllClientsInput: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        active: lazyParams.active,
        globalSearch: lazyParams.globalFilter,
        name: lazyParams.name,
        companyName: lazyParams.companyName,
        cltNumber: lazyParams.cltNumber,
        status: lazyParams.status,
        cnpj: lazyParams.cnpj,
        businessType: lazyParams.businessType,
        numberOfStores: lazyParams.numberOfStores,
        address: lazyParams.address,
        city: lazyParams.idCity,
        zipCode: lazyParams.zipCode,
        paymentAdvance: lazyParams.paymentAdvance,
        paymentBalance: lazyParams.paymentBalance,
        paymentDeadline: lazyParams.paymentDeadline,
        salesMultiplier: lazyParams.salesMultiplier,
        costsMultiplier: lazyParams.costsMultiplier,
        incoterm: lazyParams.incoterm,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting clients',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Delete selected clients
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedClients.length} clients?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada client
        const idsToDelete = selectedClients.map((a: IClient) => a.idClient);
        try {
          // Delete clients
          await deleteClients({
            variables: {
              clientsIds: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedClients.length} clients`,
            life: ToastLife.SUCCESS,
          });

          // Zera estado de clients selecionadas
          setSelectedClients([]);

          // Atualiza grid de clients
          await clientsRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting clients',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;

        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Define os produtos no estado
   */
  useEffect(() => {
    // Busca colunas selecionadas salvas no local storage
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      const allowedColumns = filterOnlyAllowedColumnsFromLocalStorage(
        JSON.parse(localStorageSelectedColumns),
        columns,
      );
      setSelectedColumns(allowedColumns);
    }
  }, [columns]);

  /**
   * Direciona usuario para pagina do client clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(`/clients/list/${e.data.idClient}`);
  }

  // Ao mudar pagina da tabela, muda os parametros de busca no backend
  // @param event Parametros de paginacao da tabela
  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  // Ao fazer sort de alguma coluna, muda os parametros de busca no backend
  // @param event Parametros de sort da tabela
  function onSort(event: DataTableSortParams) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Valida se coluna active é true ou false
   * @param rowData Dados da linha
   * @returns Campo com true ou false
   */
  const parseBooleanColumn = (rowData: any, field: any) => {
    if (field.columnKey === 'active') {
      return <p>{rowData.active ? 'Yes' : 'No'}</p>;
    }
    return undefined;
  };

  const parseImageColumn = (rowData: any) => {
    return (
      <img
        src={`${rowData.imageUrl}`}
        onError={e => (e.currentTarget.src = imagePlaceholder)}
        alt={rowData.image}
        style={{ width: '90px' }}
      />
    );
  };

  /**
   * Retorna coluna com link para cadastro de Client
   * @param rowData Dados da Linha
   * @returns Coluna com link para cadastro de Client
   */
  const parseCltColumn = (rowData: any) => {
    return (
      <Link
        to={`/clients/list/${rowData.idClient}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData.cltNumber}
        <FiExternalLink size={15} />
      </Link>
    );
  };

  /* const parseCnpjColumn = (rowData: any) => {
    return <p>{rowData.cnpj ? parseCnpj(rowData.cnpj) : ''}</p>;
  }; */

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'active':
        return parseBooleanColumn;
      case 'imageUrl':
        return parseImageColumn;
      case 'cltNumber':
        return parseCltColumn;
      /* case 'cnpj':
        return parseCnpjColumn; */
      default:
        return undefined;
    }
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'name':
      case 'companyName':
        return { width: '300px' };
      case 'status2.description':
      case 'active':
        return { width: '100px' };
      case 'cnpj':
        return { width: '160px' };
      case 'businessType2.description':
      case 'idCity2.name':
      case 'numberOfStores2.description':
        return { width: '200px' };
      case 'address':
        return { width: '350px' };
      case 'zipCode':
      case 'incoterm2.description':
      case 'idClient':
        return { width: '150px' };
      case 'paymentAdvance':
      case 'paymentBalance':
      case 'paymentDeadline':
      case 'salesMultiplier':
      case 'costsMultiplier':
        return { width: '250px' };
      case 'cltNumber':
        return { width: '140px' };
      default:
        return {
          width: '120px',
        };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map((col: any) => {
    return (
      <Column
        key={col.field}
        // Valida o body da coluna (caso precise ser um link)
        // body={handleColumn(col.field)}
        // body={col.field}
        body={handleColumn(col.field)}
        columnKey={col.field}
        field={col.field}
        // Valida necessidade de icone de filtro no header
        header={handleColumnHeader(col.header)}
        style={handleColumnSize(col.field)}
        sortable={col.field !== 'imageUrl'}
      />
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeParams) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );
    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  /**
   * Salva arquivo como xlsx
   * @param buffer
   * @param fileName Nome do arquivo
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`,
    );
  }

  /**
   * Exporta dados para xlsx
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function exportToXlsx(clientsExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    clientsExportData.listAllClients.data.forEach((client: any) => {
      columnsToRemove.forEach(column => {
        delete client[column.field as keyof IClient];
      });

      client.status = client.status2 ? client.status2.description : undefined;
      client.numberOfStores = client.numberOfStores2
        ? client.numberOfStores2.description
        : undefined;

      // Remover colunas __typename e idClient
      delete client['__typename' as keyof IClient];
      delete client['idLogo' as keyof IClient];
      delete client['payment' as keyof IClient];
      // delete client['status' as keyof IClient];
      delete client['businessType' as keyof IClient];
      delete client['operationType' as keyof IClient];
      // delete client['numberOfStores' as keyof IClient];
      delete client['courier' as keyof IClient];
      delete client['incoterm' as keyof IClient];
      delete client['idCountry' as keyof IClient];
      delete client['idRegion' as keyof IClient];
      delete client['idCity' as keyof IClient];
      delete client['website' as keyof IClient];
      delete client['createdBy' as keyof IClient];
      delete client['createdAt' as keyof IClient];
      delete client['updatedBy' as keyof IClient];
      delete client['updatedAt' as keyof IClient];
      delete client['cityText' as keyof IClient];
      delete client['active' as keyof IClient];
      delete client['clientService' as keyof IClient];
      delete client['imageUrl' as keyof IClient];
      delete client['incoterm2' as keyof IClient];
      delete client['businessType2' as keyof IClient];
      delete client['idDomain' as keyof IClient];
      delete client['description' as keyof IClient];
      delete client['__typename' as keyof IClient];
      delete client['numberOfStores2' as keyof IClient];
      delete client['status2' as keyof IClient];
      delete client['idDomain' as keyof IClient];
      delete client['description' as keyof IClient];
      delete client['__typename' as keyof IClient];
      delete client['idRegion2' as keyof IClient];
      delete client['idCountry2' as keyof IClient];
      delete client['idCity2' as keyof IClient];
    });

    const columnsOrder = gridRef.current?.state.columnOrder?.filter(
      (item: any) =>
        item !== null &&
        item !== 'imageUrl' &&
        item !== 'businessType2.description' &&
        item !== 'idCity2.name' &&
        item !== 'incoterm2.description' &&
        item !== columnsToRemove.find(a => a.field === item)?.field,
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'status2.description':
          columnsOrder[index] = 'status';
          break;
        case 'numberOfStores2.description':
          columnsOrder[index] = 'numberOfStores';
          break;
        default:
          break;
      }
    });

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(
      clientsExportData.listAllClients.data,
      { header: columnsOrder },
    );
    worksheet['!autofilter'] = { ref: 'A1:H1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveAsExcelFile(excelBuffer, 'clients');
  }

  /**
   * Busca usuarios para exportar para XLSX
   */
  const [
    loadClientsToExport,
    { loading: clientToExportLoading, data: clientsToExportData },
  ] = useLazyQuery(queryToListClients, {
    onCompleted: () => {
      exportToXlsx(clientsToExportData);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while exporting clients',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <Container>
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar parceiro */}
        <MainButton
          className="mainButton"
          label="New Client"
          onClick={() => history.push('/clients/list/create')}
        />

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />

        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Botao para excluir parceiros selecionados */}
        {userHasPermission(
          idPermissionDeleteClient,
          userPermissions.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedClients.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {/* Botao export para XLSX */}
        {userHasPermission(
          idPermissionExportClientList,
          userPermissions.userPermissions,
        ) && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={clientToExportLoading}
            onClick={() => {
              loadClientsToExport({
                variables: {
                  listAllClientsInput: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    globalSearch: lazyParams.globalFilter,
                    name: lazyParams.name,
                    companyName: lazyParams.companyName,
                    active: lazyParams.active,
                    idClient: lazyParams.idClient,
                    status: lazyParams.status,
                    cnpj: lazyParams.cnpj,
                    businessType: lazyParams.businessType,
                    numberOfStores: lazyParams.numberOfStores,
                    address: lazyParams.address,
                    city: lazyParams.idCity,
                    zipCode: lazyParams.zipCode,
                    paymentAdvance: lazyParams.paymentAdvance,
                    paymentBalance: lazyParams.paymentBalance,
                    paymentDeadline: lazyParams.paymentDeadline,
                    salesMultiplier: lazyParams.salesMultiplier,
                    costsMultiplier: lazyParams.costsMultiplier,
                    incoterm: lazyParams.incoterm,
                  },
                },
              });
            }}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a client"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        className="grid"
        name="clients"
        lazy
        totalRecords={
          !clientsData || clientsError ? 0 : clientsData.listAllClients.items
        }
        value={
          !clientsData || clientsError
            ? undefined
            : clientsData.listAllClients.data
        }
        globalFilter={globalFilter}
        emptyMessage="No clients found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={!clientsData || clientsError ? undefined : lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedClients}
        onSelectionChange={e => setSelectedClients(e.value)}
      >
        {userHasPermission(
          idPermissionDeleteClient,
          userPermissions.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}
        {dynamicColumns}
      </Grid>
      {(pageLoading || clientsLoading) && <Loading />}
    </Container>
  );
};

export default ClientsList;
