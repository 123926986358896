import styled from 'styled-components';

export const Container = styled.span`
  width: fit-content;

  i {
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: var(--blue-light);
    }
  }
`;
