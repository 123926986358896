export const satsRoles = {
  idEntity: 23,
  idModule: 4,
  fields: {
    idFieldAdvancePaymentPercentage: 55,
    idFieldAdvanceReceivedPercentage: 56,
    idFieldBalancePaymentPercentage: 62,
    idFieldBalanceReceivedPercentage: 63,
    idFieldCbmTotalSum: 66,
    idFieldClientAddress: 68,
    idFieldClientBalance: 70,
    idFieldClientCNPJ: 71,
    idFieldCompanyName: 78,
    idFieldCtrSum: 81,
    idFieldDesignLevel: 87,
    idFieldEstAdvancePayment: 93,
    idFieldEstAdvancePaymentAmount: 94,
    idFieldEstAdvanceReceived: 95,
    idFieldEstAdvanceReceivedAmount: 96,
    idFieldEstBalancePayment: 97,
    idFieldEstBalancePaymentAmount: 98,
    idFieldEstBalanceReceived: 99,
    idFieldEstBalanceReceivedAmount: 100,
    idFieldEstimatedArrivalDate: 101,
    idFieldEstimatedInspectionDate: 103,
    idFieldEstimatedShipmentDate: 104,
    idFieldEstimateMarginTotal: 105,
    idFieldEstimateProfitTotal: 106,
    idFieldExpireDate: 109,
    idFieldFx: 119,
    idFieldGrossMarginTotal: 121,
    idFieldGrossProfitTotal: 122,
    idFieldIdCategory: 124,
    idFieldIdClient: 125,
    idFieldIdClientIncoterm: 126,
    idFieldIdComexExportUser: 127,
    idFieldIdComexImportUser: 128,
    idFieldIdCountryAcquisition: 129,
    idFieldIdFirstBuyer: 132,
    idFieldIdImporter: 133,
    idFieldIdLoadingPort: 134,
    idFieldIdPaymentTermCondition: 135,
    idFieldIdPort: 136,
    idFieldIdFinancialUser: 1436,
    idFieldIdPurchaseUser: 137,
    idFieldIdSalesUser: 138,
    idFieldIdSecondBuyer: 141,
    idFieldImporterAddress: 142,
    idFieldImporterCNPJ: 143,
    idFieldLoadingAmountCotTotal: 152,
    idFieldLoadingAmountTotal: 153,
    idFieldManufacture: 154,
    idFieldNetMarginTotal: 157,
    idFieldNetProfitTotal: 158,
    idFieldPackagesNotes: 160,
    idFieldPaymentTermAdvance: 161,
    idFieldPaymentTermBalance: 162,
    idFieldPurchaseCI: 166,
    idFieldPurchasePI: 169,
    idFieldReceiveStatus: 174,
    idFieldRemarks: 175,
    idFieldSatNumber: 179,
    idFieldSellerCI: 181,
    idFieldSellerPI: 182,
    idFieldService: 183,
    idFieldSupplierBalance: 187,
    idFieldTotalPaid: 193,
    idFieldTotalReceived: 194,
    idFieldTotalToPay: 195,
    idFieldTotalToReceive: 196,
    idFieldTypeOfOrder: 198,
    idFieldTypeOfOrderNumber: 199,
    idImporter: 822,
    idFieldMainProduct: 1100,
    idFieldProductLine: 1101,
    idFieldIdTypeOperation: 1102,
    idFieldIdSatOrigin: 1103,
    idFieldIndSamples: 1104,
    idFieldSamplesLeadTime: 1105,
    idFieldQtyCtnr: 1106,
    idFieldIdTypeCtnr: 1107,
    idFieldClientDepartment: 1108,
    idFieldImporterName: 1109,
    idFieldIdPurchaseIncoterm: 1110,
    idFieldIdSupplierExporter: 1111,
    idFieldExporterAddress: 1112,
    idFieldProductionTime: 1113,
    idFieldSatSuppliers: 1114,
    idFieldIdCountryOrigin: 1115,
    idFieldIdCountryProcedence: 1116,
    idFieldEstimatedArrivalClientDate: 1117,
    idFieldSatGroup: 1118,
    idFieldIdSalesSecondUser: 1119,
    idFieldConfirmOrder: 1461,
    idFieldStatusSat: 1462,
    idFieldSatForeignTradeNumber: 1518,
    idFieldSuppliers: 1519,
    idFieldRealEtd: 1520,
    idFieldRealEta: 1521,
    idFieldStatusImport: 1522,
    idFieldCountryOrigin: 1523,
    idFieldPurchaseIncoterm: 1524,
    idFieldSellingIncoterm: 1525,
    idFieldResultTotalConvertedCotPi: 1526,
    idFieldResultTotalConvertedCotCi: 1527,
    idFieldResultTotalConvertedSunPi: 1528,
    idFieldResultTotalConvertedSunCi: 1529,
    idFieldCategory: 1530,
    idFieldClientDepartmentSat: 1531,
    idFieldFirstBuyer: 1532,
    idFieldSecondBuyer: 1533,
    idFieldFirstSalesUser: 1534,
    idFieldSecondSalesUser: 1535,
    idFieldPurchaseUser: 1536,
    idFieldExportUser: 1537,
    idFieldImportUser: 1538,
    idFieldDesignUser: 1539,
    idFieldFinancialUser: 1540,
    idFieldExporter: 1608,
    idFieldImporterConsignee: 1610,
    idFieldClientNotify: 1609,
    idFieldStatusArtwork: 2137,
    idFieldShipmentStatus: 2138,
    idFieldStatusExport: 2139,
    idFieldIdRegisterUser: 2165,
    idFieldStock: 2166,
    idFieldRegisterStatus: 2170,
    idFieldProductionStatus: 2171,
    idFieldShipmentResume: 2172,
    idFieldDocumentsStatus: 2173,
    idFieldSupplierPaymentStatus: 2174,
    idFieldClientPaymentStatus: 2175,
  },
  permissions: {
    idPermissionDeleteSat: 10,
    idPermissionExportSellerPi: 11,
    idPermissionExportSellerPiSimplified: 154,
    idPermissionExportCotPI: 12,
    idPermissionExportDdgip: 13,
    idPermissionExportOd: 14,
    idPermissionExportShippingMark: 15,
    idPermissionExportRoundSticker: 16,
    idPermissionExportSticker: 17,
    idPermissionExportInnerSticker: 18,
    idPermissionExportCiPlusPl: 19,
    idPermissionDuplicateSat: 20,
    idPermissionExportListOfSATs: 54,
    idPermissionRefreshSatItems: 112,
    idPermissionFollowUp: 113,
    idPermissionUpdates: 114,
    idPermissionShowUpdatesRestrictInfo: 115,
    idPermissionManageUpdate: 116,
    idPermissionManageFollowUp: 117,
    idPermissionAddSatItem: 119,
    idPermissionEditSatItems: 120,
    idPermissionCompareFiles: 121,
    idPermissionSatQuotation: 122,
    idPermissionUploadArtwork: 123,
    idPermissionDeleteArtwork: 124,
    idPermissionOpenRnc: 127,
    idPermissionDownloadAreaArtworkHrp: 153,
    idPermissionDownloadAreaArtworkMainDiecut: 155,
    idPermissionDownloadAreaArtworkCareLabelFoldedHorizontal: 170,
    idPermissionDownloadAreaArtworkCareLabelSewedHorizontal: 173,
    idPermissionDownloadAreaArtworkCareLabelSewedVertical: 175,
    idPermissionExportShippingInstruction: 171,
    idPermissionTotalFinancial: 174,
    idPermissionExportContract: 176,
    idPermissionDownloadAreaArtworkCareLabelFoldedVertical: 172,
    idPermissionHistory: 194,
    idPermissionConfirmOrderFileSunPi: 195,
    idPermissionConfirmOrderFileCotPi: 196,
    idPermissionFinishSat: 197,
  },
};

export const satForeignTradeRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldConfirmOrderComercial: 1265,
    idFieldEstimatedInspectionDate: 1266,
    idFieldEstimatedShipmentDate: 1267,
    idFieldEstimatedArrivalDate: 1268,
    idFieldEstimatedArrivalClientDate: 1269,
    idFieldIdImportStatus: 1270,
    idFieldRealInspectionDate: 1271,
    idFieldRealEtd: 1272,
    idFieldRealEta: 1273,
    idFieldRealArrivalAtClient: 1274,
    idFieldInspectionScheduled: 1275,
    idFieldInspectionApproval: 1276,
    idFieldIdInspectionApprovedBy: 1277,
    idFieldRework: 1278,
    idFieldReworkDate: 1279,
    idFieldReworkCost: 1280,
    idFieldIdReworkApprovedBy: 1281,
    idFieldAuthorizationRequested: 1282,
    idFieldShipmentAuthorized: 1283,
    idFieldBookingConfirmed: 1284,
    idFieldShipmentConfirmed: 1285,
    idFieldFreightForwarderOrigin: 1286,
    idFieldFreightForwarderDestination: 1287,
    idFieldFreightCost: 1288,
    idFieldFreightExtraCost: 1289,
    idFieldImportLicenseNumber: 1290,
    idFieldImportLicenseExpireDate: 1291,
    idFieldDiNumber: 1292,
    idFieldIdTypeOfImportService: 1301,
    idFieldImportNotes: 1302,
    idFieldIdImportLevel: 1303,
    idFieldExportNotes: 1304,
    idFieldTrakingNumber: 1321,
    idFieldIdCourier: 1322,
    idFieldReceived: 1323,
    idFieldDeliveredToClient: 1324,
    idFieldStartProductionTiming: 1325,
    idFieldRealProductionTime: 1326,
    idFieldAuthorizationBookingTiming: 1327,
    idFieldAuthorizationShippingTiming: 1328,
    idFieldBookingShippingTiming: 1329,
    idFieldPreShipmentTiming: 1330,
    idFieldTransitTime: 1331,
    idFieldHblFinalTiming: 1332,
    idFieldOriginalDocsTiming: 1333,
    idFieldDeliveryAtClientTiming: 1334,
    idFieldTotalForeignTradeTiming: 1335,
    idFieldIdExportStatus: 2136,
    idFieldIdLoadingPort: 2152,
    idFieldIdPort: 2153,
    idFieldClientOrder: 2154,
    idFieldIdPurchaseUser: 2155,
    idFieldIdSalesUser: 2156,
    idFieldIdSalesSecondUser: 2157,
    idFieldIdFinancialUser: 2158,
    idFieldIdComexExportUser: 2159,
    idFieldIdComexImportUser: 2160,
    idFieldIdDesignerUser: 2161,
    idFieldArrivalConfirmed: 2167,
    idFieldStartProduction: 2168,
    idFieldShipmentDetails: 2169,
    idFieldShipmentRequestNotRequired: 2176,
    idFieldInspector: 2223,
  },
  permissions: {
    idPermissionShipmentContainers: 125,
    idPermissionCreateShipment: 129,
    idPermissionUploadFile: 130,
    idPermissionDeleteFile: 131,
    idPermissionAdvancePaymentRequest: 198,
    idPermissionBalancePaymentRequest: 199,
    idPermissionShipmentRequest: 200,
  },
};

export const satForeignTradeContainerItemsRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldStCode: 1293,
    idFieldQuantity: 1294,
    idFieldQuantityCi: 1295,
    idFieldCotPrice: 1296,
    idFieldSunPrice: 1297,
    idFieldTotalCotCi: 1298,
    idFieldTotalSunCi: 1299,
    idFieldQuantityCtnr: 1300,
    idFieldPiecesContainer: 1883,
    idFieldRemainQuantity: 1884,
    idFieldGrossWeight: 2162,
    idFieldNetWeight: 2163,
    idFieldCbm: 2164,
    idFieldQuantityPiShipment: 2217,
    idFieldSizeRange: 2218,
  },
  permissions: {
    idPermissionDeleteContainerItem: 126,
    idPermissionAddContainerItem: 128,
  },
};

export const satRncRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {},
  permissions: {
    idPermissionExportRncForm: 138,
  },
};

export const satRncItemsRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldStCode: 1306,
    idFieldName: 1307,
    idFieldQtyComplained: 1308,
    idFieldQtyBuyed: 1309,
    idFieldPercentage: 1310,
    idFieldPcsCtn: 1311,
    idFieldQtyCtn: 1312,
    idFieldFobUnit: 1313,
    idFieldFobTotal: 1314,
    idFieldNationalizedUnit: 1315,
    idFieldNationalizedTotal: 1316,
    idFieldClientAware: 1317,
    idFieldType: 1318,
    idFieldDebitCategory: 1319,
    idFieldNotes: 1320,
  },
  permissions: {
    idPermissionAddItem: 132,
    idPermissionRemoveItem: 133,
  },
};

export const satRncCostsRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  permissions: {
    idPermissionAddItem: 134,
    idPermissionEditItem: 135,
    idPermissionRemoveItem: 136,
  },
};

export const satFinancialRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldIdStatusPurchasePayment: 1337,
    idFieldSunNumber: 1338,
    idFieldSupplierName: 1339,
    idFieldPurchaseIncotermDescription: 1340,
    idFieldMainProduct: 1341,
    idFieldCountryOriginName: 1342,
    idFieldIdStatusSellingPayment: 1343,
    idFieldCompanyName: 1344,
    idFieldImporterName: 1345,
    idFieldExchangeRate: 1347,
    idFieldIdClientIncoterm: 1348,
    idFieldIdImportStatus: 1349,
    idFieldRealInspectionDate: 1350,
    idFieldRealEtd: 1351,
    idFieldRealEta: 1352,
    idFieldPurchasePiCurrency: 1353,
    idFieldTotalCotPiShipment: 1354,
    idFieldTotalConvertedCotPi: 1355,
    idFieldPurchaseCiCurrency: 1356,
    idFieldTotalCotCiShipment: 1357,
    idFieldTotalConvertedCotCi: 1358,
    idFieldEstimatedPaymentAdvance: 1366,
    idFieldEstimatedPaymentTotalAdvance: 1367,
    idFieldEstimatedPaymentAdvanceRequest: 1368,
    idFieldEstimatedPaymentBalance: 1369,
    idFieldEstimatedPaymentTotalBalance: 1370,
    idFieldEstimatedPaymentBalanceRequest: 1371,
    idFieldEstimatedPaymentTermCondition: 1372,
    idFieldEstimatedReceiveAdvance: 1381,
    idFieldEstimatedReceiveTotalAdvance: 1382,
    idFieldEstimatedReceiveAdvanceRequest: 1383,
    idFieldEstimatedReceiveBalance: 1384,
    idFieldEstimatedReceiveTotalBalance: 1385,
    idFieldEstimatedReceiveBalanceRequest: 1386,
    idFieldEstimatedReceiveTermCondition: 1387,
    idFieldEstimatedAdvance: 1366,
    idFieldEstimatedTotalAdvance: 1367,
    idFieldEstimatedAdvanceRequest: 1368,
    idFieldEstimatedBalance: 1369,
    idFieldEstimatedTotalBalance: 1370,
    idFieldEstimatedBalanceRequest: 1371,
    idFieldEstimatedTermCondition: 1372,
    idFieldSellerPiCurrency: 1359,
    idFieldTotalSunPiShipment: 1360,
    idFieldTotalConvertedSunPi: 1361,
    idFieldSellerCiCurrency: 1362,
    idFieldTotalSunCiShipment: 1363,
    idFieldTotalConvertedSunCi: 1364,
    idFieldTotalWithApportionment: 1365,
  },
};

export const satRncOtherExpensesRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  permissions: {
    idPermissionRncCreditNoteDownload: 137,
    idPermissionRncDebitNoteDownload: 139,
  },
};

export const satFinancialPurchaseOtherExpensesRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldTypeDescription: 1373,
    idFieldCurrencyAbbreviation: 1374,
    idFieldTotal: 1375,
    idFieldDateOtherExpense: 1376,
    idFieldLinkToMargin: 1377,
    idFieldDiscountFromSupplier: 1378,
    idFieldPayToSupplier: 1379,
    idFieldComment: 1380,
    idFieldSunNumber: 1468,
    idFieldSatForeignTradeNumber: 1469,
    idFieldSatForeignTradeDestinationNumber: 1470,
  },
  permissions: {
    idPermissionAddItem: 150,
    idPermissionEditItem: 151,
    idPermissionRemoveItem: 152,
  },
};

export const satFinancialSellerOtherExpensesRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldTypeDescription: 1401,
    idFieldCurrencyAbbreviation: 1402,
    idFieldTotal: 1403,
    idFieldDateOtherExpense: 1404,
    idFieldLinkToMargin: 1405,
    idFieldCreditToClient: 1406,
    idFieldApportionment: 1407,
    idFieldCalculationBasis: 1408,
    idFieldComment: 1409,
    idFieldSunNumber: 1471,
    idFieldSatForeignTradeNumber: 1472,
    idFieldSatForeignTradeDestinationNumber: 1473,
  },
  permissions: {
    idPermissionAddItem: 160,
    idPermissionEditItem: 161,
    idPermissionRemoveItem: 165,
  },
};

export const satFinancialAdvancePaidRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldPercentage: 1388,
    idFieldAmount: 1389,
    idFieldPaymentDate: 1390,
    idFieldRollingFrom: 1391,
    idFieldRollingTo: 1392,
    idFieldShipment: 1464,
  },
  permissions: {
    idPermissionAddItem: 141,
    idPermissionAddRolling: 142,
    idPermissionUpdateItem: 143,
    idPermissionRemoveItem: 144,
    idPermissionRequestPayment: 149,
  },
};

export const satFinancialAdvanceReceivedRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldPercentage: 1393,
    idFieldAmount: 1394,
    idFieldPaymentDate: 1395,
    idFieldRollingFrom: 1396,
    idFieldRollingTo: 1397,
    idFieldShipment: 1465,
  },
  permissions: {
    idPermissionAddItem: 145,
    idPermissionAddRolling: 146,
    idPermissionUpdateItem: 147,
    idPermissionRemoveItem: 148,
  },
};

export const satFinancialBalancePaidRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldPercentage: 1398,
    idFieldAmount: 1399,
    idFieldPaymentDate: 1400,
    idFieldShipment: 1466,
  },
  permissions: {
    idPermissionAddItem: 156,
    idPermissionUpdateItem: 157,
    idPermissionRemoveItem: 158,
  },
};

export const satFinancialBalanceReceivedRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldPercentage: 1411,
    idFieldAmount: 1412,
    idFieldPaymentDate: 1413,
    idFieldShipment: 1467,
  },
  permissions: {
    idPermissionAddItem: 162,
    idPermissionUpdateItem: 163,
    idPermissionRemoveItem: 164,
  },
};

export const satFinancialTotalPaidRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    totalPaid: 1414,
    totalRemainCot: 1415,
  },
};

export const satFinancialTotalReceivedRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    totalReceived: 1416,
    totalRemainSun: 1417,
  },
};

export const satFinancialUsersPurchaseRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idPurchaseUser: 1428,
    idComexExportUser: 1429,
    idFinancialUser: 1430,
    notes: 1431,
  },
};

export const satFinancialUsersSellerRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idSalesUser: 1432,
    idSalesSecondUser: 1433,
    idComexImportUser: 1434,
    sellerNotes: 1435,
  },
};

export const satFinancialPurchaseOtherExpensesTotalRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldTotalOtherExpenses: 1418,
    idFieldTotalOnMargin: 1419,
    idFieldTotalOutOfMargin: 1420,
    idFieldTotalDiscountFromSupplier: 1421,
    idFieldTotalPayToSupplier: 1422,
  },
  permissions: {
    idPermissionApportionment: 178,
  },
};

export const satFinancialSellerOtherExpensesTotalRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldTotalOtherExpenses: 1423,
    idFieldTotalOnMargin: 1424,
    idFieldTotalOutOfMargin: 1425,
    idFieldTotalCreditToClient: 1426,
    idFieldTotalForApportionment: 1427,
  },
};

export const satFinancialFilesPurchaseRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  permissions: {
    idPermissionUpload: 166,
    idPermissionDelete: 167,
  },
};

export const satFinancialFilesSellerRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  permissions: {
    idPermissionUpload: 168,
    idPermissionDelete: 169,
  },
};

export const satFinancialResultsRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldTotalCotPiShipment: 1437,
    idFieldTotalCotCiShipment: 1438,
    idFieldTotalSunPiShipment: 1439,
    idFieldTotalSunCiShipment: 1440,
    idFieldTotalWithApportionment: 1441,
  },
};

export const satFinancialResultsConvertedRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldTotalConvertedCotPi: 1442,
    idFieldTotalConvertedCotCi: 1443,
    idFieldTotalConvertedSunPi: 1444,
    idFieldTotalConvertedSunCi: 1445,
  },
};

export const satFinancialResultsGrossRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldEstimatedGrossMargin: 1446,
    idFieldGrossMargin: 1447,
    idFieldEstimatedGrossProfit: 1448,
    idFieldGrossProfit: 1449,
  },
};

export const satFinancialResultsOtherExpensesRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldPurchaseTotalOtherExpenses: 1450,
    idFieldPurchaseTotalOnMargin: 1451,
    idFieldPurchaseTotalOutOfMargin: 1452,
    idFieldSellerTotalOtherExpenses: 1453,
    idFieldSellerTotalOnMargin: 1454,
    idFieldSellerTotalOutOfMargin: 1455,
    idFieldSellerTotalForApportionment: 1456,
  },
};

export const satFinancialResultsNetRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldEstimatedNetMargin: 1457,
    idFieldNetMargin: 1458,
    idFieldEstimatedNetProfit: 1459,
    idFieldNetProfit: 1460,
  },
};

export const satFinancialResultsStatusRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldShipmentStatus: 2135,
  },
};

export const satFinancialApportionmentRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldStCode: 1584,
    idFieldQuantityPi: 1585,
    idFieldQuantityCi: 1586,
    idFieldSunPrice: 1587,
    idFieldSunPriceTotal: 1588,
    idFieldNetWeight: 1589,
    idFieldNetWeightTotal: 1590,
    idFieldGrossWeight: 1591,
    idFieldGrossWeightTotal: 1592,
    idFieldCalculationBasisNetWeight: 1593,
    idFieldCalculationBasisGrossWeight: 1594,
    idFieldCalculationBasisSunPrice: 1595,
    idFieldFreight: 1596,
    idFieldFreightTaxes: 1597,
    idFieldFobCharges: 1598,
    idFieldYiwu: 1599,
    idFieldSamplesNetWeight: 1600,
    idFieldSamplesGrossWeight: 1601,
    idFieldSamplesSunPrice: 1602,
    idFieldOtherNetWeight: 1603,
    idFieldOtherGrossWeight: 1604,
    idFieldOtherSunPrice: 1605,
    idFieldSunWithApportionmentTotal: 1606,
    idFieldSunWithApportionmentUnit: 1607,
  },
};
