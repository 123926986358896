import React from 'react';
import { ISat } from '../../../interfaces';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { FinancialFieldsCommonWithSatFieldPermissions } from '../../interfaces';

interface ISellerProps {
  sat: ISat;
  commonWithSatFieldsPermissions: FinancialFieldsCommonWithSatFieldPermissions;
}

const Seller: React.FC<ISellerProps> = ({
  sat,
  commonWithSatFieldsPermissions,
}) => {
  const userCanSeeBlock =
    commonWithSatFieldsPermissions.companyName.view ||
    commonWithSatFieldsPermissions.importerName.view ||
    commonWithSatFieldsPermissions.idClientIncoterm.view;

  return userCanSeeBlock ? (
    <div className="p-grid">
      <FormTitle className="p-col-12 seller-form-title" name="Seller" />

      {commonWithSatFieldsPermissions.companyName.view && (
        <ReadOnlyInput
          className="p-col-3"
          label="Client/Notify"
          value={sat.companyName}
        />
      )}

      {commonWithSatFieldsPermissions.importerName.view && (
        <ReadOnlyInput
          className="p-col-3"
          label="Importer/Consignee"
          value={sat.importerName}
        />
      )}

      {commonWithSatFieldsPermissions.idClientIncoterm.view && (
        <ReadOnlyInput
          className="p-col-2"
          label="Selling Incoterm"
          value={sat.idClientIncoterm2?.description}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default Seller;
