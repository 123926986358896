import { useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { number, object, string } from 'yup';
import Button from '../../../../../../../components/Button';

import FormDropdown from '../../../../../../../components/FormDropdown';
import FormInputNumber from '../../../../../../../components/FormInputNumber';
import FormInputTextArea from '../../../../../../../components/FormInputTextArea';
import Loading from '../../../../../../../components/Loading';
import MainButton from '../../../../../../../components/MainButton';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import { Option } from '../../../../../../../interfaces/Option';
import { CurrencyAbbreviation } from '../../../../../../../shared/enums/currencyAbbreviation';
import ToastLife from '../../../../../../../shared/enums/toastLife';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../../../../utils/getValidationErrors';
import { ISat, ISatRnc } from '../../../../interfaces';
import { SatRncCost } from '../interfaces';
import { createSatRncCostQuery, updateSatRncCostQuery } from './queries';
import { Buttons, Container, Row } from './styles';

interface IAddRncCostModal {
  sat: ISat;
  satRnc: ISatRnc;
  satRncCost?: SatRncCost;
  rncCostsRefetch(): void;
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddRncCostModal: React.FC<IAddRncCostModal> = ({
  sat,
  satRnc,
  satRncCost,
  rncCostsRefetch,
  setDisplayModal,
}) => {
  const { toastRef } = useRefHook();

  const formRef = useRef<FormHandles>(null);

  const [pageLoading, setPageLoading] = useState(false);

  const showToast = useCallback(
    (severity: string, summary: string, life: number, detail?: string) => {
      toastRef.current?.show({ summary, detail, severity, life });
    },
    [toastRef],
  );

  const showSuccess = useCallback(
    (summary: string, detail?: string) => {
      showToast('success', summary, ToastLife.SUCCESS, detail);
    },
    [showToast],
  );

  const showError = useCallback(
    (summary: string, detail?: string) => {
      showToast('error', summary, ToastLife.ERROR, detail);
    },
    [showToast],
  );

  function getCurrencyOptions(): Option[] {
    const currencies = [
      {
        value: CurrencyAbbreviation.BRL,
        label: CurrencyAbbreviation[CurrencyAbbreviation.BRL],
      },
    ];
    if (
      sat?.idCurrency2 &&
      sat?.idCurrency2.idCurrency !== CurrencyAbbreviation.BRL
    ) {
      const option = {
        value: sat.idCurrency2.idCurrency,
        label: sat.idCurrency2.abbreviation,
      };
      currencies.push(option);
    }
    return currencies;
  }

  const validateForm = useCallback(
    async (data: any) => {
      formRef.current?.setErrors({});
      const schema = object().shape({
        idCurrency: string().nullable().required(requiredFieldErrorMessage),
        amount: number().nullable().required(requiredFieldErrorMessage),
        description: string().required(requiredFieldErrorMessage),
      });

      try {
        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        const firstError = error.inner[0];
        const inputWithError = formRef.current?.getFieldRef(firstError.path);

        if (inputWithError.focus) {
          inputWithError.focus();
        } else if (inputWithError.inputRef?.current?.focus) {
          inputWithError.inputRef?.current?.focus();
        }

        showError('Please fill all the required fields');
        return false;
      }
    },
    [showError],
  );

  const [createSatRncCostMutation] = useMutation(createSatRncCostQuery);
  const [updateSatRncCostMutation] = useMutation(updateSatRncCostQuery);

  const saveCost = useCallback(
    async (data: any) => {
      setPageLoading(true);
      const idSatRncCost = satRncCost?.idSatRncCost;
      const costToSave: SatRncCost = {
        idCurrency: data.idCurrency,
        amount: data.amount,
        description: data.description,
      };

      try {
        if (!idSatRncCost) {
          await createSatRncCostMutation({
            variables: {
              data: {
                ...costToSave,
                idSatRnc: satRnc.idSatRnc,
              },
            },
          });
        } else {
          await updateSatRncCostMutation({
            variables: {
              data: {
                ...costToSave,
                idSatRncCost: satRncCost?.idSatRncCost,
              },
            },
          });
        }
        setPageLoading(false);
        rncCostsRefetch();
        setDisplayModal(false);
        showSuccess(`RNC Cost ${!idSatRncCost ? 'created' : 'updated'}`);
      } catch (error) {
        setPageLoading(false);
        showError(
          `Error while ${!idSatRncCost ? 'creating' : 'updating'} RNC Cost`,
          error.message,
        );
      }
    },
    [
      createSatRncCostMutation,
      rncCostsRefetch,
      satRnc.idSatRnc,
      satRncCost?.idSatRncCost,
      setDisplayModal,
      showError,
      showSuccess,
      updateSatRncCostMutation,
    ],
  );

  function handleCancel() {
    setDisplayModal(false);
  }

  async function handleSubmit() {
    const data = formRef.current?.getData();
    const isFormValid = await validateForm(data);
    if (isFormValid) {
      saveCost(data);
    }
  }

  return (
    <Container>
      <Form ref={formRef} initialData={satRncCost} onSubmit={handleSubmit}>
        <Row className="p-d-flex p-flex-wrap">
          <FormDropdown
            className="p-col-6"
            label="Cost Currency"
            name="idCurrency"
            options={getCurrencyOptions()}
            optionLabel="label"
            optionValue="value"
            initialValue={satRncCost?.idCurrency ?? undefined}
            required
          />
          <FormInputNumber
            className="p-col-6"
            name="amount"
            label="Cost Amount"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            required
          />
        </Row>
        <Row className="p-d-flex p-flex-wrap">
          <FormInputTextArea
            className="p-col-12"
            name="description"
            label="Cost Description"
            required
            rows={5}
          />
        </Row>
        <Buttons
          className="p-mt-3"
          style={{ display: 'flex', placeContent: 'end' }}
        >
          <MainButton
            type="button"
            label="Confirm"
            className="p-mx-2"
            onClick={handleSubmit}
          />
          <Button
            type="button"
            label="Cancel"
            onClick={() => handleCancel()}
            className="p-button-danger"
          />
        </Buttons>
      </Form>
      {pageLoading && <Loading />}
    </Container>
  );
};
export default AddRncCostModal;
