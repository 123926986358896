import React from 'react';

import { Toast } from 'primereact/toast';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import Footer from '../../../components/Footer';

import { useMenu } from '../../../hooks/useMenu';
import { Wrapper, Content } from './styles';
import { useRefHook } from '../../../hooks/useRefHook';

const DefaultLayout: React.FC = ({ children }) => {
  const { visible } = useMenu();
  // Referencia ao toast
  const { toastRef } = useRefHook();
  return (
    <>
      <Header />
      <Wrapper>
        <Sidebar />
        <Content id="site-content" sidebarVisible={visible}>
          {children}
        </Content>
      </Wrapper>
      <Footer />
      <Toast ref={toastRef} />
    </>
  );
};

export default DefaultLayout;
