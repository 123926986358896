import React, {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { DataView, DataViewPageParams } from 'primereact/dataview';
import { gql, useQuery } from '@apollo/client';
import { InputText } from 'primereact/inputtext';
import { AsyncPaginate } from 'react-select-async-paginate';
import pagination, { searchDelayMiliseconds } from '../../config/pagination';
import imagePlaceholder from '../../assets/imagePlaceholder.svg';
import { Container } from './styles';
import Button from '../Button';
import { LazyParams, Product } from './interfaces';
import { asyncSelectLoadSuppliers } from '../../shared/querys/supplier';

export interface IProductDataViewSelectRef {
  selectedProducts: Product[];
}

interface ProductDataViewSelectProps {
  ref: Ref<IProductDataViewSelectRef>;
  idSat?: number;
  idCq?: number;
}

const ProductDataViewSelect: React.FC<ProductDataViewSelectProps> = forwardRef(
  ({ idSat, idCq }, ref) => {
    const isMounted = useRef(false);

    const listProductQuery = gql`
      query ListAvailableProductsToModule(
        $data: ListAvaliableProdutctsToModuleInput!
      ) {
        listAvailableProductsToModule(data: $data) {
          items
          data {
            idProduct
            idSupplier
            imageUrl
            nameEn
            stCode
            sunNumber
          }
        }
      }
    `;

    const [lazyParams, setLazyParams] = useState<LazyParams>(
      pagination.initialLazyParams,
    );
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [products, setProducts] = useState<Product[]>([]);
    const [showSelectedOnly, setShowSelectedOnly] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');

    useImperativeHandle(ref, () => ({
      selectedProducts,
    }));

    const allItemsSelected = products.every(item =>
      selectedProducts.some(sItem => item.idProduct === sItem.idProduct),
    );

    const { loading: productsLoading } = useQuery(listProductQuery, {
      variables: {
        data: {
          idSat,
          idCq,
          pagination: {
            _page: lazyParams.page + 1,
            _limit: pagination.initialLazyParams.rows,
            _orderBy: 'product.idProduct',
            _sortOrder: 'DESC',
          },
          globalSearch: lazyParams.globalFilter,
          idSupplier: lazyParams.idSupplier,
        },
      },
      onCompleted: data => {
        setProducts(data.listAvailableProductsToModule.data);
        setTotalRecords(data.listAvailableProductsToModule.items);
      },
    });

    function handleClickItem(product: Product) {
      const currentProductIndex = selectedProducts.findIndex(
        item => item.idProduct === product.idProduct,
      );

      if (currentProductIndex !== -1) {
        const newSelectedProducts = [...selectedProducts];
        newSelectedProducts.splice(currentProductIndex, 1);
        setSelectedProducts(newSelectedProducts);
      } else {
        setSelectedProducts([...selectedProducts, product]);
      }
    }

    const renderGridItem = (data: Product) => {
      const itemSelected = selectedProducts.some(
        item => item.idProduct === data.idProduct,
      );
      return (
        <div className="p-d-flex p-col-12 p-md-6 p-lg-4 p-xl-3">
          <div
            className={`product-grid-item ${
              itemSelected ? 'product-item-highlight' : ''
            }`}
            role="checkbox"
            aria-checked={itemSelected}
            onClick={() => handleClickItem(data)}
            onKeyDown={e => {
              if (e.code === 'Enter') handleClickItem(data);
            }}
            tabIndex={0}
          >
            <span className="product-supplier">{data.sunNumber}</span>
            <div className="product-grid-item-content">
              <img
                src={data.imageUrl ?? imagePlaceholder}
                onError={e => {
                  e.currentTarget.src = imagePlaceholder;
                }}
                alt={data.stCode}
              />
              <div className="product-name">{data.stCode}</div>
              <div className="product-description">{data.nameEn}</div>
            </div>
          </div>
        </div>
      );
    };

    function onPage(e: DataViewPageParams) {
      setLazyParams(prev => {
        return { ...prev, ...e };
      });
    }

    function handleSelectAll() {
      if (!selectedProducts.length) return setSelectedProducts(products);

      if (allItemsSelected) {
        const newSelectedItems = [...selectedProducts].filter(
          item =>
            !products.some(product => product.idProduct === item.idProduct),
        );

        return setSelectedProducts(newSelectedItems);
      }

      const duplicatedRemoved = products.filter(
        item =>
          !selectedProducts.some(sItem => item.idProduct === sItem.idProduct),
      );

      return setSelectedProducts([...selectedProducts, ...duplicatedRemoved]);
    }

    function handleChangeSupplier(idSupplier?: number) {
      setLazyParams(prev => {
        return {
          ...prev,
          idSupplier,
          first: 0,
          page: 0,
        };
      });
    }

    useEffect(() => {
      if (isMounted.current) {
        const delayDebounceFn = setTimeout(() => {
          setLazyParams(prev => {
            return { ...prev, first: 0, page: 0, globalFilter };
          });
        }, searchDelayMiliseconds);

        return () => clearTimeout(delayDebounceFn);
      }

      isMounted.current = true;
      return undefined;
    }, [globalFilter]);

    const renderHeader = () => {
      return (
        <div className="p-d-block p-d-lg-flex p-jc-between header">
          <div className="p-mb-2 p-mb-lg-0 p-d-flex">
            <Button
              className="p-mr-2"
              label={showSelectedOnly ? 'Show all' : 'Show selected'}
              onClick={() => setShowSelectedOnly(prev => !prev)}
              type="button"
            />
            <Button
              label={allItemsSelected ? 'Unselect all' : 'Select all'}
              severity={allItemsSelected ? 'danger' : 'primary'}
              onClick={() => handleSelectAll()}
              disabled={showSelectedOnly}
              type="button"
            />
          </div>

          <div className="p-formgroup-inline p-ai-center">
            <div className="header-field p-mr-2 p-mb-2 p-mb-sm-0">
              <AsyncPaginate
                loadOptions={asyncSelectLoadSuppliers}
                debounceTimeout={1000}
                getOptionLabel={option => option.sunNumber}
                getOptionValue={option => option.idSupplier}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No suppliers found'}
                placeholder="Select a Supplier"
                isClearable
                onChange={e => handleChangeSupplier(e?.idSupplier)}
                isDisabled={showSelectedOnly}
              />
            </div>

            <div className="header-field">
              <InputText
                value={globalFilter}
                onChange={e => setGlobalFilter(e.target.value)}
                placeholder="Name or ST Code"
                disabled={showSelectedOnly}
              />
            </div>
          </div>
        </div>
      );
    };

    return (
      <Container>
        <DataView
          value={showSelectedOnly ? selectedProducts : products}
          dataKey="idProduct"
          layout="grid"
          itemTemplate={renderGridItem}
          lazy
          paginator={!showSelectedOnly}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          first={lazyParams.first}
          onPage={e => onPage(e)}
          loading={productsLoading}
          header={renderHeader()}
          emptyMessage="No products found"
        />
      </Container>
    );
  },
);

export default ProductDataViewSelect;
