import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import { Menu as PrimeMenu } from 'primereact/menu';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { ValidationError } from 'yup';
import PageHeader from '../../../components/PageHeader';
import Image from '../../../components/PageHeader/Image';
import { Container, CrudForm } from './styles';

import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageMenu, { IPageMenuItem } from '../../../components/PageMenu';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import ToastLife from '../../../shared/enums/toastLife';
import {
  satFinancialRoles,
  satForeignTradeRoles,
  satsRoles,
} from '../../../shared/roles/sat';
import { satSuppliersRoles } from '../../../shared/roles/satSuppliers';
import getFieldPermission from '../../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../utils/userHasPermission';
import Financial, { ISatFinancialRef } from './Financial';
import GeneralInformation, {
  ISatGeneralInformationRef,
} from './GeneralInformation';
import {
  ForeignTradeFieldsCommonWithSatFieldsPermissions,
  GeneralInformationFieldsPermissions,
  ISat,
  ISatCancellationReason,
  SatItemsOrderAreaFieldsPermissions,
} from './interfaces';

import SatItems from './SatItems';
import Suppliers from './Suppliers';

import {
  deleteSatQuery,
  duplicateSatQuery,
  existsRncBySatIdQuery,
  finishSatQuery,
  getSatReportsQuery,
  listSatCancellationReasonQuery,
  listSatQuery,
  updateSatQuery,
} from './queries';

import CheckboxMenu from '../../../components/CheckboxMenu';
import MutationResponseSummary, {
  IMutationResponse,
  IMutationResponseSummaryRef,
} from '../../../components/MutationResponseSummary';
import SatStatusTag, { SatStatus } from '../../../components/SatStatusTag';
import UpdateLogArchives from '../../../components/UpdateLogArchives';
import useTitle from '../../../hooks/useTitle';
import { Role } from '../../../shared/enums/role';
import { satItemsRoles } from '../../../shared/roles/satItems';
import { userHasSomeRole } from '../../../utils/userHasRole';
import Artwork, { ISatArtworkRef } from './Artwork';
import CancelSatMenu from './CancelSat';
import CancelSatStandByMenu from './CancelSatStandBy';
import ConfirmOrderMenu from './ConfirmOrder';
import { FinancialFieldsCommonWithSatFieldPermissions } from './Financial/interfaces';
import FinancialApportionment, {
  ISatFinancialApportionmentRef,
} from './FinancialApportionment';
import ForeignTrade, { ISatForeignTradeRef } from './ForeignTrade';
import AvailableSatItemsModal, {
  IAvaillableSatItemsModalRef,
} from './ForeignTrade/AvailableSatItemsModal';
import HeaderStatus from './HeaderStatus';
import OpenRncMenu from './OpenRnc';
import Rnc, { ISatRncRef } from './Rnc';
import ItemsGrid, { ISatItemsGridRef } from './SatItems/ItemsGrid';
import Status from './Status';
import Updates from './Updates';
import { satLateralMenuItems } from './constants';

// Parametros da rota de product
export interface SatRouteParams {
  satId: string;
}

const financialApportionmentTabKey = 'financialApportionment';

const defaultPageTitle = 'Manage SAT';

const Sat: React.FC = () => {
  const [sat, setSat] = useState<ISat>({} as ISat);

  useTitle(
    sat?.satNumber
      ? `${sat.satNumber} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // ID do product
  const satId = parseInt(useParams<SatRouteParams>().satId, 10);

  const currentDate = new Date().getTime();

  const [pageLoading, setPageLoading] = useState(false);

  const [isVisibleConfirmOrderMenu, setIsVisibleConfirmOrderMenu] =
    useState(false);

  const [isVisibleRecipientsModal, setIsVisibleRecipientsModal] =
    useState(false);

  const [isVisibleCancelSatMenu, setIsVisibleCancelSatMenu] = useState(false);

  const [isVisibleCancelSatStandByMenu, setIsVisibleCancelSatStandByMenu] =
    useState(false);

  const [isVisibleOpenRncMenu, setIsVisibleOpenRncMenu] = useState(false);

  // Referencia do menu de function
  const functionMenuRef = useRef<PrimeMenu>(null);

  // URL
  const { pathname, search } = useLocation();

  const { toastRef, showError } = useRefHook();

  const generalInformationRef = useRef<ISatGeneralInformationRef>(null);

  const artworkRef = useRef<ISatArtworkRef>(null);

  const satItemsGridRef = useRef<ISatItemsGridRef>(null);

  const foreignTradeRef = useRef<ISatForeignTradeRef>(null);

  const financialRef = useRef<ISatFinancialRef>(null);

  const financialApportionmentRef = useRef<ISatFinancialApportionmentRef>(null);

  const rncRef = useRef<ISatRncRef>(null);

  const mutationResponseSummaryRef = useRef<IMutationResponseSummaryRef>(null);

  const downloadFilesSummaryRef = useRef<IMutationResponseSummaryRef>(null);

  const confirmOrderSummaryRef = useRef<IMutationResponseSummaryRef>(null);

  const addForeignTradeItemModalRef = useRef<IAvaillableSatItemsModalRef>(null);

  const [existsRncForThisSat, setExistsRncForThisSat] =
    useState<boolean>(false);

  const [isConfirmOrderWithGroup, setIsConfirmOrderWithGroup] =
    useState<boolean>(false);

  const [updateSatMutationResponse, setUpdateSatMutationResponse] = useState<
    IMutationResponse[]
  >([]);

  const [downloadFilesResponse, setDownloadFilesResponse] = useState<
    IMutationResponse[]
  >([]);

  const [satCancellationReasonStandBy, setSatCancellationReasonStandBy] =
    useState<ISatCancellationReason>();

  const [confirmOrderMutationResponse, setConfirmOrderMutationResponse] =
    useState<IMutationResponse[]>([]);

  // Roles do usuario
  const { roles } = useAuth();

  // Permissions de SAT
  const {
    idPermissionDeleteSat,
    idPermissionExportSellerPi,
    idPermissionExportSellerPiSimplified,
    idPermissionExportCotPI,
    idPermissionExportOd,
    idPermissionExportShippingMark,
    idPermissionExportRoundSticker,
    idPermissionExportSticker,
    idPermissionExportInnerSticker,
    idPermissionDownloadAreaArtworkHrp,
    idPermissionDownloadAreaArtworkMainDiecut,
    idPermissionDownloadAreaArtworkCareLabelFoldedHorizontal,
    idPermissionDownloadAreaArtworkCareLabelSewedVertical,
    idPermissionDownloadAreaArtworkCareLabelFoldedVertical,
    idPermissionDownloadAreaArtworkCareLabelSewedHorizontal,
    idPermissionDuplicateSat,
    idPermissionFollowUp,
    idPermissionUpdates,
    idPermissionOpenRnc,
    idPermissionExportContract,
    idPermissionHistory,
    idPermissionFinishSat,
  } = satsRoles.permissions;

  // Busca permissoes do usuario para a entity
  const userPermissionsSat = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(
      roles.rolesUser,
      satsRoles.idEntity,
    );
  }, [roles.rolesUser]);

  const generalInformationFieldsPermissions: GeneralInformationFieldsPermissions =
    useMemo(() => {
      return {
        mainProduct: getFieldPermission(
          satsRoles.fields.idFieldMainProduct,
          userPermissionsSat.userFields,
        ),
        productLine: getFieldPermission(
          satsRoles.fields.idFieldProductLine,
          userPermissionsSat.userFields,
        ),
        idCategory: getFieldPermission(
          satsRoles.fields.idFieldIdCategory,
          userPermissionsSat.userFields,
        ),
        idTypeOperation: getFieldPermission(
          satsRoles.fields.idFieldIdTypeOperation,
          userPermissionsSat.userFields,
        ),
        typeOfOrder: getFieldPermission(
          satsRoles.fields.idFieldTypeOfOrder,
          userPermissionsSat.userFields,
        ),
        idSatOrigin: getFieldPermission(
          satsRoles.fields.idFieldIdSatOrigin,
          userPermissionsSat.userFields,
        ),
        indSamples: getFieldPermission(
          satsRoles.fields.idFieldIndSamples,
          userPermissionsSat.userFields,
        ),
        samplesLeadTime: getFieldPermission(
          satsRoles.fields.idFieldSamplesLeadTime,
          userPermissionsSat.userFields,
        ),
        packagesNotes: getFieldPermission(
          satsRoles.fields.idFieldPackagesNotes,
          userPermissionsSat.userFields,
        ),
        remarks: getFieldPermission(
          satsRoles.fields.idFieldRemarks,
          userPermissionsSat.userFields,
        ),
        qtyCtnr: getFieldPermission(
          satsRoles.fields.idFieldQtyCtnr,
          userPermissionsSat.userFields,
        ),
        idTypeCtnr: getFieldPermission(
          satsRoles.fields.idFieldIdTypeCtnr,
          userPermissionsSat.userFields,
        ),
        idClient: getFieldPermission(
          satsRoles.fields.idFieldIdClient,
          userPermissionsSat.userFields,
        ),
        companyName: getFieldPermission(
          satsRoles.fields.idFieldCompanyName,
          userPermissionsSat.userFields,
        ),
        clientCnpj: getFieldPermission(
          satsRoles.fields.idFieldClientCNPJ,
          userPermissionsSat.userFields,
        ),
        clientAddress: getFieldPermission(
          satsRoles.fields.idFieldClientAddress,
          userPermissionsSat.userFields,
        ),
        clientDepartment: getFieldPermission(
          satsRoles.fields.idFieldClientDepartment,
          userPermissionsSat.userFields,
        ),
        idFirstBuyer: getFieldPermission(
          satsRoles.fields.idFieldIdFirstBuyer,
          userPermissionsSat.userFields,
        ),
        idSecondBuyer: getFieldPermission(
          satsRoles.fields.idFieldIdSecondBuyer,
          userPermissionsSat.userFields,
        ),
        idImporter: getFieldPermission(
          satsRoles.fields.idFieldIdImporter,
          userPermissionsSat.userFields,
        ),
        importerName: getFieldPermission(
          satsRoles.fields.idFieldImporterName,
          userPermissionsSat.userFields,
        ),
        importerCnpj: getFieldPermission(
          satsRoles.fields.idFieldImporterCNPJ,
          userPermissionsSat.userFields,
        ),
        importerAddress: getFieldPermission(
          satsRoles.fields.idFieldImporterAddress,
          userPermissionsSat.userFields,
        ),
        idClientIncoterm: getFieldPermission(
          satsRoles.fields.idFieldIdClientIncoterm,
          userPermissionsSat.userFields,
        ),
        paymentTermAdvance: getFieldPermission(
          satsRoles.fields.idFieldPaymentTermAdvance,
          userPermissionsSat.userFields,
        ),
        paymentTermBalance: getFieldPermission(
          satsRoles.fields.idFieldPaymentTermBalance,
          userPermissionsSat.userFields,
        ),
        idPaymentTermCondition: getFieldPermission(
          satsRoles.fields.idFieldIdPaymentTermCondition,
          userPermissionsSat.userFields,
        ),
        idPurchaseIncoterm: getFieldPermission(
          satsRoles.fields.idFieldIdPurchaseIncoterm,
          userPermissionsSat.userFields,
        ),
        exporterAddress: getFieldPermission(
          satsRoles.fields.idFieldExporterAddress,
          userPermissionsSat.userFields,
        ),
        productionTime: getFieldPermission(
          satsRoles.fields.idFieldProductionTime,
          userPermissionsSat.userFields,
        ),
        satSuppliers: getFieldPermission(
          satsRoles.fields.idFieldSatSuppliers,
          userPermissionsSat.userFields,
        ),
        manufacture: getFieldPermission(
          satsRoles.fields.idFieldManufacture,
          userPermissionsSat.userFields,
        ),
        idLoadingPort: getFieldPermission(
          satsRoles.fields.idFieldIdLoadingPort,
          userPermissionsSat.userFields,
        ),
        idPort: getFieldPermission(
          satsRoles.fields.idFieldIdPort,
          userPermissionsSat.userFields,
        ),
        idCountryOrigin: getFieldPermission(
          satsRoles.fields.idFieldIdCountryOrigin,
          userPermissionsSat.userFields,
        ),
        idCountryAcquisition: getFieldPermission(
          satsRoles.fields.idFieldIdCountryAcquisition,
          userPermissionsSat.userFields,
        ),
        idCountryProcedence: getFieldPermission(
          satsRoles.fields.idFieldIdCountryProcedence,
          userPermissionsSat.userFields,
        ),
        estimatedInspectionDate: getFieldPermission(
          satsRoles.fields.idFieldEstimatedInspectionDate,
          userPermissionsSat.userFields,
        ),
        estimatedShipmentDate: getFieldPermission(
          satsRoles.fields.idFieldEstimatedShipmentDate,
          userPermissionsSat.userFields,
        ),
        estimatedArrivalDate: getFieldPermission(
          satsRoles.fields.idFieldEstimatedArrivalDate,
          userPermissionsSat.userFields,
        ),
        estimatedArrivalClientDate: getFieldPermission(
          satsRoles.fields.idFieldEstimatedArrivalClientDate,
          userPermissionsSat.userFields,
        ),
        satGroup: getFieldPermission(
          satsRoles.fields.idFieldSatGroup,
          userPermissionsSat.userFields,
        ),
        idFinancialUser: getFieldPermission(
          satsRoles.fields.idFieldIdFinancialUser,
          userPermissionsSat.userFields,
        ),
        idPurchaseUser: getFieldPermission(
          satsRoles.fields.idFieldIdPurchaseUser,
          userPermissionsSat.userFields,
        ),
        idSalesUser: getFieldPermission(
          satsRoles.fields.idFieldIdSalesUser,
          userPermissionsSat.userFields,
        ),
        idSalesSecondUser: getFieldPermission(
          satsRoles.fields.idFieldIdSalesSecondUser,
          userPermissionsSat.userFields,
        ),
        idComexExportUser: getFieldPermission(
          satsRoles.fields.idFieldIdComexExportUser,
          userPermissionsSat.userFields,
        ),
        idComexImportUser: getFieldPermission(
          satsRoles.fields.idFieldIdComexImportUser,
          userPermissionsSat.userFields,
        ),
        idDesignerUser: getFieldPermission(
          satsRoles.fields.idFieldDesignLevel,
          userPermissionsSat.userFields,
        ),
        idSupplierExporter: getFieldPermission(
          satsRoles.fields.idFieldIdSupplierExporter,
          userPermissionsSat.userFields,
        ),
        idRegisterUser: getFieldPermission(
          satsRoles.fields.idFieldIdRegisterUser,
          userPermissionsSat.userFields,
        ),
        stock: getFieldPermission(
          satsRoles.fields.idFieldStock,
          userPermissionsSat.userFields,
        ),
      };
    }, [userPermissionsSat.userFields]);

  const satItemsOrderAreaFieldsPermissions: SatItemsOrderAreaFieldsPermissions =
    useMemo(() => {
      return {
        mainProduct: getFieldPermission(
          satItemsRoles.fields.idFieldMainProduct,
          userPermissionsSat.userFields,
        ),
        idCategory: getFieldPermission(
          satItemsRoles.fields.idFieldCategory,
          userPermissionsSat.userFields,
        ),
        typeOfOrder: getFieldPermission(
          satItemsRoles.fields.idFieldTypeOfOrder,
          userPermissionsSat.userFields,
        ),
        idSatOrigin: getFieldPermission(
          satItemsRoles.fields.idFieldSatOrigin,
          userPermissionsSat.userFields,
        ),
        packagesNotes: getFieldPermission(
          satItemsRoles.fields.idFieldSatPackageNotes,
          userPermissionsSat.userFields,
        ),
        remarks: getFieldPermission(
          satItemsRoles.fields.idFieldRemarks,
          userPermissionsSat.userFields,
        ),
        indSamples: getFieldPermission(
          satItemsRoles.fields.idFieldIndSamples,
          userPermissionsSat.userFields,
        ),
        samplesLeadTime: getFieldPermission(
          satItemsRoles.fields.idFieldSamplesLeadTime,
          userPermissionsSat.userFields,
        ),
        idClient: getFieldPermission(
          satItemsRoles.fields.idFieldClient,
          userPermissionsSat.userFields,
        ),
        importerName: getFieldPermission(
          satItemsRoles.fields.idFieldImporter,
          userPermissionsSat.userFields,
        ),
        idClientIncoterm: getFieldPermission(
          satItemsRoles.fields.idFieldClientIncoterm,
          userPermissionsSat.userFields,
        ),
        paymentTermAdvance: getFieldPermission(
          satItemsRoles.fields.idFieldPaymentTermAdvance,
          userPermissionsSat.userFields,
        ),
        paymentTermBalance: getFieldPermission(
          satItemsRoles.fields.idFieldPaymentTermBalance,
          userPermissionsSat.userFields,
        ),
        idPaymentTermCondition: getFieldPermission(
          satItemsRoles.fields.idFieldPaymentTermCondition,
          userPermissionsSat.userFields,
        ),
        idPurchaseIncoterm: getFieldPermission(
          satItemsRoles.fields.idFieldPurchaseIncoterm,
          userPermissionsSat.userFields,
        ),
        idSupplierExporter: getFieldPermission(
          satItemsRoles.fields.idFieldSupplierExporter,
          userPermissionsSat.userFields,
        ),
        exporterAddress: getFieldPermission(
          satItemsRoles.fields.idFieldExporterAddress,
          userPermissionsSat.userFields,
        ),
        productionTime: getFieldPermission(
          satItemsRoles.fields.idFieldProductionTime,
          userPermissionsSat.userFields,
        ),
        satSuppliers: getFieldPermission(
          satItemsRoles.fields.idFieldSatSuppliers,
          userPermissionsSat.userFields,
        ),
        estimatedInspectionDate: getFieldPermission(
          satItemsRoles.fields.idFieldEstimatedInspectionDate,
          userPermissionsSat.userFields,
        ),
        estimatedShipmentDate: getFieldPermission(
          satItemsRoles.fields.idFieldEstimatedShipmentDate,
          userPermissionsSat.userFields,
        ),
        estimatedArrivalDate: getFieldPermission(
          satItemsRoles.fields.idFieldEstimatedArrivalDate,
          userPermissionsSat.userFields,
        ),
        estimatedArrivalClientDate: getFieldPermission(
          satItemsRoles.fields.idFieldEstimatedArrivalClientDate,
          userPermissionsSat.userFields,
        ),
        satSuppliersNames: getFieldPermission(
          satItemsRoles.fields.idFieldSatSupplierNames,
          userPermissionsSat.userFields,
        ),
      };
    }, [userPermissionsSat.userFields]);

  const foreignTradeFieldsCommonWithSatFieldsPermissions: ForeignTradeFieldsCommonWithSatFieldsPermissions =
    useMemo(() => {
      return {
        confirmOrderComercial: getFieldPermission(
          satForeignTradeRoles.fields.idFieldConfirmOrderComercial,
          userPermissionsSat.userFields,
        ),
      };
    }, [userPermissionsSat.userFields]);

  const financialFieldsCommonWithSatFieldsPermissions: FinancialFieldsCommonWithSatFieldPermissions =
    useMemo(() => {
      return {
        companyName: getFieldPermission(
          satFinancialRoles.fields.idFieldCompanyName,
          userPermissionsSat.userFields,
        ),
        countryOriginName: getFieldPermission(
          satFinancialRoles.fields.idFieldCountryOriginName,
          userPermissionsSat.userFields,
        ),
        idClientIncoterm: getFieldPermission(
          satFinancialRoles.fields.idFieldIdClientIncoterm,
          userPermissionsSat.userFields,
        ),
        importerName: getFieldPermission(
          satFinancialRoles.fields.idFieldImporterName,
          userPermissionsSat.userFields,
        ),
        mainProduct: getFieldPermission(
          satFinancialRoles.fields.idFieldMainProduct,
          userPermissionsSat.userFields,
        ),
        purchaseIncotermDescription: getFieldPermission(
          satFinancialRoles.fields.idFieldPurchaseIncotermDescription,
          userPermissionsSat.userFields,
        ),
      };
    }, [userPermissionsSat.userFields]);

  // Busca permissoes do usuario para a entity
  const userPermissionsSatSuppliers = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    satSuppliersRoles.idEntity,
  );

  // Flag que indica necessidade de atualizar SAT Suppliers
  const [shouldRefetchSatSuppliers, setShouldRefetchSatSuppliers] =
    useState(false);

  // Redirect
  const history = useHistory();

  // Dados para retornar para listagem de produtos
  const initialPage = parseInt(
    new URLSearchParams(search).get('initialPage') ?? '0',
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(search).get('initialFirst') ?? '0',
    10,
  );

  const urlTabParam = new URLSearchParams(search).get('tab');

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'SATs',
      path:
        initialPage && initialFirst
          ? `/commercial/sats?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/commercial/sats',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  const userCanSeeUpdatesContent = useMemo(() => {
    return userHasPermission(
      idPermissionUpdates,
      userPermissionsSat.userPermissions,
    );
  }, [idPermissionUpdates, userPermissionsSat.userPermissions]);

  const userCanSeeFollowUp = useMemo(() => {
    return userHasPermission(
      idPermissionFollowUp,
      userPermissionsSat.userPermissions,
    );
  }, [idPermissionFollowUp, userPermissionsSat.userPermissions]);

  const userCanSeeTabUpdates = useMemo(() => {
    return userCanSeeUpdatesContent || userCanSeeFollowUp;
  }, [userCanSeeFollowUp, userCanSeeUpdatesContent]);

  // Itens do menu lateral
  const menuItems = useMemo(() => {
    const itemsList: IPageMenuItem[] = [];

    itemsList.push(
      {
        key: satLateralMenuItems.generalInformation.key,
        label: satLateralMenuItems.generalInformation.label,
      },
      {
        key: satLateralMenuItems.suppliers.key,
        label: satLateralMenuItems.suppliers.label,
      },
    );

    if (sat.confirmOrderComercial) {
      itemsList.push(
        {
          key: satLateralMenuItems.foreignTrade.key,
          label: satLateralMenuItems.foreignTrade.label,
        },
        {
          key: satLateralMenuItems.artwork.key,
          label: satLateralMenuItems.artwork.label,
        },
      );
    }

    if (existsRncForThisSat) {
      itemsList.push({
        key: satLateralMenuItems.rnc.key,
        label: satLateralMenuItems.rnc.label,
      });
    }

    itemsList.push({
      key: satLateralMenuItems.satItems.key,
      label: satLateralMenuItems.satItems.label,
    });

    if (sat.confirmOrderComercial) {
      itemsList.push({
        key: satLateralMenuItems.financial.key,
        label: satLateralMenuItems.financial.label,
      });
    }

    if (userCanSeeTabUpdates) {
      itemsList.push({
        key: satLateralMenuItems.updates.key,
        label: satLateralMenuItems.updates.label,
      });
    }

    if (sat.confirmOrderComercial) {
      itemsList.push({
        key: satLateralMenuItems.status.key,
        label: satLateralMenuItems.status.label,
      });
    }

    return itemsList;
  }, [sat.confirmOrderComercial, existsRncForThisSat, userCanSeeTabUpdates]);

  // Item de menu selecionado
  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0].key);

  const isOrderConfirmed = () => {
    return !!sat.confirmOrderComercial && !!sat.confirmOrderPurchase;
  };

  /**
   * Busca SAT
   */
  const [loadSatData, { loading: satLoading, refetch: refetchSat }] =
    useLazyQuery(
      listSatQuery(
        generalInformationFieldsPermissions,
        satItemsOrderAreaFieldsPermissions,
        financialFieldsCommonWithSatFieldsPermissions,
      ),
      {
        onCompleted: response => {
          if (response.listSatById) {
            setSat({
              ...response.listSatById,
              exporterType: response.listSatById.idPartnerExporter
                ? 'Partner'
                : 'Supplier',
            });
          }
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting SAT data',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      },
    );

  /**
   * Verifica se existe RNC para a SAT
   */
  useQuery(existsRncBySatIdQuery, {
    variables: {
      satId,
    },
    onCompleted: response => {
      setExistsRncForThisSat(response.existsRncBySatId);
    },
    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while verifying if SAT has some RNC',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const [downloadSatReports, { loading: downloadSatReportsInProgress }] =
    useLazyQuery(getSatReportsQuery, {
      onCompleted: response => {
        if (response.generateSatBulkDownloads) {
          const { downloadUrl, summary } = response.generateSatBulkDownloads;

          if (downloadUrl) {
            window.open(downloadUrl, '_blank');
          }

          if (summary.length) {
            setDownloadFilesResponse(
              summary.map((item: any) => {
                return {
                  errors: [item.error],
                  moduleChanged: item.reportName,
                };
              }),
            );

            downloadFilesSummaryRef.current?.toggleDisplaySummary();
          }
        }
      },
      onError: error => {
        showError({
          summary: 'Error while downloading files',
          detail: error.message,
        });
      },
    });

  const cleanSat = useCallback(() => {
    setSat({} as ISat);
  }, []);

  const satRefetch = useCallback(async () => {
    const response = await refetchSat();

    setSat({
      ...response.data.listSatById,
      exporterType: response.data.listSatById.idPartnerExporter
        ? 'Partner'
        : 'Supplier',
      exporterAddress: response.data.listSatById.idPartnerExporter
        ? response.data.listSatById.idPartnerExporter2.address
        : response.data.listSatById.idSupplierExporter
        ? response.data.listSatById.idSupplierExporter2.address
        : undefined,
    });
  }, [refetchSat]);

  const updateSatVersionLock = () => {
    setSat({
      ...sat,
      versionLock: sat.versionLock + 1,
    });
  };

  const { loading: satCancellationReasonLoading } = useQuery(
    listSatCancellationReasonQuery,
    {
      variables: {
        id: satId,
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting SAT cancellation Reason',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
      onCompleted: async response => {
        if (response.listSatCancellationReason) {
          setSatCancellationReasonStandBy(response.listSatCancellationReason);
        }
      },
    },
  );

  const [updateSatMutation] = useMutation(updateSatQuery);
  const [duplicateSatMutation] = useMutation(duplicateSatQuery);
  const [deleteSatMutation] = useMutation(deleteSatQuery);
  const [finishSatMutation] = useMutation(finishSatQuery);

  /**
   * Duplica SAT
   * @param idSat ID da SAT
   */
  const handleDuplicateSat = useCallback(
    async (idSat: number) => {
      setPageLoading(true);
      try {
        const response = await duplicateSatMutation({
          variables: {
            idSat,
          },
        });

        toastRef.current?.show({
          severity: 'success',
          summary: 'Duplicated successfully',
          detail: (
            <>
              <p>New SAT: </p>
              <Link
                to={`/commercial/sats/${response.data.duplicateSat.idSat}`}
                style={{ textDecoration: 'none', color: 'unset' }}
              >
                {response.data.duplicateSat.satNumber}
              </Link>
            </>
          ),
          life: ToastLife.ERROR,
        });
        // Define o item selecionado no menu como a primeira aba, "General Information",
        // para garantir que ao clicar no link nao redirecione para uma aba nao existente
        setSelectedMenuItem(menuItems[0].key);
      } catch (error) {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while duplicating SAT',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [duplicateSatMutation, menuItems, toastRef],
  );

  /**
   * Deleta SAT
   */
  async function handleDeleteSat() {
    setPageLoading(true);
    try {
      await deleteSatMutation({
        variables: {
          idsSats: [sat.idSat],
        },
      });

      toastRef.current?.show({
        severity: 'success',
        summary: 'SAT Deleted',
        life: ToastLife.SUCCESS,
      });

      history.push('/commercial/sats');
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while deleting SAT',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    } finally {
      setPageLoading(false);
    }
  }

  const downloadOptions = useMemo(() => {
    const items = [];
    const itemsDocs = [];
    const itemsArtworks = [];

    if (
      userHasPermission(
        idPermissionExportSellerPi,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsDocs.push({
        key: 'sunPi',
        label: 'SUN PI',
      });
    }

    if (
      userHasPermission(
        idPermissionExportSellerPiSimplified,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsDocs.push({
        key: 'sunPiSimplified',
        label: 'SUN PI SIMPLIFIED',
      });
    }

    if (
      userHasPermission(
        idPermissionExportCotPI,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsDocs.push({
        key: 'cotPi',
        label: 'COT PI',
      });
    }

    if (
      userHasPermission(
        idPermissionExportOd,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsDocs.push({
        key: 'od',
        label: 'OD',
      });
    }

    if (
      userHasPermission(
        idPermissionExportContract,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsDocs.push({
        key: 'contract',
        label: 'Contract',
      });
    }

    if (
      userHasPermission(
        idPermissionExportShippingMark,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsArtworks.push({
        key: 'shippingMark',
        label: 'Shipping Mark',
      });
    }

    if (
      userHasPermission(
        idPermissionExportRoundSticker,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsArtworks.push({
        key: 'roundSticker',
        label: 'Round Sticker',
      });
    }

    if (
      userHasPermission(
        idPermissionExportSticker,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsArtworks.push({
        key: 'sticker',
        label: 'Sticker',
      });
    }

    if (
      userHasPermission(
        idPermissionExportInnerSticker,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsArtworks.push({
        key: 'innerSticker',
        label: 'Inner Sticker',
      });
    }

    if (
      userHasPermission(
        idPermissionDownloadAreaArtworkHrp,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsArtworks.push({
        key: 'hrp',
        label: 'HRP',
      });
    }

    if (
      userHasPermission(
        idPermissionDownloadAreaArtworkMainDiecut,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsArtworks.push({
        key: 'mainDiecut',
        label: 'DIECUT',
      });
    }

    if (
      userHasPermission(
        idPermissionDownloadAreaArtworkCareLabelFoldedHorizontal,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsArtworks.push({
        key: 'careLabelFoldedHorizontal',
        label: 'Care Label - Folded Horizontal',
      });
    }

    if (
      userHasPermission(
        idPermissionDownloadAreaArtworkCareLabelFoldedVertical,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsArtworks.push({
        key: 'careLabelFoldedVertical',
        label: 'Care Label - Folded Vertical',
      });
    }

    if (
      userHasPermission(
        idPermissionDownloadAreaArtworkCareLabelSewedHorizontal,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsArtworks.push({
        key: 'careLabelSewedHorizontal',
        label: 'Care Label - Sewed Horizontal',
      });
    }

    if (
      userHasPermission(
        idPermissionDownloadAreaArtworkCareLabelSewedVertical,
        userPermissionsSat.userPermissions,
      )
    ) {
      itemsArtworks.push({
        key: 'careLabelSewedVertical',
        label: 'Care Label - Sewed Vertical',
      });
    }

    if (itemsDocs.length) {
      items.push({
        key: 'docs',
        label: 'Docs',
        icon: 'pi pi-fw pi-file',
        children: itemsDocs,
      });
    }

    if (itemsArtworks.length) {
      items.push({
        key: 'artworks',
        label: 'Artworks',
        icon: 'pi pi-fw pi-file',
        children: itemsArtworks,
      });
    }

    return items;
  }, [
    idPermissionExportSellerPi,
    userPermissionsSat.userPermissions,
    idPermissionExportSellerPiSimplified,
    idPermissionExportCotPI,
    idPermissionExportOd,
    idPermissionExportContract,
    idPermissionExportShippingMark,
    idPermissionExportRoundSticker,
    idPermissionExportSticker,
    idPermissionExportInnerSticker,
    idPermissionDownloadAreaArtworkHrp,
    idPermissionDownloadAreaArtworkMainDiecut,
    idPermissionDownloadAreaArtworkCareLabelFoldedHorizontal,
    idPermissionDownloadAreaArtworkCareLabelFoldedVertical,
    idPermissionDownloadAreaArtworkCareLabelSewedHorizontal,
    idPermissionDownloadAreaArtworkCareLabelSewedVertical,
  ]);

  const validateForms = useCallback(async () => {
    await generalInformationRef.current?.handleValidateGeneralInformationForm();
    await artworkRef.current?.handleValidateArtworkForm();
    await foreignTradeRef.current?.validateForm();
    await rncRef.current?.validateRncForm();
    await financialRef.current?.validateForm();
  }, []);

  async function checkForApiIssuesBeforeSave() {
    const foreignTradeIssues =
      await foreignTradeRef.current?.checkForApiIssuesBeforeSave();

    return foreignTradeIssues;
  }

  const handleSaveSat = useCallback(async () => {
    setPageLoading(true);
    const mutationsResponses: IMutationResponse[] = [];

    try {
      await validateForms();

      const issuesPending = await checkForApiIssuesBeforeSave();

      if (issuesPending) return;

      const generalInformationFormData =
        generalInformationRef.current?.handleUpdateGeneralInformation();

      const updateSatResponse = await updateSatMutation({
        variables: {
          data: {
            idSat: sat.idSat,
            versionLock: sat.versionLock,
            ...generalInformationFormData,
          },
        },
      });

      if (updateSatResponse.data?.updateSat?.warnings?.length) {
        mutationsResponses.push({
          moduleChanged: 'SAT',
          warnings: updateSatResponse.data.updateSat.warnings,
        });
      }

      const updateSatArtworkResponse =
        await artworkRef.current?.handleUpdateArtwork();

      if (updateSatArtworkResponse?.errors) {
        mutationsResponses.push({
          moduleChanged: 'SAT Artwork',
          errors: updateSatArtworkResponse?.errors,
        });
      }

      const updateSatItemsGridResponse =
        await satItemsGridRef.current?.handleUpdateItems();

      if (
        updateSatItemsGridResponse?.warnings?.length ||
        updateSatItemsGridResponse?.errors?.length
      ) {
        mutationsResponses.push({
          moduleChanged: satLateralMenuItems.satItems.label,
          warnings: updateSatItemsGridResponse?.warnings,
          errors: updateSatItemsGridResponse?.errors,
        });
      }

      const updateSatForeignTradeResponse =
        await foreignTradeRef.current?.submitForm();

      if (
        updateSatForeignTradeResponse?.warnings.length ||
        updateSatForeignTradeResponse?.errors.length
      ) {
        mutationsResponses.push({
          moduleChanged: satLateralMenuItems.foreignTrade.label,
          warnings: updateSatForeignTradeResponse?.warnings,
          errors: updateSatForeignTradeResponse?.errors,
        });
      }

      const updateSatFinancialResponse =
        await financialRef.current?.submitForm();

      if (
        updateSatFinancialResponse?.warnings.length ||
        updateSatFinancialResponse?.errors.length
      ) {
        mutationsResponses.push({
          moduleChanged: satLateralMenuItems.financial.label,
          warnings: updateSatFinancialResponse?.warnings,
          errors: updateSatFinancialResponse?.errors,
        });
      }

      const updateSatRncResponse = await rncRef.current?.handleUpdateRnc();

      if (
        updateSatRncResponse?.warnings.length ||
        updateSatRncResponse?.errors?.length
      ) {
        mutationsResponses.push({
          moduleChanged: 'RNC',
          warnings: updateSatRncResponse?.warnings,
          errors: updateSatRncResponse?.errors,
        });
      }

      await satRefetch();
      generalInformationRef.current?.resetSatCtnrs();
      artworkRef.current?.refetch();
      rncRef.current?.refetch();

      toastRef.current?.show({
        severity: 'success',
        summary: 'SAT Updated',
        life: ToastLife.SUCCESS,
      });
    } catch (error) {
      // Verifica se são erros de validação
      if (error instanceof ValidationError) {
        return;
      }

      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while updating SAT data',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    } finally {
      setPageLoading(false);
      if (mutationsResponses.length) {
        setUpdateSatMutationResponse(mutationsResponses);
        mutationResponseSummaryRef.current?.toggleDisplaySummary();
      }
    }
  }, [
    sat.idSat,
    sat.versionLock,
    satRefetch,
    toastRef,
    updateSatMutation,
    validateForms,
  ]);

  const showDialogOnSelectNoReport = () => {
    const tabsNoReportFieldWasSelected =
      foreignTradeRef.current?.getTabsWhereNoReportFieldWasChecked();

    if (tabsNoReportFieldWasSelected?.length) {
      confirmDialog({
        message: 'Inspection information will be deleted, confirm?',
        header: 'No Report Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          foreignTradeRef.current?.updateTabsWhereNoReportFieldWasChecked(
            tabsNoReportFieldWasSelected,
          );
          handleSaveSat();
        },
      });
    } else {
      handleSaveSat();
    }
  };

  async function handleShowDialogOnSaving() {
    const initialGeneralInformationFormData =
      generalInformationRef.current?.handleUpdateGeneralInformation();

    if (
      sat.idClient !== initialGeneralInformationFormData?.idClient &&
      sat.idSatGroup2
    ) {
      confirmDialog({
        message: `${sat.satNumber} will be removed from SAT Group, confirm?`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => showDialogOnSelectNoReport(),
      });
    } else {
      showDialogOnSelectNoReport();
    }
  }

  async function handleShowCancelConfirmation() {
    confirmDialog({
      message:
        'This option will cancel the Shipments, Foreign Trades and Artwork. Do you want to proceed?',
      header: `Canceling SAT`,
      icon: 'pi pi-question-circle',
      accept: () => {
        setIsVisibleCancelSatMenu(true);
      },
    });
  }

  const isSatCancelled =
    sat.status === SatStatus.CANCELED || sat.status === SatStatus.STAND_BY;

  const isSatFinished = sat.status === SatStatus.FINISHED;

  /**
   * Conclui a SAT
   * @param idSat ID da SAT
   */
  const handleFinishSat = useCallback(
    async (idSat: number) => {
      setPageLoading(true);
      try {
        await finishSatMutation({
          variables: {
            idSat,
          },
        });

        await satRefetch();

        toastRef.current?.show({
          severity: 'success',
          summary: 'Finshed successfully',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while finishing SAT',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [finishSatMutation, satRefetch, toastRef],
  );

  const functionsButtonItems = useMemo(() => {
    const items = [];

    items.push({
      label: 'Cancel',
      command: () => {
        handleShowCancelConfirmation();
      },
    });

    if (
      userHasPermission(
        idPermissionFinishSat,
        userPermissionsSat.userPermissions,
      ) &&
      !isSatFinished &&
      !isSatCancelled
    ) {
      items.push({
        label: 'Finished',
        command: () => {
          confirmDialog({
            message: 'This option will finish the SAT. Do you want to proceed?',
            header: 'Finish SAT',
            icon: 'pi pi-question-circle',
            accept: () => handleFinishSat(sat.idSat),
          });
        },
      });
    }

    items.push({
      label: 'Stand By',
      command: () => {
        setIsVisibleCancelSatStandByMenu(true);
      },
    });

    if (
      userHasPermission(
        idPermissionDuplicateSat,
        userPermissionsSat.userPermissions,
      )
    ) {
      items.push({
        label: 'Duplicate',
        command: () => {
          confirmDialog({
            message: 'Do you want to duplicate this SAT?',
            header: 'Duplicate SAT',
            icon: 'pi pi-question-circle',
            accept: () => handleDuplicateSat(sat.idSat),
          });
        },
      });
    }

    if (
      userHasPermission(
        idPermissionOpenRnc,
        userPermissionsSat.userPermissions,
      ) &&
      sat.confirmOrderComercial
    ) {
      if (!isSatCancelled) {
        items.push({
          label: 'Open RNC',
          command: () => {
            setIsVisibleOpenRncMenu(true);
          },
        });
      }
    }

    if (
      sat.confirmOrderComercial &&
      userHasPermission(
        satForeignTradeRoles.permissions.idPermissionCreateShipment,
        userPermissionsSat.userPermissions,
      )
    ) {
      if (!isSatCancelled && !isSatFinished) {
        items.push({
          label: 'Create Shipment',
          command: () => {
            addForeignTradeItemModalRef.current?.toggleModal();
          },
        });
      }
    }

    return items;
  }, [
    idPermissionFinishSat,
    userPermissionsSat.userPermissions,
    isSatFinished,
    idPermissionDuplicateSat,
    idPermissionOpenRnc,
    sat.confirmOrderComercial,
    sat.idSat,
    handleFinishSat,
    handleDuplicateSat,
    isSatCancelled,
  ]);

  const isStandByOutdated =
    satCancellationReasonStandBy?.expectedReactivationDate
      ? currentDate >
        new Date(
          satCancellationReasonStandBy?.expectedReactivationDate,
        ).getTime()
      : false;

  async function handleShowConfirmOrderSatGroupConfirmation() {
    // Valida se o usuario quer fazer o Confirm Order de todos os membros do SAT Group

    /**
     * Funcionalidade removida momentaneamente - ND-1803
     */
    if (sat.idSatGroup2) {
      confirmDialog({
        message: 'Do you want to confirm all SATs from the group?',
        header: 'SAT Confirm Order',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button',
        acceptLabel: 'All SATs',
        rejectClassName: 'p-button-text',
        rejectLabel: `Only ${sat.satNumber}`,
        accept: () => {
          setIsConfirmOrderWithGroup(true);
          setIsVisibleConfirmOrderMenu(true);
        },
        reject: () => {
          setIsConfirmOrderWithGroup(false);
          setIsVisibleConfirmOrderMenu(true);
        },
      });
    } else {
      setIsVisibleConfirmOrderMenu(true);
    }
  }

  async function handleShowConfirmOrderConfirmation() {
    if (generalInformationRef.current?.checkIfDataChanged()) {
      confirmDialog({
        message:
          'You have unsaved changes. This may generate confirm order with undesired information. Do you want to proceed without saving SAT?',
        header: 'Confirm Order',
        icon: 'pi pi-question-circle',
        accept: () => {
          handleShowConfirmOrderSatGroupConfirmation();
        },
      });
    } else {
      handleShowConfirmOrderSatGroupConfirmation();
    }
  }

  useEffect(() => {
    if (sat.status === SatStatus.STAND_BY && isStandByOutdated) {
      setIsVisibleCancelSatStandByMenu(true);
    }
  }, [
    sat.status,
    satCancellationReasonStandBy,
    isStandByOutdated,
    setIsVisibleCancelSatStandByMenu,
  ]);

  /**
   * Seleciona aba de acordo com o parametro da URL
   */
  useEffect(() => {
    if (urlTabParam) {
      setSelectedMenuItem(urlTabParam);
    }
  }, [urlTabParam]);

  /**
   * Carrega dados da SAT
   */
  useEffect(() => {
    loadSatData({ variables: { idSat: satId } });

    // Limpa dados da SAT ao desmontar o componente
    return () => setSat({} as ISat);
  }, [loadSatData, satId]);

  return (
    <Container>
      {isVisibleConfirmOrderMenu && (
        <ConfirmOrderMenu
          isVisibleConfirmOrderMenu={isVisibleConfirmOrderMenu}
          setIsVisibleConfirmOrderMenu={setIsVisibleConfirmOrderMenu}
          isVisibleRecipientsModal={isVisibleRecipientsModal}
          setIsVisibleRecipientsModal={setIsVisibleRecipientsModal}
          sat={sat}
          satRefetch={satRefetch}
          isConfirmOrderWithGroup={isConfirmOrderWithGroup}
          confirmOrderSummaryRef={confirmOrderSummaryRef}
          setConfirmOrderMutationResponse={setConfirmOrderMutationResponse}
        />
      )}
      {isVisibleCancelSatMenu && (
        <CancelSatMenu
          isVisibleCancelSatMenu={isVisibleCancelSatMenu}
          setIsVisibleCancelSatMenu={setIsVisibleCancelSatMenu}
          sat={sat}
          setLoading={setPageLoading}
          satRefetch={satRefetch}
        />
      )}
      {isVisibleCancelSatStandByMenu && !satCancellationReasonLoading && (
        <CancelSatStandByMenu
          isVisibleCancelSatStandByMenu={isVisibleCancelSatStandByMenu}
          setIsVisibleCancelSatStandByMenu={setIsVisibleCancelSatStandByMenu}
          sat={sat}
          setLoading={setPageLoading}
          satRefetch={satRefetch}
        />
      )}
      {isVisibleOpenRncMenu && (
        <OpenRncMenu
          isVisibleOpenRncMenu={isVisibleOpenRncMenu}
          setIsVisibleOpenRncMenu={setIsVisibleOpenRncMenu}
          sat={sat}
          setLoading={setPageLoading}
          pageLoading={pageLoading}
          setSelectedMenuItem={setSelectedMenuItem}
          rncRef={rncRef}
        />
      )}

      <PrimeMenu
        model={functionsButtonItems}
        popup
        ref={functionMenuRef}
        id="popup_menu"
      />
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          sat?.satNumber
            ? `${sat.satNumber}  ${
                sat.mainProduct ? `- ${sat.mainProduct}` : ''
              }`
            : 'SAT00000/00'
        }
        minScrollStickyButtons={160}
        hasImage
      >
        <Image
          className="image"
          src={
            sat.idClient2 && sat.idClient2?.idLogo2
              ? sat.idClient2?.idLogo2?.url
              : 'undefined'
          }
          alt="Product main image"
        />

        {sat.status && <SatStatusTag status={sat.status} />}

        <p className="createdAt">
          {sat.createdAt && !satLoading
            ? `${new Date(sat.createdAt).toLocaleString()} by ${
                sat.createdBy2?.firstName
              } ${sat.createdBy2?.lastName}`
            : ''}
        </p>
        <p className="updatedAt">
          {sat.updatedAt && !satLoading
            ? `${new Date(sat.updatedAt).toLocaleString()} by ${
                sat.updatedBy2?.firstName
              } ${sat.updatedBy2?.lastName}`
            : ''}
        </p>

        {sat.idSat && !isSatCancelled && (
          <MainButton
            className="mainButton"
            label="Save"
            onClick={() => {
              handleShowDialogOnSaving();
            }}
          />
        )}

        {!isSatCancelled && !isSatFinished && (
          <Button
            className="secondaryButton"
            label={isOrderConfirmed() ? 'Order Confirmed' : 'Confirm Order'}
            onClick={() => {
              handleShowConfirmOrderConfirmation();
            }}
            disabled={
              (!!sat.confirmOrderComercial &&
                !userHasSomeRole(
                  [Role.PURCHASE, Role.PURCHASE_KEY_USER],
                  roles.rolesUser,
                )) ||
              (!sat.confirmOrderComercial &&
                !userHasSomeRole(
                  [Role.COMMERCIAL, Role.COMMERCIAL_KEY_USER],
                  roles.rolesUser,
                )) ||
              isOrderConfirmed()
            }
          />
        )}

        {!!downloadOptions.length && (
          <CheckboxMenu
            className="secondaryButton"
            label="Download Area"
            options={downloadOptions}
            filter
            onGetSelectedItems={e =>
              downloadSatReports({
                variables: {
                  generateSatBulkDownloadsInput: {
                    idSat: sat.idSat,
                    itemsToDownload: e,
                  },
                },
              })
            }
            getSelectedOptionsLoading={downloadSatReportsInProgress}
          />
        )}

        {!!functionsButtonItems.length && (
          <Button
            className="secondaryButton"
            label="Functions"
            onClick={event => functionMenuRef.current?.toggle(event)}
          />
        )}

        {userHasPermission(
          idPermissionHistory,
          userPermissionsSat.userPermissions,
        ) && (
          <UpdateLogArchives
            className="history-button"
            header="SAT History"
            idUpdatedRegister={satId}
            tableUpdated="sat"
          />
        )}

        {userHasPermission(
          idPermissionDeleteSat,
          userPermissionsSat.userPermissions,
        ) && (
          <Button
            label="Delete"
            className="p-button-danger"
            severity="danger"
            onClick={() =>
              confirmDialog({
                message: 'Are you sure you want to delete this SAT?',
                header: 'Delete SAT',
                icon: 'pi pi-exclamation-triangle',
                acceptClassName: 'p-button-danger',
                accept: () => handleDeleteSat(),
              })
            }
          />
        )}
      </PageHeader>
      <MutationResponseSummary
        ref={mutationResponseSummaryRef}
        header="Update SAT Summary"
        data={updateSatMutationResponse}
      />

      <MutationResponseSummary
        ref={downloadFilesSummaryRef}
        header="Download Files Summary"
        data={downloadFilesResponse}
      />

      <MutationResponseSummary
        ref={confirmOrderSummaryRef}
        header="Confirm Order Summary"
        data={confirmOrderMutationResponse}
      />

      <AvailableSatItemsModal
        ref={addForeignTradeItemModalRef}
        foreignTradeRef={foreignTradeRef}
        idSat={sat.idSat}
        financialRef={financialRef}
        satOrderConfirmed={!!sat.confirmOrderComercial}
        generalInformationRef={generalInformationRef}
      />
      {sat.confirmOrderComercial && <HeaderStatus idSat={sat.idSat} />}
      {/* Formulario */}
      {!satLoading && sat?.idSat && (
        <CrudForm>
          <PageMenu
            menuFlexDirection="line"
            menuWidth="100%"
            setSelectedMenuItem={setSelectedMenuItem}
            selectedMenuItem={selectedMenuItem}
            items={menuItems}
            menuMarginBottom="10px"
            stickyMenuTopDistance="126px"
          />
          <GeneralInformation
            ref={generalInformationRef}
            selected={
              selectedMenuItem === satLateralMenuItems.generalInformation.key
            }
            sat={sat}
            setPageLoading={setPageLoading}
            setSelectedMenuItem={setSelectedMenuItem}
            pageMenuItemKey={satLateralMenuItems.generalInformation.key}
            fieldsPermissions={generalInformationFieldsPermissions}
            setSat={setSat}
            isSatCancelled={isSatCancelled || isSatFinished}
            satRefetch={satRefetch}
          />
          <Financial
            ref={financialRef}
            selected={selectedMenuItem === satLateralMenuItems.financial.key}
            sat={sat}
            setSelectedMenuItem={setSelectedMenuItem}
            pageMenuItemKey={satLateralMenuItems.financial.key}
            userPermissions={userPermissionsSat}
            commonWithSatFieldsPermissions={
              financialFieldsCommonWithSatFieldsPermissions
            }
            apportionmentRef={financialApportionmentRef}
          />
          <Suppliers
            selected={selectedMenuItem === satLateralMenuItems.suppliers.key}
            sat={sat}
            setPageLoading={setPageLoading}
            setSat={setSat}
            setShouldRefetchSatSuppliers={setShouldRefetchSatSuppliers}
            shouldRefetchSatSuppliers={shouldRefetchSatSuppliers}
            roleEntityFields={userPermissionsSatSuppliers.userFields}
            satRefetch={satRefetch}
          />
          {sat.confirmOrderComercial && (
            <Artwork
              ref={artworkRef}
              sat={sat}
              selected={selectedMenuItem === satLateralMenuItems.artwork.key}
              setPageLoading={setPageLoading}
              userPermissionsSat={userPermissionsSat}
            />
          )}
          <ForeignTrade
            ref={foreignTradeRef}
            selected={selectedMenuItem === satLateralMenuItems.foreignTrade.key}
            sat={sat}
            pageMenuItemKey={satLateralMenuItems.foreignTrade.key}
            setSelectedMenuItem={setSelectedMenuItem}
            userPermissions={userPermissionsSat}
            commonWithSatFieldsPermissions={
              foreignTradeFieldsCommonWithSatFieldsPermissions
            }
            isSatCancelled={isSatCancelled || isSatFinished}
            financialRef={financialRef}
            handleSaveSat={handleSaveSat}
            generalInformationRef={generalInformationRef}
            satItemsGridRef={satItemsGridRef}
          />
          <Rnc
            ref={rncRef}
            selected={selectedMenuItem === satLateralMenuItems.rnc.key}
            sat={sat}
            userPermissions={userPermissionsSat}
            pageMenuItemKey={satLateralMenuItems.rnc.key}
            setSelectedMenuItem={setSelectedMenuItem}
          />
          <SatItems
            selected={selectedMenuItem === satLateralMenuItems.satItems.key}
            setPageLoading={setPageLoading}
            sat={sat}
            userPermissionsSat={userPermissionsSat}
            orderAreaFieldsPermissions={satItemsOrderAreaFieldsPermissions}
            isSatCancelled={isSatCancelled || isSatFinished}
            itemsGrid={
              <ItemsGrid
                ref={satItemsGridRef}
                selected={selectedMenuItem === satLateralMenuItems.satItems.key}
                setPageLoading={setPageLoading}
                sat={sat}
                setShouldRefetchSatSuppliers={setShouldRefetchSatSuppliers}
                roleEntityPermissions={userPermissionsSat.userPermissions}
                satRefetch={satRefetch}
                isSatCancelled={isSatCancelled || isSatFinished}
                cleanSat={cleanSat}
                foreignTradeRef={foreignTradeRef}
                updateSatVersionLock={updateSatVersionLock}
              />
            }
          />
          {userCanSeeTabUpdates && (
            <Updates
              selected={selectedMenuItem === satLateralMenuItems.updates.key}
              idSat={sat.idSat}
              displayFollowUp={userCanSeeFollowUp}
              displayUpdatesContent={userCanSeeUpdatesContent}
              roleEntityPermissions={userPermissionsSat.userPermissions}
              confirmOrderCommercialDone={!!sat.confirmOrderComercial}
            />
          )}

          <FinancialApportionment
            idSat={sat.idSat}
            ref={financialApportionmentRef}
            selected={selectedMenuItem === financialApportionmentTabKey}
            pageMenuItemKey={financialApportionmentTabKey}
            setSelectedMenuItem={setSelectedMenuItem}
            userPermissions={userPermissionsSat}
          />
          <Status
            selected={selectedMenuItem === satLateralMenuItems.status.key}
            sat={sat}
          />
        </CrudForm>
      )}
      {(pageLoading || satLoading || satCancellationReasonLoading) && (
        <Loading />
      )}
    </Container>
  );
};
export default Sat;
