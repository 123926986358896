import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import { gql } from '@apollo/client';
import client from '../../services/apollo/client';

/**
 * Carrega opcoes de Countries
 * @param search Valor escrito no input
 * @param prevOptions Opcoes carregadas anteriormente
 * @param pageData Dados da pagina
 * @returns Dados de Country
 */
export const asyncSelectLoadCountries: LoadOptions<
  any,
  GroupBase<any>,
  { page: any }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listAllCountriesPaginated($pagination: Pagination!, $name: String) {
        listAllCountriesPaginated(pagination: $pagination, name: $name) {
          data {
            idCountry
            name
          }
          items
        }
      }
    `,
    variables: {
      pagination: {
        _page: pageData?.page,
        _limit: 25,
      },
      name: search,
    },
  });

  // Opcoes filtradas
  const filteredOptions = res.data.listAllCountriesPaginated.data;
  // Quantidade de itens
  const quantity = res.data.listAllCountriesPaginated.items;
  // Valida se ha mais resultados para serem buscados
  const hasMore = quantity > prevOptions.length + 25;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
    },
  };
};
