/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import NumberFormat, {
  NumberFormatProps,
  NumberFormatState,
} from 'react-number-format';
import { Container } from './styles';
import { getCurrencySymbol } from '../../utils/getCurrencySymbol';
import isNullOrUndefined from '../../utils/isNullOrUndefined';

interface FormInputNumberProps extends NumberFormatProps {
  name: string;
  label: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  currency?: string;
  max?: number;
}

const FormInputNumber: React.FC<FormInputNumberProps> = ({
  name,
  required,
  label,
  className,
  disabled,
  onBlur,
  currency,
  prefix,
  decimalSeparator,
  max,
  onValueChange,
  readOnly,
  hidden,
  type,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const instanceRef = useRef<NumberFormat<any>>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [inputValue, setInputValue] = useState<string | number | null>(
    defaultValue || null,
  );

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  /**
   * Define valor maximo para o input
   * @returns
   */
  const withValueCap = (inputObj: any) => {
    const { value } = inputObj;
    if (value <= (max ?? 99999999)) return true;
    return false;
  };

  const getNumAsString = ({ numAsString }: NumberFormatState) => {
    if (numAsString === undefined) return '';
    if (Number.isNaN(parseFloat(numAsString))) return '';
    return numAsString;
  };

  /**
   * Busca objeto com valores do Input
   */
  const getValueObjectFromNumberFormatComponentInstance = useCallback(
    (instance: NumberFormat<any>) => {
      const numAsString = getNumAsString(instance.state);
      const formattedValue: string = (instance as any).formatNumString(
        numAsString,
      );
      return (instance as any).getValueObject(formattedValue, numAsString);
    },
    [],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        // Se nao houver referencia, retorna nulo
        if (!instanceRef.current) {
          return null;
        }

        // Busca valor do input em numero
        const value = getValueObjectFromNumberFormatComponentInstance(
          instanceRef.current,
        );

        if (isNullOrUndefined(value.floatValue)) return null;

        if (type === 'text') return value.formattedValue;

        return value.floatValue;
      },
      setValue: (_, value: number | undefined | null) => {
        setInputValue(value === undefined || value === null ? '0' : value);
      },
      clearValue: () => {
        setInputValue(null);
      },
    });
  }, [
    decimalSeparator,
    fieldName,
    getValueObjectFromNumberFormatComponentInstance,
    registerField,
    type,
  ]);

  return (
    <div
      className={`${
        className
          ? `${className} form-element formInputNumber`
          : 'form-element formInputNumber'
      }`}
    >
      <Container
        hasError={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        required={required}
        onClick={handleClick}
        disabled={disabled}
        readOnly={readOnly}
        hidden={hidden}
      >
        <span>
          {error && <FiAlertCircle color="#c53030" size={15} />}
          <p>{label}:</p>
        </span>

        <NumberFormat
          ref={instanceRef}
          getInputRef={inputRef}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
            const inputPageHeight = e.target.getBoundingClientRect().top + 60;
            const pageHeightTop = window.innerHeight;
            e.target.scrollIntoView({
              behavior: 'smooth',
              block: inputPageHeight > pageHeightTop ? 'center' : 'nearest',
              inline: 'nearest',
            });
            handleInputFocus();
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (onBlur) onBlur(e);
            handleInputBlur();
          }}
          defaultValue={defaultValue}
          disabled={disabled}
          prefix={
            currency
              ? `${getCurrencySymbol(currency)} `
              : prefix
              ? `${prefix} `
              : undefined
          }
          decimalSeparator={decimalSeparator}
          onValueChange={(values, event) => {
            if (onValueChange) onValueChange(values, event);
            setInputValue(values.formattedValue);
          }}
          value={inputValue}
          fixedDecimalScale
          isAllowed={withValueCap}
          readOnly={readOnly}
          type={type}
          {...rest}
        />
      </Container>
      {error && (
        <small id="username2-help" className="p-error p-d-block">
          {error}
        </small>
      )}
    </div>
  );
};

export default FormInputNumber;
