import React from 'react';

import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import {
  SatFinancialResultsPermissions,
  SatForeignTradeSupplier,
  UserCanResultsBlock,
} from '../../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';
import { ForeignTradeSupplierContentReducer } from '../interfaces';
import { divide, subtract } from '../../../../../../utils/mathUtil';
import { ResultsContainer } from '../../styles';

interface IResultsProps {
  satForeignTradeSupplier: SatForeignTradeSupplier;
  satCurrencyAbbreviation?: string;
  contentState: ForeignTradeSupplierContentReducer;
  fieldsPermissions: SatFinancialResultsPermissions;
  userCan: UserCanResultsBlock;
}

const Results: React.FC<IResultsProps> = ({
  satForeignTradeSupplier,
  satCurrencyAbbreviation,
  contentState,
  fieldsPermissions,
  userCan,
}) => {
  const satCurrencySymbol = getCurrencySymbol(satCurrencyAbbreviation);

  const usdSymbol = getCurrencySymbol('USD');

  const {
    convertedFieldsPermissions,
    mainFieldsPermissions,
    grossFieldsPermissions,
    otherExpensesFieldsPermissions,
    netFieldsPermissions,
    statusFieldsPermissions,
  } = fieldsPermissions;

  const userCanSeeCot =
    mainFieldsPermissions.totalCotPiShipment.view ||
    mainFieldsPermissions.totalCotCiShipment.view;

  const userCanSeeSun =
    mainFieldsPermissions.totalSunPiShipment.view ||
    mainFieldsPermissions.totalSunCiShipment.view ||
    mainFieldsPermissions.totalWithApportionment.view;

  const userCanSeeConvertedCot =
    convertedFieldsPermissions.totalConvertedCotPi.view ||
    convertedFieldsPermissions.totalConvertedCotCi.view;

  const userCanSeeConvertedSun =
    convertedFieldsPermissions.totalConvertedSunPi.view ||
    convertedFieldsPermissions.totalConvertedSunCi.view;

  const userCanSeeOtherExpensesCot =
    otherExpensesFieldsPermissions.purchaseTotalOtherExpenses.view ||
    otherExpensesFieldsPermissions.purchaseTotalOnMargin.view ||
    otherExpensesFieldsPermissions.purchaseTotalOutOfMargin.view;

  const userCanSeeOtherExpensesSun =
    otherExpensesFieldsPermissions.sellerTotalOtherExpenses.view ||
    otherExpensesFieldsPermissions.sellerTotalOnMargin.view ||
    otherExpensesFieldsPermissions.sellerTotalOutOfMargin.view ||
    otherExpensesFieldsPermissions.sellerTotalForApportionment.view;

  function convertValueToUsd(value?: number) {
    if (!value || !satForeignTradeSupplier.exchangeRate) return undefined;

    const cotTotalConverted = value * satForeignTradeSupplier.exchangeRate;

    return cotTotalConverted;
  }

  function grossDivision(dividend?: number, divisor?: number) {
    const simpleDivision = divide(dividend, divisor);

    if (simpleDivision !== undefined) {
      return (simpleDivision - 1) * 100;
    }

    return undefined;
  }

  return (
    <ResultsContainer>
      {userCan.userCanSeeResultsStatusBlock && <FormTitle name="Status" />}

      <div className="p-grid">
        {statusFieldsPermissions.shipmentStatus.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="Shipment Status"
            value={satForeignTradeSupplier.idSatForeignTrade2?.shipmentStatus}
            type="text"
          />
        )}
      </div>

      {userCan.userCanSeeResultsBlock && (
        <FormTitle className="results-title" name="Results" />
      )}

      {userCanSeeCot && (
        <div className="p-grid p-align-center">
          <span className="p-col-1 results-cot-item">COT:</span>
          {mainFieldsPermissions.totalCotPiShipment.view && (
            <ReadOnlyInput
              className="p-col-2"
              label="Total COT PI"
              value={satForeignTradeSupplier.totalCotPiShipment}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              prefix={satCurrencySymbol}
            />
          )}

          {mainFieldsPermissions.totalCotCiShipment.view && (
            <ReadOnlyInput
              className="p-col-2"
              label="Total COT CI"
              value={satForeignTradeSupplier.totalCotCiShipment}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              prefix={satCurrencySymbol}
            />
          )}
        </div>
      )}

      {userCanSeeSun && (
        <div className="p-grid p-align-center">
          <span className="p-col-1 results-sun-item">SUN:</span>
          {mainFieldsPermissions.totalSunPiShipment.view && (
            <ReadOnlyInput
              className="p-col-2"
              label="Total SUN PI"
              value={satForeignTradeSupplier.totalSunPiShipment}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              prefix={satCurrencySymbol}
            />
          )}

          {mainFieldsPermissions.totalSunCiShipment.view && (
            <ReadOnlyInput
              className="p-col-2"
              label="Total SUN CI"
              value={satForeignTradeSupplier.totalSunCiShipment}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              prefix={satCurrencySymbol}
            />
          )}

          {mainFieldsPermissions.totalWithApportionment.view && (
            <ReadOnlyInput
              className="p-col-4"
              label="Total with Apportionment"
              value={contentState.totalWithApportionment}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              prefix={satCurrencySymbol}
              loading={contentState.sellerOtherExpensesTotalLoading}
            />
          )}
        </div>
      )}

      {userCan.userCanSeeResultsConvertedBlock && (
        <FormTitle name="Converted" />
      )}

      {userCanSeeConvertedCot && (
        <div className="p-grid p-align-center">
          <span className="p-col-1 results-cot-item">COT:</span>
          {convertedFieldsPermissions.totalConvertedCotPi.view && (
            <ReadOnlyInput
              className="p-col-4"
              label="Total Converted COT PI (USD)"
              prefix={usdSymbol}
              value={convertValueToUsd(
                satForeignTradeSupplier.totalCotPiShipment,
              )}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
            />
          )}

          {convertedFieldsPermissions.totalConvertedCotCi.view && (
            <ReadOnlyInput
              className="p-col-4"
              label="Total Converted COT CI (USD)"
              prefix={usdSymbol}
              value={convertValueToUsd(
                satForeignTradeSupplier.totalCotCiShipment,
              )}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
            />
          )}
        </div>
      )}

      {userCanSeeConvertedSun && (
        <div className="p-grid p-align-center">
          <span className="p-col-1 results-sun-item">SUN:</span>
          {convertedFieldsPermissions.totalConvertedSunPi.view && (
            <ReadOnlyInput
              className="p-col-4"
              label="Total Converted SUN PI (USD)"
              prefix={usdSymbol}
              value={convertValueToUsd(
                satForeignTradeSupplier.totalSunPiShipment,
              )}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
            />
          )}

          {convertedFieldsPermissions.totalConvertedSunCi.view && (
            <ReadOnlyInput
              className="p-col-4"
              label="Total Converted SUN CI (USD)"
              prefix={usdSymbol}
              value={convertValueToUsd(
                satForeignTradeSupplier.totalSunCiShipment,
              )}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
            />
          )}
        </div>
      )}

      {userCan.userCanSeeResultsGrossBlock && <FormTitle name="Gross" />}

      <div className="p-grid">
        {grossFieldsPermissions.estimatedGrossMargin.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="Estimated Gross Margin"
            suffix="%"
            value={grossDivision(
              satForeignTradeSupplier.totalSunPiShipment,
              satForeignTradeSupplier.totalCotPiShipment,
            )}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        )}

        {grossFieldsPermissions.grossMargin.view && (
          <ReadOnlyInput
            className="p-col-4"
            label="Gross Margin"
            suffix="%"
            value={grossDivision(
              satForeignTradeSupplier.totalSunCiShipment,
              satForeignTradeSupplier.totalCotCiShipment,
            )}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        )}
      </div>

      <div className="p-grid">
        {grossFieldsPermissions.estimatedGrossProfit.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="Estimated Gross Profit"
            prefix={satCurrencySymbol}
            value={subtract(
              satForeignTradeSupplier.totalSunPiShipment,
              satForeignTradeSupplier.totalCotPiShipment,
            )}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
          />
        )}

        {grossFieldsPermissions.grossProfit.view && (
          <ReadOnlyInput
            className="p-col-4"
            label="Gross Profit"
            prefix={satCurrencySymbol}
            value={subtract(
              satForeignTradeSupplier.totalSunCiShipment,
              satForeignTradeSupplier.totalCotCiShipment,
            )}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
          />
        )}
      </div>

      {userCan.userCanSeeResultsOtherExpensesBlock && (
        <FormTitle name="Other Expenses" />
      )}

      <div className="p-grid p-align-center">
        {userCanSeeOtherExpensesCot && (
          <span className="p-col-1 results-cot-item">COT:</span>
        )}

        {otherExpensesFieldsPermissions.purchaseTotalOtherExpenses.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="Total Other Expenses"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={contentState?.purchaseOtherExpensesTotal?.totalOtherExpenses}
            loading={contentState?.purchaseOtherExpensesTotalLoading}
          />
        )}

        {otherExpensesFieldsPermissions.purchaseTotalOnMargin.view && (
          <ReadOnlyInput
            className="p-col-2"
            label="Total On Margin"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={contentState?.purchaseOtherExpensesTotal?.totalOnMargin}
            loading={contentState?.purchaseOtherExpensesTotalLoading}
          />
        )}

        {otherExpensesFieldsPermissions.purchaseTotalOutOfMargin.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="Total Out Of Margin"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={contentState?.purchaseOtherExpensesTotal?.totalOutOfMargin}
            loading={contentState?.purchaseOtherExpensesTotalLoading}
          />
        )}
      </div>

      <div className="p-grid p-align-center">
        {userCanSeeOtherExpensesSun && (
          <span className="p-col-1 results-sun-item">SUN:</span>
        )}

        {otherExpensesFieldsPermissions.sellerTotalOtherExpenses.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="Total Other Expenses"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={contentState?.sellerOtherExpensesTotal?.totalOtherExpenses}
            loading={contentState?.sellerOtherExpensesTotalLoading}
          />
        )}

        {otherExpensesFieldsPermissions.sellerTotalOnMargin.view && (
          <ReadOnlyInput
            className="p-col-2"
            label="Total On Margin"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={contentState?.sellerOtherExpensesTotal?.totalOnMargin}
            loading={contentState?.sellerOtherExpensesTotalLoading}
          />
        )}

        {otherExpensesFieldsPermissions.sellerTotalOutOfMargin.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="Total Out Of Margin"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={contentState?.sellerOtherExpensesTotal?.totalOutOfMargin}
            loading={contentState?.sellerOtherExpensesTotalLoading}
          />
        )}

        {otherExpensesFieldsPermissions.sellerTotalForApportionment.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="Total For Apportionment"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={4}
            minimumFractionDigits={4}
            value={
              contentState?.sellerOtherExpensesTotal?.totalForApportionment
            }
            loading={contentState?.sellerOtherExpensesTotalLoading}
          />
        )}
      </div>

      {userCan.userCanSeeResultsNetBlock && <FormTitle name="NET" />}

      <div className="p-grid">
        {netFieldsPermissions.estimatedNetMargin.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="Estimated NET Margin"
            type="number"
            locale="pt-BR"
            suffix="%"
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={contentState.estimatedNetMargin}
            loading={
              contentState.sellerOtherExpensesTotalLoading ||
              contentState.purchaseOtherExpensesTotalLoading
            }
          />
        )}

        {netFieldsPermissions.netMargin.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="NET Margin"
            type="number"
            locale="pt-BR"
            suffix="%"
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={contentState.netMargin}
            loading={
              contentState.sellerOtherExpensesTotalLoading ||
              contentState.purchaseOtherExpensesTotalLoading
            }
          />
        )}
      </div>

      <div className="p-grid">
        {netFieldsPermissions.estimatedNetProfit.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="Estimated NET Profit"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={contentState.estimatedNetProfit}
            loading={
              contentState.sellerOtherExpensesTotalLoading ||
              contentState.purchaseOtherExpensesTotalLoading
            }
          />
        )}

        {netFieldsPermissions.netProfit.view && (
          <ReadOnlyInput
            className="p-col-3"
            label="NET Profit"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={contentState.netProfit}
            loading={
              contentState.sellerOtherExpensesTotalLoading ||
              contentState.purchaseOtherExpensesTotalLoading
            }
          />
        )}
      </div>
    </ResultsContainer>
  );
};

export default Results;
