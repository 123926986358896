/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTableMultiSortMetaType,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortParams,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import { confirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import xlsx from 'xlsx';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import { gridConstants } from '../../../components/Grid/constants';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import StatusTag from '../../../components/StatusTag';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import useTitle from '../../../hooks/useTitle';
import ILazyParams from '../../../services/lazyParams';
import ToastLife from '../../../shared/enums/toastLife';
import {
  createClientQuotation,
  createClientQuotationQuery,
} from '../../../shared/mutations/clientQuotation';
import { clientQuotationRoles } from '../../../shared/roles/clientQuotation';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import {
  parseDateColumm,
  parseDateTimeColumm,
  parseFormatedTextColumn,
  parseNullableBooleanColumn,
  parsePrcCodeColumn,
  parseUserFirstAndLastNameColumm,
} from '../../../utils/gridColumnsParse';
import saveFileAsExcel from '../../../utils/saveFileAsExcel';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';
import { gridColumnsData } from './constants';
import { Container } from './styles';
import { parseToLocaleFormat } from '../../../utils/dateUtil';

/**
 * Interface dos parametros do backend
 */
interface IPrcsLazyParams extends ILazyParams {
  [key: string]:
    | number
    | string
    | Date
    | boolean
    | DataTableMultiSortMetaType
    | undefined;
  prcCode?: string;
  status?: string;
  prcType?: string;
  mainProduct?: string;
  createdAt?: Date;
  finishDate?: string;
  responsibleUser?: string;
  priority?: string;
  dtDeadline?: Date;
  category?: string;
  client?: string;
  satOffice?: string;
  indLicensed?: boolean;
  partnerLicensor?: string;
  notes?: string;
  createdBy?: string;
  approvedBy?: string;
}

const pageTitle = 'List of PRCs';

const Prcs: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:prcsGridColumns';

  const lazyParamsName = '@SAT:prcListLazyParams';

  const advancedFiltersName = '@SAT:prcListAdvancedFilters';

  // Redirect
  const history = useHistory();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia a grid
  const gridRef = useRef<DataTable>(null);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Roles do usuario
  const { roles } = useAuth();

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Busca permissoes do usuario para a entity de Client Quotation
  const userPermissionsClientQuotation = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    clientQuotationRoles.idEntity,
  );

  // Permissions de Client Quotation
  const { idPermissionCreateClientQuotation } =
    clientQuotationRoles.permissions;

  // URL params
  const location = useLocation();

  // Colunas da grid
  const columns = useMemo(() => {
    return (
      Object.entries(gridColumnsData)
        // .filter(column => fieldsPermissions[column[1].field].view)
        .map(column => column[1])
    );
  }, []);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  // Busca dados de pagina inicial da url
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );

  // Busca dados de quantidade da url
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  // Estado inicial de lazy params
  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IPrcsLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: IPrcsLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: IPrcsLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  // PRCs selecionadas
  const [selectedPrcs, setSelectedPrcs] = useState([]);

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  // Estado de scrollHeight da grid
  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar PRCs
  const listPrcsQuery = gql`
    query listAllPrcsQuery($data: ListAllPrcsInput!) {
      listAllPrcs(data: $data) {
        items
        data {
          idPrc
          prcCode
          idStatus2 {
            idDomain
            description
          }
          idPrcType2 {
            description
          }
          mainProduct
          createdAt
          finishDate
          idResponsibleUser2 {
            firstName
            lastName
          }
          idPriority2 {
            description
          }
          dtDeadline
          idCategory2 {
            name
          }
          idClient2 {
            name
          }
          prcOffices {
            idOffice2 {
              officeName
            }
          }
          indLicensed
          idPartnerLicensor2 {
            name
          }
          notes
          createdBy2 {
            firstName
            lastName
          }
          approvedBy2 {
            firstName
            lastName
          }
        }
      }
    }
  `;

  // Query para deletar ICMS
  const disablePrcsQuery = gql`
    mutation DisablePrcs($idsPrcs: [Int]!) {
      disablePrcs(idsPrcs: $idsPrcs)
    }
  `;

  // cria método para chamar a mutation
  const [disablePrcsMutation] = useMutation(disablePrcsQuery);
  const [createClientQuotationMutation] = useMutation(
    createClientQuotationQuery,
  );

  // Variaveis comuns para query de PRC
  const prcQueryCommonVariables = {
    globalSearch: lazyParams.globalFilter,
    prcCode: lazyParams.prcCode,
    status: lazyParams.status,
    prcType: lazyParams.prcType,
    mainProduct: lazyParams.mainProduct,
    createdAt: lazyParams.createdAt,
    finishDate: lazyParams.finishDate,
    priority: lazyParams.priority,
    responsibleUser: lazyParams.responsibleUser,
    dtDeadline: lazyParams.dtDeadline,
    category: lazyParams.category,
    client: lazyParams.client,
    satOffice: lazyParams.satOffice,
    indLicensed: lazyParams.indLicensed,
    partnerLicensor: lazyParams.partnerLicensor,
    notes: lazyParams.notes,
    createdBy: lazyParams.createdBy,
    approvedBy: lazyParams.approvedBy,
    active: true,
  };

  /**
   * Busca PRCs
   */
  const {
    loading: prcsLoading,
    data: prcsData,
    refetch: prcsRefetch,
  } = useQuery(listPrcsQuery, {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        ...prcQueryCommonVariables,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting PRCs',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Retorna string de escritorios
   * @param rowData Dados da Coluna
   * @returns String de escritorios
   */
  function parseOfficesColumn(rowData: any) {
    return (
      <p>
        {rowData.prcOffices
          .map((office: any) => {
            return office.idOffice2.officeName;
          })
          .join(', ')}
      </p>
    );
  }

  function parseStatusTagColumn(rowData: any) {
    return (
      <StatusTag
        className="status-tag"
        domain={rowData.idStatus2.idDomain}
        status={rowData.idStatus2.description}
      />
    );
  }

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'dtDeadline':
        return parseDateTimeColumm;
      case 'idResponsibleUser2.firstName':
      case 'createdBy2.firstName':
      case 'approvedBy2.firstName':
        return parseUserFirstAndLastNameColumm;
      case 'idOffice2.officeName':
        return parseOfficesColumn;
      case 'indLicensed':
        return parseNullableBooleanColumn;
      case 'idStatus2.description':
        return parseStatusTagColumn;
      case 'prcCode':
        return parsePrcCodeColumn;
      case 'notes':
        return parseFormatedTextColumn;
      case 'createdAt':
      case 'finishDate':
        return parseDateColumm;
      default:
        return undefined;
    }
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'mainProduct':
        return { width: '300px' };
      case 'idResponsibleUser2.firstName':
      case 'idCategory2.name':
      case 'idClient2.name':
      case 'idPartnerLicensor2.name':
      case 'createdBy2.firstName':
      case 'approvedBy2.firstName':
      case 'idStatus2.description':
        return { width: '200px' };
      case 'notes':
        return { width: '500px' };
      default:
        return {
          width: '140px',
        };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          body={handleColumn(col.field)}
          sortable
        />
      )
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeParams) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do icms clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(
      `/prcs/list/${e.data.idPrc}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortParams) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Deleta PRCs selecionadas
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedPrcs.length} PRCs?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada PRC
        const idsToDelete = selectedPrcs.map((a: any) => a.idPrc);
        try {
          // Deleta PRCs
          await disablePrcsMutation({
            variables: {
              idsPrcs: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedPrcs.length} PRCs`,
            life: ToastLife.SUCCESS,
          });

          // Zera estado de PRCs selecionadas
          setSelectedPrcs([]);

          // Atualiza grid de ICMS
          await prcsRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting PRCs',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Exporta dados para xlsx
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function exportToXlsx(prcsExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    prcsExportData.listAllPrcs.data.forEach((prc: any) => {
      prc.status = prc.idStatus2?.description;
      prc.prcType = prc.idPrcType2?.description;
      prc.idResponsibleUser = prc.idResponsibleUser2
        ? `${prc.idResponsibleUser2?.firstName} ${prc.idResponsibleUser2?.lastName}`
        : undefined;
      prc.priority = prc.idPriority2?.description;
      prc.dtDeadline =
        prc.dtDeadline && new Date(prc.dtDeadline)?.toLocaleString();
      prc.category = prc.idCategory2?.name;
      prc.client = prc.idClient2?.name;
      prc.indLicensed = prc.indLicensed ? 'Yes' : 'No';
      prc.licensor = prc.idPartnerLicensor2?.name;
      prc.createdBy = prc.createdBy2
        ? `${prc.createdBy2?.firstName} ${prc.createdBy2?.lastName}`
        : undefined;
      prc.approvedBy = prc.approvedBy2
        ? `${prc.approvedBy2?.firstName} ${prc.approvedBy2?.lastName}`
        : undefined;
      prc.offices = prc.prcOffices
        .map((office: any) => {
          return office.idOffice2.officeName;
        })
        .join(', ');
      prc.createdAt =
        prc.createdAt && new Date(prc.createdAt)?.toLocaleString();
      prc.finishDate = prc.finishDate && parseToLocaleFormat(prc.finishDate);

      columnsToRemove.forEach(column => {
        switch (column.field) {
          case 'idStatus2.description':
            delete prc.status;
            break;
          case 'idPrcType2.description':
            delete prc.prcType;
            break;
          case 'idResponsibleUser2.firstName':
            delete prc.idResponsibleUser;
            break;
          case 'idPriority2.description':
            delete prc.priority;
            break;
          case 'idCategory2.name':
            delete prc.category;
            break;
          case 'idClient2.name':
            delete prc.client;
            break;
          case 'idPartnerLicensor2.name':
            delete prc.licensor;
            break;
          case 'createdBy2.firstName':
            delete prc.createdBy;
            break;
          case 'approvedBy2.firstName':
            delete prc.approvedBy;
            break;
          case 'idOffice2.officeName':
            delete prc.offices;
            break;
          default:
            delete prc[column.field];
            break;
        }
      });

      // Remover colunas __typename e idPrc
      delete prc.__typename;
      delete prc.idPrc;
      delete prc.idStatus2;
      delete prc.idPrcType2;
      delete prc.idResponsibleUser2;
      delete prc.idPriority2;
      delete prc.idCategory2;
      delete prc.idClient2;
      delete prc.idPartnerLicensor2;
      delete prc.createdBy2;
      delete prc.approvedBy2;
      delete prc.prcOffices;
    });

    // Busca ordenacao das colunas da grid
    const gridColumns =
      gridRef.current?.state.columnOrder ??
      selectedColumns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: any) =>
        item !== 'idPrc' &&
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'idStatus2.description':
          columnsOrder[index] = 'status';
          break;
        case 'idPrcType2.description':
          columnsOrder[index] = 'prcType';
          break;
        case 'idResponsibleUser2.firstName':
          columnsOrder[index] = 'currentUser';
          break;
        case 'idPriority2.description':
          columnsOrder[index] = 'priority';
          break;
        case 'idCategory2.name':
          columnsOrder[index] = 'category';
          break;
        case 'idClient2.name':
          columnsOrder[index] = 'client';
          break;
        case 'idPartnerLicensor2.name':
          columnsOrder[index] = 'licensor';
          break;
        case 'createdBy2.firstName':
          columnsOrder[index] = 'createdBy';
          break;
        case 'approvedBy2.firstName':
          columnsOrder[index] = 'approvedBy';
          break;
        case 'idOffice2.officeName':
          columnsOrder[index] = 'offices';
          break;
        default:
          break;
      }
    });

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(
      prcsExportData.listAllPrcs.data,
      { header: columnsOrder },
    );
    worksheet['!autofilter'] = { ref: 'A1:F1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveFileAsExcel(excelBuffer, 'prcs');
  }

  /**
   * Busca PRCs para exportar para XLSX
   */
  const [
    loadPrcsToExport,
    { loading: prcsToExportLoading, data: prcsToExportData },
  ] = useLazyQuery(listPrcsQuery, {
    onCompleted: () => {
      exportToXlsx(prcsToExportData);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while exporting PRCs',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Cria Client Quotation com as PRCs selecionadas
   */
  function handleCreateClientQuotationWithSelectedPrcs() {
    confirmDialog({
      message: `You have selected (${selectedPrcs.length}) records. Confirm create CQ?`,
      header: 'Create CQ confirmation',
      icon: 'pi pi-info-circle',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada PRC
        const idsToCreate = selectedPrcs.map((a: any) => a.idPrc);
        try {
          // Cria Client Quotation
          const response = await createClientQuotation(
            createClientQuotationMutation,
            idsToCreate,
          );

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'CQ Created',
            detail: (
              <>
                <p>PRCs products added in the new CQ: </p>
                <Link
                  to={`/client-quotations/list/${response.data.createClientQuotation.idCq}`}
                  className="transparent-link"
                >
                  {response.data.createClientQuotation.cqNumber}
                </Link>
              </>
            ),
            life: ToastLife.SUCCESS,
          });

          // Zera estado de Prcs selecionadas
          setSelectedPrcs([]);
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating CQ',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <Container>
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar PRC */}

        <MainButton
          className="mainButton"
          label="New PRC"
          onClick={() => history.push('/prcs/list/create')}
        />
        {userHasPermission(
          idPermissionCreateClientQuotation,
          userPermissionsClientQuotation.userPermissions,
        ) && (
          <Button
            className="secondaryButton"
            label="Create CQ"
            onClick={() => {
              handleCreateClientQuotationWithSelectedPrcs();
            }}
            disabled={!selectedPrcs.length}
          />
        )}

        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Botao para excluir PRCs selecionados */}
        <Button
          label="Delete selected"
          className="p-button-danger"
          severity="danger"
          disabled={selectedPrcs.length === 0}
          onClick={handleDeleteSelected}
        />

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {/* Botao export para XLSX */}
        <Button
          type="button"
          className="export-xlsx"
          label="Export Grid"
          loading={prcsToExportLoading}
          onClick={() => {
            loadPrcsToExport({
              variables: {
                data: {
                  pagination: {
                    _page: 0,
                    _limit: 0,
                    _orderBy: lazyParams.sortField,
                    _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                  },
                  ...prcQueryCommonVariables,
                },
              },
            });
          }}
        />

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a PRC"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        className="grid"
        name="prcs"
        lazy
        totalRecords={!prcsData ? 0 : prcsData.listAllPrcs?.items}
        value={!prcsData ? undefined : prcsData.listAllPrcs?.data}
        globalFilter={globalFilter}
        emptyMessage="No PRCs found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={!prcsData ? undefined : lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedPrcs}
        onSelectionChange={e => setSelectedPrcs(e.value)}
      >
        <Column
          columnKey="multiple"
          selectionMode="multiple"
          style={{ width: '3em' }}
          reorderable={false}
        />

        {dynamicColumns}
      </Grid>
      {(pageLoading || prcsLoading) && <Loading />}
    </Container>
  );
};
export default Prcs;
