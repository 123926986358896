import React, {
  Ref,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { ValidationError, number, object, string } from 'yup';
import { SatOtherExpense } from '../interfaces';
import {
  manageOtherExpenseInitialState,
  manageOtherExpenseReducer,
} from './reducers';
import {
  ManageOtherExpenseFormData,
  ManageOtherExpenseReducerActionKind,
} from './interfaces';
import Button from '../../../../../../../components/Button';

import { Container } from './styles';
import FormAsyncSelect from '../../../../../../../components/FormAsyncSelect';
import { asyncSelectLoadDomains } from '../../../../../../../shared/querys/domain';
import { DomainGroup } from '../../../../../../../shared/enums/domainGroup';
import ReadOnlyInput from '../../../../../../../components/ReadOnlyInput';
import FormInput from '../../../../../../../components/FormInput';
import FormInputNumber from '../../../../../../../components/FormInputNumber';
import { getCurrencySymbol } from '../../../../../../../utils/getCurrencySymbol';
import FormInputTextArea from '../../../../../../../components/FormInputTextArea';
import FormCheckbox from '../../../../../../../components/FormCheckbox';
import {
  createSatForeignTradeSupplierOtherExpenseQuery,
  updateSatForeignTradeSupplierOtherExpenseQuery,
} from './queries';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../../shared/enums/toastLife';
import getValidationErrors from '../../../../../../../utils/getValidationErrors';
import { SatFinancialSellerOtherExpenseFieldsPermissions } from '../../../interfaces';

export interface IManageOtherExpenseRef {
  toggleManageOtherExpenseDialog(otherExpense?: SatOtherExpense): void;
}

interface IManageOtherExpenseProps {
  ref: Ref<IManageOtherExpenseRef>;
  idSatForeignTradeSupplier: number;
  satCurrency?: string;
  otherExpensesRefetch(): Promise<void>;
  fieldsPermissions: SatFinancialSellerOtherExpenseFieldsPermissions;
}

const ManageOtherExpense: React.FC<IManageOtherExpenseProps> = React.forwardRef(
  (
    {
      satCurrency,
      idSatForeignTradeSupplier,
      otherExpensesRefetch,
      fieldsPermissions,
    },
    ref,
  ) => {
    const formRef = useRef<FormHandles>(null);

    const { toastRef } = useRefHook();

    const [loading, setLoading] = useState(false);

    const [createOtherExpenseMutation] = useMutation(
      createSatForeignTradeSupplierOtherExpenseQuery,
    );

    const [updateOtherExpenseMutation] = useMutation(
      updateSatForeignTradeSupplierOtherExpenseQuery,
    );

    const [manageOtherExpenseState, manageOtherExpenseDispatch] = useReducer(
      manageOtherExpenseReducer,
      manageOtherExpenseInitialState,
    );

    const editMode = !!manageOtherExpenseState.otherExpenseToEdit;

    useImperativeHandle(ref, () => ({
      toggleManageOtherExpenseDialog: otherExpense => {
        manageOtherExpenseDispatch({
          type: ManageOtherExpenseReducerActionKind.OPEN_DIALOG,
          payload: { otherExpense },
        });
      },
    }));

    async function handleSaveOtherExpense(
      formData: ManageOtherExpenseFormData,
    ) {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = object().shape({
          idType: string().nullable().required('Required Field'),
          dateOtherExpense: string().required('Required Field'),
          total: number().nullable().required('Required Field'),
          calculationBasis: number()
            .nullable()
            .when('apportionment', {
              is: 1,
              then: number().nullable().required('Required Field'),
            }),
        });

        await schema.validate(formData, { abortEarly: false });

        if (manageOtherExpenseState.otherExpenseToEdit) {
          await updateOtherExpenseMutation({
            variables: {
              data: {
                idSatOtherExpense:
                  manageOtherExpenseState.otherExpenseToEdit.idSatOtherExpense,
                total: formData.total,
                dateOtherExpense: formData.dateOtherExpense,
                creditToClient: formData.creditToClient,
                linkToMargin: formData.linkToMargin,
                apportionment: formData.apportionment,
                comment: formData.comment,
                calculationBasis: formData.calculationBasis,
              },
            },
          });
        } else {
          await createOtherExpenseMutation({
            variables: {
              data: {
                ...formData,
                idSatForeignTradeSupplier,
              },
            },
          });
        }

        await otherExpensesRefetch();

        toastRef.current?.show({
          severity: 'success',
          summary: `Other Expense ${
            manageOtherExpenseState.otherExpenseToEdit ? 'updated' : 'created'
          }`,
          life: ToastLife.SUCCESS,
        });

        manageOtherExpenseDispatch({
          type: ManageOtherExpenseReducerActionKind.CLOSE_DIALOG,
        });
      } catch (error) {
        if (error instanceof ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }

        toastRef.current?.show({
          severity: 'error',
          summary: `Error while ${
            manageOtherExpenseState.otherExpenseToEdit ? 'updating' : 'creating'
          } Other Expense`,
          detail: error.message,
          life: ToastLife.ERROR,
        });
      } finally {
        setLoading(false);
      }
    }

    function handleChangeApportionment(value: boolean) {
      if (!value) {
        formRef.current?.setFieldValue('calculationBasis', null);
      }

      manageOtherExpenseDispatch({
        type: ManageOtherExpenseReducerActionKind.SET_CALCULATION_BASIS_REQUIRED,
        payload: {
          calculationBasisRequired: value,
        },
      });

      return true;
    }

    const dialogFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button
            label="Save"
            icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
            onClick={() => formRef.current?.submitForm()}
            disabled={loading}
          />
          <Button
            label="Cancel"
            severity="danger"
            icon="pi pi-times"
            onClick={() =>
              manageOtherExpenseDispatch({
                type: ManageOtherExpenseReducerActionKind.CLOSE_DIALOG,
              })
            }
            disabled={loading}
          />
        </div>
      );
    };

    return (
      <Dialog
        header={
          editMode ? 'Manage Seller Other Expense' : 'Add Seller Other Expense'
        }
        visible={manageOtherExpenseState.displayDialog}
        style={{ width: '500px' }}
        modal
        onHide={() =>
          manageOtherExpenseDispatch({
            type: ManageOtherExpenseReducerActionKind.CLOSE_DIALOG,
          })
        }
        footer={dialogFooter()}
        closable={false}
      >
        <Container>
          <Form
            ref={formRef}
            initialData={manageOtherExpenseState.otherExpenseToEdit}
            onSubmit={e => handleSaveOtherExpense(e)}
            className="p-grid"
          >
            {fieldsPermissions.idType.view && (
              <FormAsyncSelect
                className="p-col-6"
                name="idType"
                label="Type"
                loadOptions={asyncSelectLoadDomains}
                getOptionLabel={option => option.description}
                getOptionValue={option => option.idDomain}
                additional={{
                  id: DomainGroup.FINANCIAL_SELLER_OTHER_EXPENSES,
                }}
                noOptionsMessage={() => 'No types found'}
                initialValue={
                  manageOtherExpenseState.otherExpenseToEdit?.idType2
                }
                required
                readOnly={
                  editMode || (!editMode && !fieldsPermissions.idType.create)
                }
              />
            )}

            {fieldsPermissions.idCurrency.view && (
              <ReadOnlyInput
                className="p-col-6"
                label="Currency"
                value={satCurrency}
              />
            )}

            {fieldsPermissions.dateOtherExpense.view && (
              <FormInput
                className="p-col-6"
                name="dateOtherExpense"
                label="Date"
                required
                type="date"
                readOnly={
                  (editMode && !fieldsPermissions.dateOtherExpense.edit) ||
                  (!editMode && !fieldsPermissions.dateOtherExpense.create)
                }
              />
            )}

            {fieldsPermissions.total.view && (
              <FormInputNumber
                className="p-col-6"
                name="total"
                label="Total"
                required
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                readOnly={
                  (editMode &&
                    (!fieldsPermissions.total.edit ||
                      manageOtherExpenseState.totalFieldDisabled)) ||
                  (!editMode && !fieldsPermissions.total.create)
                }
                prefix={getCurrencySymbol(satCurrency)}
              />
            )}

            {fieldsPermissions.calculationBasis.view && (
              <FormAsyncSelect
                className="p-col-6"
                name="calculationBasis"
                label="Calculation Basis"
                required={manageOtherExpenseState.calculationBasisRequired}
                loadOptions={asyncSelectLoadDomains}
                getOptionLabel={option => option.description}
                getOptionValue={option => option.idDomain}
                additional={{
                  id: DomainGroup.FINANCIAL_SELLER_CALCULATION_BASIS,
                }}
                noOptionsMessage={() => 'No calculation basis found'}
                initialValue={
                  manageOtherExpenseState.otherExpenseToEdit?.calculationBasis2
                }
                readOnly={
                  (editMode &&
                    (!fieldsPermissions.calculationBasis.edit ||
                      manageOtherExpenseState.flagsAndCalcBasisFieldsDisabled)) ||
                  (!editMode && !fieldsPermissions.calculationBasis.create)
                }
                disabled={!manageOtherExpenseState.calculationBasisRequired}
                onValueChange={() =>
                  formRef.current?.setFieldValue(
                    'apportionment',
                    handleChangeApportionment(true),
                  )
                }
              />
            )}

            {fieldsPermissions.comment.view && (
              <FormInputTextArea
                className="p-col-12"
                name="comment"
                label="Comment"
                rows={4}
                readOnly={
                  (editMode && !fieldsPermissions.comment.edit) ||
                  (!editMode && !fieldsPermissions.comment.create)
                }
              />
            )}

            <div className="manage-other-expense-checkboxes">
              {fieldsPermissions.linkToMargin.view && (
                <FormCheckbox
                  className="p-m-2"
                  name="linkToMargin"
                  label="Link to Margin"
                  returnType="number"
                  disabled={
                    (editMode &&
                      (!fieldsPermissions.linkToMargin.edit ||
                        manageOtherExpenseState.flagsAndCalcBasisFieldsDisabled)) ||
                    (!editMode && !fieldsPermissions.linkToMargin.create)
                  }
                />
              )}

              {fieldsPermissions.creditToClient.view && (
                <FormCheckbox
                  className="p-m-2"
                  name="creditToClient"
                  label="Credit To Client"
                  returnType="number"
                  disabled={
                    (editMode &&
                      (!fieldsPermissions.creditToClient.edit ||
                        manageOtherExpenseState.flagsAndCalcBasisFieldsDisabled)) ||
                    (!editMode && !fieldsPermissions.creditToClient.create)
                  }
                />
              )}

              {fieldsPermissions.apportionment.view && (
                <FormCheckbox
                  className="p-m-2"
                  name="apportionment"
                  label="Apportionment"
                  returnType="number"
                  disabled={
                    (editMode &&
                      (!fieldsPermissions.apportionment.edit ||
                        manageOtherExpenseState.flagsAndCalcBasisFieldsDisabled)) ||
                    (!editMode && !fieldsPermissions.apportionment.create)
                  }
                  onValueChange={e => handleChangeApportionment(e.checked)}
                />
              )}
            </div>
          </Form>
        </Container>
      </Dialog>
    );
  },
);

export default ManageOtherExpense;
