import { gql, useLazyQuery } from '@apollo/client';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';

import { TabPanel, TabView } from 'primereact/tabview';

import { useRefHook } from '../../../../../../hooks/useRefHook';
import ILazyParams from '../../../../../../services/lazyParams';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { ISat, ISatSupplier } from '../../../interfaces';
import { generateCotPIQuery, generateSellerPIQuery } from '../../../queries';
import { ICategoryTree } from '../../../../../../shared/interfaces/category';

import { findParentsOnTree } from '../../../../../../utils/findParentsOnTree';
import FormInputTextArea from '../../../../../../components/FormInputTextArea';
import PurchaseFormSupplierContent from './formPurchaseSupplierContent';

interface IPurchaseFormContentProps {
  sat: ISat;
  categories: ICategoryTree[];
  setEmailTitle(emailTitle: string): void;
  index: number;
  selected: boolean;
}

interface ISatSuppliersLazyParams extends ILazyParams {
  idSupplier?: number;
  name?: string;
  paymentTermAdvance?: number;
  paymentTermBalance?: number;
  paymentTermCondition?: string;
}

export interface IListSatSupplierPurchase {
  idSatSupplier: number;
  idSupplier: number;
  name: string;
  paymentTermAdvance: number;
  paymentTermBalance: number;
  paymentTermCondition: string;
  supplierAmountCotTotal?: number;
  supplierAmountTotal?: number;
}

const PurchaseFormContent: React.FC<IPurchaseFormContentProps> = ({
  sat,
  categories,
  setEmailTitle,
  index,
  selected,
}) => {
  const [satSuppliersConfirmOrder, setSatSuppliersConfirmOrder] =
    useState<IListSatSupplierPurchase[]>();

  const { toastRef } = useRefHook();

  // Parametros de paginacao/backend
  const [lazyParams] = useState<ISatSuppliersLazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  const [tabActiveIndex, setTabActiveIndex] = useState<number>(0);

  // Gera Seller PI
  const [getSellerPILink, { loading: sellerPiLoading }] = useLazyQuery(
    generateSellerPIQuery,
    {
      variables: {
        idSat: sat.idSat,
      },
      onCompleted: response => {
        if (response.errors) {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while generating SUN PI',
            detail: response.errors[0].message,
            life: ToastLife.ERROR,
          });
        } else {
          window.open(response.generateSatSellerPI, '_blank');
        }
      },

      onError: error => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while generating SUN PI',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  // Gera Purchase PI
  const [getPurchasePILink, { loading: purchasePiLoading }] = useLazyQuery(
    generateCotPIQuery,
    {
      variables: {
        idSat: sat.idSat,
      },
      onCompleted: response => {
        if (response.errors) {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while generating COT PI',
            detail: response.errors[0].message,
            life: ToastLife.ERROR,
          });
        } else {
          window.open(response.generateSatPurchasePI, '_blank');
        }
      },

      onError: error => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while generating COT PI',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  // Query para listar sat suppliers
  const listSatSuppliersBySatIdQuery = gql`
    query listSatSuppliersBySatId($data: ListSatSuppliersBySatIdInput!) {
      listSatSuppliersBySatId(data: $data) {
        items
        data {
          idSatSupplier
          idSupplier
          name
          paymentTermAdvance
          paymentTermBalance
          paymentTermCondition
          supplierAmountCotTotal
          supplierAmountTotal
          supplierAmountCotTotal
        }
      }
    }
  `;

  /**
   * Busca SAT Suppliers
   */
  const [listSatSuppliers, { loading: satSuppliersLoading }] = useLazyQuery(
    listSatSuppliersBySatIdQuery,
    {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          idSat: sat.idSat,
        },
      },
      onCompleted: response => {
        setSatSuppliersConfirmOrder(response.listSatSuppliersBySatId.data);
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting SAT Suppliers',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  const listSatSupliersNames = (
    satSuppliers?: ISatSupplier[] | undefined,
  ): string[] => {
    if (satSuppliers) {
      return satSuppliers.map(element => `${element.idSupplier2.name}`);
    }
    return [];
  };

  useEffect(() => {
    const titleEmail = () => {
      const title: string[] = [];

      let titlePrefix = '';

      if (sat.typeOfOrder2?.description) {
        titlePrefix += `[${sat.typeOfOrder2?.description}]`;
      }
      if (sat.idSupplierExporter2?.name) {
        if (titlePrefix.length > 0) {
          titlePrefix += ` ${sat.idSupplierExporter2?.name}`;
        } else {
          titlePrefix += sat.idSupplierExporter2?.name;
        }
      }

      if (titlePrefix.length > 0) {
        title.push(titlePrefix);
      }

      title.push(listSatSupliersNames(sat.satSuppliers).join(' | '));

      if (sat.idClient2?.name) {
        title.push(sat.idClient2?.name);
      }

      if (sat.satNumber) {
        title.push(sat.satNumber);
      }
      if (sat.mainProduct) {
        title.push(sat.mainProduct);
      }

      setEmailTitle(title ? title.join(' | ') : '');
    };
    if (selected) titleEmail();
  }, [
    sat.idClient2?.name,
    sat.idSupplierExporter2?.name,
    sat.mainProduct,
    sat.satNumber,
    sat.satSuppliers,
    sat.typeOfOrder2?.description,
    setEmailTitle,
    selected,
  ]);

  useEffect(() => {
    if (sat && !satSuppliersConfirmOrder && selected) {
      listSatSuppliers();
    }
  }, [sat, satSuppliersConfirmOrder, selected, listSatSuppliers]);

  return (
    <>
      <FormInputTextArea
        className="p-col-12"
        name={`data[${index}].emailDescription`}
        defaultValue=""
        label="Email Description"
      />

      {!satSuppliersLoading && (
        <TabView
          activeIndex={tabActiveIndex}
          onTabChange={e => setTabActiveIndex(e.index)}
          renderActiveOnly={false}
          scrollable
        >
          {!satSuppliersLoading &&
            satSuppliersConfirmOrder &&
            satSuppliersConfirmOrder.map(
              (item: IListSatSupplierPurchase, indexSupplier) => {
                return (
                  <TabPanel
                    key={item.idSupplier.toString()}
                    header={`SUN${item.idSupplier.toString().padStart(5, '0')}`}
                  >
                    <PurchaseFormSupplierContent
                      satPurchaseData={sat}
                      satSupplierData={item}
                      category={
                        findParentsOnTree(categories, sat.idCategory) ?? ''
                      }
                      indexSat={index}
                      indexSupplier={indexSupplier}
                    />
                  </TabPanel>
                );
              },
            )}
        </TabView>
      )}
      <h4>Anexos</h4>

      <Button
        label="COT PI"
        icon={purchasePiLoading ? 'pi pi-spin pi-spinner' : 'pi pi-paperclip'}
        onClick={() => getPurchasePILink()}
        className="p-button-text"
        disabled={purchasePiLoading}
        type="button"
      />
      <br />
      <Button
        label="SUN PI"
        icon={sellerPiLoading ? 'pi pi-spin pi-spinner' : 'pi pi-paperclip'}
        onClick={() => getSellerPILink()}
        className="p-button-text"
        disabled={sellerPiLoading}
        type="button"
      />
    </>
  );
};

export default PurchaseFormContent;
