import React, { RefObject } from 'react';
import {
  MultiSelect as PrimeMultiSelect,
  MultiSelectProps,
  MultiSelectPanelHeaderTemplateParams,
} from 'primereact/multiselect';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Container } from './styles';
import Button from '../../Button';

interface IMultiSelectProps extends MultiSelectProps {
  gridRef?: RefObject<DataTable>;
}

const MultiSelect: React.FC<IMultiSelectProps> = ({ gridRef, ...rest }) => {
  function resetColumnPositions() {
    // Define delay para resetar ordem das colunas apenas apos
    // colunas serem renderizadas novamente
    setTimeout(() => {
      gridRef?.current?.resetColumnOrder();
    }, 1);
  }

  const panelHeader = (props: MultiSelectPanelHeaderTemplateParams) => {
    const { element } = props;

    if (!gridRef) return element;

    return (
      <>
        <Button
          className="p-mb-2 p-ml-auto"
          label="Reset Column Positions"
          severity="danger"
          onClick={() => {
            confirmDialog({
              header: 'Reset Column Positions',
              message:
                'This action will reset the order of the columns to their default positions. Are you sure you want to proceed?',
              icon: 'pi pi-question-circle',
              accept: () => resetColumnPositions(),
            });
          }}
        />
        {element}
      </>
    );
  };

  return (
    <Container>
      <PrimeMultiSelect
        optionLabel="header"
        dropdownIcon="pi pi-cog"
        panelStyle={{ fontWeight: 'bold', padding: '16px' }}
        scrollHeight="400px"
        showSelectAll
        filter
        {...rest}
        panelHeaderTemplate={panelHeader}
      />
    </Container>
  );
};

export default MultiSelect;
