import { useLazyQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Skeleton } from 'primereact/skeleton';
import FormTitle from '../../../../../../components/FormTitle';
import Grid from '../../../../../../components/Grid';
import MainButton from '../../../../../../components/MainButton';
import pagination from '../../../../../../config/pagination';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import {
  SatFinancialBalanceFieldsPermissions,
  SatFinancialBalancePermissions,
  SatForeignTradeSupplier,
} from '../../interfaces';
import { deleteSatForeignTradePaymentQuery } from '../../queries';
import AddBalanceModal from '../AddBalance';
import {
  SatForeignTradePayment,
  SatForeignTradePaymentType,
} from './interfaces';
import {
  listAllSatForeignTradePaymentsQuery,
  listBalancePaidTotals,
} from './queries';
import { Container, GridActions } from './styles';
import { parseCurrencyPtBrNumberColumn } from '../../../../../../utils/gridColumnsParse';
import {
  ForeignTradeSupplierContentReducer,
  ForeignTradeSupplierContentReducerAction,
} from '../interfaces';
import { numberToPtString } from '../../../../../../utils/formatLocale';
import { parseToLocaleFormat } from '../../../../../../utils/dateUtil';
import isNullOrUndefined from '../../../../../../utils/isNullOrUndefined';

interface IBalanceProps {
  paymentType:
    | SatForeignTradePaymentType.BALANCE_PURCHASE
    | SatForeignTradePaymentType.BALANCE_SELLER;
  satForeignTradeSupplier: SatForeignTradeSupplier;
  selected: boolean;
  fieldsPermissions: SatFinancialBalanceFieldsPermissions;
  permissions: SatFinancialBalancePermissions;
  satCurrency?: string;
  foreignTradeSupplierContentDispatch: Dispatch<ForeignTradeSupplierContentReducerAction>;
  contentState: ForeignTradeSupplierContentReducer;
  estimatedTotalAdvance?: number;
  estimatedTotalBalance?: number;
  loadPurchaseOrSellerTotalData(): void;
}

const Balance: React.FC<IBalanceProps> = ({
  paymentType,
  satForeignTradeSupplier,
  selected,
  fieldsPermissions,
  permissions,
  satCurrency,
  contentState,
  estimatedTotalAdvance,
  estimatedTotalBalance,
  loadPurchaseOrSellerTotalData,
}) => {
  const isPurchaseType =
    paymentType === SatForeignTradePaymentType.BALANCE_PURCHASE;

  const otherExpensesTotal = isPurchaseType
    ? contentState?.purchaseOtherExpensesTotal?.totalOtherExpenses || 0
    : contentState?.sellerOtherExpensesTotal?.totalOtherExpenses || 0;

  const totalAdvance = isPurchaseType
    ? contentState?.purchaseAdvancePaidTotal?.totalAmount
    : contentState?.sellerAdvanceReceivedTotal?.totalAmount;

  const title = isPurchaseType ? 'Paid' : 'Received';

  const lowercaseTitle = title.toLowerCase();

  const { showError, showSuccess } = useRefHook();

  const [satForeignTradePaymentToEdit, setSatForeignTradePaymentToEdit] =
    useState<SatForeignTradePayment>();

  const [displayAddBalanceModal, setDisplayAddBalanceModal] = useState(false);

  const [itemBeingDeleted, setItemBeingDeleted] = useState<number>();

  // PODE SER UTILIZADO FUTURAMENTE NA AUTOMATIZACAO
  // const [requestingPayment, setRequestingPayment] = useState<boolean>();
  // const [paymentRequested, setPaymentRequested] = useState<boolean>();

  const [satForeignTradePayments, setSatForeignTradePayments] =
    useState<SatForeignTradePayment[]>();

  const estimatedTotal =
    (estimatedTotalAdvance ?? 0) + (estimatedTotalBalance ?? 0);

  const [
    loadForeignTradePaymentsData,
    { loading: loadingForeignTradePaymentsData },
  ] = useLazyQuery(listAllSatForeignTradePaymentsQuery(fieldsPermissions), {
    variables: {
      data: {
        pagination: {
          _page: pagination.initialLazyParams.page + 1,
          _limit: 0,
        },
        idSatForeignTradeSupplier:
          satForeignTradeSupplier.idSatForeignTradeSupplier,
        idTypes: [paymentType],
      },
    },
    onCompleted: response => {
      if (response.listAllSatForeignTradePayments.data) {
        setSatForeignTradePayments(
          response.listAllSatForeignTradePayments.data,
        );
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting SAT Foreign Trade Payments',
        detail: errorData.message,
      });
    },
  });

  const [
    loadBalancePaidTotals,
    { loading: balancePaidTotalsLoading, data: balancePaidTotalsData },
  ] = useLazyQuery(listBalancePaidTotals, {
    variables: {
      idSatForeignTradeSupplier:
        satForeignTradeSupplier.idSatForeignTradeSupplier,
      balanceIdType: paymentType,
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Total Purchase Balance Paid',
        detail: errorData.message,
      });
    },
  });

  const loadGridData = useCallback(async () => {
    loadForeignTradePaymentsData();

    loadBalancePaidTotals();
    loadPurchaseOrSellerTotalData();
  }, [
    loadForeignTradePaymentsData,
    loadBalancePaidTotals,
    loadPurchaseOrSellerTotalData,
  ]);

  // PODE SER UTILIZADO FUTURAMENTE NA AUTOMATIZACAO
  // const [requestBalancePaymentMutation] = useMutation(
  //   requestBalancePaymentQuery,
  // );

  // const sendPaymentRequest = useCallback(async () => {
  //   if (isPurchaseType) {
  //     setRequestingPayment(true);
  //     try {
  //       const response = await requestBalancePaymentMutation({
  //         variables: {
  //           idSatForeignTradeSupplier:
  //             satForeignTradeSupplier.idSatForeignTradeSupplier,
  //         },
  //       });

  //       if (response) {
  //         setPaymentRequested(response.data.requestBalancePayment);
  //         showSuccess({ summary: 'Balance Payment Requested' });
  //         loadGridData();
  //       }
  //     } catch (error) {
  //       showError({
  //         summary: `Error while requesting balance payment`,
  //         detail: error.message,
  //       });
  //     } finally {
  //       setRequestingPayment(false);
  //     }
  //   }
  // }, [
  //   isPurchaseType,
  //   loadGridData,
  //   requestBalancePaymentMutation,
  //   satForeignTradeSupplier.idSatForeignTradeSupplier,
  //   showError,
  //   showSuccess,
  // ]);

  function handleEditItem(rowData: SatForeignTradePayment) {
    setSatForeignTradePaymentToEdit(rowData);
    setDisplayAddBalanceModal(true);
  }

  const [deleteSatForeignTradePaymentMutation] = useMutation(
    deleteSatForeignTradePaymentQuery,
  );

  const deleteItem = useCallback(
    async (satForeignTradePaymentId: number) => {
      setItemBeingDeleted(satForeignTradePaymentId);
      try {
        await deleteSatForeignTradePaymentMutation({
          variables: {
            satForeignTradePaymentId,
          },
        });
        showSuccess({ summary: 'SAT Foreign Trade Payment deleted' });
        loadGridData();
      } catch (error) {
        showError({
          summary: 'Error while deleting SAT Foreign Trade Payment',
          detail: error.message,
        });
      } finally {
        setItemBeingDeleted(undefined);
      }
    },
    [
      deleteSatForeignTradePaymentMutation,
      loadGridData,
      showError,
      showSuccess,
    ],
  );

  const handleDeleteItem = useCallback(
    async (payment: SatForeignTradePayment) => {
      confirmDialog({
        message: `Do you confirm to remove balance ${lowercaseTitle}?`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => {
          if (payment.idSatForeignTradePayment) {
            deleteItem(payment.idSatForeignTradePayment);
          }
        },
      });
    },
    [deleteItem, lowercaseTitle],
  );

  useEffect(() => {
    if (!displayAddBalanceModal) {
      setSatForeignTradePaymentToEdit({});
    }
  }, [displayAddBalanceModal]);

  const gridActions = (rowData: SatForeignTradePayment) => {
    const isBeingDeleted =
      itemBeingDeleted === rowData.idSatForeignTradePayment;
    return (
      <GridActions>
        {permissions.updateItem && (
          <button type="button" onClick={() => handleEditItem(rowData)}>
            <FiEdit2 size={20} />
          </button>
        )}
        {permissions.removeItem && (
          <button
            type="button"
            className="trash-button"
            onClick={() => handleDeleteItem(rowData)}
            disabled={!!itemBeingDeleted}
          >
            {isBeingDeleted ? (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: '1.43rem' }}
              />
            ) : (
              <FiTrash2 size={20} />
            )}
          </button>
        )}
      </GridActions>
    );
  };

  function parsePercentageColumn(rowData: any) {
    if (rowData.amount && estimatedTotal) {
      const value = (rowData.amount / estimatedTotal) * 100;
      const formattedValue = numberToPtString(value, 2);
      return `${formattedValue}%`;
    }
    return undefined;
  }

  const totalPercentageFooter = () => {
    if (balancePaidTotalsLoading) return <Skeleton />;

    const totalAmount =
      balancePaidTotalsData?.listSatForeignTradeSupplierBalanceTotals
        .totalAmount;

    if (totalAmount && estimatedTotal) {
      const value = totalAmount / estimatedTotal;

      return value.toLocaleString('pt', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'percent',
      });
    }
    return undefined;
  };

  const totalAmountFooter = () => {
    if (balancePaidTotalsLoading) return <Skeleton />;

    const totalAmount =
      balancePaidTotalsData?.listSatForeignTradeSupplierBalanceTotals
        .totalAmount;

    if (!isNullOrUndefined(totalAmount)) {
      return totalAmount.toLocaleString('pt', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: satCurrency ? 'currency' : undefined,
        currency: satCurrency,
      });
    }

    return undefined;
  };

  useEffect(() => {
    if (!satForeignTradePayments && selected) {
      loadGridData();
    }
  }, [loadGridData, satForeignTradePayments, selected]);

  return (
    <Container>
      <Dialog
        header={`${title} - ${
          satForeignTradePaymentToEdit?.idSatForeignTradePayment
            ? 'Manage'
            : 'Add'
        } Balance`}
        visible={displayAddBalanceModal}
        onHide={() => setDisplayAddBalanceModal(false)}
      >
        <AddBalanceModal
          totalAdvance={totalAdvance}
          totalBalance={
            balancePaidTotalsData?.listSatForeignTradeSupplierBalanceTotals
              ?.totalAmount
          }
          paymentType={paymentType}
          satForeignTradeSupplier={satForeignTradeSupplier}
          satForeignTradePayment={satForeignTradePaymentToEdit}
          setDisplayModal={setDisplayAddBalanceModal}
          refetchForeignTradePaymentsData={loadGridData}
          otherExpensesTotal={otherExpensesTotal}
          estimatedTotalAdvance={estimatedTotalAdvance}
          estimatedTotalBalance={estimatedTotalBalance}
          advancePaidTotalAmount={totalAdvance}
          balancePaidTotalAmount={
            balancePaidTotalsData?.listSatForeignTradeSupplierBalanceTotals
              ?.totalAmount
          }
        />
      </Dialog>
      <FormTitle className="p-col-12 p-md-12 p-mt-5" name={`Balance ${title}`}>
        {permissions.addItem && (
          <MainButton
            type="button"
            label="Add Balance"
            onClick={() => setDisplayAddBalanceModal(true)}
          />
        )}
      </FormTitle>
      <Grid
        emptyMessage={`No balance ${lowercaseTitle} found`}
        loading={loadingForeignTradePaymentsData}
        value={satForeignTradePayments}
        paginator={false}
      >
        <Column
          field="actions"
          headerStyle={{ width: '6rem' }}
          body={gridActions}
        />
        {fieldsPermissions.percentage.view && (
          <Column
            field="percentage"
            header="Balance %"
            style={{ width: '7rem' }}
            body={parsePercentageColumn}
            footer={() => totalPercentageFooter()}
          />
        )}
        {fieldsPermissions.amount.view && (
          <Column
            field="amount"
            header="Amount"
            style={{ width: '7rem' }}
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 2, satCurrency)
            }
            footer={() => totalAmountFooter()}
          />
        )}
        {fieldsPermissions.paymentDate.view && (
          <Column
            field="paymentDate"
            header="Payment Date"
            style={{ width: '7rem' }}
            body={e => parseToLocaleFormat(e.paymentDate)}
          />
        )}
      </Grid>
    </Container>
  );
};
export default Balance;
