import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { ValidationError, object, string } from 'yup';
import FormInput from '../../../../../components/FormInput';
import { ISupplierAssessment } from '../../interfaces';
import { useRefHook } from '../../../../../hooks/useRefHook';
import {
  createSupplierAssessmentQuery,
  updateSupplierAssessmentQuery,
} from './queries';
import Button from '../../../../../components/Button';
import FormInputNumber from '../../../../../components/FormInputNumber';
import { IAddAssessmentRef, SupplierAssessmentFormData } from './interfaces';
import { asyncSelectLoadDomains } from '../../../../../shared/querys/domain';
import FormAsyncSelect from '../../../../../components/FormAsyncSelect';
import { DomainGroup } from '../../../../../shared/enums/domainGroup';
import FileUpload from '../../../../../components/FileUpload';
import { FileType } from '../../../../../shared/enums/fileType';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../../utils/getValidationErrors';

interface IAddAssessmentProps {
  ref: Ref<IAddAssessmentRef>;
  idSupplier: number;
  assessmentsRefetch(): Promise<ApolloQueryResult<any>>;
}

const AddAssessment: React.FC<IAddAssessmentProps> = forwardRef(
  ({ idSupplier, assessmentsRefetch }, ref) => {
    const formRef = useRef<FormHandles>(null);
    const { showError, showSuccess } = useRefHook();

    const [createSupplierAssessmentMutation, { loading: createLoading }] =
      useMutation(createSupplierAssessmentQuery);
    const [updateSupplierAssessmentMutation, { loading: updateLoading }] =
      useMutation(updateSupplierAssessmentQuery);

    const [displayModal, setDisplayModal] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<string | undefined>();
    const [initialFormData, setInitialFormData] =
      useState<ISupplierAssessment>();
    const loading = createLoading || updateLoading;
    const uploadFileButtonDisabled =
      !!uploadedFile || loading || !!initialFormData?.imageUrl;

    function closeModal() {
      setDisplayModal(false);
      setUploadedFile(undefined);
      setInitialFormData(undefined);
    }

    useImperativeHandle(ref, () => ({
      toggleModal: (data?: ISupplierAssessment) => {
        if (displayModal) {
          closeModal();
        } else {
          setDisplayModal(true);
          if (data) setInitialFormData(data);
        }
      },
    }));

    const dialogFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => formRef.current?.submitForm()}
            loading={loading}
          />
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={() => closeModal()}
            className="p-button-danger"
            severity="danger"
            disabled={loading}
          />
        </div>
      );
    };

    async function onSubmit(formData: SupplierAssessmentFormData) {
      try {
        formRef.current?.setErrors({});

        const schema = object().shape({
          name: string().required(requiredFieldErrorMessage),
        });

        await schema.validate(formData, { abortEarly: false });

        if (initialFormData) {
          await updateSupplierAssessmentMutation({
            variables: {
              data: {
                ...formData,
                uploadedFile,
              },
            },
          });

          showSuccess({ summary: 'Assessment updated' });
        } else {
          await createSupplierAssessmentMutation({
            variables: {
              data: {
                idSupplier,
                name: formData.name,
                idType: formData.idType,
                expirationDate: formData.expirationDate,
                uploadedFile,
              },
            },
          });

          showSuccess({ summary: 'Assessment created' });
        }

        assessmentsRefetch();

        closeModal();
      } catch (error) {
        if (error instanceof ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        } else {
          showError({
            summary: `Error while ${
              initialFormData ? 'updating' : 'creating'
            } assessment`,
            detail: error.message,
          });
        }
      }
    }

    return (
      <Dialog
        header="Manage Assessment"
        visible={displayModal}
        style={{ width: '40vw' }}
        onHide={() => setDisplayModal(false)}
        closable={!loading}
        footer={dialogFooter()}
      >
        <Form ref={formRef} onSubmit={onSubmit} initialData={initialFormData}>
          <FormInputNumber name="idSupplierAssesment" label="" hidden />
          <FormInputNumber name="versionLock" label="" hidden />

          <FormInput className="p-field" name="name" label="Name" required />

          <FormAsyncSelect
            className="p-field"
            name="idType"
            label="Certificaty Type"
            loadOptions={asyncSelectLoadDomains}
            initialValue={initialFormData?.idType2}
            getOptionLabel={option => option.description}
            getOptionValue={option => option.idDomain}
            noOptionsMessage={() => 'No types found'}
            placeholder="Select a type"
            additional={{ id: DomainGroup.TYPE_OF_ASSESMENT }}
            menuPosition="fixed"
          />

          <FormInput
            className="p-field"
            name="expirationDate"
            label="Expiration Date"
            type="date"
          />

          <FileUpload
            className="p-field"
            mode="basic"
            accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX}}`}
            auto
            chooseLabel={
              !!uploadedFile || !!initialFormData?.imageUrl
                ? 'File uploaded'
                : 'Upload a file'
            }
            disabled={uploadFileButtonDisabled}
            onConfirm={e => setUploadedFile(e[0].serverName)}
          />
        </Form>
      </Dialog>
    );
  },
);

export default AddAssessment;
