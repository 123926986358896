/* eslint-disable jsx-a11y/label-has-associated-control */
import { RadioButton, RadioButtonChangeParams } from 'primereact/radiobutton';
import React, { useCallback, useState } from 'react';

// import { Container } from './styles';

interface IBooleanRadioProps {
  initialValue?: boolean;
  /**
   * Funcao executada ao ocorrer mudanca de valor
   * @param e Parametro de evento
   */
  onValueChange?(e: RadioButtonChangeParams): void;
}

const BooleanRadio: React.FC<IBooleanRadioProps> = ({
  initialValue,
  onValueChange,
}) => {
  const [booleanValue, setBooleanValue] = useState(!!initialValue);

  /**
   * Funcao executada ao ocorrer mudanca de valor
   * @param e Evento de mudanca de valor
   */
  const handleValueChange = useCallback(
    (e: RadioButtonChangeParams) => {
      // Define valor do componente
      setBooleanValue(e.value);

      // Valida se usuario informou funcao para mudanca de valor no componente
      if (onValueChange) {
        // Repassa evento para funcao informada pelo usuario
        onValueChange(e);
      }
    },
    [onValueChange],
  );

  return (
    <div className="p-field-radiobutton">
      <label htmlFor="radioYes">
        Yes
        <RadioButton
          id="radioYes"
          value
          onChange={handleValueChange}
          checked={booleanValue}
        />
      </label>

      <label htmlFor="radioNo">
        No
        <RadioButton
          id="radioNo"
          value={false}
          onChange={handleValueChange}
          checked={!booleanValue}
        />
      </label>
    </div>
  );
};

export default BooleanRadio;
