import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTablePageParams } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import FormTitle from '../../../../../../components/FormTitle';
import Grid from '../../../../../../components/Grid';
import { getSumAdvancePaid, listAdvancePaidQuery } from './queries';
import pagination from '../../../../../../config/pagination';
import {
  PurchaseAdvancePaidLazyParams,
  SatForeignTradePayment,
} from './interfaces';
import { FinancialPurchasePaymentType } from '../../../../../../shared/enums/domains';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { parseToLocaleFormat } from '../../../../../../utils/dateUtil';
import {
  parseCurrencyPtBrNumberColumn,
  parsePercentageColumnPt,
} from '../../../../../../utils/gridColumnsParse';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import { numberToPtString } from '../../../../../../utils/formatLocale';
import {
  ForeignTradeSupplierTotalReducerAction,
  ForeignTradeSupplierTotalReducerActionKind,
} from '../interfaces';
import { SatFinancialAdvancePaidFieldsPermissions } from '../../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';

interface IPurchaseAdvancePaidProps {
  idSat: number;
  selected: boolean;
  totalDispatch: Dispatch<ForeignTradeSupplierTotalReducerAction>;
  fieldsPermissions: SatFinancialAdvancePaidFieldsPermissions;
  satCurrency?: string;
}

const PurchaseAdvancePaid: React.FC<IPurchaseAdvancePaidProps> = ({
  idSat,
  selected,
  totalDispatch,
  fieldsPermissions,
  satCurrency,
}) => {
  const { toastRef } = useRefHook();

  const userCanSeeBlock =
    fieldsPermissions.percentage.view ||
    fieldsPermissions.amount.view ||
    fieldsPermissions.paymentDate.view ||
    fieldsPermissions.rollingFrom.view ||
    fieldsPermissions.rollingTo.view ||
    fieldsPermissions.shipment.view;

  const [payments, setPayments] = useState<SatForeignTradePayment[]>();

  const [lazyParams, setLazyParams] = useState<PurchaseAdvancePaidLazyParams>(
    pagination.initialLazyParams,
  );

  const [
    loadForeignTradePaymentsData,
    {
      loading: loadingForeignTradePaymentsData,
      data: foreignTradePaymentsData,
    },
  ] = useLazyQuery(listAdvancePaidQuery(fieldsPermissions), {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
        },
        idSat,
        idsTypes: [
          FinancialPurchasePaymentType.ADVANCE,
          FinancialPurchasePaymentType.ROLLING,
        ],
      },
    },
    onCompleted: response => {
      if (response.listSatForeignTradeSupplierPaymentsByIdSat.data) {
        setPayments(response.listSatForeignTradeSupplierPaymentsByIdSat.data);
      }
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Total Purchase Advance Paid',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const [loadGridSumData, { loading: gridSumLoading, data: gridSumSumData }] =
    useLazyQuery(getSumAdvancePaid, {
      variables: {
        idSat,
        typeIdDomainGroup: DomainGroup.FINANCIAL_PURCHASE_PAYMENT_TYPES,
      },
      onCompleted: response => {
        totalDispatch({
          type: ForeignTradeSupplierTotalReducerActionKind.SET_PURCHASE_ADVANCE_PAID_TOTAL,
          payload: {
            purchaseAdvancePaidTotal:
              response.getSumDataFromSatForeignTradeSupplierAdvanceByIdSat,
          },
        });
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Total Purchase Advance Paid',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });

        totalDispatch({
          type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_PURCHASE_ADVANCE_PAID_TOTAL_LOADING,
        });
      },
    });

  const loadGridData = useCallback(async () => {
    totalDispatch({
      type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_PURCHASE_ADVANCE_PAID_TOTAL_LOADING,
    });

    if (userCanSeeBlock) {
      loadForeignTradePaymentsData();
    }

    loadGridSumData();
  }, [
    loadForeignTradePaymentsData,
    loadGridSumData,
    totalDispatch,
    userCanSeeBlock,
  ]);

  const amountCotTotal =
    gridSumSumData?.getSumDataFromSatForeignTradeSupplierAdvanceByIdSat
      ?.totalAmount || 0;

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  const onPage = (event: DataTablePageParams) => {
    setLazyParams({ ...lazyParams, ...event });
  };

  const totalAmountFooter = () => {
    if (gridSumLoading) return <Skeleton />;

    const sumData =
      gridSumSumData?.getSumDataFromSatForeignTradeSupplierAdvanceByIdSat
        .totalAmount;

    if (!sumData) {
      return '';
    }

    const formattedSumData = numberToPtString(sumData, 2);

    return `${getCurrencySymbol(satCurrency)} ${formattedSumData}`;
  };

  function paymentIsRollingFromOrigin(rowData: SatForeignTradePayment) {
    return (
      rowData.idType === FinancialPurchasePaymentType.ROLLING &&
      rowData.idSat === idSat &&
      !rowData.isDestination
    );
  }

  function paymentIsRollingFromDestination(rowData: SatForeignTradePayment) {
    return (
      rowData.idType === FinancialPurchasePaymentType.ROLLING &&
      rowData.idSatDestination === idSat &&
      rowData.isDestination
    );
  }

  function getRowClassName(rowData: SatForeignTradePayment) {
    return {
      'ap-grid-rolling-from-origin': paymentIsRollingFromOrigin(rowData),
      'ap-grid-rolling-from-destination':
        paymentIsRollingFromDestination(rowData),
    };
  }

  function showRollingFrom(rowData: any) {
    if (rowData.idType === FinancialPurchasePaymentType.ROLLING) {
      return rowData?.idSatForeignTrade2?.satForeignTradeNumber;
    }
    return '';
  }

  function parsePercentageColumn(rowData: any, field: any) {
    if (rowData[field.field]) {
      return parsePercentageColumnPt(rowData, field);
    }
    if (rowData.amount && amountCotTotal) {
      const value = (rowData.amount / amountCotTotal) * 100;
      const formattedValue = numberToPtString(value, 2);
      return `${formattedValue}%`;
    }
    return undefined;
  }

  const totalPercentageFooter = () => {
    const sumData = payments
      ?.map(payment => {
        if (payment.percentage) {
          return payment.percentage;
        }
        if (payment.amount && paymentIsRollingFromOrigin(payment)) {
          return (payment.amount / amountCotTotal) * -100;
        }
        if (payment.amount && paymentIsRollingFromDestination(payment)) {
          return (payment.amount / amountCotTotal) * 100;
        }
        return 0;
      })
      .reduce((previous: number, current: number) => previous + current, 0);

    if (!sumData) {
      return '';
    }

    const formattedSumData = numberToPtString(sumData, 2);
    return `${formattedSumData}%`;
  };

  useEffect(() => {
    if (!payments && selected) {
      loadGridData();
    }
  }, [loadGridData, payments, selected]);

  return userCanSeeBlock ? (
    <div className="p-grid">
      <FormTitle className="p-col-12" name="Advance Paid" />

      <Grid
        className="p-col-12 p-datatable-sm"
        emptyMessage="No advance paid found"
        loading={loadingForeignTradePaymentsData}
        value={payments}
        lazy
        rows={lazyParams.rows}
        first={lazyParams.first}
        totalRecords={
          !foreignTradePaymentsData
            ? 0
            : foreignTradePaymentsData
                .listSatForeignTradeSupplierPaymentsByIdSat?.items
        }
        scrollable
        scrollHeight="calc(100vh - 272px)"
        onPage={onPage}
        rowClassName={getRowClassName}
      >
        {fieldsPermissions.shipment.view && (
          <Column
            field="idSatForeignTradeSupplier2.satForeignTradeSupplierNumber"
            header="Shipment"
            style={{ width: '16rem' }}
          />
        )}

        {fieldsPermissions.percentage.view && (
          <Column
            field="percentage"
            header="Advance %"
            style={{ width: '8rem' }}
            body={parsePercentageColumn}
            footer={() => totalPercentageFooter()}
          />
        )}

        {fieldsPermissions.amount.view && (
          <Column
            field="amount"
            header="Amount"
            style={{ width: '8rem' }}
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 2, satCurrency)
            }
            footer={() => totalAmountFooter()}
          />
        )}

        {fieldsPermissions.paymentDate.view && (
          <Column
            field="paymentDate"
            header="Payment Date"
            style={{ width: '8rem' }}
            body={e => parseToLocaleFormat(e.paymentDate)}
          />
        )}

        {fieldsPermissions.rollingFrom.view && (
          <Column
            field="idSatForeignTrade2.satForeignTradeNumber"
            header="Rolling From"
            style={{ width: '8rem' }}
            body={showRollingFrom}
          />
        )}

        {fieldsPermissions.rollingTo.view && (
          <Column
            field="idSatForeignTradeDestination2.satForeignTradeNumber"
            header="Rolling To"
            style={{ width: '8rem' }}
          />
        )}
      </Grid>
    </div>
  ) : (
    <div />
  );
};

export default PurchaseAdvancePaid;
