import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Dialog } from 'primereact/dialog';
import { useMutation } from '@apollo/client';
import PackageBarcodeContent from '../PackageBarcodeContent';
import { IPackageBarcodeContentRef } from '../PackageBarcodeContent/interfaces';
import { IAddBarcodeRef } from './interfaces';
import Button from '../../../../../../../components/Button';
import { createProductPackageBarcodeQuery } from './queries';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import { PackageBarcodeFieldsPermissions } from '../../../interfaces';

interface IAddBarcodeProps {
  ref: Ref<IAddBarcodeRef>;
  barcodesRefetch(): Promise<void>;
  idProductPackage: number;
  userCanChangeFields: boolean;
  fieldsPermissions: PackageBarcodeFieldsPermissions;
}
const AddBarcode: React.FC<IAddBarcodeProps> = forwardRef(
  (
    {
      idProductPackage,
      barcodesRefetch,
      userCanChangeFields,
      fieldsPermissions,
    },
    ref,
  ) => {
    const [createProductPackageBarcodeMutation] = useMutation(
      createProductPackageBarcodeQuery,
    );

    const barcodeContentRef = useRef<IPackageBarcodeContentRef>(null);
    const { showSuccess, showError } = useRefHook();

    const [displayDialog, setDisplayDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
      toggleDialog: () => {
        setDisplayDialog(!displayDialog);
      },
    }));

    function closeModal() {
      setDisplayDialog(false);
    }

    async function handleSaveBarcode() {
      const formData = barcodeContentRef.current?.getDataIfChanged();

      if (!formData) return;

      setLoading(true);
      try {
        await createProductPackageBarcodeMutation({
          variables: {
            data: {
              idProductPackage,
              ...formData,
            },
          },
        });

        await barcodesRefetch();

        showSuccess({ summary: 'Barcode created' });

        closeModal();
      } catch (error) {
        showError({
          summary: 'Error while creating barcode',
          detail: error.message,
        });
      } finally {
        setLoading(false);
      }
    }

    const dialogFooter = () => {
      return (
        <div className="p-grid">
          <small className="p-col-8 s-warning-text">
            <p className="p-text-left">
              If you have <b>unsaved changes</b> on any barcodes at this
              package, adding a new barcode to it will cause <b>data loss</b>
            </p>
          </small>
          <span className="p-col" />
          <span className="p-col-3 p-d-flex">
            <Button
              label="Save"
              icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
              onClick={() => handleSaveBarcode()}
              disabled={loading}
            />
            <Button
              label="Cancel"
              severity="danger"
              icon="pi pi-times"
              onClick={() => closeModal()}
              disabled={loading}
            />
          </span>
        </div>
      );
    };

    return (
      <Dialog
        header="Add Barcode"
        visible={displayDialog}
        style={{ width: '800px' }}
        modal
        onHide={() => closeModal()}
        footer={dialogFooter()}
        closable={false}
      >
        <PackageBarcodeContent
          ref={barcodeContentRef}
          userCanChangeFields={userCanChangeFields}
          fieldsPermissions={fieldsPermissions}
        />
      </Dialog>
    );
  },
);

export default AddBarcode;
