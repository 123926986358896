import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { FiDownload } from 'react-icons/fi';
import { Skeleton } from 'primereact/skeleton';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Container } from './styles';
import { listSatCompareFilesQuery } from './queries';
import { AttachmentTypes } from '../../../../../shared/enums/attachmentType';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { SatAttachment } from './interfaces';
import FormRow from '../../../../../components/FormRow';
import { FileType } from '../../../../../shared/enums/fileType';
import Button from '../../../../../components/Button';
import { getAttachmentDownloadLinkQuery } from '../../../../../shared/querys/attachment';
import { createSatAttachmentsQuery } from '../../queries';
import FileUpload from '../../../../../components/FileUpload';
import { FileUploadResponse } from '../../../../../components/FileUpload/interfaces';

interface ICompareFileProps {
  idSat: number;
  selected: boolean;
  isSatCancelled: boolean;
}

const CompareFile: React.FC<ICompareFileProps> = ({
  idSat,
  selected,
  isSatCancelled,
}) => {
  const compareFileTemplateName = 'SAT_COMPARE_PURCHASE.xlsx';

  const { showSuccess, showError } = useRefHook();

  const compareFilesPanelRef = useRef<OverlayPanel>(null);

  const [createCompareFileMutation, { loading: uploadInProgress }] =
    useMutation(createSatAttachmentsQuery);

  const [compareFiles, setCompareFiles] = useState<SatAttachment[]>();

  const mostRecentCompareFile = compareFiles?.length
    ? compareFiles[0]
    : undefined;

  const [getCompareFiles, { loading: getCompareFilesLoading }] = useLazyQuery(
    listSatCompareFilesQuery,
    {
      variables: {
        data: {
          idSat,
          idTypes: [AttachmentTypes.COMPARE_TYPE_FILES],
          _orderBy: 'attachment.createdAt',
          _sortOrder: 'DESC',
        },
      },
      onCompleted: response => {
        setCompareFiles(response.listSatAttachmentsBySatId.data);
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting compare files',
          detail: errorData.message,
        });
      },
    },
  );

  const handleUploadCompareFile = async (e: FileUploadResponse[]) => {
    try {
      await createCompareFileMutation({
        variables: {
          data: {
            idSat,
            uploadedFiles: e.map(file => file.serverName),
            idType: AttachmentTypes.COMPARE_TYPE_FILES,
          },
        },
      });

      showSuccess({
        summary: 'Compare File uploaded',
      });

      getCompareFiles();
    } catch (error) {
      showError({
        summary: `Error while uploading Compare File`,
        detail: error.message,
      });
    }
  };

  const [getTemplateLink, { loading: getTemplateLinkLoading }] = useLazyQuery(
    getAttachmentDownloadLinkQuery,
    {
      variables: {
        name: compareFileTemplateName,
      },
      onCompleted: async response => {
        window.open(response.getAttachmentDownloadLink, '_blank');
      },
      onError: error => {
        showError({
          summary: 'Error while getting template',
          detail: error.message,
        });
      },
    },
  );

  useEffect(() => {
    // Busca compare files apenas se aba SAT Items estiver selecionada
    if (selected && !compareFiles) {
      getCompareFiles();
    }
  }, [compareFiles, getCompareFiles, selected]);

  return (
    <Container id="sat-compare-file">
      <h2>Compare File</h2>

      <FormRow>
        {getCompareFilesLoading && (
          <Skeleton width="9rem" height="2.5rem" borderRadius="4px" />
        )}
        {mostRecentCompareFile && !getCompareFilesLoading && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="latest-compare-file"
            href={mostRecentCompareFile?.idAttachment2.url}
          >
            <FiDownload size={18} className="svg-left" />
            COMPARE FILE
          </a>
        )}

        <FileUpload
          mode="basic"
          onConfirm={e => handleUploadCompareFile(e)}
          chooseLabel="Upload"
          chooseOptions={{
            icon: 'pi pi-upload',
          }}
          auto
          disabled={uploadInProgress || isSatCancelled}
          uploadInProgress={uploadInProgress}
          accept={`${FileType.XLS},${FileType.XLSX},${FileType.DOCX},${FileType.DOC},${FileType.PDF},${FileType.ALL_IMAGES}`}
        />

        <Button
          type="button"
          onClick={() => getTemplateLink()}
          label="Download Template"
          icon={<FiDownload size={17} />}
          loading={getTemplateLinkLoading}
        />

        <button
          type="button"
          className="items-log"
          onClick={e => compareFilesPanelRef.current?.toggle(e, e.target)}
        >
          <AiOutlineUnorderedList size={24} />
        </button>

        <OverlayPanel
          ref={compareFilesPanelRef}
          showCloseIcon
          dismissable
          appendTo={document.getElementById('sat-compare-file')}
          style={{ width: '400px' }}
        >
          <h2>Compare File - Log</h2>
          <DataTable
            value={compareFiles}
            style={{ maxWidth: '400px', marginTop: '12px' }}
            removableSort
            emptyMessage="No files to show"
            loading={getCompareFilesLoading}
            scrollable
            scrollHeight="14rem"
          >
            <Column
              field="idAttachment2.createdAt"
              header="Created At"
              body={(e: SatAttachment) =>
                new Date(e.idAttachment2.createdAt).toLocaleDateString()
              }
              sortable
            />
            <Column
              field="idAttachment2.createdBy2.firstName"
              header="Created By"
              body={(e: SatAttachment) =>
                `${e.idAttachment2.createdBy2?.firstName} ${e.idAttachment2.createdBy2?.lastName}`
              }
              sortable
            />
            <Column
              field="file"
              header="File"
              body={(e: SatAttachment) => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="latest-compare-file"
                  href={e.idAttachment2.url}
                >
                  <FiDownload size={18} className="svg-left" />
                </a>
              )}
            />
          </DataTable>
        </OverlayPanel>
      </FormRow>
    </Container>
  );
};

export default CompareFile;
