import {
  ManageForeignTradeContainerReducer,
  ManageForeignTradeContainerReducerAction,
  ManageForeignTradeContainerReducerActionKind,
} from './interfaces';

export const manageForeignTradeContainerInitialState: ManageForeignTradeContainerReducer =
  {
    displayDialog: false,
  };

export const manageForeignTradeContainerReducer = (
  state: ManageForeignTradeContainerReducer,
  action: ManageForeignTradeContainerReducerAction,
): ManageForeignTradeContainerReducer => {
  switch (action.type) {
    case ManageForeignTradeContainerReducerActionKind.CLOSE_DIALOG:
      return {
        ...state,
        displayDialog: false,
        containerName: undefined,
        idContainerToEdit: undefined,
      };

    case ManageForeignTradeContainerReducerActionKind.OPEN_DIALOG: {
      if (!state.displayDialog && action.payload?.shimpentContainer) {
        return {
          ...state,
          containerName: action.payload?.shimpentContainer.containerName,
          idContainerToEdit:
            action.payload?.shimpentContainer.idSatForeignTradeCtnr,
          displayDialog: !state.displayDialog,
        };
      }

      return { ...state, displayDialog: !state.displayDialog };
    }

    case ManageForeignTradeContainerReducerActionKind.SET_CONTAINER_NAME:
      return { ...state, containerName: action.payload?.containerName };

    default:
      throw new Error();
  }
};
