import { useLazyQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Skeleton } from 'primereact/skeleton';
import FormTitle from '../../../../../../components/FormTitle';
import Grid from '../../../../../../components/Grid';
import MainButton from '../../../../../../components/MainButton';
import pagination from '../../../../../../config/pagination';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import {
  SatFinancialAdvancePaidFieldsPermissions,
  SatFinancialAdvancePaidPermissions,
  SatForeignTradeSupplier,
} from '../../interfaces';
import { deleteSatForeignTradePaymentQuery } from '../../queries';
import AddAdvanceModal from '../AddAdvance';
import AddRollingModal from '../AddRolling';
import { SatForeignTradePayment } from './interfaces';
import {
  listAdvancePaidTotals,
  listAllSatForeignTradePaymentsQuery,
  requestAdvancePaymentQuery,
} from './queries';
import { Container } from './styles';
import {
  FinancialPurchasePaymentType,
  FinancialSellerPaymentType,
} from '../../../../../../shared/enums/domains';
import { parseToLocaleFormat } from '../../../../../../utils/dateUtil';
import { parseCurrencyPtBrNumberColumn } from '../../../../../../utils/gridColumnsParse';
import { GridActions } from '../../../../../../components/Grid/styles';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import {
  ForeignTradeSupplierContentReducerAction,
  ForeignTradeSupplierContentReducerActionKind,
} from '../interfaces';
import { numberToPtString } from '../../../../../../utils/formatLocale';
import { ISat } from '../../../interfaces';

interface IAdvancePaidProps {
  satForeignTradeSupplier: SatForeignTradeSupplier;
  selected: boolean;
  fieldsPermissions: SatFinancialAdvancePaidFieldsPermissions;
  permissions: SatFinancialAdvancePaidPermissions;
  estimatedTotalAdvance?: number;
  estimatedTotalBalance?: number;
  satCurrency?: string;
  foreignTradeSupplierContentDispatch: Dispatch<ForeignTradeSupplierContentReducerAction>;
  totalRemainCot?: number;
  estimatedAdvance?: number;
  sat: ISat;
  loadPurchaseTotalPaidData(): void;
}

const AdvancePaid: React.FC<IAdvancePaidProps> = ({
  satForeignTradeSupplier,
  selected,
  fieldsPermissions,
  permissions,
  estimatedTotalAdvance = 0,
  foreignTradeSupplierContentDispatch,
  satCurrency,
  estimatedTotalBalance = 0,
  totalRemainCot,
  estimatedAdvance = 0,
  sat,
  loadPurchaseTotalPaidData,
}) => {
  const { showError, showSuccess } = useRefHook();

  const [satForeignTradePaymentToEdit, setSatForeignTradePaymentToEdit] =
    useState<SatForeignTradePayment>();

  const [displayAddAdvanceModal, setDisplayAddAdvanceModal] = useState(false);

  const [displayAddRollingModal, setDisplayAddRollingModal] = useState(false);

  const [itemBeingDeleted, setItemBeingDeleted] = useState<number>();

  const [requestingPayment, setRequestingPayment] = useState<boolean>();

  const [paymentRequested, setPaymentRequested] = useState<boolean>();

  const [satForeignTradePayments, setSatForeignTradePayments] =
    useState<SatForeignTradePayment[]>();

  const [requestAdvancePaymentMutation] = useMutation(
    requestAdvancePaymentQuery,
  );

  const [deleteSatForeignTradePaymentMutation] = useMutation(
    deleteSatForeignTradePaymentQuery,
  );

  const estimatedTotal =
    (estimatedTotalAdvance ?? 0) + (estimatedTotalBalance ?? 0);

  const isPaymentToEditRollingType: boolean =
    satForeignTradePaymentToEdit?.idType ===
      FinancialPurchasePaymentType.ROLLING ||
    satForeignTradePaymentToEdit?.idType === FinancialSellerPaymentType.ROLLING;

  const [
    loadForeignTradePaymentsData,
    { loading: loadingForeignTradePaymentsData },
  ] = useLazyQuery(listAllSatForeignTradePaymentsQuery(fieldsPermissions), {
    variables: {
      data: {
        pagination: {
          _page: pagination.initialLazyParams.page + 1,
          _limit: 0,
        },
        idSatForeignTradeSupplier:
          satForeignTradeSupplier.idSatForeignTradeSupplier,
        idTypes: [
          FinancialPurchasePaymentType.ADVANCE,
          FinancialPurchasePaymentType.ROLLING,
        ],
      },
    },
    onCompleted: response => {
      if (response.listAllSatForeignTradePayments.data) {
        setSatForeignTradePayments(
          response.listAllSatForeignTradePayments.data,
        );
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting SAT Foreign Trade Payments',
        detail: errorData.message,
      });
    },
  });

  const [
    loadAdvancePaidTotals,
    { loading: advancePaidTotalsLoading, data: advancePaidTotalsData },
  ] = useLazyQuery(listAdvancePaidTotals, {
    variables: {
      idSatForeignTradeSupplier:
        satForeignTradeSupplier.idSatForeignTradeSupplier,
      typeIdDomainGroup: DomainGroup.FINANCIAL_PURCHASE_PAYMENT_TYPES,
    },
    onCompleted: response => {
      // Adiciona no dispatch pra ser utilizado no Balance
      foreignTradeSupplierContentDispatch({
        type: ForeignTradeSupplierContentReducerActionKind.SET_PURCHASE_ADVANCE_PAID_TOTAL,
        payload: {
          purchaseAdvancePaidTotal:
            response.listSatForeignTradeSupplierAdvanceTotals,
          satForeignTradeSupplier,
        },
      });
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Total Purchase Advance Paid',
        detail: errorData.message,
      });
    },
  });

  const loadGridData = useCallback(async () => {
    loadForeignTradePaymentsData();

    loadAdvancePaidTotals();

    loadPurchaseTotalPaidData();
  }, [
    loadForeignTradePaymentsData,
    loadAdvancePaidTotals,
    loadPurchaseTotalPaidData,
  ]);

  const sendPaymentRequest = useCallback(async () => {
    setRequestingPayment(true);
    try {
      const response = await requestAdvancePaymentMutation({
        variables: {
          idSatForeignTradeSupplier:
            satForeignTradeSupplier.idSatForeignTradeSupplier,
        },
      });

      if (response) {
        setPaymentRequested(response.data.requestAdvancePayment);
        showSuccess({ summary: 'Advance Payment Requested' });
        loadGridData();
      }
    } catch (error) {
      showError({
        summary: 'Error while requesting advance payment',
        detail: error.message,
      });
    } finally {
      setRequestingPayment(false);
    }
  }, [
    loadGridData,
    requestAdvancePaymentMutation,
    satForeignTradeSupplier.idSatForeignTradeSupplier,
    showError,
    showSuccess,
  ]);

  const deleteItem = useCallback(
    async (satForeignTradePaymentId: number) => {
      setItemBeingDeleted(satForeignTradePaymentId);
      try {
        await deleteSatForeignTradePaymentMutation({
          variables: {
            satForeignTradePaymentId,
          },
        });
        showSuccess({ summary: 'SAT Foreign Trade Payment deleted' });
        loadGridData();
      } catch (error) {
        showError({
          summary: 'Error while deleting SAT Foreign Trade Payment',
          detail: error.message,
        });
      } finally {
        setItemBeingDeleted(undefined);
      }
    },
    [
      deleteSatForeignTradePaymentMutation,
      loadGridData,
      showError,
      showSuccess,
    ],
  );

  function handleEditItem(rowData: SatForeignTradePayment) {
    setSatForeignTradePaymentToEdit(rowData);
    setDisplayAddAdvanceModal(true);
  }

  const handleDeleteItem = useCallback(
    async (payment: SatForeignTradePayment) => {
      const { totalAmount } =
        advancePaidTotalsData?.listSatForeignTradeSupplierAdvanceTotals;

      if ((totalAmount || 0) - (payment.amount || 0) < 0) {
        showError({
          summary: 'Deletion is not allowed, insufficient balance!',
        });
      } else {
        confirmDialog({
          message: 'Do you confirm to remove advance paid?',
          header: 'Delete Confirmation',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () => {
            if (payment.idSatForeignTradePayment) {
              deleteItem(payment.idSatForeignTradePayment);
            }
          },
        });
      }
    },
    [
      deleteItem,
      advancePaidTotalsData?.listSatForeignTradeSupplierAdvanceTotals,
      showError,
    ],
  );

  function paymentIsRollingFromOrigin(rowData: SatForeignTradePayment) {
    return (
      rowData.idSatForeignTradeSupplier ===
        satForeignTradeSupplier.idSatForeignTradeSupplier &&
      rowData.idType === FinancialPurchasePaymentType.ROLLING
    );
  }

  function paymentIsRollingFromDestination(rowData: SatForeignTradePayment) {
    return (
      rowData.idSatForeignTradeSupplierDestination ===
        satForeignTradeSupplier.idSatForeignTradeSupplier &&
      rowData.idType === FinancialPurchasePaymentType.ROLLING
    );
  }

  const gridActions = (rowData: SatForeignTradePayment) => {
    const isBeingDeleted =
      itemBeingDeleted === rowData.idSatForeignTradePayment;
    return (
      <GridActions>
        {permissions.updateItem && (
          <button
            type="button"
            onClick={() => handleEditItem(rowData)}
            disabled={
              rowData.idSatForeignTradeSupplierDestination ===
              satForeignTradeSupplier.idSatForeignTradeSupplier
            }
          >
            <FiEdit2 size={20} />
          </button>
        )}
        {permissions.removeItem && (
          <button
            type="button"
            className="trash-button"
            onClick={() => handleDeleteItem(rowData)}
            disabled={!!itemBeingDeleted || paymentIsRollingFromOrigin(rowData)}
          >
            {isBeingDeleted ? (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: '1.43rem' }}
              />
            ) : (
              <FiTrash2 size={20} />
            )}
          </button>
        )}
      </GridActions>
    );
  };

  function parsePercentageColumn(rowData: any) {
    if (rowData.amount && estimatedTotal) {
      const value = (rowData.amount / estimatedTotal) * 100;
      const formattedValue = numberToPtString(value, 2);
      return `${formattedValue}%`;
    }
    return undefined;
  }

  function showRollingFrom(rowData: any) {
    if (rowData.idType === FinancialPurchasePaymentType.ROLLING) {
      return rowData?.idSatForeignTrade2?.satForeignTradeNumber;
    }
    return '';
  }

  function getRowClassName(rowData: SatForeignTradePayment) {
    return {
      'ap-grid-rolling-from-origin': paymentIsRollingFromOrigin(rowData),
      'ap-grid-rolling-from-destination':
        paymentIsRollingFromDestination(rowData),
    };
  }

  const totalPercentageFooter = () => {
    if (advancePaidTotalsLoading) return <Skeleton />;

    const totalAmount =
      advancePaidTotalsData?.listSatForeignTradeSupplierAdvanceTotals
        .totalAmount;

    if (totalAmount && estimatedTotal) {
      const value = totalAmount / estimatedTotal;

      return value.toLocaleString('pt', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'percent',
      });
    }
    return undefined;
  };

  const totalAmountFooter = () => {
    if (advancePaidTotalsLoading) return <Skeleton />;

    const sumData =
      advancePaidTotalsData?.listSatForeignTradeSupplierAdvanceTotals
        .totalAmount;

    if (!sumData) {
      return '';
    }

    const formattedSumData = numberToPtString(sumData, 2);

    return `${getCurrencySymbol(satCurrency)} ${formattedSumData}`;
  };

  useEffect(() => {
    if (!displayAddAdvanceModal) {
      setSatForeignTradePaymentToEdit({});
    }
  }, [displayAddAdvanceModal]);

  useEffect(() => {
    if (!satForeignTradePayments && selected) {
      loadGridData();
    }
  }, [loadGridData, satForeignTradePayments, selected]);

  return (
    <Container>
      <Dialog
        header={`PAID - ${
          satForeignTradePaymentToEdit?.idSatForeignTradePayment
            ? 'Manage'
            : 'Add'
        } ${isPaymentToEditRollingType ? 'Rolling' : ''} Advance`}
        visible={displayAddAdvanceModal}
        onHide={() => setDisplayAddAdvanceModal(false)}
      >
        <AddAdvanceModal
          estimatedTotalAdvance={estimatedTotalAdvance}
          totalAmount={
            advancePaidTotalsData?.listSatForeignTradeSupplierAdvanceTotals
              .totalAmount
          }
          paymentType={FinancialPurchasePaymentType.ADVANCE}
          satForeignTradeSupplier={satForeignTradeSupplier}
          satForeignTradePayment={satForeignTradePaymentToEdit}
          setDisplayModal={setDisplayAddAdvanceModal}
          refetchForeignTradePaymentsData={loadGridData}
          estimatedTotalBalance={estimatedTotalBalance}
          totalRemain={totalRemainCot}
        />
      </Dialog>
      <Dialog
        header={`PAID - ${
          satForeignTradePaymentToEdit?.idSatForeignTradePayment
            ? 'Manage'
            : 'Add'
        } Rolling Advance`}
        visible={displayAddRollingModal}
        onHide={() => setDisplayAddRollingModal(false)}
      >
        <AddRollingModal
          paymentType={FinancialPurchasePaymentType.ROLLING}
          satForeignTradeSupplier={satForeignTradeSupplier}
          setDisplayModal={setDisplayAddRollingModal}
          refetchForeignTradePaymentsData={loadGridData}
          sat={sat}
        />
      </Dialog>
      <FormTitle className="p-col-12 p-md-12 p-mt-5" name="Advance Paid">
        {permissions.addItem && (
          <MainButton
            type="button"
            label="Add Advance"
            onClick={() => setDisplayAddAdvanceModal(true)}
          />
        )}
        {permissions.addRolling && (
          <MainButton
            type="button"
            label="Add Rolling"
            onClick={() => setDisplayAddRollingModal(true)}
            disabled={
              (advancePaidTotalsData?.listSatForeignTradeSupplierAdvanceTotals
                .totalAmount || 0) <= 0
            }
          />
        )}
        {permissions.requestPayment && (
          <MainButton
            type="button"
            label="Payment Request"
            onClick={() => sendPaymentRequest()}
            disabled={
              paymentRequested ||
              requestingPayment ||
              !!satForeignTradeSupplier.advancePaymentRequest ||
              estimatedAdvance <= 0
            }
          >
            {requestingPayment && (
              <i
                className="pi pi-spin pi-spinner p-ml-2"
                style={{ fontSize: '1.43rem' }}
              />
            )}
          </MainButton>
        )}
      </FormTitle>
      <Grid
        emptyMessage="No advance paid found"
        loading={loadingForeignTradePaymentsData}
        value={satForeignTradePayments}
        paginator={false}
        rowClassName={getRowClassName}
      >
        <Column
          field="actions"
          headerStyle={{ width: '6rem' }}
          body={gridActions}
        />
        {fieldsPermissions.percentage.view && (
          <Column
            field="percentage"
            header="Advance %"
            style={{ width: '7rem' }}
            body={parsePercentageColumn}
            footer={() => totalPercentageFooter()}
          />
        )}
        {fieldsPermissions.amount.view && (
          <Column
            field="amount"
            header="Amount"
            style={{ width: '7rem' }}
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 2, satCurrency)
            }
            footer={() => totalAmountFooter()}
          />
        )}
        {fieldsPermissions.paymentDate.view && (
          <Column
            field="paymentDate"
            header="Payment Date"
            style={{ width: '7rem' }}
            body={e => parseToLocaleFormat(e.paymentDate)}
          />
        )}
        {fieldsPermissions.rollingFrom.view && (
          <Column
            field="idSatForeignTrade2.satForeignTradeNumber"
            header="Rolling From"
            style={{ width: '7rem' }}
            body={showRollingFrom}
          />
        )}
        {fieldsPermissions.rollingTo.view && (
          <Column
            field="idSatForeignTradeDestination2.satForeignTradeNumber"
            header="Rolling To"
            style={{ width: '7rem' }}
          />
        )}
      </Grid>
    </Container>
  );
};
export default AdvancePaid;
