/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import Grid from '../../components/Grid';
import { gridConstants } from '../../components/Grid/constants';
import MultiSelect from '../../components/Grid/MultiSelect';
import Loading from '../../components/Loading';
import MainButton from '../../components/MainButton';
import PageHeader from '../../components/PageHeader';
import { searchDelayMiliseconds } from '../../config/pagination';
import { useRefHook } from '../../hooks/useRefHook';
import useTitle from '../../hooks/useTitle';
import ToastLife from '../../shared/enums/toastLife';
import updateLocalStorageInDb from '../../utils/updateLocalStorageInDb';
import PickFuncionalityAndRolesDialog, {
  IPickFuncionalityAndRolesDialogRef,
} from './PickFuncionalityAndRolesDialog';
import { Container } from './styles';

interface IRole {
  idRole: number;
  name: string;
  active: boolean;
}

/**
 * Interface dos parametros do backend
 */
interface ILazyParams {
  first: number;
  rows: number;
  page: number;
  sortField?: string;
  sortOrder?: DataTableSortOrderType;
  globalFilter?: string;
  name?: string;
  active?: boolean;
}

const pageTitle = 'List of Roles';

const Roles: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:rolesGridColumns';

  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { toastRef } = useRefHook();

  const pickFuncionalityAndRolesDialogRef =
    useRef<IPickFuncionalityAndRolesDialogRef>(null);

  // Referencia a grid
  const gridRef = useRef<DataTable>(null);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Colunas da grid
  const columns = [
    { field: 'name', header: 'Name' },
    { field: 'active', header: 'Active' },
  ];

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ILazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  // Busca global na grid
  const [globalFilter, setGlobalFilter] = useState('');

  // Estado de scrollHeight da grid
  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );
  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar roles
  const listRolesQuery = gql`
    query listAllRolesQuery(
      $listAllRolesInput: ListAllRolesInput
      $pagination: Pagination!
    ) {
      listAllRoles(
        listAllRolesInput: $listAllRolesInput
        pagination: $pagination
      ) {
        data {
          idRole
          name
          active
        }
        items
      }
    }
  `;

  /**
   * Busca Roles
   */
  const {
    loading: rolesLoading,
    data: rolesData,
    error: rolesError,
  } = useQuery(listRolesQuery, {
    variables: {
      listAllRolesInput: {
        globalSearch: lazyParams.globalFilter,
        name: lazyParams.name,
        active: lazyParams.active,
      },
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting roles',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Define a selecao de colunas no estado
   */
  useEffect(() => {
    // Busca colunas selecionadas salvas no local storage
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    }
  }, []);

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return <span className="custom-header">{headerName}</span>;
  }

  /**
   * Valida se coluna active é true ou false
   * @param rowData Dados da linha
   * @returns Campo com true ou false
   */
  const parseActiveColumn = (rowData: IRole) => {
    return <p>{rowData.active ? 'Yes' : 'No'}</p>;
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    if (field === 'active') return parseActiveColumn;
    return undefined;
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      <Column
        key={col.field}
        columnKey={col.field}
        body={handleColumn(col.field)}
        field={col.field}
        style={{ flex: 'auto', width: '100px' }}
        // Valida necessidade de icone de filtro no header
        header={handleColumnHeader(col.header)}
        sortable
      />
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeParams) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina da role clicada
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(`/roles/${e.data.idRole}`);
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortParams) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  return (
    <Container>
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar role */}
        <MainButton
          className="mainButton"
          label="New Role"
          onClick={() => history.push('/roles/create')}
        />
        {/* Botao para listar entities */}
        <Button
          className="secondaryButton"
          label="Roles by Functionality"
          onClick={() =>
            pickFuncionalityAndRolesDialogRef.current?.toggleDialog()
          }
        />

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns}
          onChange={onColumnToggle}
        />
        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a role"
        />
        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <PickFuncionalityAndRolesDialog ref={pickFuncionalityAndRolesDialogRef} />
      <Grid
        ref={gridRef}
        className="grid"
        name="roles"
        lazy
        totalRecords={
          !rolesData || rolesError ? 0 : rolesData.listAllRoles.items
        }
        value={
          !rolesData || rolesError ? undefined : rolesData.listAllRoles.data
        }
        globalFilter={globalFilter}
        emptyMessage="No roles found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={!rolesData || rolesError ? undefined : lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selectionMode="single"
      >
        {dynamicColumns}
      </Grid>
      {rolesLoading && <Loading />}
    </Container>
  );
};
export default Roles;
