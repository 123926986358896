/* eslint-disable no-shadow */

/**
 * Dominio de PRC Status
 */
export enum PrcStatus {
  DRAFT = 608,
  APPROVED = 609,
  CANCELED = 610,
  WAITING_PROCUREMENT = 616,
  AT_PROCUREMENT = 617,
  WAITING_PURCHASE = 766,
  AT_PURCHASE = 767,
  WAITING_MANAGEMENT = 768,
  AT_MANAGEMENT = 769,
  WAITING_COMMERCIAL = 770,
  AT_COMMERCIAL = 771,
}

/**
 * Dominio de PRC File Types
 */
export enum PrcFileType {
  PRC_ITEMS = 611,
  PRC_COMPARE = 612,
  PRC_BEST_PRICE = 613,
  PRC_QUOTATION = 614,
  PRC_CATALOGUE = 792,
}

/**
 * Dominio de PRC Type
 */
export enum PrcType {
  NEW_LINE = 599,
  NEW_TREND = 600,
  LINE_INCREASING = 601,
  LINE_IMPROVEMENT = 602,
  UPDATE_QUOTATION = 603,
  CATALOGUE = 604,
}

/**
 * Dominios de Type of Package
 */
export enum TypeOfPackage {
  ASSORTMENT_SIZES = 758,
  ONE_SIZE_PER_BOX = 759,
}

export enum ForeignTradeStatus {
  DONE = 835,
  STAND_BY = 836,
  CANCELED = 891,
  WAIT_QUOTATION = 1064,
  WAIT_IL_GRANTING = 1065,
  WAIT_ARTWORK = 1066,
  WAIT_SHIPMENT = 1067,
  WAIT_SHIPMENT_WAIT_IL = 1068,
  WAIT_SHIPMENT_WAIT_BALANCE_PAYMENT = 1069,
  WAIT_CLOSURE = 1070,
  WAIT_FOR_DRAFT = 1071,
  WAIT_ADVANCE_PAYMENT = 1072,
  WAIT_SISCOSEV = 1073,
  REGISTER = 1074,
  CLEARED_WAIT_LOADING = 1075,
  CLEARED_WAIT_DELIVERY = 1076,
  ON_CLEARANCE = 1077,
  ON_PRODUCTION = 1078,
  ON_PRODUCTION_WAIT_AUTHORIZATION = 1079,
  ON_PRODUCTION_WAIT_BOOKING = 1080,
  ON_PRODUCTION_WAIT_QUOTATION = 1081,
  ON_PRODUCTION_BOOKING_RECEIVED = 1082,
  SHIPPED_WAIT_DRAFTS_APPROVAL = 1083,
  SHIPPED_WAIT_BL = 1084,
  SHIPPED_WAIT_ARRIVAL = 1085,
  SHIPPED_WAIT_ORIGINALS = 1086,
  SHIPPED_WAIT_BALANCE_PAYMENT = 1087,
  DELIVERED_WAIT_INVOICE = 1088,
  DELIVERED_WAIT_BALANCE = 1089,
  BILLED_WAIT_PAYMENT = 1090,
  INSPECTION_RECEIVED = 1091,
  NEW_ORDER_WAITING_SCHEDULE = 1092,
}

export enum ForeignTradeTypeFiles {
  INSPECTION_REPORT = 848,
  SHIPPING_INSTRUCTION = 849,
  LOADING_PICTURES = 850,
  HBL_FINAL = 851,
  CI_PL_TO_SIGN = 852,
  CI_PL_SIGNED = 853,
  OTHER_DOCS = 854,
}

export enum FinancialPurchaseOtherExpenses {
  FREIGHT = 865,
  FOB_CHARGES = 866,
  SAMPLES = 867,
  YIWU_COMISSION = 868,
  OTHERS = 869,
  DEBIT_NOTE = 980,
  FREIGHT_TAXES = 1010,
}

export enum FinancialPurchaseFiles {
  COT_PI = 998,
  COT_CI = 999,
  PURCHASE_CONTRACT = 1000,
  PURCHASE_ADVANCE_SWIFT = 1001,
  PURCHASE_BALANCE_SWIFT = 1002,
}

export enum FinancialSellerFiles {
  SELLING_ADVANCE_SWIFT = 1008,
  SELLING_BALANCE_SWIFT = 1009,
}

export enum FinancialSellerOtherExpenses {
  FREIGHT = 879,
  FREIGHT_TAXES = 880,
  FOB_CHARGES = 881,
  SAMPLES = 882,
  YIWU_COMISSION = 883,
  OTHERS = 884,
  CREDIT_NOTE = 979,
}

export enum FinancialPurchasePaymentType {
  ADVANCE = 870,
  ROLLING = 871,
  BALANCE = 872,
}

export enum FinancialSellerPaymentType {
  ADVANCE = 1003,
  ROLLING = 1004,
  BALANCE = 1005,
}

export enum PortType {
  AIRPORT = 1,
  PORT = 2,
}

export enum ConfirmOrderFilesType {
  SUN_PI = 1132,
  COT_PI = 1133,
}

export enum SatStatusHandlerLogType {
  ERROR = 1409,
  SAT_BEING_UPDATED = 1410,
}
