import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ProgressBar } from 'primereact/progressbar';
import {
  FileUpload as PrimeFileUpload,
  FileUploadHandlerParam,
  FileUploadProps,
  ItemTemplateOptions,
} from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import crypto from 'crypto';
import axios from 'axios';
import {
  fileExceedMaxSize,
  maxFileSizeInBytes,
} from '../../utils/uploadFileSize';
import { useRefHook } from '../../hooks/useRefHook';
import imagePlaceholder from '../../assets/imagePlaceholder.svg';
import satApi from '../../services/axios/satApi';
import {
  FileUploadRef,
  FileUploadResponse,
  GetAttachmentUploadPresignedUrlResponse,
} from './interfaces';
import Loading from '../Loading';

interface IFileUploadProps extends FileUploadProps {
  onConfirm?(data: FileUploadResponse[]): void;
  uploadInProgress?: boolean;
  ref?: Ref<FileUploadRef>;
  showFullscreenLoading?: boolean;
}

const FileUpload: React.FC<IFileUploadProps> = forwardRef(
  (
    {
      onConfirm,
      multiple,
      className,
      uploadInProgress,
      disabled,
      mode,
      chooseOptions,
      showFullscreenLoading,
      ...rest
    },
    ref,
  ) => {
    const { toastRef, showError } = useRefHook();

    const [loading, setLoading] = useState(false);

    const fileUploadRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      choose: () => {
        fileUploadRef.current.choose();
      },
    }));

    function getUserFriendlyErrorMessage(error: string | null) {
      if (error && error.includes('$Content-Type')) {
        return 'Invalid file type';
      }

      return 'Unknown error. Please try again or contact support.';
    }

    /**
     * Cria os arquivos de attachment
     * @param event Evento de upload
     */
    async function myUploader(event: FileUploadHandlerParam) {
      if (onConfirm) {
        try {
          setLoading(true);

          // Busca apenas os que nao foram enviados para o bucket
          const filesToUpload = event.files.filter(
            (_, index) => !fileUploadRef.current?.state.files[index].serverName,
          );

          if (filesToUpload.length) {
            const typesToUpload = filesToUpload.map(file => file.type);

            const presignedUrlData = await satApi.post(
              'attachment/presigned-url/upload',
              {
                fileTypes: typesToUpload,
              },
            );

            const presignedUrlsByTypes = presignedUrlData.data;

            const uploadPromises = filesToUpload.map(async file => {
              const { fields, url } = presignedUrlsByTypes.find(
                (item: GetAttachmentUploadPresignedUrlResponse) =>
                  item.fileType === file.type,
              );

              // Busca indice do arquivo na listagem do evento, pois o array
              // do map esta filtrado
              const index = event.files.findIndex(item => item === file);

              // Limpa erro do arquivo para evitar que continue sendo exibido
              // durante novo upload
              fileUploadRef.current.files[index].error = undefined;

              // Gera nome unico para o arquivo
              const fileHash = crypto.randomBytes(16).toString('hex');
              const hashedFileName = `${fileHash}.${file.name
                .split('.')
                .pop()}`;

              const formData = new FormData();
              Object.keys(fields).forEach(key => {
                formData.append(key, fields[key]);
              });

              // Altera o parametro ${filename} pelo nome do arquivo, para que seja
              // possivel enviar o arquivo com o nome correto para o bucket
              formData.set(
                'key',
                // eslint-disable-next-line no-template-curly-in-string
                fields.key.replace('${filename}', hashedFileName),
              );

              // Salva o nome original do arquivo para download posterior caso
              // necessario
              formData.append(
                'Content-Disposition ',
                `inline; filename=${file.name}`,
              );

              formData.append('Content-Type', file.type);
              formData.append('file', file);

              return axios
                .post(url, formData, {
                  responseType: 'text',
                  onUploadProgress: progressEvent => {
                    const progress = (
                      (progressEvent.progress ?? 0) * 100
                    ).toFixed(2);
                    fileUploadRef.current.files[index].progress = progress;
                    // Garante que progresso seja atualizado na tela
                    fileUploadRef.current.forceUpdate();
                  },
                })
                .then(() => {
                  fileUploadRef.current.files[index].serverName =
                    hashedFileName;
                  fileUploadRef.current.files[index].originalName =
                    file.name.replace(/\.[^/.]+$/, '');
                  fileUploadRef.current.files[index].fileUrl =
                    URL.createObjectURL(file);
                })
                .catch(err => {
                  // Como resposta do S3 vem em XML, eh necessario fazer o parse
                  // para buscar a mensagem de erro
                  const parser = new DOMParser();
                  const xmlDoc = parser.parseFromString(
                    err.response.data,
                    'text/xml',
                  );

                  const errorMessage =
                    xmlDoc.getElementsByTagName('Message')[0].textContent;

                  const userFriendlyErrorMessage =
                    getUserFriendlyErrorMessage(errorMessage);
                  fileUploadRef.current.files[index].error =
                    userFriendlyErrorMessage;

                  // Remove valor de progress para que usuario saiba que arquivo
                  // nao foi enviado
                  fileUploadRef.current.files[index].progress = undefined;
                  fileUploadRef.current.forceUpdate();
                });
            });

            await axios.all(uploadPromises);

            if (
              fileUploadRef.current.state.files.some(
                (item: FileUploadResponse) => !item.serverName,
              )
            ) {
              showError({
                summary: 'Error while uploading files',
                detail: 'One or more files failed to upload. Please try again.',
              });

              return;
            }
          }

          const filesResponse = fileUploadRef.current.state.files.map(
            (file: FileUploadResponse) => {
              return {
                serverName: file.serverName,
                originalName: file.originalName,
                fileUrl: file.fileUrl,
              };
            },
          );

          onConfirm(filesResponse);

          // Se o modo for basic, eh necessario limpar arquivos do componente
          // para evitar que sejam enviados novamente no proximo clique
          if (mode === 'basic') {
            event.options.clear();
          }
        } catch (error) {
          const summary = 'Error while uploading files';
          // Se for um erro da API
          if (error.response) {
            showError({
              summary,
              detail: error.response.data.error,
            });
          } else {
            showError({
              summary,
              detail: 'Please try again or contact support.',
            });
          }
        } finally {
          setLoading(false);
        }
      }
    }

    /**
     * Renderiza lista de itens
     * @param file Dados do arquivo
     * @param props Props do componente
     * @returns Itens renderizados
     */
    const itemTemplate = (file: any, props: ItemTemplateOptions) => {
      // Busca indice do arquivo na listagem
      const itemIndex = fileUploadRef.current?.files.findIndex(
        (item: any) => item === file,
      );

      return (
        <div className="p-d-flex p-ai-center p-flex-wrap">
          <div className="p-d-flex p-ai-center" style={{ width: '90%' }}>
            <img
              alt={file.name}
              src={
                file.type?.includes('image') ? file.objectURL : imagePlaceholder
              }
              width={100}
            />
            <span className="w-full p-ml-3 p-mr-3">
              <InputText
                className="w-full"
                name="fileName"
                defaultValue={file.name.replace(/\.[^/.]+$/, '')}
                onChange={e => {
                  Object.defineProperty(file, 'name', {
                    writable: true,
                    value: e.target.value.trim()
                      ? `${e.target.value.trim()}.${e.currentTarget.placeholder
                          .split('.')
                          .pop()}`
                      : e.currentTarget.placeholder,
                  });

                  fileUploadRef.current?.files.splice(itemIndex, 1, file);
                }}
                placeholder={file.name}
                disabled={disabled || file.progress !== undefined}
              />
              {file.progress !== undefined && (
                <ProgressBar
                  className="p-mt-2"
                  value={file.progress}
                  style={{ height: '21px' }}
                />
              )}
              {!!file.error && <small className="p-error">{file.error}</small>}
            </span>
          </div>
          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
            onClick={e => props.onRemove(e)}
            disabled={disabled}
          />
        </div>
      );
    };

    function renderChooseOptions() {
      if (!loading || multiple) return chooseOptions;

      return {
        ...chooseOptions,
        icon: 'pi pi-spin pi-spinner',
      };
    }

    return (
      <>
        <PrimeFileUpload
          ref={fileUploadRef as any}
          multiple={multiple}
          maxFileSize={maxFileSizeInBytes}
          onValidationFail={e => fileExceedMaxSize(e, toastRef)}
          customUpload
          uploadHandler={myUploader}
          emptyTemplate={
            multiple ? (
              <p className="p-m-0">Drag and drop files to here to upload.</p>
            ) : undefined
          }
          itemTemplate={multiple ? itemTemplate : undefined}
          cancelLabel="Clear All"
          uploadLabel="Confirm"
          uploadOptions={{
            icon:
              uploadInProgress || loading
                ? 'pi pi-spin pi-spinner'
                : 'pi pi-check',
          }}
          mode={mode}
          {...rest}
          disabled={disabled || loading || uploadInProgress}
          className={`s-file-upload ${className ?? ''}`}
          chooseOptions={renderChooseOptions()}
        />
        {showFullscreenLoading && loading && <Loading />}
      </>
    );
  },
);

export default FileUpload;
