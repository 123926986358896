import React, { useEffect, useMemo, useReducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Row } from '../styles';
import Purchase from './Purchase';
import { getSumDatFromFinancialQuery } from './queries';
import {
  ForeignTradeSupplierTotalFieldsPermissions,
  ForeignTradeSupplierTotalReducerActionKind,
} from './interfaces';
import { useRefHook } from '../../../../../hooks/useRefHook';
import ToastLife from '../../../../../shared/enums/toastLife';
import { ISat } from '../../interfaces';
import PurchasePi from './PurchasePi';
import PurchaseCi from './PurchaseCi';
import PurchaseOtherExpenses from './PurchaseOtherExpenses';
import PurchaseOtherExpensesTotal from './PurchaseOtherExpensesTotal';
import PurchaseAdvancePaid from './PurchaseAdvancePaid';
import PurchaseBalancePaid from './PurchaseBalancePaid';
import { foreignTradeSupplierTotalReducer } from './reducers';
import PurchaseTotalPaid from './PurchaseTotalPaid';
import {
  FinancialFieldsCommonWithSatFieldPermissions,
  FinancialFieldsPermissions,
  SatFinancialAdvancePaidFieldsPermissions,
  SatFinancialAdvanceReceivedFieldsPermissions,
  SatFinancialBalanceFieldsPermissions,
  SatFinancialPurchaseOtherExpenseFieldsPermissions,
  SatFinancialPurchaseOtherExpensesTotalFieldsPermissions,
  SatFinancialResultsPermissions,
  SatFinancialSellerOtherExpenseFieldsPermissions,
  SatFinancialSellerOtherExpensesTotalFieldsPermissions,
  SatFinancialTotalFieldsPermissions,
  UserCanObject,
} from '../interfaces';
import Seller from './Seller';
import SellerPi from './SellerPi';
import SellerCi from './SellerCi';
import SellerOtherExpenses from './SellerOtherExpenses';
import SellerOtherExpensesTotal from './SellerOtherExpensesTotal';
import SellerAdvanceReceived from './SellerAdvanceReceived';
import SellerBalanceReceived from './SellerBalanceReceived';
import SellerTotalReceived from './SellerTotalReceived';
import getFieldPermission from '../../../../../utils/getFieldPermission';
import {
  satFinancialPurchaseOtherExpensesRoles,
  satFinancialSellerOtherExpensesRoles,
} from '../../../../../shared/roles/sat';
import { IUserFieldsAndPermissionsResponse } from '../../../../../utils/getUserFieldsAndPermissionsByEntity';
import Results from './Results';

interface ITotalProps {
  commonWithSatFieldsPermissions: FinancialFieldsCommonWithSatFieldPermissions;
  fieldsPermissions: FinancialFieldsPermissions;
  purchaseAdvancePaidFieldsPermissions: SatFinancialAdvancePaidFieldsPermissions;
  purchaseBalancePaidFieldsPermissions: SatFinancialBalanceFieldsPermissions;
  sellerBalanceReceivedFieldsPermissions: SatFinancialBalanceFieldsPermissions;
  purchaseOtherExpensesFieldsPermissions: SatFinancialPurchaseOtherExpenseFieldsPermissions;
  purchaseOtherExpensesTotalFieldsPermissions: SatFinancialPurchaseOtherExpensesTotalFieldsPermissions;
  sat: ISat;
  selected: boolean;
  sellerOtherExpensesFieldsPermissions: SatFinancialSellerOtherExpenseFieldsPermissions;
  sellerOtherExpensesTotalFieldsPermissions: SatFinancialSellerOtherExpensesTotalFieldsPermissions;
  purchaseTotalPaidFieldsPermissions: SatFinancialTotalFieldsPermissions;
  sellerTotalReceivedFieldsPermissions: SatFinancialTotalFieldsPermissions;
  userCanObjects: UserCanObject;
  userPermissions: IUserFieldsAndPermissionsResponse;
  sellerAdvanceReceivedFieldsPermissions: SatFinancialAdvanceReceivedFieldsPermissions;
  resultsFieldsPermissions: SatFinancialResultsPermissions;
}

const Total: React.FC<ITotalProps> = ({
  commonWithSatFieldsPermissions,
  fieldsPermissions,
  purchaseAdvancePaidFieldsPermissions,
  purchaseBalancePaidFieldsPermissions,
  purchaseOtherExpensesFieldsPermissions,
  purchaseOtherExpensesTotalFieldsPermissions,
  sat,
  selected,
  sellerOtherExpensesFieldsPermissions,
  sellerOtherExpensesTotalFieldsPermissions,
  purchaseTotalPaidFieldsPermissions,
  userCanObjects,
  userPermissions,
  sellerAdvanceReceivedFieldsPermissions,
  sellerBalanceReceivedFieldsPermissions,
  sellerTotalReceivedFieldsPermissions,
  resultsFieldsPermissions,
}) => {
  const { toastRef } = useRefHook();

  const [totalState, totalDispatch] = useReducer(
    foreignTradeSupplierTotalReducer,
    {},
  );

  const [loadTotalData, { loading: totalDataLoading, data }] = useLazyQuery(
    getSumDatFromFinancialQuery,
    {
      variables: {
        idSat: sat.idSat,
      },
      onCompleted: response => {
        if (response.getSumDataFromSatForeignTradeSuppliers) {
          totalDispatch({
            type: ForeignTradeSupplierTotalReducerActionKind.SET_FINANCIAL_TOTAL,
            payload: {
              financialTotal: response.getSumDataFromSatForeignTradeSuppliers,
            },
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Total Financial',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });

        totalDispatch({
          type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_FINANCIAL_TOTAL_LOADING,
        });
      },
    },
  );

  const totalFieldsPermissions: ForeignTradeSupplierTotalFieldsPermissions =
    useMemo(() => {
      return {
        purchaseOtherExpenses: {
          sunNumber: getFieldPermission(
            satFinancialPurchaseOtherExpensesRoles.fields.idFieldSunNumber,
            userPermissions.userFields,
          ),
          satForeignTradeNumber: getFieldPermission(
            satFinancialPurchaseOtherExpensesRoles.fields
              .idFieldSatForeignTradeNumber,
            userPermissions.userFields,
          ),
          satForeignTradeDestinationNumber: getFieldPermission(
            satFinancialPurchaseOtherExpensesRoles.fields
              .idFieldSatForeignTradeDestinationNumber,
            userPermissions.userFields,
          ),
        },
        sellerOtherExpenses: {
          sunNumber: getFieldPermission(
            satFinancialSellerOtherExpensesRoles.fields.idFieldSunNumber,
            userPermissions.userFields,
          ),
          satForeignTradeNumber: getFieldPermission(
            satFinancialSellerOtherExpensesRoles.fields
              .idFieldSatForeignTradeNumber,
            userPermissions.userFields,
          ),
          satForeignTradeDestinationNumber: getFieldPermission(
            satFinancialSellerOtherExpensesRoles.fields
              .idFieldSatForeignTradeDestinationNumber,
            userPermissions.userFields,
          ),
        },
      };
    }, [userPermissions.userFields]);

  const userCanSeePurchaseOtherExpenses =
    userCanObjects.userCanSeePurchaseOtherExpensesBlock ||
    totalFieldsPermissions.purchaseOtherExpenses.sunNumber.view ||
    totalFieldsPermissions.purchaseOtherExpenses.satForeignTradeNumber.view ||
    totalFieldsPermissions.purchaseOtherExpenses
      .satForeignTradeDestinationNumber.view;

  const userCanSeeSellerOtherExpenses =
    userCanObjects.userCanSeeSellerOtherExpensesBlock ||
    totalFieldsPermissions.sellerOtherExpenses.sunNumber.view ||
    totalFieldsPermissions.sellerOtherExpenses.satForeignTradeNumber.view ||
    totalFieldsPermissions.sellerOtherExpenses.satForeignTradeDestinationNumber
      .view;

  useEffect(() => {
    if (!data && selected) {
      totalDispatch({
        type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_FINANCIAL_TOTAL_LOADING,
      });

      loadTotalData();
    }
  }, [selected, data, loadTotalData]);

  return (
    <div className="p-d-flex">
      <div>
        <Row>
          <Purchase
            sat={sat}
            commonWithSatFieldsPermissions={commonWithSatFieldsPermissions}
          />

          <Seller
            sat={sat}
            commonWithSatFieldsPermissions={commonWithSatFieldsPermissions}
          />
        </Row>

        <Row>
          <PurchasePi
            sat={sat}
            totalData={totalState.financialTotal}
            loading={totalDataLoading}
            fieldsPermissions={fieldsPermissions}
          />

          <SellerPi
            sat={sat}
            totalData={totalState.financialTotal}
            loading={totalDataLoading}
            fieldsPermissions={fieldsPermissions}
          />
        </Row>

        <Row>
          <PurchaseCi
            sat={sat}
            totalData={totalState.financialTotal}
            loading={totalDataLoading}
            fieldsPermissions={fieldsPermissions}
          />

          <SellerCi
            sat={sat}
            totalState={totalState}
            fieldsPermissions={fieldsPermissions}
          />
        </Row>

        <Row>
          {userCanSeePurchaseOtherExpenses ? (
            <PurchaseOtherExpenses
              idSat={sat.idSat}
              selected={selected}
              fieldsPermissions={purchaseOtherExpensesFieldsPermissions}
              totalOtherExpensesFieldsPermissions={
                totalFieldsPermissions.purchaseOtherExpenses
              }
            />
          ) : (
            <div />
          )}

          {userCanSeeSellerOtherExpenses ? (
            <SellerOtherExpenses
              idSat={sat.idSat}
              selected={selected}
              totalOtherExpensesFieldsPermissions={
                totalFieldsPermissions.sellerOtherExpenses
              }
              fieldsPermissions={sellerOtherExpensesFieldsPermissions}
            />
          ) : (
            <div />
          )}
        </Row>

        <Row>
          <PurchaseOtherExpensesTotal
            idSat={sat.idSat}
            selected={selected}
            totalDispatch={totalDispatch}
            totalState={totalState}
            fieldsPermissions={purchaseOtherExpensesTotalFieldsPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
          />

          <SellerOtherExpensesTotal
            idSat={sat.idSat}
            selected={selected}
            totalDispatch={totalDispatch}
            totalState={totalState}
            fieldsPermissions={sellerOtherExpensesTotalFieldsPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
          />
        </Row>

        <Row>
          <PurchaseAdvancePaid
            idSat={sat.idSat}
            selected={selected}
            totalDispatch={totalDispatch}
            fieldsPermissions={purchaseAdvancePaidFieldsPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
          />

          <SellerAdvanceReceived
            idSat={sat.idSat}
            selected={selected}
            totalDispatch={totalDispatch}
            fieldsPermissions={sellerAdvanceReceivedFieldsPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
          />
        </Row>

        <Row>
          <PurchaseBalancePaid
            idSat={sat.idSat}
            selected={selected}
            totalDispatch={totalDispatch}
            fieldsPermissions={purchaseBalancePaidFieldsPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
          />

          <SellerBalanceReceived
            idSat={sat.idSat}
            selected={selected}
            totalDispatch={totalDispatch}
            fieldsPermissions={sellerBalanceReceivedFieldsPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
          />
        </Row>

        <Row>
          <PurchaseTotalPaid
            totalState={totalState}
            fieldsPermissions={purchaseTotalPaidFieldsPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
          />

          <SellerTotalReceived
            totalState={totalState}
            fieldsPermissions={sellerTotalReceivedFieldsPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
          />
        </Row>
      </div>

      <Results
        totalState={totalState}
        satCurrencyAbbreviation={sat.idCurrency2?.abbreviation}
        fieldsPermissions={resultsFieldsPermissions}
        userCan={userCanObjects.userCanResultsBlock}
        satCurrency={sat.idCurrency2?.abbreviation}
      />
    </div>
  );
};

export default Total;
