import { FormHandles } from '@unform/core';
import { differenceInDays } from 'date-fns';
import React, { Fragment, RefObject } from 'react';
import Empty from '../../../../../../components/Empty';
import FormAsyncSelect from '../../../../../../components/FormAsyncSelect';
import FormInput from '../../../../../../components/FormInput';
import FormInputNumber from '../../../../../../components/FormInputNumber';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import { asyncSelectLoadDomains } from '../../../../../../shared/querys/domain';
import { asyncSelectLoadUsersOptions } from '../../../../../../shared/querys/user';
import {
  ForeignTradeFieldsPermissions,
  SatForeignTradeSupplier,
} from '../../interfaces';
import { SunNumber } from './styles';
import FormCheckbox from '../../../../../../components/FormCheckbox';

interface IProductionProps {
  satForeignTradeSuppliers?: SatForeignTradeSupplier[];
  fieldsPermissions: ForeignTradeFieldsPermissions;
  shipmentIsCanceled: boolean;
  formRef: RefObject<FormHandles>;
  shipmentAuthorized?: string;
  noReport?: boolean;
}

const Production: React.FC<IProductionProps> = ({
  satForeignTradeSuppliers,
  fieldsPermissions,
  shipmentIsCanceled,
  formRef,
  shipmentAuthorized,
  noReport,
}) => {
  function getAuthorizationTime(supplierIndex: number) {
    const inspectionApproval = formRef.current?.getFieldValue(
      `satForeignTradeSuppliers[${supplierIndex}].inspectionApproval`,
    );

    if (!shipmentAuthorized || !inspectionApproval) {
      return '';
    }

    const difference = differenceInDays(
      new Date(shipmentAuthorized),
      new Date(inspectionApproval),
    );

    return difference;
  }

  return (
    <div className="form-group p-grid nested-grid">
      <FormTitle className="p-col-12" name="Production" />
      {!!satForeignTradeSuppliers?.length &&
        satForeignTradeSuppliers?.map((foreignTradeSupplier, supplierIndex) => {
          return (
            <Fragment key={foreignTradeSupplier.idSatForeignTradeSupplier}>
              <div className="p-col-12 p-grid nested-grid">
                <FormInputNumber
                  name={`satForeignTradeSuppliers[${supplierIndex}].idSatForeignTradeSupplier`}
                  label="id Foreign Trade Supplier"
                  hidden
                />

                <SunNumber className="p-col-2">
                  {foreignTradeSupplier.idSatSupplier2.idSupplier2.sunNumber}
                </SunNumber>

                {fieldsPermissions.inspectionScheduled.view && (
                  <FormInput
                    className="p-col-2"
                    name={`satForeignTradeSuppliers[${supplierIndex}].inspectionScheduled`}
                    label="Inspection Scheduled"
                    type="date"
                    readOnly={
                      shipmentIsCanceled ||
                      !fieldsPermissions.inspectionScheduled.edit ||
                      noReport
                    }
                  />
                )}

                {fieldsPermissions.inspector.view && (
                  <FormInput
                    className="p-col-2"
                    name={`satForeignTradeSuppliers[${supplierIndex}].inspector`}
                    label="Inspector"
                    readOnly={
                      shipmentIsCanceled || !fieldsPermissions.inspector.edit
                    }
                  />
                )}

                {fieldsPermissions.inspectionApproval.view && (
                  <ReadOnlyInput
                    className="p-col-2"
                    label="Authorization Timing"
                    value={getAuthorizationTime(supplierIndex)}
                  />
                )}

                {fieldsPermissions.inspectionApproval.view && (
                  <FormInput
                    className="p-col-2"
                    name={`satForeignTradeSuppliers[${supplierIndex}].inspectionApproval`}
                    label="Inspection Approval"
                    type="date"
                    readOnly={
                      shipmentIsCanceled ||
                      !fieldsPermissions.inspectionApproval.edit ||
                      noReport
                    }
                  />
                )}

                {fieldsPermissions.idInspectionApprovedBy.view && (
                  <FormAsyncSelect
                    className="p-col-2"
                    name={`satForeignTradeSuppliers[${supplierIndex}].idInspectionApprovedBy`}
                    label="Inspection Approved By"
                    loadOptions={asyncSelectLoadUsersOptions}
                    getOptionLabel={option =>
                      `${option.firstName} ${option.lastName}`
                    }
                    getOptionValue={option => option.idUser}
                    noOptionsMessage={() => 'No users found'}
                    initialValue={foreignTradeSupplier.idInspectionApprovedBy2}
                    readOnly={
                      shipmentIsCanceled ||
                      !fieldsPermissions.idInspectionApprovedBy.edit
                    }
                  />
                )}
              </div>

              <div className="p-col-12 p-grid nested-grid">
                {fieldsPermissions.startProduction.view && (
                  <FormCheckbox
                    className="p-col-2 p-as-center"
                    name={`satForeignTradeSuppliers[${supplierIndex}].startProduction`}
                    label="Start Production"
                  />
                )}

                {fieldsPermissions.rework.view && (
                  <FormAsyncSelect
                    className="p-col-2"
                    name={`satForeignTradeSuppliers[${supplierIndex}].rework`}
                    label="Rework"
                    loadOptions={asyncSelectLoadDomains}
                    getOptionLabel={option => option.description}
                    getOptionValue={option => option.idDomain}
                    additional={{
                      id: DomainGroup.FOREIGN_TRADE_REWORK,
                    }}
                    noOptionsMessage={() => 'No reworks found'}
                    initialValue={foreignTradeSupplier.rework2}
                    readOnly={
                      shipmentIsCanceled || !fieldsPermissions.rework.edit
                    }
                  />
                )}

                {fieldsPermissions.reworkDate.view && (
                  <FormInput
                    className="p-col-2"
                    name={`satForeignTradeSuppliers[${supplierIndex}].reworkDate`}
                    label="Rework Date"
                    type="date"
                    readOnly={
                      shipmentIsCanceled || !fieldsPermissions.reworkDate.edit
                    }
                  />
                )}

                {fieldsPermissions.reworkCost.view && (
                  <FormInputNumber
                    className="p-col-2"
                    name={`satForeignTradeSuppliers[${supplierIndex}].reworkCost`}
                    label="Rework Cost (USD)"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="$"
                    decimalScale={2}
                    readOnly={
                      shipmentIsCanceled || !fieldsPermissions.reworkCost.edit
                    }
                  />
                )}

                {fieldsPermissions.idReworkApprovedBy.view && (
                  <FormAsyncSelect
                    className="p-col-2"
                    name={`satForeignTradeSuppliers[${supplierIndex}].idReworkApprovedBy`}
                    label="Rework Approved By"
                    loadOptions={asyncSelectLoadUsersOptions}
                    getOptionLabel={option =>
                      `${option.firstName} ${option.lastName}`
                    }
                    getOptionValue={option => option.idUser}
                    noOptionsMessage={() => 'No users found'}
                    initialValue={foreignTradeSupplier.idReworkApprovedBy2}
                    readOnly={
                      shipmentIsCanceled ||
                      !fieldsPermissions.idReworkApprovedBy.edit
                    }
                  />
                )}
              </div>
            </Fragment>
          );
        })}

      {!satForeignTradeSuppliers?.length && (
        <Empty
          className="p-col-12"
          message="There is no Suppliers to show"
          position="left"
        />
      )}
    </div>
  );
};

export default Production;
