/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DataTable,
  DataTableMultiSortMetaType,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import saveAs from 'file-saver';
import xlsx from 'xlsx';
import { isValid } from 'date-fns';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { useRefHook } from '../../../hooks/useRefHook';
import ILazyParams from '../../../services/lazyParams';
import { ISatListDesignView, SatListDesignPermissions } from './interfaces';
import { useAuth } from '../../../hooks/useAuth';
import { designRoles } from '../../../shared/roles/design';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../utils/userHasPermission';
import getFieldPermission from '../../../utils/getFieldPermission';
import { ICategory } from '../../../interfaces/ICategory';
import MultiSelectGroup, {
  MultiSelectGroupData,
} from '../../../components/Grid/MultiSelectGroup';
import { gridColumnsData, groupedColumns } from './constants';
import { findParentsOnTree } from '../../../utils/findParentsOnTree';
import {
  getSumDataFromSatDesignListQuery,
  listSatListDesignViewQuery,
} from './queries';
import { getUTC } from '../../../utils/dateUtil';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import { Container } from './styles';
import PageHeader from '../../../components/PageHeader';
import Button from '../../../components/Button';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import pagination from '../../../config/pagination';
import Loading from '../../../components/Loading';
import Grid from '../../../components/Grid';
import Tag from '../../../components/Tag';
import { gridConstants } from '../../../components/Grid/constants';
import { SatStatus } from '../../../components/SatStatusTag';
import { renderNumber } from '../../../utils/formatLocale';

interface ISatListDesignViewLazyParams extends ILazyParams, ISatListDesignView {
  [key: string]:
    | number
    | string
    | DataTableSortOrderType
    | DataTableMultiSortMetaType
    | Date
    | boolean
    | undefined;
}

const ControlDesign: React.FC = () => {
  // Key das opcoes de coluna que resetam preferencias da grid
  const gridResetOptionKey = '0-1';

  const gridColumnsName = '@SAT:satControlsDesignGridColumns';

  const lazyParamsName = '@SAT:satControlsDesignLazyParams';

  const advancedFiltersName = '@SAT:satControlsDesignAdvancedFilters';

  const location = useLocation();

  const { showError } = useRefHook();

  const isMounted = useRef(false);

  const gridRef = useRef<DataTable>(null);

  const [pageLoading /* , setPageLoading */] = useState(false);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  const [lazyParams, setLazyParams] = useState<ISatListDesignViewLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback(
    (newLazyParams: ISatListDesignViewLazyParams) => {
      localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
      updateLocalStorageInDb(lazyParamsName, newLazyParams);
    },
    [],
  );
  const changeLazyParams = useCallback(
    (newLazyParams: ISatListDesignViewLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const { roles } = useAuth();

  const { idEntity } = designRoles;

  const { idPermissionExportSatsDesign } = designRoles.permissions;

  // Busca permissoes do usuario para a entity
  const { userFields, userPermissions } = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(roles.rolesUser, idEntity);
  }, [idEntity, roles.rolesUser]);

  const permissions = useMemo(() => {
    return {
      export: userHasPermission(idPermissionExportSatsDesign, userPermissions),
    };
  }, [idPermissionExportSatsDesign, userPermissions]);

  const fieldsPermissions: SatListDesignPermissions = useMemo(() => {
    const fieldPermissions = userFields;
    const { fields } = designRoles;

    const designPermissions = {} as SatListDesignPermissions;

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(fields)) {
      designPermissions[key] = getFieldPermission(value, fieldPermissions);
    }

    return designPermissions;
  }, [userFields]);

  const [categories, setCategories] = useState<ICategory[]>([]);

  const listCategoriesQuery = gql`
    query listAllCategoriesQuery {
      listAllCategories {
        data
        items
      }
    }
  `;

  useQuery(listCategoriesQuery, {
    onError: errorData => {
      showError({
        summary: 'Error while getting categories',
        detail: errorData.message,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        setCategories(JSON.parse(response.listAllCategories.data));
      }
    },
  });

  const columns = useMemo(() => {
    gridColumnsData.category.options = categories;

    return Object.entries(gridColumnsData)
      .filter(column => fieldsPermissions[column[1].field].view)
      .map(column => column[1]);
  }, [categories, fieldsPermissions]);

  const [selectedColumns, setSelectedColumns] = useState<MultiSelectGroupData>({
    columns: columns.filter(
      (column: any) =>
        groupedColumns &&
        groupedColumns.length > 0 &&
        groupedColumns[0]?.children?.[0]?.data?.includes(column.field),
    ),
    treeSelectedData: {
      0: {
        checked: false,
        partialChecked: true,
      },
      '0-0': {
        checked: true,
        partialChecked: false,
      },
    },
  });

  const satListDesignCommonVariables = {
    allColumns: lazyParams.globalFilter,
    idSat: lazyParams.idSat,
    status: lazyParams.status,
    confirmOrder: lazyParams.confirmOrder,
    mainProduct: lazyParams.mainProduct,
    registerStatus: lazyParams.registerStatus,
    idStatusArtwork: lazyParams.idStatusArtwork,
    productionStatus: lazyParams.productionStatus,
    shipmentStatus: lazyParams.shipmentStatus,
    idImporter: lazyParams.idImporter,
    idClient: lazyParams.idClient,
    idTypeOfOrder: lazyParams.idTypeOfOrder,
    dueDate: lazyParams.dueDate,
    estimatedShipmentDate: lazyParams.estimatedShipmentDate,
    samples: lazyParams.samples ? Number(lazyParams.samples) : undefined,
    idDesignLevel: lazyParams.idDesignLevel,
    newLayout:
      lazyParams.newLayout === true
        ? 'Yes'
        : lazyParams.newLayout === false
        ? 'No'
        : undefined,
    idCountryOrigin: lazyParams.idCountryOrigin,
    designNotes: lazyParams.designNotes,
    artworkStartDate: lazyParams.artworkStartDate,
    idSupportResponsible: lazyParams.idSupportResponsible,
    idDesignResponsible: lazyParams.idDesignResponsible,
    idReviewResponsible: lazyParams.idReviewResponsible,
    idCommercialReviewResponsible: lazyParams.idCommercialReviewResponsible,
    idCommercialResponsible: lazyParams.idCommercialResponsible,
    idFinalArtWorkResponsible: lazyParams.idFinalArtWorkResponsible,
    idSketchesResponsible: lazyParams.idSketchesResponsible,
    artworkDone: lazyParams.artworkDone,
    category: lazyParams.category
      ? findParentsOnTree(categories, lazyParams.category)
      : undefined,
  };

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  const {
    loading: satListDesignViewLoading,
    data: satListDesignViewData,
    error: satListDesignViewError,
  } = useQuery(listSatListDesignViewQuery(fieldsPermissions), {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        fieldsSearch: satListDesignCommonVariables,
      },
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Design List',
        detail: errorData.message,
      });
    },
  });

  const { loading: listSumLoading, data: listSumData } = useQuery(
    getSumDataFromSatDesignListQuery,
    {
      variables: {
        data: satListDesignCommonVariables,
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Design List totals',
          detail: errorData.message,
        });
      },
    },
  );

  function saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });

    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`,
    );
  }

  async function exportToXlsx(exportData: any) {
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.columns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    const colunsNotRemoved = columns.filter(
      col => !columnsToRemove.includes(col),
    );

    exportData.data.forEach((e: any) => {
      columnsToRemove.forEach(column => {
        delete e[column.field];
      });
      delete e.idSat;
      delete e.idClient;
      delete e.idImporter;
      delete e.status;

      // Formata campos de data e porcentagem
      colunsNotRemoved.forEach(column => {
        if (
          e[column.field] &&
          isValid(new Date(e[column.field])) &&
          typeof e[column.field] === 'string'
        ) {
          const date = getUTC(e[column.field]).toLocaleString().split(',')[0];
          e[column.field] = date;
        }
      });
    });

    const columnsOrder = gridRef.current?.state.columnOrder?.filter(
      (item: string) =>
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'status':
          columnsOrder[index] = 'statusSat';
          break;
        default:
          break;
      }
    });

    const worksheet = xlsx.utils.json_to_sheet(exportData.data, {
      header: columnsOrder,
    });

    worksheet['!autofilter'] = { ref: 'A1:H1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    saveAsExcelFile(excelBuffer, 'satListDesign');
  }

  const [
    loadSatListDesignView,
    {
      loading: satListDesignViewExportLoading,
      data: satListDesignViewExportData,
    },
  ] = useLazyQuery(listSatListDesignViewQuery(fieldsPermissions), {
    onCompleted: () => {
      if (satListDesignViewExportData.listSatListDesignView) {
        exportToXlsx(satListDesignViewExportData.listSatListDesignView);
      } else {
        showError({
          summary: 'Error while exporting Design List',
          detail: 'Please try again later',
        });
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Design List to export',
        detail: errorData.message,
      });
    },
  });

  useEffect(() => {
    if (isMounted.current) {
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  function onSort(event: DataTableSortParams) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  const parseSatStatusColumn = (rowData: any) => {
    switch (rowData.status) {
      case SatStatus.ACTIVE:
        return <Tag className="tag" value="Active" severity="success" />;
      case SatStatus.CANCELED:
        return <Tag className="tag" value="Canceled" severity="danger" />;
      case SatStatus.STAND_BY:
        return <Tag className="tag" value="Stand by" severity="warn" />;
      case SatStatus.FINISHED:
        return <Tag className="tag" value="Finished" severity="secondary" />;
      default:
        return undefined;
    }
  };

  const parseDateColumm = (rowData: any, field: any) => {
    let date;
    if (rowData[field.field]) {
      date = getUTC(rowData[field.field]).toLocaleString();
    }
    return <p>{date?.split(',')[0]}</p>;
  };

  const parseSatColumn = (rowData: any) => {
    if (rowData.idSat) {
      return (
        <Link
          to={`/commercial/sats/${rowData.idSat}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.satNumber}
          <FiExternalLink size={15} />
        </Link>
      );
    }
    return rowData.satNumber;
  };

  const parseImporterColumn = (rowData: any) => {
    return (
      rowData.idImporter && (
        <Link
          to={`/clients/list/${rowData.idImporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.importerConsignee}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseClientNameColumn = (rowData: any) => {
    return (
      rowData.idClient && (
        <Link
          to={`/clients/list/${rowData.idClient}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.clientNotify}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseSatGroupColumn = (rowData: any) => {
    const satNumbers = rowData.satGroup?.split(', ');

    const satsStringWithLinks = satNumbers?.map((satNumber: any) => {
      const satId = satNumber.replaceAll('SAT', '').split('/')[0];
      return (
        rowData.satGroup && (
          <Link
            to={`/commercial/sats/${satId}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
            style={{ justifyContent: 'space-between' }}
          >
            {satNumber}
          </Link>
        )
      );
    });

    return satsStringWithLinks;
  };

  const parseSamplesColumn = (rowData: any) => {
    return rowData.samples ? 'Yes' : 'No';
  };

  function handleColumn(field: string) {
    switch (field) {
      case 'status':
        return parseSatStatusColumn;
      case 'dueDate':
      case 'estimatedShipmentDate':
      case 'artworkStartDate':
      case 'internalInfo':
      case 'clientInfo':
      case 'hrp':
      case 'startDoing':
      case 'sentLayout':
      case 'layoutApproved':
      case 'designReview':
      case 'commercialReview':
      case 'sentToClient':
      case 'approvedByClient':
      case 'licensorApproval':
      case 'sentFinalArtWork':
      case 'sketchesReceived':
      case 'artworkDone':
        return parseDateColumm;
      case 'satNumber':
        return parseSatColumn;
      case 'importerConsignee':
        return parseImporterColumn;
      case 'clientNotify':
        return parseClientNameColumn;
      case 'satGroup':
        return parseSatGroupColumn;
      case 'samples':
        return parseSamplesColumn;
      default:
        return undefined;
    }
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'status':
        return { width: '95px' };
      case 'satNumber':
        return { width: '140px' };
      case 'satGroup':
        return { width: '140px', display: 'grid' };
      case 'artworkQuantity':
      case 'layoutApproved':
        return { width: '170px' };
      case 'replacementFrom':
      case 'dueDate':
      case 'artworkStartDate':
      case 'approvedByClient':
      case 'licensorApproval':
      case 'sentFinalArtWork':
      case 'sketchesReceived':
        return { width: '180px' };
      case 'category':
      case 'importerConsignee':
      case 'clientNotify':
      case 'supportResponsible':
      case 'designResponsible':
      case 'reviewResponsible':
      case 'commercialReview':
        return { width: '185px' };
      case 'sketchesResponsible':
      case 'timingTotalArtwork':
        return { width: '190px' };
      case 'commercialResponsible':
      case 'timingSketchesReplay':
        return { width: '210px' };
      case 'finalArtWorkResponsible':
      case 'timingSketchesApproval':
        return { width: '225px' };
      case 'commercialReviewResponsible':
        return { width: '260px' };
      default:
        return { width: '160px' };
    }
  }

  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  function handleColumnFooter(field: string) {
    if (listSumLoading) return <Skeleton />;

    switch (field) {
      case 'satNumber':
        return renderNumber(
          listSumData?.getSumDataFromSatDesignList.satCount,
          'int',
        );
      case 'artworkQuantity':
        return renderNumber(
          listSumData?.getSumDataFromSatDesignList[field],
          'int',
        );
      default:
        return undefined;
    }
  }

  const dynamicColumns = selectedColumns.columns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable={col.header !== 'Image'}
          footer={handleColumnFooter(col.field)}
        />
      )
    );
  });

  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  const history = useHistory();

  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(`/commercial/sats/${e.data.idSat}`);
  }

  const onColumnToggle = (event: MultiSelectGroupData) => {
    const orderedSelectedColumns = columns.filter(col =>
      event.columns.some((sCol: { field: string }) => sCol.field === col.field),
    );

    const finalObject = {
      columns: orderedSelectedColumns,
      treeSelectedData: event.treeSelectedData,
    };

    // Salva colunas selecionadas no local storage
    localStorage.setItem(gridColumnsName, JSON.stringify(finalObject));
    setSelectedColumns(finalObject);

    updateLocalStorageInDb(gridColumnsName, finalObject);
  };

  useEffect(() => {
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    if (localStorageSelectedColumns) {
      const columnsObject = JSON.parse(localStorageSelectedColumns);
      setSelectedColumns(columnsObject);
    }
  }, [columns]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <Container>
      <PageHeader title="Design List" fixedStickyButtons={fixedStickyButtons}>
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
        {permissions.export && (
          <Button
            label="Export Grid"
            className="export-xlsx"
            loading={satListDesignViewExportLoading}
            onClick={() =>
              loadSatListDesignView({
                variables: {
                  data: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    fieldsSearch: satListDesignCommonVariables,
                  },
                },
              })
            }
          />
        )}
        <MultiSelectGroup
          gridRef={gridRef}
          className="grid-multiselect-panel"
          allColumns={columns}
          groups={groupedColumns}
          showFields
          onSelectionChange={e => onColumnToggle(e)}
          initialData={selectedColumns.treeSelectedData}
          resetOptionKey={gridResetOptionKey}
        />
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a SAT"
        />
      </PageHeader>
      <Grid
        ref={gridRef}
        className="grid p-datatable-hoverable-rows"
        name="controlsDesign"
        lazy
        totalRecords={
          !satListDesignViewData || !satListDesignViewData.listSatListDesignView
            ? 0
            : satListDesignViewData.listSatListDesignView.items
        }
        value={
          !satListDesignViewData || !satListDesignViewData.listSatListDesignView
            ? undefined
            : satListDesignViewData.listSatListDesignView.data
        }
        globalFilter={globalFilter}
        emptyMessage="No SATs found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={
          !satListDesignViewData || satListDesignViewError
            ? undefined
            : lazyParams.first
        }
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onRowClick={onRowClick}
      >
        {dynamicColumns}
      </Grid>
      {(pageLoading || satListDesignViewLoading) && <Loading />}
    </Container>
  );
};
export default ControlDesign;
