import { Skeleton } from 'primereact/skeleton';
import React from 'react';

import { Actions, CommentContent, Container } from './styles';

const CommentSkeleton: React.FC = () => {
  return (
    <Container className="s-comment s-skeleton s-comment-skeleton">
      <span className="user-avatar-wrapper">
        <Skeleton shape="circle" size="2.4rem" className="p-mr-2" />
      </span>

      <CommentContent>
        <div className="comment-header">
          <Skeleton width="15rem" className="p-mb-2" borderRadius="16px" />
          <Skeleton width="10rem" className="p-mb-2" borderRadius="16px" />
        </div>
        <div className="comment-text">
          <Skeleton width="100%" height="150px" />
        </div>

        <Actions>
          <Skeleton width="4rem" height="1rem" />

          <Skeleton width="4rem" height="1rem" />
        </Actions>
      </CommentContent>
    </Container>
  );
};

export default CommentSkeleton;
