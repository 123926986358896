import { DocumentNode, gql } from '@apollo/client';
import { SatFinancialPurchaseOtherExpenseFieldsPermissions } from '../../interfaces';

export const listOtherExpensesQuery = (
  fieldsPermissions: SatFinancialPurchaseOtherExpenseFieldsPermissions,
): DocumentNode => gql`
query ListSatForeignTradeSupplierOtherExpenses(
  $data: ListSatForeignTradeSupplierOtherExpensesInput!
) {
  listSatForeignTradeSupplierOtherExpenses(data: $data) {
    items
    data {
      idSatOtherExpense
      idType
      ${
        fieldsPermissions.idType.view
          ? `idType2 {
              idDomain
              description
            }
            `
          : ''
      }
      ${
        fieldsPermissions.idCurrency.view
          ? `idCurrency2 {
              abbreviation
            }`
          : ''
      }
      ${fieldsPermissions.total.view ? 'total' : ''}
      ${fieldsPermissions.dateOtherExpense.view ? 'dateOtherExpense' : ''}
      ${fieldsPermissions.linkToMargin.view ? 'linkToMargin' : ''}
      ${
        fieldsPermissions.discountFromSupplier.view
          ? 'discountFromSupplier'
          : ''
      }
      ${fieldsPermissions.payToSupplier.view ? 'payToSupplier' : ''}
      ${fieldsPermissions.comment.view ? 'comment' : ''}
      confirmationDate
      idSatForeignTradeSupplierDestination
      idSatForeignTradeSupplier
      number
      idSatForeignTrade2 {
        satForeignTradeNumber
      }
      idSatForeignTradeDestination2 {
        satForeignTradeNumber
      }
      idSatRnc2 {
        rncNumber
      }
    }
  }
}
`;

export const deleteOtherExpenseQuery = gql`
  mutation Mutation($idSatOtherExpense: Int!) {
    deleteSatForeignTradeSupplierOtherExpense(
      idSatOtherExpense: $idSatOtherExpense
    )
  }
`;

export const addDebitNoteQuery = gql`
  mutation Mutation($idSatOtherExpense: Int!) {
    addSatForeignTradeSupplierDebitNote(idSatOtherExpense: $idSatOtherExpense) {
      idSatOtherExpense
    }
  }
`;

export const removeDebitNoteQuery = gql`
  mutation RemoveSatForeignTradeSupplierDebitNote($idSatOtherExpense: Int!) {
    removeSatForeignTradeSupplierDebitNote(
      idSatOtherExpense: $idSatOtherExpense
    ) {
      idSatOtherExpense
    }
  }
`;
