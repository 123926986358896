import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useLocation } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { Replies } from './styles';
import Comment, { ICommentProps } from '../../Comment';
import {
  deleteChildCommentQuery,
  listCommentsByIdParentQuery,
} from './queries';
import { useRefHook } from '../../../hooks/useRefHook';
import pagination from '../../../config/pagination';
import { LoadMoreButton } from '../../Dashboard/styles';
import { parseCommentMessage } from '../../../utils/parseCommentMessage';
import { ParentComment, TComment } from './interfaces';
import { useAuth } from '../../../hooks/useAuth';
import ReplyToCommentDialog, {
  ReplyToCommentDialogRef,
} from '../ReplyToCommentDialog';
import EditReplyDialog, { EditReplyDialogRef } from '../EditReplyDialog';
import { FileUploadResponse } from '../../FileUpload/interfaces';
import { IAttachment } from '../../../shared/interfaces/attachment';
import Loading from '../../Loading';

interface ICommentAndRepliesProps extends ICommentProps {
  comment: ParentComment;
  showRestrictInfo?: boolean;
  onUploadAttachment(e: FileUploadResponse[]): Promise<IAttachment | undefined>;
}

/**
 * Parametros de busca na URL para highlight de comentario
 */
export const commentSearchParams = {
  idComment: 'idComment',
  idChildComment: 'idChildComment',
};

const CommentAndReplies: React.FC<ICommentAndRepliesProps> = ({
  comment,
  highlighted,
  showRestrictInfo = false,
  onUploadAttachment,
  ...rest
}) => {
  const { user } = useAuth();
  const replyToCommentDialogRef = useRef<ReplyToCommentDialogRef>(null);
  const editReplyDialogRef = useRef<EditReplyDialogRef>(null);

  const { search } = useLocation();
  const higlightedReply = parseInt(
    new URLSearchParams(search).get(commentSearchParams.idChildComment) ?? '',
    10,
  );

  const { showError, showWarn, showSuccess } = useRefHook();

  const [showReplies, setShowReplies] = useState(false);
  const [replies, setReplies] = useState<TComment[]>([]);
  const [moreRepliesLoading, setMoreRepliesLoading] = useState(false);
  const [childrenCount, setChildrenCount] = useState<number>(
    comment.childrenCount ?? 0,
  );

  const [loadReplies, { loading, fetchMore }] = useLazyQuery(
    listCommentsByIdParentQuery,
    {
      // Impede que useQuery execute novamente apos paginacao
      nextFetchPolicy: 'network-only',
      variables: {
        data: {
          idParentComment: comment.idComment,
          highlightedComment: higlightedReply || undefined,
        },
      },
      onCompleted: response => {
        setReplies(response.listCommentsByIdParent);
      },
      onError: err => {
        showError({
          summary: 'Error loading replies',
          detail: err.message,
        });
      },
    },
  );
  const [deleteReplyMutation, { loading: deleteLoading }] = useMutation(
    deleteChildCommentQuery,
  );

  async function handleLoadMore() {
    setMoreRepliesLoading(true);
    try {
      const response = await fetchMore({
        variables: {
          data: {
            idParentComment: comment.idComment,
            offset: replies.length,
          },
        },
      });

      if (!response.data.listCommentsByIdParent.length) {
        showWarn({
          summary: 'No more replies',
          detail: 'There are no more replies to load for this comment.',
        });

        return;
      }

      setReplies([...replies, ...response.data.listCommentsByIdParent]);
    } catch (err) {
      showError({
        summary: 'Error loading more',
        detail: err.message,
      });
    } finally {
      setMoreRepliesLoading(false);
    }
  }

  function renderLoadMoreButton() {
    if (!replies.length || replies.length % pagination.itemsPerPage !== 0) {
      return undefined;
    }

    return (
      <LoadMoreButton disabled={moreRepliesLoading}>
        <span className="s-load-more-content">
          {moreRepliesLoading ? (
            <ProgressSpinner
              style={{ width: '20px', height: '20px' }}
              strokeWidth="5"
            />
          ) : (
            <button
              type="button"
              onClick={() => handleLoadMore()}
              disabled={moreRepliesLoading}
            >
              Load more
            </button>
          )}
        </span>
      </LoadMoreButton>
    );
  }

  const handleShowReplies = useCallback(() => {
    setShowReplies(prevState => {
      if (prevState) return false;

      if (!replies.length) loadReplies();

      return true;
    });
  }, [loadReplies, replies.length]);

  function renderShowRepliesButtonLabel() {
    switch (true) {
      case showReplies && !loading:
        return 'Hide replies';
      case showReplies && loading:
        return (
          <>
            Loading replies...
            <i
              className="pi pi-spin pi-spinner p-ml-3"
              style={{ fontSize: '1em' }}
            />
          </>
        );
      default:
        return `View replies (${childrenCount})`;
    }
  }

  function handleReplyAll(replyingTo: TComment) {
    const { createdBy, createdBy2, message } = replyingTo;
    const userMention = `@[${createdBy2.username}]@(${createdBy})`;
    const currentUserMentionString = `@(${user.idUser})`;
    const commentMentions = message?.match(/@[^)\s]+[)]/g) ?? [];

    // Remove duplicados (caso o proprio usuario tenha se marcado)
    // e a mencao do usuario que esta respondendo
    const uniqueMentions = commentMentions.filter(
      item => item !== userMention && !item.includes(currentUserMentionString),
    );

    const replyInitialText = `${userMention} ${uniqueMentions.join(' ')} `;

    replyToCommentDialogRef.current?.setCommentToReply(
      replyingTo,
      replyInitialText,
    );
  }

  function handleReply(replyingTo: TComment) {
    const { createdBy, createdBy2 } = replyingTo;
    const userMention = `@[${createdBy2.username}]@(${createdBy}) `;

    replyToCommentDialogRef.current?.setCommentToReply(replyingTo, userMention);
  }

  function onReplySave() {
    loadReplies();

    setShowReplies(true);
    setChildrenCount(childrenCount + 1);
  }

  async function handleDeleteReply(idComment: number) {
    try {
      await deleteReplyMutation({
        variables: {
          idComment,
        },
      });
      showSuccess({
        summary: 'Reply deleted',
      });

      setReplies(replies.filter(reply => reply.idComment !== idComment));
      setChildrenCount(childrenCount - 1);
    } catch (error) {
      showError({
        summary: 'Error deleting reply',
        detail: error.message,
      });
    }
  }

  useEffect(() => {
    // Carrega respostas se o comentario estiver destacado, e possuir uma
    // resposta tambem destacada
    if (highlighted && !!childrenCount && higlightedReply && !replies.length) {
      handleShowReplies();
    }
  }, [
    childrenCount,
    handleShowReplies,
    highlighted,
    higlightedReply,
    replies.length,
  ]);

  return (
    <div className="comment-wrapper p-d-flex p-flex-column">
      <ReplyToCommentDialog
        ref={replyToCommentDialogRef}
        idParentComment={comment.idComment}
        onSave={() => onReplySave()}
        showRestrictInfo={showRestrictInfo}
        parentIsRestrictInfo={comment.isRestrictInfo}
        onUploadAttachment={onUploadAttachment}
      />
      <EditReplyDialog
        ref={editReplyDialogRef}
        onSave={() => loadReplies()}
        showRestrictInfo={showRestrictInfo}
        parentIsRestrictInfo={comment.isRestrictInfo}
        onUploadAttachment={onUploadAttachment}
      />

      <Comment
        {...rest}
        highlighted={highlighted}
        onReplyAll={() => handleReplyAll(comment)}
        onReply={() => handleReply(comment)}
      />
      {!!childrenCount && (
        <Replies>
          <div
            className="show-repplies"
            role="button"
            onClick={() => handleShowReplies()}
            onKeyDown={() => handleShowReplies()}
            tabIndex={0}
          >
            <span className="replies-button-label">
              {renderShowRepliesButtonLabel()}
            </span>
          </div>

          {showReplies && (
            <div>
              {replies.map(reply => (
                <Comment
                  key={reply.idComment}
                  idComment={reply.idComment}
                  userName={reply.createdBy2.fullName}
                  creation={reply.createdAt}
                  editedData={
                    reply.updatedBy2
                      ? {
                          editedAt: reply.updatedAt,
                          editedBy: reply.updatedBy2.fullName,
                        }
                      : undefined
                  }
                  onEdit={() =>
                    editReplyDialogRef.current?.setCommentToEdit(reply)
                  }
                  onReply={() => handleReply(reply)}
                  onReplyAll={() => handleReplyAll(reply)}
                  onDelete={() => {
                    confirmDialog({
                      header: 'Delete reply',
                      message: 'Are you sure you want to delete this reply?',
                      icon: 'pi pi-question-circle',
                      accept: () => handleDeleteReply(reply.idComment),
                    });
                  }}
                  userImageUrl={reply.createdBy2?.avatarUrl}
                  attachmentUrl={reply.idAttachment2?.url}
                  idCreatedBy={reply.createdBy}
                  highlighted={higlightedReply === reply.idComment}
                  commonTag={{
                    className: 'p-mr-2',
                    rounded: true,
                    isRestrictInfo: reply.isRestrictInfo,
                  }}
                >
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: parseCommentMessage(reply),
                    }}
                    className="comment"
                  />
                </Comment>
              ))}

              {renderLoadMoreButton()}
            </div>
          )}
        </Replies>
      )}

      {deleteLoading && <Loading />}
    </div>
  );
};

export default CommentAndReplies;
