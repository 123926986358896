import { useLazyQuery, useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { Column, ColumnBodyOptions } from 'primereact/column';
import {
  DataTable,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortParams,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Empty from '../../../components/Empty';
import Grid from '../../../components/Grid';
import { gridConstants } from '../../../components/Grid/constants';
import { ColumnData } from '../../../components/Grid/interfaces';
import MultiSelectGroup, {
  MultiSelectGroupData,
} from '../../../components/Grid/MultiSelectGroup';
import Loading from '../../../components/Loading';
import PageHeader from '../../../components/PageHeader';
import pagination from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import useTitle from '../../../hooks/useTitle';
import { expandOrCollapseHeader } from '../../../services/layoutsDefinitions';
import { satsPurchaseRoles } from '../../../shared/roles/satsPurchaseRoles';
import getFieldPermission from '../../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import {
  parseCurrencyPtBrNumberColumn,
  parseDateColumm,
  parseDateTimeColumm,
  parseNullableBooleanColumn,
  parsePercentageColumnPt,
  parsePtBrNumberColumn,
  parseSatColumn,
} from '../../../utils/gridColumnsParse';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';
import { gridColumnsData, groupedColumns } from './constants';
import {
  SatListPurchasePermissions,
  SatListPurchaseView,
  SatListPurchaseViewLazyParams,
} from './interfaces';
import {
  exportListQuery,
  getSumDataFromSatPurchaseListQuery,
  listSatListPurchaseViewQuery,
} from './queries';
import SatStatusTag from '../../../components/SatStatusTag';
import { filterOnlyAllowedColumnsFromLocalStorage } from '../../../components/Grid/utils';
import { renderNumber } from '../../../utils/formatLocale';

const pageTitle = 'Purchase List';

const gridColumnsName = '@SAT:satControlsPurchaseGridColumns';

const lazyParamsName = '@SAT:satControlsPurchaseLazyParams';

const advancedFiltersName = '@SAT:satControlsPurchaseAdvancedFilters';

const Purchase: React.FC = () => {
  useTitle(pageTitle);

  // Key das opcoes de coluna que resetam preferencias da grid
  const gridResetOptionKey = 'preview-full';

  const gridRef = useRef<DataTable>(null);

  const isMounted = useRef(false);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const { roles } = useAuth();

  const { idEntity } = satsPurchaseRoles;

  const { showError } = useRefHook();

  const history = useHistory();

  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.allColumns || '',
  );

  const [lazyParams, setLazyParams] = useState<SatListPurchaseViewLazyParams>(
    localStorageLazyParams || pagination.initialLazyParams,
  );

  const saveLazyParams = useCallback(
    (newLazyParams: SatListPurchaseViewLazyParams) => {
      localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
      updateLocalStorageInDb(lazyParamsName, newLazyParams);
    },
    [],
  );

  const changeLazyParams = useCallback(
    (newLazyParams: SatListPurchaseViewLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const [purchaseView, setPurchaseView] = useState<
    [SatListPurchaseView[], number]
  >([[], 0]);

  const { userFields, userPermissions } = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(roles.rolesUser, idEntity);
  }, [idEntity, roles.rolesUser]);

  const permissions = useMemo(() => {
    return {
      export: userHasPermission(
        satsPurchaseRoles.permissions.idPermissionExportSatsPurchase,
        userPermissions,
      ),
    };
  }, [userPermissions]);

  /**
   * Permissao do usuario para cada coluna da grid
   */
  const fieldsPermissions: SatListPurchasePermissions = useMemo(() => {
    const fields = {} as SatListPurchasePermissions;

    Object.entries(satsPurchaseRoles.fields).forEach(([key, value]) => {
      fields[key] = getFieldPermission(value, userFields);
    });

    return fields;
  }, [userFields]);

  /**
   * Colunas da grid que o usuario tem permissao de visualizar
   */
  const columns = useMemo(() => {
    return Object.values(gridColumnsData).reduce((acc, value) => {
      if (fieldsPermissions[value.field].view) {
        acc.push(value);
      }

      return acc;
    }, [] as ColumnData[]);
  }, [fieldsPermissions]);

  const [selectedColumns, setSelectedColumns] = useState<MultiSelectGroupData>({
    columns: columns.filter(column =>
      groupedColumns[0]?.children?.[0]?.data?.includes(column.field),
    ),
    treeSelectedData: {
      0: {
        checked: false,
        partialChecked: true,
      },
      'preview-resume': {
        checked: true,
        partialChecked: false,
      },
    },
  });

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  const purchaseCommonVariables = {
    allColumns: lazyParams.allColumns,
    prc: lazyParams.prc,
    cq: lazyParams.cq,
    idSat: lazyParams.idSat,
    idSatForeignTrade: lazyParams.idSatForeignTrade,
    sunNumber: lazyParams.sunNumber,
    mainProduct: lazyParams.mainProduct,
    status: lazyParams.status,
    idTypeOfOrder: lazyParams.idTypeOfOrder,
    idSupplierShipment: lazyParams.idSupplierShipment,
    idSupplierExporter: lazyParams.idSupplierExporter,
    idImporter: lazyParams.idImporter,
    idClient: lazyParams.idClient,
    clientDepartment: lazyParams.clientDepartment,
    idLoadingPort: lazyParams.idLoadingPort,
    idPort: lazyParams.idPort,
    idCountryOrigin: lazyParams.idCountryOrigin,
    createdAt: lazyParams.createdAt,
    confirmOrderPurchase: lazyParams.confirmOrderPurchase,
    samples: lazyParams.samples,
    estimatedInspectionDate: lazyParams.estimatedInspectionDate,
    realInspectionDate: lazyParams.realInspectionDate,
    estimatedShipmentDate: lazyParams.estimatedShipmentDate,
    realEtd: lazyParams.realEtd,
    idImportStatus: lazyParams.idImportStatus,
    idRegisterStatus: lazyParams.idRegisterStatus,
    idStatusArtwork: lazyParams.idStatusArtwork,
    idProductionStatus: lazyParams.idProductionStatus,
    idShipmentStatus: lazyParams.idShipmentStatus,
    idStatusPurchasePayment: lazyParams.idStatusPurchasePayment,
    shipmentResume: lazyParams.shipmentResume,
    idPurchaseIncoterm: lazyParams.idPurchaseIncoterm,
    idClientIncoterm: lazyParams.idClientIncoterm,
    idPurchaseUser: lazyParams.idPurchaseUser,
    idSalesUser: lazyParams.idSalesUser,
    idSalesSecondUser: lazyParams.idSalesSecondUser,
    idComexExportUser: lazyParams.idComexExportUser,
    idComexImportUser: lazyParams.idComexImportUser,
    idDesignerUser: lazyParams.idDesignerUser,
    idFinancialUser: lazyParams.idFinancialUser,
    category: lazyParams.category,
  };

  const { loading: satListPurchaseViewLoading, data: satListPurchaseViewData } =
    useQuery(listSatListPurchaseViewQuery(fieldsPermissions), {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          fieldsSearch: purchaseCommonVariables,
        },
      },
      onCompleted: data => {
        const { listSatListPurchaseView } = data;
        if (listSatListPurchaseView) {
          setPurchaseView([
            listSatListPurchaseView.data,
            listSatListPurchaseView.items,
          ]);
        } else {
          setPurchaseView([[], 0]);
          showError({
            summary: 'Error while getting Purchase List',
          });
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Purchase List',
          detail: errorData.message,
        });
      },
    });

  const [exportPurchaseView, { loading: exportLoading }] = useLazyQuery(
    exportListQuery,
    {
      onCompleted: response => {
        if (response.exportSatListPurchaseView) {
          window.open(response.exportSatListPurchaseView, '_blank');
        } else {
          showError({
            summary: 'Error while exporting list of SATs',
          });
        }
      },

      onError: error => {
        showError({
          summary: 'Error while exporting list of SATs',
          detail: error.message,
        });
      },
    },
  );

  const { loading: listSumLoading, data: listSumData } = useQuery(
    getSumDataFromSatPurchaseListQuery,
    {
      variables: {
        data: purchaseCommonVariables,
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Purchase List totals',
          detail: errorData.message,
        });
      },
    },
  );

  function expandCollapsePageHeader() {
    const collapseOrnot = expandOrCollapseHeader(fixedStickyButtons);
    setGridScrollHeight(collapseOrnot[0]);
    return setFixedStickyButtons(collapseOrnot[1]);
  }

  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);

    gridRef.current?.resetScroll();
  }

  function onSort(event: DataTableSortParams) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(`/commercial/sats/${e.data.idSat}`);
  }

  /**
   * Retorna coluna de Shipment/Foreign Trade com link para pagina de SAT
   * @param rowData Dados da linha
   * @returns Coluna de Shipment/Foreign Trade com link para pagina de SAT
   */
  const parseShipmentColumn = (rowData: SatListPurchaseView) => {
    if (rowData.idSat && rowData.idSatForeignTrade && rowData.shipment) {
      return (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=foreignTrade&idSatForeignTrade=${rowData.idSatForeignTrade}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.shipment}
          <FiExternalLink />
        </Link>
      );
    }
    return rowData.shipment;
  };

  const parseSunColumn = (rowData: any) => {
    if (!rowData.sunNumber) return undefined;

    const idSupplier = rowData.sunNumber.replace('SUN', '');

    return (
      <Link
        to={`/suppliers/list/${idSupplier}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData.sunNumber}
        <FiExternalLink size={15} />
      </Link>
    );
  };

  const parseExporterColumn = (rowData: SatListPurchaseView) => {
    return (
      rowData.idSupplierExporter && (
        <Link
          to={`/suppliers/list/${rowData.idSupplierExporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.exporter}
          <span>
            <FiExternalLink />
          </span>
        </Link>
      )
    );
  };

  const parseImporterColumn = (rowData: SatListPurchaseView) => {
    return (
      rowData.idImporter && (
        <Link
          to={`/clients/list/${rowData.idImporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.importerConsignee}
          <span>
            <FiExternalLink />
          </span>
        </Link>
      )
    );
  };

  const parseClientNotifyColumn = (rowData: any) => {
    return (
      rowData.idClient && (
        <Link
          to={`/clients/list/${rowData.idClient}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.clientNotify}
          <span>
            <FiExternalLink />
          </span>
        </Link>
      )
    );
  };

  function handleColumn(
    rowData: SatListPurchaseView,
    column: ColumnBodyOptions,
  ) {
    switch (column.field) {
      case 'satNumber':
        return parseSatColumn(rowData, column);
      case 'shipment':
        return parseShipmentColumn(rowData);
      case 'sunNumber':
        return parseSunColumn(rowData);
      case 'statusSat':
        return <SatStatusTag status={rowData.status} />;
      case 'exporter':
        return parseExporterColumn(rowData);
      case 'importerConsignee':
        return parseImporterColumn(rowData);
      case 'clientNotify':
        return parseClientNotifyColumn(rowData);
      case 'createdAt':
      case 'confirmOrderPurchase':
        return parseDateTimeColumm(rowData, column);
      case 'estimatedInspectionDate':
      case 'realInspectionDate':
      case 'estimatedShipmentDate':
      case 'realEtd':
      case 'realEta':
      case 'purchaseEstimatedAdvancePaymentRequest':
        return parseDateColumm(rowData, column);
      case 'quantityPiBySun':
      case 'purchaseEstimatedAdvance':
      case 'purchaseEstimatedBalance':
        return parsePtBrNumberColumn(rowData, column, 0);
      case 'totalCotPi':
      case 'totalCotCi':
      case 'purchaseEstimatedTotalAdvance':
      case 'purchaseEstimatedTotalBalance':
      case 'totalSunPi':
      case 'totalSunCi':
      case 'estimatedGrossProfit':
      case 'estimatedNetProfit':
      case 'grossProfit':
      case 'netProfit':
        return parseCurrencyPtBrNumberColumn(
          rowData,
          column,
          2,
          rowData.satCurrency,
        );
      case 'paymentTermAdvance':
      case 'paymentTermBalance':
      case 'estimatedGrossMargin':
      case 'estimatedNetMargin':
      case 'grossMargin':
      case 'netMargin':
        return parsePercentageColumnPt(rowData, column);
      case 'samples':
        return parseNullableBooleanColumn(rowData, column);
      default:
        return rowData[column.field];
    }
  }

  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'realEtd':
      case 'realEta':
      case 'samples':
        return { width: '130px' };
      case 'netProfit':
      case 'netMargin':
      case 'grossProfit':
      case 'grossMargin':
      case 'totalSunPi':
      case 'totalSunCi':
      case 'totalCotPi':
      case 'totalCotCi':
      case 'satNumber':
      case 'prc':
      case 'cq':
        return { width: '160px' };
      case 'firstSalesUser':
      case 'secondSalesUser':
      case 'replacementFrom':
      case 'importerConsignee':
      case 'clientDepartment':
      case 'portDischarge':
      case 'portLoading':
      case 'countryOrigin':
      case 'sampleLeadTime':
      case 'realInspectionDate':
      case 'purchaseIncoterm':
      case 'createdAt':
        return { width: '210px' };
      case 'estimatedGrossMargin':
      case 'estimatedGrossProfit':
        return { width: '230px' };
      case 'estimatedNetMargin':
      case 'estimatedNetProfit':
      case 'paymentSellingIncoterm':
      case 'estimatedShipmentDate':
      case 'estimatedInspectionDate':
      case 'confirmOrderPurchase':
        return { width: '240px' };
      case 'quantityPiBySun':
      case 'paymentTermBalance':
      case 'paymentTermAdvance':
      case 'purchaseEstimatedAdvance':
      case 'purchaseEstimatedBalance':
      case 'ctnrs':
        return { width: '270px' };
      case 'purchaseEstimatedTotalAdvance':
      case 'purchaseEstimatedTermCondition':
      case 'purchaseEstimatedTotalBalance':
      case 'paymentTermCondition':
      case 'category':
        return { width: '300px' };
      case 'purchaseEstimatedAdvancePaymentRequest':
        return { width: '370px' };
      default:
        return { width: '180px' };
    }
  }

  const onColumnToggle = (event: MultiSelectGroupData) => {
    const orderedSelectedColumns = columns.filter(col =>
      event.columns.some((sCol: { field: string }) => sCol.field === col.field),
    );

    const finalObject = {
      columns: orderedSelectedColumns,
      treeSelectedData: event.treeSelectedData,
    };

    // Salva colunas selecionadas no local storage
    localStorage.setItem(gridColumnsName, JSON.stringify(finalObject));
    setSelectedColumns(finalObject);

    updateLocalStorageInDb(gridColumnsName, finalObject);
  };

  function handleColumnFooter(field: string) {
    if (listSumLoading) return <Skeleton />;

    const [purchaseViewData] = purchaseView;
    // Currency para adicionar nos campos de moeda - sao listados se todas as
    // SATs possuirem a mesma currency
    const currencyAbbreviation = purchaseViewData.length
      ? purchaseViewData[0].satCurrency
      : undefined;

    const value = listSumData?.getSumDataFromSatPurchaseList[field];

    switch (field) {
      case 'satNumber':
        return renderNumber(
          listSumData?.getSumDataFromSatPurchaseList.satCount,
          'int',
        );
      case 'quantityPiBySun':
      case 'purchaseEstimatedAdvance':
      case 'purchaseEstimatedBalance':
        return renderNumber(value, 'int');
      case 'totalCotPi':
      case 'totalSunCi':
      case 'purchaseEstimatedTotalAdvance':
      case 'purchaseEstimatedTotalBalance':
      case 'totalSunPi':
      case 'totalCotCi':
      case 'grossProfit':
      case 'estimatedGrossProfit':
      case 'estimatedNetProfit':
      case 'netProfit':
        return renderNumber(value, 'currency', currencyAbbreviation);
      case 'netMargin':
      case 'grossMargin':
      case 'estimatedNetMargin':
      case 'estimatedGrossMargin': {
        const valuePercent = value / 100;
        return renderNumber(valuePercent, 'percent');
      }
      default:
        return undefined;
    }
  }

  const dynamicColumns = selectedColumns.columns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          field={col.field}
          body={(rowData, props) => handleColumn(rowData, props)}
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable
          footer={handleColumnFooter(col.field)}
        />
      )
    );
  });

  /**
   * Busca colunas selecionadas na ordem em que sao apresentadas ao usuario
   * @returns Colunas selecionadas na ordem em que sao apresentadas ao usuario
   */
  function getOrderedSelectedColumns() {
    const columnsOrder = gridRef.current?.state.columnOrder;

    // Ordena itens de acordo com o array columnsOrder. Itens que nao existam
    // em columnsOrder sao colocados no final do array
    const sortedSelectedColumns = sortBy(selectedColumns.columns, item => {
      const index = columnsOrder.indexOf(item.field);
      return index === -1 ? Infinity : index;
    });

    return sortedSelectedColumns.map(col => col.field);
  }

  /**
   * Atualiza o filtro global da grid
   */
  useEffect(() => {
    if (isMounted.current) {
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.allColumns = globalFilter;
        changeLazyParams(newLazyParams);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      const localStorageObject = JSON.parse(localStorageSelectedColumns);
      const allowedColumns = filterOnlyAllowedColumnsFromLocalStorage(
        localStorageObject.columns,
        columns,
      );

      setSelectedColumns({
        ...localStorageObject,
        columns: allowedColumns,
      });
    }
  }, [columns]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div>
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...pagination.initialLazyParams,
              allColumns: globalFilter,
            })
          }
        />
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>

        {permissions.export && (
          <Button
            label="Export Grid"
            className="export-xlsx"
            loading={exportLoading}
            onClick={() =>
              exportPurchaseView({
                variables: {
                  data: {
                    pagination: {
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    columnsToExport: getOrderedSelectedColumns(),
                    fieldsSearch: purchaseCommonVariables,
                  },
                },
              })
            }
          />
        )}
        <MultiSelectGroup
          gridRef={gridRef}
          className="grid-multiselect-panel"
          allColumns={columns}
          groups={groupedColumns}
          showFields
          onSelectionChange={e => onColumnToggle(e)}
          initialData={selectedColumns.treeSelectedData}
          resetOptionKey={gridResetOptionKey}
        />
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a SAT"
        />
      </PageHeader>
      <Grid
        ref={gridRef}
        className="grid"
        name="controlsPurchase"
        lazy
        totalRecords={purchaseView[1]}
        value={purchaseView[0]}
        globalFilter={globalFilter}
        emptyMessage="No SATs found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={!satListPurchaseViewData ? undefined : lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onRowClick={onRowClick}
        rowHover
      >
        {dynamicColumns}
      </Grid>

      {satListPurchaseViewLoading && <Loading />}

      {columns && !columns.length && (
        <Empty message="You do not have permission to see any columns">
          <i className="pi pi-exclamation-circle" />
        </Empty>
      )}

      {columns && columns.length && !selectedColumns.columns.length && (
        <Empty message="You need to select at least one column to see any data" />
      )}
    </div>
  );
};

export default Purchase;
