import { useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Dialog } from 'primereact/dialog';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import Button from '../../../../../../components/Button';
import FormAsyncSelect from '../../../../../../components/FormAsyncSelect';
import FormCheckbox from '../../../../../../components/FormCheckbox';
import FormDatePicker from '../../../../../../components/FormDatePicker';
import FormInputTextArea from '../../../../../../components/FormInputTextArea';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { FileType } from '../../../../../../shared/enums/fileType';
import { asyncSelectLoadSuppliers } from '../../../../../../shared/querys/supplier';
import { SatQuotation } from '../interfaces';
import { createSatQuotationQuery, updateSatQuotationQuery } from './queries';

import { SatQuotationFormData } from './interfaces';
import ToastLife from '../../../../../../shared/enums/toastLife';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import FileUpload from '../../../../../../components/FileUpload';

interface IQuotationProps {
  /**
   * SAT Quotation a ser mantida
   */
  satQuotation?: SatQuotation;

  /**
   * Define estado de Quotation
   */
  setSelectedQuotation: React.Dispatch<
    React.SetStateAction<SatQuotation | undefined>
  >;

  /**
   * ID da SAT atual
   */
  idSat: number;

  getSatQuotations(): void;
}

const Quotation: React.FC<IQuotationProps> = ({
  satQuotation,
  setSelectedQuotation,
  idSat,
  getSatQuotations,
}) => {
  const { toastRef } = useRefHook();

  const formRef = useRef<FormHandles>(null);

  const [newQuotationFile, setNewQuotationFile] = useState<string>();

  const [loading, setLoading] = useState(false);

  // cria método para chamar a mutation
  const [createSatQuotationMutation] = useMutation(createSatQuotationQuery);
  const [updateSatQuotationMutation] = useMutation(updateSatQuotationQuery);

  const handleSatQuotationSave = useCallback(
    async (formData: SatQuotationFormData) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          idSupplier: Yup.number().nullable().required('Required Field'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        const commonVariables = {
          ...formData,
          uploadedFile: newQuotationFile,
        };

        if (!satQuotation?.idSatQuotation) {
          await createSatQuotationMutation({
            variables: {
              data: {
                idSat,
                ...commonVariables,
              },
            },
          });
        } else {
          await updateSatQuotationMutation({
            variables: {
              data: {
                idSatQuotation: satQuotation.idSatQuotation,
                ...commonVariables,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `SAT quotation ${
            satQuotation?.idSatQuotation ? 'updated' : 'created'
          }`,
          life: ToastLife.SUCCESS,
        });

        setSelectedQuotation(undefined);
        setNewQuotationFile(undefined);

        getSatQuotations();
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${
              satQuotation?.idSatQuotation ? 'updating' : 'creating'
            } SAT quotation`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      createSatQuotationMutation,
      getSatQuotations,
      idSat,
      newQuotationFile,
      satQuotation?.idSatQuotation,
      setSelectedQuotation,
      toastRef,
      updateSatQuotationMutation,
    ],
  );

  /**
   * Footer da dialog de PRC Quotation
   * @returns Footer
   */
  const quotationDialogFooter = () => {
    return (
      <div style={{ display: 'flex', placeContent: 'end' }}>
        <Button
          loading={loading}
          label="Save"
          onClick={() => formRef.current?.submitForm()}
        />
        <Button
          label="Cancel"
          onClick={() => {
            setSelectedQuotation(undefined);
          }}
          severity="danger"
          disabled={loading}
        />
      </div>
    );
  };

  return (
    <Dialog
      header="SAT Quotation Item"
      visible={!!satQuotation}
      onHide={() => setSelectedQuotation(undefined)}
      closable={false}
      footer={quotationDialogFooter()}
      style={{ width: '444px' }}
    >
      <Form
        className="p-formgrid p-grid"
        ref={formRef}
        onSubmit={handleSatQuotationSave}
        initialData={satQuotation}
      >
        <FormAsyncSelect
          className="p-field p-col-12"
          name="idSupplier"
          label="Supplier"
          required
          loadOptions={asyncSelectLoadSuppliers}
          getOptionLabel={(option: any) => option.sunNumber}
          getOptionValue={(option: any) => option.idSupplier}
          noOptionsMessage={() => 'No suppliers found'}
          initialValue={satQuotation?.idSupplier2}
          additional={{
            active: true,
          }}
          menuPosition="fixed"
        />

        <FormDatePicker
          className="p-field p-col-6"
          name="validDate"
          label="Valid Date"
        />

        <FormCheckbox
          className="p-d-flex p-as-center p-field p-col-6"
          name="bestPrice"
          label="Best Price"
          initialValue={satQuotation?.bestPrice}
        />

        <FormInputTextArea
          className="p-field p-col-12"
          name="comment"
          label="Comment"
          rows={6}
        />

        <FileUpload
          mode="basic"
          className="p-col-12"
          accept={`${FileType.XLS},${FileType.XLSX},${FileType.DOC},${FileType.DOCX},${FileType.ALL_IMAGES},${FileType.PDF}`}
          auto
          customUpload
          onConfirm={e => setNewQuotationFile(e[0].serverName)}
          chooseLabel={!newQuotationFile ? 'Upload a file' : 'File selected'}
          disabled={!!newQuotationFile}
        />
      </Form>
    </Dialog>
  );
};

export default Quotation;
