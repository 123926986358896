import React, { Ref, forwardRef, useImperativeHandle, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { number, object } from 'yup';
import {
  PackageDescriptionFieldsPermissions,
  ProductPackageDescription,
} from '../../../interfaces';
import FormAsyncSelect from '../../../../../../../components/FormAsyncSelect';
import { asyncSelectLoadDomains } from '../../../../../../../shared/querys/domain';
import { DomainGroup } from '../../../../../../../shared/enums/domainGroup';
import {
  IPackageDescriptionContentRef,
  PackageDescriptionContentFormData,
} from './interfaces';
import getValidationErrors from '../../../../../../../utils/getValidationErrors';
import FormInputNumber from '../../../../../../../components/FormInputNumber';
import FormInputTextArea from '../../../../../../../components/FormInputTextArea';

interface IPackageDescriptionContentProps {
  packageDescription?: ProductPackageDescription;
  ref: Ref<IPackageDescriptionContentRef>;
  fieldsPermissions: PackageDescriptionFieldsPermissions;
  userCanChangeFields: boolean;
}

const PackageDescriptionContent: React.FC<IPackageDescriptionContentProps> =
  forwardRef(
    ({ packageDescription, fieldsPermissions, userCanChangeFields }, ref) => {
      const formRef = useRef<FormHandles>(null);

      async function validateForm(formData: PackageDescriptionContentFormData) {
        if (!formData) return false;

        const schema = object().shape({
          idPackageDescription: number().nullable().required('Required Field'),
        });

        try {
          formRef.current?.setErrors({});

          await schema.validate(formData, { abortEarly: false });

          return true;
        } catch (error) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          const firstError = error.inner[0];

          const inputWithError = formRef.current?.getFieldRef(firstError.path);

          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef?.current?.focus) {
            inputWithError.inputRef?.current?.focus();
          }

          throw error;
        }
      }

      async function onSubmit(formData: PackageDescriptionContentFormData) {
        try {
          await validateForm(formData);

          return formData;
        } catch (error) {
          return undefined;
        }
      }

      useImperativeHandle(ref, () => ({
        submit: () => {
          const formData = formRef.current?.getData();
          return onSubmit(formData as PackageDescriptionContentFormData);
        },
        validateForm: () => {
          const formData = formRef.current?.getData();
          return validateForm(formData as PackageDescriptionContentFormData);
        },
        getData: () => {
          return formRef.current?.getData() as PackageDescriptionContentFormData;
        },
      }));

      return (
        <Form
          className="p-formgrid p-grid"
          ref={formRef}
          initialData={packageDescription}
          onSubmit={data => onSubmit(data)}
        >
          {packageDescription?.idProductPackage && (
            <>
              <FormInputNumber
                name="idProductPackage"
                value={packageDescription.idProductPackage}
                label=""
                hidden
              />
              <FormInputNumber
                name="previousIdPackageDescription"
                value={packageDescription.idPackageDescription}
                label=""
                hidden
              />
            </>
          )}

          {fieldsPermissions.idPackageDescription.view && (
            <FormAsyncSelect
              className="p-field p-col-6"
              name="idPackageDescription"
              label="Selling Package Description"
              required
              loadOptions={asyncSelectLoadDomains}
              getOptionLabel={option => option.description}
              getOptionValue={option => option.idDomain}
              initialValue={packageDescription?.idPackageDescription2}
              additional={{
                id: DomainGroup.PACKAGE_DESCRIPTION,
              }}
              noOptionsMessage={() => 'No descriptions found'}
              menuPosition="fixed"
              readOnly={
                !userCanChangeFields ||
                (packageDescription?.idPackageDescription
                  ? !fieldsPermissions.idPackageDescription.edit
                  : !fieldsPermissions.idPackageDescription.create)
              }
            />
          )}

          {fieldsPermissions.idResponsible.view && (
            <FormAsyncSelect
              className="p-field p-col-6"
              name="idResponsible"
              label="Responsible"
              loadOptions={asyncSelectLoadDomains}
              getOptionLabel={option => option.description}
              getOptionValue={option => option.idDomain}
              initialValue={{
                idDomain: packageDescription?.idResponsible,
                description: packageDescription?.idResponsible2?.description,
              }}
              additional={{
                id: DomainGroup.PACKAGE_RESPONSIBLE,
              }}
              menuPosition="fixed"
            />
          )}

          {fieldsPermissions.comments.view && (
            <FormInputTextArea
              className="p-field p-col-12"
              name="comments"
              label="Comments"
              // Refletindo com o tamanho da coluna no banco
              maxLength={100}
              resize="none"
              rows={3}
            />
          )}
        </Form>
      );
    },
  );

export default PackageDescriptionContent;
