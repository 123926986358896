import { useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import React, {
  Ref,
  RefObject,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { ValidationError, object, string } from 'yup';
import { ISatForeignTradeRef } from '..';
import Button from '../../../../../components/Button';
import { useRefHook } from '../../../../../hooks/useRefHook';
import ToastLife from '../../../../../shared/enums/toastLife';
import { createSatShipmentQuery } from '../../queries';
import { IContainerItemsRef } from '../ForeignTradeContent/ContainerItems';
import ItemsGrid, { IItemsGridRef } from './ItemsGrid';
import { createSatForeignTradeItemsQuery } from './queries';
import { ISatFinancialRef } from '../../Financial';
import FormInput from '../../../../../components/FormInput';
import {
  AvailableSatItemsModalFormData,
  CreateSatShipmentMutationData,
} from './interfaces';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../../utils/getValidationErrors';
import { ISatGeneralInformationRef } from '../../GeneralInformation';

export interface IAvaillableSatItemsModalRef {
  toggleModal(): void;
}

interface IAvailableSatItemsModal {
  ref: Ref<IAvaillableSatItemsModalRef>;
  idSat: number;
  idSatForeignTrade?: number;
  containerItemsRef?: RefObject<IContainerItemsRef>;
  foreignTradeRef?: RefObject<ISatForeignTradeRef>;
  financialRef: React.RefObject<ISatFinancialRef>;
  satOrderConfirmed?: boolean;
  generalInformationRef?: RefObject<ISatGeneralInformationRef>;
}

const AvailableSatItemsModal: React.FC<IAvailableSatItemsModal> =
  React.forwardRef(
    (
      {
        idSat,
        idSatForeignTrade,
        containerItemsRef,
        foreignTradeRef,
        financialRef,
        satOrderConfirmed,
        generalInformationRef,
      },
      ref,
    ) => {
      const itemsGridRef = useRef<IItemsGridRef>(null);
      const formRef = useRef<FormHandles>(null);

      const { toastRef } = useRefHook();

      const [displayModal, setDisplayModal] = useState(false);

      const [saveOnGoing, setSaveOnGoing] = useState(false);

      useImperativeHandle(ref, () => ({
        toggleModal: () => {
          setDisplayModal(!displayModal);
        },
      }));

      const [createSatForeignTradeItemsMutation] = useMutation(
        createSatForeignTradeItemsQuery,
      );

      const [createSatShipmentMutation] = useMutation(createSatShipmentQuery);

      const dialogHeader = idSatForeignTrade ? 'Add Item' : 'Create Shipment';

      const shouldShowScheduleFields =
        !idSatForeignTrade && !!satOrderConfirmed;

      async function handleSaveItems(
        formData: AvailableSatItemsModalFormData,
      ): Promise<void> {
        const items = itemsGridRef.current?.getItems();

        if (
          !items?.satItems.length &&
          !items?.satReferenceSizeBarcodes.length
        ) {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Nothing to send',
            detail: 'You need to select at least one item to send',
            life: ToastLife.ERROR,
          });

          return;
        }

        try {
          setSaveOnGoing(true);

          if (idSatForeignTrade) {
            // Add item
            await createSatForeignTradeItemsMutation({
              variables: {
                data: {
                  idSatForeignTrade,
                  satItems: items.satItems,
                  satReferenceSizeBarcodes: items.satReferenceSizeBarcodes,
                },
              },
            });

            if (containerItemsRef) {
              await containerItemsRef.current?.refreshGridData();
            }

            toastRef.current?.show({
              severity: 'success',
              summary: 'Items added',
              life: ToastLife.SUCCESS,
            });
          } else {
            const mutationData: CreateSatShipmentMutationData = {
              idSat,
              satItems: items.satItems,
              satReferenceSizeBarcodes: items.satReferenceSizeBarcodes,
            };

            // Os campos de data serao informados pelo usuario apenas se a SAT
            // ja possuir uma Foreign Trade criada
            if (shouldShowScheduleFields) {
              const schema = object().shape({
                estimatedInspectionDate: string().required(
                  requiredFieldErrorMessage,
                ),
                estimatedShipmentDate: string().required(
                  requiredFieldErrorMessage,
                ),
                estimatedArrivalDate: string().required(
                  requiredFieldErrorMessage,
                ),
                estimatedArrivalClientDate: string().required(
                  requiredFieldErrorMessage,
                ),
              });

              formRef.current?.setErrors({});

              await schema.validate(formData, { abortEarly: false });

              mutationData.estimatedInspectionDate =
                formData.estimatedInspectionDate;

              mutationData.estimatedShipmentDate =
                formData.estimatedShipmentDate;

              mutationData.estimatedArrivalDate = formData.estimatedArrivalDate;

              mutationData.estimatedArrivalClientDate =
                formData.estimatedArrivalClientDate;
            }

            await createSatShipmentMutation({
              variables: {
                data: mutationData,
              },
            });

            if (foreignTradeRef) {
              foreignTradeRef.current?.refetchAndFocus();
            }

            if (generalInformationRef) {
              generalInformationRef.current?.refetchShipmentCount();
            }

            toastRef.current?.show({
              severity: 'success',
              summary: 'Shipment created',
              life: ToastLife.SUCCESS,
            });
          }

          financialRef.current?.refreshForm();

          setDisplayModal(false);
        } catch (error) {
          if (error instanceof ValidationError) {
            // Pega os erros de cada input
            const errors = getValidationErrors(error);

            // Define os erros para cada input
            formRef.current?.setErrors(errors);
          } else {
            toastRef.current?.show({
              severity: 'error',
              summary: `Error while ${
                idSatForeignTrade
                  ? 'adding Foreign Trade items'
                  : 'creating shipment'
              }`,
              detail: error.message,
              life: ToastLife.ERROR,
            });
          }
        } finally {
          setSaveOnGoing(false);
        }
      }

      function handleCancel() {
        const items = itemsGridRef.current?.getItems();

        if (items?.satItems.length || items?.satReferenceSizeBarcodes.length) {
          confirmDialog({
            message: 'You will lost any unsaved information',
            header: 'Are you sure you want to proceed?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => setDisplayModal(false),
          });
        } else {
          setDisplayModal(false);
        }
      }

      /**
       * Footer da modal
       * @returns Footer
       */
      const dialogFooter = () => {
        return (
          <div style={{ display: 'flex', placeContent: 'end' }}>
            <Button
              icon={saveOnGoing ? 'pi pi-spin pi-spinner' : undefined}
              label="Save"
              onClick={() => formRef.current?.submitForm()}
              disabled={saveOnGoing}
              type="button"
            />
            <Button
              label="Cancel"
              onClick={() => handleCancel()}
              className="p-button-danger"
              disabled={saveOnGoing}
              type="button"
            />
          </div>
        );
      };

      return (
        <div>
          <Dialog
            header={dialogHeader}
            visible={displayModal}
            onHide={() => setDisplayModal(false)}
            footer={dialogFooter}
            closable={false}
          >
            <Form ref={formRef} onSubmit={data => handleSaveItems(data)}>
              {shouldShowScheduleFields && (
                <div className="p-formgrid p-grid">
                  <FormInput
                    className="p-field p-col"
                    name="estimatedInspectionDate"
                    label="Est. Inspection Date"
                    type="date"
                    required
                  />

                  <FormInput
                    className="p-field p-col"
                    name="estimatedShipmentDate"
                    label="Est. Shipment Date"
                    type="date"
                    required
                  />

                  <FormInput
                    className="p-field p-col"
                    name="estimatedArrivalDate"
                    label="Est. Arrival Date"
                    type="date"
                    required
                  />

                  <FormInput
                    className="p-field p-col"
                    name="estimatedArrivalClientDate"
                    label="Est. Arrival At Client"
                    type="date"
                    required
                  />
                </div>
              )}
            </Form>

            <ItemsGrid
              ref={itemsGridRef}
              idSat={idSat}
              idSatForeignTrade={idSatForeignTrade}
            />
          </Dialog>
        </div>
      );
    },
  );

export default AvailableSatItemsModal;
