/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DataTable, DataTableProps } from 'primereact/datatable';
import React, {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import pagination from '../../config/pagination';
import updateLocalStorageInDb from '../../utils/updateLocalStorageInDb';

import { Container } from './styles';

interface GridProps extends DataTableProps {
  ref?: Ref<DataTable>;
  onGridScroll?(scrollTop: number): void;
  name?: string;
  paginator?: boolean;
  onPageHeaderToggle?(): void;
}

const Grid: React.FC<GridProps> = forwardRef(
  (
    {
      onGridScroll,
      children,
      scrollable,
      name,
      paginator = true,
      onRowClick,
      ...rest
    },
    ref,
  ) => {
    const dataTableRef = useRef<DataTable>(null);
    const [columnOrderChanged, setColumnOrderChanged] = useState(false);

    /**
     * Chama funcao de scroll passada por props
     */
    function handleScroll() {
      // Busca elemento da grid
      if (onGridScroll) {
        const element = document.getElementsByClassName(
          'p-datatable-scrollable-body',
        );

        // Chama funcao de scroll passada por props
        onGridScroll(element[0]!.scrollTop);
      }
    }

    // Ao ordenar coluna
    useEffect(() => {
      // Valida se ordem da coluna mudou
      if (columnOrderChanged && name) {
        // Define delay para buscar informacao do local storage
        const delayDebounceFn = setTimeout(() => {
          const data = localStorage.getItem(`@SAT:${name}Grid`);

          // Se houver informacao, atualiza em banco
          if (data) {
            const json = JSON.parse(data);
            // Zera array de linhas selecionadas
            json.selection = [];

            // Atualiza em banco
            updateLocalStorageInDb(`@SAT:${name}Grid`, json);
          }
          setColumnOrderChanged(false);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      }
      // Define que componente esta montado
      return undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnOrderChanged]);

    /**
     * A cada evento de scroll, chama função para atualizar estado
     */
    useEffect(() => {
      if (scrollable) {
        document
          .getElementById('grid')
          ?.addEventListener('scroll', handleScroll, true);
      }
    }, []);

    const customSaveState = useCallback((e: any) => {
      const object = {
        ...e,
        selection: [],
        expandedRows: undefined,
      };

      localStorage.setItem(`@SAT:${name}Grid`, JSON.stringify(object));
    }, []);

    const customRestoreState = useCallback((): any => {
      const data = localStorage.getItem(`@SAT:${name}Grid`);
      if (data) {
        return JSON.parse(data);
      }
      return data;
    }, []);

    function handleRowClick(e: any) {
      /*
       * Validando se o clique nao eh de deselecao da checkbox
       * Ha uma issue relacionada no github, que pode ter sido corrigida em uma
       * versao mais nova da biblioteca
       * https://github.com/primefaces/primereact/issues/900
       */
      if (
        typeof e?.originalEvent?.target?.className === 'string' &&
        e.originalEvent.target?.className?.includes('p-checkbox')
      ) {
        return;
      }

      if (onRowClick) onRowClick(e);
    }

    useImperativeHandle(ref, () => ({
      ...(dataTableRef.current as any),
      setState: (state: any) => {
        dataTableRef.current?.setState(state);
      },
      resetScroll: () => {
        dataTableRef.current?.resetScroll();
      },
      resetColumnOrder: () => {
        dataTableRef.current?.resetColumnOrder();
        setColumnOrderChanged(true);
      },
    }));

    return (
      <Container>
        <DataTable
          ref={dataTableRef}
          id="grid"
          paginator={paginator}
          paginatorPosition="top"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {last} of {totalRecords} entries"
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          scrollable={scrollable}
          stateStorage="custom"
          customSaveState={name ? customSaveState : undefined}
          customRestoreState={name ? customRestoreState : undefined}
          onColReorder={() => setColumnOrderChanged(true)}
          {...rest}
          onRowClick={e => handleRowClick(e)}
        >
          {children}
        </DataTable>
      </Container>
    );
  },
);

export default Grid;
