import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { GlobalStyle } from './styles/global';

import Routes from './routes';
import AppProvider from './hooks';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import client from './services/apollo/client';

const App: React.FC = () => (
  <Router>
    <ApolloProvider client={client}>
      <AppProvider>
        <Routes />
      </AppProvider>
    </ApolloProvider>
    <GlobalStyle />
  </Router>
);

export default App;
