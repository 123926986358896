/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Container } from './styled';

interface IChecklistItemProps {
  question: string;
  fieldType?: string;
  onValueChange?(value: any): void;
  initialValue?: any;
  missingTypeErrorExplanation?: boolean;
}

const ChecklistItem: React.FC<IChecklistItemProps> = ({
  fieldType,
  question,
  onValueChange,
  initialValue,
  missingTypeErrorExplanation,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [answer, setAnswer] = useState<any>(initialValue);

  const radioOptions = [
    { name: 'Yes', key: 'Yes' },
    { name: 'No', key: 'No' },
  ];

  function onChange(value: any) {
    setAnswer(value);
    if (onValueChange) {
      onValueChange(value);
    }
  }

  function renderAnswerType() {
    switch (fieldType) {
      case 'radio':
        return radioOptions.map(option => {
          return (
            <div key={option.key} className="p-field-radiobutton">
              <RadioButton
                inputId={option.key}
                name="answer"
                value={option.name}
                onChange={e => onChange(e.value)}
                checked={answer === option.name}
              />
              <label>{option.name}</label>
            </div>
          );
        });
      case 'check':
        return (
          <Checkbox
            onChange={e => onChange(e.checked ? 'Yes' : 'No')}
            checked={answer === 'Yes' || answer === true}
          />
        );
      case 'longText':
        return (
          <InputTextarea
            rows={5}
            cols={23}
            value={answer}
            onChange={e => onChange(e.target.value)}
            autoResize
          />
        );
      case 'text':
        return (
          <InputText value={answer} onChange={e => onChange(e.target.value)} />
        );
      default:
        return (
          <p className="no-info">
            {missingTypeErrorExplanation
              ? 'Go to the checklist screen and select a type for this question first'
              : 'Select a type'}
          </p>
        );
    }
  }

  return (
    <Container className="checklist-item">
      <div className="question">
        <h3>Question</h3>
        {question || <p className="no-info">Enter a question</p>}
      </div>
      <div className="answer">
        <h3>Answer Type</h3>
        {renderAnswerType()}
      </div>
    </Container>
  );
};

export default ChecklistItem;
