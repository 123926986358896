import { get } from 'lodash';
import { Checkbox } from 'primereact/checkbox';
import { Skeleton } from 'primereact/skeleton';
import React, { Ref, useEffect, useImperativeHandle, useReducer } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import Empty from '../Empty';
import { FilesByTypesReducerActionKind, IFileType } from './interfaces';
import {
  filesByTypesReducer,
  filesByTypesReducerInitialState,
} from './reducers';

import { Column, Container, Option } from './styles';

export interface IFilesByTypesRef {
  getSelectedItems(): number[];
}

interface IFilesByTypesProps {
  ref?: Ref<IFilesByTypesRef>;
  className?: string;
  typesLoading?: boolean;
  filesLoading?: boolean;
  fileTypes: IFileType[];
  emptyMessage?: string;
  files: any[];
  fileIdKey: string;
  fileDescriptionKey: string;
  fileTypeKey: string;
  fileCreatedAtKey?: string;
}

const FilesByTypes: React.FC<IFilesByTypesProps> = React.forwardRef(
  (
    {
      className,
      typesLoading,
      filesLoading,
      fileTypes,
      emptyMessage,
      files,
      fileIdKey,
      fileDescriptionKey,
      fileTypeKey,
      fileCreatedAtKey,
    },
    ref,
  ) => {
    const [filesByTypesState, filesByTypesDispatch] = useReducer(
      filesByTypesReducer,
      filesByTypesReducerInitialState,
    );

    useImperativeHandle(ref, () => ({
      getSelectedItems: () => {
        const selectedItems = filesByTypesState.files.filter(
          item => item.isSelected,
        );

        return selectedItems.map(item => get(item, fileIdKey));
      },
    }));

    useEffect(() => {
      if (files) {
        filesByTypesDispatch({
          type: FilesByTypesReducerActionKind.SET_FILES,
          payload: {
            files,
          },
        });
      }
    }, [files]);

    useEffect(() => {
      if (fileTypes) {
        filesByTypesDispatch({
          type: FilesByTypesReducerActionKind.SET_FILE_TYPES,
          payload: {
            fileTypes,
          },
        });
      }
    }, [fileTypes]);

    function renderFileCreatedAt(file: any) {
      if (!fileCreatedAtKey) return undefined;

      const createdAt = get(file, fileCreatedAtKey);

      return ` - ${new Date(createdAt).toLocaleString([], {
        dateStyle: 'short',
        timeStyle: 'short',
      })}`;
    }

    const renderFilesByType = (idDomain: number) => {
      const filesByType = filesByTypesState.files.filter(
        file => get(file, fileTypeKey) === idDomain,
      );

      if (!filesByType.length) {
        return (
          <Empty message="There is no files for this type" position="left" />
        );
      }

      return filesByType.map(file => {
        const fileId = get(file, fileIdKey);
        return (
          <Option key={fileId}>
            <Checkbox
              onChange={e =>
                filesByTypesDispatch({
                  type: FilesByTypesReducerActionKind.TOGGLE_FILE_CHECKBOX,
                  payload: {
                    toggleFileCheckboxData: {
                      fileId,
                      fileIdKey,
                      fileTypeKey,
                      checked: e.checked,
                    },
                  },
                })
              }
              checked={!!file?.isSelected}
            />
            {get(file, fileDescriptionKey)}
            {fileCreatedAtKey && renderFileCreatedAt(file)}
            <a
              href={file.idAttachment2?.url}
              target="_blank"
              rel="noopener noreferrer"
              className="attachment"
            >
              <FiExternalLink className="p-mx-2" />
            </a>
          </Option>
        );
      });
    };

    return (
      <>
        <Container className={`s-files-by-types ${className ?? ''}`}>
          {!!filesByTypesState.fileTypes.length &&
            filesByTypesState.fileTypes.map(type => (
              <Column key={type.idDomain}>
                <Option className="header">
                  <Checkbox
                    onChange={e =>
                      filesByTypesDispatch({
                        type: FilesByTypesReducerActionKind.TOGGLE_FILE_TYPE_CHECKBOX,
                        payload: {
                          toggleFileTypeCheckboxData: {
                            idDomain: type.idDomain,
                            fileTypeKey,
                            checked: e.checked,
                          },
                        },
                      })
                    }
                    checked={!!type?.isSelected}
                  />
                  <p>{type.description}</p>
                </Option>

                {filesLoading && (
                  <>
                    <Option>
                      <Skeleton height="1rem" />
                    </Option>
                    <Option>
                      <Skeleton height="1rem" />
                    </Option>
                    <Option>
                      <Skeleton height="1rem" />
                    </Option>
                  </>
                )}

                {!filesLoading &&
                  filesByTypesState.files &&
                  renderFilesByType(type.idDomain)}
              </Column>
            ))}
          {typesLoading && (
            <>
              {[...Array(4)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Column key={i}>
                  <Option className="header">
                    <Skeleton width="13rem" height="2rem" />
                  </Option>
                  <Option>
                    <Skeleton width="11rem" height="1rem" />
                  </Option>
                  <Option>
                    <Skeleton width="10rem" height="1rem" />
                  </Option>
                  <Option>
                    <Skeleton width="12rem" height="1rem" />
                  </Option>
                </Column>
              ))}
            </>
          )}

          {!typesLoading && !filesByTypesState.fileTypes.length && (
            <Empty
              message={emptyMessage ?? 'There is no file types to show'}
              position="left"
            />
          )}
        </Container>
      </>
    );
  },
);

export default FilesByTypes;
