import React, { useRef, useEffect, useState, useCallback } from 'react';

import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { convertUtcToZonedTime } from '../../utils/convertDate';
import 'react-datepicker/dist/react-datepicker.css';
import { Container } from './styles';

/**
 * Props do componente de Form DatePicker
 */
interface FormDatePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  label?: string;
  required?: boolean;
  className?: string;
  onChange?(date: Date | null): void;
  defaultValue?: Date;
}

const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  label,
  required,
  disabled,
  className,
  onBlur,
  onChange,
  readOnly,
  ...rest
}) => {
  const datepickerRef = useRef<ReactDatePicker>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [date, setDate] = useState(
    defaultValue ? convertUtcToZonedTime(defaultValue) : null,
  );

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!datepickerRef.current?.props.selected);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleClick = useCallback(() => {
    datepickerRef.current?.setFocus();
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
      setValue: (_, value: Date | null) => {
        setDate(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className={`${className ?? ''} form-element formDatePicker`}>
      <Container
        hasError={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        required={required}
        onClick={handleClick}
        disabled={disabled}
        readOnly={readOnly}
        nolabel={!label}
      >
        {label && (
          <span>
            {error && <FiAlertCircle color="#c53030" size={15} />}
            <p>{label}:</p>
          </span>
        )}
        <ReactDatePicker
          ref={datepickerRef}
          selected={date}
          onChange={e => {
            if (onChange) onChange(e);
            setDate(e);
          }}
          onFocus={e => {
            const inputPageHeight = e.target.getBoundingClientRect().top + 60;
            const pageHeightTop = window.innerHeight;
            e.target.scrollIntoView({
              behavior: 'smooth',
              block: inputPageHeight > pageHeightTop ? 'center' : 'nearest',
              inline: 'nearest',
            });
            handleInputFocus();
          }}
          onBlur={e => {
            if (onBlur) onBlur(e);
            handleInputBlur();
          }}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
          locale="locale"
          dateFormat="P"
        />
      </Container>
      {error && (
        <small id="username2-help" className="p-error p-d-block">
          {error}
        </small>
      )}
    </div>
  );
};

export default FormDatePicker;
