import { Dialog } from 'primereact/dialog';
import React, { Ref, forwardRef, useImperativeHandle, useReducer } from 'react';
import { useMutation } from '@apollo/client';
import { TComment } from '../CommentAndReplies/interfaces';
import ReplyToComment from '../ReplyToComment';
import { updateChildCommentQuery } from './queries';
import { useRefHook } from '../../../hooks/useRefHook';
import { FileUploadResponse } from '../../FileUpload/interfaces';
import { IAttachment } from '../../../shared/interfaces/attachment';
import {
  editReplyDialogReducer,
  editReplyDialogReducerInitialState,
} from './reducer';
import { EditReplyDialogActionKind } from './interfaces';

export type EditReplyDialogRef = {
  setCommentToEdit: (comment: TComment) => void;
};

interface IEditReplyDialog {
  ref: Ref<EditReplyDialogRef>;
  onSave?: () => void;
  parentIsRestrictInfo?: boolean;
  showRestrictInfo?: boolean;
  onUploadAttachment(e: FileUploadResponse[]): Promise<IAttachment | undefined>;
}

const EditReplyDialog: React.FC<IEditReplyDialog> = forwardRef(
  (
    { onSave, parentIsRestrictInfo, showRestrictInfo, onUploadAttachment },
    ref,
  ) => {
    const [updateChildCommentMutation, { loading }] = useMutation(
      updateChildCommentQuery,
    );

    const [state, stateDispatch] = useReducer(
      editReplyDialogReducer,
      editReplyDialogReducerInitialState,
    );
    const { showError, showSuccess } = useRefHook();

    useImperativeHandle(
      ref,
      () => ({
        setCommentToEdit: (comment: TComment) => {
          stateDispatch({
            type: EditReplyDialogActionKind.OPEN_DIALOG,
            payload: {
              commentToEdit: comment,
            },
          });
        },
      }),
      [],
    );

    function handleCloseDialog() {
      stateDispatch({
        type: EditReplyDialogActionKind.CLOSE_DIALOG,
      });
    }

    async function onSaveComment(
      editedMessage: string,
      isRestrictInfo: boolean,
      idAttachment?: number,
    ) {
      try {
        await updateChildCommentMutation({
          variables: {
            data: {
              idComment: state.commentToEdit?.idComment,
              isRestrictInfo,
              message: editedMessage,
              idAttachment,
            },
          },
        });

        showSuccess({
          summary: 'Comment updated successfully',
        });

        if (onSave) onSave();
        handleCloseDialog();
      } catch (error) {
        showError({
          summary: 'Error updating comment',
          detail: error.message,
        });
      }
    }

    return (
      <Dialog
        header="Edit your reply"
        visible={state.dialogVisible}
        style={{ width: '520px' }}
        onHide={() => handleCloseDialog()}
        contentClassName="p-pt-2"
      >
        <ReplyToComment
          initialValue={state.commentToEdit?.message ?? ''}
          onSave={e => onSaveComment(e.reply, e.isRestrictInfo, e.idAttachment)}
          attachmentDisabled={!!state.commentToEdit?.idAttachment2}
          saveLoading={loading}
          showRestrictInfo={showRestrictInfo}
          isRestrictInfoValue={parentIsRestrictInfo}
          isRestrictInfoDefaultValue={state.commentToEdit?.isRestrictInfo}
          onUploadAttachment={onUploadAttachment}
        />
      </Dialog>
    );
  },
);

export default EditReplyDialog;
