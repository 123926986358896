/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
import React, {
  Dispatch,
  Ref,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import { DataTablePageParams, DataTableSortParams } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FiExternalLink } from 'react-icons/fi';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import Button from '../../../../components/Button';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';

import { Container } from './styles';
import { IProductImage } from '../interfaces';
import { useRefHook } from '../../../../hooks/useRefHook';
import { downloadAttachmentsFromServerQuery } from '../../../../shared/querys/attachment';
import { StoragePathsTypes } from '../../../../shared/enums/storagePaths';
import {
  deleteProductImagesQuery,
  listProductImagesQuery,
  updateProductImageTypesQuery,
} from './queries';
import pagination from '../../../../config/pagination';
import {
  AttachmentsRef,
  ProductAttachmentsReducerActionKind,
  ProductImagesLazyParams,
} from './interfaces';
import Grid from '../../../../components/Grid';
import { gridConstants } from '../../../../components/Grid/constants';
import {
  productAttachmentsReducer,
  productAttachmentsReducerInitialState,
} from './reducers';
import AttachmentUpload, { IAttachmentUploadRef } from './AttachmentUpload';

interface IAttachmentProps extends PageTabContainerProps {
  ref: Ref<AttachmentsRef>;
  idProduct: number;
  indConfection?: boolean;
  changePropertyValue(property: string, value: any): void;
  idMainImage?: number | string;
  setUploadImageTempUrl: Dispatch<SetStateAction<string>>;
  userCanChangeFields: boolean;
}

const Attachments: React.FC<IAttachmentProps> = forwardRef(
  (
    {
      selected,
      idProduct,
      indConfection,
      changePropertyValue,
      idMainImage,
      setUploadImageTempUrl,
      userCanChangeFields,
    },
    ref,
  ) => {
    const [deleteProductAttachmentsMutation, { loading: deleteInProgress }] =
      useMutation(deleteProductImagesQuery);
    const [updateProductImageTypesMutation] = useMutation(
      updateProductImageTypesQuery,
    );

    const [attachmentsState, attachmentsDispatch] = useReducer(
      productAttachmentsReducer,
      productAttachmentsReducerInitialState,
    );

    const attachmentUploadRef = useRef<IAttachmentUploadRef>(null);
    const { showError, showSuccess } = useRefHook();

    const [lazyParams, setLazyParams] = useState<ProductImagesLazyParams>(
      pagination.initialLazyParams,
    );

    const [
      loadAttachmentsData,
      {
        loading: attachmentsLoading,
        data: attachmentsData,
        refetch: attachmentsRefetch,
      },
    ] = useLazyQuery(listProductImagesQuery, {
      variables: {
        // Garante que loading sera atualizado mesmo no refetch
        notifyOnNetworkStatusChange: true,
        data: {
          idProduct,
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
        },
      },
      onCompleted: response => {
        if (response.listProductImages) {
          attachmentsDispatch({
            type: ProductAttachmentsReducerActionKind.SET_PRODUCT_ATTACHMENTS,
            payload: { productAttachments: response.listProductImages.data },
          });
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Product Attachments',
          detail: errorData.message,
        });
      },
    });

    const imageBodyTemplate = (rowData: { imageUrl: string | undefined }) => {
      return rowData.imageUrl ? (
        <a
          href={`${rowData.imageUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="attachment"
        >
          Open
          <FiExternalLink />
        </a>
      ) : (
        <p>Image not found in the bucket</p>
      );
    };

    const mainColumnBody = (rowData: IProductImage) => {
      return (
        <RadioButton
          value={rowData.idAttachment}
          checked={idMainImage === rowData.idAttachment}
          onChange={e => {
            changePropertyValue('idimage', e.value);
            setUploadImageTempUrl(rowData.imageUrl ? rowData.imageUrl : '');
          }}
          disabled={!userCanChangeFields}
        />
      );
    };

    function checkNotSavedData(accept: () => void) {
      if (attachmentsState.productAttachmentsToUpdate.size) {
        confirmDialog({
          message: 'You will lost any attachments information not saved',
          header: 'Are you sure you want to proceed?',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept,
        });
      } else {
        accept();
      }
    }

    function handleSetUniqueImageType(
      idProductImage: number,
      imageType: string,
    ) {
      const alreadyHasImageType = attachmentsState.productAttachments.filter(
        productImage =>
          productImage.productImageTypes?.find(
            productImageType => productImageType.type === imageType,
          ),
      );

      const newProductImages = [...attachmentsState.productAttachments];

      if (alreadyHasImageType && alreadyHasImageType.length) {
        // Busca indice do tipo
        const oldMainImageTypeIndex =
          alreadyHasImageType[0].productImageTypes?.findIndex(
            productImageType => productImageType.type === imageType,
          );

        // Remove do array
        alreadyHasImageType[0].productImageTypes!.splice(
          oldMainImageTypeIndex!,
          1,
        );

        const oldImageTypeIndex = newProductImages.findIndex(
          productImage =>
            productImage.idProductImage ===
            alreadyHasImageType[0].idProductImage,
        );

        // Adiciona no array
        newProductImages.splice(oldImageTypeIndex, 1, alreadyHasImageType[0]);
      }

      const index = attachmentsState.productAttachments.findIndex(
        productImage => productImage.idProductImage === idProductImage,
      );

      if (!newProductImages[index].productImageTypes) {
        newProductImages[index].productImageTypes = [];
      }

      newProductImages[index].productImageTypes?.push({
        type: imageType,
      });

      attachmentsDispatch({
        type: ProductAttachmentsReducerActionKind.SET_PRODUCT_ATTACHMENTS_TO_UPDATE,
        payload: {
          productAttachments: newProductImages,
          idProductImageUpdated: idProductImage,
        },
      });
    }

    function handleSetImageType(
      imageType: string,
      checked: boolean,
      productImage: IProductImage,
    ) {
      const newProductImages = [...attachmentsState.productAttachments];

      const productImageIndex = newProductImages.findIndex(
        productI => productI.idProductImage === productImage.idProductImage,
      );

      if (!productImage.productImageTypes) productImage.productImageTypes = [];

      if (!checked) {
        // Busca indice do tipo
        const oldImageTypeIndex = productImage.productImageTypes?.findIndex(
          productImageType => productImageType.type === imageType,
        );

        // Remove do array
        productImage.productImageTypes!.splice(oldImageTypeIndex!, 1);
      } else {
        productImage.productImageTypes?.push({ type: imageType });
      }

      // Adiciona no array
      newProductImages.splice(productImageIndex, 1, productImage);

      attachmentsDispatch({
        type: ProductAttachmentsReducerActionKind.SET_PRODUCT_ATTACHMENTS_TO_UPDATE,
        payload: {
          productAttachments: newProductImages,
          idProductImageUpdated: productImage.idProductImage,
        },
      });
    }

    const radioButtonColumnBody = (
      rowData: IProductImage,
      imageType: string,
    ) => {
      const isMainColor = rowData.productImageTypes?.find(
        productImageType => productImageType.type === imageType,
      );

      return (
        <RadioButton
          value={rowData.idProductImage}
          checked={!!isMainColor}
          onChange={() => {
            if (!isMainColor) {
              handleSetUniqueImageType(rowData.idProductImage, imageType);
            }
          }}
          disabled={!userCanChangeFields}
        />
      );
    };

    const checkboxColumnBody = (rowData: IProductImage, imageType: string) => {
      const isChecked = rowData.productImageTypes?.find(
        productImageType => productImageType.type === imageType,
      );

      return (
        <Checkbox
          value={rowData.idProductImage}
          checked={!!isChecked}
          onChange={() => {
            handleSetImageType(imageType, !isChecked, rowData);
          }}
          disabled={!userCanChangeFields}
        />
      );
    };

    const [downloadAttachments, { loading: productsFilesDownloadInProgress }] =
      useLazyQuery(downloadAttachmentsFromServerQuery, {
        onCompleted: response => {
          if (response.downloadAttachmentsFromServer) {
            window.open(response.downloadAttachmentsFromServer, '_blank');
          }
        },
        onError: error => {
          showError({
            summary: 'Error while downloading files',
            detail: error.message,
          });
        },
      });

    function handleDownloadFiles() {
      const attachmentsSelected = attachmentsState.productAttachments.filter(
        attachment =>
          attachmentsState.selectedProductAttachments.includes(attachment),
      );
      if (!attachmentsSelected?.length) {
        showError({
          summary: 'You need to select at least one item do download',
        });

        return;
      }

      downloadAttachments({
        variables: {
          data: attachmentsSelected.map(attachment => {
            return {
              name: attachment.idAttachment2?.name,
              folder: StoragePathsTypes.PRODUCT,
            };
          }),
        },
      });
    }

    async function handleDeleteSelected() {
      try {
        await deleteProductAttachmentsMutation({
          variables: {
            idsProductImages: attachmentsState.selectedProductAttachments.map(
              a => a.idProductImage,
            ),
          },
        });

        const mainImageBeingDeleted =
          attachmentsState.selectedProductAttachments.find(
            attachment => attachment.idAttachment === idMainImage,
          );

        if (mainImageBeingDeleted) {
          changePropertyValue('idimage', null);
          setUploadImageTempUrl('/');
        }

        showSuccess({
          summary: 'Attachments deleted',
          detail: `${attachmentsState.selectedProductAttachments.length} attachments deleted`,
        });

        attachmentsDispatch({
          type: ProductAttachmentsReducerActionKind.CLEAN_PRODUCT_ATTACHMENTS_RELATED_DATA,
        });
      } catch (error) {
        showError({
          summary: 'Error while deleting attachments',
          detail: error.message,
        });
      }
    }

    useImperativeHandle(ref, () => ({
      handleUpdateAttachments: async () => {
        if (attachmentsState.productAttachmentsToUpdate.size) {
          try {
            await updateProductImageTypesMutation({
              variables: {
                data: Array.from(
                  attachmentsState.productAttachmentsToUpdate,
                ).map(idProductImage => {
                  const productImage = attachmentsState.productAttachments.find(
                    product => product.idProductImage === idProductImage,
                  );

                  return {
                    idProductImage,
                    productImageTypes: productImage?.productImageTypes?.map(
                      productImageType => productImageType.type,
                    ),
                  };
                }),
              },
            });

            attachmentsDispatch({
              type: ProductAttachmentsReducerActionKind.CLEAN_PRODUCT_ATTACHMENTS_RELATED_DATA,
            });
          } catch (error) {
            return {
              moduleChanged: 'Attachments',
              errors: [error.message],
            };
          }
        }

        return undefined;
      },
    }));

    const gridHeader = (
      <Container className="form-group p-grid p-flex-grid">
        <Button
          className="p-mr-2"
          label="Add Attachment"
          onClick={() =>
            checkNotSavedData(() => attachmentUploadRef.current?.toggleModal())
          }
          disabled={!userCanChangeFields}
          type="button"
        />
        <Button
          type="button"
          label="Download"
          onClick={() => handleDownloadFiles()}
          disabled={!attachmentsState.selectedProductAttachments.length}
          icon={
            productsFilesDownloadInProgress
              ? 'pi pi-spin pi-spinner'
              : 'pi pi-download'
          }
        />
        <Button
          className="p-ml-auto"
          label="Delete selected"
          severity="danger"
          onClick={() => {
            confirmDialog({
              message: `Are you sure you want to delete ${attachmentsState.selectedProductAttachments.length} attachments?`,
              header: 'Delete Confirmation',
              icon: 'pi pi-question-circle',
              accept: () => checkNotSavedData(() => handleDeleteSelected()),
            });
          }}
          disabled={
            !attachmentsState.selectedProductAttachments.length ||
            !userCanChangeFields
          }
          loading={deleteInProgress}
          type="button"
        />
      </Container>
    );

    function onPage(event: DataTablePageParams) {
      checkNotSavedData(() => setLazyParams({ ...lazyParams, ...event }));
    }

    function onSort(event: DataTableSortParams) {
      checkNotSavedData(() => setLazyParams({ ...lazyParams, ...event }));
    }

    useEffect(() => {
      if (selected && !attachmentsState.productAttachments.length) {
        loadAttachmentsData();
      }
    }, [
      attachmentsState.productAttachments.length,
      loadAttachmentsData,
      selected,
    ]);

    return (
      <PageTabContainer selected={selected}>
        <AttachmentUpload
          ref={attachmentUploadRef}
          idProduct={idProduct}
          attachmentsRefetch={attachmentsRefetch}
        />
        <Container>
          <Grid
            className="s-internal-datatable"
            value={attachmentsState.productAttachments}
            header={gridHeader}
            selection={attachmentsState.selectedProductAttachments}
            onSelectionChange={e => {
              attachmentsDispatch({
                type: ProductAttachmentsReducerActionKind.SET_SELECTED_PRODUCT_ATTACHMENTS,
                payload: { selectedProductAttachments: e.value },
              });
            }}
            emptyMessage="No attachments found"
            selectionMode="checkbox"
            loading={attachmentsLoading}
            lazy
            rows={lazyParams.rows}
            first={lazyParams.first}
            totalRecords={attachmentsData?.listProductImages?.items ?? 0}
            scrollable
            scrollHeight={gridConstants.internalGridScrollHeight}
            onSort={onSort}
            onPage={onPage}
            removableSort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
          >
            <Column
              selectionMode="multiple"
              style={{ width: '3em' }}
              reorderable={false}
            />
            <Column
              header="Main"
              style={{ width: '4em' }}
              body={mainColumnBody}
              className="p-jc-center"
            />
            <Column
              header="Products"
              style={{ width: '5em' }}
              className="p-jc-center"
              body={(e: IProductImage) => checkboxColumnBody(e, 'products')}
            />
            <Column
              header="Main HRP"
              style={{ width: '4em' }}
              className="p-jc-center"
              headerClassName="p-text-center"
              body={(e: IProductImage) => radioButtonColumnBody(e, 'hrp_main')}
            />
            <Column
              header="HRP"
              style={{ width: '4em' }}
              className="p-jc-center"
              headerClassName="p-text-center"
              body={(e: IProductImage) => checkboxColumnBody(e, 'hrp')}
            />
            <Column
              header="Main Colors"
              style={{ width: '5em' }}
              className="p-jc-center"
              headerClassName="p-text-center"
              body={(e: IProductImage) =>
                radioButtonColumnBody(e, 'colour_main')
              }
            />
            <Column
              header="Colors"
              style={{ width: '5em' }}
              className="p-jc-center"
              body={(e: IProductImage) => checkboxColumnBody(e, 'colour')}
            />
            <Column
              header="Main Printing"
              style={{ width: '5em' }}
              className="p-jc-center"
              headerClassName="p-text-center"
              body={(e: IProductImage) =>
                radioButtonColumnBody(e, 'printing_main')
              }
            />
            <Column
              header="Printing"
              style={{ width: '5em' }}
              className="p-jc-center"
              body={(e: IProductImage) => checkboxColumnBody(e, 'printing')}
            />
            <Column
              header="Main Die Cut"
              style={{ width: '6em' }}
              className="p-jc-center"
              headerClassName="p-text-center"
              body={(e: IProductImage) =>
                radioButtonColumnBody(e, 'die_cut_main')
              }
            />
            <Column
              header="Die Cut"
              style={{ width: '5em' }}
              className="p-jc-center"
              headerClassName="p-text-center"
              body={(e: IProductImage) => checkboxColumnBody(e, 'die_cut')}
            />
            <Column
              header="Main Package"
              style={{ width: '5em' }}
              className="p-jc-center"
              headerClassName="p-text-center"
              body={(e: IProductImage) =>
                radioButtonColumnBody(e, 'packages_main')
              }
            />
            {indConfection && (
              <Column
                header="Tech Pack"
                style={{
                  width: '5em',
                }}
                body={(e: IProductImage) =>
                  radioButtonColumnBody(e, 'tech_pack')
                }
                className="p-jc-center"
                headerClassName="p-text-center"
              />
            )}
            {indConfection && (
              <Column
                header="Technical File"
                style={{
                  width: '5em',
                }}
                className="p-jc-center"
                headerClassName="p-text-center"
                body={(e: IProductImage) =>
                  radioButtonColumnBody(e, 'technical_file')
                }
              />
            )}
            <Column
              field="idAttachment2.name"
              header="Name"
              sortable
              headerStyle={{ flex: '1 1' }}
              style={{ flex: '1 1' }}
            />
            <Column
              field="imageUrl"
              header="Attachment"
              body={imageBodyTemplate}
            />
          </Grid>
        </Container>
      </PageTabContainer>
    );
  },
);

export default Attachments;
