import React from 'react';
import { Route as ReactDOMRoute, RouteProps, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';

import DefaultLayout from '../pages/_layouts/default';
import AuthLayout from '../pages/_layouts/auth';

interface IRouteProps extends RouteProps {
  isPrivate?: boolean;
  idEntity?: number;
  component: React.ComponentType;
}

const Route: React.FC<IRouteProps> = ({
  isPrivate = false,
  idEntity,
  component: Component,
  ...rest
}) => {
  const { user, roles } = useAuth();

  if (!user && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (!!user && !isPrivate) {
    return <Redirect to="/home" />;
  }

  if (idEntity && roles.entities && !roles.entities.includes(idEntity)) {
    return <Redirect to="/forbidden" />;
  }

  const Layout = user ? DefaultLayout : AuthLayout;

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return (
          <Layout>
            <Component />
          </Layout>
        );
      }}
    />
  );
};

export default Route;
