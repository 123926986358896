import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { DataTable } from 'primereact/datatable';
import {
  deleteProductPackageDescriptionQuery,
  listPackageDescriptionsQuery,
} from './queries';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import FormTitle from '../../../../../../components/FormTitle';
import MainButton from '../../../../../../components/MainButton';

import { gridConstants } from '../../../../../../components/Grid/constants';
import {
  PackageDescriptionFieldsPermissions,
  ProductPackageDescription,
} from '../../interfaces';
import { GridActions } from '../../../../../../components/Grid/styles';
import ManagePackageDescription, {
  IManagePackageDescriptionRef,
} from './ManagePackageDescription';

interface IPackageDescriptionsProps {
  idProductPackage: number;
  userCanChangeFields: boolean;
  fieldsPermissions: PackageDescriptionFieldsPermissions;
}

const PackageDescriptions: React.FC<IPackageDescriptionsProps> = ({
  idProductPackage,
  userCanChangeFields,
  fieldsPermissions,
}) => {
  const { showError, showSuccess } = useRefHook();
  const [deleteProductPackageDescription] = useMutation(
    deleteProductPackageDescriptionQuery,
  );

  const manageDescriptionRef = useRef<IManagePackageDescriptionRef>(null);

  const [itemBeingDeleted, setItemBeingDeleted] = useState<number>();

  // const [lazyParams, setLazyParams] =
  //   useState<ProductPackageDescriptionLazyParams>(pagination.initialLazyParams);

  const [packageDescriptions, setPackageDescriptions] =
    useState<{ items: number; data: ProductPackageDescription[] }>();

  const { loading, refetch } = useQuery(
    listPackageDescriptionsQuery(fieldsPermissions),
    {
      variables: {
        idProductPackage,
        /**
         * ! A paginacao foi removida a pedido do cliente
         * * manter como ponto de atencao em caso de lentidao
         */
        pagination: {
          _page: 1, // lazyParams.page + 1,
          _limit: 0, // lazyParams.rows,
        },
      },
      onCompleted: response => {
        if (response.listProductPackageDescriptionsByIdProductPackage) {
          setPackageDescriptions({
            ...response.listProductPackageDescriptionsByIdProductPackage,
          });
        }
      },
      onError: err => {
        showError({
          summary: 'Error while getting Product Package descriptions',
          detail: err.message,
        });
      },
    },
  );

  // function onPage(e: DataTablePageParams) {
  //   setLazyParams(prev => {
  //     return { ...prev, ...e };
  //   });
  // }

  async function handleRemoveDescription(idPackageDescription: number) {
    setItemBeingDeleted(idPackageDescription);

    try {
      await deleteProductPackageDescription({
        variables: {
          data: {
            idPackageDescription,
            idProductPackage,
          },
        },
      });

      await refetch();

      showSuccess({ summary: 'Description deleted' });
    } catch (error) {
      showError({
        summary: 'Error while deleting description',
        detail: error.message,
      });
    } finally {
      setItemBeingDeleted(undefined);
    }
  }

  const gridActions = (rowData: ProductPackageDescription) => {
    const { idPackageDescription } = rowData;
    const isBeingDeleted = itemBeingDeleted === idPackageDescription;

    if (idPackageDescription === undefined) return undefined;

    return (
      <GridActions>
        <button
          type="button"
          onClick={() =>
            manageDescriptionRef.current?.toggleManageDescriptionDialog(rowData)
          }
          disabled={!!itemBeingDeleted || !userCanChangeFields}
        >
          <FiEdit2 size={20} />
        </button>
        <button
          type="button"
          className="trash-button"
          onClick={() =>
            confirmDialog({
              message: 'Are you sure you want to delete this item?',
              header: 'Delete Confirmation',
              icon: 'pi pi-info-circle',
              acceptClassName: 'p-button-danger',
              accept: () => handleRemoveDescription(idPackageDescription),
            })
          }
          disabled={!!itemBeingDeleted || !userCanChangeFields}
        >
          {isBeingDeleted ? (
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: '1.43rem' }}
            />
          ) : (
            <FiTrash2 size={20} />
          )}
        </button>
      </GridActions>
    );
  };

  return (
    <div className="p-col-12">
      <span className="p-d-flex">
        <FormTitle name="Descriptions" />

        <MainButton
          className="p-ml-4"
          label="Add Description"
          type="button"
          onClick={() =>
            manageDescriptionRef.current?.toggleManageDescriptionDialog({
              idProductPackage,
            })
          }
          disabled={!userCanChangeFields}
        />
      </span>

      <DataTable
        className="s-internal-datatable"
        emptyMessage="No descriptions found"
        loading={loading}
        value={packageDescriptions?.data}
        lazy
        // rows={lazyParams.rows}
        // first={lazyParams.first}
        // totalRecords={!packageDescriptions ? 0 : packageDescriptions.items}
        scrollable
        scrollHeight={gridConstants.internalGridScrollHeight}
        // onPage={onPage}
      >
        <Column
          field="actions"
          body={rowData => gridActions(rowData)}
          className="p-col-1"
        />
        {fieldsPermissions.idPackageDescription.view && (
          <Column
            field="idPackageDescription2.description"
            header="Selling Package Description"
            className="p-col-3"
          />
        )}
        {fieldsPermissions.idResponsible.view && (
          <Column
            field="idResponsible2.description"
            header="Responsible"
            className="p-col-3"
          />
        )}
        {fieldsPermissions.comments.view && (
          <Column field="comments" header="Comments" className="p-col-5" />
        )}
      </DataTable>

      <ManagePackageDescription
        ref={manageDescriptionRef}
        packageDescriptionsRefetch={refetch}
        fieldsPermissions={fieldsPermissions}
        userCanChangeFields={userCanChangeFields}
      />
    </div>
  );
};

export default PackageDescriptions;
