import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { FormHandles } from '@unform/core';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { confirmDialog } from 'primereact/confirmdialog';
import { ISat, ISatCancellationReason } from '../interfaces';
import { useRefHook } from '../../../../hooks/useRefHook';
import ToastLife from '../../../../shared/enums/toastLife';
import getValidationErrors from '../../../../utils/getValidationErrors';
import FormCheckbox from '../../../../components/FormCheckbox';
import FormInputTextArea from '../../../../components/FormInputTextArea';
import { SatStatus } from '../../../../components/SatStatusTag';
import Button from '../../../../components/Button';

interface ICancelSatMenuProps {
  isVisibleCancelSatMenu: boolean;
  setIsVisibleCancelSatMenu(e: boolean): void;
  sat: ISat;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  satRefetch(): Promise<void>;
}

const CancelSatMenu: React.FC<ICancelSatMenuProps> = ({
  isVisibleCancelSatMenu,
  setIsVisibleCancelSatMenu,
  sat,
  setLoading,
  satRefetch,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { toastRef } = useRefHook();

  const [satCancellationReason, setSatCancellationReason] =
    useState<ISatCancellationReason>();

  const onHide = () => {
    setIsVisibleCancelSatMenu(false);
  };

  // Mutation para criar satCancellationReason
  const createSatCancellationReasonQuery = gql`
    mutation CancelSat($data: CreateSatCancellationReasonInput!) {
      cancelSat(data: $data) {
        idSatCancellationReason
      }
    }
  `;

  // Query para listar Sat Cancel
  const listSatCancellationReasonQuery = gql`
    query ListSatCancellationReasonQuery($id: Int!) {
      listSatCancellationReason(id: $id) {
        idSatCancellationReason
        idSat
        isPrice
        isQuality
        isDeliveryTime
        isLegalRequirements
        isOthers
        detailReason
      }
    }
  `;

  const [loadSatCancellationReason, { loading: satCancellationReasonLoading }] =
    useLazyQuery(listSatCancellationReasonQuery, {
      variables: {
        id: sat.idSat,
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting SAT cancellation Reason',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
      onCompleted: async response => {
        if (response.listSatCancellationReason) {
          setSatCancellationReason(response.listSatCancellationReason);
        }
      },
    });

  // Método para chamar a mutation
  const [createSatCancellationReasonMutation] = useMutation(
    createSatCancellationReasonQuery,
  );

  const handleConfirmCancellation = (formData: any) => {
    confirmDialog({
      message: `Are you sure you want to cancel this SAT?`,
      header: 'Cancel SAT',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        setLoading(true);

        try {
          await createSatCancellationReasonMutation({
            variables: {
              data: {
                idSat: sat.idSat,
                isPrice: formData.isPrice,
                isQuality: formData.isQuality,
                isDeliveryTime: formData.isDeliveryTime,
                isLegalRequirements: formData.isLegalRequirements,
                isOthers: formData.isOthers,
                detailReason: formData.detailReason,
              },
            },
          });

          await satRefetch();

          // Em caso de sucesso exibe toast
          toastRef.current?.show({
            severity: 'success',
            summary: `SAT cancelled successfully`,
            life: ToastLife.SUCCESS,
          });
        } catch (error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while cancelling SAT`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  async function handleSatCancellationReasonSave(formData: any) {
    try {
      // Esvazia possíveis erros já existentes no formulário
      formRef.current?.setErrors({});

      // Define requisitos de preenchimento do formulario
      const schema = Yup.object().shape({
        detailReason: Yup.string()
          .nullable()
          .notRequired()
          .when(['isOthers'], {
            is: false,
            then: Yup.string().notRequired(),
            otherwise: Yup.string().required('Required Field'),
          }),
      });

      // Efetua validação
      await schema.validate(formData, { abortEarly: false });

      const hasNoReasonSelected =
        !formData.isPrice &&
        !formData.isQuality &&
        !formData.isDeliveryTime &&
        !formData.isLegalRequirements &&
        !formData.isOthers;

      if (hasNoReasonSelected) {
        // Exibe toast sobre o erro
        toastRef.current?.show({
          severity: 'warn',
          summary: 'Please, fill at least one reason',
          life: ToastLife.WARN,
        });
        return;
      }

      // Se não tiver nenhum erro, abre a confimação
      handleConfirmCancellation(formData);
      setIsVisibleCancelSatMenu(false);
    } catch (error) {
      // Verifica se são erros de validação
      if (error instanceof Yup.ValidationError) {
        // Pega os erros de cada input
        const errors = getValidationErrors(error);

        // Define os erros para cada input
        formRef.current?.setErrors(errors);
      } else if (error instanceof Error) {
        // Exibe toast sobre o erro
        toastRef.current?.show({
          severity: 'error',
          summary: `Error while cancelling SAT`,
          detail: error.message,
          life: ToastLife.ERROR,
        });
      }
    }
  }

  const footer = () => {
    return (
      <div className="p-d-flex p-jc-end">
        <Button
          label="Confirm"
          icon="pi pi-check"
          type="button"
          onClick={() => formRef.current?.submitForm()}
          disabled={!!satCancellationReason?.idSatCancellationReason}
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide()}
          severity="danger"
        />
      </div>
    );
  };

  useEffect(() => {
    if (sat.status === SatStatus.CANCELED && isVisibleCancelSatMenu) {
      loadSatCancellationReason();
    }
  }, [sat.status, isVisibleCancelSatMenu, loadSatCancellationReason]);

  return (
    <Dialog
      header="SAT Cancel"
      visible={isVisibleCancelSatMenu}
      onHide={() => onHide()}
      style={{ width: '444px' }}
      footer={footer}
    >
      {!satCancellationReasonLoading && (
        <Form
          className="p-formgrid p-grid p-px-3"
          ref={formRef}
          onSubmit={handleSatCancellationReasonSave}
        >
          <h3 className="p-field p-col-12">
            Please confirm the cancellation reason:
          </h3>
          <FormCheckbox
            className="p-d-flex p-as-center p-field p-col-3"
            name="isPrice"
            label="Price"
            value={
              !satCancellationReasonLoading
                ? satCancellationReason?.isPrice
                : false
            }
            disabled={!!satCancellationReason?.idSatCancellationReason}
          />

          <FormCheckbox
            className="p-d-flex p-as-center p-field p-col-5"
            name="isLegalRequirements"
            label="Legal Requirements"
            value={
              !satCancellationReasonLoading
                ? satCancellationReason?.isLegalRequirements
                : false
            }
            disabled={!!satCancellationReason?.idSatCancellationReason}
          />

          <FormCheckbox
            className="p-d-flex p-as-center p-field p-col-4"
            name="isQuality"
            label="Quality"
            value={
              !satCancellationReasonLoading
                ? satCancellationReason?.isQuality
                : false
            }
            disabled={!!satCancellationReason?.idSatCancellationReason}
          />

          <FormCheckbox
            className="p-d-flex p-as-center p-field p-col-3"
            name="isOthers"
            label="Others"
            value={
              !satCancellationReasonLoading
                ? satCancellationReason?.isOthers
                : false
            }
            disabled={!!satCancellationReason?.idSatCancellationReason}
          />

          <FormCheckbox
            className="p-d-flex p-as-center p-field p-col-5"
            name="isDeliveryTime"
            label="Delivery Time"
            value={
              !satCancellationReasonLoading
                ? satCancellationReason?.isDeliveryTime
                : false
            }
            disabled={!!satCancellationReason?.idSatCancellationReason}
          />

          <FormInputTextArea
            className="p-field p-col-12"
            name="detailReason"
            label="Detail Cancellation Reason"
            rows={6}
            value={
              !satCancellationReasonLoading
                ? satCancellationReason?.detailReason
                : undefined
            }
            disabled={!!satCancellationReason?.idSatCancellationReason}
          />
        </Form>
      )}
      {satCancellationReasonLoading && (
        <div>
          <div>
            <Skeleton shape="rectangle" height="255px" />
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default CancelSatMenu;
