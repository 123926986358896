import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';

import React, { Dispatch } from 'react';
import { numberToPtString } from '../../../../../../../../utils/formatLocale';
import {
  parseCurrencyPtBrNumberColumn,
  parsePtBrNumberColumn,
} from '../../../../../../../../utils/gridColumnsParse';

import {
  ForeignTradeContainerItemsPermissions,
  ForeignTradeReducerAction,
  ForeignTradeReducerActionKind,
} from '../../../../interfaces';
import { SatForeignTradeShipmentContainer } from '../../../interfaces';

import {
  SatForeignTradeItem,
  SatForeignTradeItemContainer,
  SatItemReference,
} from '../../interfaces';
import { getAllRowsAsTrue } from '../../../../../../../../components/Grid/utils';
import { Container } from './styles';
import { containerColumns } from '../../constants';

/**
 * Interface de Reference Sizes
 */
interface IReferenceSizesProps {
  shipmentContainers?: SatForeignTradeShipmentContainer[];

  foreignTradesDispatch: Dispatch<ForeignTradeReducerAction>;

  satItemReference: SatItemReference;

  containerItemsPermissions: ForeignTradeContainerItemsPermissions;

  userCanDeleteContainerItem: boolean;

  shipmentIsCanceled: boolean;

  satCurrency?: string;

  foreignTradeItem: SatForeignTradeItem;

  containerItemsRowIndex: number;
  satItemReferenceRowIndex: number;
}

const ReferenceSizes: React.FC<IReferenceSizesProps> = ({
  shipmentContainers,
  foreignTradesDispatch,
  satItemReference,
  containerItemsPermissions,
  userCanDeleteContainerItem,
  shipmentIsCanceled,
  satCurrency,
  foreignTradeItem,
  containerItemsRowIndex,
  satItemReferenceRowIndex,
}) => {
  /**
   * Chave de identificacao de cada linha da grid
   */
  const gridDataKey = 'idSatForeignTradeItemReference';

  /**
   * Gera ID do input para a grid
   * @param field Coluna da grid
   * @param rowIndex Indice do objeto da grid
   * @param outerTableRowIndex Indice da linha da tabela que envolve a grid (satItemReferences)
   * @param ciRowIndex  Indice da linha da tabela que envolve ambas as grids (containerItems)
   * @returns ID para o Input da grid
   */
  function getInputId(
    field: string,
    rowIndex: number,
    outerTableRowIndex = satItemReferenceRowIndex,
    ciRowIndex = containerItemsRowIndex,
  ) {
    return `containerItems-${foreignTradeItem.idSatForeignTrade}-satItemReferences-${ciRowIndex}-referenceSizes-${outerTableRowIndex}-${field}-${rowIndex}`;
  }

  /**
   * Gera foco no proximo input ao pressionar ENTER
   * @param e Evento do teclado
   * @param field Coluna da grid
   * @param index Indice da grid
   * @returns
   */
  function handleGoToNextField(
    e: React.KeyboardEvent<HTMLInputElement>,
    field: string,
    index: number,
  ) {
    if (e.key === 'Enter') {
      // Busca o input diretamente na proxima linha
      const inputId = getInputId(field, index + 1);
      const inputBelow = document.getElementById(inputId);

      if (inputBelow) {
        inputBelow.focus();
        return;
      }

      // Busca o input na proxima linha da tabela que envolve a grid
      const inputIdOnOuterTable = getInputId(
        field,
        0, // O input deve estar na primeira linha
        satItemReferenceRowIndex + 1,
      );
      const inputOnOuterTable = document.getElementById(inputIdOnOuterTable);

      if (inputOnOuterTable) {
        inputOnOuterTable.focus();
        return;
      }

      // Busca o input na proxima linha da tabela que envolve ambas as grids
      const inputIdOnContainerItemsTable = getInputId(
        field,
        0, // O input deve estar na primeira linha
        0,
        containerItemsRowIndex + 1,
      );

      document.getElementById(inputIdOnContainerItemsTable)?.focus();
    }
  }

  const renderContainerEditor = (
    props: ColumnEditorOptions,
    idSatForeignTradeCtnr: number,
    fractionDigits: number,
  ) => {
    const containerItem = foreignTradeItem.satForeignTradeItemCtnrs?.find(
      itemContainer =>
        itemContainer.idSatForeignTradeCtnr === idSatForeignTradeCtnr &&
        itemContainer.idSatForeignTradeItemReference ===
          props.rowData.idSatForeignTradeItemReference,
    );

    const fieldValue = containerItem
      ? containerItem[props.field as keyof SatForeignTradeItemContainer]
      : 0;

    if (
      !containerItemsPermissions[
        props.field as keyof ForeignTradeContainerItemsPermissions
      ].edit ||
      shipmentIsCanceled
    ) {
      return numberToPtString(fieldValue);
    }

    const inputIdField = `container-${idSatForeignTradeCtnr}-${props.field}`;

    return (
      <InputNumber
        inputId={getInputId(inputIdField, props.rowIndex)}
        value={fieldValue}
        onChange={e =>
          foreignTradesDispatch(
            containerItem
              ? {
                  type: ForeignTradeReducerActionKind.SET_ITEM_CONTAINER_TO_UPDATE,
                  payload: {
                    itemContainerToUpdate: {
                      idSatForeignTrade: foreignTradeItem.idSatForeignTrade,
                      idSatForeignTradeItemCtnr:
                        containerItem.idSatForeignTradeItemCtnr,
                      quantityCtnr: containerItem.quantityCtnr ?? 0,
                      grossWeight: containerItem.grossWeight,
                      netWeight: containerItem.netWeight,
                      cbm: containerItem.cbm,
                      [props.field]: Number(e.value),
                      fieldUpdated: props.field,
                    },
                  },
                }
              : {
                  type: ForeignTradeReducerActionKind.SET_ITEM_CONTAINER_TO_CREATE,
                  payload: {
                    itemContainerToCreate: {
                      idSatForeignTrade: foreignTradeItem.idSatForeignTrade,
                      idSatForeignTradeCtnr,
                      idSatForeignTradeItemReference:
                        props.rowData.idSatForeignTradeItemReference,
                      idSatForeignTradeItem:
                        foreignTradeItem.idSatForeignTradeItem,
                      quantityCtnr: 0,
                      grossWeight: 0,
                      netWeight: 0,
                      cbm: 0,
                      [props.field]: Number(e.value),
                      fieldUpdated: props.field,
                    },
                  },
                },
          )
        }
        locale="pt-BR"
        onKeyDown={e => handleGoToNextField(e, inputIdField, props.rowIndex)}
        min={0}
        allowEmpty={false}
        maxFractionDigits={fractionDigits}
        minFractionDigits={0}
      />
    );
  };

  const renderContainerColuns = shipmentContainers?.map(container => {
    return containerColumns.map(column => {
      if (
        !containerItemsPermissions[
          column.field as keyof ForeignTradeContainerItemsPermissions
        ].view
      ) {
        return undefined;
      }

      return (
        <Column
          key={`${container.idSatForeignTradeCtnr}-${column.field}`}
          field={column.field}
          style={{ width: '8rem' }}
          editor={e =>
            renderContainerEditor(
              e,
              container.idSatForeignTradeCtnr,
              column.fractionDigits,
            )
          }
        />
      );
    });
  });

  return (
    <Container>
      {!!satItemReference.satForeignTradeItemReferences?.length && (
        <DataTable
          value={satItemReference.satForeignTradeItemReferences}
          editMode="row"
          editingRows={getAllRowsAsTrue(
            gridDataKey,
            satItemReference.satForeignTradeItemReferences,
          )}
          onRowEditChange={() => ''}
          responsiveLayout="scroll"
          dataKey={gridDataKey}
          rowClassName={() => 'reference-sizes-table'}
        >
          {userCanDeleteContainerItem && !shipmentIsCanceled && (
            <Column field="multiple" style={{ width: '2em' }} />
          )}

          {userCanDeleteContainerItem && !shipmentIsCanceled && (
            <Column field="actions" style={{ width: '4rem' }} />
          )}

          {containerItemsPermissions.stCode.view && (
            <Column
              field="idSatReferenceSizeBarcode2.reference"
              style={{ width: '7rem' }}
            />
          )}

          {containerItemsPermissions.quantity.view && (
            <Column
              field="idSatReferenceSizeBarcode2.qtyPiSize"
              style={{ width: '7rem' }}
              editor={e =>
                numberToPtString(e.rowData.idSatReferenceSizeBarcode2.qtyPiSize)
              }
            />
          )}

          {containerItemsPermissions.quantityPiShipment.view && (
            <Column field="quantityPiShipment" style={{ width: '7rem' }} />
          )}

          {containerItemsPermissions.sizeRange.view && (
            <Column
              field="idSatReferenceSizeBarcode2.sizeRange"
              style={{ width: '7rem' }}
            />
          )}

          {containerItemsPermissions.quantityCi.view && (
            <Column
              field="quantityCi"
              style={{ width: '7rem' }}
              editor={e => parsePtBrNumberColumn(e.rowData, e, 0)}
            />
          )}

          {containerItemsPermissions.cotPrice.view && (
            <Column field="cotPrice" style={{ width: '7rem' }} />
          )}

          {containerItemsPermissions.sunPrice.view && (
            <Column field="sunPrice" style={{ width: '7rem' }} />
          )}

          {containerItemsPermissions.totalCotCi.view && (
            <Column
              field="totalCotCi"
              style={{ width: '8rem' }}
              editor={e =>
                parseCurrencyPtBrNumberColumn(e.rowData, e, 2, satCurrency)
              }
            />
          )}

          {containerItemsPermissions.totalSunCi.view && (
            <Column
              field="totalSunCi"
              style={{ width: '8rem' }}
              editor={e =>
                parseCurrencyPtBrNumberColumn(e.rowData, e, 2, satCurrency)
              }
            />
          )}

          {containerItemsPermissions.piecesContainer.view && (
            <Column
              field="idSatItem2.piecesContainer"
              style={{ width: '7rem' }}
            />
          )}

          {containerItemsPermissions.remainQuantityPiShipment.view && (
            <Column
              field="idSatReferenceSizeBarcode2.remainQuantityPiShipment"
              style={{ width: '7rem' }}
            />
          )}

          {renderContainerColuns}
        </DataTable>
      )}
    </Container>
  );
};

export default ReferenceSizes;
