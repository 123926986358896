import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Form } from '@unform/web';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { number, object, string } from 'yup';

import { AsyncPaginate } from 'react-select-async-paginate';
import { SelectInstance } from 'react-select';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Button from '../../../components/Button';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import Input from '../../../components/FormInput';
import FormTitle from '../../../components/FormTitle';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import { IUser } from '../../../interfaces/IUser';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import ToastLife from '../../../shared/enums/toastLife';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { userGroupRoles } from '../../../shared/roles/userGroup';
import getFieldPermission from '../../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../utils/getValidationErrors';
import userHasPermission from '../../../utils/userHasPermission';
import { IUserGroup, IUserGroupRouteParams } from './interfaces';
import { createUserGroupQuery, updateUserGroupQuery } from './queries';

import { Container, CrudForm } from './styles';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

const UserGroup: React.FC = () => {
  const history = useHistory();

  const { pathname } = useLocation();

  const { showError, showSuccess, showToast, formRef } = useRefHook();

  const selectRef = useRef<SelectInstance<any, boolean, any>>(null);

  const userGroupId = parseInt(
    useParams<IUserGroupRouteParams>().userGroupId,
    10,
  );

  const [userGroup, setUserGroup] = useState<IUserGroup>({} as IUserGroup);

  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

  const [users, setUsers] = useState<IUser[]>([]);

  const [pageLoading, setPageLoading] = useState(false);

  const { roles } = useAuth();

  const userPermissions = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(
      roles.rolesUser,
      userGroupRoles.idEntity,
    );
  }, [roles.rolesUser]);

  const fieldsPermissions = useMemo(() => {
    return {
      name: getFieldPermission(
        userGroupRoles.fields.name,
        userPermissions.userFields,
      ),
      type: getFieldPermission(
        userGroupRoles.fields.type,
        userPermissions.userFields,
      ),
      firstName: getFieldPermission(
        userGroupRoles.fields.firstName,
        userPermissions.userFields,
      ),
      lastName: getFieldPermission(
        userGroupRoles.fields.lastName,
        userPermissions.userFields,
      ),
      email: getFieldPermission(
        userGroupRoles.fields.email,
        userPermissions.userFields,
      ),
      active: getFieldPermission(
        userGroupRoles.fields.active,
        userPermissions.userFields,
      ),
    };
  }, [userPermissions.userFields]);

  const permissions = useMemo(() => {
    return {
      edit: userHasPermission(
        userGroupRoles.permissions.editUserGroup,
        userPermissions.userPermissions,
      ),
      addUser: userHasPermission(
        userGroupRoles.permissions.addUser,
        userPermissions.userPermissions,
      ),
      removeUser: userHasPermission(
        userGroupRoles.permissions.removeUser,
        userPermissions.userPermissions,
      ),
    };
  }, [userPermissions.userPermissions]);

  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'List of User Groups',
      path: '/userGroups',
    },
    {
      name: userGroupId ? 'Manage User Group' : 'Create User Group',
      path: pathname,
    },
  ];

  const listUserGroupQuery = gql`
    query ListUserGroupById($userGroupId: Int!) {
      listUserGroupById(userGroupId: $userGroupId) {
        idUserGroup
        ${fieldsPermissions.name.view ? 'name' : ''}
        ${
          fieldsPermissions.type.view
            ? `type
               type2 {
                 idDomain
                 description
               }`
            : ''
        }
        userGroupUsers {
          idUser2 {
            idUser
            ${fieldsPermissions.firstName.view ? 'firstName' : ''}
            ${fieldsPermissions.lastName.view ? 'lastName' : ''}
            ${fieldsPermissions.email.view ? 'email' : ''}
            ${fieldsPermissions.active.view ? 'active' : ''}
          }
        }
      }
    }
  `;

  const [
    loadUserGroupData,
    { loading: userGroupLoading, refetch: userGroupRefetchData },
  ] = useLazyQuery(listUserGroupQuery, {
    variables: {
      userGroupId,
    },
    onCompleted: response => {
      if (response.listUserGroupById) {
        setUserGroup(response.listUserGroupById);
        setUsers(
          response.listUserGroupById.userGroupUsers.map(
            (e: { idUser2: any }) => e.idUser2,
          ),
        );
      } else {
        showError({
          summary: 'User Group not found',
        });
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting User Group data',
        detail: errorData.message,
      });
    },
  });

  const columns = useMemo(() => {
    const columnList = [];
    if (fieldsPermissions.firstName.view) {
      columnList.push({ field: 'firstName', header: 'First Name' });
    }
    if (fieldsPermissions.lastName.view) {
      columnList.push({ field: 'lastName', header: 'Last Name' });
    }
    if (fieldsPermissions.email.view) {
      columnList.push({ field: 'email', header: 'E-mail' });
    }
    if (fieldsPermissions.active.view) {
      columnList.push({ field: 'active', header: 'Active' });
    }
    return columnList;
  }, [fieldsPermissions]);

  const parseActiveColumn = (rowData: any) => {
    return rowData.active && rowData.active === 'S' ? 'True' : 'False';
  };

  function handleColumn(field: string) {
    switch (field) {
      case 'active':
        return parseActiveColumn;
      default:
        return undefined;
    }
  }

  const dynamicColumns = columns.map(col => {
    return (
      <Column
        key={col.field}
        columnKey={col.field}
        field={col.field}
        header={col.header}
        body={handleColumn(col.field)}
      />
    );
  });

  const [createUserGroupMutation] = useMutation(createUserGroupQuery);
  const [updateUserGroupMutation] = useMutation(updateUserGroupQuery);

  const save = useCallback(
    async (data: any) => {
      setPageLoading(true);

      const variables: any = {
        variables: {
          data: {
            idUserGroup: userGroupId || undefined,
            name: data.name,
            type: data.type,
            userIds: users.map(user => user.idUser),
          },
        },
      };

      let response;
      try {
        if (!userGroupId) {
          response = await createUserGroupMutation(variables);
        } else {
          response = await updateUserGroupMutation(variables);
        }
        setPageLoading(false);
        showSuccess({
          summary: `UserGroup ${!userGroupId ? 'created' : 'updated'}`,
        });
        if (userGroupId) {
          userGroupRefetchData();
        } else {
          history.push(
            `/userGroups/${response.data.createUserGroup.idUserGroup ?? ''}`,
          );
        }
      } catch (error) {
        setPageLoading(false);
        showError({
          summary: `Error while ${
            !userGroupId ? 'creating' : 'updating'
          } UserGroup`,
          detail: error.message,
        });
      }
    },
    [
      createUserGroupMutation,
      history,
      showError,
      showSuccess,
      updateUserGroupMutation,
      userGroupId,
      userGroupRefetchData,
      users,
    ],
  );

  const validateForm = useCallback(
    async (data: any) => {
      formRef.current?.setErrors({});
      const schema = object().shape({
        name: string().nullable().required(requiredFieldErrorMessage),
        type: number().nullable().required(requiredFieldErrorMessage),
      });

      try {
        await schema.validate(data, { abortEarly: false });
        if (!users.length) {
          showToast({
            severity: 'warn',
            summary: 'User Group has no user',
            detail: 'Add a user to continue',
            life: ToastLife.WARN,
          });
          return false;
        }

        return true;
      } catch (error) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        const firstError = error.inner[0];
        const inputWithError = formRef.current?.getFieldRef(firstError.path);

        if (inputWithError.focus) {
          inputWithError.focus();
        } else if (inputWithError.inputRef?.current?.focus) {
          inputWithError.inputRef?.current?.focus();
        }

        showError({
          summary: 'Please fill all the required fields.',
        });
        return false;
      }
    },
    [formRef, showError, showToast, users.length],
  );

  async function handleSave() {
    const data = formRef.current?.getData();
    const isFormValid = await validateForm(data);
    if (isFormValid) {
      save(data);
    }
  }

  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/userGroups'),
    });
  }

  function deleteSelected() {
    const filteredUsers = users.filter(user => !selectedUsers.includes(user));
    setUsers(filteredUsers);
  }

  function handleAddUsers() {
    const usersToAdd = selectRef.current?.props.value;

    if (!usersToAdd?.length) {
      showError({ summary: 'No users selected' });
      return;
    }

    const userAlreadyAdded: IUser = usersToAdd.find((user: IUser) =>
      users.find(userAdded => userAdded.idUser === user.idUser),
    );

    if (userAlreadyAdded) {
      showError({
        summary: `User ${userAlreadyAdded.firstName} ${userAlreadyAdded.lastName} already added`,
      });
      return;
    }

    setUsers([...users, ...selectRef.current?.props.value]);

    showSuccess({
      summary: 'Users added',
    });

    selectRef.current?.clearValue();
  }

  useEffect(() => {
    if (userGroupId) {
      loadUserGroupData();
    }
  }, [userGroupId, loadUserGroupData]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          userGroupId
            ? userGroup.name
              ? `Manage User Group - ${userGroup.name}`
              : 'Manage User Group'
            : 'Create User Group'
        }
        minScrollStickyButtons={160}
      >
        {permissions.edit && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => formRef.current?.submitForm()}
          />
        )}
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      {((!userGroupLoading && userGroup?.idUserGroup) || !userGroupId) && (
        <Form ref={formRef} onSubmit={handleSave} initialData={userGroup}>
          <CrudForm>
            <FormTitle className="p-col-12" name="General Information" />
            <div className="p-d-flex p-flex-wrap">
              {fieldsPermissions.name.view && (
                <Input
                  className="p-col-3"
                  name="name"
                  label="Name"
                  required
                  disabled={
                    (!userGroupId && !fieldsPermissions.name.create) ||
                    (!!userGroupId && !fieldsPermissions.name.edit)
                  }
                />
              )}
              {fieldsPermissions.type.view && (
                <FormAsyncSelect
                  className="p-col-3"
                  name="type"
                  label="Type"
                  required
                  loadOptions={asyncSelectLoadDomains}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    id: DomainGroup.USERS_GROUP,
                  }}
                  noOptionsMessage={() => 'No type found'}
                  initialValue={userGroup.type2}
                  disabled={
                    (!userGroupId && !fieldsPermissions.type.create) ||
                    (!!userGroupId && !fieldsPermissions.type.edit)
                  }
                />
              )}
            </div>
            <FormTitle className="p-col-12" name="Users" />
            <div className="p-col-6 p-d-flex p-jc-between p-ai-center">
              {permissions.addUser && (
                <span className="p-d-flex p-ai-center">
                  <AsyncPaginate
                    selectRef={selectRef}
                    className="p-mr-2"
                    name="user"
                    loadOptions={asyncSelectLoadUsersOptions}
                    getOptionLabel={option => option.fullName}
                    getOptionValue={option => option.idUser}
                    additional={{
                      page: 1,
                    }}
                    noOptionsMessage={() => 'No users found'}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 2 }),
                      container: base => ({
                        ...base,
                        width: '278px',
                      }),
                    }}
                    menuPosition="fixed"
                    placeholder="Select users to add"
                    isMulti
                    debounceTimeout={1000}
                  />
                  <Button
                    type="button"
                    onClick={() => {
                      handleAddUsers();
                    }}
                  >
                    Add users
                  </Button>
                </span>
              )}
              {permissions.removeUser && (
                <Button
                  type="button"
                  severity="danger"
                  onClick={() => deleteSelected()}
                  disabled={!selectedUsers.length}
                >
                  Delete selected
                </Button>
              )}
            </div>
            <DataTable
              className="p-col-6"
              value={users}
              lazy
              emptyMessage="No user found."
              selection={selectedUsers}
              onSelectionChange={e => setSelectedUsers(e.value)}
            >
              <Column
                selectionMode="multiple"
                style={{ width: '3em' }}
                reorderable={false}
              />
              {dynamicColumns}
            </DataTable>
          </CrudForm>
        </Form>
      )}
      {(pageLoading || userGroupLoading) && <Loading />}
    </Container>
  );
};

export default UserGroup;
