/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-param-reassign: "error" */
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { gql, useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { FaTimes } from 'react-icons/fa';
import { Container, Row, ClientPortItem } from './styles';

import MainButton from '../../../../components/MainButton';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import FormInput from '../../../../components/FormInput';
import FormCheckbox from '../../../../components/FormCheckbox';
import { IClient, IClientPort } from '../interfaces';
import FormInputNumber from '../../../../components/FormInputNumber';
import { useRefHook } from '../../../../hooks/useRefHook';
import FormDropdown from '../../../../components/FormDropdown';
import { lazyParamsPaginationGrid } from '../../../../services/layoutsDefinitions';
import ILazyParams from '../../../../services/lazyParams';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import generateRandomString from '../../../../utils/generateRandomString';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import getFieldPermission from '../../../../utils/getFieldPermission';
import { clientRoles } from '../../../../shared/roles/client';
import ToastLife from '../../../../shared/enums/toastLife';

interface IGeneralInformationProps extends PageTabContainerProps {
  avatar?: File;
  client: IClient;
  setClient: Dispatch<SetStateAction<IClient>>;
  roleEntityFields: IRoleEntityField[];
}

const GeneralInformation: React.FC<IGeneralInformationProps> = ({
  selected,
  client,
  setClient,
  roleEntityFields,
}) => {
  const clientApollo = useApolloClient();

  // Referencia ao toast
  const { toastRef, formRef } = useRefHook();

  // ID do pais selecionado
  const [selectedCountryId, setSelectedCountryId] = useState<any>();

  // ID da regiao selecionada
  const [selectedRegionId, setSelectedRegionId] = useState<any>();

  // Parametros de paginacao/backend
  const [lazyParams] = useState<ILazyParams>(lazyParamsPaginationGrid);

  const loadPorts: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await clientApollo.query({
      query: gql`
        query listAllPortsQuery($listAllPortsInput: ListAllPortsInput!) {
          listAllPorts(listAllPortsInput: $listAllPortsInput) {
            data {
              idPort
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllPortsInput: {
          _page: 1,
          _limit: 100,
          _orderBy: 'name',
          _sortOrder: 'DESC',
          name: search,
        },
      },
    });
    // Opcoes filtradas
    const filteredOptions = res.data.listAllPorts.data;
    // Quantidade de itens
    const quantity = res.data.listAllPorts.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  const loadOptions: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await clientApollo.query({
      query: gql`
        query listAllUsersQuery($listAllUsersInput: ListAllUsersInput) {
          listAllUsers(listAllUsersInput: $listAllUsersInput) {
            data {
              idUser
              username
            }
            items
          }
        }
      `,
      variables: {
        listAllUsersInput: {
          _page: 1,
          _limit: 100,
          // _orderBy: lazyParams.sortField,
          // _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          // globalSearch: lazyParams.globalFilter,
        },
      },
    });

    const filteredOptions = res.data.listAllUsers.data;
    const quantity = res.data.listAllUsers.items;

    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  // Query de listagem de paises
  const listAllCountriesQuery = gql`
    query ListAllCountriesQuery {
      listAllCountries {
        idCountry
        name
      }
    }
  `;

  // Query para listar regioes
  const listRegionsQuery = gql`
    query ListRegionsByCountryIdQuery($id: Int!) {
      listRegionsByCountryId(id: $id) {
        idRegion
        name
      }
    }
  `;

  // Query para listar cidades
  const listCitiesQuery = gql`
    query ListCitiesByRegionIdQuery($id: Int!) {
      listCitiesByRegionId(id: $id) {
        idCity
        name
      }
    }
  `;

  // Query para listar domains
  const listDomainQuery = gql`
    query ListDomainsByGroupIdQuery(
      $id: Int!
      $pagination: Pagination!
      $description: String
    ) {
      listDomainsByGroupId(
        id: $id
        pagination: $pagination
        description: $description
      ) {
        data {
          idDomain
          description
        }
        items
      }
    }
  `;

  /**
   * Load status
   */
  const [
    loadStatus,
    {
      called: statusCalled,
      loading: statusLoading,
      data: statusData,
      error: statusError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 5,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting status',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Load businessType
   */
  const [
    loadBusinessType,
    {
      called: businessTypeCalled,
      loading: businessTypeLoading,
      data: businessTypeData,
      error: businessTypeError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 7,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting businessType',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Load numberOfStores
   */
  const [
    loadNumberOfStores,
    {
      called: numberOfStoresCalled,
      loading: numberOfStoresLoading,
      data: numberOfStoresData,
      error: numberOfStoresError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 8,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting numberOfStores',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Load operationType
   */
  const [
    loadOperationType,
    {
      called: operationTypeCalled,
      loading: operationTypeLoading,
      data: operationTypeData,
      error: operationTypeError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 9,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting operationType',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Load paymentLoading
   */
  const [
    loadPayment,
    {
      called: paymentCalled,
      loading: paymentLoading,
      data: paymentData,
      error: paymentError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 13,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting payment',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca Paises
   */
  const {
    loading: countriesLoading,
    data: countriesData,
    error: countriesError,
  } = useQuery(listAllCountriesQuery, {
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting countries',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca regioes
   */
  const [
    loadRegions,
    {
      called: regionsCalled,
      loading: regionsLoading,
      data: regionsData,
      error: regionsError,
    },
  ] = useLazyQuery(listRegionsQuery, {
    variables: {
      id: selectedCountryId,
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting regions',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca cidades
   */
  const [
    loadCities,
    {
      called: citiesCalled,
      loading: citiesLoading,
      data: citiesData,
      error: citiesError,
    },
  ] = useLazyQuery(listCitiesQuery, {
    variables: {
      id: selectedRegionId,
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting cities',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca correios
   */
  const [
    loadCouriers,
    {
      called: couriersCalled,
      loading: couriersLoading,
      data: couriersData,
      error: couriersError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 3,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting couriers',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const {
    idFieldAddress,
    idFieldCnpj,
    idFieldCompanyName,
    idFieldCourier,
    idFieldIdCity,
    idFieldIdRegion,
    idFieldMeetingAddress,
    idFieldNumberOfStores,
    idFieldOperationType,
    idFieldPayment,
    idFieldPaymentAdvance,
    idFieldPaymentBalance,
    idFieldPaymentDeadline,
    idFieldWebsite,
    idFieldZipCode,
  } = clientRoles.fields;

  function onSelectCountry(country: Record<string, unknown>) {
    setSelectedCountryId(country.idCountry);
    formRef.current?.setFieldValue('region', undefined);
    loadRegions();
    formRef.current?.setFieldValue('cityName', undefined);
  }

  function onSelectRegion(region: Record<string, unknown>) {
    formRef.current?.setFieldValue('cityName', undefined);
    if (region) {
      setSelectedRegionId(region.idRegion);
      loadCities();
    }
  }

  useEffect(() => {
    if (client.idClient && client.idCountry2) {
      setSelectedCountryId(client.idCountry2.idCountry);
      loadRegions();
      if (client.idRegion) {
        setSelectedRegionId(client.idRegion2.idRegion);
        loadCities();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadRegions, loadCities]);

  useEffect(() => {
    loadCouriers();
    loadStatus();
    loadBusinessType();
    loadNumberOfStores();
    loadOperationType();
    loadPayment();
  }, [
    loadCouriers,
    loadStatus,
    loadBusinessType,
    loadNumberOfStores,
    loadOperationType,
    loadPayment,
  ]);

  function addClientPort() {
    // Cria novo array com novo item
    let newClientPort: IClientPort[];
    if (client.clientPorts) {
      newClientPort = [
        ...client.clientPorts,
        {
          idClientPort: generateRandomString(4),
        } as IClientPort,
      ];
    } else {
      newClientPort = [
        {
          idClientPort: generateRandomString(4),
        } as IClientPort,
      ];
    }

    // Add objetc in array
    setClient({
      ...client,
      clientPorts: newClientPort,
    });
  }

  function removeClientPort(index: number) {
    // Copy array
    const newClientPort = [...client.clientPorts];
    // Remove item of array
    newClientPort.splice(index, 1);
    // Update array
    client.clientPorts = newClientPort;

    setClient({
      ...client,
    });
  }

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  function disableField(idField: number): boolean {
    return client.idClient
      ? !getFieldPermission(idField, roleEntityFields).edit
      : !getFieldPermission(idField, roleEntityFields).create;
  }

  function changeProperty(value?: number) {
    client.paymentAdvance = value;

    client.paymentBalance = 100 - (value ?? 0);

    setClient({
      ...client,
    });
  }

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <h1>General Information</h1>
        <Row>
          <FormInput
            className="generalInput"
            name="idClient"
            label="CLT Number"
            readOnly
          />
          <FormInput
            className="generalInput"
            name="name"
            label="Name"
            required
          />
          {showField(idFieldCompanyName) && (
            <FormInput
              className="generalInput"
              name="companyName"
              label="Formal Client Name/Notify"
              required
              readOnly={disableField(idFieldCompanyName)}
            />
          )}
          {showField(idFieldCnpj) && (
            /* <FormInputMask
              className="generalInput"
              name="cnpj"
              label="Tax ID/CNPJ"
              required
              mask="99.999.999/9999-99"
              autoClear={false}
              readOnly={disableField(idFieldCnpj)}
            /> */
            <FormInput
              className="generalInput"
              name="cnpj"
              label="Tax ID/CNPJ"
              required
              readOnly={disableField(idFieldCnpj)}
            />
          )}
        </Row>

        <Row>
          <FormDropdown
            className="generalInput"
            name="status"
            label="Status"
            placeholder={statusLoading ? 'Loading' : ''}
            options={
              statusCalled && !statusLoading && !statusError
                ? statusData.listDomainsByGroupId.data
                : undefined
            }
            optionLabel="description"
            initialValue={client.status2}
            filter
            filterBy="description"
            showClear
            required
          />
          <FormDropdown
            className="generalInput"
            name="businessType"
            label="Type of Business"
            placeholder={businessTypeLoading ? 'Loading' : ''}
            options={
              businessTypeCalled && !businessTypeLoading && !businessTypeError
                ? businessTypeData.listDomainsByGroupId.data
                : undefined
            }
            optionLabel="description"
            initialValue={client.businessType2}
            filter
            filterBy="description"
            showClear
            required
          />
          {showField(idFieldNumberOfStores) && (
            <FormDropdown
              className="generalInput"
              name="numberOfStores"
              label="Number of Stores"
              placeholder={numberOfStoresLoading ? 'Loading' : ''}
              options={
                numberOfStoresCalled &&
                !numberOfStoresLoading &&
                !numberOfStoresError
                  ? numberOfStoresData.listDomainsByGroupId.data
                  : undefined
              }
              optionLabel="description"
              initialValue={client.numberOfStores2}
              filter
              filterBy="description"
              showClear
              readOnly={disableField(idFieldNumberOfStores)}
            />
          )}
          {showField(idFieldOperationType) && (
            <FormDropdown
              className="generalInput"
              name="operationType"
              label="Type of Operation"
              placeholder={operationTypeLoading ? 'Loading' : ''}
              options={
                operationTypeCalled &&
                !operationTypeLoading &&
                !operationTypeError
                  ? operationTypeData.listDomainsByGroupId.data
                  : undefined
              }
              optionLabel="description"
              initialValue={client.operationType2}
              filter
              filterBy="description"
              showClear
              readOnly={disableField(idFieldOperationType)}
            />
          )}
        </Row>

        <Row>
          {showField(idFieldPaymentAdvance) && (
            <FormInputNumber
              className="generalInput"
              name="paymentAdvance"
              min={0}
              max={100}
              onValueChange={e => changeProperty(e.floatValue)}
              label="Payment Term Advance"
              readOnly={disableField(idFieldPaymentAdvance)}
              decimalScale={0}
            />
          )}
          {showField(idFieldPaymentBalance) && (
            <FormInputNumber
              className="generalInput"
              name="paymentBalance"
              label="Payment Term Balance"
              value={client.paymentBalance}
              min={0}
              max={100}
              readOnly
            />
          )}
          {showField(idFieldPaymentDeadline) && (
            <FormInputNumber
              className="generalInput"
              name="paymentDeadline"
              label="Payment Term Deadline"
              min={0}
              max={100}
              readOnly={disableField(idFieldPaymentDeadline)}
            />
          )}
          {showField(idFieldPayment) && (
            <FormDropdown
              className="generalInput"
              name="payment"
              label="Payment Term Condition"
              placeholder={paymentLoading ? 'Loading' : ''}
              options={
                paymentCalled && !paymentLoading && !paymentError
                  ? paymentData.listDomainsByGroupId.data
                  : undefined
              }
              optionLabel="description"
              initialValue={client.payment2}
              filter
              filterBy="description"
              showClear
              readOnly={disableField(idFieldPayment)}
            />
          )}
        </Row>

        <Row>
          {showField(idFieldWebsite) && (
            <FormInput
              className="generalInput"
              name="website"
              label="WebSite"
              readOnly={disableField(idFieldWebsite)}
            />
          )}

          {/* TODO: - Campo customerServices não encontrado nada tabela client */}
          <FormAsyncSelect
            className="generalInput"
            name="customerServices"
            label="Customer Services"
            loadOptions={loadOptions}
            isMulti
            closeMenuOnSelect={false}
            debounceTimeout={1000}
            getOptionLabel={(option: any) => option.username}
            getOptionValue={(option: any) => option.idUser}
            additional={{
              page: 1,
            }}
            initialValue={() =>
              client.clientCustomerServices
                ? client.clientCustomerServices.map(customerService => {
                    return {
                      username: customerService.idUser2.username,
                      idUser: customerService.idUser2.idUser,
                    };
                  })
                : undefined
            }
            noOptionsMessage={() => 'No customer service found'}
          />
          {showField(idFieldCourier) && (
            <FormDropdown
              className="generalInput"
              name="courier"
              label="Courier"
              placeholder={couriersLoading ? 'Loading' : ''}
              options={
                couriersCalled && !couriersLoading && !couriersError
                  ? couriersData.listDomainsByGroupId.data
                  : undefined
              }
              optionLabel="description"
              initialValue={client.courier2}
              filter
              filterBy="description"
              showClear
              readOnly={disableField(idFieldCourier)}
            />
          )}
          {showField(idFieldMeetingAddress) && (
            <FormInput
              className="generalInput"
              name="meetingAddress"
              label="Meeting Address"
              readOnly={disableField(idFieldMeetingAddress)}
            />
          )}
        </Row>

        <div className="form-div-margin">
          <h1>Address Info</h1>
          <Row>
            <FormDropdown
              className="generalInput"
              name="country"
              label="Country"
              readOnly={countriesLoading || countriesError !== undefined}
              options={
                countriesLoading || countriesError
                  ? undefined
                  : countriesData.listAllCountries
              }
              optionLabel="name"
              placeholder={countriesLoading ? 'Loading' : ''}
              initialValue={client.idCountry2}
              filter
              filterBy="name"
              onValueChange={onSelectCountry}
              required
            />
            {showField(idFieldIdRegion) && (
              <FormDropdown
                className="generalInput"
                name="region"
                label="State/Provincy"
                placeholder={regionsLoading ? 'Loading' : ''}
                options={
                  regionsCalled && !regionsLoading && !regionsError
                    ? regionsData.listRegionsByCountryId
                    : undefined
                }
                optionLabel="name"
                initialValue={client.idRegion2}
                filter
                filterBy="name"
                onValueChange={onSelectRegion}
                showClear
                readOnly={disableField(idFieldIdRegion)}
              />
            )}
            {showField(idFieldAddress) && (
              <FormInput
                className="generalInput"
                name="address"
                label="Address"
                readOnly={disableField(idFieldAddress)}
              />
            )}
          </Row>

          <Row>
            {showField(idFieldIdCity) && (
              <FormDropdown
                className="generalInput"
                name="cityName"
                label="City"
                placeholder={citiesLoading ? 'Loading' : ''}
                options={
                  citiesCalled && !citiesLoading && !citiesError
                    ? citiesData.listCitiesByRegionId
                    : undefined
                }
                optionLabel="name"
                initialValue={client.idCity2}
                filter
                filterBy="name"
                editable
                showClear
                readOnly={disableField(idFieldIdCity)}
              />
            )}
            {showField(idFieldZipCode) && (
              <FormInput
                className="generalInput"
                name="zipCode"
                label="Zip Code"
                readOnly={disableField(idFieldZipCode)}
              />
            )}
          </Row>
        </div>

        <div className="form-div-margin">
          <h1>Port of Delivery</h1>
          {client.clientPorts &&
            client.clientPorts.map((clientPort, index) => {
              return (
                <ClientPortItem key={clientPort.idClientPort}>
                  <div>
                    <FormCheckbox
                      name={`clientPorts[${index}].isPrimary`}
                      label="Default"
                      className="p-my-2 activeCheckbox"
                      initialValue={clientPort.isPrimary}
                    />
                    <FormAsyncSelect
                      className="generalInput"
                      name={`clientPorts[${index}].idPort`}
                      label="Port/Airport"
                      debounceTimeout={1000}
                      loadOptions={loadPorts}
                      initialValue={clientPort.idPort2}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.idPort}
                      additional={{
                        page: 1,
                      }}
                      noOptionsMessage={() => 'No port/airport found'}
                    />
                  </div>
                  <div className="button-div">
                    <button
                      type="button"
                      onClick={() => removeClientPort(index)}
                    >
                      <FaTimes size={18} />
                    </button>
                  </div>
                </ClientPortItem>
              );
            })}

          <MainButton
            label="Add Port of Delivery"
            onClick={() => addClientPort()}
          />
        </div>
      </Container>
    </PageTabContainer>
  );
};

export default GeneralInformation;
