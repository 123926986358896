import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { confirmDialog } from 'primereact/confirmdialog';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import pagination from '../../../../../../config/pagination';
import {
  IPackageBarcodesRef,
  ProductPackageBarcodeLazyParams,
  ProductPackageBarcodeState,
} from './interfaces';
import {
  deleteProductPackageBarcodeQuery,
  listPackageBarcodesQuery,
} from './queries';
import CommentPagination, {
  ICommentPaginationPageParams,
} from '../../../../../../components/CommentPagination';
import FormTitle from '../../../../../../components/FormTitle';
import MainButton from '../../../../../../components/MainButton';
import Card from '../../../../../../components/Card';
import PackageBarcodeContent from './PackageBarcodeContent';
import {
  IPackageBarcodeContentRef,
  PackageBarcodeFormData,
} from './PackageBarcodeContent/interfaces';
import AddBarcode from './AddBarcode';
import { IAddBarcodeRef } from './AddBarcode/interfaces';
import Loading from '../../../../../../components/Loading';
import { PackageBarcodeFieldsPermissions } from '../../interfaces';

interface IPackageBarcodesProps {
  idProductPackage: number;
  ref: Ref<IPackageBarcodesRef>;
  userCanChangeFields: boolean;
  fieldsPermissions: PackageBarcodeFieldsPermissions;
}

const PackageBarcodes: React.FC<IPackageBarcodesProps> = forwardRef(
  ({ idProductPackage, userCanChangeFields, fieldsPermissions }, ref) => {
    const [deletePackageBarcodeMutation] = useMutation(
      deleteProductPackageBarcodeQuery,
    );

    const barcodesContentRef = useRef<IPackageBarcodeContentRef[] | null[]>([]);
    const addBarcodeRef = useRef<IAddBarcodeRef>(null);

    const { showError, showSuccess } = useRefHook();

    useImperativeHandle(ref, () => ({
      getDataIfChanged: () => {
        const barcodesToUpdate: PackageBarcodeFormData[] = [];

        barcodesContentRef.current.forEach(contentRef => {
          const barcodeData = contentRef?.getDataIfChanged();

          if (barcodeData) {
            barcodesToUpdate.push(barcodeData);
          }
        });

        if (!barcodesToUpdate.length) {
          return undefined;
        }

        return barcodesToUpdate;
      },
    }));

    const [deleteLoading, setDeleteLoading] = useState(false);
    const [lazyParams, setLazyParams] =
      useState<ProductPackageBarcodeLazyParams>(pagination.initialLazyParams);
    const [productPackageBarcodes, setProductPackageBarcodes] =
      useState<ProductPackageBarcodeState>();

    const { loading, refetch } = useQuery(
      listPackageBarcodesQuery(fieldsPermissions),
      {
        variables: {
          idProductPackage,
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
          },
        },
        onCompleted: response => {
          if (response.listProductPackageBarcodesByIdProductPackage) {
            const { items, data } =
              response.listProductPackageBarcodesByIdProductPackage;

            setProductPackageBarcodes(prev => {
              return {
                items,
                data: prev?.data ? [...prev.data, ...data] : data,
              };
            });
          }
        },
        onError: err => {
          showError({
            summary: 'Error while getting Product Package barcodes',
            detail: err.message,
          });
        },
      },
    );

    function onPage(e: ICommentPaginationPageParams) {
      setLazyParams(prev => {
        return { ...prev, ...e };
      });
    }

    async function barcodesRefetch() {
      setProductPackageBarcodes(undefined);

      // Se usuario ja tiver carregado mais paginas,
      // eh necessario voltar para a primeira pagina para evitar registros
      // duplicados
      if (lazyParams.page) {
        setLazyParams(prev => {
          return {
            ...prev,
            page: 0,
          };
        });
      } else {
        await refetch();
      }
    }

    async function handleRemoveBarcode(idProductPackageBarcode?: number) {
      if (!idProductPackageBarcode) return;
      setDeleteLoading(true);

      try {
        await deletePackageBarcodeMutation({
          variables: {
            idProductPackageBarcode,
          },
        });

        showSuccess({ summary: 'Barcode removed' });

        await barcodesRefetch();
      } catch (error) {
        showError({
          summary: 'Error while removing barcode',
          detail: error.message,
        });
      } finally {
        setDeleteLoading(false);
      }
    }

    return (
      <div className="p-col-12">
        <span className="p-d-flex">
          <FormTitle name="Barcodes" />

          <MainButton
            className="p-ml-4"
            label="Add Barcode"
            type="button"
            onClick={() => addBarcodeRef.current?.toggleDialog()}
            disabled={!userCanChangeFields}
          />
        </span>

        {productPackageBarcodes && (
          <>
            {productPackageBarcodes.data.map((barcode, index) => {
              return (
                <Card
                  className="p-mb-2"
                  key={barcode.idProductPackageBarcode}
                  showRemoveButton={userCanChangeFields}
                  onRemove={() =>
                    confirmDialog({
                      style: { maxWidth: '350px' },
                      message: `Are you sure you want to delete this item?
                      If you have unsaved changes on any barcodes at this
                      package, removing a barcode from it will cause data loss`,
                      header: 'Delete Confirmation',
                      icon: 'pi pi-info-circle',
                      acceptClassName: 'p-button-danger',
                      accept: () =>
                        handleRemoveBarcode(barcode.idProductPackageBarcode),
                    })
                  }
                >
                  <PackageBarcodeContent
                    ref={el => {
                      barcodesContentRef.current[index] = el;
                    }}
                    packageBarcode={barcode}
                    userCanChangeFields={userCanChangeFields}
                    fieldsPermissions={fieldsPermissions}
                  />
                </Card>
              );
            })}

            <CommentPagination
              first={lazyParams.first}
              rows={lazyParams.rows}
              page={lazyParams.page}
              totalRecords={productPackageBarcodes.items}
              loading={loading}
              onPage={e => onPage(e)}
            />
          </>
        )}

        <AddBarcode
          ref={addBarcodeRef}
          idProductPackage={idProductPackage}
          barcodesRefetch={barcodesRefetch}
          userCanChangeFields={userCanChangeFields}
          fieldsPermissions={fieldsPermissions}
        />

        {loading && !lazyParams.page && (
          <Card>
            <div className="p-col p-grid p-ai-center">
              <Skeleton className="p-col p-mr-4" height="47px" />
              <span className="p-col-8 p-mr-4">
                <Skeleton height="60px" />
                <Divider />
                <Skeleton height="60px" />
                <Divider />
                <Skeleton height="60px" />
              </span>
            </div>
          </Card>
        )}

        {deleteLoading && <Loading />}
      </div>
    );
  },
);

export default PackageBarcodes;
