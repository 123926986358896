import { Button } from 'primereact/button';
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import FormInput from '../../../../../../components/FormInput';
import FormInputNumber from '../../../../../../components/FormInputNumber';
import FormInputTextArea from '../../../../../../components/FormInputTextArea';
import { findParentsOnTree } from '../../../../../../utils/findParentsOnTree';
import CheckboxItems from '../../CheckBoxItems';
import { ISat, ISatCtnr } from '../../../interfaces';
import { ICategoryTree } from '../../../../../../shared/interfaces/category';
import { generateSellerPIQuery } from '../../../queries';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { commercialFormCheckboxItems } from './constants';

interface ICommercialFormContentProps {
  sat: ISat;
  categories: ICategoryTree[];
  index: number;
  setEmailTitle(emailTitle: string): void;
  selected: boolean;
}

const CommercialFormContent: React.FC<ICommercialFormContentProps> = ({
  sat,
  categories,
  index,
  setEmailTitle,
  selected,
}) => {
  const { toastRef } = useRefHook();

  const concatSatCtnrs = (satCtnrs?: ISatCtnr[] | undefined): string => {
    let satCtnrsConcat = '';
    if (satCtnrs) {
      satCtnrs.forEach(element => {
        satCtnrsConcat += ` ${element.qtyCtnr} ${element.idTypeCtnr2.description}, `;
      });
    }
    return satCtnrsConcat;
  };

  // Gera Seller PI
  const [getSellerPILink, { loading: sellerPiLoading }] = useLazyQuery(
    generateSellerPIQuery,
    {
      variables: {
        idSat: sat.idSat,
      },
      onCompleted: response => {
        if (response.errors) {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while generating SUN PI',
            detail: response.errors[0].message,
            life: ToastLife.ERROR,
          });
        } else {
          window.open(response.generateSatSellerPI, '_blank');
        }
      },

      onError: error => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while generating SUN PI',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  const createSellersName = (): string => {
    const formatName = (user?: {
      firstName?: string;
      lastName?: string;
    }): string => {
      return user
        ? `${user.firstName?.toLocaleUpperCase()} ${user.lastName?.toLocaleUpperCase()}`
        : '';
    };

    const salesUserName = formatName(sat.idSalesUser2);
    const salesSecondUserName = formatName(sat.idSalesSecondUser2);

    return (
      salesUserName + (salesSecondUserName ? ` / ${salesSecondUserName}` : '')
    );
  };

  useEffect(() => {
    const titleEmail = () => {
      const title: string[] = [];

      let titlePrefix = '';

      if (sat.typeOfOrder2?.description) {
        titlePrefix += `[${sat.typeOfOrder2?.description}]`;
      }
      if (sat.idClient2?.name) {
        if (titlePrefix.length > 0) {
          titlePrefix += ` ${sat.idClient2?.name}`;
        } else {
          titlePrefix += sat.idClient2?.name;
        }
      }
      if (titlePrefix.length > 0) {
        title.push(titlePrefix);
      }
      if (sat.satNumber) {
        title.push(` ${sat.satNumber}`);
      }
      if (sat.mainProduct) {
        title.push(sat.mainProduct);
      }
      if (sat.idSupplierExporter2?.name) {
        title.push(sat.idSupplierExporter2?.name);
      }

      setEmailTitle(title ? title.join(' | ') : '');
    };

    if (selected) titleEmail();
  }, [
    sat.idClient2?.name,
    sat.idSupplierExporter2?.name,
    sat.mainProduct,
    sat.satNumber,
    sat.typeOfOrder2?.description,
    setEmailTitle,
    selected,
  ]);

  return (
    <>
      <div className="p-grid">
        <FormInputTextArea
          className="p-col-12"
          name={`data[${index}].emailDescription`}
          defaultValue=""
          label="Email Description"
        />
        <br />
        <FormInput
          className="p-col-4"
          name={`data[${index}].idSatOriginSatNumber`}
          defaultValue={sat.idSatOrigin2?.satNumber ?? ''}
          label="SAT Reposition"
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].mainProduct`}
          defaultValue={sat.mainProduct ?? ''}
          label="Product"
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].clientDepartment`}
          defaultValue={findParentsOnTree(categories, sat.idCategory) ?? ''}
          label="Category"
        />
        <FormInputNumber
          className="p-col-4"
          name={`data[${index}].amountTotal`}
          defaultValue={sat.amountTotal ?? ''}
          label="Total SUN PI"
          currency={sat.idCurrency2?.abbreviation}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].idClientIncotermName`}
          defaultValue={sat.idClientIncoterm2?.description ?? ''}
          label="Selling Incoterm"
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].idClientName`}
          defaultValue={sat.idClient2?.name ?? ''}
          label="Client"
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].BuyerName`}
          defaultValue={`${sat.idFirstBuyer2?.name ?? ''}${
            sat.idSecondBuyer2?.name ? ` / ${sat.idSecondBuyer2.name}` : ''
          }`}
          label="Buyer"
        />

        <FormInput
          className="p-col-4"
          name={`data[${index}].sellersName`}
          defaultValue={createSellersName()}
          label="Seller"
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].satCtnrs`}
          defaultValue={concatSatCtnrs(sat.satCtnrs)}
          label="Ctnr"
        />
        <FormInputNumber
          className="p-col-4"
          name={`data[${index}].productionTime`}
          defaultValue={sat.productionTime ?? ''}
          label="Production Time"
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].estimatedInspectionDate`}
          defaultValue={sat.estimatedInspectionDate ?? ''}
          label="Estimated Inspection Date"
          type="date"
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].estimatedShipmentDate`}
          defaultValue={sat.estimatedShipmentDate ?? ''}
          label="Estimated Shipment Date"
          type="date"
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].estimatedArrivalDate`}
          defaultValue={sat.estimatedArrivalDate ?? ''}
          label="Estimated Arrival Date"
          type="date"
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].estimatedArrivalClientDate`}
          defaultValue={sat.estimatedArrivalClientDate ?? ''}
          label="Estimated Arrival at Client Date"
          type="date"
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].idSupplierExporterName`}
          defaultValue={sat.idSupplierExporter2?.name ?? ''}
          label="Exporter"
        />
        <FormInput
          className="p-col-4"
          name={`data[${index}].idLoadingPortName`}
          defaultValue={sat.idLoadingPort2?.name ?? ''}
          label="Port of Loading"
        />
        <div className="p-grid" style={{ margin: '10px' }}>
          <div className="p-col-12">
            <h4>Contracted Modality</h4>

            <CheckboxItems
              objectArrayName={`data[${index}].contractedModality`}
              checkboxMenu={commercialFormCheckboxItems.contractedModalityItems}
            />
          </div>
          <div className="p-col-12">
            <h4>AW Responsible</h4>
            <CheckboxItems
              objectArrayName={`data[${index}].awResponsible`}
              checkboxMenu={commercialFormCheckboxItems.awResponsibleItems}
            />
          </div>
          <div className="p-col-12">
            <h4>NDI Service at Destination</h4>
            <CheckboxItems
              objectArrayName={`data[${index}].ndiDistributionService`}
              checkboxMenu={
                commercialFormCheckboxItems.serviceNdiDistribuctionItems
              }
            />
          </div>
          <div className="p-col-12">
            <h4>RFid</h4>
            <CheckboxItems
              objectArrayName={`data[${index}].rfid`}
              checkboxMenu={commercialFormCheckboxItems.rfIdItems}
            />
          </div>
        </div>
      </div>

      <h4>Attachments</h4>

      <Button
        label="SUN PI"
        icon={sellerPiLoading ? 'pi pi-spin pi-spinner' : 'pi pi-paperclip'}
        onClick={() => getSellerPILink()}
        className="p-button-text"
        disabled={sellerPiLoading}
        type="button"
      />
    </>
  );
};

export default CommercialFormContent;
