/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DataTable,
  DataTableMultiSortMetaType,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import saveAs from 'file-saver';
import { isValid } from 'date-fns';
import xlsx from 'xlsx';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { useRefHook } from '../../../hooks/useRefHook';
import { commercialRoles } from '../../../shared/roles/commercial';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../utils/userHasPermission';
import { useAuth } from '../../../hooks/useAuth';
import getFieldPermission from '../../../utils/getFieldPermission';
import MultiSelectGroup, {
  MultiSelectGroupData,
} from '../../../components/Grid/MultiSelectGroup';
import { gridConstants } from '../../../components/Grid/constants';
import {
  ISatListCommercialView,
  SatListCommercialPermissions,
} from './interfaces';
import { ICategory } from '../../../interfaces/ICategory';
import { gridColumnsData, groupedColumns } from './constants';
import ILazyParams from '../../../services/lazyParams';
import {
  getSumDataFromSatCommercialListQuery,
  listSatListCommercialViewQuery,
} from './queries';
import { getUTC } from '../../../utils/dateUtil';
import Tag from '../../../components/Tag';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import { Container } from './styles';
import PageHeader from '../../../components/PageHeader';
import Button from '../../../components/Button';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import pagination from '../../../config/pagination';
import Grid from '../../../components/Grid';
import Loading from '../../../components/Loading';
import { findParentsOnTree } from '../../../utils/findParentsOnTree';
import { SatStatus } from '../../../components/SatStatusTag';
import { renderNumber } from '../../../utils/formatLocale';

interface ISatListCommercialViewLazyParams
  extends ILazyParams,
    ISatListCommercialView {
  [key: string]:
    | number
    | string
    | DataTableSortOrderType
    | DataTableMultiSortMetaType
    | Date
    | boolean
    | undefined;
}

const ControlCommercial: React.FC = () => {
  // Key das opcoes de coluna que resetam preferencias da grid
  const gridResetOptionKey = '0-1';

  const gridColumnsName = '@SAT:satControlsCommercialGridColumns';

  const lazyParamsName = '@SAT:satControlsCommercialLazyParams';

  const advancedFiltersName = '@SAT:satControlsCommercialAdvancedFilters';

  const location = useLocation();

  const { showError } = useRefHook();

  const isMounted = useRef(false);

  const gridRef = useRef<DataTable>(null);

  const [pageLoading /* , setPageLoading */] = useState(false);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  const [lazyParams, setLazyParams] =
    useState<ISatListCommercialViewLazyParams>(
      localStorageLazyParams || initialLazyParams,
    );

  const saveLazyParams = useCallback(
    (newLazyParams: ISatListCommercialViewLazyParams) => {
      localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
      updateLocalStorageInDb(lazyParamsName, newLazyParams);
    },
    [],
  );

  const changeLazyParams = useCallback(
    (newLazyParams: ISatListCommercialViewLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const { roles } = useAuth();

  const { idEntity } = commercialRoles;

  const { idPermissionExportSatsCommercial } = commercialRoles.permissions;

  // Busca permissoes do usuario para a entity
  const { userFields, userPermissions } = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(roles.rolesUser, idEntity);
  }, [idEntity, roles.rolesUser]);

  const permissions = useMemo(() => {
    return {
      export: userHasPermission(
        idPermissionExportSatsCommercial,
        userPermissions,
      ),
    };
  }, [idPermissionExportSatsCommercial, userPermissions]);

  const fieldsPermissions: SatListCommercialPermissions = useMemo(() => {
    const fieldPermissions = userFields;
    const { fields } = commercialRoles;

    const commercialPermissions = {} as SatListCommercialPermissions;

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(fields)) {
      commercialPermissions[key] = getFieldPermission(value, fieldPermissions);
    }

    return commercialPermissions;
  }, [userFields]);

  const [categories, setCategories] = useState<ICategory[]>([]);

  const listCategoriesQuery = gql`
    query listAllCategoriesQuery {
      listAllCategories {
        data
        items
      }
    }
  `;

  useQuery(listCategoriesQuery, {
    onError: errorData => {
      showError({
        summary: 'Error while getting categories',
        detail: errorData.message,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        setCategories(JSON.parse(response.listAllCategories.data));
      }
    },
  });

  const columns = useMemo(() => {
    gridColumnsData.category.options = categories;

    return Object.entries(gridColumnsData)
      .filter(column => fieldsPermissions[column[1].field].view)
      .map(column => column[1]);
  }, [categories, fieldsPermissions]);

  const [selectedColumns, setSelectedColumns] = useState<MultiSelectGroupData>({
    columns: columns.filter(
      (column: any) =>
        groupedColumns &&
        groupedColumns.length > 0 &&
        groupedColumns[0]?.children?.[0]?.data?.includes(column.field),
    ),
    treeSelectedData: {
      0: {
        checked: false,
        partialChecked: true,
      },
      '0-0': {
        checked: true,
        partialChecked: false,
      },
    },
  });

  const satListCommercialCommonVariables = {
    allColumns: lazyParams.globalFilter,
    status: lazyParams.status,
    confirmOrder: lazyParams.confirmOrder,
    idSat: lazyParams.idSat,
    idSatForeignTrade: lazyParams.idSatForeignTrade,
    mainProduct: lazyParams.mainProduct,
    idTypeOfOrder: lazyParams.idTypeOfOrder,
    replacementFrom: lazyParams.replacementFrom,
    idSupplierExporter: lazyParams.idSupplierExporter,
    idImporter: lazyParams.idImporter,
    idClient: lazyParams.idClient,
    suppliers: lazyParams.suppliers,
    clientOrder: lazyParams.clientOrder,
    clientDepartment: lazyParams.clientDepartment,
    idFirstBuyer: lazyParams.idFirstBuyer,
    idSecondBuyer: lazyParams.idSecondBuyer,
    idImportStatus: lazyParams.idImportStatus,
    idStatusArtwork: lazyParams.idStatusArtwork,
    idRegisterStatus: lazyParams.idRegisterStatus,
    productionStatus: lazyParams.productionStatus,
    idShipmentStatus: lazyParams.idShipmentStatus,
    supplierPaymentStatus: lazyParams.supplierPaymentStatus,
    clientPaymentStatus: lazyParams.clientPaymentStatus,
    shipmentResume: lazyParams.shipmentResume,
    idLoadingPort: lazyParams.idLoadingPort,
    idPort: lazyParams.idPort,
    idCountryOrigin: lazyParams.idCountryOrigin,
    idClientIncoterm: lazyParams.idClientIncoterm,
    confirmOrderDate: lazyParams.confirmOrderDate,
    idCommercialReviewResponsible: lazyParams.idCommercialReviewResponsible,
    estimatedInspectionDate: lazyParams.estimatedInspectionDate,
    realInspectionDate: lazyParams.realInspectionDate,
    estimatedShipmentDate: lazyParams.estimatedShipmentDate,
    realEtd: lazyParams.realEtd,
    estimatedArrivalDate: lazyParams.estimatedArrivalDate,
    realEta: lazyParams.realEta,
    estimatedArrivalClientDate: lazyParams.estimatedArrivalClientDate,
    realArrivalAtClient: lazyParams.realArrivalAtClient,
    category: lazyParams.category
      ? findParentsOnTree(categories, lazyParams.category)
      : undefined,
    idSalesUser: lazyParams.idSalesUser,
    idSalesSecondUser: lazyParams.idSalesSecondUser,
    idPurchaseUser: lazyParams.idPurchaseUser,
    idComexExportUser: lazyParams.idComexExportUser,
    idComexImportUser: lazyParams.idComexImportUser,
    idDesignerUser: lazyParams.idDesignerUser,
    idFinancialUser: lazyParams.idFinancialUser,
  };

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  const {
    loading: satListCommercialViewLoading,
    data: satListCommercialViewData,
    error: satListCommercialViewError,
  } = useQuery(listSatListCommercialViewQuery(fieldsPermissions), {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        fieldsSearch: satListCommercialCommonVariables,
      },
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Commercial List',
        detail: errorData.message,
      });
    },
  });

  const { loading: listSumLoading, data: listSumData } = useQuery(
    getSumDataFromSatCommercialListQuery,
    {
      variables: {
        data: satListCommercialCommonVariables,
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Commercial List totals',
          detail: errorData.message,
        });
      },
    },
  );

  function saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });

    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`,
    );
  }

  async function exportToXlsx(exportData: any) {
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.columns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    const colunsNotRemoved = columns.filter(
      col => !columnsToRemove.includes(col),
    );

    exportData.data.forEach((e: any) => {
      columnsToRemove.forEach(column => {
        delete e[column.field];
      });
      delete e.idSat;
      delete e.idSatForeignTrade;
      delete e.idSupplierExporter;
      delete e.idClient;
      delete e.idImporter;
      delete e.status;

      // Formata campos de data e porcentagem
      colunsNotRemoved.forEach(column => {
        if (
          e[column.field] &&
          isValid(new Date(e[column.field])) &&
          typeof e[column.field] === 'string'
        ) {
          const date = getUTC(e[column.field]).toLocaleString().split(',')[0];
          e[column.field] = date;
        }
      });
    });

    const columnsOrder = gridRef.current?.state.columnOrder?.filter(
      (item: string) =>
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'status':
          columnsOrder[index] = 'statusSat';
          break;
        default:
          break;
      }
    });

    const worksheet = xlsx.utils.json_to_sheet(exportData.data, {
      header: columnsOrder,
    });

    worksheet['!autofilter'] = { ref: 'A1:H1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    saveAsExcelFile(excelBuffer, 'satListCommercial');
  }

  const [
    loadSatListCommercialView,
    {
      loading: satListCommercialViewExportLoading,
      data: satListCommercialViewExportData,
    },
  ] = useLazyQuery(listSatListCommercialViewQuery(fieldsPermissions), {
    onCompleted: () => {
      if (satListCommercialViewExportData.listSatListCommercialView) {
        exportToXlsx(satListCommercialViewExportData.listSatListCommercialView);
      } else {
        showError({
          summary: 'Error while exporting Commercial List',
          detail: 'Please try again later',
        });
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Commercial List to export',
        detail: errorData.message,
      });
    },
  });

  useEffect(() => {
    if (isMounted.current) {
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  function onSort(event: DataTableSortParams) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  const parseSatStatusColumn = (rowData: any) => {
    switch (rowData.status) {
      case SatStatus.ACTIVE:
        return <Tag className="tag" value="Active" severity="success" />;
      case SatStatus.CANCELED:
        return <Tag className="tag" value="Canceled" severity="danger" />;
      case SatStatus.STAND_BY:
        return <Tag className="tag" value="Stand by" severity="warn" />;
      case SatStatus.FINISHED:
        return <Tag className="tag" value="Finished" severity="secondary" />;
      default:
        return undefined;
    }
  };

  const parseDateColumm = (rowData: any, field: any) => {
    let date;
    if (rowData[field.field]) {
      date = getUTC(rowData[field.field]).toLocaleString();
    }
    return <p>{date?.split(',')[0]}</p>;
  };

  const parseSatColumn = (rowData: any) => {
    if (rowData.idSat) {
      return (
        <Link
          to={`/commercial/sats/${rowData.idSat}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.satNumber}
          <FiExternalLink size={15} />
        </Link>
      );
    }
    return rowData.satNumber;
  };

  const parseShipmentColumn = (rowData: any) => {
    if (rowData.idSat && rowData.idSatForeignTrade) {
      return (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=foreignTrade&foreignTradeId=${rowData.idSatForeignTrade}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.shipment}
          <FiExternalLink size={15} />
        </Link>
      );
    }
    return rowData.shipment;
  };

  const parseReplacementFromColumn = (rowData: any) => {
    if (rowData.replacementFrom) {
      const replacementFromSatNumber = rowData.replacementFrom
        .replaceAll('SAT', '')
        .split('/')
        .shift();
      return (
        <Link
          to={`/commercial/sats/${replacementFromSatNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.replacementFrom}
          <FiExternalLink size={15} />
        </Link>
      );
    }
    return rowData.replacementFrom;
  };

  const parseExporterColumn = (rowData: any) => {
    return (
      rowData.idSupplierExporter && (
        <Link
          to={`/suppliers/list/${rowData.idSupplierExporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.exporter}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseImporterColumn = (rowData: any) => {
    return (
      rowData.idImporter && (
        <Link
          to={`/clients/list/${rowData.idImporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.importerConsignee}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseClientNameColumn = (rowData: any) => {
    return (
      rowData.idClient && (
        <Link
          to={`/clients/list/${rowData.idClient}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.clientNotify}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseSunColumn = (rowData: any) => {
    const suppliers = rowData.suppliers?.replaceAll('SUN', '').split(', ');

    const sunNumbers = suppliers?.map((supplierId: number) => {
      return (
        rowData.suppliers && (
          <Link
            to={`/suppliers/list/${supplierId}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            {`SUN${supplierId}`}
          </Link>
        )
      );
    });
    return sunNumbers;
  };

  const parseSamplesColumn = (rowData: any) => {
    return rowData.samples ? 'Yes' : 'No';
  };

  const parseSatGroupColumn = (rowData: any) => {
    const satNumbers = rowData.satGroup?.split(', ');

    const satsStringWithLinks = satNumbers?.map((satNumber: any) => {
      const satId = satNumber.replaceAll('SAT', '').split('/')[0];

      return (
        rowData.satGroup && (
          <Link
            key={satId}
            to={`/commercial/sats/${satId}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
            style={{ justifyContent: 'space-between' }}
          >
            {satNumber}
          </Link>
        )
      );
    });

    return satsStringWithLinks;
  };

  function handleColumn(field: string) {
    switch (field) {
      case 'status':
        return parseSatStatusColumn;
      case 'estimatedInspectionDate':
      case 'realInspectionDate':
      case 'estimatedShipmentDate':
      case 'realEtd':
      case 'estimatedArrivalDate':
      case 'realEta':
      case 'estimatedArrivalClientDate':
      case 'realArrivalAtClient':
      case 'commercialReview':
      case 'artworkDone':
      case 'createdAt':
      case 'confirmOrderDate':
        return parseDateColumm;
      case 'satNumber':
        return parseSatColumn;
      case 'shipment':
        return parseShipmentColumn;
      case 'replacementFrom':
        return parseReplacementFromColumn;
      case 'exporter':
        return parseExporterColumn;
      case 'importerConsignee':
        return parseImporterColumn;
      case 'clientNotify':
        return parseClientNameColumn;
      case 'suppliers':
        return parseSunColumn;
      case 'samples':
        return parseSamplesColumn;
      case 'satGroup':
        return parseSatGroupColumn;
      default:
        return undefined;
    }
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'status':
        return { width: '95px' };
      case 'satNumber':
      case 'quantitySatPi':
        return { width: '140px' };
      case 'satGroup':
      case 'suppliers':
        return {
          width: '140px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
        };
      case 'replacementFrom':
      case 'clientDepartment':
      case 'countryOrigin':
        return { width: '180px' };
      case 'category':
      case 'importerConsignee':
      case 'clientNotify':
      case 'commercialReview':
      case 'confirmOrderDate':
        return { width: '185px' };
      case 'portLoading':
      case 'portDischarge':
      case 'quantityShipmentCi':
      case 'sampleLeadTime':
        return { width: '190px' };
      case 'paymentTermAdvance':
      case 'paymentTermBalance':
      case 'paymentTermCondition':
      case 'commercialResponsible':
      case 'firstSalesUser':
      case 'secondSalesUser':
        return { width: '210px' };
      case 'realInspectionDate':
      case 'realEtd':
      case 'realEta':
      case 'realArrivalAtClient':
      case 'estimatedShipmentDate':
      case 'estimatedInspectionDate':
      case 'estimatedArrivalDate':
      case 'estimatedArrivalClientDate':
        return { width: '170px' };
      case 'commercialReviewResponsible':
        return { width: '260px' };
      default:
        return { width: '160px' };
    }
  }

  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  function handleColumnFooter(field: string) {
    if (listSumLoading) return <Skeleton />;
    // Currency para adicionar nos campos de moeda - sao listados se todas as
    // SATs possuirem a mesma currency
    const currencyAbbreviation =
      satListCommercialViewData?.listSatListCommercialView?.data[0]
        ?.satCurrency;

    const value = listSumData?.getSumDataFromSatCommercialList[field];

    switch (field) {
      case 'satNumber':
        return renderNumber(
          listSumData?.getSumDataFromSatCommercialList.satCount,
          'int',
        );
      case 'sellerTotalSunPi':
      case 'sellerTotalSunCi':
        return renderNumber(value, 'currency', currencyAbbreviation);
      case 'quantitySatPi':
      case 'quantityShipmentCi':
        return renderNumber(value, 'float');
      default:
        return undefined;
    }
  }

  const dynamicColumns = selectedColumns.columns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable
          footer={handleColumnFooter(col.field)}
        />
      )
    );
  });

  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  const history = useHistory();

  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(`/commercial/sats/${e.data.idSat}`);
  }

  const onColumnToggle = (event: MultiSelectGroupData) => {
    const orderedSelectedColumns = columns.filter(col =>
      event.columns.some((sCol: { field: string }) => sCol.field === col.field),
    );

    const finalObject = {
      columns: orderedSelectedColumns,
      treeSelectedData: event.treeSelectedData,
    };

    // Salva colunas selecionadas no local storage
    localStorage.setItem(gridColumnsName, JSON.stringify(finalObject));
    setSelectedColumns(finalObject);

    updateLocalStorageInDb(gridColumnsName, finalObject);
  };

  useEffect(() => {
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    if (localStorageSelectedColumns) {
      const columnsObject = JSON.parse(localStorageSelectedColumns);
      setSelectedColumns(columnsObject);
    }
  }, [columns]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <Container>
      <PageHeader
        title="Commercial List"
        fixedStickyButtons={fixedStickyButtons}
      >
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
        {permissions.export && (
          <Button
            label="Export Grid"
            className="export-xlsx"
            loading={satListCommercialViewExportLoading}
            onClick={() =>
              loadSatListCommercialView({
                variables: {
                  data: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    fieldsSearch: satListCommercialCommonVariables,
                  },
                },
              })
            }
          />
        )}
        <MultiSelectGroup
          gridRef={gridRef}
          className="grid-multiselect-panel"
          allColumns={columns}
          groups={groupedColumns}
          showFields
          onSelectionChange={e => onColumnToggle(e)}
          initialData={selectedColumns.treeSelectedData}
          resetOptionKey={gridResetOptionKey}
        />
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a SAT"
        />
      </PageHeader>
      <Grid
        ref={gridRef}
        className="grid p-datatable-hoverable-rows"
        name="controlsCommercial"
        lazy
        totalRecords={
          !satListCommercialViewData ||
          !satListCommercialViewData.listSatListCommercialView
            ? 0
            : satListCommercialViewData.listSatListCommercialView.items
        }
        value={
          !satListCommercialViewData ||
          !satListCommercialViewData.listSatListCommercialView
            ? undefined
            : satListCommercialViewData.listSatListCommercialView.data
        }
        globalFilter={globalFilter}
        emptyMessage="No SATs found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={
          !satListCommercialViewData || satListCommercialViewError
            ? undefined
            : lazyParams.first
        }
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onRowClick={onRowClick}
      >
        {dynamicColumns}
      </Grid>
      {(pageLoading || satListCommercialViewLoading) && <Loading />}
    </Container>
  );
};
export default ControlCommercial;
