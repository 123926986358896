import { useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Dialog } from 'primereact/dialog';
import React, {
  useState,
  useReducer,
  Ref,
  useImperativeHandle,
  useRef,
} from 'react';
import { number, object, string, ValidationError } from 'yup';
import Button from '../../../../../../../components/Button';
import FormAsyncSelect from '../../../../../../../components/FormAsyncSelect';
import FormCheckbox from '../../../../../../../components/FormCheckbox';
import FormInput from '../../../../../../../components/FormInput';
import FormInputNumber from '../../../../../../../components/FormInputNumber';
import FormInputTextArea from '../../../../../../../components/FormInputTextArea';
import ReadOnlyInput from '../../../../../../../components/ReadOnlyInput';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import { DomainGroup } from '../../../../../../../shared/enums/domainGroup';
import { FinancialPurchaseOtherExpenses } from '../../../../../../../shared/enums/domains';
import ToastLife from '../../../../../../../shared/enums/toastLife';
import { asyncSelectLoadDomains } from '../../../../../../../shared/querys/domain';
import getValidationErrors from '../../../../../../../utils/getValidationErrors';
import {
  ManageOtherExpenseFormData,
  ManageOtherExpenseReducerActionKind,
} from './interfaces';
import { SatOtherExpense } from '../interfaces';
import {
  createSatForeignTradeSupplierOtherExpenseQuery,
  updateSatForeignTradeSupplierOtherExpenseQuery,
} from './queries';
import {
  manageOtherExpenseInitialState,
  manageOtherExpenseReducer,
} from './reducers';
import { Container } from './styles';
import { SatFinancialPurchaseOtherExpenseFieldsPermissions } from '../../../interfaces';
import { getCurrencySymbol } from '../../../../../../../utils/getCurrencySymbol';

export interface IManageOtherExpenseRef {
  toggleManageOtherExpenseDialog(otherExpense?: SatOtherExpense): void;
}

interface IManageOtherExpenseProps {
  ref: Ref<IManageOtherExpenseRef>;
  idSatForeignTradeSupplier: number;
  satCurrency?: string;
  otherExpensesRefetch(): Promise<void>;
  fieldsPermissions: SatFinancialPurchaseOtherExpenseFieldsPermissions;
}

const ManageOtherExpense: React.FC<IManageOtherExpenseProps> = React.forwardRef(
  (
    {
      idSatForeignTradeSupplier,
      satCurrency,
      otherExpensesRefetch,
      fieldsPermissions,
    },
    ref,
  ) => {
    const { toastRef } = useRefHook();

    const formRef = useRef<FormHandles>(null);

    const [loading, setLoading] = useState(false);

    const [createOtherExpenseMutation] = useMutation(
      createSatForeignTradeSupplierOtherExpenseQuery,
    );

    const [updateOtherExpenseMutation] = useMutation(
      updateSatForeignTradeSupplierOtherExpenseQuery,
    );

    const [manageOtherExpenseState, manageOtherExpenseDispatch] = useReducer(
      manageOtherExpenseReducer,
      manageOtherExpenseInitialState,
    );

    const editMode = !!manageOtherExpenseState.otherExpenseToEdit;

    useImperativeHandle(ref, () => ({
      toggleManageOtherExpenseDialog: otherExpense => {
        manageOtherExpenseDispatch({
          type: ManageOtherExpenseReducerActionKind.OPEN_DIALOG,
          payload: { otherExpense },
        });
      },
    }));

    async function handleSaveOtherExpense(
      formData: ManageOtherExpenseFormData,
    ) {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = object().shape({
          idType: string().required('Required Field'),
          dateOtherExpense: string().required('Required Field'),
          total: number().nullable().required('Required Field'),
        });

        await schema.validate(formData, { abortEarly: false });

        if (manageOtherExpenseState.otherExpenseToEdit) {
          await updateOtherExpenseMutation({
            variables: {
              data: {
                idSatOtherExpense:
                  manageOtherExpenseState.otherExpenseToEdit.idSatOtherExpense,
                total: formData.total,
                dateOtherExpense: formData.dateOtherExpense,
                discountFromSupplier: formData.discountFromSupplier,
                linkToMargin: formData.linkToMargin,
                payToSupplier: formData.payToSupplier,
                comment: formData.comment,
              },
            },
          });
        } else {
          await createOtherExpenseMutation({
            variables: {
              data: {
                ...formData,
                idSatForeignTradeSupplier,
              },
            },
          });
        }

        await otherExpensesRefetch();

        toastRef.current?.show({
          severity: 'success',
          summary: `Other Expense ${
            manageOtherExpenseState.otherExpenseToEdit ? 'updated' : 'created'
          }`,
          life: ToastLife.SUCCESS,
        });

        manageOtherExpenseDispatch({
          type: ManageOtherExpenseReducerActionKind.CLOSE_DIALOG,
        });
      } catch (error) {
        if (error instanceof ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }

        toastRef.current?.show({
          severity: 'error',
          summary: `Error while ${
            manageOtherExpenseState.otherExpenseToEdit ? 'updating' : 'creating'
          } Other Expense`,
          detail: error.message,
          life: ToastLife.ERROR,
        });
      } finally {
        setLoading(false);
      }
    }

    const dialogFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button
            label="Save"
            icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
            onClick={() => formRef.current?.submitForm()}
            disabled={loading}
          />
          <Button
            label="Cancel"
            severity="danger"
            icon="pi pi-times"
            onClick={() =>
              manageOtherExpenseDispatch({
                type: ManageOtherExpenseReducerActionKind.CLOSE_DIALOG,
              })
            }
            disabled={loading}
          />
        </div>
      );
    };

    function handleChangeType(e: { idDomain: number; description: string }) {
      if (
        e.idDomain === FinancialPurchaseOtherExpenses.FOB_CHARGES ||
        e.idDomain === FinancialPurchaseOtherExpenses.YIWU_COMISSION
      ) {
        formRef.current?.setFieldValue('linkToMargin', true);
        formRef.current?.setFieldValue('discountFromSupplier', false);
        formRef.current?.setFieldValue('payToSupplier', true);
        manageOtherExpenseDispatch({
          type: ManageOtherExpenseReducerActionKind.SET_CHECKBOXES_ARE_DISABLED,
          payload: {
            checkboxesAreDisabled: true,
          },
        });
      } else {
        manageOtherExpenseDispatch({
          type: ManageOtherExpenseReducerActionKind.SET_CHECKBOXES_ARE_DISABLED,
          payload: {
            checkboxesAreDisabled: false,
          },
        });
      }
    }

    return (
      <Dialog
        header={
          editMode
            ? 'Manage Purchase Other Expense'
            : 'Add Purchase Other Expense'
        }
        visible={manageOtherExpenseState.displayDialog}
        style={{ width: '500px' }}
        modal
        onHide={() =>
          manageOtherExpenseDispatch({
            type: ManageOtherExpenseReducerActionKind.CLOSE_DIALOG,
          })
        }
        footer={dialogFooter()}
        closable={false}
      >
        <Container>
          <Form
            ref={formRef}
            initialData={manageOtherExpenseState.otherExpenseToEdit}
            onSubmit={e => handleSaveOtherExpense(e)}
            className="p-grid"
          >
            {fieldsPermissions.idType.view && (
              <FormAsyncSelect
                className="p-col-6"
                name="idType"
                label="Type"
                loadOptions={asyncSelectLoadDomains}
                getOptionLabel={option => option.description}
                getOptionValue={option => option.idDomain}
                additional={{
                  id: DomainGroup.FINANCIAL_PURCHASE_OTHER_EXPENSES,
                }}
                noOptionsMessage={() => 'No types found'}
                initialValue={
                  manageOtherExpenseState.otherExpenseToEdit?.idType2
                }
                required
                onValueChange={e => handleChangeType(e)}
                readOnly={
                  editMode || (!editMode && !fieldsPermissions.idType.create)
                }
              />
            )}

            {fieldsPermissions.idCurrency.view && (
              <ReadOnlyInput
                className="p-col-6"
                label="Currency"
                value={satCurrency}
              />
            )}

            {fieldsPermissions.dateOtherExpense.view && (
              <FormInput
                className="p-col-6"
                name="dateOtherExpense"
                label="Date"
                required
                type="date"
                readOnly={
                  (editMode && !fieldsPermissions.dateOtherExpense.edit) ||
                  (!editMode && !fieldsPermissions.dateOtherExpense.create)
                }
              />
            )}

            {fieldsPermissions.total.view && (
              <FormInputNumber
                className="p-col-6"
                name="total"
                label="Total"
                required
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                readOnly={
                  (editMode && !fieldsPermissions.total.edit) ||
                  (!editMode && !fieldsPermissions.total.create)
                }
                prefix={getCurrencySymbol(satCurrency)}
              />
            )}

            {fieldsPermissions.comment.view && (
              <FormInputTextArea
                className="p-col-12"
                name="comment"
                label="Comment"
                rows={4}
                readOnly={
                  (editMode && !fieldsPermissions.comment.edit) ||
                  (!editMode && !fieldsPermissions.comment.create)
                }
              />
            )}

            <div className="manage-other-expense-checkboxes">
              {fieldsPermissions.linkToMargin.view && (
                <FormCheckbox
                  className="p-m-2"
                  name="linkToMargin"
                  label="Link to Margin"
                  returnType="number"
                  disabled={
                    manageOtherExpenseState.checkboxesAreDisabled ||
                    (editMode && !fieldsPermissions.linkToMargin.edit) ||
                    (!editMode && !fieldsPermissions.linkToMargin.create)
                  }
                />
              )}

              {fieldsPermissions.discountFromSupplier.view && (
                <FormCheckbox
                  className="p-m-2"
                  name="discountFromSupplier"
                  label="Discount From Supplier"
                  returnType="number"
                  onValueChange={() => {
                    formRef.current?.setFieldValue('payToSupplier', false);
                    return true;
                  }}
                  disabled={
                    manageOtherExpenseState.checkboxesAreDisabled ||
                    (editMode &&
                      !fieldsPermissions.discountFromSupplier.edit) ||
                    (!editMode &&
                      !fieldsPermissions.discountFromSupplier.create)
                  }
                />
              )}

              {fieldsPermissions.payToSupplier.view && (
                <FormCheckbox
                  className="p-m-2"
                  name="payToSupplier"
                  label="Pay To Supplier"
                  returnType="number"
                  onValueChange={() => {
                    formRef.current?.setFieldValue(
                      'discountFromSupplier',
                      false,
                    );
                    return true;
                  }}
                  disabled={
                    manageOtherExpenseState.checkboxesAreDisabled ||
                    (editMode && !fieldsPermissions.payToSupplier.edit) ||
                    (!editMode && !fieldsPermissions.payToSupplier.create)
                  }
                />
              )}
            </div>
          </Form>
        </Container>
      </Dialog>
    );
  },
);

export default ManageOtherExpense;
