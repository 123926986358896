import { gql, useLazyQuery } from '@apollo/client';
import { Form } from '@unform/web';
import { uniqBy } from 'lodash';
import { PickList, PickListChangeParams } from 'primereact/picklist';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, {
  ChangeEvent,
  forwardRef,
  Ref,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { InputText } from 'primereact/inputtext';
import { useRefHook } from '../../hooks/useRefHook';
import ToastLife from '../../shared/enums/toastLife';
import { asyncSelectLoadSuppliers } from '../../shared/querys/supplier';
import FormAsyncSelect from '../FormAsyncSelect';
import imagePlaceholder from '../../assets/imagePlaceholder.svg';

import { Container } from './styles';

export interface IPickListProductData {
  /**
   * ID de produto
   */
  idProduct: number;

  /**
   * URL da imagem do produto
   */
  imageUrl?: string;

  /**
   * Nome do Produto em Ingles
   */
  nameEn: string;

  /**
   * Codigo do produto
   */
  stCode: string;

  /**
   * ID do Supplier
   */
  idSupplier: number;
}

export interface IPickListProductRef {
  selectedItems: IPickListProductData[];
}

interface IPickListProductProps {
  ref: Ref<IPickListProductRef>;
  initialData?: IPickListProductData[];
}

const PickListProduct: React.FC<IPickListProductProps> = forwardRef(
  ({ initialData }, ref) => {
    // Query para listar products
    const listProductsQuery = gql`
      query listAllProductsBySupplierQuery(
        $listAllProductsInput: ListAllProductsInput!
      ) {
        listAllProductsBySupplier(listAllProductsInput: $listAllProductsInput) {
          data {
            idProduct
            imageUrl
            stCode
            nameEn
            idSupplier
          }
        }
      }
    `;

    // Referencia ao toast
    const { toastRef } = useRefHook();

    // Estado de supplier selecionado
    const [selectedSupplier, setSelectedSupplier] = useState();

    const [source, setSource] = useState([]);
    const [target, setTarget] = useState<any[]>((initialData as any[]) || []);

    useImperativeHandle(ref, () => ({
      selectedItems: target,
    }));

    /**
     * Busca dados de Produto
     */
    const [loadProductsData, { loading: productsLoading, data: productsData }] =
      useLazyQuery(listProductsQuery, {
        variables: {
          listAllProductsInput: {
            pagination: {
              _page: 0,
              _limit: 0,
            },
            idSupplier: selectedSupplier,
          },
        },
        onCompleted: response => {
          // Valida se existem registros de produtos
          if (response.listAllProductsBySupplier?.data) {
            // Adiciona na source apenas produtos que nao estejam na lista de selecao
            setSource(
              response.listAllProductsBySupplier.data.filter(
                (product: IPickListProductData) =>
                  !target.find(
                    targetProduct =>
                      targetProduct.idProduct === product.idProduct,
                  ),
              ),
            );
          } else {
            toastRef.current?.show({
              severity: 'error',
              summary: 'No products found',
              life: ToastLife.ERROR,
            });
          }
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting products data',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      });

    const onChange = (event: PickListChangeParams) => {
      setSource(event.source);
      setTarget(uniqBy(event.target, 'idProduct'));
    };

    const itemTemplate = (item: IPickListProductData) => {
      return (
        <div className="product-item">
          <div className="image-container">
            <img
              src={item.imageUrl ?? ''}
              onError={e => {
                e.currentTarget.src = imagePlaceholder;
              }}
              alt={item.nameEn}
              style={{ width: '90px' }}
            />
          </div>
          <div className="product-list-detail">
            <h5 className="p-mb-2">{item.nameEn}</h5>
            <span className="product-category">{item.stCode}</span>
          </div>
        </div>
      );
    };

    /**
     * Filtra produtos da origem
     * @param e Evento de onChange
     */
    const handleFilterProductsSource = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const filteredProducts =
          productsData.listAllProductsBySupplier.data.filter(
            (product: IPickListProductData) =>
              (product.stCode
                .toLowerCase()
                .includes(e.target.value.toLocaleLowerCase()) ||
                product.nameEn
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())) &&
              !target.find(
                targetProduct => targetProduct.idProduct === product.idProduct,
              ),
          );
        setSource(filteredProducts);
      },
      [productsData?.listAllProductsBySupplier.data, target],
    );

    /**
     * Retorna componente de header da coluna de source
     * @returns Componente de header da source
     */
    const sourceHeader = () => {
      return (
        <>
          <p>Available</p>
          <InputText
            id="in"
            placeholder="Search an item"
            onChange={e => handleFilterProductsSource(e)}
          />
        </>
      );
    };

    return (
      <Container>
        <Form
          onSubmit={() => {
            ('');
          }}
        >
          <FormAsyncSelect
            name="idSupplier"
            className="generalInput"
            label="Supplier"
            loadOptions={asyncSelectLoadSuppliers}
            debounceTimeout={1000}
            getOptionLabel={(option: any) => option.sunNumber}
            getOptionValue={(option: any) => option.idSupplier}
            additional={{
              page: 1,
            }}
            noOptionsMessage={() => 'No suppliers found'}
            required
            onValueChange={e => {
              setSelectedSupplier(e.idSupplier);
              loadProductsData();
            }}
          />
        </Form>

        {productsLoading && <ProgressSpinner strokeWidth="5" />}
        {!selectedSupplier && (
          <p className="warn-text">First, select a supplier</p>
        )}

        {!productsLoading && selectedSupplier && (
          <PickList
            source={source}
            target={target}
            itemTemplate={itemTemplate}
            sourceHeader={sourceHeader}
            targetHeader="Selected"
            sourceStyle={{ height: '342px' }}
            targetStyle={{ height: '342px' }}
            onChange={onChange}
            dataKey="idProduct"
          />
        )}
      </Container>
    );
  },
);

export default PickListProduct;
