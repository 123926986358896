import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { lighten, shade } from 'polished';

// Interface das propriedades do componente
interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  buttonDirection: string;
}

export const Container = styled.button<CustomButtonProps>`
  background: #fff;
  height: 36px;
  padding: 8px 15px;
  border: none;
  white-space: nowrap;

  display: flex;
  align-items: center;

  color: ${props => (props.selected ? 'var(--blue)' : 'var(--grey-scale)')};
  background-color: ${props =>
    props.selected ? lighten(0.43, '#4c85c8') : 'none'};
  ${props =>
    props.buttonDirection === 'column'
      ? 'border-right: 2px solid;'
      : `
        border-bottom: 2px solid;
        p {
          margin: 32px;
        }
        `};

  ${props => (props.selected ? 'var(--blue)' : '#d7d6d6')};

  font-weight: ${props => (props.selected ? '500' : 'normal')};

  transition: color 0.2s, background-color 0.2s, border-right 0.2s;

  &:hover {
    color: ${shade(0.2, '#29ABE2')};
  }

  p {
    margin-left: 32px;
  }

  svg {
    margin-left: 5px;
  }

  svg + p {
    margin-left: 7px;
  }
`;
