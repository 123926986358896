/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { FaTimes } from 'react-icons/fa';
import { gql, useLazyQuery } from '@apollo/client';
import { InputTextarea } from 'primereact/inputtextarea';
import {
  AnswerOption,
  AnswerType,
  Checklist,
  ChecklistItem,
  Comment,
  Container,
  Question,
} from './styles';

import FormInput from '../../../../components/FormInput';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import MainButton from '../../../../components/MainButton';
import {
  IChecklist,
  IChecklistAnswer,
  IChecklistItem,
  IProduct,
  IProductChecklist,
  IProductCheckListName,
  ISelectCategory,
} from '../interfaces';
import ToastLife from '../../../../shared/enums/toastLife';
import { useRefHook } from '../../../../hooks/useRefHook';
import { TypeQuestion } from '../../../../shared/enums/checklistTypeQuestion';
import FormCheckbox from '../../../../components/FormCheckbox';
import RadioInput from '../../../../components/RadioInput/radioindex';
import Loading from '../../../../components/Loading';
import { TypeLayout } from '../../../../shared/enums/checklistTypeLayout';
import generateRandomString from '../../../../utils/generateRandomString';
import { IBulkCheckListResponse } from '../../Products/interfaces';
import { useDefaultProductPackage } from '../hooks/useDefaultProductPackage';

interface IChecklistsProps extends PageTabContainerProps {
  product: IProduct;
  changePropertyValue(property: string, value: any): void;
  checklistItems: IChecklistItem[];
  setChecklistItems: Dispatch<SetStateAction<IChecklistItem[]>>;
  selectedCategory?: ISelectCategory;
  bulkCheckListResponse?: IBulkCheckListResponse;
  userCanChangeFields: boolean;
}

const Checklists: React.FC<IChecklistsProps> = ({
  selected,
  product,
  selectedCategory,
  bulkCheckListResponse,
  changePropertyValue,
}) => {
  const { getDefaultProductPackage } = useDefaultProductPackage();
  const { toastRef, formRef } = useRefHook();

  function showToast(
    severity: string,
    summary: string,
    life: number,
    detail?: string,
  ) {
    toastRef.current?.show({ summary, detail, severity, life });
  }

  function showError(summary: string, detail?: string) {
    showToast('error', summary, ToastLife.ERROR, detail);
  }

  const [idCheckList, setIdCheckList] = useState<number>();

  const [checkList, setCheckList] = useState<IChecklist>();
  const [packagePcQuantity, setPackagePcQuantity] = useState(0);

  const listCheckListQuery = gql`
    query listCheckListQuery($idCheckList: Int!) {
      listChecklistWithItemsById(id: $idCheckList) {
        idCheckList
        name
        checkListItems {
          idCheckListItem
          name
          type
          idTypeQuestion
          idTypeQuestion2 {
            idDomain
            description
          }
          idTypeLayout
          idTypeLayout2 {
            idDomain
            description
          }
          indComment
          order
          checkListItemAnswers {
            idCheckListItemAnswer
            idCheckListAnswer
            idCheckListAnswer2 {
              idCheckListAnswer
              answer
              description
              imageUrl
            }
            indCommentRequired
            order
          }
        }
      }
    }
  `;

  const listProductCheckListNamesQuery = gql`
    query listProductCheckListNamesByIdQuery($idProduct: Int!) {
      listProductCheckListNamesById(idProduct: $idProduct) {
        idProductCheckListName
        idCheckList
        idProduct
        nameEn
        namePt
        productCheckLists {
          idProductCheckLlist
          idProduct
          idCheckListItem
          idProductCheckListName
          value
          comment
          createdBy
          createdAt
        }
      }
    }
  `;

  const [loadCheckList, { loading: checkListLoading }] = useLazyQuery(
    listCheckListQuery,
    {
      variables: {
        idCheckList,
      },
      onCompleted: response => {
        if (response.listChecklistWithItemsById) {
          const checkListLoaded: IChecklist =
            response.listChecklistWithItemsById;
          checkListLoaded.checkListItems.sort((a, b) => a.order - b.order);
          checkListLoaded.checkListItems.map(e => {
            return e.checkListItemAnswers.sort(
              (a, b) => (a.order ?? 0) - (b.order ?? 0),
            );
          });
          setCheckList(checkListLoaded);
        } else {
          showError('CheckList not found');
        }
      },
      onError: errorData => {
        showError('CheckList not found', errorData.message);
      },
    },
  );

  const [loadProductCheckListNames, { loading: productCheckListNamesLoading }] =
    useLazyQuery(listProductCheckListNamesQuery, {
      variables: {
        idProduct: product.idProduct,
      },
      onCompleted: response => {
        if (response.listProductCheckListNamesById) {
          changePropertyValue(
            'productCheckListNames',
            response.listProductCheckListNamesById,
          );
        } else {
          showError('Product CheckList not found');
        }
      },
      onError: errorData => {
        showError('Product CheckList not found', errorData.message);
      },
    });

  function generateNewProductCheckLists(): IProductChecklist[] {
    const productCheckLists: IProductChecklist[] = [];
    checkList?.checkListItems.forEach(item => {
      if (item.idTypeQuestion !== TypeQuestion.CHECK) {
        productCheckLists.push({
          idProduct: product.idProduct,
          idCheckListItem: item.idCheckListItem,
          idCheckListItem2: item,
          comment: '',
        } as IProductChecklist);
      } else {
        item.checkListItemAnswers.forEach(answer => {
          productCheckLists.push({
            idProduct: product.idProduct,
            idCheckListItem: item.idCheckListItem,
            idCheckListItem2: item,
            value: answer.idCheckListAnswer,
            comment: '',
          } as IProductChecklist);
        });
      }
    });
    return productCheckLists;
  }

  function addChecklist() {
    let productCheckListNames: IProductCheckListName[];
    if (product.productCheckListNames) {
      productCheckListNames = [
        ...product.productCheckListNames,
        {
          idProductCheckListName: generateRandomString(4),
          idProduct: product?.idProduct,
          idCheckList: checkList?.idCheckList,
          productCheckLists: generateNewProductCheckLists(),
        } as IProductCheckListName,
      ];
    } else {
      productCheckListNames = [
        {
          idProductCheckListName: generateRandomString(4),
          idProduct: product?.idProduct,
          idCheckList: checkList?.idCheckList,
          productCheckLists: generateNewProductCheckLists(),
        } as IProductCheckListName,
      ];
    }
    changePropertyValue('productCheckListNames', productCheckListNames);
  }

  function removeChecklist(index: number) {
    const productCheckListNames = [...product.productCheckListNames];
    productCheckListNames.splice(index, 1);
    changePropertyValue('productCheckListNames', productCheckListNames);
  }

  function isLastChecklist(): boolean {
    return product.productCheckListNames.length === 1;
  }

  function isChecklistLengthEqualOrGraterThanQtySellingPackage(): boolean {
    return product.productCheckListNames?.length >= packagePcQuantity;
  }

  function clearProductCheckListNames(): void {
    changePropertyValue(`productCheckListNames`, []);
  }

  useEffect(() => {
    if (product.productCheckListNames?.length === 0) {
      addChecklist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkList]);

  useEffect(() => {
    if (
      selectedCategory?.idCheckList &&
      (selectedCategory?.idCheckList !== checkList?.idCheckList ||
        !checkList?.idCheckList)
    ) {
      clearProductCheckListNames();
      setIdCheckList(selectedCategory?.idCheckList);
      loadCheckList();
    } else if (!selectedCategory?.idCheckList) {
      clearProductCheckListNames();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, checkList?.idCheckList, loadCheckList]);

  useEffect(() => {
    if (selectedCategory?.idCheckList) {
      setIdCheckList(selectedCategory?.idCheckList);
      if (product.idProduct) {
        loadProductCheckListNames();
      }
      loadCheckList();
    }
  }, [
    loadCheckList,
    loadProductCheckListNames,
    product.idCategory2?.idCheckList,
    product.idProduct,
    selectedCategory?.idCheckList,
    bulkCheckListResponse,
  ]);

  function getCssClassForLayout(idTypeLayout: number): string {
    return idTypeLayout
      ? TypeLayout[idTypeLayout].replace('_', '-').toLowerCase()
      : '';
  }

  function changeValueRadio(
    value: any,
    productCheckListNameIndex: number,
    productCheckListIndex: number,
  ): void {
    const productCheckListNames = [...product.productCheckListNames];
    const productCheckLists = [
      ...productCheckListNames[productCheckListNameIndex].productCheckLists,
    ];
    const productCheckList = productCheckLists[productCheckListIndex];
    productCheckList.value = parseInt(value, 10);
    changePropertyValue(`productCheckListNames`, productCheckListNames);
  }

  function changeValueCheckbox(
    productCheckListNameIndex: number,
    checkListItem: IChecklistItem,
    answer: IChecklistAnswer,
  ): boolean {
    const productCheckListNames = [...product.productCheckListNames];

    const { productCheckLists } =
      productCheckListNames[productCheckListNameIndex];

    const productCheckList = productCheckLists.find(
      pcl =>
        pcl.idCheckListItem === checkListItem.idCheckListItem &&
        pcl.value === answer.idCheckListAnswer,
    );

    const isItemChecked = productCheckList?.comment === 'true';

    if (productCheckList) {
      productCheckList.comment = String(!isItemChecked);
    }

    changePropertyValue(`productCheckListNames`, productCheckListNames);

    return true;
  }

  function changeValueText(
    value: any,
    productCheckListNameIndex: number,
    productCheckListIndex: number,
  ): void {
    const productCheckListNames = [...product.productCheckListNames];
    const productCheckLists = [
      ...productCheckListNames[productCheckListNameIndex].productCheckLists,
    ];
    const productCheckList = productCheckLists[productCheckListIndex];
    productCheckList.comment = value;
    changePropertyValue(`productCheckListNames`, productCheckListNames);
  }

  function changeNameEn(value: string, productCheckListNameIndex: number) {
    const productCheckListNames = [...product.productCheckListNames];

    if (
      productCheckListNames.every(
        (pcln, index) =>
          pcln.nameEn !== value || productCheckListNameIndex === index,
      )
    ) {
      const productCheckListName =
        productCheckListNames[productCheckListNameIndex];
      productCheckListName.nameEn = value;
      changePropertyValue(`productCheckListNames`, productCheckListNames);
    } else {
      showError('An Important Information with this name already exists.');
      formRef.current?.setFieldValue(
        `productCheckListNames[${productCheckListNameIndex}].nameEn`,
        '',
      );
    }
  }

  function changeNamePt(value: string, productCheckListNameIndex: number) {
    const productCheckListNames = [...product.productCheckListNames];

    if (
      productCheckListNames.every(
        (pcln, index) =>
          pcln.namePt !== value || productCheckListNameIndex === index,
      )
    ) {
      const productCheckListName =
        productCheckListNames[productCheckListNameIndex];
      productCheckListName.namePt = value;
      changePropertyValue(`productCheckListNames`, productCheckListNames);
    } else {
      showError('An Important Information with this name already exists.');
      formRef.current?.setFieldValue(
        `productCheckListNames[${productCheckListNameIndex}].namePt`,
        '',
      );
    }
  }

  function removeAllCheckboxes(
    item: IChecklistItem,
    productCheckListNameIndex: number,
  ) {
    const productCheckListNames = [...product.productCheckListNames];
    const { productCheckLists } =
      productCheckListNames[productCheckListNameIndex];

    const itemsToClear = productCheckLists.filter(
      p => p.idCheckListItem === item.idCheckListItem,
    );

    itemsToClear.forEach(itemToClear => {
      // eslint-disable-next-line no-param-reassign
      itemToClear.comment = 'false';
      const itemIndex = productCheckLists.indexOf(itemToClear);
      const fieldPrefix = `productCheckLists[${itemIndex}]`;
      formRef.current?.setFieldValue(`${fieldPrefix}.value`, false);
    });
    changePropertyValue(`productCheckListNames`, productCheckListNames);
  }

  function removeAnswer(
    item: IChecklistItem,
    productCheckListNameIndex: number,
    productCheckListIndex: number,
  ): void {
    switch (item.idTypeQuestion) {
      case TypeQuestion.CHECK:
        removeAllCheckboxes(item, productCheckListNameIndex);
        break;
      case TypeQuestion.RADIO:
        changeValueRadio(
          undefined,
          productCheckListNameIndex,
          productCheckListIndex,
        );
        break;
      default:
        changeValueText('', productCheckListNameIndex, productCheckListIndex);
        break;
    }
    const fieldPrefix = `productCheckListNames[${productCheckListNameIndex}].productCheckLists[${productCheckListIndex}]`;
    formRef.current?.clearField(`${fieldPrefix}.value`);
    formRef.current?.setFieldValue(`${fieldPrefix}.comment`, '');
  }

  useEffect(() => {
    async function getPackagePcQuantity() {
      const defaultPackage = await getDefaultProductPackage(product.idProduct);

      if (defaultPackage?.packagePcQuantity) {
        setPackagePcQuantity(defaultPackage?.packagePcQuantity);
      }
    }

    if (selected && product.idProduct) getPackagePcQuantity();
  }, [getDefaultProductPackage, product.idProduct, selected]);

  return (
    <PageTabContainer selected={selected}>
      {!productCheckListNamesLoading && !checkListLoading && (
        <Container>
          {product.productCheckListNames?.map(
            (productCheckListName, productCheckListNameIndex) => {
              return (
                <Checklist key={productCheckListName?.idProductCheckListName}>
                  <div className="p-d-flex p-jc-end button-div">
                    <button
                      type="button"
                      onClick={() => removeChecklist(productCheckListNameIndex)}
                      disabled={isLastChecklist()}
                    >
                      <FaTimes size={18} />
                    </button>
                  </div>
                  <div className="p-d-flex p-flex-wrap">
                    <FormInput
                      className="p-md-6 p-sm-12"
                      name={`productCheckListNames[${productCheckListNameIndex}].nameEn`}
                      label="Item Name (EN)"
                      required={product.productCheckListNames.length > 1}
                      onBlur={e =>
                        changeNameEn(e.target.value, productCheckListNameIndex)
                      }
                    />
                    <FormInput
                      className="p-md-6 p-sm-12"
                      name={`productCheckListNames[${productCheckListNameIndex}].namePt`}
                      label="Item Name (PT)"
                      required={product.productCheckListNames.length > 1}
                      onBlur={e =>
                        changeNamePt(e.target.value, productCheckListNameIndex)
                      }
                    />
                    {checkList?.checkListItems?.map(item => {
                      const productCheckListIndex =
                        productCheckListName.productCheckLists.findIndex(
                          pcl => pcl.idCheckListItem === item.idCheckListItem,
                        );
                      const productCheckList =
                        productCheckListName.productCheckLists[
                          productCheckListIndex
                        ];

                      return (
                        <div key={item.idCheckListItem} className="p-md-12">
                          <ChecklistItem
                            className={getCssClassForLayout(item.idTypeLayout)}
                          >
                            <div className="p-d-flex p-jc-end button-div">
                              <button
                                type="button"
                                onClick={() =>
                                  removeAnswer(
                                    item,
                                    productCheckListNameIndex,
                                    productCheckListIndex,
                                  )
                                }
                              >
                                <FaTimes size={18} />
                              </button>
                            </div>
                            <Question className="checklist-question">
                              <b>Question</b>
                              <div>{item.name}</div>
                            </Question>
                            {(item.idTypeQuestion === TypeQuestion.RADIO ||
                              item.idTypeQuestion === TypeQuestion.CHECK) && (
                              <AnswerType className="checklist-answer-type">
                                <b>Answer Type</b>
                                {(item.idTypeQuestion ===
                                  TypeQuestion.RADIO && (
                                  <AnswerOption>
                                    <RadioInput
                                      className="radio"
                                      name={`productCheckListNames[${productCheckListNameIndex}].productCheckLists[${productCheckListIndex}].value`}
                                      defaultValue={item.value}
                                      options={item.checkListItemAnswers.map(
                                        answer => {
                                          return {
                                            id: answer.idCheckListAnswer,
                                            label:
                                              answer.idCheckListAnswer2.answer,
                                            icon: answer.idCheckListAnswer2
                                              .imageUrl,
                                          };
                                        },
                                      )}
                                      onChange={e =>
                                        changeValueRadio(
                                          e.target.value,
                                          productCheckListNameIndex,
                                          productCheckListIndex,
                                        )
                                      }
                                    />
                                  </AnswerOption>
                                )) ||
                                  (item.idTypeQuestion ===
                                    TypeQuestion.CHECK && (
                                    <div>
                                      {item.checkListItemAnswers?.map(
                                        answer => {
                                          const productCheckListCheckBoxIndex =
                                            productCheckListName?.productCheckLists.findIndex(
                                              pcl =>
                                                pcl.idCheckListItem ===
                                                  item.idCheckListItem &&
                                                answer.idCheckListAnswer ===
                                                  pcl.value,
                                            );
                                          const productCheckListsCheckbox =
                                            productCheckListName
                                              .productCheckLists[
                                              productCheckListCheckBoxIndex
                                            ];

                                          return (
                                            <AnswerOption
                                              key={answer.idCheckListAnswer}
                                              className="checklist-answer-option"
                                            >
                                              <FormCheckbox
                                                name={`productCheckLists[${productCheckListCheckBoxIndex}].value`}
                                                iconUrl={
                                                  answer.idCheckListAnswer2
                                                    .imageUrl
                                                }
                                                value={
                                                  productCheckListsCheckbox?.comment ===
                                                  'true'
                                                }
                                                label={`${answer.idCheckListAnswer2.answer}`}
                                                onValueChange={() =>
                                                  changeValueCheckbox(
                                                    productCheckListNameIndex,
                                                    item,
                                                    answer.idCheckListAnswer2,
                                                  )
                                                }
                                              />
                                            </AnswerOption>
                                          );
                                        },
                                      )}
                                    </div>
                                  ))}
                              </AnswerType>
                            )}
                            {((item.indComment === 1 ||
                              item.idTypeQuestion === TypeQuestion.TEXT) && (
                              <Comment>
                                <FormInput
                                  name={`productCheckListNames[${productCheckListNameIndex}].productCheckLists[${productCheckListIndex}].comment`}
                                  label="Comment"
                                  defaultValue={productCheckList?.comment}
                                  onBlur={e => {
                                    changeValueText(
                                      e.target.value,
                                      productCheckListNameIndex,
                                      productCheckListIndex,
                                    );
                                  }}
                                />
                              </Comment>
                            )) ||
                              (item.idTypeQuestion ===
                                TypeQuestion.LONGTEXT && (
                                <Comment>
                                  <InputTextarea
                                    rows={5}
                                    cols={23}
                                    defaultValue={productCheckList?.comment}
                                    autoResize
                                    onBlur={e => {
                                      changeValueText(
                                        e.target.value,
                                        productCheckListNameIndex,
                                        productCheckListIndex,
                                      );
                                    }}
                                  />
                                </Comment>
                              ))}
                          </ChecklistItem>
                        </div>
                      );
                    })}
                  </div>
                </Checklist>
              );
            },
          )}
          <MainButton
            className="add-checklist-button"
            label="Add an Item"
            onClick={() => addChecklist()}
            disabled={isChecklistLengthEqualOrGraterThanQtySellingPackage()}
            type="button"
          />
        </Container>
      )}

      {(productCheckListNamesLoading || checkListLoading) && <Loading />}
    </PageTabContainer>
  );
};

export default Checklists;
