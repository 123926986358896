/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useHistory } from 'react-router-dom';
import ToastLife from '../../shared/enums/toastLife';

// Direciona usuario para pagina do porto clicado
// @param e Evento de clique na linha da tabela
export const RedirectIdRespect = (idItenRedirect: string) => {
  const history = useHistory();
  return history.push(idItenRedirect);
};

export const onErrorWhileGetting = (
  errorData: { message: string },
  searchTearm: string,
) => {
  const objectError = {
    severity: 'error',
    summary: `Error while getting ${searchTearm}`,
    detail: errorData.message,
    life: ToastLife.ERROR,
  };
  return objectError;
};
