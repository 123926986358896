import React, { useMemo, useState } from 'react';
import FormAsyncSelect from '../../../../../../components/FormAsyncSelect';
import FormInput from '../../../../../../components/FormInput';
import FormInputMask from '../../../../../../components/FormInputMask';
import FormInputNumber from '../../../../../../components/FormInputNumber';
import FormTitle from '../../../../../../components/FormTitle';
import { PartnerCores } from '../../../../../../shared/enums/partner';
import { asyncSelectLoadPartners } from '../../../../../../shared/querys/partner';
import {
  ForeignTradeFieldsPermissions,
  SatForeignTrade,
} from '../../interfaces';
import { asyncSelectLoadPortsOptions } from '../../../../../../shared/querys/port';
import FormCheckbox from '../../../../../../components/FormCheckbox';
import Button from '../../../../../../components/Button';
import ShipmentRequestMenu from './ShipmentRequest';
import { ISatForeignTradeRef } from '../..';
import { ISat } from '../../../interfaces';
import userHasPermission from '../../../../../../utils/userHasPermission';
import { IUserFieldsAndPermissionsResponse } from '../../../../../../utils/getUserFieldsAndPermissionsByEntity';
import { satForeignTradeRoles } from '../../../../../../shared/roles/sat';
import { SatForeignTradeConsolidatedData } from '../Consolidation/interfaces';

interface IShipmentProps {
  shipmentIsCanceled?: boolean;
  foreignTrade: SatForeignTrade;
  fieldsPermissions: ForeignTradeFieldsPermissions;
  sat: ISat;
  foreignTradeRef: React.RefObject<ISatForeignTradeRef>;
  userPermissionsSat: IUserFieldsAndPermissionsResponse;
  consolidationData: SatForeignTradeConsolidatedData[];
}

const Shipment: React.FC<IShipmentProps> = ({
  shipmentIsCanceled,
  foreignTrade,
  fieldsPermissions,
  sat,
  foreignTradeRef,
  userPermissionsSat,
  consolidationData,
}) => {
  const { idPermissionShipmentRequest } = satForeignTradeRoles.permissions;

  const [isVisibleShipmentRequestMenu, setIsVisibleShipmentRequestMenu] =
    useState(false);

  const shouldRenderShipmentRequestButton = useMemo(() => {
    return (
      foreignTrade.shipmentDetails &&
      userHasPermission(
        idPermissionShipmentRequest,
        userPermissionsSat.userPermissions,
      )
    );
  }, [
    foreignTrade.shipmentDetails,
    idPermissionShipmentRequest,
    userPermissionsSat.userPermissions,
  ]);

  return (
    <div className="form-group p-grid">
      {isVisibleShipmentRequestMenu && (
        <ShipmentRequestMenu
          sat={sat}
          foreignTrade={foreignTrade}
          isVisibleShipmentRequestMenu={isVisibleShipmentRequestMenu}
          setIsVisibleShipmentRequestMenu={setIsVisibleShipmentRequestMenu}
          foreignTradeRef={foreignTradeRef}
          consolidationData={consolidationData}
        />
      )}

      <FormTitle className="p-col-12" name="Shipment">
        {shouldRenderShipmentRequestButton && (
          <Button
            label="Shipment Request"
            className="secondaryButton"
            onClick={() => setIsVisibleShipmentRequestMenu(true)}
            disabled={
              !!foreignTrade.shipmentRequestDate ||
              foreignTrade.shipmentRequestNotRequired
            }
          />
        )}
        {fieldsPermissions.shipmentRequestNotRequired.view && (
          <FormCheckbox
            className="p-d-flex p-as-center p-col-3"
            name="shipmentRequestNotRequired"
            label="Shipment Request Not Required"
            disabled={!!foreignTrade.shipmentRequestDate}
          />
        )}
      </FormTitle>

      <div className="p-d-flex w-full">
        {fieldsPermissions.idLoadingPort.view && (
          <FormAsyncSelect
            name="idLoadingPort"
            className="p-col-3"
            label="Port/Airport Of Loading"
            loadOptions={asyncSelectLoadPortsOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.idPort}
            noOptionsMessage={() => 'No ports found'}
            required
            initialValue={foreignTrade.idLoadingPort2}
            additional={{
              idCountry: sat.satCountryOrigin,
            }}
            readOnly={
              shipmentIsCanceled || !fieldsPermissions.idLoadingPort.edit
            }
            cacheUniqs={[sat.satCountryOrigin]}
          />
        )}

        {fieldsPermissions.idPort.view && (
          <FormAsyncSelect
            name="idPort"
            className="p-col-3"
            label="Port/Airport Of Discharge"
            loadOptions={asyncSelectLoadPortsOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.idPort}
            noOptionsMessage={() => 'No ports found'}
            required
            initialValue={foreignTrade.idPort2}
            readOnly={shipmentIsCanceled || !fieldsPermissions.idPort.edit}
            cacheUniqs={[sat.satCountryOrigin]}
          />
        )}

        {fieldsPermissions.clientOrder.view && (
          <FormInput
            className="p-col-3"
            name="clientOrder"
            label="Client Order"
            readOnly={shipmentIsCanceled || !fieldsPermissions.clientOrder.edit}
          />
        )}

        {fieldsPermissions.shipmentDetails.view && (
          <FormCheckbox
            className="p-d-flex p-as-center p-col-3"
            name="shipmentDetails"
            label="Shipment Details"
            disabled={!!foreignTrade.shipmentRequestDate}
          />
        )}
      </div>
      {fieldsPermissions.authorizationRequested.view && (
        <FormInput
          className="p-col-3"
          name="authorizationRequested"
          label="Authorization Requested"
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.authorizationRequested.edit
          }
          type="date"
        />
      )}

      {fieldsPermissions.shipmentAuthorized.view && (
        <FormInput
          className="p-col-3"
          name="shipmentAuthorized"
          label="Shipment Authorized"
          type="date"
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.shipmentAuthorized.edit
          }
        />
      )}

      {fieldsPermissions.bookingConfirmed.view && (
        <FormInput
          className="p-col-3"
          name="bookingConfirmed"
          label="Booking Confirmed"
          type="date"
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.bookingConfirmed.edit
          }
        />
      )}

      {fieldsPermissions.shipmentConfirmed.view && (
        <FormInput
          className="p-col-3"
          name="shipmentConfirmed"
          label="Shipment Confirmed"
          type="date"
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.shipmentConfirmed.edit
          }
        />
      )}
      {fieldsPermissions.freightForwarderOrigin.view && (
        <FormAsyncSelect
          className="p-col-3"
          name="freightForwarderOrigin"
          label="Freight Forwarder (Origin)"
          loadOptions={asyncSelectLoadPartners}
          additional={{
            core: PartnerCores.IND_FREIGHT_FORWARDER_ORIGIN,
          }}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.idPartner}
          noOptionsMessage={() => 'No partners found'}
          initialValue={foreignTrade.freightForwarderOrigin2}
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.freightForwarderOrigin.edit
          }
        />
      )}

      {fieldsPermissions.freightForwarderDestination.view && (
        <FormAsyncSelect
          className="p-col-3"
          name="freightForwarderDestination"
          label="Freight Forwarder (Destination)"
          loadOptions={asyncSelectLoadPartners}
          additional={{
            core: PartnerCores.IND_FREIGHT_FORWARDER_DESTINATION,
          }}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.idPartner}
          noOptionsMessage={() => 'No partners found'}
          initialValue={foreignTrade.freightForwarderDestination2}
          readOnly={
            shipmentIsCanceled ||
            !fieldsPermissions.freightForwarderDestination.edit
          }
        />
      )}

      {fieldsPermissions.freightCost.view && (
        <FormInputNumber
          className="p-col-3"
          name="freightCost"
          label="Freight Cost"
          thousandSeparator="."
          decimalSeparator=","
          prefix="$"
          decimalScale={2}
          readOnly={shipmentIsCanceled || !fieldsPermissions.freightCost.edit}
        />
      )}

      {fieldsPermissions.freightExtraCost.view && (
        <FormInputNumber
          className="p-col-3"
          name="freightExtraCost"
          label="Freight Extra Cost"
          thousandSeparator="."
          decimalSeparator=","
          prefix="$"
          decimalScale={2}
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.freightExtraCost.edit
          }
        />
      )}

      {fieldsPermissions.importLicenseNumber.view && (
        <FormInputMask
          className="p-col-3"
          name="importLicenseNumber"
          label="Import License Number"
          mask="99/9999999-9"
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.importLicenseNumber.edit
          }
        />
      )}

      {fieldsPermissions.importLicenseExpireDate.view && (
        <FormInput
          className="p-col-3"
          name="importLicenseExpireDate"
          label="Import License Expire Date"
          type="date"
          readOnly={
            shipmentIsCanceled ||
            !fieldsPermissions.importLicenseExpireDate.edit
          }
        />
      )}

      {fieldsPermissions.diNumber.view && (
        <FormInputMask
          className="p-col-3"
          name="diNumber"
          label="DI Number"
          mask="99/9999999-9"
          readOnly={shipmentIsCanceled || !fieldsPermissions.diNumber.edit}
        />
      )}

      {fieldsPermissions.arrivalConfirmed.view && (
        <FormCheckbox
          className="p-d-flex p-as-center p-col-3"
          name="arrivalConfirmed"
          label="Arrival Confirmed"
        />
      )}
    </div>
  );
};

export default Shipment;
