/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { get } from 'lodash';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { ColumnBodyOptions } from 'primereact/column';
import { IFilterInput } from '../services/dataInterface';
import imagePlaceholder from '../assets/imagePlaceholder.svg';
import isNullOrUndefined from './isNullOrUndefined';
import { getCurrencySymbol } from './getCurrencySymbol';
import { parseToLocaleFormat } from './dateUtil';

/**
 * Efetua parse de coluna de Data e Hora
 * @param rowData dados da linha
 * @param field dados do campo
 * @returns Coluna em formato de data e hora
 */
export const parseDateTimeColumm = (rowData: any, field: any) => {
  const value = rowData[field.field];

  let date;
  if (value) {
    date = new Date(value).toLocaleString();
  }

  return <p>{date}</p>;
};

/**
 * Efetua parse de coluna de Data
 * @param rowData dados da linha
 * @param column dados da coluna
 * @returns Coluna em formato de data
 */
export const parseDateColumm = (rowData: any, column: ColumnBodyOptions) => {
  const value = rowData[column.field];

  return <p>{parseToLocaleFormat(value)}</p>;
};

/**
 * Valida se coluna eh de porcentagem
 * @param rowData Dados da linha
 * @returns Campo com porcentagem
 */
export const parsePercentageColumnPt = (rowData: any, field: any) => {
  if (!rowData) return undefined;

  const value = rowData[field.field];

  return (
    value && (
      <p>{`${value?.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`}</p>
    )
  );
};

/**
 * Valida se coluna active é true ou false
 * @param rowData Dados da linha
 * @returns Campo com true ou false
 */
export const parseNullableBooleanColumn = (rowData: any, field: any) => {
  if (!rowData) return undefined;

  if (rowData[field.field] !== null && rowData[field.field] !== undefined) {
    return <p>{rowData[field.field] ? 'Yes' : 'No'}</p>;
  }
  return undefined;
};

/**
 * Renderiza caractere X caso valor seja true
 * @param rowData Dados da linha
 * @param field Campo com valor booleano
 * @returns X caso valor seja true
 */
export const parseXBooleanColumn = (rowData: any, field: any) => {
  if (rowData[field.field] !== null && rowData[field.field] !== undefined) {
    return <p>{rowData[field.field] ? 'X' : ''}</p>;
  }
  return undefined;
};

/**
 * Transforma valores numericos para padrao BR
 * @param rowData Dados da linha
 * @returns Campo com valor numerico em padrao BR
 */
export const parseFloatColumnPt = (rowData: any, field: any) => {
  return (
    rowData[field.field] && (
      <p>{`${rowData[field.field]?.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`}</p>
    )
  );
};

/**
 * Retorna coluna SAT Number com Link para pagina de SAT
 * @param rowData Dados da linha
 * @returns Coluna SAT Number com Link para pagina de SAT
 */
export const parseSatColumn = (rowData: any, field: any) => {
  return (
    rowData[field.field] && (
      <Link
        to={`/commercial/sats/${rowData.idSat}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData[field.field]}
        <FiExternalLink size={15} />
      </Link>
    )
  );
};

/**
 * Efetua parse de coluna de usuario
 * @param rowData dados da linha
 * @param field dados do campo
 * @returns Coluna em formato de data
 */
export const parseUserFirstAndLastNameColumm = (rowData: any, field: any) => {
  // Separa propriedade dividida por ponto
  const [property] = field.field?.split('.');

  return (
    rowData[property] && (
      <p>{`${rowData[property].firstName} ${rowData[property].lastName}`}</p>
    )
  );
};

/**
 * Retorna coluna com link para cadastro de Supplier
 * @param rowData Dados da Linha
 * @returns Coluna com link para cadastro de Supplier
 */
export const parseSunColumn = (rowData: any) => {
  return (
    rowData.idSupplier2 && (
      <Link
        to={`/suppliers/list/${rowData.idSupplier2?.idSupplier}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData.idSupplier2?.sunNumber}
        <FiExternalLink size={15} />
      </Link>
    )
  );
};

/**
 * Retorna coluna ST Code com Link para pagina de Produtos
 * @param rowData Dados da linha
 * @returns Coluna ST Code com Link para pagina de Produtos
 */
export const parseStCodeColumn = (rowData: any, field: any) => {
  return (
    rowData[field.field] && (
      <Link
        to={`/products/list/${rowData.idProduct}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData[field.field]}
        <FiExternalLink size={15} />
      </Link>
    )
  );
};

/**
 * Retorna coluna ST Code com Link para pagina de Produtos
 * @param rowData Dados da linha
 * @returns Coluna ST Code com Link para pagina de Produtos
 */
export const parseClientNameColumn = (rowData: any) => {
  return (
    rowData.idClient2 && (
      <Link
        to={`/clients/list/${rowData.idClient2.idClient}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
        style={{ justifyContent: 'space-between' }}
      >
        {rowData.idClient2.name}
        <span>
          <FiExternalLink size={15} />
        </span>
      </Link>
    )
  );
};

/**
 * Retorna coluna PRC Code
 * @param rowData Dados da linha
 * @returns Coluna PRC Code com Link para pagina de PRC
 */
export const parsePrcCodeColumn = (rowData: any) => {
  return (
    rowData?.prcCode && (
      <Link
        to={`/prcs/list/${rowData?.idPrc}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData?.prcCode}
        <FiExternalLink size={15} />
      </Link>
    )
  );
};

/**
 * Retorna coluna CQ Number
 * @param rowData Dados da linha
 * @returns Coluna CQ Number com Link para pagina de CQ
 */
export const parseCqCodeColumn = (rowData: any) => {
  return (
    rowData?.cqNumber && (
      <Link
        to={`/client-quotations/list/${rowData?.idCq}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData?.cqNumber}
        <FiExternalLink size={15} />
      </Link>
    )
  );
};

/**
 * Retorna coluna Texto formatado
 * @param rowData Dados da linha
 * @returns Coluna com texto formatado
 */
export const parseFormatedTextColumn = (rowData: any, field: any) => {
  if (!rowData) return undefined;

  return rowData[field.field] && <pre>{rowData[field.field]}</pre>;
};

/**
 * Retorna coluna com imagem
 * @param rowData Dados da Linha
 * @param field dados do campo
 * @returns Coluna com Imagem
 */
export const parseImageColumn = (rowData: any, field: any) => {
  if (!rowData) return undefined;

  return (
    <img
      src={get(rowData, field.field) ?? ''}
      onError={e => {
        e.currentTarget.src = imagePlaceholder;
      }}
      alt="Item"
      style={{ width: '90px' }}
    />
  );
};

/**
 * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
 * @param headerName Nome do header
 * @returns componente de header
 */
export function handleColumnHeader(
  headerName: string,
  filteredColumnsHeader: IFilterInput[],
  tooltip?: string,
) {
  return (
    <span
      className="custom-header"
      data-pr-tooltip={tooltip}
      data-pr-position="right"
    >
      {headerName}
      {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
      {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
        <i className="pi pi-filter" />
      ) : null}
    </span>
  );
}

/**
 * Retorna coluna com numero formatado no padrao em portugues
 * @param rowData Dados da linha
 * @param column Dados da coluna
 * @param decimalPlaces Quantidade de casas decimais exibidas
 * @returns Coluna com numero formatado no padrao em portugues
 */
export const parsePtBrNumberColumn = (
  rowData: any,
  column: ColumnBodyOptions,
  decimalPlaces?: number,
) => {
  return (
    rowData[column.field] &&
    rowData[column.field].toLocaleString('pt', {
      minimumFractionDigits: decimalPlaces ?? 2,
      maximumFractionDigits: decimalPlaces ?? 4,
    })
  );
};

/**
 * Retorna coluna com numero formatado no padrao em portugues e cifrao
 * @param rowData Dados da linha
 * @param column Dados da coluna
 * @param decimalPlaces Quantidade de casas decimais exibidas
 * @returns Coluna com numero formatado no padrao em portugues e cifrao
 */
export const parseCurrencyPtBrNumberColumn = (
  rowData: any,
  column: ColumnBodyOptions,
  decimalPlaces?: number,
  currencyCode?: string,
) => {
  return (
    !isNullOrUndefined(rowData[column.field]) &&
    `${getCurrencySymbol(currencyCode)} ${parsePtBrNumberColumn(
      rowData,
      column,
      decimalPlaces,
    )}`
  );
};
