import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { ValidationError, number, object } from 'yup';
import { SatContainer } from '../interfaces';
import {
  manageSatContainerInitialState,
  manageSatContainerReducer,
} from './reducers';
import { createSatContainerQuery, updateSatContainerQuery } from './queries';
import {
  ManageSatContainerReducerActionKind,
  SatContainerFormData,
} from './interfaces';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import Button from '../../../../../../../components/Button';
import FormInputNumber from '../../../../../../../components/FormInputNumber';
import FormAsyncSelect from '../../../../../../../components/FormAsyncSelect';
import { asyncSelectLoadDomains } from '../../../../../../../shared/querys/domain';
import { DomainGroup } from '../../../../../../../shared/enums/domainGroup';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../../../../utils/getValidationErrors';

export interface IManageContainerRef {
  toggle(satContainer?: SatContainer): void;
}

interface IManageContainerProps {
  ref: Ref<IManageContainerRef>;
  idSatForeignTrade: number;
  containersRefetch(): Promise<ApolloQueryResult<any>>;
}

const ManageContainer: React.FC<IManageContainerProps> = forwardRef(
  ({ idSatForeignTrade, containersRefetch }, ref) => {
    const { showError, showSuccess } = useRefHook();

    const formRef = useRef<FormHandles>(null);

    const [manageContainerState, manageContainerDispatch] = useReducer(
      manageSatContainerReducer,
      manageSatContainerInitialState,
    );
    const [loading, setLoading] = useState(false);

    const [createSatContainerMutation] = useMutation(createSatContainerQuery);
    const [updateSatContainerMutation] = useMutation(updateSatContainerQuery);

    useImperativeHandle(ref, () => ({
      toggle: satContainer => {
        manageContainerDispatch({
          type: ManageSatContainerReducerActionKind.OPEN_DIALOG,
          payload: { satContainer },
        });
      },
    }));

    async function handleSaveContainer(formData: SatContainerFormData) {
      setLoading(true);

      formRef.current?.setErrors({});

      const schema = object().shape({
        qtyCtnr: number().nullable().required(requiredFieldErrorMessage),
        idTypeCtnr: number().nullable().required(requiredFieldErrorMessage),
      });

      try {
        await schema.validate(formData, { abortEarly: false });

        if (manageContainerState.containerToEdit) {
          await updateSatContainerMutation({
            variables: {
              data: {
                idSatCtnr: formData.idSatCtnr,
                idTypeCtnr: formData.idTypeCtnr,
                qtyCtnr: formData.qtyCtnr,
              },
            },
          });
        } else {
          await createSatContainerMutation({
            variables: {
              data: {
                idSatForeignTrade,
                idTypeCtnr: formData.idTypeCtnr,
                qtyCtnr: formData.qtyCtnr,
              },
            },
          });
        }

        await containersRefetch();

        showSuccess({
          summary: `Container ${
            manageContainerState.containerToEdit ? 'updated' : 'created'
          }`,
        });

        manageContainerDispatch({
          type: ManageSatContainerReducerActionKind.CLOSE_DIALOG,
        });
      } catch (error) {
        if (error instanceof ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        } else {
          showError({
            summary: `Error while ${
              manageContainerState.containerToEdit ? 'updating' : 'creating'
            } Container`,
            detail: error.message,
          });
        }
      } finally {
        setLoading(false);
      }
    }

    const dialogFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button
            label="Save"
            icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
            onClick={() => formRef.current?.submitForm()}
            disabled={loading}
          />
          <Button
            label="Cancel"
            severity="danger"
            icon="pi pi-times"
            onClick={() =>
              manageContainerDispatch({
                type: ManageSatContainerReducerActionKind.CLOSE_DIALOG,
              })
            }
            disabled={loading}
          />
        </div>
      );
    };

    return (
      <Dialog
        header={
          manageContainerState.containerToEdit
            ? 'Manage Container'
            : 'Add Container'
        }
        visible={manageContainerState.displayDialog}
        style={{ width: '400px' }}
        modal
        onHide={() =>
          manageContainerDispatch({
            type: ManageSatContainerReducerActionKind.CLOSE_DIALOG,
          })
        }
        footer={dialogFooter()}
        closable={false}
      >
        <Form
          className="p-grid p-formgrid"
          ref={formRef}
          onSubmit={handleSaveContainer}
          initialData={manageContainerState.containerToEdit}
        >
          <FormInputNumber name="idSatCtnr" label="id" hidden />

          <FormInputNumber
            className="p-field p-col"
            name="qtyCtnr"
            label="Qty of CTNR"
            required
          />

          <FormAsyncSelect
            className="p-field p-col"
            name="idTypeCtnr"
            label="Type of CTNR"
            loadOptions={asyncSelectLoadDomains}
            additional={{
              id: DomainGroup.TYPE_OF_CTNR,
            }}
            getOptionLabel={option => option.description}
            getOptionValue={option => option.idDomain}
            noOptionsMessage={() => 'No type of CTNRs found'}
            required
            initialValue={manageContainerState.containerToEdit?.idTypeCtnr2}
            menuPosition="fixed"
          />
        </Form>
      </Dialog>
    );
  },
);

export default ManageContainer;
