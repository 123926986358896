import React from 'react';

import { Container, Widgets } from './styles';
import { useAuth } from '../../hooks/useAuth';
import WidgetCard from '../../components/WidgetCard';
import PrcWidget from './PrcWidget';

import { prcRoles } from '../../shared/roles/prc';
import ClientQuotationWidget from './ClientQuotationWidget';
import { clientQuotationRoles } from '../../shared/roles/clientQuotation';
import useTitle from '../../hooks/useTitle';

const Home: React.FC = () => {
  useTitle('Home');

  // Dados do usuario
  const { user, roles } = useAuth();

  return (
    <Container>
      <h1>{`Welcome, ${user.firstName}`}</h1>

      <Widgets>
        {roles.entities.includes(prcRoles.idEntity) && (
          <WidgetCard
            name="PRC Pool"
            className="prcWidget"
            subTitle="Showing items that are in the PRC pool or are assigned directly to you."
            style={{ width: '80em' }}
          >
            <PrcWidget />
          </WidgetCard>
        )}
      </Widgets>

      <Widgets>
        {roles.entities.includes(clientQuotationRoles.idEntity) && (
          <WidgetCard
            name="CQ Pool"
            className="cqWidget"
            subTitle="Showing items that are in the CQ pool or are assigned directly to you."
            style={{ width: '70em' }}
          >
            <ClientQuotationWidget />
          </WidgetCard>
        )}
      </Widgets>
    </Container>
  );
};

export default Home;
