import { ReactElement } from 'react';
import { FiCoffee, FiTag } from 'react-icons/fi';
import { TiShoppingCart } from 'react-icons/ti';
import { RiShip2Fill } from 'react-icons/ri';
import { GiShakingHands } from 'react-icons/gi';
import { FaClipboardList } from 'react-icons/fa';

import { BsFillKanbanFill } from 'react-icons/bs';
import { ReactComponent as SatIcon } from '../../assets/satIcons/satIconBlack.svg';
import { ReactComponent as SatSupplier } from '../../assets/satIcons/satSupplier.svg';
import { ReactComponent as SatClient } from '../../assets/satIcons/satClient.svg';
import { ReactComponent as SatFinancial } from '../../assets/satIcons/satFinancial.svg';
import { ReactComponent as SatControls } from '../../assets/satIcons/satControls.svg';

import { productRoles } from '../../shared/roles/product';
import { clientRoles } from '../../shared/roles/client';
import { categoryRoles } from '../../shared/roles/category';
import { packageRoles } from '../../shared/roles/package';
import { finishingRoles } from '../../shared/roles/finishing';
import { pantoneRoles } from '../../shared/roles/pantone';
import { portRoles } from '../../shared/roles/port';
import { courierRoles } from '../../shared/roles/courier';
import { incotermRoles } from '../../shared/roles/incoterm';
import { paymentConditionRoles } from '../../shared/roles/paymentCondition';
import { contactRoles } from '../../shared/roles/contactRoles';
import { clientStatus } from '../../shared/roles/clientStatus';
import { clientTypeOfOperations } from '../../shared/roles/clientTypeOfOperations';
import { clientNumbersOfStoresRoles } from '../../shared/roles/clientNumbersOfStores';
import { clientTaxBenefitRoles } from '../../shared/roles/clientTaxBenefit';
import { specialNegotiationRoles } from '../../shared/roles/specialNegotiation';
import { typeOfBusinessRoles } from '../../shared/roles/typeOfBusiness';
import { checklistRoles } from '../../shared/roles/checklist';
import { clientServicesRole } from '../../shared/roles/clientServices';
import { satsRoles } from '../../shared/roles/sat';
import { partnersListRoles } from '../../shared/roles/partners';
import { supplierRoles } from '../../shared/roles/supplier';
import { ncmRoles } from '../../shared/roles/ncm';
import { icmsRoles } from '../../shared/roles/icms';
import { oceanFreightRoles } from '../../shared/roles/oceanFreight';
import { portTaxRoles } from '../../shared/roles/portTax';
import { oceanFreightTaxRoles } from '../../shared/roles/oceanFreightTax';
import { airFreightRoles } from '../../shared/roles/airFreight';
import { airFreightTaxRoles } from '../../shared/roles/airFreightTax';
import { airportTaxRoles } from '../../shared/roles/airportTax';
import { prcRoles } from '../../shared/roles/prc';
import { inlandFreightRoles } from '../../shared/roles/inlandFreight';
import { inlandFreightTaxRoles } from '../../shared/roles/inlandFreightTax';
import { expenseRoles } from '../../shared/roles/expense';
import { tableSiscomexRoles } from '../../shared/roles/tableSiscomex';
import { clientQuotationRoles } from '../../shared/roles/clientQuotation';
import { registerRoles } from '../../shared/roles/registerRoles';
import { qualityRoles } from '../../shared/roles/quality';
import { financialRoles } from '../../shared/roles/financial';
import { importRoles } from '../../shared/roles/importRoles';
import { designRoles } from '../../shared/roles/design';
import { followUpRoles } from '../../shared/roles/followUp';
import { satsPurchaseRoles } from '../../shared/roles/satsPurchaseRoles';
import { commercialRoles } from '../../shared/roles/commercial';
import { satsExportRoles } from '../../shared/roles/satsExportRoles';
import {
  artworkDashboardRoles,
  clientPaymentDashboardRoles,
  dashboardsIdModule,
  documentsDashboardRoles,
  productionDashboardRoles,
  registerDashboardRoles,
  shipmentDashboardRoles,
  rncDashboardRoles,
  supplierPaymentDashboardRoles,
} from '../../shared/roles/dashboards';

interface Item {
  label: string;
  Icon?: ReactElement;
  path: string;
  idModule: number;
  idEntity: number;
}

interface ItemGroupSubItem {
  label: string;
  path: string;
  idEntity: number;
}

export interface ItemGroup {
  label: string;
  Icon?: ReactElement;
  iconBackground?: string;
  collapse: boolean;
  items: ItemGroupSubItem[];
  idModule: number;
}

export interface ISidebarItems {
  items: Item[];
  itemGroups?: ItemGroup[];
}

export const sidebarItems: ISidebarItems = {
  items: [
    {
      label: 'PRCs',
      path: '/prcs/list',
      Icon: <TiShoppingCart size={22} />,
      idModule: prcRoles.idModule,
      idEntity: prcRoles.idEntity,
    },
    {
      label: 'CQs',
      path: '/client-quotations/list',
      Icon: <FiTag size={20} />,
      idModule: clientQuotationRoles.idModule,
      idEntity: clientQuotationRoles.idEntity,
    },
    {
      label: 'Products',
      path: '/products/list',
      Icon: <FiCoffee size={20} />,
      idModule: productRoles.idModule,
      idEntity: productRoles.idEntity,
    },
    {
      label: 'SATs',
      path: '/commercial/sats',
      Icon: <SatIcon />,
      idModule: satsRoles.idModule,
      idEntity: satsRoles.idEntity,
    },
    {
      label: 'Clients',
      path: '/clients',
      idModule: 3,
      idEntity: 0,
    },
    {
      label: 'Quotations',
      path: '/quotations',
      idModule: 0,
      idEntity: 0,
    },
    {
      label: 'Partners',
      path: '/partners',
      idModule: 0,
      idEntity: 0,
    },
    {
      label: 'Suppliers',
      path: '/suppliers',
      idModule: 2,
      idEntity: 0,
    },
    {
      label: 'NCMs',
      path: '/ncms',
      idModule: 0,
      idEntity: 0,
    },
  ],
  itemGroups: [
    {
      label: 'Controls',
      Icon: <SatControls fill="white" style={{ width: '25px' }} />,
      iconBackground: '#4d4d4d',
      collapse: true,
      idModule: 12,
      items: [
        {
          label: 'Commercial',
          path: '/controls/commercial',
          idEntity: commercialRoles.idEntity,
        },
        {
          label: 'Design',
          path: '/controls/design',
          idEntity: designRoles.idEntity,
        },
        {
          label: 'Export',
          path: '/controls/export',
          idEntity: satsExportRoles.idEntity,
        },
        {
          label: 'Financial',
          path: '/controls/financial',
          idEntity: financialRoles.idEntity,
        },
        {
          label: 'Follow Up',
          path: '/controls/followup',
          idEntity: followUpRoles.idEntity,
        },
        {
          label: 'Import',
          path: '/controls/import',
          idEntity: importRoles.idEntity,
        },
        {
          label: 'Purchase',
          path: '/controls/purchase',
          idEntity: satsPurchaseRoles.idEntity,
        },
        {
          label: 'Quality',
          path: '/controls/quality',
          idEntity: qualityRoles.idEntity,
        },
        {
          label: 'Register',
          path: '/controls/register',
          idEntity: registerRoles.idEntity,
        },
      ],
    },
    {
      label: 'Dashboards',
      Icon: <BsFillKanbanFill size={20} />,
      iconBackground: '#4d4d4d',
      collapse: true,
      idModule: dashboardsIdModule,
      items: [
        {
          label: 'Register',
          path: '/dashboards/register',
          idEntity: registerDashboardRoles.idEntity,
        },
        {
          label: 'Supplier Payment',
          path: '/dashboards/supplierPayment',
          idEntity: supplierPaymentDashboardRoles.idEntity,
        },
        {
          label: 'Client Payment',
          path: '/dashboards/clientPayment',
          idEntity: clientPaymentDashboardRoles.idEntity,
        },
        {
          label: 'Artwork',
          path: '/dashboards/artwork',
          idEntity: artworkDashboardRoles.idEntity,
        },
        {
          label: 'Production',
          path: '/dashboards/production',
          idEntity: productionDashboardRoles.idEntity,
        },
        {
          label: 'Shipment',
          path: '/dashboards/shipment',
          idEntity: shipmentDashboardRoles.idEntity,
        },
        {
          label: 'Documents',
          path: '/dashboards/documents',
          idEntity: documentsDashboardRoles.idEntity,
        },
        {
          label: 'RNC',
          path: '/dashboards/rnc',
          idEntity: rncDashboardRoles.idEntity,
        },
      ],
    },
    {
      label: 'Suppliers',
      Icon: <SatSupplier fill="white" style={{ width: '25px' }} />,
      iconBackground: '#4d4d4d',
      collapse: true,
      idModule: 2,
      items: [
        {
          label: 'Suppliers',
          path: '/suppliers/list',
          idEntity: supplierRoles.idEntity,
        },
      ],
    },
    {
      label: 'Clients',
      Icon: <SatClient fill="white" style={{ width: '25px' }} />,
      iconBackground: '#4d4d4d',
      collapse: true,
      idModule: 3,
      items: [
        {
          label: 'Clients',
          path: '/clients/list',
          idEntity: clientRoles.idEntity,
        },
        {
          label: 'Client Status',
          path: '/clients/status',
          idEntity: clientStatus.idEntity,
        },

        {
          label: 'Type of Business',
          path: '/clients/type-of-business',
          idEntity: typeOfBusinessRoles.idEntity,
        },
        {
          label: 'Number of Stores',
          path: '/clients/number-of-stores',
          idEntity: clientNumbersOfStoresRoles.idEntity,
        },
      ],
    },
    {
      label: 'Partners',
      // Icon: <SatCommercial fill="white" style={{ width: '25px' }} />,
      Icon: <GiShakingHands size={25} />,
      iconBackground: '#4d4d4d',
      collapse: true,
      idModule: 9,
      items: [
        {
          label: 'Partners',
          path: '/partners/list',
          idEntity: partnersListRoles.idEntity,
        },
      ],
    },
    {
      label: 'Financial',
      Icon: <SatFinancial fill="white" style={{ width: '25px' }} />,
      iconBackground: '#E51FB2',
      collapse: true,
      idModule: 6,
      items: [
        {
          label: 'Account Number',
          path: '/financial/account-number',
          idEntity: 0,
        },
        {
          label: 'Account Receivable',
          path: '/financial/account-receivable',
          idEntity: 0,
        },
        {
          label: 'Account Payable',
          path: '/financial/account-payable',
          idEntity: 0,
        },
        {
          label: 'Debit Note',
          path: '/financial/debit-note',
          idEntity: 0,
        },
        {
          label: 'Credit Note',
          path: '/financial/credit-note',
          idEntity: 0,
        },
        {
          label: 'Bank Account',
          path: '/financial/bank-account',
          idEntity: 0,
        },
        {
          label: 'Cash Flow',
          path: '/financial/cash-flow',
          idEntity: 0,
        },
      ],
    },
    {
      label: 'Lists',
      Icon: <FaClipboardList size={22} />,
      iconBackground: '#4d4d4d',
      collapse: true,
      idModule: 8,
      items: [
        {
          label: 'Categories',
          path: '/list/categories',
          idEntity: categoryRoles.idEntity,
        },
        {
          label: 'Contact Roles',
          path: '/list/contact-roles',
          idEntity: contactRoles.idEntity,
        },
        {
          label: 'Couriers',
          path: '/list/couriers',
          idEntity: courierRoles.idEntity,
        },
        {
          label: 'ICMS',
          path: '/list/icms',
          idEntity: icmsRoles.idEntity,
        },
        {
          label: 'Incoterms',
          path: '/list/incoterms',
          idEntity: incotermRoles.idEntity,
        },
        {
          label: 'Payment Conditions',
          path: '/list/payment-conditions',
          idEntity: paymentConditionRoles.idEntity,
        },
        {
          label: 'Airports/Ports',
          path: '/list/ports',
          idEntity: portRoles.idEntity,
        },
        {
          label: 'Services',
          path: '/list/services',
          idEntity: clientServicesRole.idEntity,
        },
        {
          label: 'Special Negotiations',
          path: '/list/special-negotiations',
          idEntity: specialNegotiationRoles.idEntity,
        },
        {
          label: 'Tax Benefits',
          path: '/list/tax-benefits',
          idEntity: clientTaxBenefitRoles.idEntity,
        },
        {
          label: 'Type of Operations',
          path: '/list/type-of-operations',
          idEntity: clientTypeOfOperations.idEntity,
        },
        {
          label: 'Packages',
          path: '/packages',
          idEntity: packageRoles.idEntity,
        },
        {
          label: 'Checklists',
          path: '/products/checklists',
          idEntity: checklistRoles.idEntity,
        },
        {
          label: 'Finishings',
          path: '/finishings',
          idEntity: finishingRoles.idEntity,
        },
        {
          label: 'Pantones',
          path: '/pantones',
          idEntity: pantoneRoles.idEntity,
        },
        {
          label: 'NCMs',
          path: '/products/ncms',
          idEntity: ncmRoles.idEntity,
        },
      ],
    },
    {
      label: 'Logistics',
      Icon: <RiShip2Fill size={24} />,
      iconBackground: '#4d4d4d',
      collapse: true,
      idModule: 10,
      items: [
        {
          label: 'Ocean Freight',
          path: '/logistics/oceanFreight',
          idEntity: oceanFreightRoles.idEntity,
        },
        {
          label: 'Ocean Freight Taxes',
          path: '/logistics/oceanFreightTaxes',
          idEntity: oceanFreightTaxRoles.idEntity,
        },
        {
          label: 'Port Taxes',
          path: '/logistics/portTaxes',
          idEntity: portTaxRoles.idEntity,
        },
        {
          label: 'Air Freight',
          path: '/logistics/airFreight',
          idEntity: airFreightRoles.idEntity,
        },
        {
          label: 'Air Freight Taxes',
          path: '/logistics/airFreightTaxes',
          idEntity: airFreightTaxRoles.idEntity,
        },
        {
          label: 'Airport Taxes',
          path: '/logistics/airportTaxes',
          idEntity: airportTaxRoles.idEntity,
        },
        {
          label: 'Inland Freight',
          path: '/logistics/inlandFreights',
          idEntity: inlandFreightRoles.idEntity,
        },
        {
          label: 'Inland Freight Taxes',
          path: '/logistics/inlandFreightTaxes',
          idEntity: inlandFreightTaxRoles.idEntity,
        },
        {
          label: 'Expenses',
          path: '/logistics/expenses',
          idEntity: expenseRoles.idEntity,
        },
        {
          label: 'Tabela Siscomex',
          path: '/logistics/tableSiscomexes',
          idEntity: tableSiscomexRoles.idEntity,
        },
      ],
    },
  ],
};
