import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { useLazyQuery } from '@apollo/client';
import { DataTablePageParams } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import FormTitle from '../../../../../../components/FormTitle';
import Grid from '../../../../../../components/Grid';
import { SatOtherExpense, SatOtherExpensesLazyParams } from './interfaces';
import pagination from '../../../../../../config/pagination';
import { listOtherExpensesQuery } from './queries';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { FinancialSellerOtherExpenses } from '../../../../../../shared/enums/domains';
import {
  parseCurrencyPtBrNumberColumn,
  parseNullableBooleanColumn,
} from '../../../../../../utils/gridColumnsParse';
import { parseToLocaleFormat } from '../../../../../../utils/dateUtil';
import { ForeignTradeSupplierTotalSellerOtherExpensesFieldsPermissions } from '../interfaces';
import { SatFinancialSellerOtherExpenseFieldsPermissions } from '../../interfaces';

interface ISellerOtherExpensesProps {
  fieldsPermissions: SatFinancialSellerOtherExpenseFieldsPermissions;
  idSat: number;
  selected: boolean;
  totalOtherExpensesFieldsPermissions: ForeignTradeSupplierTotalSellerOtherExpensesFieldsPermissions;
}

const SellerOtherExpenses: React.FC<ISellerOtherExpensesProps> = ({
  fieldsPermissions,
  idSat,
  selected,
  totalOtherExpensesFieldsPermissions,
}) => {
  const { toastRef } = useRefHook();

  const [otherExpenses, setOtherExpenses] = useState<SatOtherExpense[]>();

  const [lazyParams, setLazyParams] = useState<SatOtherExpensesLazyParams>(
    pagination.initialLazyParams,
  );

  const [
    loadOtherExpensesData,
    { loading: otherExpensesLoading, data: otherExpensesData },
  ] = useLazyQuery(listOtherExpensesQuery(fieldsPermissions), {
    variables: {
      data: {
        idSat,
        typeIdDomainGroup: DomainGroup.FINANCIAL_SELLER_OTHER_EXPENSES,
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
        },
      },
    },
    onCompleted: response => {
      if (response.listSatForeignTradeSupplierOtherExpensesByIdSat) {
        setOtherExpenses([
          ...response.listSatForeignTradeSupplierOtherExpensesByIdSat.data,
        ]);
      }
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Total Seller Other Expenses',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  function expenseIsCreditNoteAdded(rowData: SatOtherExpense) {
    return (
      rowData.idType === FinancialSellerOtherExpenses.CREDIT_NOTE &&
      rowData.idSatDestination === idSat &&
      rowData.confirmationDate &&
      rowData.isDestination
    );
  }

  function expenseIsCreditNoteAddedFromOrigin(rowData: SatOtherExpense) {
    return (
      rowData.idType === FinancialSellerOtherExpenses.CREDIT_NOTE &&
      rowData.idSat === idSat &&
      rowData.confirmationDate &&
      !rowData.isDestination
    );
  }

  function getRowClassName(rowData: SatOtherExpense) {
    return {
      'oe-grid-credit-note-added': expenseIsCreditNoteAdded(rowData),
      'oe-grid-credit-note-added-from-origin':
        expenseIsCreditNoteAddedFromOrigin(rowData),
    };
  }

  const renderTypeColumn = (rowData: SatOtherExpense) => {
    const { idType2 } = rowData;

    return (
      <div>
        <span>{idType2?.description}</span>
      </div>
    );
  };

  const renderBooleanFields = (rowData: SatOtherExpense, field: string) => {
    return parseNullableBooleanColumn(rowData, { columnKey: field });
  };

  const renderObsColumn = (rowData: SatOtherExpense) => {
    return (
      <>
        <Tooltip
          target={`.s-comment-${rowData.idSatOtherExpense}`}
          position="top"
          style={{ width: '100%' }}
        />
        <span
          className={`s-comment-${rowData.idSatOtherExpense} s-grid-break-text`}
          data-pr-tooltip={rowData.comment}
        >
          {rowData.comment}
        </span>
      </>
    );
  };

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  const onPage = (event: DataTablePageParams) => {
    setLazyParams({ ...lazyParams, ...event });
  };

  useEffect(() => {
    if (!otherExpensesData && selected) {
      loadOtherExpensesData();
    }
  }, [loadOtherExpensesData, otherExpenses, otherExpensesData, selected]);

  return (
    <div>
      <FormTitle name="Other Expenses" />

      <Grid
        className="p-datatable-sm"
        emptyMessage="No Other Expenses found"
        loading={otherExpensesLoading}
        value={otherExpenses}
        lazy
        rows={lazyParams.rows}
        first={!otherExpensesData ? undefined : lazyParams.first}
        totalRecords={
          !otherExpensesData
            ? 0
            : otherExpensesData.listSatForeignTradeSupplierOtherExpensesByIdSat
                ?.items
        }
        scrollable
        scrollHeight="calc(100vh - 272px)"
        onPage={onPage}
        rowClassName={getRowClassName}
      >
        {totalOtherExpensesFieldsPermissions.sunNumber.view && (
          <Column
            field="idSatForeignTradeSupplier2.idSupplier2.sunNumber"
            header="Supplier"
            style={{ width: '6rem' }}
          />
        )}

        {totalOtherExpensesFieldsPermissions.satForeignTradeNumber.view && (
          <Column
            field="idSatForeignTrade2.satForeignTradeNumber"
            header="Shipment From"
            style={{ width: '9rem' }}
          />
        )}

        {totalOtherExpensesFieldsPermissions.satForeignTradeDestinationNumber
          .view && (
          <Column
            field="idSatForeignTradeDestination2.satForeignTradeNumber"
            header="Shipment To"
            style={{ width: '9rem' }}
          />
        )}

        {fieldsPermissions.idType.view && (
          <Column
            field="idType2.description"
            header="Type"
            body={e => renderTypeColumn(e)}
            bodyClassName="s-uppercase"
            style={{ width: '8rem' }}
          />
        )}

        {fieldsPermissions.idCurrency.view && (
          <Column
            field="idCurrency2.abbreviation"
            header="Currency"
            style={{ width: '6rem' }}
          />
        )}

        {fieldsPermissions.total.view && (
          <Column
            field="total"
            header="Total"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(
                data,
                props,
                2,
                data.idCurrency2.abbreviation,
              )
            }
            style={{ width: '8rem' }}
          />
        )}

        {fieldsPermissions.dateOtherExpense.view && (
          <Column
            header="Date"
            body={e => parseToLocaleFormat(e.dateOtherExpense)}
            style={{ width: '8rem' }}
          />
        )}

        {fieldsPermissions.linkToMargin.view && (
          <Column
            header="Link to Margin"
            body={e => renderBooleanFields(e, 'linkToMargin')}
            bodyClassName="s-uppercase"
            style={{ width: '6rem' }}
          />
        )}

        {fieldsPermissions.creditToClient.view && (
          <Column
            header="Credit to Client"
            body={e => renderBooleanFields(e, 'creditToClient')}
            bodyClassName="s-uppercase"
            style={{ width: '6rem' }}
          />
        )}

        {fieldsPermissions.apportionment.view && (
          <Column
            header="Apportionment"
            body={e => renderBooleanFields(e, 'apportionment')}
            bodyClassName="s-uppercase"
            style={{ width: '8rem' }}
          />
        )}

        {fieldsPermissions.calculationBasis.view && (
          <Column
            header="Calculation Basis"
            field="calculationBasis2.description"
            style={{ width: '8rem' }}
          />
        )}

        {fieldsPermissions.comment.view && (
          <Column
            header="Obs"
            body={e => renderObsColumn(e)}
            style={{ width: '8rem' }}
          />
        )}
      </Grid>
    </div>
  );
};

export default SellerOtherExpenses;
