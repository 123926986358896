import React from 'react';
import FormInputNumber from '../../../../../../../../../components/FormInputNumber';
import {
  PackageReferenceFieldsPermissions,
  ProductPackageSizeBarcode,
} from '../../../../../interfaces';
import FormCheckbox from '../../../../../../../../../components/FormCheckbox';
import ReadOnlyInput from '../../../../../../../../../components/ReadOnlyInput';
import Barcode from '../../../../../../../../../components/Barcode';

interface IPackageTypePackProps {
  packageSizeBarcode: ProductPackageSizeBarcode;
  reference?: string;
  isANewSizeBarcode: boolean;
  packageUnit?: string;
  fieldsPermissions: PackageReferenceFieldsPermissions;
}

const PackageTypePack: React.FC<IPackageTypePackProps> = ({
  packageSizeBarcode,
  reference,
  isANewSizeBarcode,
  packageUnit,
  fieldsPermissions,
}) => {
  return (
    <div className="p-d-flex p-ai-center">
      {isANewSizeBarcode && (
        <FormInputNumber
          name="size"
          label="size"
          value={packageSizeBarcode.size}
          hidden
        />
      )}

      {fieldsPermissions.masterBarcode.view && (
        <div className="p-ml-2">
          <FormInputNumber
            className="p-field"
            name="masterBox"
            label="Master Barcode"
            decimalScale={0}
            type="text"
            inputMode="numeric"
            max={99999999999999}
            thousandSeparator={false}
          />

          <FormCheckbox
            label="Auto"
            name="autoMasterBox"
            tooltip="Auto Generate Master Barcode"
            initialValue={packageSizeBarcode.autoMasterBox}
          />
        </div>
      )}
      <div className="p-pl-6 p-formgrid p-grid">
        {fieldsPermissions.unit.view && (
          <ReadOnlyInput
            className="p-field p-col-2"
            label="Unit"
            value={packageSizeBarcode.unit2?.description ?? packageUnit}
          />
        )}

        {fieldsPermissions.size.view && (
          <ReadOnlyInput
            className="p-field p-col-5"
            label="Size"
            value={packageSizeBarcode.size2?.description}
          />
        )}

        {fieldsPermissions.reference.view && (
          <ReadOnlyInput
            className="p-field p-col-3"
            label="Reference"
            value={reference}
            allowOverflow
          />
        )}

        {fieldsPermissions.sizeRange.view && (
          <FormInputNumber
            className="p-field p-col-3"
            name="sizeRange"
            label="Size Range"
            decimalScale={0}
            inputMode="numeric"
            thousandSeparator={false}
          />
        )}

        {fieldsPermissions.sellingPackage.view && (
          <>
            <FormInputNumber
              className="p-field p-col-5"
              name="sellingPackage"
              label="Selling Package (EAN-13)"
              max={9999999999999}
              decimalScale={0}
              type="text"
              inputMode="numeric"
              thousandSeparator={false}
            />
            <FormCheckbox
              className="p-d-flex p-as-center p-field p-col-2"
              name="auto"
              tooltip="Auto Generate Selling Package"
              label="Auto"
              initialValue={packageSizeBarcode.auto}
            />
          </>
        )}
      </div>

      {fieldsPermissions.sellingPackage.view && (
        <Barcode
          value={packageSizeBarcode.sellingPackage?.toString()}
          format="EAN13"
          downloadOnClick
          svgHeight={100}
          svgWidth={120}
          fontOptions="bold"
        />
      )}
    </div>
  );
};

export default PackageTypePack;
