import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DataTablePageParams, DataTableSortParams } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FiExternalLink } from 'react-icons/fi';
import { RadioButton } from 'primereact/radiobutton';
import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import Button from '../../../components/Button';
import ImageUpload, { IAttachmentUploadRef } from './AttachmentUpload';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../components/PageTabContainer';

import { Container } from './styles';
import {
  deletePartnerAttachmentsQuery,
  listPartnerAttachmentsQuery,
} from './queries';
import { PartnerAttachment, PartnerAttachmentsLazyParams } from './interfaces';
import pagination from '../../../config/pagination';
import { useRefHook } from '../../../hooks/useRefHook';
import Grid from '../../../components/Grid';
import { gridConstants } from '../../../components/Grid/constants';

interface IAttachmentProps extends PageTabContainerProps {
  changePropertyValue(property: string, value: any): void;
  idMainImage?: number | string;
  setUploadImageTempUrl: Dispatch<SetStateAction<string>>;
  idPartner: number;
}

const Attachments: React.FC<IAttachmentProps> = ({
  selected,
  changePropertyValue,
  idMainImage,
  setUploadImageTempUrl,
  idPartner,
}) => {
  const [deletePartnerAttachmentsMutation, { loading: deleteInProgress }] =
    useMutation(deletePartnerAttachmentsQuery);

  const attachmentUploadRef = useRef<IAttachmentUploadRef>(null);

  const { showError, showSuccess } = useRefHook();

  const [selectedImages, setSelectedImages] = useState<PartnerAttachment[]>([]);
  const [attachments, setAttachments] = useState<PartnerAttachment[]>();
  const [lazyParams, setLazyParams] = useState<PartnerAttachmentsLazyParams>(
    pagination.initialLazyParams,
  );

  const [
    loadAttachmentsData,
    {
      loading: attachmentsLoading,
      data: attachmentsData,
      refetch: attachmentsRefetch,
    },
  ] = useLazyQuery(listPartnerAttachmentsQuery, {
    // Garante que loading sera atualizado mesmo no refetch
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        idPartner,
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
      },
    },
    onCompleted: response => {
      if (response.listPartnerAttachments) {
        setAttachments([...response.listPartnerAttachments.data]);
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Partner Attachments',
        detail: errorData.message,
      });
    },
  });

  const imageBodyTemplate = (rowData: { imageUrl: string | undefined }) => {
    return rowData.imageUrl ? (
      <a
        href={`${rowData.imageUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        className="attachment"
      >
        Open
        <FiExternalLink />
      </a>
    ) : (
      <p> - </p>
    );
  };

  const mainColumnBody = (rowData: PartnerAttachment) => {
    return (
      <RadioButton
        value={rowData.idAttachment}
        checked={idMainImage === rowData.idAttachment}
        onChange={e => {
          changePropertyValue('idImage', e.value);
          setUploadImageTempUrl(rowData.imageUrl ? rowData.imageUrl : '');
        }}
      />
    );
  };

  /**
   * Deleta Attachments selecionadas
   */
  async function handleDeleteSelected() {
    try {
      await deletePartnerAttachmentsMutation({
        variables: {
          idsPartnerAttachments: selectedImages.map(a => a.idPartnerAttachment),
        },
      });

      const mainImageBeingDeleted = selectedImages.find(
        attachment => attachment.idAttachment === idMainImage,
      );

      if (mainImageBeingDeleted) {
        changePropertyValue('idImage', null);
        setUploadImageTempUrl('/');
      }

      showSuccess({
        summary: 'Attachments deleted',
        detail: `${selectedImages.length} attachments deleted`,
      });

      setSelectedImages([]);

      await attachmentsRefetch();
    } catch (error) {
      showError({
        summary: 'Error while deleting attachments',
        detail: error.message,
      });
    }
  }

  const gridHeader = (
    <div className="buttons-header">
      <Button
        label="Add Attachment"
        onClick={() => attachmentUploadRef.current?.toggleModal()}
      />
      <Button
        label="Delete selected"
        severity="danger"
        onClick={() => {
          confirmDialog({
            message: `Are you sure you want to delete ${selectedImages.length} attachments?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-question-circle',
            accept: () => handleDeleteSelected(),
          });
        }}
        disabled={!selectedImages.length}
        loading={deleteInProgress}
      />
    </div>
  );

  function onPage(event: DataTablePageParams) {
    setLazyParams({ ...lazyParams, ...event });
  }

  function onSort(event: DataTableSortParams) {
    setLazyParams({ ...lazyParams, ...event });
  }

  useEffect(() => {
    if (selected && !attachments) {
      loadAttachmentsData();
    }
  }, [attachments, loadAttachmentsData, selected]);

  return (
    <PageTabContainer selected={selected}>
      <ImageUpload
        ref={attachmentUploadRef}
        attachmentsRefetch={attachmentsRefetch}
        idPartner={idPartner}
      />
      <Container>
        <Grid
          className="s-internal-datatable"
          value={attachments}
          header={gridHeader}
          selection={selectedImages}
          onSelectionChange={e => setSelectedImages(e.value)}
          emptyMessage="No attachments found"
          selectionMode="checkbox"
          loading={attachmentsLoading}
          lazy
          rows={lazyParams.rows}
          first={lazyParams.first}
          totalRecords={attachmentsData?.listPartnerAttachments?.items ?? 0}
          scrollable
          scrollHeight={gridConstants.internalGridScrollHeight}
          onSort={onSort}
          onPage={onPage}
          removableSort
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
        >
          <Column
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
          <Column
            header="Main"
            style={{ width: '3em' }}
            body={mainColumnBody}
          />
          <Column
            field="name"
            header="Name"
            sortable
            headerStyle={{ flex: '1 1' }}
            style={{ flex: '1 1' }}
          />
          <Column
            field="imageUrl"
            header="Attachment"
            body={imageBodyTemplate}
          />
        </Grid>
      </Container>
    </PageTabContainer>
  );
};

export default Attachments;
