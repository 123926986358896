import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import FormInput from '../../../../../components/FormInput';
import FormAsyncSelect from '../../../../../components/FormAsyncSelect';
import { IProductBrands } from '../../interfaces';
import { useRefHook } from '../../../../../hooks/useRefHook';
import {
  createProductBrandQuery,
  loadBrandingOptions,
  updateProductBrandQuery,
} from './queries';
import { asyncSelectLoadClients } from '../../../../../shared/querys/client';
import { FileType } from '../../../../../shared/enums/fileType';
import FileUpload from '../../../../../components/FileUpload';
import Button from '../../../../../components/Button';
import { ProductBrandFormData } from './interfaces';
import FormInputNumber from '../../../../../components/FormInputNumber';

export interface IAddBrandRef {
  toggleModal(data?: IProductBrands): void;
}

interface IAddBrandProps {
  ref: Ref<IAddBrandRef>;
  idProduct: number;
  brandsRefetch(): Promise<ApolloQueryResult<any>>;
}

const AddBrand: React.FC<IAddBrandProps> = forwardRef(
  ({ idProduct, brandsRefetch }, ref) => {
    const formRef = useRef<FormHandles>(null);

    const { showSuccess, showError } = useRefHook();

    const [createProductBrandMutation, { loading: createLoading }] =
      useMutation(createProductBrandQuery);
    const [updateProductBrandMutation, { loading: updateLoading }] =
      useMutation(updateProductBrandQuery);

    const [displayModal, setDisplayModal] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<string | undefined>();
    const [initialFormData, setInitialFormData] = useState<IProductBrands>();
    const [selectedClient, setSelectedClient] = useState<number>();

    const loading = createLoading || updateLoading;
    const uploadFileButtonDisabled =
      !!uploadedFile || loading || !!initialFormData?.imageUrl;

    function closeModal() {
      setDisplayModal(false);
      setUploadedFile(undefined);
      setInitialFormData(undefined);
      setSelectedClient(undefined);
    }

    async function onSubmit(formData: ProductBrandFormData) {
      try {
        if (initialFormData) {
          await updateProductBrandMutation({
            variables: {
              data: {
                ...formData,
                uploadedFile,
              },
            },
          });

          showSuccess({ summary: 'Brand updated' });
        } else {
          await createProductBrandMutation({
            variables: {
              data: {
                idProduct,
                idClient: formData.idClient,
                idClientBrand: formData.idClientBrand,
                code: formData.code,
                uploadedFile,
              },
            },
          });

          showSuccess({ summary: 'Brand created' });
        }

        brandsRefetch();

        closeModal();
      } catch (error) {
        showError({
          summary: `Error while ${
            initialFormData ? 'updating' : 'creating'
          } brand`,
          detail: error.message,
        });
      }
    }

    useImperativeHandle(ref, () => ({
      toggleModal: (data?: IProductBrands) => {
        if (displayModal) {
          closeModal();
        } else {
          setDisplayModal(true);
          if (data) {
            setInitialFormData(data);
            setSelectedClient(data.idClient2?.idClient);
          }
        }
      },
    }));

    const dialogFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => formRef.current?.submitForm()}
            loading={loading}
          />
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={() => closeModal()}
            className="p-button-danger"
            severity="danger"
            disabled={loading}
          />
        </div>
      );
    };

    function onClientSelecionChange(idClient: number) {
      setSelectedClient(idClient);
      formRef.current?.clearField('idClientBrand');
    }

    return (
      <Dialog
        header="Manage Brand"
        visible={displayModal}
        style={{ width: '40vw' }}
        onHide={() => setDisplayModal(false)}
        closable={!loading}
        footer={dialogFooter()}
      >
        <Form ref={formRef} onSubmit={onSubmit} initialData={initialFormData}>
          <FormInputNumber name="idProductBrand" label="" hidden />
          <FormAsyncSelect
            className="p-field"
            name="idClient"
            label="Client"
            loadOptions={asyncSelectLoadClients}
            initialValue={initialFormData?.idClient2}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.idClient}
            noOptionsMessage={() => 'No clients found'}
            onValueChange={option => onClientSelecionChange(option.idClient)}
            menuPosition="fixed"
          />
          <FormAsyncSelect
            className="p-field"
            name="idClientBrand"
            label="Client Brand"
            loadOptions={loadBrandingOptions}
            initialValue={initialFormData?.idClientBrand2}
            getOptionLabel={option => option.brand}
            getOptionValue={option => option.idClientBrand}
            noOptionsMessage={() => 'No client brands found'}
            placeholder="Select a client brand"
            readOnly={!selectedClient}
            cacheUniqs={[selectedClient]}
            isSearchable={false}
            additional={{ idClient: selectedClient }}
            menuPosition="fixed"
          />
          <FormInput className="p-field" name="code" label="Client Item Code" />
          <FileUpload
            className="p-field"
            mode="basic"
            accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX}}`}
            auto
            chooseLabel={
              !!uploadedFile || !!initialFormData?.imageUrl
                ? 'File uploaded'
                : 'Upload a file'
            }
            disabled={uploadFileButtonDisabled}
            onConfirm={e => setUploadedFile(e[0].serverName)}
          />
        </Form>
      </Dialog>
    );
  },
);

export default AddBrand;
