import { gql, useQuery } from '@apollo/client';
import { isBefore } from 'date-fns';
import { Column } from 'primereact/column';
// import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import MainButton from '../../../components/MainButton';
import StatusTag from '../../../components/StatusTag';
import { useRefHook } from '../../../hooks/useRefHook';
import ToastLife from '../../../shared/enums/toastLife';
import { parsePrcCodeColumn } from '../../../utils/gridColumnsParse';

import { Container } from './styles';

const PrcWidget: React.FC = () => {
  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Redirect
  const history = useHistory();

  // Query para listar PRCs
  const listPrcsQuery = gql`
    query listPrcsWidgetQuery($pagination: Pagination!) {
      listPrcsWidget(pagination: $pagination) {
        items
        data {
          idPrc
          prcCode
          idStatus
          idStatus2 {
            idDomain
            description
          }
          mainProduct
          createdBy2 {
            firstName
            lastName
          }
          createdAt
          dtDeadline
          idResponsibleUser2 {
            firstName
            lastName
          }
        }
      }
    }
  `;

  /**
   * Busca PRCs
   */
  const { loading: prcsLoading, data: prcsData } = useQuery(listPrcsQuery, {
    variables: {
      pagination: {
        _page: 1,
        _limit: 35,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting PRCs',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const prcsSkeleton = Array.from({ length: 5 });

  const bodyTemplate = () => {
    return <Skeleton />;
  };

  return (
    <Container>
      <DataTable
        className="grid"
        value={!prcsData ? prcsSkeleton : prcsData.listPrcsWidget?.data}
        emptyMessage="No PRCs found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="275px"
        responsiveLayout="scroll"
        paginator={false}
        onRowClick={e => history.push(`/prcs/list/${e?.data?.idPrc}`)}
      >
        <Column
          columnKey="prcCode"
          field="prcCode"
          className="prcCodeColumn"
          header="PRC Code"
          sortable
          body={e =>
            prcsLoading || !e ? bodyTemplate() : parsePrcCodeColumn(e)
          }
        />
        <Column
          columnKey="idStatus2.description"
          field="idStatus2.description"
          header="Status"
          sortable
          body={e =>
            prcsLoading || !e ? (
              bodyTemplate()
            ) : (
              <StatusTag
                className="status-tag"
                domain={e?.idStatus2.idDomain}
                status={e?.idStatus2.description}
              />
            )
          }
        />
        <Column
          columnKey="mainProduct"
          field="mainProduct"
          header="Main Product"
          sortable
          body={e => (prcsLoading || !e ? bodyTemplate() : `${e?.mainProduct}`)}
        />
        <Column
          columnKey="currentUser2.firstName"
          field="currentUser2.firstName"
          header="Current User"
          sortable
          body={e =>
            prcsLoading || !e
              ? bodyTemplate()
              : e?.idResponsibleUser2 === null
              ? ''
              : `${e?.idResponsibleUser2?.firstName} ${e?.idResponsibleUser2?.lastName}`
          }
        />
        <Column
          columnKey="createdBy2.firstName"
          field="createdBy2.firstName"
          header="Created By"
          sortable
          body={e =>
            prcsLoading || !e
              ? bodyTemplate()
              : `${e?.createdBy2.firstName} ${e?.createdBy2.lastName}`
          }
        />
        <Column
          columnKey="createdAt"
          field="createdAt"
          header="Start Date"
          sortable
          body={e =>
            prcsLoading || !e
              ? bodyTemplate()
              : new Date(e?.createdAt).toLocaleDateString()
          }
        />
        <Column
          columnKey="dtDeadline"
          field="dtDeadline"
          header="Deadline"
          sortable
          body={e =>
            prcsLoading || !e ? (
              bodyTemplate()
            ) : (
              <p
                className={
                  isBefore(new Date(e?.dtDeadline), new Date())
                    ? 'expiredDate'
                    : undefined
                }
              >
                {new Date(e?.dtDeadline).toLocaleDateString()}
              </p>
            )
          }
        />
      </DataTable>
      <MainButton
        label="View All PRCs"
        onClick={() => history.push('/prcs/list')}
      />
    </Container>
  );
};

export default PrcWidget;
