import React from 'react';
import FormInputTextArea from '../../../../../../components/FormInputTextArea';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { ISat } from '../../../interfaces';
import { SatFinancialUsersSellerFieldsPermissions } from '../../interfaces';

import { Container } from './styles';

interface IUsersSellerProps {
  itemIndex: number;
  sat: ISat;
  fieldsPermissions: SatFinancialUsersSellerFieldsPermissions;
  userCanSee: boolean;
}

const UsersSeller: React.FC<IUsersSellerProps> = ({
  itemIndex,
  sat,
  fieldsPermissions,
  userCanSee,
}) => {
  return userCanSee ? (
    <Container className="p-grid">
      <FormTitle className="p-col-12" name="Users" />
      {fieldsPermissions.idSalesUser && (
        <ReadOnlyInput
          className="p-col-4"
          label="1st Commercial User"
          value={
            sat.idSalesUser2
              ? `${sat.idSalesUser2?.firstName} ${sat.idSalesUser2?.lastName}`
              : ''
          }
        />
      )}
      {fieldsPermissions.idSalesSecondUser && (
        <ReadOnlyInput
          className="p-col-4"
          label="2nd Commercial User"
          value={
            sat.idSalesSecondUser2
              ? `${sat.idSalesSecondUser2?.firstName} ${sat.idSalesSecondUser2?.lastName}`
              : undefined
          }
        />
      )}
      {fieldsPermissions.idComexImportUser && (
        <ReadOnlyInput
          className="p-col-4"
          label="Import User"
          value={
            sat.idComexImportUser2
              ? `${sat.idComexImportUser2?.firstName} ${sat.idComexImportUser2?.lastName}`
              : ''
          }
        />
      )}
      {fieldsPermissions.sellerNotes && (
        <FormInputTextArea
          className="p-col-12"
          label="Notes"
          name={`data[${itemIndex}].sellerNotes`}
          rows={4}
          cols={73}
          disabled={!fieldsPermissions.sellerNotes.edit}
        />
      )}
    </Container>
  ) : (
    <div />
  );
};

export default UsersSeller;
