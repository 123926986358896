/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FiExternalLink } from 'react-icons/fi';
import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import Button from '../../../../components/Button';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';

import { Container } from './styles';
import { IPrcAttachment } from '../interfaces';
import { useRefHook } from '../../../../hooks/useRefHook';
import ToastLife from '../../../../shared/enums/toastLife';
import { deletePrcAttachmentsQuery } from '../queries';
import { AttachmentTypes } from '../../../../shared/enums/attachmentType';
import { listPrcAttachmentsByIdPrcQuery } from '../Updates/queries';
import AttachmentsUpload from './AttachmentUpload';
import { IFilesUploadRef } from '../PrcUploadFiles';
import { downloadAttachmentsFromServerQuery } from '../../../../shared/querys/attachment';
import { StoragePathsTypes } from '../../../../shared/enums/storagePaths';

/**
 * Props do componente de Attachment
 */
interface IAttachmentProps extends PageTabContainerProps {
  /**
   * ID da PRC
   */
  idPrc: number;

  /**
   * Define estado de loading
   */
  setPageLoading: Dispatch<SetStateAction<boolean>>;
}

const Attachments: React.FC<IAttachmentProps> = ({
  selected,
  idPrc,
  setPageLoading,
}) => {
  // Attachments selecionados
  const [selectedAttachments, setSelectedAttachments] = useState<
    IPrcAttachment[]
  >([]);

  // Referencia ao toast e formulario
  const { toastRef } = useRefHook();

  // Attachments de PRC
  const [prcAttachments, setPrcAttachments] = useState<IPrcAttachment[]>();

  const filesUploadRef = useRef<IFilesUploadRef>(null);

  // cria método para chamar a mutation
  const [deletePrcAttachmentsMutation] = useMutation(deletePrcAttachmentsQuery);

  /**
   * Busca PRC Attachments
   */
  const [loadPrcFiles, { loading: attachmentsLoading }] = useLazyQuery(
    listPrcAttachmentsByIdPrcQuery,
    {
      variables: {
        data: {
          idPrc,
          idTypes: AttachmentTypes.ATTACHMENT_FILE_PRC,
        },
      },
      onCompleted: response => {
        setPrcAttachments(response.listAllPrcAttachments.data);
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting PRC Attachments',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  const imageBodyTemplate = (rowData: IPrcAttachment) => {
    return rowData.idAttachment2?.url ? (
      <a
        href={`${rowData.idAttachment2?.url}`}
        target="_blank"
        rel="noopener noreferrer"
        className="attachment"
      >
        Open
        <FiExternalLink />
      </a>
    ) : (
      <p>Attachment not found in the bucket</p>
    );
  };

  /**
   * Deleta Attachments selecionados
   */
  async function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedAttachments.length} attachments?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);

        try {
          await deletePrcAttachmentsMutation({
            variables: {
              idsPrcAttachments: selectedAttachments.map(
                attachment => attachment.idPrcAttachment,
              ),
            },
          });

          const newAttachments = prcAttachments?.filter(
            attachment => !selectedAttachments.includes(attachment),
          );

          setPrcAttachments(newAttachments);
          setSelectedAttachments([]);

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Attachments deleted',
            life: ToastLife.SUCCESS,
          });
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting attachments',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  const [downloadAttachments, { loading: prcFilesDownloadInProgress }] =
    useLazyQuery(downloadAttachmentsFromServerQuery, {
      onCompleted: response => {
        if (response.downloadAttachmentsFromServer) {
          window.open(response.downloadAttachmentsFromServer, '_blank');
        }
      },
      onError: error => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while downloading files',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      },
    });

  function handleDownloadFiles() {
    const prcAttachmentsSelected = selectedAttachments?.filter(attachment =>
      selectedAttachments.includes(attachment),
    );

    if (!prcAttachmentsSelected?.length) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'You need to select at least one item do download',
        life: ToastLife.ERROR,
      });

      return;
    }

    downloadAttachments({
      variables: {
        data: selectedAttachments?.map(attachment => {
          return {
            name: attachment.idAttachment2?.name,
            nameGivenByUser: attachment.idAttachment2?.nameGivenByUser,
            folder: StoragePathsTypes.PRC,
          };
        }),
      },
    });
  }

  const gridHeader = (
    <Container className="form-group p-grid p-flex-grid">
      <Button
        label="Add Attachment"
        onClick={() => filesUploadRef.current?.toggleModal()}
        type="button"
        style={{ marginRight: 10 }}
      />
      <Button
        type="button"
        label="Download"
        onClick={() => handleDownloadFiles()}
        disabled={!selectedAttachments.length}
        icon={
          prcFilesDownloadInProgress
            ? 'pi pi-spin pi-spinner'
            : 'pi pi-download'
        }
      />
      <Button
        label="Delete selected"
        severity="danger"
        onClick={() => handleDeleteSelected()}
        type="button"
        disabled={!selectedAttachments.length}
        style={{ marginLeft: 'auto' }}
      />
    </Container>
  );

  useEffect(() => {
    if (selected && !prcAttachments) {
      loadPrcFiles();
    }
  }, [loadPrcFiles, prcAttachments, selected]);

  return (
    <PageTabContainer selected={selected}>
      <AttachmentsUpload
        idPrc={idPrc}
        ref={filesUploadRef}
        prcFilesRefetch={loadPrcFiles}
      />
      <Container>
        <DataTable
          value={prcAttachments}
          header={gridHeader}
          selection={selectedAttachments}
          onSelectionChange={e => setSelectedAttachments(e.value)}
          emptyMessage="No attachments found"
          selectionMode="checkbox"
          loading={attachmentsLoading}
          removableSort
        >
          <Column
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
          <Column
            field="idAttachment2.nameGivenByUser"
            header="Name"
            sortable
            style={{ overflow: 'hidden' }}
          />
          <Column
            field="idAttachment2.url"
            header="Attachment"
            body={imageBodyTemplate}
          />
          <Column
            field="createdBy2.firstName"
            header="Created By"
            body={e =>
              `${e.idAttachment2.createdBy2.firstName} ${e.idAttachment2.createdBy2.lastName}`
            }
            sortable
          />
          <Column
            field="createdAt"
            header="Created At"
            body={e =>
              e.idAttachment2.createdAt
                ? new Date(e.idAttachment2.createdAt).toLocaleString()
                : ''
            }
            sortable
          />
        </DataTable>
      </Container>
    </PageTabContainer>
  );
};

export default Attachments;
