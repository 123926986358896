export const financialRoles = {
  idEntity: 50,
  idModule: 12,
  fields: {
    satCurrency: 1611,
    satNumber: 1612,
    satForeignTradeSupplierNumber: 1613,
    sunNumber: 1614,
    nameSupplier: 1615,
    countryOfOrigin: 1616,
    mainProduct: 1617,
    category: 1618,
    exporter: 1619,
    importerConsignee: 1620,
    clientNotify: 1621,
    clientOrder: 1622,
    statusSat: 1623,
    importStatus: 1624,
    shipmentStatus: 2209,
    supplierPaymentStatus: 1653,
    clientPaymentStatus: 1679,
    startDate: 1625,
    realEtd: 1626,
    realEta: 1627,
    purchaseIncoterm: 1628,
    purchaseTotalCotPi: 1629,
    purchaseTotalCotCi: 1630,
    exchangeRate: 1631,
    purchaseTotalConvertedCotPiUsd: 1632,
    purchaseTotalConvertedCotCiUsd: 1633,
    estimatedAdvance: 1634,
    estimatedBalance: 1635,
    purchaseEstimatedTermCondition: 1636,
    purchaseEstimatedTotalAdvance: 1637,
    estimatedAdvanceRequest: 1638,
    purchaseEstimatedTotalBalance: 1639,
    estimatedBalanceRequest: 1640,
    purchaseTotalOtherExpenses: 1641,
    purchaseTotalOnMargin: 1642,
    purchaseTotalOutOfMargin: 1643,
    paidTotalAdvance: 1644,
    paidTotalRolling: 1645,
    paidAdvancePaymentDate: 1646,
    paidTotalBalance: 1647,
    paidBalancePaymentDate: 1648,
    totalPaid: 1649,
    totalCotRemain: 1650,
    purchaseDiscountFormSupplier: 1651,
    purchasePayToSupplier: 1652,
    sellingIncoterm: 1654,
    sellerTotalSunPi: 1655,
    sellerTotalSunCi: 1656,
    sellerTotalConvertedSunPi: 1657,
    sellerTotalConvertedSunCi: 1658,
    totalWithApportionment: 1659,
    sellerReceiveEstimatedAdvanced: 1660,
    sellerReceiveEstimatedBalance: 1661,
    sellerPaymentTermCondition: 1662,
    sellerEstimatedTotalAdvance: 1663,
    sellerEstimatedAdvanceRequest: 1664,
    sellerEstimatedTotalBalance: 1665,
    sellerEstimatedBalanceRequest: 1666,
    sellerTotalOtherExpenses: 1667,
    sellerTotalOnMargin: 1668,
    sellerTotalOutOfMargin: 1669,
    receivedTotalAdvance: 1670,
    receivedTotalRolling: 1671,
    receivedAdvanceReceivedDate: 1672,
    receivedTotalBalance: 1673,
    receivedBalanceReceivedDate: 1674,
    totalReceived: 1675,
    totalRemainSun: 1676,
    sellerTotalCreditToClient: 1677,
    sellerTotalForApportionment: 1678,
    estimatedGrossMargin: 1680,
    estimatedGrossProfit: 1681,
    estimatedNetMargin: 1682,
    estimatedNetProfit: 1683,
    grossMargin: 1684,
    grossProfit: 1685,
    netMargin: 1686,
    netProfit: 1687,
    firstSalesUser: 1688,
    secondSalesUser: 1689,
    purchaseUser: 1690,
    exportUser: 1691,
    importUser: 1692,
    financialUser: 1693,
    purchaseNotes: 1694,
    sellerNotes: 1695,
    shipmentResume: 2142,
    exportStatus: 2151,
  },
  permissions: {
    idPermissionExportSatsFinancial: 181,
  },
};
