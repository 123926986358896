/* eslint-disable no-return-assign */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DataTablePageParams, DataTableSortParams } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FiExternalLink } from 'react-icons/fi';
import { RadioButton } from 'primereact/radiobutton';
import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import Button from '../../../../components/Button';
import AttachmentUpload, { IAttachmentUploadRef } from './AttachmentUpload';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';

import { Container } from './styles';
import { IClientAttachments } from '../interfaces';
import {
  deleteClientAttachmentsQuery,
  listClientAttachmentsQuery,
} from './queries';
import { ClientAttachmentsLazyParams } from './interfaces';
import pagination from '../../../../config/pagination';
import { useRefHook } from '../../../../hooks/useRefHook';
import Grid from '../../../../components/Grid';
import { gridConstants } from '../../../../components/Grid/constants';

interface IAttachmentProps extends PageTabContainerProps {
  idClient: number;
  changePropertyValue(property: string, value: any): void;
  idMainImage?: number | string;
  setUploadImageTempUrl: Dispatch<SetStateAction<string>>;
}

const Attachments: React.FC<IAttachmentProps> = ({
  selected,
  changePropertyValue,
  idMainImage,
  setUploadImageTempUrl,
  idClient,
}) => {
  const [deleteClientAttachmentsMutation, { loading: deleteInProgress }] =
    useMutation(deleteClientAttachmentsQuery);

  const attachmentUploadRef = useRef<IAttachmentUploadRef>(null);
  const { showError, showSuccess } = useRefHook();

  const [selectedImages, setSelectedImages] = useState<IClientAttachments[]>(
    [],
  );
  const [lazyParams, setLazyParams] = useState<ClientAttachmentsLazyParams>(
    pagination.initialLazyParams,
  );
  const [attachments, setAttachments] = useState<IClientAttachments[]>();

  const [
    loadAttachmentsData,
    {
      loading: attachmentsLoading,
      data: attachmentsData,
      refetch: attachmentsRefetch,
    },
  ] = useLazyQuery(listClientAttachmentsQuery, {
    variables: {
      // Garante que loading sera atualizado mesmo no refetch
      notifyOnNetworkStatusChange: true,
      data: {
        idClient,
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
      },
    },
    onCompleted: response => {
      if (response.listClientAttachments) {
        setAttachments([...response.listClientAttachments.data]);
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Client Attachments',
        detail: errorData.message,
      });
    },
  });

  const imageBodyTemplate = (rowData: { imageUrl: string | undefined }) => {
    return rowData.imageUrl ? (
      <a
        href={`${rowData.imageUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        className="attachment"
      >
        Open
        <FiExternalLink />
      </a>
    ) : (
      <p> - </p>
    );
  };

  const mainColumnBody = (rowData: IClientAttachments) => {
    return (
      <RadioButton
        value={rowData.idAttachment}
        checked={idMainImage === rowData.idAttachment}
        onChange={e => {
          changePropertyValue('idLogo', e.value);
          setUploadImageTempUrl(rowData.imageUrl ? rowData.imageUrl : '');
        }}
      />
    );
  };

  /**
   * Deleta Attachments selecionadas
   */
  async function handleDeleteSelected() {
    try {
      await deleteClientAttachmentsMutation({
        variables: {
          idsClientAttachments: selectedImages.map(a => a.idClientAttachment),
        },
      });
      const mainImageBeingDeleted = selectedImages.find(
        attachment => attachment.idAttachment === idMainImage,
      );

      if (mainImageBeingDeleted) {
        changePropertyValue('idLogo', null);
        setUploadImageTempUrl('/');
      }

      showSuccess({
        summary: 'Attachments deleted',
        detail: `${selectedImages.length} attachments deleted`,
      });

      setSelectedImages([]);

      await attachmentsRefetch();
    } catch (error) {
      showError({
        summary: 'Error while deleting attachments',
        detail: error.message,
      });
    }
  }

  const gridHeader = (
    <div className="buttons-header">
      <Button
        label="Add Attachment"
        onClick={() => attachmentUploadRef.current?.toggleModal()}
      />
      <Button
        label="Delete selected"
        severity="danger"
        onClick={() => {
          confirmDialog({
            message: `Are you sure you want to delete ${selectedImages.length} attachments?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-question-circle',
            accept: () => handleDeleteSelected(),
          });
        }}
        disabled={!selectedImages.length}
        loading={deleteInProgress}
      />
    </div>
  );

  function onPage(event: DataTablePageParams) {
    setLazyParams({ ...lazyParams, ...event });
  }

  function onSort(event: DataTableSortParams) {
    setLazyParams({ ...lazyParams, ...event });
  }

  useEffect(() => {
    if (selected && !attachments) {
      loadAttachmentsData();
    }
  }, [attachments, loadAttachmentsData, selected]);

  return (
    <PageTabContainer selected={selected}>
      <AttachmentUpload
        ref={attachmentUploadRef}
        idClient={idClient}
        attachmentsRefetch={attachmentsRefetch}
      />
      <Container>
        <Grid
          className="s-internal-datatable"
          value={attachments}
          header={gridHeader}
          loading={attachmentsLoading}
          lazy
          rows={lazyParams.rows}
          first={lazyParams.first}
          totalRecords={attachmentsData?.listClientAttachments?.items ?? 0}
          scrollable
          scrollHeight={gridConstants.internalGridScrollHeight}
          onSort={onSort}
          onPage={onPage}
          removableSort
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          selection={selectedImages}
          onSelectionChange={e => setSelectedImages(e.value)}
          emptyMessage="No attachments found"
          selectionMode="checkbox"
        >
          <Column
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
          <Column
            header="Main"
            style={{ width: '3em' }}
            body={mainColumnBody}
          />
          <Column
            field="name"
            header="Name"
            sortable
            headerStyle={{ flex: '1 1' }}
            style={{ flex: '1 1' }}
          />
          <Column
            field="imageUrl"
            header="Attachment"
            body={imageBodyTemplate}
          />
        </Grid>
      </Container>
    </PageTabContainer>
  );
};

export default Attachments;
