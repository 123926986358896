import { useLazyQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTablePageParams } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { Skeleton } from 'primereact/skeleton';
import React, {
  Dispatch,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { FiTrash2 } from 'react-icons/fi';
import FormTitle from '../../../../../../components/FormTitle';
import Grid from '../../../../../../components/Grid';
import { GridActions } from '../../../../../../components/Grid/styles';
import MainButton from '../../../../../../components/MainButton';
import pagination from '../../../../../../config/pagination';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { numberToPtString } from '../../../../../../utils/formatLocale';
import {
  parseCurrencyPtBrNumberColumn,
  parsePtBrNumberColumn,
} from '../../../../../../utils/gridColumnsParse';
import { IAvaillableSatItemsModalRef } from '../../AvailableSatItemsModal';
import {
  ForeignTradeContainerItemsPermissions,
  ForeignTradeReducer,
  ForeignTradeReducerAction,
  ForeignTradeReducerActionKind,
} from '../../interfaces';
import { SatForeignTradeShipmentContainer } from '../interfaces';
import {
  SatForeignTradeItem,
  SatForeignTradeItemContainer,
  SatForeignTradeItemsContainersTotals,
  SatForeignTradeItemsLazyParams,
} from './interfaces';
import {
  deleteSatForeignTradeItemsQuery,
  getSumDataFromSatForeignTradeItemsQuery,
  listSatForeignTradeItemsBySatForeignTradeIdQuery,
} from './queries';
import { Container } from './styles';
import { gridConstants } from '../../../../../../components/Grid/constants';
import { getAllRowsAsTrue } from '../../../../../../components/Grid/utils';
import SatItemReferences from './SatItemReferences';
import { ISatFinancialRef } from '../../../Financial';
import Button from '../../../../../../components/Button';
import { containerColumns } from './constants';

export interface IContainerItemsRef {
  refreshGridData(): Promise<void>;
}

interface IContainerItemsProps {
  idSatForeignTrade: number;
  selected: boolean;
  shipmentContainers?: SatForeignTradeShipmentContainer[];
  shipmentContainersLoading: boolean;
  foreignTradesDispatch: Dispatch<ForeignTradeReducerAction>;
  foreignTradesState: ForeignTradeReducer;
  containerItemsPermissions: ForeignTradeContainerItemsPermissions;
  userCanDeleteContainerItem: boolean;
  userCanAddContainerItem: boolean;
  addForeignTradeItemModalRef: React.RefObject<IAvaillableSatItemsModalRef>;
  ref: Ref<IContainerItemsRef>;
  shipmentIsCanceled: boolean;
  satCurrency?: string;
  financialRef: React.RefObject<ISatFinancialRef>;
  indConfection?: boolean;
}

const ContainerItems: React.FC<IContainerItemsProps> = React.forwardRef(
  (
    {
      idSatForeignTrade,
      selected,
      shipmentContainers,
      shipmentContainersLoading,
      foreignTradesDispatch,
      foreignTradesState,
      containerItemsPermissions,
      userCanDeleteContainerItem,
      addForeignTradeItemModalRef,
      userCanAddContainerItem,
      shipmentIsCanceled,
      satCurrency,
      financialRef,
      indConfection,
    },
    ref,
  ) => {
    const { showSuccess, showError } = useRefHook();
    /**
     * Chave de identificacao de cada linha da grid
     */
    const gridDataKey = 'idSatForeignTradeItem';

    // Parametros de paginacao/backend
    const [lazyParams, setLazyParams] =
      useState<SatForeignTradeItemsLazyParams>(pagination.initialLazyParams);

    const [containerItems, setContainerItems] =
      useState<SatForeignTradeItem[]>();

    const allGridRowsAsTrue = getAllRowsAsTrue(gridDataKey, containerItems);

    const [itemBeingDeleted, setItemBeingDeleted] = useState<number>();
    const [selectedItems, setSelectedItems] = useState<SatForeignTradeItem[]>(
      [],
    );

    const [deleteSatForeignTradeItemsMutation, { loading: deleteItemLoading }] =
      useMutation(deleteSatForeignTradeItemsQuery);

    const [
      loadContainerItemsData,
      { loading: containerItemsLoading, data: containerItemsData },
    ] = useLazyQuery(
      listSatForeignTradeItemsBySatForeignTradeIdQuery(
        containerItemsPermissions,
      ),
      {
        variables: {
          data: {
            idSatForeignTrade,
            pagination: {
              _page: lazyParams.page + 1,
              _limit: lazyParams.rows,
            },
          },
        },
        onCompleted: response => {
          if (response.listSatForeignTradeItemsBySatForeignTradeId) {
            setContainerItems([
              ...response.listSatForeignTradeItemsBySatForeignTradeId.data,
            ]);
          }
        },
        onError: errorData => {
          showError({
            summary: 'Error while getting Foreign Trade Container Items',
            detail: errorData.message,
          });
        },
      },
    );

    const [
      loadContainerItemsSumData,
      { loading: containerItemsSumLoading, data: containerItemsSumData },
    ] = useLazyQuery(getSumDataFromSatForeignTradeItemsQuery, {
      variables: {
        idSatForeignTrade,
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Foreign Trade Container Items Totals',
          detail: errorData.message,
        });
      },
    });

    const loadGridData = useCallback(async () => {
      loadContainerItemsData();
      loadContainerItemsSumData();
    }, [loadContainerItemsData, loadContainerItemsSumData]);

    useImperativeHandle(ref, () => ({
      refreshGridData: async () => {
        return loadGridData();
      },
    }));

    async function handleDeleteItems(idsSatForeignTradeItems: number[]) {
      if (idsSatForeignTradeItems.length === 1) {
        setItemBeingDeleted(idsSatForeignTradeItems[0]);
      }

      try {
        await deleteSatForeignTradeItemsMutation({
          variables: {
            idsSatForeignTradeItems,
          },
        });

        await loadGridData();

        // Verifica se é o ultimo item, se for, recarrega a aba de financial
        if (containerItems?.length === 1) {
          financialRef.current?.refreshForm();
        }

        showSuccess({
          summary: 'Container Items deleted',
        });
      } catch (error) {
        showError({
          summary: 'Error while deleting Container Items',
          detail: error.message,
        });
      } finally {
        setItemBeingDeleted(undefined);
        setSelectedItems([]);
      }
    }

    const gridActions = (rowData: SatForeignTradeItem) => {
      const isBeingDeleted = itemBeingDeleted === rowData.idSatForeignTradeItem;

      return (
        <GridActions>
          <button
            type="button"
            className="trash-button"
            onClick={() =>
              confirmDialog({
                message: 'Are you sure you want to delete this item?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: () =>
                  handleDeleteItems([rowData.idSatForeignTradeItem]),
              })
            }
            disabled={!!itemBeingDeleted || deleteItemLoading}
          >
            {isBeingDeleted ? (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: '1.43rem' }}
              />
            ) : (
              <FiTrash2 size={20} />
            )}
          </button>
        </GridActions>
      );
    };

    function getContainerReferenceQuantitySum(
      satForeignTradeItemCtnrs: SatForeignTradeItemContainer[],
      idSatForeignTradeCtnr: number,
      field: string,
    ) {
      const containers = satForeignTradeItemCtnrs?.filter(
        container =>
          container.idSatForeignTradeCtnr === idSatForeignTradeCtnr &&
          !!container.idSatForeignTradeItemReference,
      );

      const containersQuantitySum = containers?.reduce(
        (acc, curr) =>
          acc + (curr[field as keyof SatForeignTradeItemContainer] ?? 0),
        0,
      );

      return numberToPtString(containersQuantitySum);
    }

    const totalColumnFooter = (
      key: string,
      currency?: boolean,
      decimalPlaces?: number,
    ) => {
      if (containerItemsSumLoading) return <Skeleton />;

      if (!containerItemsSumData?.getSumDataFromSatForeignTradeItems[key]) {
        return '';
      }

      return `${currency ? '$ ' : ''}${numberToPtString(
        containerItemsSumData?.getSumDataFromSatForeignTradeItems[key],
        decimalPlaces || (currency ? 2 : 0),
      )}`;
    };

    const totalContainerColumnFooter = (
      idSatForeignTradeCtnr: number,
      field: string,
    ) => {
      if (containerItemsSumLoading) return <Skeleton />;

      const containerData: SatForeignTradeItemsContainersTotals =
        containerItemsSumData?.getSumDataFromSatForeignTradeItems.containersTotals.find(
          (container: SatForeignTradeItemsContainersTotals) =>
            container.idSatForeignTradeCtnr === idSatForeignTradeCtnr,
        );

      switch (field) {
        case 'quantityCtnr':
          return numberToPtString(containerData?.quantityCtnrSum);
        case 'grossWeight':
          return numberToPtString(containerData?.grossWeightSum);
        case 'netWeight':
          return numberToPtString(containerData?.netWeightSum);
        case 'cbm':
          return numberToPtString(containerData?.cbmSum);
        default:
          return undefined;
      }
    };

    /**
     * Gera ID do input para a grid
     * @param field Coluna da grid
     * @param rowIndex Indice do objeto da grid
     * @returns ID para o Input da grid
     */
    function getInputId(field: string, rowIndex: number) {
      return `containerItems-${idSatForeignTrade}-${field}-${rowIndex}`;
    }

    /**
     * Gera foco no proximo input ao pressionar ENTER
     * @param e Evento do teclado
     * @param field Coluna da grid
     * @param index Indice da grid
     */
    function handleGoToNextField(
      e: React.KeyboardEvent<HTMLInputElement>,
      field: string,
      index: number,
    ) {
      if (e.key === 'Enter') {
        const inputId = getInputId(field, index + 1);
        document.getElementById(inputId)?.focus();
      }
    }

    const rowExpansionTemplate = (
      data: SatForeignTradeItem,
      props: { index: number },
    ) => {
      return (
        <SatItemReferences
          foreignTradeItem={data}
          shipmentContainers={shipmentContainers}
          foreignTradesDispatch={foreignTradesDispatch}
          containerItemsPermissions={containerItemsPermissions}
          userCanDeleteContainerItem={userCanDeleteContainerItem}
          shipmentIsCanceled={shipmentIsCanceled}
          satCurrency={satCurrency}
          containerItemsRowIndex={props.index}
        />
      );
    };

    const calculateActualRowIndex = useCallback(
      (itemIndex: number) => {
        return itemIndex - lazyParams.rows * lazyParams.page;
      },
      [lazyParams.page, lazyParams.rows],
    );

    const onNumberEditorValueChange = useCallback(
      (props, value) => {
        const rowIndex = calculateActualRowIndex(props.rowIndex);
        const updatedItem = props.props.value[rowIndex];
        updatedItem[props.field] = Number(value);

        foreignTradesDispatch({
          type: ForeignTradeReducerActionKind.SET_GRID_ITEM_TO_UPDATE,
          payload: {
            itemToUpdate: updatedItem,
          },
        });
      },
      [foreignTradesDispatch, calculateActualRowIndex],
    );

    const renderNumberEditor = (props: any, decimalPlaces?: number) => {
      const isCancelledOrWithoutPermission =
        !containerItemsPermissions[
          props.field as keyof ForeignTradeContainerItemsPermissions
        ].edit || shipmentIsCanceled;

      if (
        props.rowData.satItemReferences?.length ||
        isCancelledOrWithoutPermission
      ) {
        return props.rowData[props.field];
      }

      return (
        <InputNumber
          inputId={getInputId(props.field, props.rowIndex)}
          value={props.rowData[props.field]}
          onChange={e => {
            onNumberEditorValueChange(props, e.value);
          }}
          onKeyDown={e => handleGoToNextField(e, props.field, props.rowIndex)}
          locale="pt-BR"
          min={0}
          maxFractionDigits={decimalPlaces || 0}
          allowEmpty={false}
        />
      );
    };

    const renderContainerEditor = (
      props: any,
      idSatForeignTradeCtnr: number,
      fractionDigits: number,
    ) => {
      if (props.rowData.satItemReferences?.length) {
        return getContainerReferenceQuantitySum(
          props.rowData.satForeignTradeItemCtnrs,
          idSatForeignTradeCtnr,
          props.field,
        );
      }

      const containerItem = props.rowData.satForeignTradeItemCtnrs?.find(
        (itemContainer: SatForeignTradeItemContainer) =>
          itemContainer.idSatForeignTradeCtnr === idSatForeignTradeCtnr &&
          !itemContainer.idSatForeignTradeItemReference,
      );

      if (
        !containerItemsPermissions[
          props.field as keyof ForeignTradeContainerItemsPermissions
        ].edit ||
        shipmentIsCanceled
      ) {
        return containerItem ? numberToPtString(containerItem[props.field]) : 0;
      }

      const inputIdField = `container-${idSatForeignTradeCtnr}-${props.field}`;

      return (
        <InputNumber
          inputId={getInputId(inputIdField, props.rowIndex)}
          value={containerItem ? containerItem[props.field] : 0}
          onChange={e =>
            foreignTradesDispatch(
              containerItem
                ? {
                    type: ForeignTradeReducerActionKind.SET_ITEM_CONTAINER_TO_UPDATE,
                    payload: {
                      itemContainerToUpdate: {
                        idSatForeignTrade,
                        idSatForeignTradeItemCtnr:
                          containerItem.idSatForeignTradeItemCtnr,
                        quantityCtnr: containerItem.quantityCtnr ?? 0,
                        grossWeight: containerItem.grossWeight,
                        netWeight: containerItem.netWeight,
                        cbm: containerItem.cbm,
                        [props.field]: Number(e.value),
                        fieldUpdated: props.field,
                      },
                    },
                  }
                : {
                    type: ForeignTradeReducerActionKind.SET_ITEM_CONTAINER_TO_CREATE,
                    payload: {
                      itemContainerToCreate: {
                        idSatForeignTrade,
                        idSatForeignTradeCtnr,
                        idSatForeignTradeItem:
                          props.rowData.idSatForeignTradeItem,
                        quantityCtnr: 0,
                        grossWeight: 0,
                        netWeight: 0,
                        cbm: 0,
                        [props.field]: Number(e.value),
                        fieldUpdated: props.field,
                      },
                    },
                  },
            )
          }
          onKeyDown={e => handleGoToNextField(e, inputIdField, props.rowIndex)}
          locale="pt-BR"
          min={0}
          allowEmpty={false}
          minFractionDigits={fractionDigits}
          maxFractionDigits={fractionDigits}
        />
      );
    };

    function changeLazyParams(newLazyParams: SatForeignTradeItemsLazyParams) {
      // Antes de efetuar a paginacao, valida se usuario alterou algo na grid
      const itemContainersToUpdate =
        foreignTradesState.itemContainersToUpdate.some(
          item => item.idSatForeignTrade === idSatForeignTrade,
        );

      const itemContainersToCreate =
        foreignTradesState.itemContainersToCreate.some(
          item => item.idSatForeignTrade === idSatForeignTrade,
        );

      const userHaveSomethingNotSaved =
        itemContainersToUpdate || itemContainersToCreate;

      if (userHaveSomethingNotSaved) {
        confirmDialog({
          message: 'You will lost any items information not saved',
          header: 'Are you sure you want to proceed?',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () => {
            setLazyParams(newLazyParams);

            foreignTradesDispatch({
              type: ForeignTradeReducerActionKind.REMOVE_UPDATES_AND_CREATES_FROM_FOREIGN_TRADE,
              payload: {
                idForeignTradeToRemoveEntries: idSatForeignTrade,
              },
            });
          },
        });
      } else {
        setLazyParams(newLazyParams);
      }
    }

    /**
     * Ao mudar pagina da tabela, muda os parametros de busca no backend
     * @param event Parametros de paginacao da tabela
     */
    const onPage = (event: DataTablePageParams) => {
      const newLazyParams = { ...lazyParams, ...event };
      changeLazyParams(newLazyParams);
    };

    const renderContainerColuns = shipmentContainers?.map(container => {
      return containerColumns.map(column => {
        if (
          !containerItemsPermissions[
            column.field as keyof ForeignTradeContainerItemsPermissions
          ].view
        ) {
          return undefined;
        }

        return (
          <Column
            key={`${container.idSatForeignTradeCtnr}-${column.field}`}
            field={column.field}
            header={`${column.header} ${container.containerName}`}
            headerClassName={column.field}
            style={{ width: '8rem' }}
            editor={e =>
              renderContainerEditor(
                e,
                container.idSatForeignTradeCtnr,
                column.fractionDigits,
              )
            }
            footer={() =>
              totalContainerColumnFooter(
                container.idSatForeignTradeCtnr,
                column.field,
              )
            }
            footerClassName={column.field}
          />
        );
      });
    });

    useEffect(() => {
      if (!containerItems && selected) {
        loadGridData();
      }
    }, [containerItems, loadGridData, selected]);

    return (
      <Container className="form-group">
        <FormTitle name="Container Items">
          {userCanAddContainerItem && !shipmentIsCanceled && (
            <MainButton
              type="button"
              onClick={() => addForeignTradeItemModalRef.current?.toggleModal()}
            >
              Add Item
            </MainButton>
          )}

          {userCanDeleteContainerItem && !shipmentIsCanceled && (
            <Button
              label="Delete selected"
              className="p-button-danger"
              severity="danger"
              disabled={!selectedItems.length}
              loading={deleteItemLoading && !!selectedItems.length}
              onClick={() =>
                confirmDialog({
                  message: `Are you sure you want to delete ${selectedItems.length} items?`,
                  header: 'Delete Confirmation',
                  icon: 'pi pi-info-circle',
                  acceptClassName: 'p-button-danger',
                  accept: () =>
                    handleDeleteItems(
                      selectedItems.map(item => item.idSatForeignTradeItem),
                    ),
                })
              }
            />
          )}
        </FormTitle>

        <Grid
          className="s-expanded-datatable s-internal-datatable"
          emptyMessage="No container items found"
          loading={containerItemsLoading || shipmentContainersLoading}
          value={containerItems}
          lazy
          rows={lazyParams.rows}
          first={!containerItems ? undefined : lazyParams.first}
          totalRecords={
            !containerItemsData
              ? 0
              : containerItemsData?.listSatForeignTradeItemsBySatForeignTradeId
                  ?.items
          }
          scrollable
          scrollHeight={gridConstants.internalGridScrollHeight}
          onPage={onPage}
          expandedRows={allGridRowsAsTrue}
          rowExpansionTemplate={rowExpansionTemplate}
          editMode="row"
          dataKey={gridDataKey}
          editingRows={allGridRowsAsTrue}
          onRowEditChange={() => ''}
          selectionMode="checkbox"
          selection={selectedItems}
          onSelectionChange={e => setSelectedItems(e.value)}
          selectionAutoFocus={false}
        >
          {userCanDeleteContainerItem && !shipmentIsCanceled && (
            <Column
              field="multiple"
              selectionMode="multiple"
              style={{ width: '2em' }}
            />
          )}

          {userCanDeleteContainerItem && !shipmentIsCanceled && (
            <Column
              field="actions"
              style={{ width: '4rem' }}
              editor={e => gridActions(e.rowData)}
            />
          )}

          {containerItemsPermissions.stCode.view && (
            <Column
              field="idSatItem2.stCode"
              header="ST Code"
              style={{ width: '7rem' }}
              footer="TOTAL"
            />
          )}
          {containerItemsPermissions.quantity.view && (
            <Column
              field="idSatItem2.quantity"
              header="Qty PI"
              style={{ width: '7rem' }}
              footer={() => totalColumnFooter('quantityPiItemsSum')}
            />
          )}

          {containerItemsPermissions.quantityPiShipment.view && (
            <Column
              field="quantityPiShipment"
              header="Qty PI Shipment"
              style={{ width: '7rem' }}
              editor={props => renderNumberEditor(props)}
              footer={() => totalColumnFooter('quantityPiShipmentSum')}
            />
          )}

          {indConfection && containerItemsPermissions.sizeRange.view && (
            <Column
              field="sizeRange"
              header="Size Range"
              style={{ width: '7rem' }}
            />
          )}
          {containerItemsPermissions.quantityCi.view && (
            <Column
              field="quantityCi"
              header="Qty CI"
              style={{ width: '7rem' }}
              editor={e => parsePtBrNumberColumn(e.rowData, e, 0)}
              footer={() => totalColumnFooter('quantityCiItemsSum')}
            />
          )}
          {containerItemsPermissions.cotPrice.view && (
            <Column
              field="cotPrice"
              header="COT Price"
              style={{ width: '7rem' }}
              editor={e =>
                parseCurrencyPtBrNumberColumn(e.rowData, e, 3, satCurrency)
              }
            />
          )}
          {containerItemsPermissions.sunPrice.view && (
            <Column
              field="sunPrice"
              header="SUN Price"
              style={{ width: '7rem' }}
              editor={e =>
                parseCurrencyPtBrNumberColumn(e.rowData, e, 3, satCurrency)
              }
            />
          )}

          {containerItemsPermissions.totalCotCi.view && (
            <Column
              field="totalCotCi"
              header="Total COT CI"
              style={{ width: '8rem' }}
              editor={e =>
                parseCurrencyPtBrNumberColumn(e.rowData, e, 2, satCurrency)
              }
              footer={() => totalColumnFooter('totalCotCiItemsSum', true)}
            />
          )}

          {containerItemsPermissions.totalSunCi.view && (
            <Column
              field="totalSunCi"
              header="Total SUN CI"
              style={{ width: '8rem' }}
              editor={e =>
                parseCurrencyPtBrNumberColumn(e.rowData, e, 2, satCurrency)
              }
              footer={() => totalColumnFooter('totalSunCiItemsSum', true)}
            />
          )}

          {containerItemsPermissions.piecesContainer.view && (
            <Column
              field="idSatItem2.piecesContainer"
              header="PCS/CTN"
              style={{ width: '7rem' }}
            />
          )}

          {containerItemsPermissions.remainQuantityPiShipment.view && (
            <Column
              field="idSatItem2.remainQuantityPiShipment"
              header="Remain Quantity"
              style={{ width: '7rem' }}
              footer={() => totalColumnFooter('remainQuantityPiShipmentSum')}
            />
          )}

          {renderContainerColuns}
        </Grid>
      </Container>
    );
  },
);

export default ContainerItems;
