import React, { Ref, forwardRef, useEffect, useImperativeHandle } from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import {
  Container,
  BoardContainer,
  DashboardColumn,
  DashboardCardList,
  LoadMoreButton,
  DashboardColumnContainer,
} from './styles';
import pagination from '../../config/pagination';
import updateLocalStorageInDb from '../../utils/updateLocalStorageInDb';

export interface IDashboardRef {
  getLocalStorageData(): { globalSearch?: string } | undefined;
}

interface IDashboardProps {
  ref?: Ref<IDashboardRef>;
  loading: boolean;
  columns: any[];
  cardTemplate(card: any, index: number): React.ReactNode;
  loadMore(idColumn: number, cardsLength: number): void;
  columnLoadingMore?: number;
  columnIdKey: string;
  columnTitleKey: string;
  columnCardsKey: string;
  name?: string;
  globalSearch?: string;
}

const Dashboard: React.FC<IDashboardProps> = forwardRef(
  (
    {
      loading,
      columns,
      cardTemplate,
      loadMore,
      columnLoadingMore,
      columnIdKey,
      columnTitleKey,
      columnCardsKey,
      name,
      globalSearch,
    },
    ref,
  ) => {
    const dasboardName = name ? `@SAT:${name}Dashboard` : undefined;

    function renderLoadMoreButton(statusDataLength: number, idColumn: number) {
      if (
        !statusDataLength ||
        statusDataLength % pagination.itemsPerPage !== 0
      ) {
        return undefined;
      }

      const someColumnIsLoadingMore = columnLoadingMore !== undefined;

      return (
        <LoadMoreButton disabled={someColumnIsLoadingMore}>
          <span className="s-load-more-content">
            {columnLoadingMore === idColumn ? (
              <ProgressSpinner
                style={{ width: '20px', height: '20px' }}
                strokeWidth="5"
              />
            ) : (
              <button
                type="button"
                onClick={() => loadMore(idColumn, statusDataLength)}
                disabled={someColumnIsLoadingMore}
              >
                Load more
              </button>
            )}
          </span>
        </LoadMoreButton>
      );
    }

    useImperativeHandle(ref, () => ({
      getLocalStorageData: () => {
        if (!dasboardName) {
          return undefined;
        }

        const data = localStorage.getItem(dasboardName);

        return JSON.parse(data || '{}');
      },
    }));

    useEffect(() => {
      if (dasboardName) {
        const emptyObject = '{}';
        const currentLocalStorageData = localStorage.getItem(dasboardName);
        const newLocalStorageObjectData = {
          ...JSON.parse(currentLocalStorageData || emptyObject),
          globalSearch,
        };
        const newLocalStorageData = JSON.stringify(newLocalStorageObjectData);

        if (
          currentLocalStorageData === newLocalStorageData ||
          newLocalStorageData === emptyObject
        ) {
          return undefined;
        }

        const delayDebounceFn = setTimeout(() => {
          localStorage.setItem(dasboardName, newLocalStorageData);
          updateLocalStorageInDb(dasboardName, newLocalStorageObjectData);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      }

      return undefined;
    }, [dasboardName, globalSearch]);

    return (
      <Container>
        <div>
          <BoardContainer>
            {!loading &&
              columns.map(column => (
                <DashboardColumn key={column[columnIdKey]}>
                  <DashboardColumnContainer>
                    <div className="w-full flex justify-content-between">
                      <span className="dashboard-column-title">
                        {column[columnTitleKey]}
                      </span>
                      <span className="dashboard-column-title">
                        {column.items ?? ''}
                      </span>
                    </div>
                    <DashboardCardList>
                      {column[columnCardsKey].map((card: any, index: number) =>
                        cardTemplate(card, index),
                      )}

                      {renderLoadMoreButton(
                        column[columnCardsKey].length,
                        column[columnIdKey],
                      )}
                    </DashboardCardList>
                  </DashboardColumnContainer>
                </DashboardColumn>
              ))}

            {loading &&
              [...Array(7)].map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <DashboardColumn key={index}>
                  <Skeleton />
                </DashboardColumn>
              ))}
          </BoardContainer>
        </div>
      </Container>
    );
  },
);

export default Dashboard;
