/* eslint-disable no-return-assign */
import React, { Dispatch, SetStateAction, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Container } from './styles';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import Button from '../../../../components/Button';
import AddContact from './AddContact';
import { IClientContact } from '../interfaces';
import generateRandomString from '../../../../utils/generateRandomString';
import { useRefHook } from '../../../../hooks/useRefHook';

interface IContactProps extends PageTabContainerProps {
  clientContacts: IClientContact[];
  setClientContacts: Dispatch<SetStateAction<IClientContact[]>>;
}

const Contact: React.FC<IContactProps> = ({
  selected,
  clientContacts,
  setClientContacts,
}) => {
  // Referencia ao formulario
  const { formRef } = useRefHook();

  // Contacts selecionados
  const [selectedContacts, setSelectedContacts] = useState<IClientContact[]>(
    [],
  );

  // Contact utilizado na modal
  const [newContact, setNewContact] = useState<IClientContact>(
    {} as IClientContact,
  );

  // Estado para exibir modal
  const [displayImageModal, setDisplayImageModal] = useState(false);

  /**
   * Exibe modal
   */
  const onClick = () => {
    setNewContact({ ...newContact, idClientContact: generateRandomString(4) });
    setDisplayImageModal(true);
  };

  /**
   * Oculta modal
   */
  const onHide = () => {
    setDisplayImageModal(false);
  };

  function onConfirmDialog() {
    if (!newContact.name) {
      formRef.current?.setFieldError('nameContact', 'Contact name is required');
      return;
    }

    if (!newContact.idRoleContact2) {
      formRef.current?.setFieldError('role', 'Contact Role is required');
      return;
    }

    // Busca indice do item editado
    const itemAlreadExists = clientContacts.findIndex(
      item => item.idClientContact === newContact.idClientContact,
    );

    // Valida se item ja existe na lista
    if (itemAlreadExists !== -1) {
      // Se existir, atualiza informacoes
      const newItems = [...clientContacts];
      newItems[itemAlreadExists] = newContact;
      setClientContacts(newItems);
    } else {
      // Se nao, adiciona na lista
      setClientContacts([...clientContacts, newContact]);
    }
    setNewContact({} as IClientContact);
    onHide();
  }

  /**
   * Footer da modal
   * @returns Footer
   */
  const dialogFooter = () => {
    return (
      <div style={{ display: 'flex', placeContent: 'end' }}>
        <Button
          label="Confirm"
          icon="pi pi-check"
          onClick={() => {
            onConfirmDialog();
          }}
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => {
            setNewContact({} as IClientContact);
            onHide();
          }}
          className="p-button-danger"
          severity="danger"
        />
      </div>
    );
  };

  /**
   * Deleta contacts selecionados
   */
  function handleDeleteSelected() {
    const newContacts = clientContacts.filter(
      contact => !selectedContacts.includes(contact),
    );

    setClientContacts(newContacts);
    setSelectedContacts([]);
  }

  /**
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: any) {
    if (e.originalEvent.target.className.includes('p-checkbox')) {
      return;
    }

    setNewContact(e.data);
    setDisplayImageModal(true);
  }

  const gridHeader = (
    <div className="buttons-header">
      <Button label="Add Contact" onClick={() => onClick()} />
      <Button
        label="Delete selected"
        severity="danger"
        onClick={() => handleDeleteSelected()}
        disabled={!selectedContacts.length}
      />
    </div>
  );

  return (
    <PageTabContainer selected={selected}>
      <Dialog
        header="Add a Contact"
        visible={displayImageModal}
        style={{ width: '40vw' }}
        onHide={() => onHide()}
        closable={false}
        footer={dialogFooter()}
      >
        <AddContact setNewContact={setNewContact} newContact={newContact} />
      </Dialog>
      <Container>
        <DataTable
          value={clientContacts}
          header={gridHeader}
          selection={selectedContacts}
          onSelectionChange={e => setSelectedContacts(e.value)}
          emptyMessage="No contacts found"
          onRowClick={onRowClick}
          selectionMode="checkbox"
        >
          <Column
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
          <Column field="idRoleContact2.description" header="Role" sortable />
          <Column field="name" header="Name" sortable />
          <Column field="department" header="Department" sortable />
          <Column field="area" header="Area" sortable />
          <Column field="email" header="E-mail" sortable />
          <Column field="telephone" header="Telephone" sortable />
        </DataTable>
      </Container>
    </PageTabContainer>
  );
};

export default Contact;
