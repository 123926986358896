/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTable,
  DataTableMultiSortMetaType,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortParams,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import { gridConstants } from '../../../components/Grid/constants';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import useTitle from '../../../hooks/useTitle';
import ILazyParams from '../../../services/lazyParams';
import ToastLife from '../../../shared/enums/toastLife';
import { icmsRoles } from '../../../shared/roles/icms';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import saveFileAsExcel from '../../../utils/saveFileAsExcel';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';
import { gridColumnsData } from './contants';
import { Container } from './styles';

/**
 * Interface dos parametros do backend
 */
interface IIcmsLazyParams extends ILazyParams {
  [key: string]: number | string | DataTableMultiSortMetaType | undefined;
  icmsName?: string;
  client?: string;
  region?: string;
  destinacao?: string;
  icmsBase?: number;
  icmsRecolhido?: number;
}

const pageTitle = 'List of ICMS';

const IcmsList: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:icmsGridColumns';

  const lazyParamsName = '@SAT:icmsLazyParams';

  const advancedFiltersName = '@SAT:icmsAdvancedFilters';

  // Redirect
  const history = useHistory();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia a grid
  const gridRef = useRef<DataTable>(null);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // URL params
  const location = useLocation();

  // Permissions de ICMS
  const { idPermissionDeleteIcms, idPermissionCreateIcms } =
    icmsRoles.permissions;

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsIcms = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    icmsRoles.idEntity,
  );

  // Colunas da grid
  const columns = useMemo(() => {
    return (
      Object.entries(gridColumnsData)
        // .filter(column => fieldsPermissions[column[1].field].view)
        .map(column => column[1])
    );
  }, []);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  // Busca dados de pagina inicial da url
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );

  // Busca dados de quantidade da url
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  // Estado inicial de lazy params
  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IIcmsLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: IIcmsLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: IIcmsLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  // ICMS selecionados
  const [selectedIcms, setSelectedIcms] = useState([]);

  // Colunas da grid que possuem filtro aplicado
  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  // Estado de scrollHeight da grid
  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );
  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar ICMS
  const listIcmsQuery = gql`
    query listAllIcmsQuery($data: ListAllIcmsInput!) {
      listAllIcms(data: $data) {
        data {
          idIcms
          icmsName
          idRegion2 {
            name
          }
          idDestinacao2 {
            description
          }
          idClient2 {
            name
          }
          icmsBase
          icmsRecolhido
        }
        items
      }
    }
  `;

  // Query para deletar ICMS
  const deleteIcmsQuery = gql`
    mutation DisableIcms($disableIcmsIds: [Int]!) {
      disableIcms(ids: $disableIcmsIds)
    }
  `;

  // cria método para chamar a mutation
  const [deleteIcms] = useMutation(deleteIcmsQuery);

  /**
   * Busca ICMS
   */
  const {
    loading: icmsLoading,
    data: icmsData,
    refetch: icmsRefetch,
  } = useQuery(listIcmsQuery, {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        globalSearch: lazyParams.globalFilter,
        icmsName: lazyParams.icmsName,
        client: lazyParams.client,
        region: lazyParams.region,
        destinacao: lazyParams.destinacao,
        icmsBase: lazyParams.icmsBase,
        icmsRecolhido: lazyParams.icmsRecolhido,
        active: true,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting ICMS',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Valida se coluna eh de porcentagem
   * @param rowData Dados da linha
   * @returns Campo com porcentagem
   */
  const parsePercentageColumn = (rowData: any, field: any) => {
    return (
      <p>{`${rowData[field.field]?.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`}</p>
    );
  };

  /**
   * Valida se coluna eh do nome do cliente
   * @param rowData Dados da linha
   * @returns Campo com porcentagem
   */
  const parseClientNameColumn = (rowData: any) => {
    return <p>{rowData.idClient2?.name ?? 'ALL CLIENTS'}</p>;
  };

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'icmsBase':
      case 'icmsRecolhido':
        return parsePercentageColumn;
      case 'idClient2.name':
        return parseClientNameColumn;
      default:
        return undefined;
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={{ width: '318px' }}
          body={handleColumn(col.field)}
          sortable
        />
      )
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeParams) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do icms clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(
      `/list/icms/${e.data.idIcms}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortParams) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Deleta ICMS selecionados
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedIcms.length} ICMS?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada ICMS
        const idsToDelete = selectedIcms.map((a: any) => a.idIcms);
        try {
          // Deleta ICMS
          await deleteIcms({
            variables: {
              disableIcmsIds: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedIcms.length} ICMS`,
            life: ToastLife.SUCCESS,
          });

          // Zera estado de ICMS selecionadas
          setSelectedIcms([]);

          // Atualiza grid de ICMS
          await icmsRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting ICMS',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Exporta dados para xlsx
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function exportToXlsx(icmsExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    icmsExportData.listAllIcms.data.forEach((icms: any) => {
      icms.estado = icms.idRegion2 ? icms.idRegion2.name : undefined;
      icms.icmsClient = icms.idClient2 ? icms.idClient2.name : 'ALL CLIENTS';
      icms.destinacao = icms.idDestinacao2
        ? icms.idDestinacao2.description
        : undefined;

      columnsToRemove.forEach(column => {
        switch (column.field) {
          case 'idRegion2.name':
            delete icms.estado;
            break;
          case 'idClient2.name':
            delete icms.icmsClient;
            break;
          case 'idDestinacao2.description':
            delete icms.destinacao;
            break;
          default:
            delete icms[column.field];
            break;
        }
      });

      // Remover colunas __typename e idIcms
      delete icms.__typename;
      delete icms.idIcms;
      delete icms.idRegion2;
      delete icms.idClient2;
      delete icms.idDestinacao2;
    });

    // Busca ordenacao das colunas da grid
    const gridColumns =
      gridRef.current?.state.columnOrder ??
      selectedColumns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: string) =>
        item !== 'idIcms' &&
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'idRegion2.name':
          columnsOrder[index] = 'estado';
          break;
        case 'idClient2.name':
          columnsOrder[index] = 'icmsClient';
          break;
        case 'idDestinacao2.description':
          columnsOrder[index] = 'destinacao';
          break;
        default:
          break;
      }
    });

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(
      icmsExportData.listAllIcms.data,
      { header: columnsOrder },
    );
    worksheet['!autofilter'] = { ref: 'A1:F1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveFileAsExcel(excelBuffer, 'icms');
  }

  /**
   * Busca icms para exportar para XLSX
   */
  const [
    loadIcmsToExport,
    { loading: icmsToExportLoading, data: icmsToExportData },
  ] = useLazyQuery(listIcmsQuery, {
    onCompleted: () => {
      exportToXlsx(icmsToExportData);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while exporting ICMS',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <Container>
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar ICMS */}
        {userHasPermission(
          idPermissionCreateIcms,
          userPermissionsIcms.userPermissions,
        ) && (
          <MainButton
            className="mainButton"
            label="New ICMS"
            onClick={() => history.push('/list/icms/create')}
          />
        )}

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />
        {/* Botao para excluir ICMS selecionados */}
        {userHasPermission(
          idPermissionDeleteIcms,
          userPermissionsIcms.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedIcms.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {/* Botao export para XLSX */}
        <Button
          type="button"
          className="export-xlsx"
          label="Export Grid"
          loading={icmsToExportLoading}
          onClick={() => {
            loadIcmsToExport({
              variables: {
                data: {
                  pagination: {
                    _page: 0,
                    _limit: 0,
                    _orderBy: lazyParams.sortField,
                    _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                  },
                  globalSearch: lazyParams.globalFilter,
                  icmsName: lazyParams.icmsName,
                  client: lazyParams.client,
                  region: lazyParams.region,
                  destinacao: lazyParams.destinacao,
                  active: true,
                },
              },
            });
          }}
        />

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for an ICMS"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        className="grid"
        name="icms"
        lazy
        totalRecords={!icmsData ? 0 : icmsData.listAllIcms.items}
        value={!icmsData ? undefined : icmsData.listAllIcms.data}
        globalFilter={globalFilter}
        emptyMessage="No ICMS found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={!icmsData ? undefined : lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedIcms}
        onSelectionChange={e => setSelectedIcms(e.value)}
        autoLayout
      >
        {userHasPermission(
          idPermissionDeleteIcms,
          userPermissionsIcms.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}

        {dynamicColumns}
      </Grid>
      {(pageLoading || icmsLoading) && <Loading />}
    </Container>
  );
};
export default IcmsList;
