import { useQuery } from '@apollo/client';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import Loading from '../../../../components/Loading';
import { useRefHook } from '../../../../hooks/useRefHook';
import { onErrorWhileGetting } from '../../../../services/generalFunctions';
import { ICategoryTree } from '../../../../shared/interfaces/category';
import { listCategoriesQuery } from '../../../../shared/querys/category';
import ConfirmOrderCommercialForm from './ConfirmOrderCommercialForm';
import { IConfirmOrderMenuProps } from './interfaces';
import { Container, Row } from './styles';
import ConfirmOrderPurchaseForm from './ConfirmOrderPurchaseForm';
import SelectRecipientsModal from '../../../../components/SelectRecipientsModal';
import {
  IUserEmailsProps,
  IUserGroupsProps,
} from '../../../../components/SelectRecipientsModal/interfaces';

const SelectedGroupEmailConcat = (groupUser?: IUserGroupsProps[]): string => {
  let namesUserGroupsConcat = '';
  const selectedUserGroups = groupUser?.filter(groups => groups.isChecked);
  if (selectedUserGroups) {
    selectedUserGroups.forEach(element => {
      namesUserGroupsConcat += `${element.name}, `;
    });
  }
  return namesUserGroupsConcat;
};

const SelectedUserConcat = (userEmailsProps?: IUserEmailsProps[]): string => {
  let namesUsersConcat = '';
  if (userEmailsProps) {
    userEmailsProps.forEach(element => {
      namesUsersConcat += ` ${element.firstName} ${element.lastName}, `;
    });
  }
  return namesUsersConcat;
};

const ConfirmOrderMenu: React.FC<IConfirmOrderMenuProps> = ({
  isVisibleConfirmOrderMenu,
  setIsVisibleConfirmOrderMenu,
  isVisibleRecipientsModal,
  setIsVisibleRecipientsModal,
  sat,
  satRefetch,
  isConfirmOrderWithGroup,
  setConfirmOrderMutationResponse,
  confirmOrderSummaryRef,
}) => {
  const { toastRef } = useRefHook();

  const [selectedUsers, setSelectedUsers] = useState<
    IUserEmailsProps[] | undefined
  >();
  const [userGroups, setUserGroups] = useState<
    IUserGroupsProps[] | undefined
  >();
  const [emailTitle, setEmailTitle] = useState<string>();

  // Categories
  const [categories, setCategories] = useState<ICategoryTree[]>([]);

  /**
   * Busca Categories
   */
  const { loading: satCategoriesLoading } = useQuery(listCategoriesQuery, {
    onError: errorData => {
      toastRef.current?.show(
        onErrorWhileGetting({ message: errorData.message }, 'categories'),
      );
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        setCategories(JSON.parse(response.listAllCategories.data));
      }
    },
  });

  const onHide = () => {
    setIsVisibleConfirmOrderMenu(false);
  };

  const SelectRecipients = () => {
    return (
      <>
        <h5>Recipients NDI</h5>
        <Container>
          <InputText
            id="recipients"
            aria-describedby="recipients-help"
            className="p-d-block"
            value={`${SelectedGroupEmailConcat(userGroups)}${SelectedUserConcat(
              selectedUsers,
            )}`}
            disabled
          />
          <Button
            label="Add"
            icon="pi pi-plus"
            onClick={() => {
              setIsVisibleRecipientsModal(true);
            }}
            autoFocus
          />
        </Container>
      </>
    );
  };

  return (
    <>
      <SelectRecipientsModal
        isVisibleRecipientsModal={isVisibleRecipientsModal}
        setIsVisibleRecipientsModal={setIsVisibleRecipientsModal}
        userGroups={userGroups}
        setUserGroups={setUserGroups}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
      <Dialog
        header={`Confirm Order - ${
          !sat.confirmOrderComercial ? 'Commercial' : 'Purchase'
        }`}
        visible={isVisibleConfirmOrderMenu}
        style={{ width: '50vw' }}
        onHide={() => onHide()}
      >
        <Row>
          <h5>Email Title</h5>
          <InputText
            id="title"
            aria-describedby="email-title"
            className="p-d-block"
            defaultValue={emailTitle}
            disabled
          />
          <SelectRecipients />
          {!sat.confirmOrderComercial && (
            <ConfirmOrderCommercialForm
              onHide={onHide}
              sat={sat}
              selectedUsers={selectedUsers}
              userGroups={userGroups}
              setEmailTitle={setEmailTitle}
              categories={categories}
              satRefetch={satRefetch}
              isConfirmOrderWithGroup={isConfirmOrderWithGroup}
              confirmOrderSummaryRef={confirmOrderSummaryRef}
              setConfirmOrderMutationResponse={setConfirmOrderMutationResponse}
            />
          )}
          {sat.confirmOrderComercial && (
            <ConfirmOrderPurchaseForm
              onHide={onHide}
              sat={sat}
              selectedUsers={selectedUsers}
              userGroups={userGroups}
              categories={categories}
              setEmailTitle={setEmailTitle}
              satRefetch={satRefetch}
              isConfirmOrderWithGroup={isConfirmOrderWithGroup}
              confirmOrderSummaryRef={confirmOrderSummaryRef}
              setConfirmOrderMutationResponse={setConfirmOrderMutationResponse}
            />
          )}
        </Row>
        {satCategoriesLoading && <Loading />}
      </Dialog>
    </>
  );
};

export default ConfirmOrderMenu;
