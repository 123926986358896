import styled from 'styled-components';

export const SatArtworkGeneralInformation = styled.div``;
export const SatArtworkTracking = styled.div``;
export const SatArtworkTiming = styled.div``;
export const SatArtworkFiles = styled.div``;

export const Container = styled.div`
  max-width: 1154px;

  .dropdown-width-ajust .form-dropdown {
    width: 100%;
  }

  .flex {
    display: flex;
  }

  .buttons button + button {
    margin-left: 8px;
  }

  .buttons {
    margin-top: 38px;
    margin-bottom: 12px;
  }
`;

export const LinkField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
`;

export const Subtitle = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 120%;
`;

export const AttachmentTypeContent = styled.div``;
export const SatArtworkAttachment = styled.div``;

export const AttachmentTypeBox = styled.div`
  width: 252px;
  padding: 5px;

  .attachment-type-title {
    font-weight: 700;
    line-height: 17px;
  }
`;
