import React, {
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { Tooltip } from 'primereact/tooltip';
import { Container } from './styles';

interface FormInputTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label: string;
  required?: boolean;
  className?: string;
  tooltip?: string;
  resize?: 'vertical' | 'none';
}

const FormInputTextArea: React.FC<FormInputTextAreaProps> = ({
  name,
  label,
  required,
  className,
  tooltip,
  onBlur,
  disabled,
  readOnly,
  resize,
  ...rest
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div
      className={`${
        className
          ? `${className} form-element form-textarea-input`
          : 'form-element form-textarea-input'
      }`}
    >
      <Container
        hasError={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        required={required}
        onClick={handleClick}
        data-pr-tooltip={tooltip}
        data-pr-position="right"
        disabled={disabled}
        readOnly={readOnly}
        resize={resize}
      >
        <Tooltip
          target=".form-textarea-input"
          mouseTrack
          mouseTrackLeft={10}
          showDelay={100}
        />
        <span>
          {error && <FiAlertCircle color="#c53030" size={15} />}
          <p>{label}:</p>
        </span>

        <textarea
          ref={inputRef}
          onFocus={e => {
            const inputPageHeight = e.target.getBoundingClientRect().top + 60;
            const pageHeightTop = window.innerHeight;
            e.target.scrollIntoView({
              behavior: 'smooth',
              block: inputPageHeight > pageHeightTop ? 'center' : 'nearest',
              inline: 'nearest',
            });
            handleInputFocus();
          }}
          onBlur={e => {
            if (onBlur) onBlur(e);
            handleInputBlur();
          }}
          defaultValue={defaultValue}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
        />
      </Container>
      {error && (
        <small id="username2-help" className="p-error p-d-block">
          {error}
        </small>
      )}
    </div>
  );
};

export default FormInputTextArea;
