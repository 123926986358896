import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { CheckboxChangeParams } from 'primereact/checkbox';
import { ValidationError } from 'yup';
import { isEmpty } from 'lodash';
import {
  PackageBarcodeFieldsPermissions,
  PackageDescriptionFieldsPermissions,
  PackageDetailsFieldsPermissions,
  PackageReferenceFieldsPermissions,
  ProductPackage,
} from '../interfaces';
import PackageDetails from './PackageDetails';
import PackageDescriptions from './PackageDescriptions';
import PackageReferences from './PackageReferences';
import PackageBarcodes from './PackageBarcodes';
import { IPackageDetailsRef } from './PackageDetails/interfaces';
import { IPackageContentRef, PackageContentFormData } from './interfaces';
import { IPackageReferencesRef } from './PackageReferences/interfaces';
import { IPackageBarcodesRef } from './PackageBarcodes/interfaces';

interface IContentProps {
  productPackage: ProductPackage;
  indConfection?: boolean;
  handleChangeDefault: (
    idProductPackage: number,
    e: CheckboxChangeParams,
  ) => boolean;
  ref: Ref<IPackageContentRef>;
  stCode: string;
  userCanChangeFields: boolean;
  descriptionsFieldsPermissions: PackageDescriptionFieldsPermissions;
  detailsFieldsPermissions: PackageDetailsFieldsPermissions;
  barcodesFieldsPermissions: PackageBarcodeFieldsPermissions;
  referencesFieldsPermissions: PackageReferenceFieldsPermissions;
}

const PackageContent: React.FC<IContentProps> = forwardRef(
  (
    {
      productPackage,
      indConfection,
      handleChangeDefault,
      stCode,
      userCanChangeFields,
      descriptionsFieldsPermissions,
      detailsFieldsPermissions,
      barcodesFieldsPermissions,
      referencesFieldsPermissions,
    },
    ref,
  ) => {
    const packageDetailsRef = useRef<IPackageDetailsRef>(null);
    const packageReferencesRef = useRef<IPackageReferencesRef>(null);
    const packageBarcodesRef = useRef<IPackageBarcodesRef>(null);

    useImperativeHandle(ref, () => ({
      validateForm: async () => {
        try {
          await packageDetailsRef.current?.validateForm();

          await packageReferencesRef.current?.validateForm();

          return true;
        } catch (error) {
          throw new ValidationError(error);
        }
      },
      getDataIfChanged: () => {
        const response: PackageContentFormData = {};

        const packageDetails = packageDetailsRef.current?.getDataIfChanged();
        if (packageDetails) response.packageDetails = packageDetails;

        const references = packageReferencesRef.current?.getDataIfChanged();
        if (references) response.packageReferences = references;

        const barcodes = packageBarcodesRef.current?.getDataIfChanged();
        if (barcodes) response.packageBarcodes = barcodes;

        if (isEmpty(response)) return undefined;

        return {
          ...response,
          idProductPackage: productPackage.idProductPackage,
        };
      },
      setIsDefault: (isDefault: boolean) => {
        packageDetailsRef.current?.setIsDefault(isDefault);
      },
    }));

    const [idTypePackage, setIdTypePackage] = useState(
      productPackage.idTypePackage,
    );

    const userCanSeeDescriptions = Object.values(
      descriptionsFieldsPermissions,
    ).some(field => field.view);

    const userCanSeePackageDetails = Object.values(
      detailsFieldsPermissions,
    ).some(field => field.view);

    const userCanSeeBarcodes = Object.values(barcodesFieldsPermissions).some(
      field => field.view,
    );

    const userCanSeeReferences = Object.values(
      referencesFieldsPermissions,
    ).some(field => field.view);

    return (
      <div className="p-grid" style={{ width: '886px' }}>
        {userCanSeeDescriptions && (
          <PackageDescriptions
            idProductPackage={productPackage.idProductPackage}
            userCanChangeFields={userCanChangeFields}
            fieldsPermissions={descriptionsFieldsPermissions}
          />
        )}

        {userCanSeePackageDetails && (
          <PackageDetails
            ref={packageDetailsRef}
            productPackage={productPackage}
            handleChangeDefault={handleChangeDefault}
            indConfection={indConfection}
            idTypePackage={idTypePackage}
            setIdTypePackage={setIdTypePackage}
            userCanChangeFields={userCanChangeFields}
            fieldsPermissions={detailsFieldsPermissions}
          />
        )}

        {indConfection && userCanSeeReferences && (
          <PackageReferences
            ref={packageReferencesRef}
            productPackage={productPackage}
            stCode={stCode}
            idTypePackage={idTypePackage}
            userCanChangeFields={userCanChangeFields}
            fieldsPermissions={referencesFieldsPermissions}
          />
        )}

        {!indConfection && userCanSeeBarcodes && (
          <PackageBarcodes
            ref={packageBarcodesRef}
            idProductPackage={productPackage.idProductPackage}
            userCanChangeFields={userCanChangeFields}
            fieldsPermissions={barcodesFieldsPermissions}
          />
        )}
      </div>
    );
  },
);

export default PackageContent;
