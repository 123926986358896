/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Dispatch, SetStateAction } from 'react';
import { gql } from '@apollo/client';
import { RadioButton } from 'primereact/radiobutton';
import { FaTimes } from 'react-icons/fa';
import { CheckboxChangeParams } from 'primereact/checkbox';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { ISupplier, ISupplierBankAccount } from '../interfaces';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { Container, BankDetailContainer, Row } from './styles';
import FormInput from '../../../../components/FormInput';
import { useRefHook } from '../../../../hooks/useRefHook';
import MainButton from '../../../../components/MainButton';
import generateRandomString from '../../../../utils/generateRandomString';
import FormCheckbox from '../../../../components/FormCheckbox';
import { supplierBankAccountRoles } from '../../../../shared/roles/supplierBankAccount';
import getFieldPermission from '../../../../utils/getFieldPermission';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import client from '../../../../services/apollo/client';
import FormInputTextArea from '../../../../components/FormInputTextArea';

interface IBankDetailProps extends PageTabContainerProps {
  supplier: ISupplier;
  setSupplier: Dispatch<SetStateAction<ISupplier>>;
  roleEntityFields: IRoleEntityField[];
}

const BankDetails: React.FC<IBankDetailProps> = ({
  selected,
  supplier,
  setSupplier,
  roleEntityFields,
}) => {
  // Referencia ao formulario e toast
  const { formRef } = useRefHook();

  const changePropertyValue = (property: string, value: any) => {
    setSupplier({
      ...supplier,
      [property]: value,
    });
  };

  function onSelectCountry(index: number, country: any) {
    formRef.current?.setFieldValue(
      `supplierBankAccounts[${index}].idRegion`,
      null,
    );
    formRef.current?.setFieldValue(
      `supplierBankAccounts[${index}].bankCity`,
      null,
    );

    const newBankAccounts = [...supplier.supplierBankAccounts!];

    newBankAccounts[index].idCountry2 = country;

    changePropertyValue('supplierBankAccounts', newBankAccounts);
  }

  function onSelectSecondCountry(index: number, country: any) {
    formRef.current?.setFieldValue(
      `supplierBankAccounts[${index}].idSecondRegion`,
      undefined,
    );

    formRef.current?.setFieldValue(
      `supplierBankAccounts[${index}].intermediaryBankCity`,
      undefined,
    );

    const newBankAccounts = [...supplier.supplierBankAccounts!];

    newBankAccounts[index].idSecondCountry2 = country;

    changePropertyValue('supplierBankAccounts', newBankAccounts);
  }

  function onSelectRegion(index: number, region: any) {
    formRef.current?.setFieldValue(
      `supplierBankAccounts[${index}].bankCity`,
      null,
    );

    const newBankAccounts = [...supplier.supplierBankAccounts!];

    newBankAccounts[index].idRegion2 = region;

    changePropertyValue('supplierBankAccounts', newBankAccounts);
  }

  function onSelectSecondRegion(index: number, region: any) {
    formRef.current?.setFieldValue(
      `supplierBankAccounts[${index}].intermediaryBankCity`,
      null,
    );

    const newBankAccounts = [...supplier.supplierBankAccounts!];

    newBankAccounts[index].idSecondRegion2 = region;

    changePropertyValue('supplierBankAccounts', newBankAccounts);
  }

  const changeIsIntermediateValue = (index: number, value: any) => {
    const bankAccounts = supplier.supplierBankAccounts;
    if (bankAccounts) {
      bankAccounts[index].isIntermediaryBank = value;
    }
    changePropertyValue('supplierBankAccounts', bankAccounts);
  };

  /**
   * Adiciona novo bank details
   */
  function addBankDetails() {
    // Cria novo array de pricings com novo pricing incluso
    let newSupplierBankAccounts: ISupplierBankAccount[];
    if (supplier.supplierBankAccounts) {
      newSupplierBankAccounts = [
        ...supplier.supplierBankAccounts,
        {
          idSupplierBankAccount: generateRandomString(4),
        } as ISupplierBankAccount,
      ];
    } else {
      newSupplierBankAccounts = [
        {
          idSupplierBankAccount: generateRandomString(4),
        } as ISupplierBankAccount,
      ];
    }

    // Altera array atual pelo novo no objeto de product
    changePropertyValue('supplierBankAccounts', newSupplierBankAccounts);
  }

  /**
   * Remove Bank Details
   * @param index Indice do Bank Details
   */
  function removeBankDetails(index: number) {
    if (supplier.supplierBankAccounts) {
      // Copia array de pricings
      const newSupplierBankAccounts = [...supplier.supplierBankAccounts];
      // Remove item do array copiado
      newSupplierBankAccounts.splice(index, 1);
      // Altera array atual pelo novo no objeto de product
      changePropertyValue('supplierBankAccounts', newSupplierBankAccounts);
    }
  }

  function handleIsDefaultChange(
    e: CheckboxChangeParams,
    index: number,
  ): boolean {
    if (supplier.supplierBankAccounts?.length === 1 && e.checked === true) {
      return true;
    }

    const newSupplierBankAccounts = [...supplier.supplierBankAccounts!];

    if (e.checked === true) {
      supplier.supplierBankAccounts?.forEach((bankAccount, i) => {
        if (bankAccount.isPrimary) {
          formRef.current?.setFieldValue(
            `supplierBankAccounts[${i}].isPrimary`,
            false,
          );
          newSupplierBankAccounts[i].isPrimary = false;
        }
      });
    }

    const primaryBankAccount = newSupplierBankAccounts.find(
      bankAccount => bankAccount.isPrimary,
    );

    if (
      primaryBankAccount?.idSupplierBankAccount ===
      newSupplierBankAccounts[index].idSupplierBankAccount
    ) {
      return true;
    }

    formRef.current?.setFieldValue(
      `supplierBankAccounts[${index}].isPrimary`,
      e.checked,
    );

    newSupplierBankAccounts[index].isPrimary = e.checked;

    changePropertyValue('supplierBankAccounts', newSupplierBankAccounts);

    return false;
  }

  /**
   * Carrega Paises
   * @param search Valor escrito no input
   * @param prevOptions Opcoes carregadas anteriormente
   * @param pageData Dados da pagina
   * @returns Dados de domains
   */
  const loadCountries: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query listAllCountriesPaginated(
          $pagination: Pagination!
          $name: String
        ) {
          listAllCountriesPaginated(pagination: $pagination, name: $name) {
            data {
              idCountry
              name
            }
            items
          }
        }
      `,
      variables: {
        pagination: {
          _page: pageData?.page,
          _limit: 25,
        },
        name: search,
      },
    });

    // Opcoes filtradas
    const filteredOptions = res.data.listAllCountriesPaginated.data;
    // Quantidade de itens
    const quantity = res.data.listAllCountriesPaginated.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  /**
   * Carrega Paises
   * @param search Valor escrito no input
   * @param prevOptions Opcoes carregadas anteriormente
   * @param pageData Dados da pagina
   * @returns Dados de domains
   */
  const loadRegions: LoadOptions<
    any,
    GroupBase<any>,
    { page: any; idCountry?: number }
  > = async (search: string, prevOptions: any, pageData) => {
    const res = await client.query({
      query: gql`
        query listAllRegions(
          $pagination: Pagination!
          $idCountry: Int
          $name: String
        ) {
          listAllRegions(
            pagination: $pagination
            idCountry: $idCountry
            name: $name
          ) {
            data {
              idRegion
              name
            }
            items
          }
        }
      `,
      variables: {
        pagination: {
          _page: pageData?.page,
          _limit: 25,
        },
        idCountry: pageData?.idCountry,
        name: search,
      },
    });

    // Opcoes filtradas
    const filteredOptions = res.data.listAllRegions.data;
    // Quantidade de itens
    const quantity = res.data.listAllRegions.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
        idCountry: pageData?.idCountry,
      },
    };
  };

  /**
   * Carrega Paises
   * @param search Valor escrito no input
   * @param prevOptions Opcoes carregadas anteriormente
   * @param pageData Dados da pagina
   * @returns Dados de domains
   */
  const loadCities: LoadOptions<
    any,
    GroupBase<any>,
    { page: any; idRegion?: number }
  > = async (search: string, prevOptions: any, pageData) => {
    const res = await client.query({
      query: gql`
        query listAllCities(
          $pagination: Pagination!
          $idRegion: Int
          $name: String
        ) {
          listAllCities(
            pagination: $pagination
            idRegion: $idRegion
            name: $name
          ) {
            data {
              idCity
              name
            }
            items
          }
        }
      `,
      variables: {
        pagination: {
          _page: pageData?.page,
          _limit: 25,
        },
        idRegion: pageData?.idRegion,
        name: search,
      },
    });

    // Opcoes filtradas
    const filteredOptions = res.data.listAllCities.data;
    // Quantidade de itens
    const quantity = res.data.listAllCities.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
        idRegion: pageData?.idRegion,
      },
    };
  };

  const {
    idFieldAccountNumber,
    idFieldBankCity,
    idFieldBeneficiaryName,
    idFieldIban,
    idFieldIdCountry,
    idFieldIdRegion,
    idFieldIdSecondCountry,
    idFieldIdSecondRegion,
    idFieldIntermediaryAccountNumber,
    idFieldIntermediaryBankAddress,
    idFieldIntermediaryBankCity,
    idFieldIntermediaryBeneficiaryName,
    idFieldIntermediaryIban,
    idFieldIntermediarySwift,
    idFieldIsIntermediaryBank,
    idFieldIsPrimary,
    idFieldMainBankAddress,
    idFieldMainBankName,
    idFieldSwift,
  } = supplierBankAccountRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  function disableField(
    idSupplierBankAccount: number | string | undefined,
    idField: number,
  ): boolean {
    return typeof idSupplierBankAccount === 'number'
      ? !getFieldPermission(idField, roleEntityFields).edit
      : !getFieldPermission(idField, roleEntityFields).create;
  }

  return (
    <PageTabContainer selected={selected}>
      <Container>
        {supplier.supplierBankAccounts &&
          supplier.supplierBankAccounts.map((bankAccount, index) => {
            const id = bankAccount.idSupplierBankAccount;
            return (
              <BankDetailContainer key={bankAccount.idSupplierBankAccount}>
                <div className="header-div">
                  <h1>Bank Details</h1>
                  <div className="button-div">
                    <button
                      type="button"
                      onClick={() => removeBankDetails(index)}
                    >
                      <FaTimes size={18} />
                    </button>
                  </div>
                </div>
                <Row>
                  {showField(idFieldBeneficiaryName) && (
                    <FormInput
                      className="largeInput"
                      name={`supplierBankAccounts[${index}].beneficiaryName`}
                      label="Beneficiary Name"
                      readOnly={disableField(id, idFieldBeneficiaryName)}
                    />
                  )}
                  {showField(idFieldMainBankName) && (
                    <FormInput
                      className="mediumInput"
                      name={`supplierBankAccounts[${index}].mainBankName`}
                      label="Main Bank Name"
                      readOnly={disableField(id, idFieldMainBankName)}
                    />
                  )}
                </Row>
                <Row>
                  {showField(idFieldMainBankAddress) && (
                    <FormInputTextArea
                      className="mainBankAddress"
                      name={`supplierBankAccounts[${index}].mainBankAddress`}
                      label="Main Bank Address"
                      readOnly={disableField(id, idFieldMainBankAddress)}
                      rows={1}
                    />
                  )}
                </Row>
                <Row>
                  {showField(idFieldIdCountry) && (
                    <FormAsyncSelect
                      className="generalInput"
                      name={`supplierBankAccounts[${index}].idCountry`}
                      label="Country"
                      debounceTimeout={1000}
                      loadOptions={loadCountries}
                      initialValue={bankAccount.idCountry2}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.idCountry}
                      additional={{
                        page: 1,
                      }}
                      noOptionsMessage={() => 'No Countries found'}
                      readOnly={disableField(id, idFieldIdCountry)}
                      onValueChange={country => onSelectCountry(index, country)}
                    />
                  )}
                  {showField(idFieldIdRegion) && (
                    <FormAsyncSelect
                      className="generalInput"
                      name={`supplierBankAccounts[${index}].idRegion`}
                      label="State/Provincy"
                      debounceTimeout={1000}
                      loadOptions={loadRegions}
                      initialValue={bankAccount.idRegion2}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.idRegion}
                      additional={{
                        page: 1,
                        idCountry:
                          supplier.supplierBankAccounts![index].idCountry2
                            ?.idCountry,
                      }}
                      noOptionsMessage={() => 'No Regions found'}
                      readOnly={
                        !supplier.supplierBankAccounts![index].idCountry2 ||
                        disableField(id, idFieldIdRegion)
                      }
                      onValueChange={region => onSelectRegion(index, region)}
                      isClearable
                      cacheUniqs={[supplier]}
                    />
                  )}
                  {showField(idFieldBankCity) && (
                    <FormAsyncSelect
                      className="generalInput"
                      name={`supplierBankAccounts[${index}].bankCity`}
                      label="City"
                      debounceTimeout={1000}
                      loadOptions={loadCities}
                      initialValue={{ name: bankAccount.bankCity }}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.name}
                      additional={{
                        page: 1,
                        idRegion:
                          supplier.supplierBankAccounts![index].idRegion2
                            ?.idRegion,
                      }}
                      noOptionsMessage={() => 'No Cities found'}
                      readOnly={
                        !supplier.supplierBankAccounts![index].idRegion2 ||
                        disableField(id, idFieldBankCity)
                      }
                      isClearable
                      cacheUniqs={[supplier]}
                    />
                  )}
                </Row>
                <Row>
                  {showField(idFieldSwift) && (
                    <FormInput
                      className="generalInput"
                      name={`supplierBankAccounts[${index}].swift`}
                      label="Swift"
                      readOnly={disableField(id, idFieldSwift)}
                    />
                  )}
                  {showField(idFieldAccountNumber) && (
                    <FormInput
                      className="generalInput"
                      name={`supplierBankAccounts[${index}].accountNumber`}
                      label="Account Number"
                      readOnly={disableField(id, idFieldAccountNumber)}
                    />
                  )}
                  {showField(idFieldIban) && (
                    <FormInput
                      className="generalInput"
                      name={`supplierBankAccounts[${index}].iban`}
                      label="Iban"
                      readOnly={disableField(id, idFieldIban)}
                    />
                  )}
                </Row>
                <Row>
                  {showField(idFieldIsIntermediaryBank) && (
                    <div className="radio-button-div generalInput">
                      <label>Intermediary:</label>
                      <div>
                        <RadioButton
                          inputId="yes"
                          name={`supplierBankAccounts[${index}].isIntermediaryBank`}
                          value="s"
                          checked={bankAccount.isIntermediaryBank === true}
                          onChange={() =>
                            changeIsIntermediateValue(index, true)
                          }
                          disabled={disableField(id, idFieldIsIntermediaryBank)}
                        />
                        <label>Yes</label>
                        <RadioButton
                          inputId="no"
                          name={`supplierBankAccounts[${index}].isIntermediaryBank`}
                          value="n"
                          checked={bankAccount.isIntermediaryBank !== true}
                          onChange={() =>
                            changeIsIntermediateValue(index, false)
                          }
                          disabled={disableField(id, idFieldIsIntermediaryBank)}
                        />
                        <label>No</label>
                      </div>
                    </div>
                  )}
                  {showField(idFieldIsPrimary) && (
                    <FormCheckbox
                      name={`supplierBankAccounts[${index}].isPrimary`}
                      label="Default"
                      className="activeCheckbox"
                      initialValue={bankAccount.isPrimary}
                      onValueChange={e => handleIsDefaultChange(e, index)}
                      readOnly={disableField(id, idFieldIsPrimary)}
                    />
                  )}
                </Row>
                {bankAccount.isIntermediaryBank && (
                  <div>
                    <h1>Intermediary</h1>
                    <Row>
                      {showField(idFieldIntermediaryBeneficiaryName) && (
                        <FormInput
                          className="mediumInput"
                          name={`supplierBankAccounts[${index}].intermediaryBeneficiaryName`}
                          label="Intermediary Beneficiary Name"
                          readOnly={disableField(
                            id,
                            idFieldIntermediaryBeneficiaryName,
                          )}
                        />
                      )}
                      {showField(idFieldIntermediaryBankAddress) && (
                        <FormInputTextArea
                          className="largeInput"
                          name={`supplierBankAccounts[${index}].intermediaryBankAddress`}
                          label="Intermediary Bank Address"
                          readOnly={disableField(
                            id,
                            idFieldIntermediaryBankAddress,
                          )}
                          rows={1}
                        />
                      )}
                    </Row>
                    <Row>
                      {showField(idFieldIdSecondCountry) && (
                        <FormAsyncSelect
                          className="generalInput"
                          name={`supplierBankAccounts[${index}].idSecondCountry`}
                          label="Intermediary Bank Country"
                          debounceTimeout={1000}
                          loadOptions={loadCountries}
                          initialValue={bankAccount.idSecondCountry2}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.idCountry}
                          additional={{
                            page: 1,
                          }}
                          noOptionsMessage={() => 'No Countries found'}
                          readOnly={disableField(id, idFieldIdSecondCountry)}
                          onValueChange={country =>
                            onSelectSecondCountry(index, country)
                          }
                        />
                      )}
                      {showField(idFieldIdSecondRegion) && (
                        <FormAsyncSelect
                          className="generalInput"
                          name={`supplierBankAccounts[${index}].idSecondRegion`}
                          label="Intermediary Bank State/Provincy"
                          debounceTimeout={1000}
                          loadOptions={loadRegions}
                          initialValue={bankAccount.idSecondRegion2}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.idRegion}
                          additional={{
                            page: 1,
                            idCountry:
                              supplier.supplierBankAccounts![index]
                                .idSecondCountry2?.idCountry,
                          }}
                          noOptionsMessage={() => 'No Regions found'}
                          readOnly={
                            !supplier.supplierBankAccounts![index]
                              .idSecondCountry2 ||
                            disableField(id, idFieldIdSecondRegion)
                          }
                          onValueChange={region =>
                            onSelectSecondRegion(index, region)
                          }
                          isClearable
                          cacheUniqs={[supplier]}
                        />
                      )}
                      {showField(idFieldIntermediaryBankCity) && (
                        <FormAsyncSelect
                          className="generalInput"
                          name={`supplierBankAccounts[${index}].intermediaryBankCity`}
                          label="Intermediary Bank City"
                          debounceTimeout={1000}
                          loadOptions={loadCities}
                          initialValue={{
                            name: bankAccount.intermediaryBankCity,
                          }}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.name}
                          additional={{
                            page: 1,
                            idRegion:
                              supplier.supplierBankAccounts![index]
                                .idSecondRegion2?.idRegion,
                          }}
                          noOptionsMessage={() => 'No Cities found'}
                          readOnly={
                            !supplier.supplierBankAccounts![index]
                              .idSecondRegion2 ||
                            disableField(id, idFieldIntermediaryBankCity)
                          }
                          isClearable
                          cacheUniqs={[supplier]}
                        />
                      )}
                    </Row>
                    <Row>
                      {showField(idFieldIntermediarySwift) && (
                        <FormInput
                          className="generalInput"
                          name={`supplierBankAccounts[${index}].intermediarySwift`}
                          label="Intermediary Swift"
                          readOnly={disableField(id, idFieldIntermediarySwift)}
                        />
                      )}
                      {showField(idFieldIntermediaryAccountNumber) && (
                        <FormInput
                          className="generalInput"
                          name={`supplierBankAccounts[${index}].intermediaryAccountNumber`}
                          label="Intermediary Account Number"
                          readOnly={disableField(
                            id,
                            idFieldIntermediaryAccountNumber,
                          )}
                        />
                      )}
                      {showField(idFieldIntermediaryIban) && (
                        <FormInput
                          className="generalInput"
                          name={`supplierBankAccounts[${index}].intermediaryIban`}
                          label="Intermediary Iban"
                          readOnly={disableField(id, idFieldIntermediaryIban)}
                        />
                      )}
                    </Row>
                  </div>
                )}
              </BankDetailContainer>
            );
          })}
        <MainButton label="Add Bank Details" onClick={() => addBankDetails()} />
      </Container>
    </PageTabContainer>
  );
};

export default BankDetails;
