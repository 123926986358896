import React from 'react';
import Tag from '../Tag';

// eslint-disable-next-line no-shadow
export enum SatStatus {
  ACTIVE = 855,
  CANCELED = 856,
  STAND_BY = 857,
  FINISHED = 858,
}

interface ISatStatusTagProps {
  status?: SatStatus;
}

const SatStatusTag: React.FC<ISatStatusTagProps> = ({ status }) => {
  const className = 'tag';

  switch (status) {
    case SatStatus.ACTIVE:
      return <Tag className={className} value="Active" severity="success" />;
    case SatStatus.CANCELED:
      return <Tag className={className} value="Canceled" severity="danger" />;
    case SatStatus.STAND_BY:
      return <Tag className={className} value="Stand by" severity="warn" />;
    case SatStatus.FINISHED:
      return (
        <Tag className={className} value="Finished" severity="secondary" />
      );
    default:
      return <span />;
  }
};

export default SatStatusTag;
