import { FormHandles } from '@unform/core';
import { Toast, ToastMessageType } from 'primereact/toast';
import React, {
  createContext,
  useState,
  useContext,
  useRef,
  RefObject,
  useCallback,
} from 'react';
import ToastLife from '../shared/enums/toastLife';

type ToastProps = ToastMessageType & {
  summary: string;
};

// Interface do contexto de referencia
interface IRefHookContext {
  formRef: RefObject<FormHandles>;
  toastRef: RefObject<Toast>;
  showToast: (props: ToastProps) => void;
  showError: (props: ToastProps) => void;
  showSuccess: (props: ToastProps) => void;
  showWarn: (props: ToastProps) => void;
}

// Cria o contexto
const RefHookContext = createContext<IRefHookContext>({} as IRefHookContext);

const RefHookProvider: React.FC = ({ children }) => {
  // Referencia ao formulario
  const refCont = useRef<FormHandles>(null);
  const [formRef] = useState(refCont);

  // Referencia a toast
  const toast = useRef<Toast>(null);
  const [toastRef] = useState(toast);

  const showToast = useCallback(
    (props: ToastProps) => {
      toastRef.current?.show(props);
    },
    [toastRef],
  );

  const showError = useCallback(
    (props: ToastProps) => {
      showToast({ ...props, severity: 'error', life: ToastLife.ERROR });
    },
    [showToast],
  );

  const showSuccess = useCallback(
    (props: ToastProps) => {
      showToast({ ...props, severity: 'success', life: ToastLife.SUCCESS });
    },
    [showToast],
  );

  const showWarn = useCallback(
    (props: ToastProps) => {
      showToast({ ...props, severity: 'warn', life: ToastLife.WARN });
    },
    [showToast],
  );

  return (
    <RefHookContext.Provider
      value={{ formRef, toastRef, showToast, showError, showSuccess, showWarn }}
    >
      {children}
    </RefHookContext.Provider>
  );
};

function useRefHook(): IRefHookContext {
  // Busca contexto
  const context = useContext(RefHookContext);

  // Valida o uso no arquivo app.tsx
  if (!context) {
    throw new Error('useRefHook must be used within an RefHookProvider');
  }

  // retorna contexto
  return context;
}

export { RefHookProvider, useRefHook };
