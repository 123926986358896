import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import FormAsyncSelect from '../../../../../../components/FormAsyncSelect';
import FormInput from '../../../../../../components/FormInput';
import FormTitle from '../../../../../../components/FormTitle';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import { asyncSelectLoadDomains } from '../../../../../../shared/querys/domain';
import {
  ForeignTradeFieldsPermissions,
  SatForeignTrade,
} from '../../interfaces';

interface IForeignTradeProps {
  foreignTrade: SatForeignTrade;
  fieldsPermissions: ForeignTradeFieldsPermissions;
  shipmentIsCanceled: boolean;
}

const ForeignTrade: React.FC<IForeignTradeProps> = ({
  foreignTrade,
  fieldsPermissions,
  shipmentIsCanceled,
}) => {
  const importLevelTooltipContent = `1 - Até 10 ST diferentes, sem li, replacement, selling incoterm: fob, infláveis sem certificação

    2 - De 11 a 50 ST diferentes, com li ou dumping, selling incoterm: cfr, itens novos, serviço NDI distribuição

    3 - Mais de 50 ST diferentes

    4 - Com inmetro ou anvisa

    5 - Samples, serviço completo, yiwu, conta e ordem
  `;

  return (
    <div className="form-group p-grid">
      <Tooltip target=".tooltip" position="right" />

      <FormTitle className="p-col-12" name="Foreign Trade" />

      {fieldsPermissions.typeOfImportService.view && (
        <FormAsyncSelect
          className="p-col-3"
          name="idTypeOfImportService"
          label="Type Of Import Service"
          loadOptions={asyncSelectLoadDomains}
          additional={{
            id: DomainGroup.FOREIGN_TRADE_TYPE_OF_IMPORT_SERVICE,
          }}
          getOptionLabel={option => option.description}
          getOptionValue={option => option.idDomain}
          noOptionsMessage={() => 'No type of import services found'}
          initialValue={foreignTrade.idTypeOfImportService2}
          readOnly={
            !fieldsPermissions.typeOfImportService.edit || shipmentIsCanceled
          }
        />
      )}

      {fieldsPermissions.importNotes.view && (
        <FormInput
          name="importNotes"
          label="Import Notes"
          className="p-col-9"
          readOnly={!fieldsPermissions.importNotes.edit || shipmentIsCanceled}
        />
      )}

      {fieldsPermissions.importLevel.view && (
        <span
          className="p-col-3 tooltip"
          data-pr-tooltip={importLevelTooltipContent}
        >
          <FormAsyncSelect
            name="idImportLevel"
            label="Import Level"
            loadOptions={asyncSelectLoadDomains}
            additional={{
              id: DomainGroup.FOREIGN_TRADE_IMPORT_LEVEL,
            }}
            getOptionLabel={option => option.description}
            getOptionValue={option => option.idDomain}
            noOptionsMessage={() => 'No import levels found'}
            initialValue={foreignTrade.idImportLevel2}
            readOnly={!fieldsPermissions.importLevel.edit || shipmentIsCanceled}
          />
        </span>
      )}

      {fieldsPermissions.exportNotes.view && (
        <FormInput
          name="exportNotes"
          label="Export Notes"
          className="p-col-9"
          readOnly={!fieldsPermissions.exportNotes.edit || shipmentIsCanceled}
        />
      )}
    </div>
  );
};

export default ForeignTrade;
