import styled from 'styled-components';
import { shade } from 'polished';

export const Wrapper = styled.div`
  background: linear-gradient(288.42deg, #eb8f10 5.74%, #b1251b 99.26%);
  height: 100vh;

  display: flex;

  align-items: stretch;
  place-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;

  max-width: 317px;
  width: 100%;

  h3,
  h4 {
    text-align: center;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 255px;
    margin-bottom: 60px;
  }

  form {
    padding: 30px 32px;
    flex-direction: column;
    .p-field {
      margin-bottom: 16px;
    }

    input {
      padding: 0 12px;
      width: 100%;
      height: 36px;
      margin-top: 4px;
    }

    a {
      color: var(--blue-light);
      display: block;
      margin-top: 4px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#29ABE2')};
      }
    }
  }
`;
