import { useLazyQuery } from '@apollo/client';
import React, { useRef } from 'react';
import Button from '../../../../../../components/Button';
import FilesByTypes, {
  IFilesByTypesRef,
} from '../../../../../../components/FilesByTypes';
import { IFileType } from '../../../../../../components/FilesByTypes/interfaces';
import FormTitle from '../../../../../../components/FormTitle';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { downloadSatForeignTradeAttachmentsQuery } from '../../../ForeignTrade/ForeignTradeContent/Files/queries';
import { Container } from './styles';
import { IFinancialFileData } from '../interfaces';

interface IFilesProps {
  financialForeignTradeFileTypesData: IFileType[];
  financialForeignTradeFileTypesLoading: boolean;
  financialFilesData: IFinancialFileData[];
  financialFilesLoading: boolean;
}

const FinancialForeignTradeFiles: React.FC<IFilesProps> = ({
  financialForeignTradeFileTypesData,
  financialForeignTradeFileTypesLoading,
  financialFilesData,
  financialFilesLoading,
}) => {
  const { toastRef } = useRefHook();

  const filesByTypesRef = useRef<IFilesByTypesRef>(null);

  const [
    downloadSatForeignTradeFiles,
    { loading: satForeignTradeFilesDownloadInProgress },
  ] = useLazyQuery(downloadSatForeignTradeAttachmentsQuery, {
    onCompleted: async response => {
      if (response.downloadSatForeignTradeFiles) {
        window.open(response.downloadSatForeignTradeFiles, '_blank');
      } else {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while downloading files',
          life: ToastLife.ERROR,
        });
      }
    },
    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while downloading files',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    },
  });

  function handleDownloadFiles() {
    const filesToDownload = filesByTypesRef.current?.getSelectedItems();

    if (!filesToDownload?.length) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'You need to select at least one item do download',
        life: ToastLife.ERROR,
      });

      return;
    }

    downloadSatForeignTradeFiles({
      variables: {
        idsSatAttachments: filesToDownload,
      },
    });
  }

  return (
    <Container className="form-group p-grid p-flex-column">
      <FormTitle className="p-col-12" name="Foreign Trade Files">
        <Button
          type="button"
          label="Download"
          onClick={() => handleDownloadFiles()}
          disabled={satForeignTradeFilesDownloadInProgress}
          icon={
            satForeignTradeFilesDownloadInProgress
              ? 'pi pi-spin pi-spinner'
              : 'pi pi-download'
          }
        />
      </FormTitle>

      <FilesByTypes
        ref={filesByTypesRef}
        className="p-col-12"
        fileTypes={financialForeignTradeFileTypesData}
        files={financialFilesData}
        typesLoading={financialForeignTradeFileTypesLoading}
        fileIdKey="idSatAttachment"
        filesLoading={financialFilesLoading}
        fileDescriptionKey="idAttachment2.nameGivenByUser"
        fileTypeKey="idAttachment2.idType"
        fileCreatedAtKey="idAttachment2.createdAt"
      />
    </Container>
  );
};

export default FinancialForeignTradeFiles;
