import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useReducer,
  useRef,
} from 'react';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { TabPanel, TabView } from 'primereact/tabview';
import {
  DivergentForeignTradesGetDataMode,
  DivergentForeignTradesReducerActionKind,
  DivergentForeignTradesResponse,
} from './interfaces';
import Button from '../../../../../components/Button';
import DivergentDataGrid, { IDivergengtDataGridRef } from './DivergentDataGrid';
import {
  divergentForeignTradesReducer,
  divergentForeignTradesReducerInitialState,
} from './reducers';
import { SelectedDivergentData } from '../interfaces';

export interface IDivergentForeignTradesModalRef {
  toggle(divergentForeignTrades?: DivergentForeignTradesResponse[]): void;
}

interface IDivergentForeignTradesModalProps {
  ref: Ref<IDivergentForeignTradesModalRef>;
  onSubmit: (selectedData: SelectedDivergentData[]) => void;
  getDataMode: DivergentForeignTradesGetDataMode;
}

const DivergentForeignTradesModal: React.FC<IDivergentForeignTradesModalProps> =
  forwardRef(({ onSubmit, getDataMode }, ref) => {
    const [state, stateDispatch] = useReducer(
      divergentForeignTradesReducer,
      divergentForeignTradesReducerInitialState,
    );

    const tabsRef = useRef<IDivergengtDataGridRef[] | null[]>([]);

    useImperativeHandle(ref, () => ({
      toggle: divergentForeignTrades => {
        stateDispatch({
          type: DivergentForeignTradesReducerActionKind.OPEN_DIALOG,
          payload: { divergentForeignTrades },
        });
      },
    }));

    function handleCancel() {
      confirmDialog({
        message: 'You will lost any unsaved information',
        header: 'Are you sure you want to proceed?',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () =>
          stateDispatch({
            type: DivergentForeignTradesReducerActionKind.CLOSE_DIALOG,
          }),
      });
    }

    function handleSubmit() {
      const selectedData: SelectedDivergentData[] = [];
      tabsRef.current.forEach(tab => {
        const selectedItem = tab?.getSelectedItem();
        if (selectedItem) selectedData.push(selectedItem);
      });

      onSubmit(selectedData);

      stateDispatch({
        type: DivergentForeignTradesReducerActionKind.CLOSE_DIALOG,
      });
    }

    const dialogFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button label="Ok" onClick={() => handleSubmit()} type="button" />
          <Button
            label="Cancel"
            onClick={() => handleCancel()}
            className="p-button-danger"
            type="button"
          />
        </div>
      );
    };

    return (
      <Dialog
        header="Consolidation"
        visible={state.displayDialog}
        onHide={() =>
          stateDispatch({
            type: DivergentForeignTradesReducerActionKind.CLOSE_DIALOG,
          })
        }
        footer={dialogFooter}
        closable={false}
      >
        <TabView renderActiveOnly={false}>
          {state.divergentForeignTrades.map((ft, index) => {
            return (
              <TabPanel
                key={ft.idForeignTradeSent}
                header={ft.foreignTradeSentNumber}
              >
                <DivergentDataGrid
                  ref={el => {
                    tabsRef.current[index] = el;
                  }}
                  idForeignTrade={ft.idForeignTradeSent}
                  divergentData={ft.divergentData}
                  getDataMode={getDataMode}
                />
              </TabPanel>
            );
          })}
        </TabView>
      </Dialog>
    );
  });

export default DivergentForeignTradesModal;
