import { useLazyQuery, useMutation } from '@apollo/client';
import { InputText } from 'primereact/inputtext';
import { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Checkbox } from 'primereact/checkbox';
import { Skeleton } from 'primereact/skeleton';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import {
  CheckboxItem,
  FilesHeader,
  FilesList,
  RecipientsNDI,
  Section,
} from './styles';
import Button from '../../../../../../../components/Button';
import {
  IBalancePaymentRequest,
  IBalancePaymentRequestProps,
  IUploadedFile,
} from './interfaces';
import FormInputTextArea from '../../../../../../../components/FormInputTextArea';
import FormInput from '../../../../../../../components/FormInput';
import FormInputNumber from '../../../../../../../components/FormInputNumber';
import Empty from '../../../../../../../components/Empty';
import {
  getToBalancePaymentRequestQuery,
  senBalancePaymentRequestQuery,
} from './queries';
import { asyncSelectLoadClientContactsOptions } from '../../../../../../../shared/querys/clientContacts';
import { IClientContact } from '../../../../../../Clients/ClientDetail/interfaces';
import FilesUpload from './FilesUpload';
import {
  IUserEmailsProps,
  IUserGroupsProps,
} from '../../../../../../../components/SelectRecipientsModal/interfaces';
import SelectRecipientsModal from '../../../../../../../components/SelectRecipientsModal';

const BalancePaymentRequestMenu: React.FC<IBalancePaymentRequestProps> = ({
  sat,
  foreignTrade,
  isVisibleBalancePaymentRequestMenu,
  setIsVisibleBalancePaymentRequestMenu,
  foreignTradeRef,
}) => {
  const formRef = useRef<FormHandles>(null);

  const { showError, showSuccess } = useRefHook();

  const [isVisibleRecipientsModal, setIsVisibleRecipientsModal] =
    useState(false);

  const [isVisibleFilesUploadModal, setIsVisibleFilesUploadModal] =
    useState(false);

  const [selectedUsersNdi, setSelectedUsersNdi] = useState<IUserEmailsProps[]>(
    [],
  );

  const [userGroups, setUserGroups] = useState<IUserGroupsProps[]>([]);

  const [selectedClientContacts, setSelectedClientContacts] = useState<
    IClientContact[]
  >([]);

  const [balancePaymentRequestData, setBalancePaymentRequestData] =
    useState<IBalancePaymentRequest>();

  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([]);

  const [balancePaymentRequestLoading, setBalancePaymentRequestLoading] =
    useState(false);

  const [attachCreditNoteFiles, setAttachCreditNoteFiles] = useState(true);

  const [attachCiPlSignedFiles, setAttachCiPlSignedFiles] = useState(true);

  const [attachHblFinalFiles, setAttachHblFinalFiles] = useState(true);

  const [
    listBalancePaymentRequestData,
    { loading: listBalancePaymentRequestLoading },
  ] = useLazyQuery(getToBalancePaymentRequestQuery, {
    variables: {
      idSatForeignTrade: foreignTrade.idSatForeignTrade,
    },
    onCompleted: response => {
      if (response.getSatForeignTradeToBalancePaymentRequest) {
        setBalancePaymentRequestData(
          response.getSatForeignTradeToBalancePaymentRequest,
        );
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Balance Payment Request data',
        detail: errorData.message,
      });
    },
  });

  const [sendBalancePaymentRequestMutation] = useMutation(
    senBalancePaymentRequestQuery,
  );

  const emailTitle = () => {
    const { satNumber } = sat;
    const satMainProduct = sat.mainProduct ?? '';
    const clientNotify = sat.idClient2?.name ?? '';
    const exporter = sat.idSupplierExporter2?.name ?? '';

    return `[PAGAMENTO SALDO] ${clientNotify} | ${satNumber} | ${satMainProduct} | ${exporter}`;
  };

  const selectedGroupEmailConcat = (groupUser?: IUserGroupsProps[]): string => {
    let namesUserGroupsConcat = '';
    const selectedUserGroups = groupUser?.filter(groups => groups.isChecked);
    if (selectedUserGroups) {
      selectedUserGroups.forEach(element => {
        namesUserGroupsConcat += `${element.name}, `;
      });
    }
    return namesUserGroupsConcat;
  };

  const selectedUserConcat = (userEmailsProps?: IUserEmailsProps[]): string => {
    let namesUsersConcat = '';
    if (userEmailsProps) {
      userEmailsProps.forEach(element => {
        namesUsersConcat += ` ${element.firstName} ${element.lastName}, `;
      });
    }
    return namesUsersConcat;
  };

  const onHide = () => {
    setIsVisibleBalancePaymentRequestMenu(false);
  };

  async function handleSubmit(formData: IBalancePaymentRequest) {
    setBalancePaymentRequestLoading(true);

    const idsUsersNdi = selectedUsersNdi?.map(e => e.idUser);
    const idsUserGroups = userGroups?.flatMap(e =>
      e.isChecked ? [e.idUserGroup] : [],
    );
    const idsClientContacts = selectedClientContacts?.map(
      e => e.idClientContact,
    );

    try {
      await sendBalancePaymentRequestMutation({
        variables: {
          data: {
            idSat: sat.idSat,
            idSatForeignTrade: foreignTrade.idSatForeignTrade,
            idsUsersNdi,
            idsUserGroups,
            idsClientContacts,
            uploadedFiles: uploadedFiles.map(file => file.serverName),
            emailSubject: emailTitle(),
            attachCreditNoteFiles,
            attachCiPlSignedFiles,
            attachHblFinalFiles,
            ...formData,
          },
        },
      });

      foreignTradeRef.current?.refreshForm();

      showSuccess({
        summary: 'Balance Payment Request sent',
      });

      onHide();
    } catch (error) {
      showError({
        summary: 'Error while sending Balance Payment Request',
        detail: error.message,
      });
    } finally {
      setBalancePaymentRequestLoading(false);
    }
  }

  const handleSelectFile = (isSelected: boolean, fileKey: string) => {
    const updatedList = uploadedFiles.map(item => {
      if (item.key === fileKey) return { ...item, isSelected };
      return item;
    });

    setUploadedFiles(updatedList);
  };

  const checkIfExistSelectedFiles = () => {
    const selectedFiles = uploadedFiles.filter(
      file => file.isSelected === true,
    );

    return !!selectedFiles.length;
  };

  function removeSelectedFiles() {
    const notSelectedFiles = uploadedFiles.filter(
      item => item.isSelected === false,
    );

    setUploadedFiles(notSelectedFiles);
  }

  const renderUploadedFilesList = () => {
    if (!uploadedFiles.length) {
      return <Empty message="There is no files attached" position="left" />;
    }

    return (
      <FilesList>
        {uploadedFiles.map(file => {
          return (
            <CheckboxItem key={file.key}>
              <Checkbox
                onChange={e => handleSelectFile(e.checked, file.key)}
                checked={file.isSelected}
              />
              <p>{file.nameGivenByUser}</p>
            </CheckboxItem>
          );
        })}
      </FilesList>
    );
  };

  const footer = () => {
    return (
      <div className="p-d-flex p-jc-end">
        <Button
          label="Send"
          icon={
            balancePaymentRequestLoading
              ? 'pi pi-spin pi-spinner'
              : 'pi pi-send'
          }
          type="submit"
          onClick={() => formRef.current?.submitForm()}
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide()}
          severity="danger"
        />
      </div>
    );
  };

  useEffect(() => {
    if (isVisibleBalancePaymentRequestMenu && !balancePaymentRequestData) {
      listBalancePaymentRequestData();
    }
  }, [
    isVisibleBalancePaymentRequestMenu,
    balancePaymentRequestData,
    listBalancePaymentRequestData,
  ]);

  return (
    <div>
      <SelectRecipientsModal
        isVisibleRecipientsModal={isVisibleRecipientsModal}
        setIsVisibleRecipientsModal={setIsVisibleRecipientsModal}
        userGroups={userGroups}
        setUserGroups={setUserGroups}
        selectedUsers={selectedUsersNdi}
        setSelectedUsers={setSelectedUsersNdi}
      />
      <FilesUpload
        isVisibleFilesUploadModal={isVisibleFilesUploadModal}
        setIsVisibleFilesUploadModal={setIsVisibleFilesUploadModal}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />
      <Dialog
        header="Balance Payment Request"
        visible={isVisibleBalancePaymentRequestMenu}
        onHide={onHide}
        style={{ width: '45vw' }}
        footer={footer}
      >
        <Section>
          <h5>Título do Email</h5>
          <InputText
            name="emailTitle"
            aria-describedby="email-title"
            className="p-col-12"
            defaultValue={emailTitle()}
            disabled
          />

          <RecipientsNDI>
            <h5>Destinatários NDI</h5>
            <div className="content">
              <InputText
                aria-describedby="recipients-help"
                className="p-col-10"
                value={`${selectedGroupEmailConcat(
                  userGroups,
                )}${selectedUserConcat(selectedUsersNdi)}`}
              />
              <Button
                label="Add"
                icon="pi pi-plus"
                onClick={() => {
                  setIsVisibleRecipientsModal(true);
                }}
                autoFocus
              />
            </div>
          </RecipientsNDI>

          <h5>Destinatários Client</h5>
          <AsyncPaginate
            loadOptions={asyncSelectLoadClientContactsOptions}
            debounceTimeout={1000}
            isMulti
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.idClientContact}
            additional={{
              page: 1,
              idClient: sat.idClient,
            }}
            value={selectedClientContacts}
            onChange={e => setSelectedClientContacts(e as IClientContact[])}
          />
        </Section>

        {!listBalancePaymentRequestLoading && balancePaymentRequestData && (
          <Form
            className="p-grid"
            style={{ margin: '10px' }}
            ref={formRef}
            initialData={balancePaymentRequestData}
            onSubmit={handleSubmit}
          >
            <FormInputTextArea
              className="p-col-12"
              name="emailDescription"
              defaultValue=""
              label="Descrição do Email"
            />

            <FormInput className="p-col-6" name="mainProduct" label="Produto" />
            <FormInput className="p-col-6" name="currency" label="Moeda" />
            <FormInputNumber
              className="p-col-6"
              name="totalSunCi"
              label="Total Pedido"
              currency={balancePaymentRequestData?.currency}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              defaultValue={balancePaymentRequestData?.totalSunCi}
            />
            <FormInputNumber
              className="p-col-6"
              name="totalAdvance"
              label="Total Antecipado"
              currency={balancePaymentRequestData?.currency}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
            />
            <FormInputNumber
              className="p-col-6"
              name="totalRemainSun"
              label="Saldo a Pagar"
              currency={balancePaymentRequestData?.currency}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
            />
            <FormInput
              className="p-col-6"
              name="paymentTerm"
              label="Termo de Pagamento"
            />
            <FormInput
              className="p-col-6"
              name="clientOrder"
              label="Nº Pedido"
            />
            <FormInput className="p-col-6" name="clientCnpj" label="CNPJ" />
            <FormInput
              className="p-col-6"
              name="realEta"
              label="Previsão Chegada"
              type="date"
            />
            <FormInput
              className="p-col-6"
              name="portOfDischarge"
              label="Port of Discharge"
            />
          </Form>
        )}

        {listBalancePaymentRequestLoading && <Skeleton height="2rem" />}

        <Section>
          <FilesHeader>
            <h2>Anexos</h2>
            <Button
              label="Add"
              icon="pi pi-plus"
              onClick={() => setIsVisibleFilesUploadModal(true)}
            />
            <Button
              label="Remove"
              icon="pi pi-times"
              onClick={() => removeSelectedFiles()}
              severity="danger"
              disabled={!checkIfExistSelectedFiles()}
            />
            <CheckboxItem style={{ margin: 0 }}>
              <Checkbox
                onChange={e => setAttachCreditNoteFiles(e.checked)}
                checked={attachCreditNoteFiles}
              />
              <p>Credit Notes</p>
            </CheckboxItem>
            <CheckboxItem style={{ margin: 0 }}>
              <Checkbox
                onChange={e => setAttachCiPlSignedFiles(e.checked)}
                checked={attachCiPlSignedFiles}
              />
              <p>CI+PL Signed files</p>
            </CheckboxItem>
            <CheckboxItem style={{ margin: 0 }}>
              <Checkbox
                onChange={e => setAttachHblFinalFiles(e.checked)}
                checked={attachHblFinalFiles}
              />
              <p>HBL Final files</p>
            </CheckboxItem>
          </FilesHeader>

          {renderUploadedFilesList()}
        </Section>
      </Dialog>
    </div>
  );
};

export default BalancePaymentRequestMenu;
