import React, { Ref, forwardRef, useImperativeHandle, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Divider } from 'primereact/divider';
import {
  PackageBarcodeFieldsPermissions,
  ProductPackageBarcode,
} from '../../../interfaces';
import FormAsyncSelect from '../../../../../../../components/FormAsyncSelect';
import { asyncSelectLoadClients } from '../../../../../../../shared/querys/client';
import Barcode from '../../../../../../../components/Barcode';
import FormCheckbox from '../../../../../../../components/FormCheckbox';
import { objectsAreEqual } from '../../../../../../../utils/objectsAreEqual';
import {
  IPackageBarcodeContentRef,
  PackageBarcodeFormData,
} from './interfaces';
import FormInputNumber from '../../../../../../../components/FormInputNumber';

interface IPackageBarcodeContentProps {
  packageBarcode?: ProductPackageBarcode;
  ref: Ref<IPackageBarcodeContentRef>;
  userCanChangeFields: boolean;
  fieldsPermissions: PackageBarcodeFieldsPermissions;
}

const PackageBarcodeContent: React.FC<IPackageBarcodeContentProps> = forwardRef(
  ({ packageBarcode, userCanChangeFields, fieldsPermissions }, ref) => {
    const formRef = useRef<FormHandles>(null);

    function onSubmit(data: PackageBarcodeFormData) {
      if (packageBarcode && objectsAreEqual(data, packageBarcode)) {
        return undefined;
      }

      return data;
    }

    useImperativeHandle(ref, () => ({
      getDataIfChanged: () => {
        const formData = formRef.current?.getData() as PackageBarcodeFormData;

        return onSubmit(formData);
      },
    }));

    return (
      <Form
        className="p-formgrid p-grid p-ai-center"
        ref={formRef}
        initialData={packageBarcode}
        onSubmit={data => onSubmit(data)}
      >
        {packageBarcode?.idProductPackageBarcode && (
          <FormInputNumber
            name="idProductPackageBarcode"
            label=""
            value={packageBarcode.idProductPackageBarcode}
            hidden
          />
        )}

        <span className="p-col-4 p-grid">
          {fieldsPermissions.idClient.view && (
            <FormAsyncSelect
              className="p-col-12 p-field"
              name="idClient"
              label="Client"
              loadOptions={asyncSelectLoadClients}
              initialValue={
                packageBarcode?.idClient
                  ? {
                      idClient: packageBarcode.idClient,
                      name: packageBarcode.idClient2?.name,
                    }
                  : undefined
              }
              getOptionLabel={option => option.name}
              getOptionValue={option => option.idClient}
              noOptionsMessage={() => 'No clients found'}
              isClearable
              placeholder={<p>All Clients</p>}
              menuPosition="fixed"
              readOnly={
                !userCanChangeFields ||
                (packageBarcode?.idProductPackageBarcode
                  ? !fieldsPermissions.idClient.edit
                  : !fieldsPermissions.idClient.create)
              }
            />
          )}

          {fieldsPermissions.auto.view && (
            <FormCheckbox
              className="p-col-12"
              name="auto"
              label="Auto"
              readOnly={
                !userCanChangeFields ||
                (packageBarcode?.idProductPackageBarcode
                  ? !fieldsPermissions.auto.edit
                  : !fieldsPermissions.auto.create)
              }
            />
          )}
        </span>

        <span className="p-col-8">
          {fieldsPermissions.packageEan13.view && (
            <>
              <div className="p-grid p-align-center p-justify-between">
                <FormInputNumber
                  className="p-col-6"
                  name="packageEan13"
                  label="SELLING PACKAGE (EAN13/UPC)"
                  max={9999999999999}
                  decimalScale={0}
                  type="text"
                  inputMode="numeric"
                  thousandSeparator={false}
                  readOnly={
                    !userCanChangeFields ||
                    (packageBarcode?.idProductPackageBarcode
                      ? !fieldsPermissions.packageEan13.edit
                      : !fieldsPermissions.packageEan13.create)
                  }
                />

                <Barcode
                  className="p-col-4"
                  value={packageBarcode?.packageEan13?.toString()}
                  format="EAN13"
                  downloadOnClick
                  fontOptions="bold"
                  svgHeight={100}
                  svgWidth={180}
                />
              </div>
              <Divider />
            </>
          )}

          {fieldsPermissions.innerDun14.view && (
            <>
              <div className="p-grid p-align-center p-justify-between">
                <FormInputNumber
                  className="p-col-6"
                  name="innerDun14"
                  label="INNER BOX (DUN14)"
                  max={99999999999999}
                  decimalScale={0}
                  type="text"
                  inputMode="numeric"
                  thousandSeparator={false}
                  readOnly={
                    !userCanChangeFields ||
                    (packageBarcode?.idProductPackageBarcode
                      ? !fieldsPermissions.innerDun14.edit
                      : !fieldsPermissions.innerDun14.create)
                  }
                />

                <Barcode
                  className="p-col-4"
                  value={packageBarcode?.innerDun14?.toString()}
                  format="ITF14"
                  downloadOnClick
                  fontOptions="bold"
                  svgHeight={100}
                  svgWidth={180}
                />
              </div>
              <Divider />
            </>
          )}

          {fieldsPermissions.masterDun14.view && (
            <div className="p-grid p-align-center p-justify-between">
              <FormInputNumber
                className="p-col-6"
                name="masterDun14"
                label="MASTER BOX (DUN14)"
                max={99999999999999}
                decimalScale={0}
                type="text"
                inputMode="numeric"
                thousandSeparator={false}
                readOnly={
                  !userCanChangeFields ||
                  (packageBarcode?.idProductPackageBarcode
                    ? !fieldsPermissions.masterDun14.edit
                    : !fieldsPermissions.masterDun14.create)
                }
              />

              <Barcode
                className="p-col-4"
                value={packageBarcode?.masterDun14?.toString()}
                format="ITF14"
                downloadOnClick
                fontOptions="bold"
                svgHeight={100}
                svgWidth={180}
              />
            </div>
          )}
        </span>
      </Form>
    );
  },
);

export default PackageBarcodeContent;
