import React from 'react';
import { Divider } from 'primereact/divider';
import {
  PackageReferenceFieldsPermissions,
  ProductPackageSizeBarcode,
} from '../../../../../interfaces';
import ReadOnlyInput from '../../../../../../../../../components/ReadOnlyInput';
import FormInputNumber from '../../../../../../../../../components/FormInputNumber';
import FormCheckbox from '../../../../../../../../../components/FormCheckbox';
import Barcode from '../../../../../../../../../components/Barcode';
import { ProductPackageUnitData } from './interfaces';

interface IPackageTypeCalculatedProps {
  packageSizeBarcode: ProductPackageSizeBarcode;
  reference?: string;
  isANewSizeBarcode: boolean;
  packageUnitData?: ProductPackageUnitData;
  fieldsPermissions: PackageReferenceFieldsPermissions;
}

const PackageTypeCalculated: React.FC<IPackageTypeCalculatedProps> = ({
  packageSizeBarcode,
  reference,
  isANewSizeBarcode,
  packageUnitData,
  fieldsPermissions,
}) => {
  return (
    <div className="p-grid p-ai-center w-full">
      {isANewSizeBarcode && (
        <FormInputNumber
          name="size"
          label="size"
          value={packageSizeBarcode.size}
          hidden
        />
      )}
      <span className="p-col-3">
        {fieldsPermissions.size.view && (
          <ReadOnlyInput
            label="Size"
            value={packageSizeBarcode.size2?.description}
          />
        )}

        {fieldsPermissions.reference.view && (
          <ReadOnlyInput label="Reference" value={reference} />
        )}
      </span>
      <span className="p-col-9">
        <div className="p-grid p-ai-center">
          {fieldsPermissions.unit.view && (
            <ReadOnlyInput
              className="p-col-2"
              label="Unit"
              value={
                packageSizeBarcode.unit2?.description ||
                packageUnitData?.packageUnit
              }
            />
          )}

          {fieldsPermissions.sellingPackage.view && (
            <>
              <FormInputNumber
                className="p-col-5"
                name="sellingPackage"
                label="Selling Package (EAN-13)"
                max={9999999999999}
                decimalScale={0}
                type="text"
                inputMode="numeric"
                thousandSeparator={false}
              />

              <FormCheckbox
                className="p-col-2"
                name="auto"
                label="Auto"
                tooltip="Auto Generate Selling Package (EAN-13)"
                initialValue={packageSizeBarcode.auto}
              />

              <Barcode
                className="p-col-3"
                value={packageSizeBarcode.sellingPackage?.toString()}
                format="EAN13"
                downloadOnClick
                svgHeight={100}
                svgWidth={120}
                fontOptions="bold"
              />
            </>
          )}
        </div>

        {(fieldsPermissions.unit.view ||
          fieldsPermissions.sellingPackage.view) && <Divider />}

        <div className="p-grid p-ai-center">
          {fieldsPermissions.unit.view && (
            <ReadOnlyInput
              className="p-col-2"
              label="Unit"
              value={
                packageSizeBarcode.unitInnerBox || packageUnitData?.unitInnerBox
              }
            />
          )}

          {fieldsPermissions.innerBox.view && (
            <>
              <FormInputNumber
                className="p-col-5"
                name="innerBox"
                label="Inner Box (DUN-14)"
                max={99999999999999}
                decimalScale={0}
                type="text"
                inputMode="numeric"
                thousandSeparator={false}
              />

              <FormCheckbox
                className="p-col-2"
                tooltip="Auto Generate Inner Box (DUN-14)"
                name="autoInnerBox"
                label="Auto"
                initialValue={packageSizeBarcode.autoInnerBox}
              />

              <Barcode
                className="p-col-3"
                value={packageSizeBarcode.innerBox?.toString()}
                format="ITF14"
                downloadOnClick
                svgHeight={100}
                svgWidth={120}
                fontOptions="bold"
              />
            </>
          )}
        </div>

        {(fieldsPermissions.unit.view || fieldsPermissions.innerBox.view) && (
          <Divider />
        )}

        <div className="p-grid p-ai-center">
          {fieldsPermissions.unit.view && (
            <ReadOnlyInput
              className="p-col-2"
              label="Unit"
              value={
                packageSizeBarcode.unitMasterBox ||
                packageUnitData?.unitMasterBox
              }
            />
          )}

          {fieldsPermissions.innerBox.view && (
            <>
              <FormInputNumber
                className="p-col-5"
                name="masterBox"
                label="Master Box (DUN-14)"
                max={99999999999999}
                decimalScale={0}
                type="text"
                inputMode="numeric"
                thousandSeparator={false}
              />

              <FormCheckbox
                className="p-col-2"
                tooltip="Auto Generate Master Box (DUN-14)"
                name="autoMasterBox"
                label="Auto"
                initialValue={packageSizeBarcode.autoMasterBox}
              />

              <Barcode
                className="p-col-3"
                value={packageSizeBarcode.masterBox?.toString()}
                format="ITF14"
                downloadOnClick
                svgHeight={100}
                svgWidth={120}
                fontOptions="bold"
              />
            </>
          )}
        </div>
      </span>
    </div>
  );
};

export default PackageTypeCalculated;
