/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { gql, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTable,
  DataTableMultiSortMetaType,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortParams,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import { gridConstants } from '../../../components/Grid/constants';
import { ColumnData } from '../../../components/Grid/interfaces';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import useTitle from '../../../hooks/useTitle';
import { IRoleEntityField } from '../../../interfaces/IRoleEntityField';
import ILazyParams from '../../../services/lazyParams';
import ToastLife from '../../../shared/enums/toastLife';
import { portRoles } from '../../../shared/roles/port';
import getFieldPermission from '../../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';
import { gridColumnsData } from './contants';
import { Container } from './styles';
import { filterOnlyAllowedColumnsFromLocalStorage } from '../../../components/Grid/utils';

interface ICountry {
  name: string;
}

interface ICity {
  name: string;
}

interface IType {
  description: string;
}

/**
 * Interface de Porto
 */
interface IPort {
  idPort: number;
  name: string;
  country: ICountry;
  city: ICity;
  type: IType;
}

/**
 * Interface dos parametros do backend
 */
interface IPortsLazyParams extends ILazyParams {
  [key: string]: number | string | DataTableMultiSortMetaType | undefined;
  name?: string;
  country?: string;
  city?: string;
  type?: number;
}

const pageTitle = 'List of Airports/Ports';

const Ports: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:portsGridColumns';

  const lazyParamsName = '@SAT:portsLazyParams';

  const advancedFiltersName = '@SAT:portsAdvancedFilters';

  // Redirect
  const history = useHistory();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia a grid
  const gridRef = useRef<DataTable>(null);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // ID da entity de porto
  const { idEntity } = portRoles;

  // Busca IDs dos fields de porto
  const { idFieldCity, idFieldType } = portRoles.fields;

  // Permissions de Port
  const { idPermissionDeletePort } = portRoles.permissions;

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsPort = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    portRoles.idEntity,
  );

  // Permissoes de fields do usuario
  const [roleEntityFields, setRoleEntityFields] = useState<IRoleEntityField[]>(
    [],
  );

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList: ColumnData[] = [];
    columnList.push(gridColumnsData.name);
    columnList.push(gridColumnsData.country);
    if (getFieldPermission(idFieldCity, userPermissionsPort.userFields).view) {
      columnList.push(gridColumnsData.city);
    }
    if (getFieldPermission(idFieldType, userPermissionsPort.userFields).view) {
      columnList.push(gridColumnsData.type);
    }
    return columnList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    // Busca permissoes do usuario de acordo com a entity de portos
    const portRoleEntity = roles.rolesUser
      .map(roleUser => {
        return roleUser.idRole2.roleEntities.find(
          roleEntity => roleEntity.idEntity2.idEntity === idEntity,
        );
      })
      .filter(roleUser => roleUser !== undefined);

    if (portRoleEntity[0]) {
      // Define permissoes de field do usuario
      setRoleEntityFields(portRoleEntity[0].roleEntityFields);
    }

    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      const allowedColumns = filterOnlyAllowedColumnsFromLocalStorage(
        JSON.parse(localStorageSelectedColumns),
        columns,
      );
      setSelectedColumns(allowedColumns);
    } else {
      setSelectedColumns(columns);
    }
  }, [columns, idEntity, roles.rolesUser]);

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  // Estado inicial de lazy params
  const initialLazyParams = {
    first: pagination.initialLazyParams.first,
    rows: pagination.initialLazyParams.rows,
    page: pagination.initialLazyParams.page,
  };

  const [lazyParams, setLazyParams] = useState<IPortsLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: IPortsLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: IPortsLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);
  // Produtos selecionados
  const [selectedPorts, setSelectedPorts] = useState([]);

  // Estado de scrollHeight da grid
  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar portos
  const listPortsQuery = gql`
    query listAllPortsQuery($listAllPortsInput: ListAllPortsInput!) {
      listAllPorts(listAllPortsInput: $listAllPortsInput) {
        data {
          idPort
          name
          idCountry2 {
            name
          }
          ${
            getFieldPermission(idFieldType, roleEntityFields).view
              ? `
              type
              type2 {
                description
              }
              `
              : ''
          }

          ${
            getFieldPermission(idFieldCity, roleEntityFields).view
              ? `
              idCity2 {
                name
              }
              `
              : ''
          }
        }
        items
      }
    }
  `;

  // Query para atualizar porto
  const deletePortsQuery = gql`
    mutation DeletePortsMutation($portsId: [Int]!) {
      deletePorts(portsId: $portsId)
    }
  `;

  // cria método para chamar a mutation
  const [deletePorts] = useMutation(deletePortsQuery);

  /**
   * Busca Portos
   */
  const {
    loading: portsLoading,
    data: portsData,
    error: portsError,
    refetch: portsRefetch,
  } = useQuery(listPortsQuery, {
    variables: {
      listAllPortsInput: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        globalSearch: lazyParams.globalFilter,
        name: lazyParams.name,
        country: lazyParams.country,
        city: lazyParams.city,
        type: lazyParams.type,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting airports/ports',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={{ width: '460px' }}
          sortable
        />
      )
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeParams) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do porto clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(`/list/ports/${e.data.idPort}`);
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortParams) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Deleta portos selecionados
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedPorts.length} airports/ports?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada porto
        const idsToDelete = selectedPorts.map((a: IPort) => a.idPort);
        try {
          // Deleta portos
          await deletePorts({
            variables: {
              portsId: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedPorts.length} airports/ports`,
            life: ToastLife.SUCCESS,
          });

          // Zera estado de portos selecionadas
          setSelectedPorts([]);

          // Atualiza grid de portos
          await portsRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting airports/ports',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <Container>
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar porto */}
        <MainButton
          className="mainButton"
          label="New Airport/Port"
          onClick={() => history.push('/list/ports/create')}
        />

        {/* Advanced Filters */}

        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Botao para excluir portos selecionados */}
        {userHasPermission(
          idPermissionDeletePort,
          userPermissionsPort.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedPorts.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for airport/port"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        className="grid"
        name="ports"
        lazy
        totalRecords={
          !portsData || portsError ? 0 : portsData.listAllPorts.items
        }
        value={
          !portsData || portsError ? undefined : portsData.listAllPorts.data
        }
        globalFilter={globalFilter}
        emptyMessage="No airports/ports found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={!portsData || portsError ? undefined : lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedPorts}
        onSelectionChange={e => setSelectedPorts(e.value)}
      >
        {userHasPermission(
          idPermissionDeletePort,
          userPermissionsPort.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}

        {dynamicColumns}
      </Grid>
      {(pageLoading || portsLoading) && <Loading />}
    </Container>
  );
};
export default Ports;
