// eslint-disable-next-line no-shadow
export enum AttachmentTypes {
  COMPARE_TYPE_FILES = 831,
  UPDATE_FILE = 832,
  FOLLOW_UP_FILE = 833,
  UPDATE_FILE_PRC = 911,
  UPDATE_FILE_CQ = 912,
  ATTACHMENT_FILE_CQ = 913,
  ATTACHMENT_FILE_PRC = 943,
  UPDATE_FILE_RNC = 1412,
  UPDATE_SAT_FOREIGN_TRADE = 1415,
  FOLLOW_UP_SAT_FOREIGN_TRADE = 1416,
}
