import { useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import React, {
  Ref,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import PackageReferenceContent from '../PackageReferenceContent';
import { IPackageReferenceContentRef } from '../PackageReferenceContent/interfaces';
import {
  PackageReferenceFieldsPermissions,
  ProductPackage,
} from '../../../interfaces';
import Button from '../../../../../../../components/Button';
import {
  addReferenceReducer,
  addReferenceReducerInitialState,
} from './reducers';
import { AddReferenceReducerActionKind, IAddReferenceRef } from './interfaces';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import { createProductPackageReferenceQuery } from './queries';
import { ProductPackageUnitData } from '../PackageReferenceContent/PackageSizeBarcode/PackageTypeCalculated/interfaces';

interface IAddReferenceProps {
  ref: Ref<IAddReferenceRef>;
  referencesRefetch(): Promise<void>;
  productPackage: ProductPackage;
  stCode: string;
  packageUnitData: ProductPackageUnitData;
  fieldsPermissions: PackageReferenceFieldsPermissions;
}

const AddReference: React.FC<IAddReferenceProps> = React.forwardRef(
  (
    {
      referencesRefetch,
      productPackage,
      stCode,
      packageUnitData,
      fieldsPermissions,
    },
    ref,
  ) => {
    const [createProductReferenceMutation] = useMutation(
      createProductPackageReferenceQuery,
    );

    const referenceContentRef = useRef<IPackageReferenceContentRef>(null);
    const { showSuccess, showError } = useRefHook();

    const [addReferenceState, addReferenceDispatch] = useReducer(
      addReferenceReducer,
      addReferenceReducerInitialState,
    );

    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
      toggleDialog: (lastReferenceIndex: number) => {
        addReferenceDispatch({
          type: AddReferenceReducerActionKind.OPEN_DIALOG,
          payload: {
            idProductPackage: productPackage.idProductPackage,
            lastReferenceIndex,
          },
        });
      },
    }));

    function closeModal() {
      addReferenceDispatch({
        type: AddReferenceReducerActionKind.CLOSE_DIALOG,
      });
    }

    function setReferenceNumbers() {
      const referenceIndex = addReferenceState.lastReferenceIndex + 1;

      referenceContentRef.current?.setSizeBarcodesReferences(
        referenceIndex,
        stCode,
      );
    }

    async function handleSaveReference() {
      try {
        await referenceContentRef.current?.validateForm();
      } catch (error) {
        return;
      }

      const formData = referenceContentRef.current?.getDataIfChanged();

      if (!formData) return;

      setLoading(true);

      try {
        await createProductReferenceMutation({
          variables: {
            data: {
              idProductPackage: productPackage.idProductPackage,
              ...formData.packageReferenceContent,
              productPackageSizeBarcodes: formData.sizeBarcodesToCreate,
            },
          },
        });

        await referencesRefetch();

        showSuccess({ summary: 'Reference created' });

        closeModal();
      } catch (error) {
        showError({
          summary: 'Error while creating reference',
          detail: error.message,
        });
      } finally {
        setLoading(false);
      }
    }

    const dialogFooter = () => {
      return (
        <div className="p-grid">
          <small className="p-col-8 s-warning-text">
            <p className="p-text-left">
              If you have <b>unsaved changes</b> on any references at this
              package, adding a new reference to it will cause <b>data loss</b>
            </p>
          </small>
          <span className="p-col" />
          <span className="p-col-3 p-d-flex">
            <Button
              label="Save"
              icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
              onClick={() => handleSaveReference()}
              disabled={loading}
            />
            <Button
              label="Cancel"
              severity="danger"
              icon="pi pi-times"
              onClick={() => closeModal()}
              disabled={loading}
            />
          </span>
        </div>
      );
    };

    return (
      <Dialog
        header="Add Reference"
        visible={addReferenceState.displayDialog}
        style={{ width: '800px' }}
        modal
        onHide={() => closeModal()}
        footer={dialogFooter()}
        closable={false}
      >
        {!!addReferenceState.packageReference && (
          <PackageReferenceContent
            ref={referenceContentRef}
            setReferenceNumbers={setReferenceNumbers}
            idTypePackage={productPackage.idTypePackage}
            packageReference={addReferenceState.packageReference}
            packageUnitData={packageUnitData}
            fieldsPermissions={fieldsPermissions}
          />
        )}
      </Dialog>
    );
  },
);

export default AddReference;
