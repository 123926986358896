/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Column } from 'primereact/column';
import { useHistory, useLocation } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import {
  DataTable,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortParams,
} from 'primereact/datatable';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import xlsx from 'xlsx';
import { Container } from './styles';
import PageHeader from '../../../components/PageHeader';
import Grid from '../../../components/Grid';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import { useRefHook } from '../../../hooks/useRefHook';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import ILazyParams from '../../../services/lazyParams';
import ToastLife from '../../../shared/enums/toastLife';
import { tableSiscomexRoles } from '../../../shared/roles/tableSiscomex';
import saveFileAsExcel from '../../../utils/saveFileAsExcel';
import Button from '../../../components/Button';
import userHasPermission from '../../../utils/userHasPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { useAuth } from '../../../hooks/useAuth';
import { parseFloatColumnPt } from '../../../utils/gridColumnsParse';
import { searchDelayMiliseconds } from '../../../config/pagination';
import useTitle from '../../../hooks/useTitle';
import { gridConstants } from '../../../components/Grid/constants';

const pageTitle = 'List of Tabela Siscomex';

const TableSiscomexes: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:tableSiscomexGridColumns';

  // Redirect
  const history = useHistory();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia a grid
  const gridRef = useRef<DataTable>(null);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Permissions de TableSiscomex
  const { idPermissionExportListTableSiscomex } =
    tableSiscomexRoles.permissions;

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsTableSiscomex = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    tableSiscomexRoles.idEntity,
  );

  const roleEntityPermissions = userPermissionsTableSiscomex.userPermissions;

  function hasPermission(idPermission: number): boolean {
    return userHasPermission(idPermission, roleEntityPermissions);
  }

  // URL params
  const location = useLocation();

  // Colunas da grid
  const columns = useMemo(() => {
    return [
      { field: 'idTableSiscomex', header: 'Addition' },
      { field: 'taxValue', header: 'Siscomex Value' },
      { field: 'idCurTax2.abbreviation', header: 'Currency' },
      { field: 'idBcValue2.description', header: 'BC Value' },
      { field: 'bcCif', header: 'BC CIF' },
      { field: 'updatedAt', header: 'Last Updated' },
    ];
  }, []);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  // Busca dados de pagina inicial da url
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );

  // Busca dados de quantidade da url
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Estado inicial de lazy params
  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ILazyParams>(initialLazyParams);

  // Busca global na grid
  const [globalFilter, setGlobalFilter] = useState('');

  // AirFreights selecionados
  const [selectedAirFreights, setSelectedAirFreights] = useState([]);

  // Estado de scrollHeight da grid
  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );
  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar TableSiscomex
  const listTableSiscomexQuery = gql`
    query listAllTableSiscomex(
      $listAllTableSiscomexInput: ListAllTableSiscomexInput!
    ) {
      listAllTableSiscomex(
        listAllTableSiscomexInput: $listAllTableSiscomexInput
      ) {
        data {
          idTableSiscomex
          taxValue
          idCurTax2 {
            abbreviation
          }
          idBcValue2 {
            description
          }
          bcCif
          createdAt
          updatedAt
        }
        items
      }
    }
  `;

  /**
   * Busca TableSiscomex
   */
  const { loading: tableSiscomexLoading, data: tableSiscomexData } = useQuery(
    listTableSiscomexQuery,
    {
      variables: {
        listAllTableSiscomexInput: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          globalSearch: lazyParams.globalFilter,
        },
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Tabela Siscomex data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  /**
   * Efetua parse de coluna de Data
   * @param rowData dados da linha
   * @param field dados do campo
   * @returns Coluna em formato de data
   */
  const parseDateColumm = (rowData: any) => {
    let date: string;
    if (rowData.updatedAt) {
      date = new Date(rowData.updatedAt).toLocaleString();
    } else {
      date = new Date(rowData.createdAt).toLocaleString();
    }

    return <p>{date}</p>;
  };

  /**
   * Valida se coluna active é true ou false
   * @param rowData Dados da linha
   * @returns Campo com true ou false
   */
  const parseBooleanColumn = (rowData: any, field: any) => {
    return <p>{rowData[field.field] ? 'Yes' : 'No'}</p>;
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'taxValue':
        return parseFloatColumnPt;
      case 'updatedAt':
        return parseDateColumm;
      case 'bcCif':
        return parseBooleanColumn;
      default:
        return undefined;
    }
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'idTableSiscomex':
        return { width: '40px' };
      case 'idBcValue2.description':
        return { width: '70px' };
      case 'idCurTax2.abbreviation':
      case 'taxValue':
      case 'bcCif':
        return { width: '60px' };
      case 'updatedAt':
        return { width: '80px' };
      default:
        return { width: '100px' };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          style={handleColumnSize(col.field)}
          header={col.header}
          body={handleColumn(col.field)}
          sortable
        />
      )
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeParams) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do icms clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(
      `/logistics/tableSiscomexes/${e.data.idTableSiscomex}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortParams) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Exporta dados para xlsx
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function exportToXlsx(tableSiscomexExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    tableSiscomexExportData.listAllTableSiscomex.data.forEach(
      (tableSiscomex: any) => {
        tableSiscomex.addition = tableSiscomex.idTableSiscomex;
        tableSiscomex.siscomexValue = tableSiscomex.taxValue;
        tableSiscomex.currency = tableSiscomex.idCurTax2
          ? tableSiscomex.idCurTax2.abbreviation
          : undefined;
        tableSiscomex.bcValue = tableSiscomex.idBcValue2
          ? tableSiscomex.idBcValue2.description
          : undefined;
        tableSiscomex.bcCif = tableSiscomex.bcCif ? 'Yes' : 'No';
        tableSiscomex.updatedAt =
          tableSiscomex.updatedAt ?? tableSiscomex.createdAt;

        columnsToRemove.forEach(column => {
          switch (column.field) {
            case 'idCurTax2.abbreviation':
              delete tableSiscomex.currency;
              break;
            case 'taxValue':
              delete tableSiscomex.siscomexValue;
              break;
            case 'idBcValue2.description':
              delete tableSiscomex.bcValue;
              break;
            default:
              delete tableSiscomex[column.field];
              break;
          }
        });

        // Remover colunas __typename e idTableSiscomex
        delete tableSiscomex.__typename;
        delete tableSiscomex.idTableSiscomex;
        delete tableSiscomex.taxValue;
        delete tableSiscomex.idTaxName2;
        delete tableSiscomex.idCurTax2;
        delete tableSiscomex.idBcValue2;
        delete tableSiscomex.createdAt;
      },
    );

    // Busca ordenacao das colunas da grid
    const gridColumns =
      gridRef.current?.state.columnOrder ??
      selectedColumns.map(column => column.field);
    const columnsOrder = gridColumns.filter(
      (item: string) =>
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'idTableSiscomex':
          columnsOrder[index] = 'addition';
          break;
        case 'taxValue':
          columnsOrder[index] = 'siscomexValue';
          break;
        case 'idCurTax2.abbreviation':
          columnsOrder[index] = 'currency';
          break;
        case 'idBcValue2.description':
          columnsOrder[index] = 'bcValue';
          break;
        default:
          break;
      }
    });

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(
      tableSiscomexExportData.listAllTableSiscomex.data,
      { header: columnsOrder },
    );
    worksheet['!autofilter'] = { ref: 'A1:I1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveFileAsExcel(excelBuffer, 'tableSiscomex');
  }

  /**
   * Busca TableSiscomex para exportar para XLSX
   */
  const [
    loadTableSiscomexToExport,
    { loading: TableSiscomexToExportLoading, data: TableSiscomexToExportData },
  ] = useLazyQuery(listTableSiscomexQuery, {
    onCompleted: () => {
      exportToXlsx(TableSiscomexToExportData);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while exporting Tabela Siscomex',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  return (
    <Container>
      <PageHeader
        title="List of Tabela Siscomex"
        fixedStickyButtons={fixedStickyButtons}
      >
        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {/* Botao export para XLSX */}
        {hasPermission(idPermissionExportListTableSiscomex) && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={TableSiscomexToExportLoading}
            onClick={() => {
              loadTableSiscomexToExport({
                variables: {
                  listAllTableSiscomexInput: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    globalSearch: lazyParams.globalFilter,
                  },
                },
              });
            }}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for an Item"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        className="grid"
        name="tableSiscomex"
        lazy
        totalRecords={
          !tableSiscomexData ? 0 : tableSiscomexData.listAllTableSiscomex.items
        }
        value={
          !tableSiscomexData
            ? undefined
            : tableSiscomexData.listAllTableSiscomex.data
        }
        globalFilter={globalFilter}
        emptyMessage="No Tabela Siscomex found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={!tableSiscomexData ? undefined : lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedAirFreights}
        onSelectionChange={e => setSelectedAirFreights(e.value)}
      >
        {dynamicColumns}
      </Grid>
      {tableSiscomexLoading && <Loading />}
    </Container>
  );
};
export default TableSiscomexes;
