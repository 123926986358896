import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { array, number, object, string } from 'yup';
import { useMutation } from '@apollo/client';
import {
  Attachment,
  PackageReferenceFieldsPermissions,
  ProductPackageReference,
} from '../../../interfaces';
import FormInput from '../../../../../../../components/FormInput';
import FormAsyncSelect from '../../../../../../../components/FormAsyncSelect';
import { asyncSelectLoadDomains } from '../../../../../../../shared/querys/domain';
import {
  DomainGroup,
  DomainGroupFashion,
} from '../../../../../../../shared/enums/domainGroup';
import { loadDomainGroups } from '../../../../../../../shared/querys/domainGroup';
import { getEnumNumericValues } from '../../../../../../../utils/getEnumValues';
import Card from '../../../../../../../components/Card';
import PackageSizeBarcode from './PackageSizeBarcode';
import {
  IPackageSizeBarcodeRef,
  PackageSizeBarcodeFormData,
} from './PackageSizeBarcode/interfaces';
import {
  Domain,
  IPackageReferenceContentRef,
  PackageReferenceContentReducerActionKind,
  PackageReferenceFormData,
} from './interfaces';
import { packageReferenceContentReducer } from './reducers';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../../../../utils/getValidationErrors';
import { objectsAreEqual } from '../../../../../../../utils/objectsAreEqual';
import { ProductPackageUnitData } from './PackageSizeBarcode/PackageTypeCalculated/interfaces';
import Empty from '../../../../../../../components/Empty';
import Image from '../../../../../../../components/PageHeader/Image';
import { FileUploadResponse } from '../../../../../../../components/FileUpload/interfaces';
import imagePlaceholder from '../../../../../../../assets/imagePlaceholder.svg';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import { uploadProductPackageReferenceAttachmentQuery } from '../queries';
import Loading from '../../../../../../../components/Loading';

interface IPackageReferenceContentProps {
  packageReference?: ProductPackageReference;
  idTypePackage?: number;
  ref: Ref<IPackageReferenceContentRef>;
  setReferenceNumbers(): void;
  packageUnitData?: ProductPackageUnitData;
  fieldsPermissions: PackageReferenceFieldsPermissions;
}

const PackageReferenceContent: React.FC<IPackageReferenceContentProps> =
  forwardRef(
    (
      {
        packageReference,
        idTypePackage,
        setReferenceNumbers,
        packageUnitData,
        fieldsPermissions,
      },
      ref,
    ) => {
      const { showSuccess, showError } = useRefHook();

      const [attachment, setAttachment] = useState<Attachment | undefined>(
        packageReference?.idAttachment2,
      );

      const [referenceContentState, referenceContentDispatch] = useReducer(
        packageReferenceContentReducer,
        {
          productPackageSizeBarcodes:
            packageReference?.productPackageSizeBarcodes ?? [],
          sizesToDelete: [],
          idSizeGroup: packageReference?.idSizeGroup,
        },
      );

      const [uploadProductPackageReferenceAttachment, { loading }] =
        useMutation(uploadProductPackageReferenceAttachmentQuery);

      const formRef = useRef<FormHandles>(null);

      const packageSizeBarcodesRef = useRef<IPackageSizeBarcodeRef[] | null[]>(
        [],
      );

      function onSubmit(formData: PackageReferenceFormData) {
        const data = formData;

        // O array de package size barcodes eh controlado pelo componente
        // PackageSizeBarcode, portanto deve ser removido do objeto de
        // formulario para evitar validacoes incorretas
        delete data.productPackageSizeBarcodes;

        if (packageReference && objectsAreEqual(data, packageReference)) {
          return undefined;
        }

        return data;
      }

      useImperativeHandle(ref, () => ({
        validateForm: async () => {
          const formData = formRef.current?.getData();

          if (!formData) return false;

          const schema = object().shape({
            colourEn: string().required(requiredFieldErrorMessage),
            idGender: number().nullable().required(requiredFieldErrorMessage),
            idSizeGroup: number()
              .nullable()
              .required(requiredFieldErrorMessage),
            productPackageSizeBarcodes: array().min(
              1,
              requiredFieldErrorMessage,
            ),
          });

          try {
            formRef.current?.setErrors({});

            await schema.validate(formData, { abortEarly: false });

            return true;
          } catch (error) {
            const errors = getValidationErrors(error);

            formRef.current?.setErrors(errors);

            const firstError = error.inner[0];

            const inputWithError = formRef.current?.getFieldRef(
              firstError.path,
            );

            if (inputWithError.focus) {
              inputWithError.focus();
            } else if (inputWithError.inputRef?.current?.focus) {
              inputWithError.inputRef?.current?.focus();
            }

            throw error;
          }
        },
        getDataIfChanged: () => {
          const sizeBarcodesToCreate: PackageSizeBarcodeFormData[] = [];
          const sizeBarcodesToUpdate: PackageSizeBarcodeFormData[] = [];

          const referenceData =
            formRef.current?.getData() as PackageReferenceFormData;

          const referenceFormData = onSubmit(referenceData);

          for (
            let index = 0;
            index < packageSizeBarcodesRef.current.length;
            index += 1
          ) {
            const formData =
              packageSizeBarcodesRef.current[index]?.getDataIfChanged();

            if (formData) {
              // Se o ID de package size barcode for do tipo nulo, significa que
              // foi criado pelo frontend e nao esta persistido no banco
              if (!formData.idPackageSizeBarcode) {
                sizeBarcodesToCreate.push(formData);
              } else {
                sizeBarcodesToUpdate.push(formData);
              }
            }
          }

          if (
            !referenceFormData &&
            !sizeBarcodesToCreate.length &&
            !sizeBarcodesToUpdate.length &&
            !referenceContentState.sizesToDelete.length
          ) {
            return undefined;
          }

          const response = {
            packageReferenceContent: referenceFormData,
            sizeBarcodesToCreate,
            sizeBarcodesToUpdate,
            sizeBarcodesToDelete: referenceContentState.sizesToDelete,
          };

          if (typeof packageReference?.idPackageReference === 'string') {
            return response;
          }

          return {
            ...response,
            idPackageReference: packageReference?.idPackageReference,
          };
        },
        getPackageSizeBarcodesCount: () => {
          return referenceContentState.productPackageSizeBarcodes?.length ?? 0;
        },
        setSizeBarcodesReferences: (startFrom: number, stCode: string) => {
          packageSizeBarcodesRef.current.forEach((barcodeRef, index) => {
            barcodeRef?.setReferenceNumber(startFrom + index, stCode);
          });
        },
      }));

      function handleAddSize(size: Domain) {
        if (!size) return;

        referenceContentDispatch({
          type: PackageReferenceContentReducerActionKind.ADD_SIZE,
          payload: {
            idPackageReference: packageReference?.idPackageReference,
            size,
          },
        });

        // Adiciona timeout para garantir que componente de Size Barcode seja
        // renderizado antes de chamar funcao
        setTimeout(() => setReferenceNumbers(), 1);
      }

      function handleRemoveSize(size: Domain) {
        if (!size) return;

        referenceContentDispatch({
          type: PackageReferenceContentReducerActionKind.DELETE_SIZE,
          payload: {
            size,
          },
        });

        // Adiciona timeout para garantir que componente de Size Barcode seja
        // renderizado antes de chamar funcao
        setTimeout(() => setReferenceNumbers(), 1);
      }

      function handleChangeSizeGroup(idSizeGroup: number) {
        referenceContentDispatch({
          type: PackageReferenceContentReducerActionKind.CHANGE_SIZE_GROUP,
          payload: {
            idSizeGroup,
          },
        });

        formRef.current?.clearField('productPackageSizeBarcodes');
      }

      async function handleUploadAttachment(data: FileUploadResponse) {
        try {
          const response = await uploadProductPackageReferenceAttachment({
            variables: {
              data: {
                idPackageReference: packageReference?.idPackageReference,
                uploadedFile: data.serverName,
              },
            },
          });
          setAttachment(response.data?.uploadProductPackageReferenceAttachment);
          showSuccess({
            summary: 'Package Reference attachment updated',
          });
        } catch (err) {
          showError({
            summary: 'Error while updating attachment',
            detail: err.message,
          });
        }
      }

      return (
        <div className="p-col-12 p-d-flex p-flex-column">
          <div className="p-d-flex">
            <Form
              className="p-formgrid p-grid"
              ref={formRef}
              initialData={packageReference}
              onSubmit={data => onSubmit(data)}
            >
              {fieldsPermissions.colourEn.view && (
                <FormInput
                  className="p-field p-col-4"
                  name="colourEn"
                  label="Color (EN)"
                  required
                />
              )}

              {fieldsPermissions.colourPt.view && (
                <FormInput
                  className="p-field p-col-4"
                  name="colourPt"
                  label="Color (PT)"
                />
              )}

              {fieldsPermissions.idGender.view && (
                <FormAsyncSelect
                  className="p-field p-col-4"
                  name="idGender"
                  label="Gender"
                  required
                  loadOptions={asyncSelectLoadDomains}
                  getOptionLabel={option => option.description}
                  getOptionValue={option => option.idDomain}
                  initialValue={{
                    idDomain: packageReference?.idGender,
                    description: packageReference?.idGender2?.description,
                  }}
                  additional={{
                    id: DomainGroup.GENDER,
                  }}
                  noOptionsMessage={() => 'No Genders found'}
                  menuPosition="fixed"
                />
              )}

              {fieldsPermissions.idSizeGroup.view && (
                <FormAsyncSelect
                  className="p-field p-col-4"
                  name="idSizeGroup"
                  label="Size Group"
                  required
                  loadOptions={loadDomainGroups}
                  getOptionLabel={option => option.description}
                  getOptionValue={option => option.idDomainGroup}
                  initialValue={{
                    idDomainGroup: packageReference?.idSizeGroup,
                    description: packageReference?.idSizeGroup2?.description,
                  }}
                  additional={{
                    ids: getEnumNumericValues(DomainGroupFashion),
                  }}
                  noOptionsMessage={() => 'No Size Groups found'}
                  onValueChange={e => handleChangeSizeGroup(e.idDomainGroup)}
                  menuPosition="fixed"
                />
              )}

              {fieldsPermissions.size.view && (
                <FormAsyncSelect
                  className="p-field p-col-8"
                  name="productPackageSizeBarcodes"
                  label="Size"
                  loadOptions={asyncSelectLoadDomains}
                  getOptionLabel={option => option.description}
                  getOptionValue={option => option.idDomain}
                  additional={{
                    id: referenceContentState.idSizeGroup,
                  }}
                  isMulti
                  cacheUniqs={[referenceContentState.idSizeGroup]}
                  onValueRemove={e => handleRemoveSize(e)}
                  onValueAdd={e => handleAddSize(e)}
                  noOptionsMessage={() => 'No Sizes found'}
                  initialValue={packageReference?.productPackageSizeBarcodes?.map(
                    sizeBarcode => {
                      return {
                        idDomain: sizeBarcode.size,
                        description: sizeBarcode.size2?.description,
                      };
                    },
                  )}
                  required
                  readOnly={!referenceContentState.idSizeGroup}
                  menuPosition="fixed"
                />
              )}
            </Form>
            {typeof packageReference?.idPackageReference === 'number' && (
              <Image
                className="p-mr-0 p-ml-3"
                onChange={e => handleUploadAttachment(e[0])}
                src={attachment?.url || imagePlaceholder}
                alt={`Package reference image ${attachment?.nameGivenByUser}`}
                openOnClick
              />
            )}
          </div>

          {idTypePackage ? (
            referenceContentState.productPackageSizeBarcodes?.map(
              (sizeBarcode, index) => {
                return (
                  <Card
                    key={sizeBarcode.idPackageSizeBarcode}
                    className="p-mb-2"
                  >
                    <PackageSizeBarcode
                      ref={el => {
                        packageSizeBarcodesRef.current[index] = el;
                      }}
                      packageSizeBarcode={sizeBarcode}
                      idTypePackage={idTypePackage}
                      packageUnitData={packageUnitData}
                      fieldsPermissions={fieldsPermissions}
                    />
                  </Card>
                );
              },
            )
          ) : (
            <Empty message="Please set a package type before making changes to barcodes" />
          )}
          {loading && <Loading />}
        </div>
      );
    },
  );

export default PackageReferenceContent;
