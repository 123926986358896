import {
  TabPanel,
  TabView,
  TabViewProps,
  TabViewTabChangeParams,
} from 'primereact/tabview';
import React from 'react';

export interface ITabViewAsyncPageParams {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

interface ITabViewAsyncProps extends TabViewProps {
  totalRecords: number;
  first: number;
  rows: number;
  loading: boolean;
  page: number;
  itemsToRenderLength: number;
  onPage(e: ITabViewAsyncPageParams): void;
  onTabChange(e: TabViewTabChangeParams): void;
}

const TabViewAsync: React.FC<ITabViewAsyncProps> = ({
  rows,
  totalRecords,
  first,
  loading,
  page,
  itemsToRenderLength,
  children,
  onPage,
  onTabChange,
  ...rest
}) => {
  const pageCount = Math.ceil(totalRecords / rows);

  const hasMoreItems = pageCount !== 0 && page + 1 !== pageCount;

  function handleLoadMore() {
    const onPageParams: ITabViewAsyncPageParams = {
      first: first + rows,
      page: page + 1,
      pageCount,
      rows,
    };

    onPage(onPageParams);
  }

  function handleTabChange(e: TabViewTabChangeParams) {
    // Valida se tab selecionada foi a load more
    if (e.index === itemsToRenderLength) {
      handleLoadMore();
    }

    onTabChange(e);
  }

  return (
    <TabView {...rest} onTabChange={e => handleTabChange(e)}>
      {children}

      <TabPanel
        header={loading ? '' : 'Load more'}
        disabled={loading}
        leftIcon={loading ? 'pi pi-spin pi-spinner' : ''}
        headerStyle={!hasMoreItems ? { display: 'none' } : undefined}
      />
    </TabView>
  );
};

export default TabViewAsync;
