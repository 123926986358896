import { Form } from '@unform/web';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import FormBooleanRadio from '../../../../components/FormBooleanRadio';
import FormInput from '../../../../components/FormInput';
import FormInputNumber from '../../../../components/FormInputNumber';
import FormTitle from '../../../../components/FormTitle';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { asyncSelectLoadDomains } from '../../../../shared/querys/domain';
import { satsRoles } from '../../../../shared/roles/sat';
import { IUserFieldsAndPermissionsResponse } from '../../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../../utils/userHasPermission';
import { ISat, SatItemsOrderAreaFieldsPermissions } from '../interfaces';
import CompareFile from './CompareFile';
import Quotations from './Quotations';
import { FormContainer, Group } from './styles';
import ConfirmOrderFiles from './ConfirmOrderFiles';

interface ISatItemsProps extends PageTabContainerProps {
  itemsGrid: ReactNode;
  setPageLoading: Dispatch<SetStateAction<boolean>>;
  sat: ISat;
  userPermissionsSat: IUserFieldsAndPermissionsResponse;
  orderAreaFieldsPermissions: SatItemsOrderAreaFieldsPermissions;
  isSatCancelled: boolean;
}

const SatItems: React.FC<ISatItemsProps> = ({
  itemsGrid,
  selected,
  sat,
  userPermissionsSat,
  orderAreaFieldsPermissions,
  isSatCancelled,
}) => {
  const userCanSeeConfirmOrderFileSunPi = userHasPermission(
    satsRoles.permissions.idPermissionConfirmOrderFileSunPi,
    userPermissionsSat.userPermissions,
  );

  const userCanSeeConfirmOrderFileCotPi = userHasPermission(
    satsRoles.permissions.idPermissionConfirmOrderFileCotPi,
    userPermissionsSat.userPermissions,
  );

  const userCanSeeConfirmOrderFiles =
    userCanSeeConfirmOrderFileSunPi || userCanSeeConfirmOrderFileCotPi;

  const userCanSeeCompareFile = userHasPermission(
    satsRoles.permissions.idPermissionCompareFiles,
    userPermissionsSat.userPermissions,
  );

  const userCanSeeSatQuotation = userHasPermission(
    satsRoles.permissions.idPermissionSatQuotation,
    userPermissionsSat.userPermissions,
  );

  const userCanSeeNegotiationArea =
    userCanSeeCompareFile ||
    userCanSeeSatQuotation ||
    userCanSeeConfirmOrderFiles;

  const userCanSeeMainOrderArea =
    orderAreaFieldsPermissions.mainProduct.view ||
    orderAreaFieldsPermissions.idCategory.view ||
    orderAreaFieldsPermissions.typeOfOrder.view ||
    orderAreaFieldsPermissions.idSatOrigin.view ||
    orderAreaFieldsPermissions.packagesNotes.view ||
    orderAreaFieldsPermissions.remarks.view ||
    orderAreaFieldsPermissions.indSamples.view ||
    orderAreaFieldsPermissions.samplesLeadTime.view ||
    orderAreaFieldsPermissions.idClient.view ||
    orderAreaFieldsPermissions.importerName.view;

  const userCanSeeClientsPayment =
    orderAreaFieldsPermissions.idClientIncoterm.view ||
    orderAreaFieldsPermissions.paymentTermAdvance.view ||
    orderAreaFieldsPermissions.paymentTermBalance.view ||
    orderAreaFieldsPermissions.idPaymentTermCondition.view;

  const userCanSeeExporter =
    orderAreaFieldsPermissions.idPurchaseIncoterm.view ||
    orderAreaFieldsPermissions.idSupplierExporter.view ||
    orderAreaFieldsPermissions.exporterAddress.view ||
    orderAreaFieldsPermissions.productionTime.view ||
    orderAreaFieldsPermissions.satSuppliers.view;

  const userCanSeeSchedule =
    orderAreaFieldsPermissions.estimatedInspectionDate.view ||
    orderAreaFieldsPermissions.estimatedShipmentDate.view ||
    orderAreaFieldsPermissions.estimatedArrivalDate.view ||
    orderAreaFieldsPermissions.estimatedArrivalClientDate.view;

  const userCanSeeOrderArea =
    userCanSeeMainOrderArea ||
    userCanSeeClientsPayment ||
    userCanSeeExporter ||
    userCanSeeSchedule;

  return (
    <PageTabContainer selected={selected}>
      {userCanSeeOrderArea && (
        <FormContainer>
          <Form initialData={sat} onSubmit={() => ''}>
            <Group className="p-grid">
              <FormTitle name="Order Area" className="p-col-12" />
              {orderAreaFieldsPermissions.mainProduct.view && (
                <FormInput
                  name="mainProduct"
                  label="Main Product"
                  readOnly
                  value={sat.mainProduct ?? ''}
                  defaultValue={undefined}
                  className="p-col-3"
                />
              )}
              {orderAreaFieldsPermissions.idCategory.view && (
                <FormInput
                  name="idCategory"
                  label="SAT Category"
                  readOnly
                  className="p-col-3"
                  value={sat.idCategory2?.name ?? ''}
                  defaultValue={undefined}
                />
              )}
              {orderAreaFieldsPermissions.typeOfOrder.view && (
                <FormInput
                  name="typeOfOrder"
                  label="Type of Order"
                  readOnly
                  value={sat.typeOfOrder2?.description ?? ''}
                  defaultValue={undefined}
                  className="p-col-3"
                />
              )}
              {orderAreaFieldsPermissions.idSatOrigin.view && (
                <FormInput
                  name="idSatOrigin2"
                  label="Replacement From"
                  readOnly
                  value={sat.idSatOrigin2?.satNumber ?? ''}
                  defaultValue={undefined}
                  className="p-col-3"
                />
              )}
              {orderAreaFieldsPermissions.packagesNotes.view && (
                <FormInput
                  name="packagesNotes"
                  label="Package Notes"
                  readOnly
                  value={sat.packagesNotes ?? ''}
                  defaultValue={undefined}
                  className="p-col-6"
                />
              )}
              {orderAreaFieldsPermissions.remarks.view && (
                <FormInput
                  name="remarks"
                  label="Remarks"
                  readOnly
                  value={sat.remarks ?? ''}
                  defaultValue={undefined}
                  className="p-col-6"
                />
              )}
              {orderAreaFieldsPermissions.indSamples.view && (
                <FormBooleanRadio
                  name="indSamples"
                  label="Samples"
                  readOnly
                  value={sat.indSamples}
                  className="p-col-3"
                />
              )}
              {orderAreaFieldsPermissions.samplesLeadTime.view && (
                <FormInput
                  name="samplesLeadTime"
                  label="Samples Lead Time"
                  readOnly
                  value={sat.samplesLeadTime ?? ''}
                  defaultValue={undefined}
                  className="p-col-3"
                />
              )}
            </Group>

            <Group className="p-grid">
              {orderAreaFieldsPermissions.idClient.view && (
                <FormInput
                  name="idClient"
                  label="Client/Notify"
                  readOnly
                  value={sat.idClient2?.name ?? ''}
                  defaultValue={undefined}
                  className="p-col-6"
                />
              )}
              {orderAreaFieldsPermissions.importerName.view && (
                <FormInput
                  name="idImporter"
                  label="Importer/Consignee"
                  readOnly
                  value={sat.idImporter2?.name ?? ''}
                  defaultValue={undefined}
                  className="p-col-6"
                />
              )}
            </Group>

            {userCanSeeClientsPayment && (
              <Group className="p-grid">
                <h2 className="p-col-12">Client&apos;s Payment</h2>
                {orderAreaFieldsPermissions.idClientIncoterm.view && (
                  <FormInput
                    name="idClientIncoterm"
                    label="Selling Incoterm"
                    readOnly
                    value={sat.idClientIncoterm2?.description ?? ''}
                    defaultValue={undefined}
                    className="p-col-3"
                  />
                )}
                {orderAreaFieldsPermissions.paymentTermAdvance.view && (
                  <FormInputNumber
                    name="paymentTermAdvance"
                    label="Payment Term Advance (%)"
                    suffix="%"
                    readOnly
                    value={sat.paymentTermAdvance ?? ''}
                    defaultValue={undefined}
                    className="p-col-3"
                  />
                )}
                {orderAreaFieldsPermissions.paymentTermBalance.view && (
                  <FormInputNumber
                    name="paymentTermBalance"
                    label="Payment Term Balance (%)"
                    suffix="%"
                    readOnly
                    value={sat.paymentTermBalance ?? ''}
                    defaultValue={undefined}
                    className="p-col-3"
                  />
                )}
                {orderAreaFieldsPermissions.idPaymentTermCondition.view && (
                  <FormInput
                    name="idPaymentTermCondition"
                    label="Payment Term Condition"
                    readOnly
                    value={sat.idPaymentTermCondition2?.description ?? ''}
                    defaultValue={undefined}
                    className="p-col-3"
                  />
                )}
              </Group>
            )}

            {userCanSeeExporter && (
              <Group className="p-grid">
                <h2 className="p-col-12">Exporter</h2>
                {orderAreaFieldsPermissions.idPurchaseIncoterm.view && (
                  <FormInput
                    name="idPurchaseIncoterm"
                    label="Purchase Incoterm"
                    readOnly
                    value={sat.idPurchaseIncoterm2?.description ?? ''}
                    defaultValue={undefined}
                    className="p-col-3"
                  />
                )}
                {orderAreaFieldsPermissions.idSupplierExporter.view && (
                  <FormInput
                    name="idSupplierExporter"
                    label="Exporter"
                    readOnly
                    value={sat.idSupplierExporter2?.name ?? ''}
                    defaultValue={undefined}
                    className="p-col-3"
                  />
                )}
                {orderAreaFieldsPermissions.exporterAddress.view && (
                  <FormInput
                    name="exporterAddress"
                    label="Exporter Address"
                    value={sat.exporterAddress ?? ''}
                    defaultValue={undefined}
                    readOnly
                    className="p-col-3"
                  />
                )}
                {orderAreaFieldsPermissions.productionTime.view && (
                  <FormInputNumber
                    name="productionTime"
                    label="Production Time"
                    value={sat.productionTime ?? ''}
                    defaultValue={undefined}
                    readOnly
                    className="p-col-3"
                  />
                )}

                {orderAreaFieldsPermissions.satSuppliers.view && (
                  <FormAsyncSelect
                    name="satSuppliers"
                    className="p-col-3"
                    label="SUN Number"
                    isMulti
                    loadOptions={asyncSelectLoadDomains}
                    getOptionLabel={(option: any) =>
                      `SUN${option.idSupplier.toString().padStart(5, '0')} `
                    }
                    getOptionValue={(option: any) => option.idSatSupplier}
                    initialValue={sat.satSuppliers}
                    readOnly
                  />
                )}
                {orderAreaFieldsPermissions.satSuppliersNames.view && (
                  <FormInput
                    name="satSuppliersNames"
                    label="Supplier"
                    readOnly
                    defaultValue={undefined}
                    value={sat.satSuppliers
                      ?.map(supplier => supplier.idSupplier2.name)
                      .join('; ')}
                    className="p-col-9"
                  />
                )}
              </Group>
            )}

            {userCanSeeSchedule && (
              <Group className="p-grid">
                <h2 className="p-col-12">Schedule</h2>

                {orderAreaFieldsPermissions.estimatedInspectionDate.view && (
                  <FormInput
                    name="estimatedInspectionDate"
                    label="Estimated Inspection Date"
                    type="date"
                    readOnly
                    defaultValue={undefined}
                    value={sat.estimatedInspectionDate?.toLocaleString()}
                    className="p-col-3"
                  />
                )}

                {orderAreaFieldsPermissions.estimatedShipmentDate.view && (
                  <FormInput
                    name="estimatedShipmentDate"
                    label="Estimated Shipment Date"
                    type="date"
                    readOnly
                    defaultValue={undefined}
                    value={sat.estimatedShipmentDate?.toLocaleString()}
                    className="p-col-3"
                  />
                )}

                {orderAreaFieldsPermissions.estimatedArrivalDate.view && (
                  <FormInput
                    name="estimatedArrivalDate"
                    label="Estimated Arrival Date"
                    type="date"
                    readOnly
                    defaultValue={undefined}
                    value={sat.estimatedArrivalDate?.toLocaleString()}
                    className="p-col-3"
                  />
                )}

                {orderAreaFieldsPermissions.estimatedArrivalClientDate.view && (
                  <FormInput
                    name="estimatedArrivalClientDate"
                    label="Estimated Arrival At Client Date"
                    type="date"
                    readOnly
                    defaultValue={undefined}
                    value={sat.estimatedArrivalClientDate?.toLocaleString()}
                    className="p-col-3"
                  />
                )}
              </Group>
            )}
          </Form>
        </FormContainer>
      )}

      {userCanSeeNegotiationArea && (
        <>
          <FormTitle name="Negotiation Area" />

          {userCanSeeConfirmOrderFiles && sat.confirmOrderComercial && (
            <Group>
              <ConfirmOrderFiles
                idSat={sat.idSat}
                isConfirmedPurchase={!!sat.confirmOrderPurchase}
                userCanSeeSunPi={userCanSeeConfirmOrderFileSunPi}
                userCanSeeCotPi={userCanSeeConfirmOrderFileCotPi}
              />
            </Group>
          )}

          {userCanSeeCompareFile && (
            <Group>
              <CompareFile
                idSat={sat.idSat}
                selected={selected}
                isSatCancelled={isSatCancelled}
              />
            </Group>
          )}

          {userCanSeeSatQuotation && (
            <Group>
              <Quotations
                idSat={sat.idSat}
                selected={selected}
                isSatCancelled={isSatCancelled}
              />
            </Group>
          )}
        </>
      )}

      <Group className="p-grid">{itemsGrid}</Group>
    </PageTabContainer>
  );
};

export default SatItems;
