/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { isValid } from 'date-fns';
import { saveAs } from 'file-saver';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTableMultiSortMetaType,
  DataTablePageParams,
  DataTableRowClickEventParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import { Skeleton } from 'primereact/skeleton';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import Loading from '../../../components/Loading';
import PageHeader from '../../../components/PageHeader';
import { useRefHook } from '../../../hooks/useRefHook';
import ILazyParams from '../../../services/lazyParams';
import {
  getSumDataFromSatFinancialListQuery,
  listSatListFinancialViewQuery,
} from './queries';

import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import { gridConstants } from '../../../components/Grid/constants';
import MultiSelectGroup, {
  MultiSelectGroupData,
} from '../../../components/Grid/MultiSelectGroup';
import pagination from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import useTitle from '../../../hooks/useTitle';
import { ICategory } from '../../../interfaces/ICategory';
import { financialRoles } from '../../../shared/roles/financial';
import { getUTC } from '../../../utils/dateUtil';
import { findParentsOnTree } from '../../../utils/findParentsOnTree';
import getFieldPermission from '../../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { parseCurrencyPtBrNumberColumn } from '../../../utils/gridColumnsParse';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';
import { gridColumnsData, groupedColumns } from './constants';
import {
  ISatListFinancialView,
  SatListFinancialPermissions,
} from './interfaces';
import { Container } from './styles';
import SatStatusTag from '../../../components/SatStatusTag';
import { renderNumber } from '../../../utils/formatLocale';

interface ISatListFinancialViewLazyParams
  extends ILazyParams,
    ISatListFinancialView {
  [key: string]:
    | number
    | string
    | DataTableSortOrderType
    | DataTableMultiSortMetaType
    | Date
    | undefined;
}

const pageTitle = 'Financial List';

const ControlFinancial: React.FC = () => {
  useTitle(pageTitle);
  // Key das opcoes de coluna que resetam preferencias da grid
  const gridResetOptionKey = '0-1';

  const gridColumnsName = '@SAT:satControlsFinancialGridColumns';

  const lazyParamsName = '@SAT:satControlsFinancialLazyParams';

  const advancedFiltersName = '@SAT:satControlsFinancialAdvancedFilters';

  const location = useLocation();

  const { showError } = useRefHook();

  const isMounted = useRef(false);

  const gridRef = useRef<DataTable>(null);

  const [pageLoading /* , setPageLoading */] = useState(false);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  const [lazyParams, setLazyParams] = useState<ISatListFinancialViewLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback(
    (newLazyParams: ISatListFinancialViewLazyParams) => {
      localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
      updateLocalStorageInDb(lazyParamsName, newLazyParams);
    },
    [],
  );

  const changeLazyParams = useCallback(
    (newLazyParams: ISatListFinancialViewLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const { roles } = useAuth();

  const { idEntity } = financialRoles;

  const { idPermissionExportSatsFinancial } = financialRoles.permissions;

  // Busca permissoes do usuario para a entity
  const { userFields, userPermissions } = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(roles.rolesUser, idEntity);
  }, [idEntity, roles.rolesUser]);

  const permissions = useMemo(() => {
    return {
      export: userHasPermission(
        idPermissionExportSatsFinancial,
        userPermissions,
      ),
    };
  }, [idPermissionExportSatsFinancial, userPermissions]);

  const fieldsPermissions: SatListFinancialPermissions = useMemo(() => {
    const fieldPermissions = userFields;
    const { fields } = financialRoles;

    const financialPermissions = {} as SatListFinancialPermissions;

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(fields)) {
      financialPermissions[key] = getFieldPermission(value, fieldPermissions);
    }

    return financialPermissions;
  }, [userFields]);

  const [categories, setCategories] = useState<ICategory[]>([]);

  const listCategoriesQuery = gql`
    query listAllCategoriesQuery {
      listAllCategories {
        data
        items
      }
    }
  `;

  useQuery(listCategoriesQuery, {
    onError: errorData => {
      showError({
        summary: 'Error while getting categories',
        detail: errorData.message,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        setCategories(JSON.parse(response.listAllCategories.data));
      }
    },
  });

  const columns = useMemo(() => {
    gridColumnsData.category.options = categories;

    return Object.entries(gridColumnsData)
      .filter(column => fieldsPermissions[column[1].field].view)
      .map(column => column[1]);
  }, [categories, fieldsPermissions]);

  const [selectedColumns, setSelectedColumns] = useState<MultiSelectGroupData>({
    columns: columns.filter(
      (column: any) =>
        groupedColumns &&
        groupedColumns.length > 0 &&
        groupedColumns[0]?.children?.[0]?.data?.includes(column.field),
    ),
    treeSelectedData: {
      0: {
        checked: false,
        partialChecked: true,
      },
      '0-0': {
        checked: true,
        partialChecked: false,
      },
    },
  });

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  const commonFilterVariables = {
    idCurrency: lazyParams.idCurrency,
    idSat: lazyParams.idSat,
    idSatForeignTrade: lazyParams.idSatForeignTrade,
    sunNumber: lazyParams.sunNumber,
    nameSupplier: lazyParams.nameSupplier,
    idCountryOrigin: lazyParams.idCountryOrigin,
    mainProduct: lazyParams.mainProduct,
    clientOrder: lazyParams.clientOrder,
    idSupplierExporter: lazyParams.idSupplierExporter,
    idImporter: lazyParams.idImporter,
    idClient: lazyParams.idClient,
    status: lazyParams.status,
    idImportStatus: lazyParams.idImportStatus,
    idShipmentStatus: lazyParams.idShipmentStatus,
    idExportStatus: lazyParams.idExportStatus,
    shipmentResume: lazyParams.shipmentResume,
    startDate: lazyParams.startDate,
    realEtd: lazyParams.realEtd,
    realEta: lazyParams.realEta,
    idPurchaseIncoterm: lazyParams.idPurchaseIncoterm,
    estimatedAdvanceRequest: lazyParams.estimatedAdvanceRequest,
    estimatedBalanceRequest: lazyParams.estimatedBalanceRequest,
    paidAdvancePaymentDate: lazyParams.paidAdvancePaymentDate,
    paidBalancePaymentDate: lazyParams.paidBalancePaymentDate,
    idStatusPurchasePayment: lazyParams.idStatusPurchasePayment,
    idClientIncoterm: lazyParams.idClientIncoterm,
    sellerEstimatedAdvanceRequest: lazyParams.sellerEstimatedAdvanceRequest,
    sellerEstimatedBalanceRequest: lazyParams.sellerEstimatedBalanceRequest,
    receivedAdvanceReceivedDate: lazyParams.receivedAdvanceReceivedDate,
    receivedBalanceReceivedDate: lazyParams.receivedBalanceReceivedDate,
    idStatusSellingPayment: lazyParams.idStatusSellingPayment,
    idSalesUser: lazyParams.idSalesUser,
    idSalesSecondUser: lazyParams.idSalesSecondUser,
    idPurchaseUser: lazyParams.idPurchaseUser,
    idComexExportUser: lazyParams.idComexExportUser,
    idComexImportUser: lazyParams.idComexImportUser,
    idFinancialUser: lazyParams.idFinancialUser,
    category: lazyParams.category
      ? findParentsOnTree(categories, lazyParams.category)
      : undefined,
  };

  const {
    loading: satListFinancialViewLoading,
    data: satListFinancialViewData,
    error: satListFinancialViewError,
  } = useQuery(listSatListFinancialViewQuery(fieldsPermissions), {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        allColumns: lazyParams.globalFilter,
        ...commonFilterVariables,
      },
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Financial List',
        detail: errorData.message,
      });
    },
  });

  const { loading: listSumLoading, data: listSumData } = useQuery(
    getSumDataFromSatFinancialListQuery,
    {
      variables: {
        data: {
          allColumns: lazyParams.globalFilter,
          fieldsSearch: commonFilterVariables,
        },
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Financial List totals',
          detail: errorData.message,
        });
      },
    },
  );

  function saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });

    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`,
    );
  }

  async function exportToXlsx(exportData: any) {
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.columns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    const colunsNotRemoved = columns.filter(
      col => !columnsToRemove.includes(col),
    );

    const percentageColumns = [
      'estimatedGrossMargin',
      'estimatedNetMargin',
      'grossMargin',
      'netMargin',
    ];

    exportData.data.forEach((e: any) => {
      columnsToRemove.forEach(column => {
        delete e[column.field];
      });
      delete e.idSat;
      delete e.idSatRnc;
      delete e.idSatForeignTrade;
      delete e.idClient;
      delete e.idImporter;
      delete e.idSatForeignTradeSupplier;
      delete e.idSupplierExporter;

      // Formata campos de data e porcentagem
      colunsNotRemoved.forEach(column => {
        if (
          e[column.field] &&
          isValid(new Date(e[column.field])) &&
          typeof e[column.field] === 'string'
        ) {
          const date = getUTC(e[column.field]).toLocaleString().split(',')[0];
          e[column.field] = date;
        } else if (
          e[column.field] &&
          percentageColumns.includes(column.field)
        ) {
          e[column.field] /= 100;
        }
      });
    });

    const columnsOrder = gridRef.current?.state.columnOrder?.filter(
      (item: string) =>
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    const worksheet = xlsx.utils.json_to_sheet(exportData.data, {
      header: columnsOrder,
    });

    const percentageColumnsIndex = columnsOrder
      .filter((item: any) => percentageColumns.includes(item))
      .map((item: any) => columnsOrder.indexOf(item));

    // Formata os campos de porcentagem no excel
    percentageColumnsIndex.forEach((index: any) => {
      const columnName = xlsx.utils.encode_col(index);

      let rowIndex = 1;
      colunsNotRemoved.forEach(() => {
        if (worksheet[`${columnName}${rowIndex}`]) {
          worksheet[`${columnName}${rowIndex}`].z = '0.00%';
        }
        rowIndex += 1;
      });
    });

    worksheet['!autofilter'] = { ref: 'A1:H1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    saveAsExcelFile(excelBuffer, 'satListFinancial');
  }

  const [
    loadSatListFinancialView,
    {
      loading: satListFinancialViewExportLoading,
      data: satListFinancialViewExportData,
    },
  ] = useLazyQuery(listSatListFinancialViewQuery(fieldsPermissions), {
    onCompleted: () => {
      if (satListFinancialViewExportData.listSatListFinancialView) {
        exportToXlsx(satListFinancialViewExportData.listSatListFinancialView);
      } else {
        showError({
          summary: 'Error while exporting Financial List',
          detail: 'Please try again later',
        });
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Financial List to export',
        detail: errorData.message,
      });
    },
  });

  const loadExportData = useCallback(() => {
    loadSatListFinancialView({
      variables: {
        data: {
          pagination: {
            _page: 0,
            _limit: 0,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          allColumns: lazyParams.globalFilter,
        },
      },
    });
  }, [lazyParams, loadSatListFinancialView]);

  useEffect(() => {
    if (isMounted.current) {
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  function onPage(event: DataTablePageParams) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  function onSort(event: DataTableSortParams) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  const parseDateColumm = (rowData: any, field: any) => {
    let date;
    if (rowData[field.field]) {
      date = getUTC(rowData[field.field]).toLocaleString();
    }
    return <p>{date?.split(',')[0]}</p>;
  };

  const parseSatColumn = (rowData: any) => {
    if (rowData.idSat) {
      return (
        <Link
          to={`/commercial/sats/${rowData.idSat}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.satNumber}
          <FiExternalLink size={15} />
        </Link>
      );
    }
    return rowData.satNumber;
  };

  const parseShipmentColumn = (rowData: any) => {
    if (rowData.idSat && rowData.idSatForeignTradeSupplier) {
      return (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=financial&satForeignTradeSupplierId=${rowData.idSatForeignTradeSupplier}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.satForeignTradeSupplierNumber}
          <FiExternalLink size={15} />
        </Link>
      );
    }
    return rowData.satForeignTradeSupplierNumber;
  };

  const parseSunColumn = (rowData: any) => {
    if (!rowData.sunNumber) return undefined;

    const idSupplier = rowData.sunNumber.replace('SUN', '');

    return (
      <Link
        to={`/suppliers/list/${idSupplier}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData.sunNumber}
        <FiExternalLink size={15} />
      </Link>
    );
  };

  const parseImporterColumn = (rowData: any) => {
    return (
      rowData.idImporter && (
        <Link
          to={`/clients/list/${rowData.idImporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.importerConsignee}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseClientNameColumn = (rowData: any) => {
    return (
      rowData.idClient && (
        <Link
          to={`/clients/list/${rowData.idClient}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.clientNotify}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseExporterColumn = (rowData: any) => {
    return (
      rowData.idSupplierExporter && (
        <Link
          to={`/suppliers/list/${rowData.idSupplierExporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.exporter}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseCurrencyColumn = (rowData: any, field: any) => {
    return parseCurrencyPtBrNumberColumn(
      rowData,
      field,
      2,
      rowData.satCurrency,
    );
  };

  const parseCurrencyColumnsPtBr = (rowData: any, field: any) => {
    return rowData[field.field]
      ? parseCurrencyPtBrNumberColumn(rowData, field, 2)
      : '';
  };

  const renderStatusTag = (rowData: any) => {
    return <SatStatusTag status={rowData.status} />;
  };

  function handleColumn(field: string) {
    switch (field) {
      case 'receivedBalanceReceivedDate':
      case 'receivedAdvanceReceivedDate':
      case 'sellerEstimatedBalanceRequest':
      case 'sellerEstimatedAdvanceRequest':
      case 'paidBalancePaymentDate':
      case 'paidAdvancePaymentDate':
      case 'estimatedBalanceRequest':
      case 'estimatedAdvanceRequest':
      case 'realEta':
      case 'realEtd':
      case 'startDate':
        return parseDateColumm;
      case 'purchaseTotalCotPi':
      case 'purchaseTotalCotCi':
      case 'purchaseEstimatedTotalAdvance':
      case 'purchaseEstimatedTotalBalance':
      case 'purchaseTotalOtherExpenses':
      case 'purchaseTotalOnMargin':
      case 'purchaseTotalOutOfMargin':
      case 'paidTotalAdvance':
      case 'paidTotalRolling':
      case 'paidTotalBalance':
      case 'totalPaid':
      case 'totalCotRemain':
      case 'purchaseDiscountFormSupplier':
      case 'purchasePayToSupplier':
      case 'sellerTotalSunPi':
      case 'sellerTotalSunCi':
      case 'totalWithApportionment':
      case 'sellerEstimatedTotalAdvance':
      case 'sellerEstimatedTotalBalance':
      case 'sellerTotalOtherExpenses':
      case 'sellerTotalOnMargin':
      case 'sellerTotalOutOfMargin':
      case 'receivedTotalAdvance':
      case 'receivedTotalRolling':
      case 'receivedTotalBalance':
      case 'totalReceived':
      case 'totalRemainSun':
      case 'sellerTotalCreditToClient':
      case 'sellerTotalForApportionment':
      case 'estimatedGrossProfit':
      case 'estimatedNetProfit':
      case 'grossProfit':
      case 'netProfit':
        return parseCurrencyColumn;
      case 'exchangeRate':
      case 'purchaseTotalConvertedCotPiUsd':
      case 'purchaseTotalConvertedCotCiUsd':
      case 'sellerTotalConvertedSunPi':
      case 'sellerTotalConvertedSunCi':
        return parseCurrencyColumnsPtBr;
      case 'satNumber':
        return parseSatColumn;
      case 'satForeignTradeSupplierNumber':
        return parseShipmentColumn;
      case 'sunNumber':
        return parseSunColumn;
      case 'importerConsignee':
        return parseImporterColumn;
      case 'clientNotify':
        return parseClientNameColumn;
      case 'exporter':
        return parseExporterColumn;
      case 'statusSat':
        return renderStatusTag;
      default:
        return undefined;
    }
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'satCurrency':
        return { width: '110px' };
      case 'suppliers':
        return {
          width: '140px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
        };
      case 'purchaseEstimatedTermCondition':
        return { width: '180px' };
      case 'sellerEstimatedAdvanceRequest':
      case 'purchaseEstimatedTotalAdvance':
        return { width: '170px' };
      case 'receivedAdvanceReceivedDate':
        return { width: '150px' };
      case 'sellerEstimatedBalanceRequest':
      case 'purchaseEstimatedTotalBalance':
        return { width: '200px' };
      case 'receivedBalanceReceivedDate':
      case 'sellerReceiveEstimatedBalance':
        return { width: '150px' };
      case 'sellerEstimatedTotalAdvance':
      case 'sellerPaymentTermCondition':
      case 'purchaseTotalOtherExpenses':
        return { width: '160px' };
      case 'sellerEstimatedTotalBalance':
      case 'sellerTotalConvertedSunPi':
      case 'purchaseTotalOutOfMargin':
        return { width: '150px' };
      case 'sellerReceiveEstimatedAdvanced':
      case 'paidAdvancePaymentDate':
        return { width: '120px' };
      case 'estimatedAdvance':
        return { width: '180px' };
      case 'estimatedBalance':
        return { width: '180px' };
      case 'purchaseTotalConvertedCotPiUsd':
      case 'purchaseTotalConvertedCotCiUsd':
        return { width: '150px' };
      case 'paidBalancePaymentDate':
        return { width: '120px' };
      case 'estimatedAdvanceRequest':
        return { width: '170px' };
      case 'sellerTotalOtherExpenses':
      case 'totalWithApportionment':
      case 'estimatedBalanceRequest':
        return { width: '170px' };
      case 'sellerTotalOutOfMargin':
      case 'purchaseTotalOnMargin':
        return { width: '150px' };
      case 'sellerTotalForApportionment':
        return { width: '170px' };
      case 'satForeignTradeSupplierNumber':
        return { width: '150px' };
      case 'estimatedGrossMargin':
      case 'receivedTotalAdvance':
      case 'sellerTotalConvertedSunCi':
      case 'purchaseDiscountFormSupplier':
        return { width: '150px' };
      case 'receivedTotalBalance':
        return { width: '150px' };
      case 'estimatedNetMargin':
        return { width: '150px' };
      case 'estimatedGrossProfit':
      case 'receivedTotalRolling':
      case 'sellerTotalOnMargin':
        return { width: '150px' };
      case 'firstSalesUser':
        return { width: '200px' };
      case 'secondSalesUser':
        return { width: '200px' };
      case 'sellerTotalCreditToClient':
        return { width: '150px' };
      case 'estimatedNetProfit':
        return { width: '150px' };
      case 'category':
        return { width: '185px' };
      case 'importerConsignee':
      case 'exporter':
        return { width: '185px' };
      case 'clientNotify':
        return { width: '185px' };
      case 'paidTotalAdvance':
        return { width: '130px' };
      case 'purchaseIncoterm':
        return { width: '120px' };
      case 'totalRemainSun':
      case 'paidTotalBalance':
        return { width: '130px' };
      case 'totalCotRemain':
        return { width: '120px' };
      case 'paidTotalRolling':
        return { width: '100px' };
      case 'countryOfOrigin':
        return { width: '165px' };
      case 'sellingIncoterm':
      case 'importStatus':
      case 'exportStatus':
      case 'shipmentResume':
        return { width: '160px' };
      case 'purchaseNotes':
      case 'purchasePayToSupplier':
      case 'statusPurchasePayment':
        return { width: '155px' };
      case 'exchangeRate':
      case 'nameSupplier':
        return { width: '150px' };
      case 'purchaseUser':
      case 'financialUser':
      case 'totalReceived':
        return { width: '145px' };
      default:
        return { width: '140px' };
    }
  }

  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  function handleColumnFooter(field: string) {
    if (listSumLoading) return <Skeleton />;

    // Currency para adicionar nos campos de moeda - sao listados se todas as SATs
    // possuirem a mesma currency
    const currencyAbbreviation =
      satListFinancialViewData?.listSatListFinancialView?.data[0]?.satCurrency;

    const value = listSumData?.getSumDataFromSatFinancialList[field];

    switch (field) {
      case 'satNumber':
        return listSumData?.getSumDataFromSatFinancialList?.satCount ?? 0;
      case 'estimatedGrossMargin':
      case 'estimatedNetMargin':
      case 'grossMargin':
      case 'netMargin':
        return renderNumber(value, 'float');
      case 'purchaseTotalCotPi':
      case 'purchaseTotalCotCi':
      case 'purchaseEstimatedTotalAdvance':
      case 'purchaseEstimatedTotalBalance':
      case 'purchaseTotalOtherExpenses':
      case 'purchaseTotalOnMargin':
      case 'purchaseTotalOutOfMargin':
      case 'paidTotalAdvance':
      case 'paidTotalRolling':
      case 'paidTotalBalance':
      case 'totalPaid':
      case 'totalCotRemain':
      case 'purchaseDiscountFormSupplier':
      case 'purchasePayToSupplier':
      case 'sellerTotalSunPi':
      case 'sellerTotalSunCi':
      case 'totalWithApportionment':
      case 'sellerEstimatedTotalAdvance':
      case 'sellerEstimatedTotalBalance':
      case 'sellerTotalOtherExpenses':
      case 'sellerTotalOnMargin':
      case 'sellerTotalOutOfMargin':
      case 'receivedTotalAdvance':
      case 'receivedTotalRolling':
      case 'receivedTotalBalance':
      case 'totalReceived':
      case 'totalRemainSun':
      case 'sellerTotalCreditToClient':
      case 'sellerTotalForApportionment':
      case 'grossProfit':
      case 'estimatedGrossProfit':
      case 'estimatedNetProfit':
      case 'netProfit':
        return renderNumber(value, 'currency', currencyAbbreviation);
      case 'purchaseTotalConvertedCotPiUsd':
      case 'purchaseTotalConvertedCotCiUsd':
      case 'sellerTotalConvertedSunPi':
      case 'sellerTotalConvertedSunCi':
        return renderNumber(value, 'currency', 'USD');
      default:
        return undefined;
    }
  }

  const dynamicColumns = selectedColumns.columns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable={col.header !== 'Image'}
          footer={handleColumnFooter(col.field)}
        />
      )
    );
  });

  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  const history = useHistory();

  function onRowClick(e: DataTableRowClickEventParams) {
    history.push(
      `/commercial/sats/${e.data.idSat}?tab=financial&satForeignTradeSupplierId=${e.data.idSatForeignTradeSupplier}`,
    );
  }

  const onColumnToggle = (event: MultiSelectGroupData) => {
    const orderedSelectedColumns = columns.filter(col =>
      event.columns.some((sCol: { field: string }) => sCol.field === col.field),
    );

    const finalObject = {
      columns: orderedSelectedColumns,
      treeSelectedData: event.treeSelectedData,
    };

    // Salva colunas selecionadas no local storage
    localStorage.setItem(gridColumnsName, JSON.stringify(finalObject));
    setSelectedColumns(finalObject);

    updateLocalStorageInDb(gridColumnsName, finalObject);
  };

  useEffect(() => {
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    if (localStorageSelectedColumns) {
      const columnsObject = JSON.parse(localStorageSelectedColumns);
      setSelectedColumns(columnsObject);
    }
  }, [columns]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <Container className="what-a-class">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
        {permissions.export && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={satListFinancialViewExportLoading}
            onClick={() => loadExportData()}
          />
        )}
        <MultiSelectGroup
          gridRef={gridRef}
          className="grid-multiselect-panel"
          allColumns={columns}
          groups={groupedColumns}
          showFields
          onSelectionChange={e => onColumnToggle(e)}
          initialData={selectedColumns.treeSelectedData}
          resetOptionKey={gridResetOptionKey}
        />
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a SAT"
        />
      </PageHeader>
      <Grid
        ref={gridRef}
        className="grid p-datatable-hoverable-rows"
        name="controlsFinancial"
        lazy
        totalRecords={
          !satListFinancialViewData ||
          !satListFinancialViewData.listSatListFinancialView
            ? 0
            : satListFinancialViewData.listSatListFinancialView.items
        }
        value={
          !satListFinancialViewData ||
          !satListFinancialViewData.listSatListFinancialView
            ? undefined
            : satListFinancialViewData.listSatListFinancialView.data
        }
        // globalFilter={globalFilter}
        emptyMessage="No SATs found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={
          !satListFinancialViewData || satListFinancialViewError
            ? undefined
            : lazyParams.first
        }
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onRowClick={onRowClick}
      >
        {dynamicColumns}
      </Grid>
      {(pageLoading || satListFinancialViewLoading) && <Loading />}
    </Container>
  );
};
export default ControlFinancial;
