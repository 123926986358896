import { useLazyQuery } from '@apollo/client';
import { addDays } from 'date-fns';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormHandles } from '@unform/core';
import FormInput from '../../../../../components/FormInput';
import FormTitle from '../../../../../components/FormTitle';
import { ISat, ISatRnc } from '../../interfaces';

import { IFileType } from '../../../../../components/FilesByTypes/interfaces';
import FormAsyncSelect from '../../../../../components/FormAsyncSelect';
import FormDatePicker from '../../../../../components/FormDatePicker';
import FormInputNumber from '../../../../../components/FormInputNumber';
import FormInputTextArea from '../../../../../components/FormInputTextArea';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { CostType } from '../../../../../shared/enums/costType';
import { CurrencyAbbreviation } from '../../../../../shared/enums/currencyAbbreviation';
import { PriorityInDays } from '../../../../../shared/enums/priorityInDays';
import { PriorityType } from '../../../../../shared/enums/priorityType';
import { ProblemType } from '../../../../../shared/enums/problemType';
import { RncOtherExpenseType } from '../../../../../shared/enums/rncOtherExpenseType';
import { StatusRnc } from '../../../../../shared/enums/rncStatus';
import { Role } from '../../../../../shared/enums/role';
import ToastLife from '../../../../../shared/enums/toastLife';
import { asyncSelectLoadDomains } from '../../../../../shared/querys/domain';
import { loadUsersByRolesOptions } from '../../../../../shared/querys/user';
import { satRncRoles } from '../../../../../shared/roles/sat';
import { IUserFieldsAndPermissionsResponse } from '../../../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../../../utils/userHasPermission';
import { generateSatRncExportQuery } from '../queries';
import RncOtherExpense from './OtherExpenses';
import { SatOtherExpense } from './OtherExpenses/interfaces';
import RncCosts from './RncCosts';
import { SatRncCost } from './RncCosts/interfaces';
import RncFiles from './RncFiles';
import RncHistory from './RncHistory';
import RncItems from './RncItems';
import { SatRncItem } from './RncItems/interfaces';
import { Container, Row, TotalBlock } from './styles';
import { getUTC } from '../../../../../utils/dateUtil';
import ReadOnlyInput from '../../../../../components/ReadOnlyInput';
import { RncCreditNoteStatus } from '../../../../../shared/enums/rncCreditNoteStatus';
import { RncDebitNoteStatus } from '../../../../../shared/enums/rncDebitNoteStatus';
import RncUpdates from './RncUpdates';
import { calculateFinalRncCost } from '../utils';

export interface IRncContentRef {
  getRncItemsToUpdate(): SatRncItem[] | undefined;
}
interface IRncContentProps {
  formRef: React.RefObject<FormHandles>;
  sat: ISat;
  satRncs: { data: ISatRnc[]; items: number };
  itemIndex: number;
  selected: boolean;
  rncFileTypes: IFileType[];
  rncFileTypesLoading: boolean;
  userPermissions: IUserFieldsAndPermissionsResponse;
  setItemsToUpdate: React.Dispatch<React.SetStateAction<SatRncItem[]>>;
}

const RncContent: React.FC<IRncContentProps> = ({
  formRef,
  sat,
  satRncs,
  itemIndex,
  selected,
  rncFileTypes,
  userPermissions,
  rncFileTypesLoading,
  setItemsToUpdate,
}) => {
  const { toastRef } = useRefHook();

  const [isStatusOpen] = useState<boolean>(
    satRncs.data[itemIndex].idStatus === StatusRnc.OPEN,
  );

  const [deadline, setDeadline] = useState<Date | undefined>(undefined);

  const [rncCosts, setRncCosts] = useState<SatRncCost[]>();

  const [creditNotes, setCreditNotes] = useState<SatOtherExpense[]>();

  const [debitNotes, setDebitNotes] = useState<SatOtherExpense[]>();

  const [exchangeRate, setExchangeRate] = useState<number>();

  const [
    isProblemTypeDescriptionRequired,
    setIsProblemTypeDescriptionRequired,
  ] = useState(false);

  const [isCostResponsibleRequired, setIsCostResponsibleRequired] =
    useState(false);

  const [isFinancialTotalCostRequired, setIsFinancialTotalCostRequired] =
    useState(false);

  const permissions = useMemo(() => {
    return {
      exportRncForm: userHasPermission(
        satRncRoles.permissions.idPermissionExportRncForm,
        userPermissions.userPermissions,
      ),
    };
  }, [userPermissions.userPermissions]);

  const onPriorityChange = useCallback((idPriority: number) => {
    const today = new Date();
    let daysToAdd: PriorityInDays = 0;
    if (idPriority === PriorityType.NORMAL) {
      daysToAdd = PriorityInDays.NORMAL;
    } else if (idPriority === PriorityType.MEDIUM) {
      daysToAdd = PriorityInDays.MEDIUM;
    } else if (idPriority === PriorityType.URGENT) {
      daysToAdd = PriorityInDays.URGENT;
    }
    const deadlineDate =
      idPriority !== PriorityType.FOLLOW
        ? addDays(today, daysToAdd)
        : undefined;
    setDeadline(deadlineDate);
  }, []);

  const sumArrayOfNumberValues = (
    values: (number | undefined)[] | undefined,
  ) => {
    if (!values || !values.length) {
      return undefined;
    }
    return values?.reduce(
      (previous, current) => (previous || 0) + (current || 0),
    );
  };

  const totals = useMemo(() => {
    const costsBrCurrency = rncCosts
      ?.filter(cost => cost.idCurrency === CurrencyAbbreviation.BRL)
      .map(cost => cost.amount);
    const costsSatCurrency = rncCosts
      ?.filter(cost => cost.idCurrency !== CurrencyAbbreviation.BRL)
      .map(cost => cost.amount);

    const exchangeValueRate =
      exchangeRate || satRncs.data[itemIndex].exchangeRate;

    const creditNotesMapped = creditNotes
      ?.filter(
        creditStatus => creditStatus.idStatus !== RncCreditNoteStatus.CANCELLED,
      )
      ?.map(credit => {
        if (credit.idCurrency === CurrencyAbbreviation.BRL) {
          return (credit.total || 0) / (exchangeValueRate || 0);
        }
        return credit.total;
      });

    const debitNotesMapped = debitNotes
      ?.filter(
        debitStatus => debitStatus.idStatus !== RncDebitNoteStatus.CANCELLED,
      )
      ?.map(debit => {
        if (debit.idCurrency === CurrencyAbbreviation.BRL) {
          return (debit.total || 0) / (exchangeValueRate || 0);
        }
        return debit.total;
      });

    const totalCostAmountBr = sumArrayOfNumberValues(costsBrCurrency);
    const totalCostAmountSat = sumArrayOfNumberValues(costsSatCurrency);
    const finalRncCostTotal = calculateFinalRncCost(
      totalCostAmountBr,
      totalCostAmountSat,
      exchangeValueRate,
    );

    const finalCreditNoteTotal = sumArrayOfNumberValues(creditNotesMapped);
    const finalDebitNoteTotal = sumArrayOfNumberValues(debitNotesMapped);

    const totalFields = {
      totalCostAmountBr,
      totalCostAmountSat,
      finalRncCostTotal,
      finalCreditNoteTotal,
      finalDebitNoteTotal,
    };

    return totalFields;
  }, [
    creditNotes,
    debitNotes,
    exchangeRate,
    itemIndex,
    rncCosts,
    satRncs.data,
  ]);

  // Gera RNC Form
  const [generateSatRncExport, { loading: rncExportLoading }] = useLazyQuery(
    generateSatRncExportQuery,
    {
      variables: {
        data: {
          idSat: sat.idSat,
          idSatRnc: satRncs.data[itemIndex].idSatRnc,
        },
      },
      onCompleted: response => {
        if (response.errors) {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while generating Sat RNC Export',
            detail: response.errors[0].message,
            life: ToastLife.ERROR,
          });
        } else {
          window.open(response.generateSatRncExport, '_blank');
        }
      },

      onError: error => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while generating Sat RNC Export',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  function handleRncCostAndStatusChange(idDomain: number, idStatus: number) {
    const isNoCost = idDomain === CostType.NO_COST;
    const isYesNotRefunded = idDomain === CostType.YES_NOT_REFUNDABLE;

    if (isNoCost) {
      formRef.current?.setFieldValue(
        `data[${itemIndex}].idCostResponsible`,
        null,
      );
    }

    const isYesNotRefundedAndClosedStatus =
      isYesNotRefunded && idStatus === StatusRnc.CLOSED;

    setIsCostResponsibleRequired(!isNoCost);
    setIsFinancialTotalCostRequired(isYesNotRefundedAndClosedStatus);
  }

  useEffect(() => {
    formRef.current?.setFieldValue(`data[${itemIndex}].deadline`, deadline);
  }, [deadline, formRef, itemIndex]);

  useEffect(() => {
    const deadLineDate = satRncs.data[itemIndex].deadline;
    if (!deadline && deadLineDate) {
      setDeadline(getUTC(deadLineDate));
    }
  }, [deadline, itemIndex, satRncs.data]);

  useEffect(() => {
    setIsCostResponsibleRequired(
      satRncs.data[itemIndex]?.idCost2?.idDomain !== CostType.NO_COST,
    );
  }, [itemIndex, satRncs.data]);

  useEffect(() => {
    setIsFinancialTotalCostRequired(
      satRncs.data[itemIndex]?.idCost2?.idDomain ===
        CostType.YES_NOT_REFUNDABLE &&
        satRncs.data[itemIndex]?.idStatus2?.idDomain === StatusRnc.CLOSED,
    );
  }, [itemIndex, satRncs.data]);

  return (
    <Container>
      <Tooltip target=".tooltip" />
      <FormInputNumber
        name={`data[${itemIndex}].idSatRnc`}
        label="ID SAT RNC"
        hidden
      />

      <FormTitle
        className="p-col-12 p-md-12 p-mt-5 content-header"
        name="General Information"
      >
        {permissions.exportRncForm && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export RNC Form"
            icon={rncExportLoading ? 'pi pi-spin pi-spinner' : undefined}
            onClick={() => {
              generateSatRncExport();
            }}
          />
        )}
      </FormTitle>
      <Row className="p-d-flex p-flex-wrap">
        <FormInput
          className="p-col-12 p-md-3"
          name={`data[${itemIndex}].rncNumber`}
          label="RNC Number"
          readOnly
        />
        <FormAsyncSelect
          className="p-col-3"
          name={`data[${itemIndex}].idStatus`}
          label="RNC Status"
          loadOptions={asyncSelectLoadDomains}
          getOptionLabel={(option: any) => option.description}
          getOptionValue={(option: any) => option.idDomain}
          noOptionsMessage={() => 'No option found'}
          initialValue={satRncs.data[itemIndex].idStatus2}
          additional={{ id: 84 }}
          required
          onValueChange={e =>
            handleRncCostAndStatusChange(
              formRef.current?.getFieldValue(`data[${itemIndex}].idCost`),
              e.idDomain,
            )
          }
        />
        <FormInput
          className="p-col-12 p-md-3"
          name="idClient"
          label="Client/Notify"
          defaultValue={sat.idClient2?.name ?? ''}
          readOnly
        />
        <FormInput
          className="p-col-12 p-md-3"
          name={`data[${itemIndex}].contactClient`}
          label="Contact at Client"
          readOnly={!isStatusOpen}
        />
        <FormInput
          className="p-col-12 p-md-3"
          name={`data[${itemIndex}].idSupplier2.sunNumber`}
          label="Reference (SUN)"
          readOnly
        />
        <FormInput
          className="p-col-12 p-md-3"
          name={`data[${itemIndex}].satShipment`}
          label="SAT Shipment"
          defaultValue={`${satRncs.data[itemIndex].idSatForeignTrade2?.satForeignTradeNumber}-${satRncs.data[itemIndex].idSupplier2?.sunNumber}`}
          readOnly
        />
        <FormAsyncSelect
          className="p-col-3"
          name={`data[${itemIndex}].idRncResponsible`}
          label="RNC Responsible"
          loadOptions={asyncSelectLoadDomains}
          getOptionLabel={(option: any) => option.description}
          getOptionValue={(option: any) => option.idDomain}
          noOptionsMessage={() => 'No option found'}
          initialValue={satRncs.data[itemIndex].idRncResponsible2}
          additional={{ id: 82 }}
          readOnly={!isStatusOpen}
          required
        />
        <FormAsyncSelect
          className="p-col-3"
          name={`data[${itemIndex}].satRncCoResponsibleDomainIds`}
          label="RNC CO-Responsible"
          loadOptions={asyncSelectLoadDomains}
          getOptionLabel={(option: any) => option.description}
          getOptionValue={(option: any) => option.idDomain}
          noOptionsMessage={() => 'No option found'}
          initialValue={satRncs.data[itemIndex].satRncCoResponsibles?.map(
            item => item.idRncCoResponsible2,
          )}
          additional={{ id: 82 }}
          readOnly={!isStatusOpen}
          isMulti
        />
        <FormAsyncSelect
          className="p-col-3"
          name={`data[${itemIndex}].idPriority`}
          label="Priority"
          loadOptions={asyncSelectLoadDomains}
          getOptionLabel={(option: any) => option.description}
          getOptionValue={(option: any) => option.idDomain}
          noOptionsMessage={() => 'No option found'}
          initialValue={satRncs.data[itemIndex].idPriority2}
          onValueChange={e => onPriorityChange(e.idDomain)}
          readOnly={!isStatusOpen}
          additional={{ id: 81 }}
          required
        />
        <FormDatePicker
          className="p-col-12 p-md-3"
          name={`data[${itemIndex}].deadline`}
          label="Deadline"
          readOnly
          defaultValue={deadline}
        />
        <span
          className="tooltip p-col-3"
          data-pr-tooltip={`Choose the description that suits your problem:
                            *SELLING PACKAGE - TYPING MISTAKES, WRONG INFORMATION PRINTED, ETC
                            *MASTER BOX - WRONG SHIPPING MARKS.
                            *PRODUCT QUALITY - WRONG COLOR, BAD QUALITY, DEFECTS.
                            *DOCUMENTS - INVOICE, HBL, IMPORT LICENSE, ETC
                            *PAYMENT
                            *MISSING QTY
                            *BROKEN ITEMS
                            *OTHER - NONE OF OTHER OPTIONS"`}
        >
          <FormAsyncSelect
            name={`data[${itemIndex}].idProblemType`}
            label="Problem Type"
            loadOptions={asyncSelectLoadDomains}
            getOptionLabel={(option: any) => option.description}
            getOptionValue={(option: any) => option.idDomain}
            noOptionsMessage={() => 'No option found'}
            initialValue={satRncs.data[itemIndex].idProblemType2}
            readOnly={!isStatusOpen}
            additional={{ id: 83 }}
            required
            onValueChange={e =>
              setIsProblemTypeDescriptionRequired(
                e.idDomain === ProblemType.OTHER,
              )
            }
          />
        </span>
        <FormInput
          className="p-col-12 p-md-3"
          name={`data[${itemIndex}].problemTypeDescription`}
          label="Problem Type Description"
          readOnly={!isStatusOpen}
          required={isProblemTypeDescriptionRequired}
          disabled={!isProblemTypeDescriptionRequired}
        />
        <FormAsyncSelect
          className="p-col-3"
          name={`data[${itemIndex}].idReWork`}
          label="Rework"
          loadOptions={asyncSelectLoadDomains}
          getOptionLabel={(option: any) => option.description}
          getOptionValue={(option: any) => option.idDomain}
          noOptionsMessage={() => 'No option found'}
          initialValue={satRncs.data[itemIndex].idReWork2}
          readOnly={!isStatusOpen}
          additional={{ id: 87 }}
          required
        />
        <FormAsyncSelect
          className="p-col-3"
          name={`data[${itemIndex}].idCost`}
          label="RNC Cost"
          loadOptions={asyncSelectLoadDomains}
          getOptionLabel={(option: any) => option.description}
          getOptionValue={(option: any) => option.idDomain}
          noOptionsMessage={() => 'No option found'}
          initialValue={satRncs.data[itemIndex].idCost2}
          readOnly={!isStatusOpen}
          additional={{ id: 88 }}
          required
          onValueChange={e =>
            handleRncCostAndStatusChange(
              e.idDomain,
              formRef.current?.getFieldValue(`data[${itemIndex}].idStatus`),
            )
          }
        />
        <FormAsyncSelect
          className="p-col-3"
          name={`data[${itemIndex}].idCostResponsible`}
          label="Cost Responsible"
          loadOptions={asyncSelectLoadDomains}
          getOptionLabel={(option: any) => option.description}
          getOptionValue={(option: any) => option.idDomain}
          noOptionsMessage={() => 'No option found'}
          initialValue={satRncs.data[itemIndex].idCostResponsible2}
          readOnly={!isCostResponsibleRequired || !isStatusOpen}
          required={isCostResponsibleRequired}
          additional={{ id: 89 }}
        />
      </Row>
      <Row className="p-d-flex p-flex-wrap">
        <span
          className="tooltip p-col-12 p-md-6"
          data-pr-tooltip="Explain what happened with a lot of details and make very clear what the is ISSUE. Where did it appear/started, costs involved"
        >
          <FormInputTextArea
            name={`data[${itemIndex}].problemDescription`}
            label="Problem Description"
            readOnly={!isStatusOpen}
            required
            rows={5}
          />
        </span>
        <span
          className="tooltip p-col-12 p-md-6"
          data-pr-tooltip="Add initially the suggested solution and update the final solution through the process, specifing how it was solve with client, with supplier and financially (credit and debit notes)"
        >
          <FormInputTextArea
            name={`data[${itemIndex}].proposedSolution`}
            label="Proposed Solution"
            readOnly={!isStatusOpen}
            required
            rows={5}
          />
        </span>
      </Row>
      <Row className="p-d-flex p-flex-wrap">
        <RncItems
          sat={sat}
          satRnc={satRncs.data[itemIndex]}
          selected={selected}
          isReadOnly={!isStatusOpen}
          setItemsToUpdate={setItemsToUpdate}
          userPermissions={userPermissions}
        />
      </Row>
      <Row className="p-d-flex p-flex-wrap">
        <RncCosts
          sat={sat}
          rncCosts={rncCosts}
          setRncCosts={setRncCosts}
          satRnc={satRncs.data[itemIndex]}
          selected={selected}
          userPermissions={userPermissions}
          isReadOnly={!isStatusOpen}
        />
      </Row>
      <TotalBlock>
        <Row className="p-d-flex p-flex-wrap">
          <FormInputNumber
            className="p-col-3"
            name="totalCostAmountBr"
            label="Total Cost (R$)"
            prefix={CurrencyAbbreviation[CurrencyAbbreviation.BRL]}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            value={totals.totalCostAmountBr ?? ''}
            readOnly
          />
          <FormInputNumber
            className="p-col-3"
            name="totalCostAmountSat"
            label="Total Cost (USD/EUR)"
            prefix={
              sat.idCurrency2?.idCurrency
                ? CurrencyAbbreviation[sat.idCurrency2.idCurrency]
                : ''
            }
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            value={totals.totalCostAmountSat ?? ''}
            readOnly
          />
          <FormInputNumber
            className="p-col-3"
            name={`data[${itemIndex}].exchangeRate`}
            label="Exchange Rate"
            prefix={
              sat.idCurrency2?.idCurrency
                ? CurrencyAbbreviation[sat.idCurrency2.idCurrency]
                : ''
            }
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            defaultValue={satRncs.data[itemIndex].exchangeRate ?? 0}
            onValueChange={e => setExchangeRate(e.floatValue)}
          />
          <FormAsyncSelect
            className="p-col-3"
            name={`data[${itemIndex}].costApprovedBy`}
            label="RNC Cost Approved By"
            loadOptions={loadUsersByRolesOptions}
            getOptionLabel={(option: any) =>
              `${option.firstName} ${option.lastName}`
            }
            getOptionValue={(option: any) => option.idUser}
            additional={{
              idsRoles: [
                Role.MANAGER,
                Role.PROCUREMENT_KEY_USER,
                Role.FINANCIAL_KEY_USER,
                Role.PURCHASE_KEY_USER,
                Role.COMMERCIAL_KEY_USER,
                Role.COMEX_IMPORT_KEY_USER,
                Role.COMEX_EXPORT_KEY_USER,
                Role.DESIGN_KEY_USER,
                Role.QUALITY_KEY_USER,
              ],
            }}
            noOptionsMessage={() => 'No users found'}
            initialValue={satRncs.data[itemIndex].costApprovedBy2}
          />
          <FormInputNumber
            className="p-col-3"
            name={`data[${itemIndex}].finalRncCostTotal`}
            label="Final RNC Cost (USD/EUR)"
            prefix={
              sat.idCurrency2?.idCurrency
                ? CurrencyAbbreviation[sat.idCurrency2.idCurrency]
                : ''
            }
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            readOnly
            required={isFinancialTotalCostRequired}
            value={totals.finalRncCostTotal ?? ''}
          />
          <FormInput
            className="p-col-3"
            name="refund"
            label="Refund (USD/EUR)"
            readOnly
          />
          <FormInputNumber
            className="p-col-3"
            name="finalCreditNoteTotal"
            label="Final Credit Note"
            prefix={
              sat.idCurrency2?.idCurrency
                ? CurrencyAbbreviation[sat.idCurrency2.idCurrency]
                : ''
            }
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            value={totals.finalCreditNoteTotal}
            readOnly
          />
          <FormInputNumber
            className="p-col-3"
            name="finalDebitNoteTotal"
            label="Final Debit Note"
            prefix={
              sat.idCurrency2?.idCurrency
                ? CurrencyAbbreviation[sat.idCurrency2.idCurrency]
                : ''
            }
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            value={totals.finalDebitNoteTotal}
            readOnly
          />
          <FormInput
            className="p-col-3"
            name={`data[${itemIndex}].clientDebitNoteNumber`}
            label="Client Debit Note Number"
          />
          <FormInput
            className="p-col-3"
            name={`data[${itemIndex}].clientNf`}
            label="Client NF"
          />
          <ReadOnlyInput
            className="p-col-3"
            label="Client Debit Note Status"
            value={satRncs.data[itemIndex].clientDebitNoteStatus2?.description}
          />
        </Row>
      </TotalBlock>
      <RncOtherExpense
        sat={sat}
        selected={selected}
        isReadOnly={!isStatusOpen}
        otherExpenses={creditNotes}
        setOtherExpenses={setCreditNotes}
        otherExpenseType={RncOtherExpenseType.CREDIT_NOTE}
        satRnc={satRncs.data[itemIndex]}
      />
      <RncOtherExpense
        sat={sat}
        selected={selected}
        isReadOnly={!isStatusOpen}
        otherExpenses={debitNotes}
        setOtherExpenses={setDebitNotes}
        otherExpenseType={RncOtherExpenseType.DEBIT_NOTE}
        satRnc={satRncs.data[itemIndex]}
      />
      <RncFiles
        idSat={sat.idSat}
        idSatRnc={satRncs.data[itemIndex].idSatRnc}
        rncFileTypesData={rncFileTypes}
        rncFileTypesLoading={rncFileTypesLoading}
        selected={selected}
        isReadOnly={!isStatusOpen}
      />
      <Row className="p-d-flex p-flex-wrap">
        <FormTitle className="p-col-12 p-md-12 p-mt-5" name="RNC Resume" />
        <FormDatePicker
          className="p-col-12 p-md-3"
          name={`data[${itemIndex}].createdAt`}
          label="Opening Date"
          readOnly
        />
        <FormInput
          className="p-col-12 p-md-3"
          name={`data[${itemIndex}].createdBy2.name`}
          label="RNC Opened By"
          value={`${satRncs.data[itemIndex].createdBy2?.firstName} ${satRncs.data[itemIndex].createdBy2?.lastName}`}
          readOnly
        />
        <FormDatePicker
          className="p-col-12 p-md-3"
          name={`data[${itemIndex}].closedAt`}
          label="Finishing Date"
          readOnly
        />
        <FormInput
          className="p-col-12 p-md-3"
          name={`data[${itemIndex}].daysOpen`}
          label="Days Open"
          readOnly
        />
        <FormAsyncSelect
          className="p-col-3"
          name={`data[${itemIndex}].idWaitingFor`}
          label="Waiting For"
          loadOptions={asyncSelectLoadDomains}
          getOptionLabel={(option: any) => option.description}
          getOptionValue={(option: any) => option.idDomain}
          noOptionsMessage={() => 'No option found'}
          initialValue={satRncs.data[itemIndex].idWaitingFor2}
          readOnly={!isStatusOpen}
          additional={{ id: 82 }}
        />
      </Row>
      <Row className="p-d-flex p-flex-wrap">
        <RncHistory
          satRnc={satRncs.data[itemIndex]}
          categoryId={sat.idCategory}
          selected={selected}
        />
      </Row>
      <Row className="p-d-flex p-flex-wrap">
        <RncUpdates
          idSatRnc={satRncs.data[itemIndex].idSatRnc}
          idSat={satRncs.data[itemIndex].idSat}
          userPermissions={userPermissions.userPermissions}
          selected={selected}
        />
      </Row>
    </Container>
  );
};
export default RncContent;
