import {
  DataTablePageParams,
  DataTableRowToggleParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';

/* eslint-disable no-shadow */
export enum UpdateLogArchivesReducerActionKind {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_CONTENT,
  CHANGE_PAGE,
  CHANGE_SORT,
  SET_EXPANDED_ROWS,
}

export type UpdateLogArchivesReducerAction = {
  type: UpdateLogArchivesReducerActionKind;
  payload?: {
    content?: { data: UpdateLogArchive[]; items: number };
    pageParams?: DataTablePageParams;
    sortParams?: DataTableSortParams;
    rowToggleParams?: DataTableRowToggleParams;
  };
};

type UpdateLogArchivesLazyParams = {
  first: number;
  rows: number;
  page: number;
  sortField?: string;
  sortOrder?: DataTableSortOrderType;
};

export type UpdateLogArchive = {
  idUpdateLogArchive: number;
  dataOperation: string;
  dataBefore?: Record<string, unknown>;
  dataAfter?: Record<string, unknown>;
  updatedBy2: {
    username: string;
  };
  updatedAt: Date;
};

export type UpdateLogArchivesReducer = {
  dialogVisible: boolean;
  lazyParams: UpdateLogArchivesLazyParams;
  content?: { data: UpdateLogArchive[]; items: number };
  expandedRows?: any[];
};
