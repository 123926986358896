/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  ApolloQueryResult,
  OperationVariables,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { get } from 'lodash';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTable,
  DataTablePageParams,
  DataTableRowReorderParams,
  DataTableSortParams,
} from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import React, {
  Dispatch,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import BooleanRadio from '../../../../components/BooleanRadio';
import Button from '../../../../components/Button';
import Grid from '../../../../components/Grid';
import MainButton from '../../../../components/MainButton';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { useRefHook } from '../../../../hooks/useRefHook';
import { IFilterInput } from '../../../../services/dataInterface';
import ILazyParams from '../../../../services/lazyParams';
import ToastLife from '../../../../shared/enums/toastLife';
import {
  handleColumnHeader,
  parseCurrencyPtBrNumberColumn,
  parseFormatedTextColumn,
  parseImageColumn,
  parseNullableBooleanColumn,
  parseStCodeColumn,
} from '../../../../utils/gridColumnsParse';
import updateLocalStorageInDb from '../../../../utils/updateLocalStorageInDb';
import {
  DifferenceToRefreshClientQuotationItemsResponse,
  IClientQuotation,
  IClientQuotationItem,
  IRefreshClientQuotationItemOptions,
} from '../interfaces';
import AddItem, { IAddClientQuotationItemRef } from './AddItem';
import {
  ClientQuotationItemsPermissions,
  ClientQuotationItemsReducerActionKind,
  HandleUpdateClientQuotationItemsResponse,
} from './interfaces';
import {
  deleteClientQuotationItemQuery,
  findCqCurrencyByIdCqQuery,
  getSumDataFromClientQuotationItemsQuery,
  listClientQuotationItemsByClientQuotationIdQuery,
  updateClientQuotationItemsQuery,
} from './queries';
import {
  clientQuotationItemsReducer,
  clientQuotationItemsReducerInitialState,
} from './reducers';
import ReferenceSizes from './ReferenceSizes';
import RefreshItemSelectFields, {
  IRefreshClientQuotationItemSelectFieldsRef,
} from './RefreshItemSelectFields';
import RefreshItemSelectItems, {
  IRefreshClientQuotationItemSelectItemsRef,
} from './RefreshItemSelectItems';

import { Container, GridHeader, TotalDetails } from './styles';
import getFieldPermission from '../../../../utils/getFieldPermission';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { clientQuotationRoles } from '../../../../shared/roles/clientQuotation';
import userHasPermission from '../../../../utils/userHasPermission';
import { IRoleEntityPermission } from '../../../../interfaces/IRoleEntityPermission';
import { getCurrencySymbol } from '../../../../utils/getCurrencySymbol';
import { gridConstants } from '../../../../components/Grid/constants';
import MutationResponseSummary, {
  IMutationResponse,
  IMutationResponseSummaryRef,
} from '../../../../components/MutationResponseSummary';
import { gridColumnsData, groupedColumns } from './constants';
import MultiSelectGroup, {
  MultiSelectGroupData,
} from '../../../../components/Grid/MultiSelectGroup';
import { filterOnlyAllowedColumnsFromLocalStorage } from '../../../../components/Grid/utils';

// eslint-disable-next-line no-shadow
export enum ModalReducerActionKind {
  CHANGE_ADD_CLIENT_QUOTATION_ITEM = 'changeAddClientQuotationItem',
  CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_FIELDS = 'changeDisplayRefreshClientQuotationItemSelectFields',
  CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_ITEMS = 'changeDisplayRefreshClientQuotationItemSelectItems',
  CHANGE_FROM_SELECT_FIELDS_TO_SELECT_ITEMS = 'changeFromSelectFieldsToSelectItems',
  CHANGE_FROM_SELECT_ITEMS_TO_SELECT_FIELDS = 'changeFromSelectItemsToSelectFields',
  CHANGE_ALL_FIELDS_SELECTED = 'changeAllFieldsSelected',
}

export interface ModalReducerAction {
  type: ModalReducerActionKind;
  payload?: boolean;
}

interface IItemsProps extends PageTabContainerProps {
  /**
   * Dados da Client Quotation
   */
  clientQuotation: IClientQuotation;

  /**
   * Define estado de loading
   */
  setPageLoading: Dispatch<SetStateAction<boolean>>;

  ref: Ref<IClientQuotationItemsRef>;

  roleEntityFields: IRoleEntityField[];

  roleEntityPermissions: IRoleEntityPermission[];

  cleanClientQuotation: () => void;

  clientQuotationRefetch(
    variables?: Partial<OperationVariables> | undefined,
  ): Promise<ApolloQueryResult<any>>;
}

export interface IClientQuotationItemsRef {
  handleUpdateItems(): Promise<HandleUpdateClientQuotationItemsResponse>;
}

const Items: React.FC<IItemsProps> = React.forwardRef(
  (
    {
      selected,
      clientQuotation,
      setPageLoading,
      roleEntityFields,
      roleEntityPermissions,
      cleanClientQuotation,
      clientQuotationRefetch,
    },
    ref,
  ) => {
    const gridRef = useRef<DataTable>(null);

    const addItemsSummaryRef = useRef<IMutationResponseSummaryRef>(null);

    const { fields } = clientQuotationRoles;

    // cria método para chamar a mutation
    const [deleteClientQuotationItemMutation] = useMutation(
      deleteClientQuotationItemQuery,
    );

    const [updateClientQuotationItemsMutation] = useMutation(
      updateClientQuotationItemsQuery,
    );

    const [clientQuotationItemsState, clientQuotationItemsDispatch] =
      useReducer(
        clientQuotationItemsReducer,
        clientQuotationItemsReducerInitialState,
      );

    const [addCqItemMutationResponse, setAddCqItemMutationResponse] = useState<
      IMutationResponse[]
    >([]);

    // Referencia ao toast
    const { toastRef } = useRefHook();

    // Estado inicial de lazy params
    const initialLazyParams = {
      first: 0,
      rows: 25,
      page: 0,
    };

    // Parametros de paginacao/backend
    const [lazyParams, setLazyParams] =
      useState<ILazyParams>(initialLazyParams);

    // Linhas expandidas da grid
    const [expandedRows, setExpandedRows] = useState<any[]>([]);

    const originalRows: IClientQuotationItem[] = useMemo(() => {
      return [];
    }, []);

    // Nome da key de grid columns
    const gridColumnsName = '@SAT:clientQuotationItemsGridColumns';

    // Key das opcoes de coluna que resetam preferencias da grid
    const gridResetOptionKey = '0-1';

    // Permissions de Client Quotation
    const {
      idPermissionUpdateClientQuotation,
      idPermissionAddClientQuotationItem,
      idPermissionRefreshClientQuotationItems,
    } = clientQuotationRoles.permissions;

    const permissions = useMemo(() => {
      return {
        updateCq: userHasPermission(
          idPermissionUpdateClientQuotation,
          roleEntityPermissions,
        ),
        addCqItem: userHasPermission(
          idPermissionAddClientQuotationItem,
          roleEntityPermissions,
        ),
        refreshCqItem: userHasPermission(
          idPermissionRefreshClientQuotationItems,
          roleEntityPermissions,
        ),
      };
    }, [
      idPermissionUpdateClientQuotation,
      idPermissionAddClientQuotationItem,
      idPermissionRefreshClientQuotationItems,
      roleEntityPermissions,
    ]);

    const fieldsPermissions: ClientQuotationItemsPermissions = useMemo(() => {
      return {
        imageUrl: getFieldPermission(fields.idFieldIdImage, roleEntityFields),
        sunNumber: getFieldPermission(
          fields.idFieldSumNumber,
          roleEntityFields,
        ),
        stCode: getFieldPermission(fields.idFieldStCode, roleEntityFields),
        refCode: getFieldPermission(fields.idFieldReference, roleEntityFields),
        name: getFieldPermission(fields.idFieldNameComplete, roleEntityFields),
        description: getFieldPermission(
          fields.idFieldDescriptionPT,
          roleEntityFields,
        ),
        namePt: getFieldPermission(fields.idFieldNamePT, roleEntityFields),
        descriptionPt: getFieldPermission(
          fields.idFieldDescriptionPT,
          roleEntityFields,
        ),
        model: getFieldPermission(fields.idFieldModel, roleEntityFields),
        sku: getFieldPermission(fields.idFieldSku, roleEntityFields),
        colour: getFieldPermission(fields.idFieldColorEN, roleEntityFields),
        colourPt: getFieldPermission(fields.idFieldColorPT, roleEntityFields),
        colourImage: getFieldPermission(
          fields.idFieldIdColourImage,
          roleEntityFields,
        ),
        materialComposition: getFieldPermission(
          fields.idFieldMaterialCompositionEN,
          roleEntityFields,
        ),
        materialPt: getFieldPermission(
          fields.idFieldMaterialCompositionPT,
          roleEntityFields,
        ),
        dimensions: getFieldPermission(
          fields.idFieldDimension,
          roleEntityFields,
        ),
        netWeight: getFieldPermission(
          fields.idFieldNetWeight,
          roleEntityFields,
        ),
        artworkEn: getFieldPermission(
          fields.idFieldArtworkEN,
          roleEntityFields,
        ),
        techniqueEn: getFieldPermission(
          fields.idFieldTechnique,
          roleEntityFields,
        ),
        typeOfFabric: getFieldPermission(
          fields.idFieldTypeOfFabric,
          roleEntityFields,
        ),
        lining: getFieldPermission(fields.idFieldLinning, roleEntityFields),
        liningComposition: getFieldPermission(
          fields.idFieldLinningComposition,
          roleEntityFields,
        ),
        linedDescription: getFieldPermission(
          fields.idFieldLinningDescription,
          roleEntityFields,
        ),
        typeOfPackage: getFieldPermission(
          fields.idFieldIdTypeOfPackage,
          roleEntityFields,
        ),
        packageDescription: getFieldPermission(
          fields.idFieldSellingPackage,
          roleEntityFields,
        ),
        packageNotes: getFieldPermission(
          fields.idFieldPackageNotes,
          roleEntityFields,
        ),
        sellingPackagePcQuantity: getFieldPermission(
          fields.idFieldQtySellingPackage,
          roleEntityFields,
        ),
        sellingPackageDimensions: getFieldPermission(
          fields.idFieldQtySellingPackageDimension,
          roleEntityFields,
        ),
        sellingPackageGrossWeight: getFieldPermission(
          fields.idFieldQtySellingPackageGross,
          roleEntityFields,
        ),
        stickerDimensionsPt: getFieldPermission(
          fields.idFieldStickerDimensions,
          roleEntityFields,
        ),
        quantityInner: getFieldPermission(
          fields.idFieldUnSetInner,
          roleEntityFields,
        ),
        boxInner: getFieldPermission(fields.idFieldInner, roleEntityFields),
        packageEan13: getFieldPermission(
          fields.idFieldPackageEan13,
          roleEntityFields,
        ),
        innerDun14: getFieldPermission(
          fields.idFieldInnerDun14,
          roleEntityFields,
        ),
        dun14: getFieldPermission(fields.idFieldDun14, roleEntityFields),
        brand: getFieldPermission(fields.idFieldBrand, roleEntityFields),
        productLine: getFieldPermission(
          fields.idFieldProductLine,
          roleEntityFields,
        ),
        quantity: getFieldPermission(fields.idFieldQtyPi, roleEntityFields),
        unit: getFieldPermission(fields.idFieldUnit, roleEntityFields),
        moq: getFieldPermission(fields.idFieldMoq, roleEntityFields),
        m2Pc: getFieldPermission(fields.idFieldSqmUnit, roleEntityFields),
        totalMasterM2: getFieldPermission(
          fields.idFieldTotalSqm,
          roleEntityFields,
        ),
        cotPriceSqm: getFieldPermission(
          fields.idFieldCotPriceSqm,
          roleEntityFields,
        ),
        sunPriceSqm: getFieldPermission(
          fields.idFieldSunPriceSqm,
          roleEntityFields,
        ),
        quantityContainer: getFieldPermission(
          fields.idFieldQtyOfCtn,
          roleEntityFields,
        ),
        piecesContainer: getFieldPermission(
          fields.idFieldPcsCtn,
          roleEntityFields,
        ),
        masterNetWeight: getFieldPermission(
          fields.idFieldNetWeightCtn,
          roleEntityFields,
        ),
        masterNetWeightTotal: getFieldPermission(
          fields.idFieldNetWeightTotal,
          roleEntityFields,
        ),
        grossWeight: getFieldPermission(
          fields.idFieldGrosstWeight,
          roleEntityFields,
        ),
        grossWeightTotal: getFieldPermission(
          fields.idFieldGrosstWeightTotall,
          roleEntityFields,
        ),
        length: getFieldPermission(fields.idFieldLength, roleEntityFields),
        width: getFieldPermission(fields.idFieldWidth, roleEntityFields),
        height: getFieldPermission(fields.idFieldHeight, roleEntityFields),
        cbm: getFieldPermission(fields.idFieldCbm, roleEntityFields),
        cbmTotal: getFieldPermission(fields.idFieldCbmTotal, roleEntityFields),
        purchaseIncoterm: getFieldPermission(
          fields.idFieldPurchaseIncoterm,
          roleEntityFields,
        ),
        targetCot: getFieldPermission(
          fields.idFieldTargetCot,
          roleEntityFields,
        ),
        cotPrice: getFieldPermission(fields.idFieldCotPrice, roleEntityFields),
        amountCot: getFieldPermission(
          fields.idFieldAmountCot,
          roleEntityFields,
        ),
        sellingIncoterm: getFieldPermission(
          fields.idFieldSellingIncoterm,
          roleEntityFields,
        ),
        targetSun: getFieldPermission(
          fields.idFieldTargetSun,
          roleEntityFields,
        ),
        sunPrice: getFieldPermission(fields.idFieldSunPrice, roleEntityFields),
        amount: getFieldPermission(fields.idFieldAmountSun, roleEntityFields),
        estimateProfit: getFieldPermission(
          fields.idFieldEstimateProfit,
          roleEntityFields,
        ),
        estimateMargin: getFieldPermission(
          fields.idFieldEstimateMargin,
          roleEntityFields,
        ),
        hsCode: getFieldPermission(fields.idFieldHsCode, roleEntityFields),
        ncm: getFieldPermission(fields.idFieldNcm, roleEntityFields),
        ncmDestaque: getFieldPermission(
          fields.idFieldDestaque,
          roleEntityFields,
        ),
        ncmForeignTradeReview: getFieldPermission(
          fields.idFieldForeingTrade,
          roleEntityFields,
        ),
        ncmLi: getFieldPermission(fields.idFieldLi, roleEntityFields),
        ncmIi: getFieldPermission(fields.idFieldIi, roleEntityFields),
        ncmIpi: getFieldPermission(fields.idFieldIpi, roleEntityFields),
        ncmPis: getFieldPermission(fields.idFieldPis, roleEntityFields),
        ncmCofins: getFieldPermission(fields.idFieldCofins, roleEntityFields),
        ncmDumping: getFieldPermission(fields.idFieldDumping, roleEntityFields),
        samples: getFieldPermission(fields.idFieldSamples, roleEntityFields),
        sampleLeadTime: getFieldPermission(
          fields.idFieldSamplesLeadTime,
          roleEntityFields,
        ),
        samplesApproved: getFieldPermission(
          fields.idFieldSamplesApproved,
          roleEntityFields,
        ),
        sampleNotes: getFieldPermission(
          fields.idFieldSamplesNotes,
          roleEntityFields,
        ),
        licensed: getFieldPermission(fields.idFieldLicensed, roleEntityFields),
        licensor: getFieldPermission(fields.idFieldlicensor, roleEntityFields),
        royalties: getFieldPermission(
          fields.idFieldRoyalties,
          roleEntityFields,
        ),
        margin: getFieldPermission(fields.idFieldMargin, roleEntityFields),
      };
    }, [roleEntityFields, fields]);

    const columns = useMemo(() => {
      return Object.entries(gridColumnsData)
        .filter(column => fieldsPermissions[column[0]].view)
        .map(column => column[1]);
    }, [fieldsPermissions]);

    const [selectedColumns, setSelectedColumns] =
      useState<MultiSelectGroupData>({
        columns: columns.filter(
          (column: any) =>
            groupedColumns &&
            groupedColumns.length > 0 &&
            groupedColumns[0]?.children?.[0]?.data?.includes(column.field),
        ),
        treeSelectedData: {
          0: {
            checked: false,
            partialChecked: true,
          },
          '0-0': {
            checked: true,
            partialChecked: false,
          },
        },
      });

    // Colunas da grid que possuem filtro aplicado
    const [filteredColumnsHeader] = useState<IFilterInput[]>([]);

    /**
     * Busca Client Quotation Items
     */
    const [
      loadItemsData,
      { loading: clientQuotationItemsLoading, data: clientQuotationItemsData },
    ] = useLazyQuery(
      listClientQuotationItemsByClientQuotationIdQuery(fieldsPermissions),
      {
        variables: {
          data: {
            pagination: {
              _page: lazyParams.page + 1,
              _limit: lazyParams.rows,
              _orderBy: lazyParams.sortField,
              _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
            },
            idCq: clientQuotation.idCq,
          },
        },
        onCompleted: response => {
          if (response.listClientQuotationItemsByClientQuotationId) {
            clientQuotationItemsDispatch({
              type: ClientQuotationItemsReducerActionKind.SET_CLIENT_QUOTATION_ITEMS,
              payload: {
                clientQuotationItems:
                  response.listClientQuotationItemsByClientQuotationId.data,
              },
            });
          }
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting CQ Items',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      },
    );

    /**
     * Busca Client Quotation Currency
     */
    const [loadCurrencyData, { data: clientQuotationCurrencyData }] =
      useLazyQuery(findCqCurrencyByIdCqQuery, {
        variables: {
          idCq: clientQuotation.idCq,
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting CQ Currency data',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      });

    /**
     * Busca Client Quotation Items Sum
     */
    const [loadItemsSumData, { data: clientQuotationItemsSumData }] =
      useLazyQuery(getSumDataFromClientQuotationItemsQuery, {
        variables: {
          idCq: clientQuotation.idCq,
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting CQ Items SUM data',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      });

    const clientQuotationItemsRefetch = useCallback(() => {
      loadItemsData();
      loadCurrencyData();
      loadItemsSumData();
    }, [loadItemsData, loadCurrencyData, loadItemsSumData]);

    useEffect(() => {
      if (!clientQuotationItemsState.clientQuotationItems.length && selected) {
        clientQuotationItemsRefetch();
      }
    }, [
      clientQuotationItemsRefetch,
      clientQuotationItemsState.clientQuotationItems.length,
      loadItemsData,
      loadCurrencyData,
      loadItemsSumData,
      selected,
    ]);

    const handleDeleteClientQuotationItems = useCallback(async () => {
      setPageLoading(true);
      /**
       * IDs de Client Quotation Items Selecionados
       */
      const idsClientQuotationItems =
        clientQuotationItemsState.selectedClientQuotationItems.map(
          item => item.idCqItem,
        );

      try {
        // Deleta Items
        await deleteClientQuotationItemMutation({
          variables: {
            idsClientQuotationItems,
          },
        });

        // Exibe toast de sucesso
        toastRef.current?.show({
          severity: 'success',
          summary: 'Deleted',
          detail: 'Items deleted',
          life: ToastLife.SUCCESS,
        });

        if (
          clientQuotationItemsState.clientQuotationItems.length ===
          clientQuotationItemsState.selectedClientQuotationItems.length
        ) {
          cleanClientQuotation();
          clientQuotationRefetch();
        }

        clientQuotationItemsDispatch({
          type: ClientQuotationItemsReducerActionKind.CLEAN_CLIENT_QUOTATION_ITEMS_RELATED_DATA,
        });
      } catch (error) {
        // Exibe toast de erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while deleting CQ Items',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      } finally {
        setPageLoading(false);
      }
    }, [
      setPageLoading,
      clientQuotationItemsState.selectedClientQuotationItems,
      clientQuotationItemsState.clientQuotationItems,
      deleteClientQuotationItemMutation,
      toastRef,
      clientQuotationRefetch,
      cleanClientQuotation,
    ]);

    // Reference do componente de AddItems
    const addItemsRef = useRef<IAddClientQuotationItemRef>(null);

    // Reference do componente de RefreshItems Select Fields
    const refreshItemsSelectFieldsRef =
      useRef<IRefreshClientQuotationItemSelectFieldsRef>(null);

    // Reference do componente de RefreshItems
    const refreshItemsSelectItemsRef =
      useRef<IRefreshClientQuotationItemSelectItemsRef>(null);

    // Estado de response do refresh de fields selecionados
    const [refreshSelectedFieldsResponse, setRefreshSelectedFieldsResponse] =
      useState<DifferenceToRefreshClientQuotationItemsResponse[]>([]);

    // Estado de fields que deverao ser atualizados em cada item
    const [itemsFieldsToUpdate, setItemsFieldsToUpdate] =
      useState<IRefreshClientQuotationItemOptions>();

    useImperativeHandle(ref, () => ({
      handleUpdateItems: async () => {
        const nothingToUpdate =
          !clientQuotationItemsState.clientQuotationItemsToUpdate.length &&
          !clientQuotationItemsState.referencesToUpdate.length &&
          !clientQuotationItemsState.referenceSizeBarcodesToUpdate.length;

        if (nothingToUpdate) return true;

        try {
          const response = await updateClientQuotationItemsMutation({
            variables: {
              data: {
                clientQuotationItems:
                  clientQuotationItemsState.clientQuotationItemsToUpdate,
                clientQuotationItemReferences:
                  clientQuotationItemsState.referencesToUpdate,
                clientQuotationReferenceSizeBarcodes:
                  clientQuotationItemsState.referenceSizeBarcodesToUpdate,
              },
            },
          });

          clientQuotationItemsDispatch({
            type: ClientQuotationItemsReducerActionKind.CLEAN_CLIENT_QUOTATION_ITEMS_RELATED_DATA,
          });

          loadItemsData();

          return response.data.updateClientQuotationItems;
        } catch (error) {
          return {
            errors: [error.message],
          };
        }
      },
    }));

    /**
     * Controla exibicao da modal para atualizar itens de acordo com a response de selecao de fields
     */
    useEffect(() => {
      if (refreshSelectedFieldsResponse.length) {
        clientQuotationItemsDispatch({
          type: ClientQuotationItemsReducerActionKind.CHANGE_FROM_SELECT_FIELDS_TO_SELECT_ITEMS,
        });
      }
    }, [refreshSelectedFieldsResponse]);

    useEffect(() => {
      // Busca preferencias de exibicao de colunas do usuario
      const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

      // Se encontrou, salva as colunas no estado
      if (localStorageSelectedColumns) {
        const columnsObject = JSON.parse(localStorageSelectedColumns);
        const allowedColumns = filterOnlyAllowedColumnsFromLocalStorage(
          columnsObject.columns ?? columnsObject,
          columns,
        );

        // Validacao de objeto de colunas no formato 'legado', sem grupos de colunas
        if (!columnsObject.columns) {
          const newGroupedColumnsObject = {
            columns: allowedColumns,
            treeSelectedData: {
              showFields: {
                checked: false,
                partialChecked: true,
              },
            },
          };

          setSelectedColumns(newGroupedColumnsObject);
          localStorage.setItem(
            gridColumnsName,
            JSON.stringify(newGroupedColumnsObject),
          );
          updateLocalStorageInDb(gridColumnsName, newGroupedColumnsObject);
        } else {
          setSelectedColumns({ ...columnsObject, columns: allowedColumns });
        }
      }
    }, [columns]);

    const changeLazyParams = (newLazyParams: ILazyParams) => {
      const userHaveSomethingNotSaved =
        clientQuotationItemsState.clientQuotationItemsToUpdate.length ||
        clientQuotationItemsState.referencesToUpdate.length ||
        clientQuotationItemsState.referenceSizeBarcodesToUpdate.length;

      if (userHaveSomethingNotSaved) {
        confirmDialog({
          message: 'You will lost any items information not saved',
          header: 'Are you sure you want to proceed?',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () => setLazyParams(newLazyParams),
        });
      } else {
        setLazyParams(newLazyParams);
      }
    };

    /**
     * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
     * @param event Parametros de sort da tabela
     */
    function onSort(event: DataTableSortParams) {
      const newLazyParams = { ...lazyParams, ...event };
      changeLazyParams(newLazyParams);
    }

    /**
     * Ao mudar pagina da tabela, muda os parametros de busca no backend
     * @param event Parametros de paginacao da tabela
     */
    function onPage(event: DataTablePageParams) {
      const newLazyParams = { ...lazyParams, ...event };
      changeLazyParams(newLazyParams);
    }

    /**
     * Renderiza coluna de SUN com Link Externo
     */
    const parseSunColumn = useCallback((rowData: IClientQuotationItem) => {
      return (
        <Link
          to={`/suppliers/list/${rowData.idCqSupplier2.idSupplier2?.idSupplier}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.idCqSupplier2.idSupplier2?.sunNumber}
          <FiExternalLink size={15} />
        </Link>
      );
    }, []);

    /**
     * Renderiza coluna com alguma informacao geral da Client Quotation
     */
    const parseGetClientQuotationInfoColumn = useCallback(
      (_, field: any) => {
        return get(clientQuotation, field.field);
      },
      [clientQuotation],
    );

    function rowExpansionTemplate(data: any) {
      return (
        <ReferenceSizes
          cqItem={data}
          editable={!!clientQuotationItemsState.editingRows.length}
          clientQuotationItemsDispatch={clientQuotationItemsDispatch}
        />
      );
    }

    /**
     * Gerencia se expander deve ser exibido
     */
    function handleExpander(data: any, rowData: any) {
      // Se produto nao for fashion, nao exibe expander
      if (!clientQuotation.indConfection) {
        return undefined;
      }

      // Retorna body do expander
      return rowData.expander.element;
    }

    /**
     * Retorna componentes diferentes dependendo da coluna
     * @param field Coluna atual
     * @returns Respectivo componente
     */
    const handleColumn = useCallback(
      (field: string) => {
        switch (field) {
          case 'idImage2.url':
          case 'idColourImage2.url':
            return parseImageColumn;
          case 'idSupplier2.sunNumber':
            return parseSunColumn;
          case 'stCode':
            return parseStCodeColumn;
          case 'description':
          case 'name':
          case 'materialComposition':
          case 'materialPt':
            return parseFormatedTextColumn;
          case 'idPurchaseIncoterm2.description':
          case 'idSellingIncoterm2.description':
            return parseGetClientQuotationInfoColumn;
          case 'cotPrice':
          case 'sunPrice':
            return (rowData: any, columnField: any) => {
              return parseCurrencyPtBrNumberColumn(
                rowData,
                columnField,
                3,
                clientQuotationCurrencyData?.findCqCurrencyByIdCq,
              );
            };
          case 'samplesApproved':
          case 'targetCot':
          case 'targetSun':
          case 'amount':
          case 'amountCot':
            return (rowData: any, columnField: any) => {
              return parseCurrencyPtBrNumberColumn(
                rowData,
                columnField,
                2,
                clientQuotationCurrencyData?.findCqCurrencyByIdCq,
              );
            };
          case 'samples':
            return parseNullableBooleanColumn;
          default:
            return undefined;
        }
      },
      [
        clientQuotationCurrencyData?.findCqCurrencyByIdCq,
        parseGetClientQuotationInfoColumn,
        parseSunColumn,
      ],
    );

    /**
     * Define o tamanho de cada coluna de acordo com seu nome
     * @param column Nome da coluna
     * @returns estilo da coluna
     */
    function handleColumnSize(column: string) {
      switch (column) {
        case 'packageDescription':
        case 'quantityInner':
        case 'packageEan13':
        case 'innerdun14':
        case 'dun14':
        case 'productLine':
        case 'cotPriceSqm':
        case 'sunPriceSqm':
        case 'quantityContainer':
        case 'masterNetWeight':
        case 'masterNetWeightTotal':
        case 'grossWeight':
        case 'grossWeightTotal':
        case 'idPurchaseIncoterm':
        case 'idSellingIncoterm':
        case 'amount':
        case 'estimateProfit':
        case 'estimateMargin':
        case 'ncmLi':
        case 'sampleLeadTime':
        case 'sampleNotes':
        case 'samplesApproved':
        case 'idPurchaseIncoterm2.description':
        case 'idSellingIncoterm2.description':
          return { width: '200px' };
        case 'description':
        case 'descriptionEn':
        case 'descriptionPt':
          return { width: '230px', overflow: 'hidden' };
        case 'netWeight':
        case 'artworkEn':
        case 'techniqueEn':
        case 'typeOfFabric':
        case 'liningComposition':
        case 'linedDescription':
        case 'idTypePackage2.description':
        case 'packageNotes':
        case 'stickerDimensionsPt':
        case 'ncmForeignTradeReview':
          return { width: '240px' };
        case 'materialComposition':
        case 'materialPt':
        case 'sellingPackageDimensions':
        case 'sellingPackagePcQuantity':
          return { width: '270px' };
        case 'sellingPackageGrossWeight':
          return { width: '280px' };
        default:
          return { width: '140px' };
      }
    }

    /**
     * Ordenacao das colunas
     * @param event
     */
    const onColumnToggle = useCallback(
      (event: MultiSelectGroupData) => {
        const orderedSelectedColumns = columns.filter(col =>
          event.columns.some(
            (sCol: { field: string }) => sCol.field === col.field,
          ),
        );

        const finalObject = {
          columns: orderedSelectedColumns,
          treeSelectedData: event.treeSelectedData,
        };

        // Salva colunas selecionadas no local storage
        localStorage.setItem(gridColumnsName, JSON.stringify(finalObject));
        setSelectedColumns(finalObject);
        updateLocalStorageInDb(gridColumnsName, finalObject);
      },
      [columns],
    );

    const calculateActualRowIndex = useCallback(
      (itemIndex: number) => {
        return itemIndex - lazyParams.rows * lazyParams.page;
      },
      [lazyParams.page, lazyParams.rows],
    );

    const onEditorValueChange = useCallback(
      (props, value) => {
        const itemIndex = calculateActualRowIndex(props.rowIndex);
        const updatedItem = props.props.value[itemIndex];
        updatedItem[props.field] = value;

        clientQuotationItemsDispatch({
          type: ClientQuotationItemsReducerActionKind.SET_CLIENT_QUOTATION_ITEMS_TO_UPDATE,
          payload: {
            itemsToupdate: updatedItem,
          },
        });
      },
      [calculateActualRowIndex],
    );

    const validateResultToQuantityOfCtn = useCallback(
      (
        e: React.FocusEvent<HTMLInputElement>,
        piecesContainer?: number,
        stCode?: string,
      ) => {
        const parsedNumber = parseFloat(e.target.ariaValueNow ?? '');
        if (
          piecesContainer &&
          !Number.isNaN(parsedNumber) &&
          parsedNumber % piecesContainer !== 0
        ) {
          // Arredonda a divisao pra cima e para baixo, para encontrar os dois numeros mais proximos
          const roundUp = Math.ceil(parsedNumber / piecesContainer);
          const roundDown = Math.floor(parsedNumber / piecesContainer);

          const detailString = `${stCode} quantity value must be a multiple of ${piecesContainer}, like '${
            roundDown * piecesContainer
          }' or '${roundUp * piecesContainer}'`;

          toastRef.current?.show({
            severity: 'warn',
            summary: 'Quantity PI not compatible with PCS/CTN',
            detail: detailString,
            life: ToastLife.WARN,
          });
        }
      },
      [toastRef],
    );

    const numberEditor = useCallback(
      (props: any, field: string, mode?: string) => {
        return (
          <InputNumber
            className="grid-input"
            value={props.rowData[field]}
            onChange={e => onEditorValueChange(props, e.value)}
            mode={mode}
            maxFractionDigits={mode ? 3 : undefined}
            onBlur={e =>
              field === 'quantity'
                ? validateResultToQuantityOfCtn(
                    e,
                    props.rowData.piecesContainer,
                    props.rowData.stCode,
                  )
                : undefined
            }
          />
        );
      },
      [onEditorValueChange, validateResultToQuantityOfCtn],
    );

    const radioEditor = useCallback(
      (props: any, field: string) => {
        return (
          <BooleanRadio
            onValueChange={e => {
              onEditorValueChange(props, !props.rowData[field]);
              e.originalEvent.stopPropagation();
            }}
            initialValue={props.rowData[field]}
          />
        );
      },
      [onEditorValueChange],
    );

    const textAreaEditor = useCallback(
      (props: any, field: string) => {
        return (
          <InputTextarea
            rows={10}
            cols={20}
            defaultValue={props.rowData[field]}
            onChange={e => onEditorValueChange(props, e.target.value)}
            onClick={e => e.stopPropagation()}
          />
        );
      },
      [onEditorValueChange],
    );

    const handleEditor = useCallback(
      (props: any, column: string) => {
        switch (column) {
          case 'quantity':
          case 'sampleLeadTime':
            return numberEditor(props, column);
          case 'targetCot':
          case 'cotPrice':
          case 'targetSun':
          case 'sunPrice':
            return numberEditor(props, column, 'decimal');
          case 'samples':
          case 'samplesApproved':
            return radioEditor(props, column);
          case 'sampleNotes':
            return textAreaEditor(props, column);
          case 'idImage2.url':
          case 'idColourImage2.url':
            return parseImageColumn(props.rowData, { field: column });
          case 'idSupplier2.sunNumber':
            return parseSunColumn(props.rowData);
          case 'stCode':
            return parseStCodeColumn(props.rowData, { field: column });
          case 'description':
          case 'name':
          case 'materialComposition':
          case 'materialPt':
            return parseFormatedTextColumn(props.rowData, {
              field: column,
            });
          case 'idPurchaseIncoterm2.description':
          case 'idSellingIncoterm2.description':
            return parseGetClientQuotationInfoColumn(undefined, {
              field: column,
            });
          default:
            return props.rowData[column];
        }
      },
      [
        numberEditor,
        parseGetClientQuotationInfoColumn,
        parseSunColumn,
        radioEditor,
        textAreaEditor,
      ],
    );

    /**
     * Reproduz as colunas selecionadas na configuracao
     */
    const dynamicColumns = selectedColumns.columns.map(col => {
      return (
        col.header &&
        col.field && (
          <Column
            key={col.field}
            columnKey={col.field}
            field={col.field}
            body={handleColumn(col.field)}
            // Valida necessidade de icone de filtro no header
            header={handleColumnHeader(col.header, filteredColumnsHeader)}
            style={handleColumnSize(col.field)}
            sortable={
              col.field !== 'idImage2.url' && col.field !== 'idColourImage2.url'
            }
            editor={props => handleEditor(props, col.field)}
          />
        )
      );
    });

    /**
     * Footer da modal de create client quotation item
     * @returns Footer
     */
    const addClientQuotationItemDialogFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button
            label="Save"
            onClick={() => addItemsRef.current?.handleAddItems()}
          />
          <Button
            label="Cancel"
            onClick={() => {
              clientQuotationItemsDispatch({
                type: ClientQuotationItemsReducerActionKind.CHANGE_ADD_CLIENT_QUOTATION_ITEM,
              });
            }}
            className="p-button-danger"
          />
        </div>
      );
    };

    /**
     * Footer da modal de refresh client quotation item select fields
     * @returns Footer
     */
    const refreshClientQuotationItemSelectFieldsFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button
            label="Continue"
            onClick={() => {
              refreshItemsSelectFieldsRef.current?.submitForm();
            }}
          />
          <Button
            label="Cancel"
            onClick={() => {
              clientQuotationItemsDispatch({
                type: ClientQuotationItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_FIELDS,
              });
            }}
            className="p-button-danger"
          />
        </div>
      );
    };

    /**
     * Footer da modal de refresh client quotation item select items
     * @returns Footer
     */
    const refreshClientQuotationItemSelectItemsFooter = () => {
      return (
        <div
          style={{ display: 'flex', placeContent: 'end', marginTop: '12px' }}
        >
          <MainButton
            label="Confirm"
            onClick={() =>
              refreshItemsSelectItemsRef.current?.handleRefreshSelectedItems()
            }
          />
          <Button
            label="Cancel"
            onClick={() => {
              clientQuotationItemsDispatch({
                type: ClientQuotationItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_ITEMS,
              });
            }}
            className="p-button-danger"
          />
          <Button
            label="Back"
            onClick={() =>
              clientQuotationItemsDispatch({
                type: ClientQuotationItemsReducerActionKind.CHANGE_FROM_SELECT_ITEMS_TO_SELECT_FIELDS,
              })
            }
            className="p-button-danger"
          />
        </div>
      );
    };

    /**
     * Header da modal de refresh client quotation item select items
     * @returns Header
     */
    const refreshClientQuotationItemSelectFieldsHeader = () => {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p className="p-dialog-title">Refresh select fields</p>
          <Button
            label={
              clientQuotationItemsState.allFieldsSelected
                ? 'Deselect All'
                : 'Select All'
            }
            onClick={() =>
              refreshItemsSelectFieldsRef.current?.handleSelectAll()
            }
            className={
              clientQuotationItemsState.allFieldsSelected
                ? 'p-button-danger'
                : undefined
            }
          />
        </div>
      );
    };

    const onRowReorder = useCallback((e: DataTableRowReorderParams) => {
      clientQuotationItemsDispatch({
        type: ClientQuotationItemsReducerActionKind.SET_CLIENT_QUOTATION_ITEMS,
        payload: {
          clientQuotationItems: e.value,
        },
      });
    }, []);

    const onRowEditChange = useCallback(() => {
      clientQuotationItemsDispatch({
        type: ClientQuotationItemsReducerActionKind.SET_EDITING_ROWS,
        payload: {
          editingRows: clientQuotationItemsState.clientQuotationItems,
        },
      });
    }, [clientQuotationItemsState.clientQuotationItems]);

    const onRowEditInit = useCallback(
      event => {
        originalRows[event.index] = {
          ...clientQuotationItemsState.clientQuotationItems[event.index],
        };
      },
      [clientQuotationItemsState.clientQuotationItems, originalRows],
    );

    return (
      <PageTabContainer selected={selected}>
        <Dialog
          header="Add CQ Item"
          visible={clientQuotationItemsState.displayAddClientQuotationItemModal}
          onHide={() =>
            clientQuotationItemsDispatch({
              type: ClientQuotationItemsReducerActionKind.CHANGE_ADD_CLIENT_QUOTATION_ITEM,
            })
          }
          closable={false}
          footer={addClientQuotationItemDialogFooter()}
          style={{ width: '80%', maxWidth: '1670px' }}
        >
          <AddItem
            ref={addItemsRef}
            idCq={clientQuotation.idCq}
            setPageLoading={setPageLoading}
            clientQuotationItemsRefetch={clientQuotationItemsRefetch}
            clientQuotationItemsDispatch={clientQuotationItemsDispatch}
            addItemsSummaryRef={addItemsSummaryRef}
            setAddCqItemMutationResponse={setAddCqItemMutationResponse}
            clientQuotationItemsState={clientQuotationItemsState}
            clientQuotationRefetch={clientQuotationRefetch}
            cleanClientQuotation={cleanClientQuotation}
          />
        </Dialog>
        <Dialog
          header={refreshClientQuotationItemSelectFieldsHeader()}
          visible={
            clientQuotationItemsState.displayRefreshItemSelectFieldsModal
          }
          onHide={() =>
            clientQuotationItemsDispatch({
              type: ClientQuotationItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_FIELDS,
            })
          }
          closable={false}
          footer={refreshClientQuotationItemSelectFieldsFooter()}
        >
          <RefreshItemSelectFields
            ref={refreshItemsSelectFieldsRef}
            indConfection={clientQuotation.indConfection}
            allFieldsSelected={clientQuotationItemsState.allFieldsSelected}
            modalsDispatch={clientQuotationItemsDispatch}
            setRefreshSelectedFieldsResponse={setRefreshSelectedFieldsResponse}
            selectedClientQuotationItems={
              clientQuotationItemsState.selectedClientQuotationItems
            }
            setItemsFieldsToUpdate={setItemsFieldsToUpdate}
          />
        </Dialog>
        <Dialog
          header="Update selected items"
          visible={clientQuotationItemsState.displayRefreshItemSelectItemsModal}
          onHide={() =>
            clientQuotationItemsDispatch({
              type: ClientQuotationItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_ITEMS,
            })
          }
          closable={false}
          footer={refreshClientQuotationItemSelectItemsFooter()}
          style={{ maxWidth: '80%' }}
        >
          <RefreshItemSelectItems
            ref={refreshItemsSelectItemsRef}
            refreshSelectedFieldsResponse={refreshSelectedFieldsResponse}
            itemsFieldsToUpdate={itemsFieldsToUpdate}
            clientQuotationItemsRefetch={clientQuotationItemsRefetch}
            clientQuotationItemsDispatch={clientQuotationItemsDispatch}
          />
        </Dialog>

        <MutationResponseSummary
          ref={addItemsSummaryRef}
          header="Add SAT Item Summary"
          data={addCqItemMutationResponse}
        />

        <Container>
          <GridHeader>
            <div className="grid-header-buttons">
              {permissions.addCqItem && (
                <Button
                  label="Add CQ Item"
                  onClick={() =>
                    clientQuotationItemsDispatch({
                      type: ClientQuotationItemsReducerActionKind.CHANGE_ADD_CLIENT_QUOTATION_ITEM,
                    })
                  }
                />
              )}
              {permissions.refreshCqItem && (
                <Button
                  label="Refresh selected items"
                  onClick={() =>
                    clientQuotationItemsDispatch({
                      type: ClientQuotationItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_FIELDS,
                    })
                  }
                  disabled={
                    !clientQuotationItemsState.selectedClientQuotationItems
                      .length
                  }
                />
              )}
              {permissions.updateCq && (
                <MainButton
                  label="Edit"
                  icon="pi pi-pencil"
                  onClick={() => onRowEditChange()}
                  disabled={
                    !clientQuotationItemsState.clientQuotationItems.length
                  }
                />
              )}
            </div>
            <div className="grid-filters">
              <Button
                label="Delete selected"
                severity="danger"
                disabled={
                  !clientQuotationItemsState.selectedClientQuotationItems.length
                }
                onClick={() =>
                  confirmDialog({
                    message: 'Are you sure you want to delete these items?',
                    header: 'Delete CQ Items',
                    icon: 'pi pi-exclamation-triangle',
                    acceptClassName: 'p-button-danger',
                    accept: () => handleDeleteClientQuotationItems(),
                  })
                }
              />

              <MultiSelectGroup
                gridRef={gridRef}
                className="grid-multiselect-panel"
                allColumns={columns}
                groups={groupedColumns}
                showFields
                onSelectionChange={onColumnToggle}
                initialData={selectedColumns.treeSelectedData}
                resetOptionKey={gridResetOptionKey}
              />
            </div>
            {/* Multi select de colunas da grid */}
          </GridHeader>
          <Grid
            ref={gridRef}
            className="grid"
            lazy
            totalRecords={
              !clientQuotationItemsData
                ? 0
                : clientQuotationItemsData
                    ?.listClientQuotationItemsByClientQuotationId?.items
            }
            value={clientQuotationItemsState.clientQuotationItems}
            emptyMessage="No CQ items found."
            reorderableColumns
            removableSort
            rows={lazyParams.rows}
            first={!clientQuotationItemsData ? undefined : lazyParams.first}
            onPage={onPage}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            loading={clientQuotationItemsLoading}
            scrollable
            selectionAutoFocus={false}
            style={{ width: 'calc(100vw - 315px)', paddingBottom: '40px' }}
            scrollHeight={gridConstants.internalGridScrollHeight}
            editMode="row"
            selection={clientQuotationItemsState.selectedClientQuotationItems}
            onSelectionChange={e =>
              clientQuotationItemsDispatch({
                type: ClientQuotationItemsReducerActionKind.SET_SELECTED_CLIENT_QUOTATION_ITEMS,
                payload: { selectedClientQuotationItems: e.value },
              })
            }
            onRowReorder={onRowReorder}
            editingRows={clientQuotationItemsState.editingRows}
            onRowEditChange={onRowEditChange}
            onRowEditInit={onRowEditInit}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRows={expandedRows}
            onRowToggle={e => setExpandedRows(e.data)}
            selectionMode="checkbox"
          >
            {clientQuotationItemsState.clientQuotationItems.length > 1 && (
              <Column
                rowReorder
                style={{ width: '3em' }}
                reorderable={false}
                key="reorderRow"
                columnKey="reorderRow"
              />
            )}
            <Column
              style={{ width: '4em' }}
              reorderable={false}
              body={handleExpander}
              expander
              key="expandRow"
              columnKey="expandRow"
            />
            <Column
              selectionMode="multiple"
              style={{ width: '3em' }}
              reorderable={false}
              key="selectRow"
              columnKey="selectRow"
            />
            {dynamicColumns}
          </Grid>

          {!!clientQuotationItemsSumData?.getSumDataFromClientQuotationItems && (
            <TotalDetails>
              <h1>
                Quantity:{' '}
                {
                  clientQuotationItemsSumData.getSumDataFromClientQuotationItems
                    ?.quantityPiItemsSum
                }
              </h1>

              <h3>
                Quantity of CQ Items:{' '}
                {clientQuotationItemsSumData.getSumDataFromClientQuotationItems?.quantityOfItemsSum?.toLocaleString(
                  'pt',
                )}
              </h3>

              <div>
                <span>
                  <h3>Selling Package</h3>

                  <p>
                    <b>Quantity of CTN:</b>{' '}
                    {clientQuotationItemsSumData.getSumDataFromClientQuotationItems?.quantityContainerItemsSum?.toLocaleString(
                      'pt',
                    )}
                  </p>

                  <p
                    className="custom-header"
                    data-pr-tooltip="Master Net Weigth Total"
                    data-pr-position="right"
                  >
                    <b>NET WEIGHT TOTAL:</b>{' '}
                    {clientQuotationItemsSumData.getSumDataFromClientQuotationItems?.masterNetWeightTotalItemsSum?.toLocaleString(
                      'pt',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}
                  </p>

                  <p
                    className="custom-header"
                    data-pr-tooltip="Gross Weigth Total"
                    data-pr-position="right"
                  >
                    <b>GROSS WEIGHT TOTAL:</b>{' '}
                    {clientQuotationItemsSumData.getSumDataFromClientQuotationItems?.grossWeightTotalItemsSum?.toLocaleString(
                      'pt',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}
                  </p>
                </span>

                <span>
                  <h3>Total</h3>

                  <p>
                    <b>CBM:</b>{' '}
                    {clientQuotationItemsSumData.getSumDataFromClientQuotationItems?.cbmTotalItemsSum?.toLocaleString(
                      'pt',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}
                  </p>
                </span>
                <span>
                  <h3>Total</h3>
                  {fieldsPermissions.amountCot.view && (
                    <p>
                      <b>Amount COT:</b>
                      {` ${getCurrencySymbol(
                        clientQuotationCurrencyData?.findCqCurrencyByIdCq,
                      )} `}
                      {clientQuotationItemsSumData.getSumDataFromClientQuotationItems?.amountCotItemsSum?.toLocaleString(
                        'pt',
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}
                    </p>
                  )}
                  <p>
                    <b>Amount SUN:</b>
                    {` ${getCurrencySymbol(
                      clientQuotationCurrencyData?.findCqCurrencyByIdCq,
                    )} `}
                    {clientQuotationItemsSumData.getSumDataFromClientQuotationItems?.amountItemsSum?.toLocaleString(
                      'pt',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}
                  </p>
                  {fieldsPermissions.margin.view && (
                    <p>
                      <b>Margin:</b>{' '}
                      {clientQuotationItemsSumData.getSumDataFromClientQuotationItems?.marginSumItems?.toLocaleString(
                        'pt',
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}
                    </p>
                  )}
                </span>
              </div>
            </TotalDetails>
          )}
        </Container>
      </PageTabContainer>
    );
  },
);

export default Items;
