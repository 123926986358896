import styled from 'styled-components';

export const Container = styled.div`
  .p-datatable {
    // Permite definir largura fixa para as colunas da Grid
    thead th,
    tbody td {
      flex: auto;
    }
  }
`;
