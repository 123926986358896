import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Container, Content } from './styles';

const Loading: React.FC = () => {
  return (
    <Container>
      <Content>
        <ProgressSpinner strokeWidth="5" />
        <p>Loading</p>
      </Content>
    </Container>
  );
};

export default Loading;
