import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import React, { useRef, useState } from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import FormTitle from '../../../../../../components/FormTitle';
import { GridActions } from '../../../../../../components/Grid/styles';
import MainButton from '../../../../../../components/MainButton';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { SatForeignTradeShipmentContainer } from '../interfaces';
import ManageContainer, { IManageContainerRef } from './ManageContainer';
import { deleteSatForeignTradeContainerQuery } from './queries';
import { Container } from './styles';

interface IShipmentContainersProps {
  idSatForeignTrade: number;
  selected: boolean;
  shipmentContainers?: SatForeignTradeShipmentContainer[];
  shipmentContainersRefetch(): Promise<ApolloQueryResult<any>>;
  shipmentContainersLoading: boolean;
  shipmentIsCanceled: boolean;
}

const ShipmentContainers: React.FC<IShipmentContainersProps> = ({
  idSatForeignTrade,
  shipmentContainers,
  shipmentContainersRefetch,
  shipmentContainersLoading,
  shipmentIsCanceled,
}) => {
  const { toastRef } = useRefHook();

  const manageContainerRef = useRef<IManageContainerRef>(null);

  const [containerBeingDeleted, setContainerBeingDeleted] = useState<number>();

  const [deleteSatForeignTradeContainerMutation] = useMutation(
    deleteSatForeignTradeContainerQuery,
  );

  async function handleDeleteForeignTradeContainer(
    idSatForeignTradeCtnr: number,
  ) {
    setContainerBeingDeleted(idSatForeignTradeCtnr);

    try {
      await deleteSatForeignTradeContainerMutation({
        variables: {
          idSatForeignTradeCtnr,
        },
      });

      await shipmentContainersRefetch();

      toastRef.current?.show({
        severity: 'success',
        summary: 'Shipment Container deleted',
        life: ToastLife.SUCCESS,
      });
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while deleting Shipment Container',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    } finally {
      setContainerBeingDeleted(undefined);
    }
  }

  const gridActions = (rowData: SatForeignTradeShipmentContainer) => {
    const isBeingDeleted =
      containerBeingDeleted === rowData.idSatForeignTradeCtnr;

    return (
      <GridActions>
        <button
          type="button"
          onClick={() =>
            manageContainerRef.current?.toggleManageContainerDialog(rowData)
          }
          disabled={isBeingDeleted}
        >
          <FiEdit2 size={20} />
        </button>
        <button
          type="button"
          className="trash-button"
          onClick={() =>
            confirmDialog({
              message:
                'Are you sure you want to delete this shipment container?',
              header: 'Delete Confirmation',
              icon: 'pi pi-info-circle',
              acceptClassName: 'p-button-danger',
              accept: () =>
                handleDeleteForeignTradeContainer(
                  rowData.idSatForeignTradeCtnr,
                ),
            })
          }
          disabled={isBeingDeleted}
        >
          {isBeingDeleted ? (
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: '1.43rem' }}
            />
          ) : (
            <FiTrash2 size={20} />
          )}
        </button>
      </GridActions>
    );
  };

  return (
    <Container className="form-group p-grid">
      <div className="shipment-containers-header p-col-12">
        <FormTitle name="Shipment Containers">
          {!shipmentIsCanceled && (
            <MainButton
              type="button"
              onClick={() =>
                manageContainerRef.current?.toggleManageContainerDialog()
              }
            >
              Add Container
            </MainButton>
          )}
        </FormTitle>
      </div>

      <DataTable
        emptyMessage="No shimpment containers found"
        loading={shipmentContainersLoading}
        value={shipmentContainers}
        responsiveLayout="scroll"
      >
        {!shipmentIsCanceled && (
          <Column
            field="actions"
            headerStyle={{ width: '6rem' }}
            body={gridActions}
          />
        )}

        <Column field="containerName" header="Container" sortable />
      </DataTable>

      <ManageContainer
        ref={manageContainerRef}
        idSatForeignTrade={idSatForeignTrade}
        shipmentContainersRefetch={shipmentContainersRefetch}
      />
    </Container>
  );
};

export default ShipmentContainers;
