import React, { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { parseDateColumm } from '../../../../../../utils/gridColumnsParse';
import {
  DivergentData,
  DivergentForeignTradesGetDataMode,
} from '../interfaces';
import { SelectedDivergentData } from '../../interfaces';

export interface IDivergengtDataGridRef {
  getSelectedItem(): SelectedDivergentData | undefined;
}

interface IDivergentDataGridProps {
  ref: Ref<IDivergengtDataGridRef>;
  idForeignTrade: number;
  divergentData: DivergentData[];
  getDataMode: DivergentForeignTradesGetDataMode;
}

const DivergentDataGrid: React.FC<IDivergentDataGridProps> = forwardRef(
  ({ divergentData, idForeignTrade, getDataMode }, ref) => {
    const [selectedItem, setSelectedItem] = useState<DivergentData | undefined>(
      () => divergentData.find(ft => ft.idSatForeignTrade === idForeignTrade),
    );

    useImperativeHandle(ref, () => ({
      getSelectedItem: () => {
        return {
          idSatForeignTrade:
            getDataMode === 'addGroup'
              ? selectedItem?.idSatForeignTrade
              : idForeignTrade,
          realArrivalAtClient: selectedItem?.realArrivalAtClient,
          realEta: selectedItem?.realEta,
          realEtd: selectedItem?.realEtd,
          idLoadingPort: selectedItem?.idLoadingPort,
          idPort: selectedItem?.idPort,
          bookingConfirmed: selectedItem?.bookingConfirmed,
          shipmentConfirmed: selectedItem?.shipmentConfirmed,
          freightForwarderOrigin: selectedItem?.freightForwarderOrigin,
          freightForwarderDestination:
            selectedItem?.freightForwarderDestination,
        };
      },
    }));

    return (
      <DataTable
        value={divergentData}
        selection={selectedItem}
        onSelectionChange={e => {
          setSelectedItem(e.value);
        }}
      >
        <Column selectionMode="single" headerStyle={{ width: '3em' }} />
        <Column field="satForeignTradeNumber" header="Shipment" />
        <Column field="realEtd" header="Real ETD" body={parseDateColumm} />
        <Column field="realEta" header="Real ETA" body={parseDateColumm} />
        <Column
          field="realArrivalAtClient"
          header="Real Arrival at Client"
          body={parseDateColumm}
        />
        <Column field="loadingPortName" header="Port/Airport of Loading" />
        <Column field="portName" header="Port/Airport of Discharge" />
        <Column
          field="bookingConfirmed"
          header="Booking Confirmed"
          body={parseDateColumm}
        />
        <Column
          field="shipmentConfirmed"
          header="Shipment Confirmed"
          body={parseDateColumm}
        />
        <Column
          field="freightForwarderOriginName"
          header="Freight Forwarder Origin"
        />
        <Column
          field="freightForwarderDestinationName"
          header="Freight Forwarder Destination"
        />
      </DataTable>
    );
  },
);

export default DivergentDataGrid;
