import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { FormHandles } from '@unform/core';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { confirmDialog } from 'primereact/confirmdialog';
import { ISat, ISatCancellationReason } from '../interfaces';
import { useRefHook } from '../../../../hooks/useRefHook';
import ToastLife from '../../../../shared/enums/toastLife';
import getValidationErrors from '../../../../utils/getValidationErrors';
import FormCheckbox from '../../../../components/FormCheckbox';
import FormInputTextArea from '../../../../components/FormInputTextArea';
import FormDatePicker from '../../../../components/FormDatePicker';
import { SatStatus } from '../../../../components/SatStatusTag';
import Button from '../../../../components/Button';

interface ICancelSatStandByMenuProps {
  isVisibleCancelSatStandByMenu: boolean;
  setIsVisibleCancelSatStandByMenu(e: boolean): void;
  sat: ISat;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  satRefetch(): Promise<void>;
}

const CancelSatStandByMenu: React.FC<ICancelSatStandByMenuProps> = ({
  isVisibleCancelSatStandByMenu,
  setIsVisibleCancelSatStandByMenu,
  sat,
  setLoading,
  satRefetch,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { toastRef } = useRefHook();

  const currentDate = new Date();

  const [satCancellationReasonStandBy, setSatCancellationReasonStandBy] =
    useState<ISatCancellationReason>();

  const onHide = () => {
    setIsVisibleCancelSatStandByMenu(false);
  };

  // Mutation para criar satCancellationReason
  const createSatCancellationReasonQuery = gql`
    mutation CancelSatStandBy($data: CreateSatCancellationReasonInput!) {
      cancelSatStandBy(data: $data) {
        idSatCancellationReason
      }
    }
  `;

  // Query para listar Sat Cancel
  const listSatCancellationReasonQuery = gql`
    query ListSatCancellationReasonQuery($id: Int!) {
      listSatCancellationReason(id: $id) {
        idSatCancellationReason
        idSat
        isPrice
        isQuality
        isDeliveryTime
        isLegalRequirements
        isOthers
        detailReason
        expectedReactivationDate
      }
    }
  `;

  // Mutation de atualizacao de sat cancellation
  const updateSatCancellationReasonQuery = gql`
    mutation UpdateSatCancellationReasonMutation(
      $data: UpdateSatCancellationReasonInput!
    ) {
      updateSatCancellationReason(data: $data) {
        idSatCancellationReason
      }
    }
  `;

  const deleteSatCancellationReasonQuery = gql`
    mutation DeleteSatCancellationReasonMutation($id: Int!) {
      deleteSatCancellationReason(id: $id)
    }
  `;

  const [loadSatCancellationReason, { loading: satCancellationReasonLoading }] =
    useLazyQuery(listSatCancellationReasonQuery, {
      variables: {
        id: sat.idSat,
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting SAT cancellation Reason',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
      onCompleted: async response => {
        if (response.listSatCancellationReason) {
          setSatCancellationReasonStandBy(response.listSatCancellationReason);
        }
      },
    });

  // Método para chamar a mutation
  const [createSatCancellationReasonMutation] = useMutation(
    createSatCancellationReasonQuery,
  );

  const [updateSatCancellationReasonMutation] = useMutation(
    updateSatCancellationReasonQuery,
  );

  const [deleteSatCancellationReasonMutation] = useMutation(
    deleteSatCancellationReasonQuery,
  );

  const handleConfirmStandByCancellation = (formData: any) => {
    confirmDialog({
      message: `Are you sure you want to put this SAT on hold?`,
      header: 'Cancel SAT - Stand By',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        setLoading(true);

        try {
          if (!satCancellationReasonStandBy?.idSatCancellationReason) {
            await createSatCancellationReasonMutation({
              variables: {
                data: {
                  idSat: sat.idSat,
                  isPrice: formData.isPrice,
                  isQuality: formData.isQuality,
                  isDeliveryTime: formData.isDeliveryTime,
                  isLegalRequirements: formData.isLegalRequirements,
                  isOthers: formData.isOthers,
                  detailReason: formData.detailReason,
                  expectedReactivationDate: formData.expectedReactivationDate,
                },
              },
            });
          } else {
            await updateSatCancellationReasonMutation({
              variables: {
                data: {
                  idSatCancellationReason:
                    satCancellationReasonStandBy.idSatCancellationReason,
                  idSat: sat.idSat,
                  isPrice: formData.isPrice,
                  isQuality: formData.isQuality,
                  isDeliveryTime: formData.isDeliveryTime,
                  isLegalRequirements: formData.isLegalRequirements,
                  isOthers: formData.isOthers,
                  detailReason: formData.detailReason,
                  expectedReactivationDate: formData.expectedReactivationDate,
                },
              },
            });
          }

          await satRefetch();

          // Em caso de sucesso exibe toast
          toastRef.current?.show({
            severity: 'success',
            summary: `SAT on stand by saved/registered`,
            life: ToastLife.SUCCESS,
          });
        } catch (error) {
          // Em caso de erro exibe toast
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while saving/registering SAT on stand by`,
            life: ToastLife.ERROR,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  async function handleSatCancellationReasonStandBySave(formData: any) {
    try {
      // Esvazia possíveis erros já existentes no formulário
      formRef.current?.setErrors({});

      // Define requisitos de preenchimento do formulario
      const schema = Yup.object().shape({
        detailReason: Yup.string()
          .nullable()
          .notRequired()
          .when(['isOthers'], {
            is: false,
            then: Yup.string().notRequired(),
            otherwise: Yup.string().required('Required Field'),
          }),
        expectedReactivationDate: Yup.date().required('Required Field'),
      });

      // Efetua validação
      await schema.validate(formData, { abortEarly: false });

      const hasNoReasonSelected =
        !formData.isPrice &&
        !formData.isQuality &&
        !formData.isDeliveryTime &&
        !formData.isLegalRequirements &&
        !formData.isOthers;

      if (hasNoReasonSelected) {
        // Exibe toast sobre o erro
        toastRef.current?.show({
          severity: 'warn',
          summary: 'Please, fill at least one reason',
          life: ToastLife.WARN,
        });
        return;
      }

      // Se não tiver nenhum erro, abre a confimação
      handleConfirmStandByCancellation(formData);
      setIsVisibleCancelSatStandByMenu(false);
    } catch (error) {
      // Verifica se são erros de validação
      if (error instanceof Yup.ValidationError) {
        // Pega os erros de cada input
        const errors = getValidationErrors(error);

        // Define os erros para cada input
        formRef.current?.setErrors(errors);
      } else if (error instanceof Error) {
        // Exibe toast sobre o erro
        toastRef.current?.show({
          severity: 'error',
          summary: `Error while cancelling SAT`,
          detail: error.message,
          life: ToastLife.ERROR,
        });
      }
    }
  }

  const handleDeleteSatCancellationReason = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await deleteSatCancellationReasonMutation({
          variables: {
            id,
          },
        });

        satRefetch();

        toastRef.current?.show({
          severity: 'success',
          summary: 'Stand By cancelled',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while cancelling Stand By',
          detail: 'Error message',
          life: ToastLife.ERROR,
        });
      } finally {
        setIsVisibleCancelSatStandByMenu(false);
        setLoading(false);
      }
    },
    [
      deleteSatCancellationReasonMutation,
      satRefetch,
      setIsVisibleCancelSatStandByMenu,
      setLoading,
      toastRef,
    ],
  );
  const isStandByOutdated =
    satCancellationReasonStandBy?.expectedReactivationDate
      ? currentDate.getTime() >
        new Date(
          satCancellationReasonStandBy?.expectedReactivationDate,
        ).getTime()
      : false;

  const footer = () => {
    return (
      <div className="p-d-flex p-jc-between">
        <span className="p-d-flex">
          <Button
            label="Confirm"
            icon="pi pi-check"
            type="button"
            onClick={() => formRef.current?.submitForm()}
            disabled={sat.status === SatStatus.CANCELED}
          />
          <Button label="Cancel" icon="pi pi-times" onClick={() => onHide()} />
        </span>
        <Button
          label="Cancel Stand By"
          icon="pi pi-times"
          onClick={() => handleDeleteSatCancellationReason(sat.idSat)}
          className="p-button-danger"
          type="button"
          disabled={!satCancellationReasonStandBy}
        />
      </div>
    );
  };

  useEffect(() => {
    if (sat.status === SatStatus.STAND_BY && isVisibleCancelSatStandByMenu) {
      loadSatCancellationReason();
    }
  }, [sat.status, isVisibleCancelSatStandByMenu, loadSatCancellationReason]);

  return (
    <Dialog
      header="SAT Stand By"
      visible={isVisibleCancelSatStandByMenu}
      onHide={() => onHide()}
      style={{ width: '444px' }}
      footer={footer}
    >
      {!satCancellationReasonLoading &&
        (sat.status === SatStatus.STAND_BY
          ? satCancellationReasonStandBy
          : true) && (
          <Form
            className="p-formgrid p-grid p-px-3"
            ref={formRef}
            onSubmit={handleSatCancellationReasonStandBySave}
            initialData={satCancellationReasonStandBy}
          >
            {satCancellationReasonStandBy && isStandByOutdated && (
              <h4
                className="p-field p-col-12"
                style={{ color: 'crimson', marginBottom: '10px' }}
              >
                Expected Reactivation Date expired, inform new date!
              </h4>
            )}

            <h3 className="p-field p-col-12">
              Please confirm the cancellation reason:
            </h3>

            <FormCheckbox
              className="p-d-flex p-as-center p-field p-col-3"
              name="isPrice"
              label="Price"
              initialValue={
                !satCancellationReasonLoading
                  ? satCancellationReasonStandBy?.isPrice
                  : false
              }
              disabled={sat.status === SatStatus.CANCELED}
            />

            <FormCheckbox
              className="p-d-flex p-as-center p-field p-col-5"
              name="isLegalRequirements"
              label="Legal Requirements"
              initialValue={
                !satCancellationReasonLoading
                  ? satCancellationReasonStandBy?.isLegalRequirements
                  : false
              }
              disabled={sat.status === SatStatus.CANCELED}
            />

            <FormCheckbox
              className="p-d-flex p-as-center p-field p-col-4"
              name="isQuality"
              label="Quality"
              initialValue={
                !satCancellationReasonLoading
                  ? satCancellationReasonStandBy?.isQuality
                  : false
              }
              disabled={sat.status === SatStatus.CANCELED}
            />

            <FormCheckbox
              className="p-d-flex p-as-center p-field p-col-3"
              name="isOthers"
              label="Others"
              initialValue={
                !satCancellationReasonLoading
                  ? satCancellationReasonStandBy?.isOthers
                  : false
              }
              disabled={sat.status === SatStatus.CANCELED}
            />

            <FormCheckbox
              className="p-d-flex p-as-center p-field p-col-5"
              name="isDeliveryTime"
              label="Delivery Time"
              initialValue={
                !satCancellationReasonLoading
                  ? satCancellationReasonStandBy?.isDeliveryTime
                  : false
              }
              disabled={sat.status === SatStatus.CANCELED}
            />

            <FormInputTextArea
              className="p-field p-col-12"
              name="detailReason"
              label="Detail Stand By Reason"
              rows={6}
              defaultValue={
                !satCancellationReasonLoading
                  ? satCancellationReasonStandBy?.detailReason
                  : undefined
              }
              disabled={sat.status === SatStatus.CANCELED}
            />

            <FormDatePicker
              className="p-field p-col-12"
              name="expectedReactivationDate"
              label="Expected Reactivation Date"
              required
              disabled={sat.status === SatStatus.CANCELED}
            />
          </Form>
        )}

      {satCancellationReasonLoading && (
        <div>
          <div>
            <Skeleton shape="rectangle" height="315px" />
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default CancelSatStandByMenu;
