import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, {
  Dispatch,
  RefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Dialog } from 'primereact/dialog';
import Button from '../../../components/Button';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../components/PageTabContainer';
import { useRefHook } from '../../../hooks/useRefHook';

import { ActionButtons } from './styles';
import ListOfRoles from './ListOfRoles';
import ToastLife from '../../../shared/enums/toastLife';

interface IRolesProps extends PageTabContainerProps {
  userId?: number;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  innerRef: RefObject<IRolesRef>;
}

export interface IRole {
  idRole: number;
  name: string;
  active: boolean;
}

interface IListUserRolesResponse {
  idRoleUser?: number;
  idRole: number;
  idUser: number;
  idRole2: IRole;
}

export interface IRolesRef {
  handleUpdateRoles(idUser?: number): void;
}

const Roles: React.FC<IRolesProps> = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ selected, userId, setLoading, innerRef }, ref) => {
    // Referencia ao  toast
    const { toastRef } = useRefHook();

    // Colunas da grid
    const columns = [{ field: 'name', header: 'Role' }];

    // Estado para exibir modal de roles
    const [displayRolesModal, setDisplayRolesModal] = useState(false);

    // Posicao da modal
    const [position, setPosition] = useState('center');

    // User roles selecionadas
    const [selectedUserRoles, setSelectedUserRoles] = useState<IRole[]>([]);

    // Roles selecionadas
    const [userRoles, setUserRoles] = useState<IRole[]>([]);

    // Roles selecionadas na modal
    const [selectedDialogRoles, setSelectedDialogRoles] = useState<IRole[]>([]);

    // Query para listar roles do usuario
    const listUserRolesQuery = gql`
      query listRoleUserByUserId($id: Int!) {
        listRoleUserByUserId(id: $id) {
          idRoleUser
          idUser
          idRole2 {
            idRole
            name
            active
          }
        }
      }
    `;

    /**
     * Atualiza roles selecionadas na modal
     * @param roles Lista de roles
     */
    function updateSelectedRoles(roles: IListUserRolesResponse[]) {
      const newSelectedRoles = roles.map(userRole => userRole.idRole2);

      setUserRoles(newSelectedRoles);
      setSelectedDialogRoles(newSelectedRoles);
    }

    const [loadUserRolesData, { data: userRolesData }] = useLazyQuery(
      listUserRolesQuery,
      {
        variables: {
          id: userId,
        },
        onCompleted: response => {
          updateSelectedRoles(response.listRoleUserByUserId);
          setLoading(false);
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting roles data',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      },
    );

    // Ao carregar a pagina, carrega dados de role
    useEffect(() => {
      if (userId && !userRolesData) {
        setLoading(true);
        loadUserRolesData();
      }
    }, [userId, loadUserRolesData, setLoading, userRolesData]);

    /**
     * Reproduz as colunas selecionadas na configuracao
     */
    const dynamicColumns = columns.map(col => {
      return (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          header={col.header}
        />
      );
    });

    /**
     * Exibe modal
     */
    const onClick = () => {
      setDisplayRolesModal(true);

      if (position) {
        setPosition(position);
      }
    };

    /**
     * Oculta modal
     */
    const onHide = () => {
      setDisplayRolesModal(false);
    };

    /**
     * Footer da modal
     * @returns Footer
     */
    const dialogFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => {
              setUserRoles(selectedDialogRoles);
              onHide();
            }}
            disabled={!selectedDialogRoles.length}
          />
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={() => {
              setSelectedDialogRoles(userRoles);
              onHide();
            }}
            className="p-button-danger"
          />
        </div>
      );
    };

    /**
     * Deleta roles selecionadas
     */
    function handleDeleteSelected() {
      const newRoles = userRoles.filter(
        role => !selectedUserRoles.includes(role),
      );

      setUserRoles(newRoles);
      setSelectedDialogRoles(newRoles);
      setSelectedUserRoles([]);
    }

    // Mutation de update de user role
    const updateUserRolesQuery = gql`
      mutation UpdateUserRolesMutation($rolesIds: [Int]!, $idUser: Int!) {
        updateUserRoles(rolesIds: $rolesIds, idUser: $idUser)
      }
    `;

    // cria método para chamar a mutation
    const [updateUserRolesMutarion] = useMutation(updateUserRolesQuery);

    useImperativeHandle(innerRef, () => ({
      async handleUpdateRoles(idUser?: number) {
        const selectedRolesIds = userRoles.map(role => {
          return role.idRole;
        });

        // Se nao houverem roles selecionadas, aborta execucao
        if (!selectedRolesIds.length) {
          // Em caso de sucesso exibe toast
          toastRef.current?.show({
            severity: 'warn',
            summary: 'User has no roles',
            detail: 'System may not appear fully functional',
            life: ToastLife.WARN,
          });
          return;
        }

        // Chamada criacao de usuario
        await updateUserRolesMutarion({
          variables: {
            rolesIds: selectedRolesIds,
            idUser: userId || idUser,
          },
        });
      },
    }));

    return (
      <PageTabContainer selected={selected}>
        <Dialog
          header="Select user roles"
          visible={displayRolesModal}
          style={{ width: '80vw' }}
          onHide={() => onHide()}
          closable={false}
          footer={dialogFooter()}
        >
          <ListOfRoles
            selectedRoles={selectedDialogRoles}
            setSelectedRoles={setSelectedDialogRoles}
          />
        </Dialog>

        <ActionButtons>
          <Button type="button" onClick={() => onClick()}>
            Add Role
          </Button>
          <Button
            type="button"
            severity="danger"
            className="p-button-danger"
            onClick={handleDeleteSelected}
            disabled={!selectedUserRoles.length}
          >
            Delete selected
          </Button>
        </ActionButtons>

        <DataTable
          value={userRoles}
          lazy
          emptyMessage="No roles found."
          selection={selectedUserRoles}
          onSelectionChange={e => setSelectedUserRoles(e.value)}
        >
          <Column
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
          {dynamicColumns}
        </DataTable>
      </PageTabContainer>
    );
  },
);

export default Roles;
