/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Fragment, useEffect, useState } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { intersectionBy } from 'lodash';
import {
  Container,
  ViewScreen,
  Wrapper,
  Permissions,
  FieldsScreen,
} from './styles';
import BreadCrumb, { IBreadCrumbItem } from '../../components/BreadCrumb';
import {
  IEntity,
  IFieldGroup,
  IRoleByEntity,
  IRoleEntityField,
} from './interfaces';
import { useRefHook } from '../../hooks/useRefHook';
import ToastLife from '../../shared/enums/toastLife';
import PageHeader from '../../components/PageHeader';
import MainButton from '../../components/MainButton';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import useTitle from '../../hooks/useTitle';

interface RoleByEntityRouteParams {
  idEntity: string;
}

const defaultPageTitle = 'Manage Functionality';

const RoleByFunctionality: React.FC = () => {
  // URL
  const { pathname, search } = useLocation();

  // Redirect
  const history = useHistory();

  // ID da role
  const idEntity = parseInt(useParams<RoleByEntityRouteParams>().idEntity, 10);

  // Estado com dados da entity
  const [entityData, setEntityData] = useState<IEntity>();

  useTitle(
    entityData ? `${entityData.name} - ${defaultPageTitle}` : defaultPageTitle,
  );

  // Referencia ao toast
  const { toastRef, showError } = useRefHook();

  // Itens do breadcrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'List of Roles',
      path: '/roles',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Estado de roles por entity
  const [rolesByEntity, setRolesByEntity] = useState<IRoleByEntity[]>();

  // Field Group selecionado
  const [selectedFieldGroup, setSelectedFieldGroup] = useState<IFieldGroup>();

  const [pageLoading, setPageLoading] = useState<boolean>(false);

  // Mutation de update de roles
  const updateRolesByEntityQuery = gql`
    mutation UpdateRolesByEntity($data: UpdateRolesByEntityInput!) {
      updateRolesByEntity(data: $data)
    }
  `;

  // Lista roles por Entity
  const listRoleByEntityQuery = gql`
    query listRolesByEntity($idEntity: Int!, $idsRoles: [Int]) {
      listRolesByEntity(idEntity: $idEntity, idsRoles: $idsRoles) {
        idRole
        name
        active
        roleEntities {
          idRoleEntity
          idEntity
          roleEntityFields {
            idRoleEntityField
            idField
            view
            create
            edit
          }
          roleEntityPermissions {
            idRoleEntityPermission
            idPermission
          }
        }
      }
    }
  `;

  // Lista dados da entity
  const listEntityByIdQuery = gql`
    query listEntityById($idEntity: Int!) {
      listEntityById(idEntity: $idEntity) {
        idEntity
        name
        active
        permissions {
          idPermission
          name
          active
        }
        fieldGroups {
          idFieldGroup
          groupName
          fields {
            idField
            label
            required
          }
        }
      }
    }
  `;

  // cria método para chamar a mutation
  const [updateRolesByEntityMutation] = useMutation(updateRolesByEntityQuery);

  // Carrega dados da role
  const [
    loadRoleData,
    { loading: roleByEntityLoading, refetch: roleDataRefetch },
  ] = useLazyQuery(listRoleByEntityQuery, {
    onCompleted: response => {
      setRolesByEntity(response.listRolesByEntity);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting entity data',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Carrega dados da entity
  const [loadEntityData, { loading: listEntityByIdLoading }] = useLazyQuery(
    listEntityByIdQuery,
    {
      variables: {
        idEntity,
      },
      onCompleted: response => {
        setSelectedFieldGroup(response.listEntityById.fieldGroups[0]);
        setEntityData(response.listEntityById);
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting entity data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  /**
   * Altera valor de role entity view screen
   * @param index Indice de Role entity
   */
  function handleChangeViewScreen(index: number) {
    // Valida se estado nao e nulo
    if (rolesByEntity) {
      // Copia estado de roles entities para constante
      const rolesEntities = [...rolesByEntity];

      // Indica que houve mudanca na role
      rolesEntities[index].changed = true;

      // Valida se role possuia permissoes
      if (rolesEntities[index].roleEntities?.length) {
        // Se possuia, remove
        rolesEntities[index].roleEntities = [];
      } else {
        const allRequiredFields: IRoleEntityField[] = [];

        entityData?.fieldGroups.forEach(fieldGroup => {
          fieldGroup.fields.forEach(field => {
            if (field.required) {
              allRequiredFields.push({
                idField: field.idField,
                create: true,
                edit: true,
                view: true,
              });
            }
          });
        });

        // Se nao possuia, cria valores
        rolesEntities[index].roleEntities = [
          {
            idEntity,
            roleEntityFields: allRequiredFields,
            roleEntityPermissions: [],
          },
        ];
      }

      // Atualiza estado
      setRolesByEntity(rolesEntities);
    }
  }

  /**
   * Altera permissao da role
   * @param roleIndex Indice da role
   * @param idPermission ID da permissao a ser adicionada
   * @param event Evento da checkbox
   */
  function handleChangePermission(
    roleIndex: number,
    idPermission: number,
    event: CheckboxChangeParams,
  ) {
    // Valida se estado nao e nulo
    if (rolesByEntity) {
      // Copia estado de roles entities para constante
      const rolesEntities = [...rolesByEntity];

      // Indica que houve mudanca na role
      rolesEntities[roleIndex].changed = true;

      // Valida se checkbox indica inclusao
      if (event.checked) {
        // Se for inclusao, cria permission
        rolesEntities[roleIndex].roleEntities[0].roleEntityPermissions = [
          ...rolesEntities[roleIndex].roleEntities[0].roleEntityPermissions,
          { idPermission },
        ];
      } else {
        // Se nao, remove
        rolesEntities[roleIndex].roleEntities[0].roleEntityPermissions =
          rolesEntities[roleIndex].roleEntities[0].roleEntityPermissions.filter(
            permission => permission.idPermission !== idPermission,
          );
      }

      // Atualiza estado
      setRolesByEntity(rolesEntities);
    }
  }

  function handleAllPermissionsToggle(
    event: InputSwitchChangeParams,
    roleIndex: number,
  ) {
    // Valida se estado nao e nulo
    if (rolesByEntity) {
      // Copia estado de roles entities para constante
      const rolesEntities = [...rolesByEntity];

      // Indica que houve mudanca na role
      rolesEntities[roleIndex].changed = true;

      // Valida se toggle indica inclusao
      if (event.value) {
        // Busca ID de todas as permissions
        const permissionsIds = entityData?.permissions.map(permission => {
          return { idPermission: permission.idPermission };
        });

        // Se houverem permissions IDs
        if (permissionsIds) {
          // Se for inclusao, cria permissions
          rolesEntities[roleIndex].roleEntities[0].roleEntityPermissions = [
            ...permissionsIds,
          ];
        }
      } else {
        // Se nao, remove
        rolesEntities[roleIndex].roleEntities[0].roleEntityPermissions = [];
      }

      // Atualiza estado
      setRolesByEntity(rolesEntities);
    }
  }

  /**
   * Atualiza todos os fields de determinada role
   * @param event Evento do switch
   * @param roleIndex Indice da role
   * @param property Propriedade a ser alterada
   */
  function handleAllFieldsToggle(
    event: InputSwitchChangeParams,
    roleIndex: number,
    property: string,
  ) {
    // Valida se estado nao e nulo
    if (rolesByEntity) {
      // Copia estado de roles entities para constante
      const rolesEntities = [...rolesByEntity];

      // Indica que houve mudanca na role
      rolesEntities[roleIndex].changed = true;

      // Array de novos fields
      const newFields: IRoleEntityField[] = [];

      // Busca Field Group Selecionado
      const fieldGroupIndex = entityData?.fieldGroups.findIndex(
        fieldGroup =>
          fieldGroup.idFieldGroup === selectedFieldGroup?.idFieldGroup,
      );

      if (fieldGroupIndex !== undefined) {
        // Cria novos fields
        entityData?.fieldGroups[fieldGroupIndex].fields.forEach(field => {
          if (!field.required) {
            newFields.push({
              idField: field.idField,
              [property]: event.value,
            });
          }
        });
      }

      // Se role nao possuir fields, adiciona novos
      if (!rolesEntities[roleIndex].roleEntities[0].roleEntityFields?.length) {
        rolesEntities[roleIndex].roleEntities[0].roleEntityFields = newFields;
      }

      // Percorre os novos fields
      newFields.forEach(newField => {
        // Busca indice de field da role que seja identico ao novo
        const fieldIndex = rolesEntities[
          roleIndex
        ].roleEntities[0].roleEntityFields.findIndex(
          field => newField.idField === field.idField,
        );

        // Se indice existir, atualiza valor com nova property
        if (fieldIndex !== -1) {
          rolesEntities[roleIndex].roleEntities[0].roleEntityFields[
            fieldIndex
          ] = {
            ...rolesEntities[roleIndex].roleEntities[0].roleEntityFields[
              fieldIndex
            ],
            [property]: event.value,
          };
        } else {
          // Se nao existir, adiciona field novo
          rolesEntities[roleIndex].roleEntities[0].roleEntityFields.push(
            newField,
          );
        }
      });

      // Atualiza estado
      setRolesByEntity(rolesEntities);
    }
  }

  /**
   * Altera valor do field
   * @param roleIndex Indice da Role
   * @param idField ID do Field
   * @param property Propriedade a ser alterada
   * @param event Evento de checkboxk
   */
  function handleChangeField(
    roleIndex: number,
    idField: number,
    property: string,
    event: CheckboxChangeParams,
  ) {
    // Valida se estado nao e nulo
    if (rolesByEntity) {
      // Copia estado de roles entities para constante
      const rolesEntities = [...rolesByEntity];

      // Indica que houve mudanca na role
      rolesEntities[roleIndex].changed = true;

      // Valida se role nao possui nenhum field
      if (!rolesEntities[roleIndex].roleEntities[0].roleEntityFields?.length) {
        // Adiciona novo field na role
        rolesEntities[roleIndex].roleEntities[0].roleEntityFields = [
          { idField, [property]: event.checked },
        ];
      } else {
        // Busca indice do field a ser alterado
        const fieldIndex = rolesEntities[
          roleIndex
        ].roleEntities[0].roleEntityFields.findIndex(
          field => field.idField === idField,
        );

        if (fieldIndex !== -1) {
          // Atualiza dados do field
          rolesEntities[roleIndex].roleEntities[0].roleEntityFields[
            fieldIndex
          ] = {
            ...rolesEntities[roleIndex].roleEntities[0].roleEntityFields[
              fieldIndex
            ],
            [property]: event.checked,
          };
        } else {
          rolesEntities[roleIndex].roleEntities[0].roleEntityFields.push({
            idField,
            [property]: event.checked,
          });
        }
      }

      // Atualiza estado
      setRolesByEntity(rolesEntities);
    }
  }

  async function handleSubmit() {
    setPageLoading(true);
    try {
      // Atualiza dados no backend
      await updateRolesByEntityMutation({
        variables: {
          data: {
            idEntity,
            roles: rolesByEntity?.filter(role => role.changed),
          },
        },
      });

      // Exibe toast de sucesso
      toastRef.current?.show({
        severity: 'success',
        summary: 'Functionality roles successfully updated',
        life: ToastLife.SUCCESS,
      });

      // Recarrega dados da pagina
      roleDataRefetch();
      loadEntityData();
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while updating functionality roles',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    } finally {
      setPageLoading(false);
    }
  }

  /**
   * Valida de Fields da role contem fields do grupo selecionado
   * @param roleEntityFields Role Entity Fields
   * @param property Propriedade a ser buscada do field
   * @returns Verdadeiro ou Falso
   */
  function roleFieldsContainsSelectedFieldGroup(
    roleEntityFields: IRoleEntityField[],
    property: string,
  ) {
    // Busca Field Group Selecionado
    const fieldGroupIndex = entityData?.fieldGroups.findIndex(
      fieldGroup =>
        fieldGroup.idFieldGroup === selectedFieldGroup?.idFieldGroup,
    );

    if (rolesByEntity !== undefined && fieldGroupIndex !== undefined) {
      return (
        intersectionBy(
          entityData?.fieldGroups[fieldGroupIndex].fields,
          roleEntityFields?.filter(field => field[property] === true),
          'idField',
        ).length === entityData?.fieldGroups[fieldGroupIndex].fields.length
      );
    }

    return false;
  }

  // Ao carregar a pagina, carrega dados da role
  useEffect(() => {
    function validateRoleLimit() {
      const rolesParams = new URLSearchParams(search).get('roles');

      const rolesIdsIntArray = rolesParams?.split(',').map(Number);

      if (!rolesIdsIntArray?.length || rolesIdsIntArray?.length > 5) {
        showError({
          summary: 'Error while loading roles by functionality',
          detail: 'You must select at least one role and a maximum of 5 roles',
        });

        history.push('/roles');
        return;
      }

      loadRoleData({
        variables: {
          idsRoles: rolesIdsIntArray,
          idEntity,
        },
      });
      loadEntityData();
    }

    validateRoleLimit();
  }, [history, idEntity, loadEntityData, loadRoleData, search, showError]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          entityData
            ? `${defaultPageTitle} - ${entityData.name} `
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            handleSubmit();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            history.push('/roles');
          }}
        />
      </PageHeader>
      {!roleByEntityLoading && !listEntityByIdLoading && (
        <Wrapper>
          <Tooltip target=".field-span" showDelay={400} position="top" />
          <h1>Roles</h1>
          <ViewScreen>
            <thead>
              <tr>
                <th />
                {rolesByEntity?.map(role => {
                  return <th key={role.idRole}>{role.name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>View Screen</th>
                {rolesByEntity?.map((role, index) => {
                  return (
                    <td key={role.idRole}>
                      <Checkbox
                        checked={!!role.roleEntities?.length}
                        onChange={() => handleChangeViewScreen(index)}
                      />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </ViewScreen>

          <h1>Permissions</h1>
          <Permissions>
            {!entityData?.permissions?.length ? (
              <p className="message">
                There is no permissions for this functionality
              </p>
            ) : (
              <>
                <thead>
                  <tr>
                    <th />
                    {rolesByEntity?.map((role, index) => {
                      return (
                        <th key={role.idRole}>
                          <span>
                            <InputSwitch
                              checked={
                                role.roleEntities[0]?.roleEntityPermissions
                                  ?.length === entityData?.permissions?.length
                              }
                              onChange={e =>
                                handleAllPermissionsToggle(e, index)
                              }
                              disabled={!role.roleEntities?.length}
                              className={
                                role.roleEntities[0]?.roleEntityPermissions
                                  ?.length &&
                                role.roleEntities[0]?.roleEntityPermissions
                                  ?.length !== entityData?.permissions?.length
                                  ? 'partial-checked'
                                  : undefined
                              }
                            />
                            {role.name}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {entityData?.permissions.map(permission => {
                    return (
                      <tr key={permission.idPermission}>
                        <th>{permission.name}</th>
                        {rolesByEntity?.map((role, index) => {
                          return (
                            <td key={role.idRole}>
                              <Checkbox
                                checked={
                                  !!role.roleEntities[0]?.roleEntityPermissions.find(
                                    rolePermission =>
                                      rolePermission.idPermission ===
                                      permission.idPermission,
                                  )
                                }
                                onChange={e =>
                                  handleChangePermission(
                                    index,
                                    permission.idPermission,
                                    e,
                                  )
                                }
                                disabled={!role.roleEntities?.length}
                              />
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
          </Permissions>
          <h1>Fields Screen</h1>
          <FieldsScreen>
            {!entityData?.fieldGroups?.length ? (
              <p className="message">
                There is no fields for this functionality
              </p>
            ) : (
              <>
                <thead>
                  <tr>
                    <th />
                    {rolesByEntity?.map(role => {
                      return (
                        <th key={role.idRole} colSpan={3} className="role-th">
                          {role.name}
                        </th>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <Dropdown
                        optionLabel="groupName"
                        value={selectedFieldGroup}
                        options={entityData?.fieldGroups}
                        onChange={e => setSelectedFieldGroup(e.value)}
                        placeholder="Select a Field Group"
                        filter
                        onFocus={e =>
                          e.target.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                            inline: 'end',
                          })
                        }
                      />
                    </th>
                    {rolesByEntity?.map((role, index) => {
                      return (
                        <Fragment key={role.idRole}>
                          <th>
                            <span
                              className="field-span"
                              data-pr-tooltip="Exibe o campo na grid e na tela de manutenção quando colunas create e edit desmarcadas."
                            >
                              <InputSwitch
                                checked={roleFieldsContainsSelectedFieldGroup(
                                  role.roleEntities[0]?.roleEntityFields,
                                  'view',
                                )}
                                onChange={e =>
                                  handleAllFieldsToggle(e, index, 'view')
                                }
                                disabled={!role.roleEntities?.length}
                                className={
                                  role.roleEntities[0]?.roleEntityFields
                                    ?.length &&
                                  role.roleEntities[0]?.roleEntityFields?.filter(
                                    field => field.view,
                                  ).length !==
                                    entityData?.fieldGroups?.reduce(
                                      (acc, key) => acc + key.fields.length,
                                      0,
                                    )
                                    ? 'partial-checked'
                                    : undefined
                                }
                              />
                              View
                            </span>
                          </th>
                          <th>
                            <span
                              className="field-span"
                              data-pr-tooltip="Exibe o campo habilitado na tela de inclusão."
                            >
                              <InputSwitch
                                checked={roleFieldsContainsSelectedFieldGroup(
                                  role.roleEntities[0]?.roleEntityFields,
                                  'create',
                                )}
                                onChange={e =>
                                  handleAllFieldsToggle(e, index, 'create')
                                }
                                disabled={!role.roleEntities?.length}
                                className={
                                  role.roleEntities[0]?.roleEntityFields
                                    ?.length &&
                                  role.roleEntities[0]?.roleEntityFields?.filter(
                                    field => field.create,
                                  ).length !==
                                    entityData?.fieldGroups?.reduce(
                                      (acc, key) => acc + key.fields.length,
                                      0,
                                    )
                                    ? 'partial-checked'
                                    : undefined
                                }
                              />
                              Create
                            </span>
                          </th>
                          <th>
                            <span
                              className="field-span"
                              data-pr-tooltip="Exibe o campo habilitado na tela de edição."
                            >
                              <InputSwitch
                                checked={roleFieldsContainsSelectedFieldGroup(
                                  role.roleEntities[0]?.roleEntityFields,
                                  'edit',
                                )}
                                onChange={e =>
                                  handleAllFieldsToggle(e, index, 'edit')
                                }
                                disabled={!role.roleEntities?.length}
                                className={
                                  role.roleEntities[0]?.roleEntityFields
                                    ?.length &&
                                  role.roleEntities[0]?.roleEntityFields?.filter(
                                    field => field.edit,
                                  ).length !==
                                    entityData?.fieldGroups?.reduce(
                                      (acc, key) => acc + key.fields.length,
                                      0,
                                    )
                                    ? 'partial-checked'
                                    : undefined
                                }
                              />
                              Edit
                            </span>
                          </th>
                        </Fragment>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <>
                    {selectedFieldGroup?.fields.map(field => {
                      return (
                        <tr key={field.idField}>
                          <th
                            className={
                              field.required
                                ? 'required-field'
                                : 'regular-field'
                            }
                          >
                            {field.label}
                          </th>
                          {rolesByEntity?.map((role, roleIndex) => {
                            return (
                              <Fragment key={role.idRole}>
                                <td>
                                  <Checkbox
                                    className={
                                      field.required
                                        ? 'required-field'
                                        : 'regular-field'
                                    }
                                    checked={
                                      !!role.roleEntities[0]?.roleEntityFields.find(
                                        roleField =>
                                          roleField.idField === field.idField &&
                                          roleField.view,
                                      )
                                    }
                                    onChange={e =>
                                      handleChangeField(
                                        roleIndex,
                                        field.idField,
                                        'view',
                                        e,
                                      )
                                    }
                                    disabled={
                                      !role.roleEntities?.length ||
                                      field.required
                                    }
                                  />
                                </td>
                                <td>
                                  <Checkbox
                                    className={
                                      field.required
                                        ? 'required-field'
                                        : 'regular-field'
                                    }
                                    checked={
                                      !!role.roleEntities[0]?.roleEntityFields.find(
                                        roleField =>
                                          roleField.idField === field.idField &&
                                          roleField.create,
                                      )
                                    }
                                    onChange={e =>
                                      handleChangeField(
                                        roleIndex,
                                        field.idField,
                                        'create',
                                        e,
                                      )
                                    }
                                    disabled={
                                      !role.roleEntities?.length ||
                                      field.required
                                    }
                                  />
                                </td>
                                <td>
                                  <Checkbox
                                    className={
                                      field.required
                                        ? 'required-field'
                                        : 'regular-field'
                                    }
                                    checked={
                                      !!role.roleEntities[0]?.roleEntityFields.find(
                                        roleField =>
                                          roleField.idField === field.idField &&
                                          roleField.edit,
                                      )
                                    }
                                    onChange={e =>
                                      handleChangeField(
                                        roleIndex,
                                        field.idField,
                                        'edit',
                                        e,
                                      )
                                    }
                                    disabled={
                                      !role.roleEntities?.length ||
                                      field.required
                                    }
                                  />
                                </td>
                              </Fragment>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                </tbody>
              </>
            )}
          </FieldsScreen>
        </Wrapper>
      )}
      {(roleByEntityLoading || listEntityByIdLoading || pageLoading) && (
        <Loading />
      )}
    </Container>
  );
};

export default RoleByFunctionality;
