import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  padding: 26px 32px;

  h1 {
    margin-top: 30px;
    color: var(--blue);
  }

  .p-selectbutton {
    display: flex;
    width: 100%;
    white-space: nowrap;
  }

  .message {
    padding: 12px 0;
    color: var(--red);
  }

  table {
    font-size: 10px;
  }
`;

export const ViewScreen = styled.table`
  border: none;
  border-collapse: collapse;

  th {
    font-weight: 400;
    white-space: nowrap;
  }

  thead th {
    color: #444444;
    text-align: center;
    padding-bottom: 13px;
    padding: 14px 10px;
  }

  tbody td {
    text-align: center;
    background: #ffffff;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 10px 17px;
  }

  tbody th {
    padding-right: 35px;
    text-align: left;
    padding-left: 12px;
    width: 200px;
    padding-left: 12px;
    position: sticky;
    left: 0;
    background: #ffffff;
    z-index: 1;
  }
`;

export const Permissions = styled.table`
  border: none;
  border-collapse: collapse;

  th {
    font-weight: 400;
    white-space: nowrap;
  }

  thead th {
    span {
      display: flex;
      flex-direction: column;
      align-items: center;

      .p-inputswitch-slider {
        padding-bottom: 5px;
        height: 24px;
      }

      .partial-checked .p-inputswitch-slider {
        background-color: var(--alert);
      }
    }
    color: #444444;
    text-align: center;
    padding-bottom: 13px;
    padding: 14px 10px;
  }

  tbody td {
    text-align: center;
    background: #ffffff;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 10px 17px;
  }

  tbody th {
    padding-right: 35px;
    text-align: left;
    padding-left: 12px;
    position: sticky;
    left: 0;
    background: #ffffff;
    z-index: 1;
    min-width: 200px;
    max-width: 200px;
  }
`;

export const FieldsScreen = styled.table`
  border: none;
  border-collapse: collapse;

  .group-name {
    color: var(--orange);
    font-size: 20px;
    font-weight: 500;
  }

  th {
    font-weight: 400;
    white-space: nowrap;
  }

  .role-th {
    top: 60px;
  }

  thead th {
    span {
      display: flex;
      flex-direction: column;
      align-items: center;

      .p-inputswitch-slider {
        padding-bottom: 5px;
        height: 24px;
      }

      .partial-checked .p-inputswitch-slider {
        background-color: var(--alert);
      }

      position: sticky;
      z-index: 1;
    }
    color: #444444;
    text-align: center;
    padding-bottom: 13px;
    padding: 14px 10px;

    position: sticky;
    top: 100px;
    z-index: 1;
    background: #ffffff;
  }

  tbody td {
    text-align: center;
    background: #ffffff;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 10px 17px;
  }

  tbody th {
    padding-right: 35px;
    padding-left: 12px;
    text-align: left;
    position: sticky;
    left: 0;
    background: #ffffff;
    z-index: 1;
  }

  .required-field {
    color: var(--sunasia-red);

    .p-highlight {
      background: var(--sunasia-red);
      border-color: var(--sunasia-red);
      &:hover {
        background: ${shade(0.2, '#B1251B')} !important;
        border-color: ${shade(0.2, '#B1251B')} !important;
      }
    }
  }
`;
