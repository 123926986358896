import styled from 'styled-components';

interface SidebarProps {
  sidebarVisible: boolean;
}

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  max-height: calc(
    100% - 96px
  ); /* Subtract the header and footer heights from the viewport height */
  margin-top: 64px; /* Add top margin to account for the header height */
  overflow: hidden; /* Hide any potential scrollbars from the container */
`;

export const Content = styled.div<SidebarProps>`
  flex: 1;
  overflow-y: auto;
`;
