import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';

import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { SatFinancialTotalFieldsPermissions } from '../../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';

interface IPurchaseTotalPaidProps {
  fieldsPermissions: SatFinancialTotalFieldsPermissions;
  userCanSee: boolean;
  satCurrency?: string;
  totalPaid: number;
  totalRemainCot: number;
  totalPaidLoading: boolean;
}

const PurchaseTotalPaid: React.FC<IPurchaseTotalPaidProps> = ({
  fieldsPermissions,
  userCanSee,
  satCurrency,
  totalPaid,
  totalRemainCot,
  totalPaidLoading,
}) => {
  const satCurrencySymbol = getCurrencySymbol(satCurrency);

  return userCanSee ? (
    <div className="p-grid">
      <FormTitle className="p-col-12" name="Total Paid" />

      {fieldsPermissions.total.view && (
        <ReadOnlyInput
          className="p-col-2"
          label="Total Paid"
          value={totalPaid}
          loading={totalPaidLoading}
          type="number"
          locale="pt-BR"
          prefix={satCurrencySymbol}
          maximumFractionDigits={4}
          minimumFractionDigits={2}
        />
      )}
      {fieldsPermissions.totalRemain.view && (
        <ReadOnlyInput
          className="p-col-2"
          label="Total Remain COT"
          value={totalRemainCot}
          loading={totalPaidLoading}
          type="number"
          locale="pt-BR"
          prefix={satCurrencySymbol}
          maximumFractionDigits={4}
          minimumFractionDigits={2}
          severity={totalRemainCot && totalRemainCot < 0 ? 'danger' : undefined}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default PurchaseTotalPaid;
