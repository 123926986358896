import React from 'react';

import { Container } from './styles';

export interface PageTabContainerProps {
  selected: boolean;
}

const PageTabContainer: React.FC<PageTabContainerProps> = ({
  selected,
  children,
}) => {
  return <Container selected={selected}>{children}</Container>;
};

export default PageTabContainer;
