import { DocumentNode, gql } from '@apollo/client';
import { SatFinancialAdvancePaidFieldsPermissions } from '../../interfaces';

export const listAllSatForeignTradePaymentsQuery = (
  fieldsPermissions: SatFinancialAdvancePaidFieldsPermissions,
): DocumentNode => gql`
  query ListAllSatForeignTradePayments(
    $data: ListAllSatForeignTradePaymentsInput!
  ) {
    listAllSatForeignTradePayments(data: $data) {
      data {
        idType
        idSatForeignTradePayment
        idSatForeignTradeSupplier
        idSatForeignTradeSupplierDestination
        ${fieldsPermissions.percentage.view ? 'percentage' : ''}
        ${fieldsPermissions.amount.view ? 'amount' : ''}
        ${fieldsPermissions.paymentDate.view ? 'paymentDate' : ''}
        ${
          fieldsPermissions.rollingFrom.view
            ? `idSatForeignTrade2 {
                satForeignTradeNumber
              }`
            : ''
        }
        ${
          fieldsPermissions.rollingTo.view
            ? `idSatForeignTradeDestination2 {
                satForeignTradeNumber
              }`
            : ''
        }

      }
    }
  }
`;

export const listAdvanceReceivedTotals = gql`
  query ListSatForeignTradeSupplierAdvanceTotals(
    $idSatForeignTradeSupplier: Int!
    $typeIdDomainGroup: Int!
  ) {
    listSatForeignTradeSupplierAdvanceTotals(
      idSatForeignTradeSupplier: $idSatForeignTradeSupplier
      typeIdDomainGroup: $typeIdDomainGroup
    ) {
      totalAmount
    }
  }
`;
