/* eslint-disable no-shadow */

import { SatForeignTradeShipmentContainer } from '../../interfaces';

export enum ManageForeignTradeContainerReducerActionKind {
  CLOSE_DIALOG,
  OPEN_DIALOG,
  SET_CONTAINER_NAME,
}

export type ManageForeignTradeContainerReducer = {
  displayDialog: boolean;
  containerName?: string;
  idContainerToEdit?: number;
};

export type ManageForeignTradeContainerReducerAction = {
  type: ManageForeignTradeContainerReducerActionKind;
  payload?: {
    shimpentContainer?: SatForeignTradeShipmentContainer;
    containerName?: string;
  };
};
