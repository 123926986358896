import styled from 'styled-components';

export const Container = styled.div``;

export const GridHeader = styled.div`
  display: flex;
  place-content: end;
  margin-bottom: 2px;

  .p-inputtext {
    margin-right: 12px;
  }
`;
