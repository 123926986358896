import { useLazyQuery } from '@apollo/client';
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { useState } from 'react';
import { Divider } from 'primereact/divider';
import { useRefHook } from '../../../../hooks/useRefHook';
import { StatusLayout } from '../../../../shared/enums/statusLayout';

import Loading from '../../../../components/Loading';
import { ISatProcessStatus } from './interfaces';
import { getProcessStatusQuery } from './queries';
import {
  Container,
  Process,
  ProcessTag,
  ProcessTitle,
  Processes,
  SatProcess,
  SatProcessNumber,
} from './styles';
import { satProcessName } from './constants';
import StatusHandlerLog from '../Status/StatusHandlerLog';

interface IHeaderStatusProps {
  idSat: number;
}

const HeaderStatus: React.FC<IHeaderStatusProps> = ({ idSat }) => {
  const { showError } = useRefHook();

  const [satProcessesStatus, setSatProcessesStatus] =
    useState<ISatProcessStatus[]>();

  const [loadSatProcessStatusData, { loading: satProcessStatusLoading }] =
    useLazyQuery(getProcessStatusQuery, {
      variables: {
        idSat,
      },
      onCompleted: response => {
        if (response.getSatProcessStatus) {
          setSatProcessesStatus(response.getSatProcessStatus);
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Status',
          detail: errorData.message,
        });
      },
    });

  const fieldSetHeaderTemplate = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-right'
      : 'pi pi-chevron-down';

    return (
      <div className={`${options.className} p-jc-start`}>
        <span className={`${options.titleClassName} p-mr-2`}>Status</span>
        <button
          type="button"
          aria-label="Expand/Collapse Status"
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <i className={toggleIcon} />
        </button>

        {!options.collapsed && (
          <>
            <Divider layout="vertical" className="p-mx-2 p-p-0" />
            <button
              type="button"
              title="Refresh"
              aria-label="Refresh Status"
              className={options.togglerClassName}
              onClick={() => {
                loadSatProcessStatusData();
              }}
            >
              <i className="pi pi-refresh" />
            </button>
          </>
        )}
      </div>
    );
  };
  return (
    <Container>
      <Panel
        headerTemplate={fieldSetHeaderTemplate}
        onExpand={() => {
          loadSatProcessStatusData();
        }}
        toggleable
        collapsed
      >
        {!satProcessStatusLoading && !!satProcessesStatus?.length && (
          <StatusHandlerLog idSat={idSat} />
        )}
        {!satProcessStatusLoading &&
          satProcessesStatus?.map(satProcessStatus => {
            return (
              <SatProcess key={satProcessStatus.satForeignTradeNumber}>
                <SatProcessNumber>
                  {satProcessStatus.satForeignTradeSupplierNumber}
                </SatProcessNumber>
                <Processes className="flex">
                  {Object.entries(satProcessStatus?.processStatus)?.map(
                    ([processName, processData]) => {
                      const statusLayout = processData?.statusLayout;
                      const statusCssClass = statusLayout?.idLayout
                        ? StatusLayout[
                            statusLayout?.idLayout
                          ]?.toLocaleLowerCase()
                        : '';
                      return (
                        <Process className={statusCssClass} key={processName}>
                          <ProcessTitle>
                            {satProcessName[processName]}
                          </ProcessTitle>
                          <ProcessTag className="tag">
                            {statusLayout?.idLayout ===
                              StatusLayout.SUCCESS && (
                              <i className="pi pi-check" />
                            )}
                            {statusLayout?.idLayout === StatusLayout.WARN && (
                              <i className="pi pi-exclamation-triangle" />
                            )}
                            <p>{processData?.description}</p>
                          </ProcessTag>
                        </Process>
                      );
                    },
                  )}
                </Processes>
              </SatProcess>
            );
          })}
        {satProcessStatusLoading && <Loading />}
      </Panel>
    </Container>
  );
};

export default HeaderStatus;
