import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTablePageParams,
  DataTableRowReorderParams,
  DataTableSortParams,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { IoRefresh } from 'react-icons/io5';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tooltip } from 'primereact/tooltip';
import React, {
  Dispatch,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { FiExternalLink, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import Button from '../../../../../components/Button';
import Grid from '../../../../../components/Grid';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { IListSatItem, ISat } from '../../interfaces';
import { getCurrencySymbol } from '../../../../../utils/getCurrencySymbol';
import AddSatItem, { IAddSatItemRef } from './AddSatItem';

import { Container, GridActions, GridHeader } from './styles';
import { satItemsRoles } from '../../../../../shared/roles/satItems';
import getFieldPermission, {
  isInstanceOfFieldPermissionResponse,
} from '../../../../../utils/getFieldPermission';
import updateLocalStorageInDb from '../../../../../utils/updateLocalStorageInDb';
import ToastLife from '../../../../../shared/enums/toastLife';
import {
  createClientQuotation,
  createClientQuotationQuery,
} from '../../../../../shared/mutations/clientQuotation';
import { IRoleEntityPermission } from '../../../../../interfaces/IRoleEntityPermission';
import userHasPermission from '../../../../../utils/userHasPermission';
import { clientQuotationRoles } from '../../../../../shared/roles/clientQuotation';
import { satsRoles } from '../../../../../shared/roles/sat';
import {
  DifferenceToRefreshSatItemsResponse,
  HandleUpdateSatItemsResponse,
  IRefreshSatItemOptions,
  ISatItemsLazyParams,
  SatItemsFieldsPermissions,
  SatItemsReducerActionKind,
  SatItemsReferenceSizeBarcodesFieldsPermissions,
  SatItemsReferenceSizesFieldsPermissions,
} from './interfaces';
import pagination from '../../../../../config/pagination';
import {
  parseFormatedTextColumn,
  parseImageColumn,
  parseNullableBooleanColumn,
  parsePercentageColumnPt,
} from '../../../../../utils/gridColumnsParse';
import { AdvancedFiltersFieldType } from '../../../../../components/AdvancedFiltersPanel/interfaces';
import AdvancedFiltersPanel from '../../../../../components/AdvancedFiltersPanel';
import ReferenceSizes from './ReferenceSizes';
import { satItemsReducer, satItemsReducerInitialState } from './reducers';
import { useAuth } from '../../../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../../../utils/getUserFieldsAndPermissionsByEntity';
import TotalDetails, { ITotalDetailsRef } from './TotalDetails';
import MainButton from '../../../../../components/MainButton';
import BooleanRadio from '../../../../../components/BooleanRadio';
import RefreshItemSelectFields, {
  IRefreshSatItemSelectFieldsRef,
} from './RefreshItemSelectFields';
import RefreshItemSelectItems, {
  IRefreshSatItemSelectItemsRef,
} from './RefreshItemSelectItems';
import { reorderSatItemQuery, updateSatItemsQuery } from './queries';
import MultiSelectGroup, {
  MultiSelectGroupData,
} from '../../../../../components/Grid/MultiSelectGroup';
import { ColumnData } from '../../../../../components/Grid/interfaces';
import { gridColumnsData, groupedColoumns } from './constants';
import MutationResponseSummary, {
  IMutationResponse,
  IMutationResponseSummaryRef,
} from '../../../../../components/MutationResponseSummary';
import { gridConstants } from '../../../../../components/Grid/constants';
import { filterOnlyAllowedColumnsFromLocalStorage } from '../../../../../components/Grid/utils';
import { ISatForeignTradeRef } from '../../ForeignTrade';

export interface IItemsGridProps {
  selected: boolean;
  setPageLoading: Dispatch<SetStateAction<boolean>>;
  sat: ISat;
  setShouldRefetchSatSuppliers: Dispatch<SetStateAction<boolean>>;
  satRefetch(): Promise<void>;
  roleEntityPermissions: IRoleEntityPermission[];
  ref: Ref<ISatItemsGridRef>;
  isSatCancelled: boolean;
  cleanSat: () => void;
  foreignTradeRef: React.RefObject<ISatForeignTradeRef>;
  updateSatVersionLock(): void;
}

export interface ISatItemsGridRef {
  handleUpdateItems(): Promise<HandleUpdateSatItemsResponse>;
  refreshItems(): void;
}

const ItemsGrid: React.FC<IItemsGridProps> = React.forwardRef(
  (
    {
      selected,
      setPageLoading,
      sat,
      setShouldRefetchSatSuppliers,
      satRefetch,
      roleEntityPermissions,
      isSatCancelled,
      cleanSat,
      foreignTradeRef,
      updateSatVersionLock,
    },
    ref,
  ) => {
    // Nome da key de grid columns
    const gridColumnsName = '@SAT:satItemsGridColumns';

    const lazyParamsName = '@SAT:satItemsLazyParams';

    const advancedFiltersName = '@SAT:satItemsAdvancedFilters';

    const deleteItemsSummaryRef = useRef<IMutationResponseSummaryRef>(null);
    const addItemsSummaryRef = useRef<IMutationResponseSummaryRef>(null);

    // Referencia ao toast e formulario
    const { toastRef } = useRefHook();

    // Roles do usuario
    const { roles } = useAuth();

    // Referencia ao painel de advancd filters
    const advancedFiltersPanelRef = useRef<OverlayPanel>(null);
    const gridRef = useRef<DataTable>(null);

    const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

    // Reference do componente de RefreshItems Select Fields
    const refreshItemsSelectFieldsRef =
      useRef<IRefreshSatItemSelectFieldsRef>(null);

    // Reference do componente de RefreshItems
    const refreshItemsSelectItemsRef =
      useRef<IRefreshSatItemSelectItemsRef>(null);

    // Reference do componente de AddItems
    const addItemsRef = useRef<IAddSatItemRef>(null);

    // Reference do componente de Resume/Total Details
    const totalDetailsRef = useRef<ITotalDetailsRef>(null);

    // Referencia ao componente (se esta montado)
    const isMounted = useRef(false);

    // Linhas expandidas da grid
    const [expandedRows, setExpandedRows] = useState<IListSatItem[]>([]);

    // Estado de response do refresh de fields selecionados
    const [refreshSelectedFieldsResponse, setRefreshSelectedFieldsResponse] =
      useState<DifferenceToRefreshSatItemsResponse[]>([]);

    // Estado de fields que deverao ser atualizados em cada item
    const [itemsFieldsToUpdate, setItemsFieldsToUpdate] =
      useState<IRefreshSatItemOptions>();

    const [deleteSatItemMutationResponse, setDeleteSatItemMutationResponse] =
      useState<IMutationResponse[]>([]);

    const [addSatItemMutationResponse, setAddSatItemMutationResponse] =
      useState<IMutationResponse[]>([]);

    // Busca permissoes do usuario para a entity
    const { userFields } = useMemo(() => {
      return getUserFieldsAndPermissionsByEntity(
        roles.rolesUser,
        satItemsRoles.idEntity,
      );
    }, [roles.rolesUser]);

    // Busca permissoes do usuario para a entity de Client Quotation
    const userPermissionsClientQuotation = useMemo(() => {
      const { userPermissions } = getUserFieldsAndPermissionsByEntity(
        roles.rolesUser,
        clientQuotationRoles.idEntity,
      );

      return userPermissions;
    }, [roles.rolesUser]);

    const fieldsPermissions: SatItemsFieldsPermissions = useMemo(() => {
      return {
        imageUrl: getFieldPermission(
          satItemsRoles.fields.idFieldIdImage,
          userFields,
        ),
        idSupplier: getFieldPermission(
          satItemsRoles.fields.idFieldIdSatSupplier,
          userFields,
        ),
        stCode: getFieldPermission(
          satItemsRoles.fields.idFieldStCode,
          userFields,
        ),
        refCode: getFieldPermission(
          satItemsRoles.fields.idFieldRefCode,
          userFields,
        ),
        name: getFieldPermission(satItemsRoles.fields.idFieldName, userFields),
        description: getFieldPermission(
          satItemsRoles.fields.idFieldDescription,
          userFields,
        ),
        namePt: getFieldPermission(
          satItemsRoles.fields.idFieldNamePT,
          userFields,
        ),
        detailedDescriptionPt: getFieldPermission(
          satItemsRoles.fields.idFieldDetailedDescriptionPt,
          userFields,
        ),
        extraInfoEn: getFieldPermission(
          satItemsRoles.fields.extraInfoEn,
          userFields,
        ),
        extraInfoPt: getFieldPermission(
          satItemsRoles.fields.extraInfoEn,
          userFields,
        ),
        descriptionPt: getFieldPermission(
          satItemsRoles.fields.idFieldDescriptionPt,
          userFields,
        ),
        model: getFieldPermission(
          satItemsRoles.fields.idFieldModel,
          userFields,
        ),
        sku: getFieldPermission(satItemsRoles.fields.idFieldSku, userFields),
        colour: getFieldPermission(
          satItemsRoles.fields.idFieldColour,
          userFields,
        ),
        colourPt: getFieldPermission(
          satItemsRoles.fields.idFieldColorPt,
          userFields,
        ),
        colourImageUrl: getFieldPermission(
          satItemsRoles.fields.idFieldIdColourImage,
          userFields,
        ),
        materialComposition: getFieldPermission(
          satItemsRoles.fields.idFieldMaterialComposition,
          userFields,
        ),
        materialCompositionPt: getFieldPermission(
          satItemsRoles.fields.idFieldMaterialCompositionPt,
          userFields,
        ),
        dimensions: getFieldPermission(
          satItemsRoles.fields.idFieldDimensions,
          userFields,
        ),
        netWeight: getFieldPermission(
          satItemsRoles.fields.idFieldNetWeight,
          userFields,
        ),
        packageDescription: getFieldPermission(
          satItemsRoles.fields.idFieldPackageDescription,
          userFields,
        ),
        packageNotes: getFieldPermission(
          satItemsRoles.fields.idFieldPackageNotes,
          userFields,
        ),
        sellingPackagePcQuantity: getFieldPermission(
          satItemsRoles.fields.idFieldSellingPackagePCQuantity,
          userFields,
        ),
        sellingPackageDimensions: getFieldPermission(
          satItemsRoles.fields.idFieldSellingPackageDimensions,
          userFields,
        ),
        sellingPackageGrossWeight: getFieldPermission(
          satItemsRoles.fields.idFieldSellingPackageGrossWeight,
          userFields,
        ),
        unitPerContainer: getFieldPermission(
          satItemsRoles.fields.idFieldUnitPerContainer,
          userFields,
        ),
        packageEan13: getFieldPermission(
          satItemsRoles.fields.idFieldPackageEan13,
          userFields,
        ),
        innerdun14: getFieldPermission(
          satItemsRoles.fields.idFieldInnerdun14,
          userFields,
        ),
        dun14: getFieldPermission(
          satItemsRoles.fields.idFieldDun14,
          userFields,
        ),
        brand: getFieldPermission(
          satItemsRoles.fields.idFieldBrand,
          userFields,
        ),
        productLine: getFieldPermission(
          satItemsRoles.fields.idFieldProductLine,
          userFields,
        ),
        artworkEn: getFieldPermission(
          satItemsRoles.fields.idFieldArtworkEn,
          userFields,
        ),
        artworkPt: getFieldPermission(
          satItemsRoles.fields.idFieldArtworkPt,
          userFields,
        ),
        techniqueEn: getFieldPermission(
          satItemsRoles.fields.idFieldTechniqueEn,
          userFields,
        ),
        techniquePt: getFieldPermission(
          satItemsRoles.fields.idFieldTechniquePt,
          userFields,
        ),
        typeOfMaterial: getFieldPermission(
          satItemsRoles.fields.idFieldTypeOfMaterial,
          userFields,
        ),
        lining: getFieldPermission(
          satItemsRoles.fields.idFieldLining,
          userFields,
        ),
        liningComposition: getFieldPermission(
          satItemsRoles.fields.idFieldLiningComposition,
          userFields,
        ),
        liningDescription: getFieldPermission(
          satItemsRoles.fields.idFieldLiningDescription,
          userFields,
        ),
        typePackageDescription: getFieldPermission(
          satItemsRoles.fields.idFieldTypePackageDescription,
          userFields,
        ),
        quantity: getFieldPermission(
          satItemsRoles.fields.idFieldQuantity,
          userFields,
        ),
        unitDescription: getFieldPermission(
          satItemsRoles.fields.idFieldUnit,
          userFields,
        ),
        remainQuantity: getFieldPermission(
          satItemsRoles.fields.idFieldRemainQuantity,
          userFields,
        ),
        moq: getFieldPermission(satItemsRoles.fields.idFieldMoq, userFields),
        m2Pc: getFieldPermission(satItemsRoles.fields.idFieldM2Pc, userFields),
        totalMasterM2: getFieldPermission(
          satItemsRoles.fields.idFieldTotalMasterM2,
          userFields,
        ),
        totalSqmCi: getFieldPermission(
          satItemsRoles.fields.idFieldTotalSqmCi,
          userFields,
        ),
        remainSqm: getFieldPermission(
          satItemsRoles.fields.idFieldRemainSqm,
          userFields,
        ),
        cotPriceSqm: getFieldPermission(
          satItemsRoles.fields.idFieldCotPriceSqm,
          userFields,
        ),
        sunPriceSqm: getFieldPermission(
          satItemsRoles.fields.idFieldSunPriceSqm,
          userFields,
        ),
        quantityContainer: getFieldPermission(
          satItemsRoles.fields.idFieldQuantityContainer,
          userFields,
        ),
        piecesContainer: getFieldPermission(
          satItemsRoles.fields.idFieldPiecesContainer,
          userFields,
        ),
        masterNetWeight: getFieldPermission(
          satItemsRoles.fields.idFieldMasterNetWeight,
          userFields,
        ),
        masterNetWeightTotal: getFieldPermission(
          satItemsRoles.fields.idFieldMasterNetWeightTotal,
          userFields,
        ),
        grossWeight: getFieldPermission(
          satItemsRoles.fields.idFieldGrossWeight,
          userFields,
        ),
        grossWeightTotal: getFieldPermission(
          satItemsRoles.fields.idFieldGrossWeightTotal,
          userFields,
        ),
        length: getFieldPermission(
          satItemsRoles.fields.idFieldLength,
          userFields,
        ),
        width: getFieldPermission(
          satItemsRoles.fields.idFieldWidth,
          userFields,
        ),
        height: getFieldPermission(
          satItemsRoles.fields.idFieldHeight,
          userFields,
        ),
        cbm: getFieldPermission(satItemsRoles.fields.idFieldCbm, userFields),
        cbmTotal: getFieldPermission(
          satItemsRoles.fields.idFieldCbmTotal,
          userFields,
        ),
        purchaseIncotermDescription: getFieldPermission(
          satItemsRoles.fields.idFieldPurchaseIncotermDescription,
          userFields,
        ),
        targetCot: getFieldPermission(
          satItemsRoles.fields.idFieldTargetCot,
          userFields,
        ),
        cotPrice: getFieldPermission(
          satItemsRoles.fields.idFieldCotPrice,
          userFields,
        ),
        remainTotalCot: getFieldPermission(
          satItemsRoles.fields.idFieldRemainTotalCot,
          userFields,
        ),
        sellingIncotermDescription: getFieldPermission(
          satItemsRoles.fields.idFieldSellingIncotermDescription,
          userFields,
        ),
        targetSun: getFieldPermission(
          satItemsRoles.fields.idFieldTargetSun,
          userFields,
        ),
        sunPrice: getFieldPermission(
          satItemsRoles.fields.idFieldSunPrice,
          userFields,
        ),
        totalSunCi: getFieldPermission(
          satItemsRoles.fields.idFieldTotalSunCi,
          userFields,
        ),
        remainTotalSun: getFieldPermission(
          satItemsRoles.fields.idFieldRemainTotalSun,
          userFields,
        ),
        grossProfit: getFieldPermission(
          satItemsRoles.fields.idFieldGrossProfit,
          userFields,
        ),
        grossMargin: getFieldPermission(
          satItemsRoles.fields.idFieldGrossMargin,
          userFields,
        ),
        hsCode: getFieldPermission(
          satItemsRoles.fields.idFieldHsCode,
          userFields,
        ),
        ncm: getFieldPermission(satItemsRoles.fields.idFieldNcm, userFields),
        ncmDestaque: getFieldPermission(
          satItemsRoles.fields.idFieldNcmDestaque,
          userFields,
        ),
        foreignTradeReview: getFieldPermission(
          satItemsRoles.fields.idFieldForeignTradeReview,
          userFields,
        ),
        generalLi: getFieldPermission(
          satItemsRoles.fields.idFieldGeneralLI,
          userFields,
        ),
        ncmIi: getFieldPermission(
          satItemsRoles.fields.idFieldNcmIi,
          userFields,
        ),
        ncmIpi: getFieldPermission(
          satItemsRoles.fields.idFieldNcmIpi,
          userFields,
        ),
        ncmPis: getFieldPermission(
          satItemsRoles.fields.idFieldNcmPis,
          userFields,
        ),
        ncmCofins: getFieldPermission(
          satItemsRoles.fields.idFieldNcmCofins,
          userFields,
        ),
        ncmDumping: getFieldPermission(
          satItemsRoles.fields.idFieldNcmDumping,
          userFields,
        ),
        containerNumber: getFieldPermission(
          satItemsRoles.fields.idFieldContainerNumber,
          userFields,
        ),
        sample: getFieldPermission(
          satItemsRoles.fields.idFieldSample,
          userFields,
        ),
        sampleLeadTime: getFieldPermission(
          satItemsRoles.fields.idFieldSampleLeadTime,
          userFields,
        ),
        samplesApproved: getFieldPermission(
          satItemsRoles.fields.idFieldSampleLeadTime,
          userFields,
        ),
        sampleNotes: getFieldPermission(
          satItemsRoles.fields.idFieldSampleNotes,
          userFields,
        ),
        licensed: getFieldPermission(
          satItemsRoles.fields.idFieldLicensed,
          userFields,
        ),
        licensorName: getFieldPermission(
          satItemsRoles.fields.idFieldLicensorName,
          userFields,
        ),
        royalties: getFieldPermission(
          satItemsRoles.fields.idFieldRoyalties,
          userFields,
        ),
        amountCot: getFieldPermission(
          satItemsRoles.fields.idFieldAmountCot,
          userFields,
        ),
        quantityShipped: getFieldPermission(
          satItemsRoles.fields.idFieldQuantityShipped,
          userFields,
        ),
        priceFob: getFieldPermission(
          satItemsRoles.fields.idFieldPriceFob,
          userFields,
        ),
        amount: getFieldPermission(
          satItemsRoles.fields.idFieldAmount,
          userFields,
        ),
        notes: getFieldPermission(
          satItemsRoles.fields.idFieldNotes,
          userFields,
        ),
        estimateProfit: getFieldPermission(
          satItemsRoles.fields.idFieldEstimateProfit,
          userFields,
        ),
        estimateMargin: getFieldPermission(
          satItemsRoles.fields.idFieldEstimateMargin,
          userFields,
        ),
        qtyOfCtnr: getFieldPermission(
          satItemsRoles.fields.idFieldQtyOfCtnr,
          userFields,
        ),
        package: getFieldPermission(
          satItemsRoles.fields.idFieldIdPackage,
          userFields,
        ),
        totalMasterM2Shipped: getFieldPermission(
          satItemsRoles.fields.idFieldTotalMasterM2Shipped,
          userFields,
        ),
        marketSurvey: getFieldPermission(
          satItemsRoles.fields.idFieldMarketSurvey,
          userFields,
        ),
        referenceSizes: {
          imageColorUrl: getFieldPermission(
            satItemsRoles.fields.idFieldSatItemReferencesAttachment,
            userFields,
          ),
          colourEn: getFieldPermission(
            satItemsRoles.fields.idFieldSatItemReferencesColourEn,
            userFields,
          ),
          colourPt: getFieldPermission(
            satItemsRoles.fields.idFieldSatItemReferencesColourPt,
            userFields,
          ),
          gender: getFieldPermission(
            satItemsRoles.fields.idFieldSatItemReferencesGenderEn,
            userFields,
          ),
          sizeGroup: getFieldPermission(
            satItemsRoles.fields.idFieldSatItemReferencesSizeGroupEn,
            userFields,
          ),
          totalPiCollor: getFieldPermission(
            satItemsRoles.fields.idFieldSatItemReferencesTotalPiColor,
            userFields,
          ),
          totalCiCollor: getFieldPermission(
            satItemsRoles.fields.idFieldSatItemReferencesTotalCiColor,
            userFields,
          ),
          remainCollorVar: getFieldPermission(
            satItemsRoles.fields.idFieldSatItemReferencesRemailColorVar,
            userFields,
          ),
          referenceSizeBarcodes: {
            size: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeSize,
              userFields,
            ),
            reference: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeReference,
              userFields,
            ),
            unit: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeUnit,
              userFields,
            ),
            sellingPackage: getFieldPermission(
              satItemsRoles.fields
                .idFieldSatItemReferenceSizeBarcodeSellingPackage,
              userFields,
            ),
            unitInnerBox: getFieldPermission(
              satItemsRoles.fields
                .idFieldSatItemReferenceSizeBarcodeUnitInnerBox,
              userFields,
            ),
            innerBox: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeInnerBox,
              userFields,
            ),
            unitMasterBox: getFieldPermission(
              satItemsRoles.fields
                .idFieldSatItemReferenceSizeBarcodeUnitMasterBox,
              userFields,
            ),
            masterBox: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeMasterBox,
              userFields,
            ),
            cotPiSize: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeCotPiSize,
              userFields,
            ),
            sunPiSize: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeSunPiSize,
              userFields,
            ),
            qtyCiSize: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeQtyCiSize,
              userFields,
            ),
            remainQuantityPiShipment: getFieldPermission(
              satItemsRoles.fields
                .idFieldSatItemReferenceSizeBarcodeRemainQtySize,
              userFields,
            ),
            cotCiSize: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeCotCiSize,
              userFields,
            ),
            sunCiSize: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeSunCiSize,
              userFields,
            ),
            remainTotCotSize: getFieldPermission(
              satItemsRoles.fields
                .idFieldSatItemReferenceSizeBarcodeRemainTotCotSize,
              userFields,
            ),
            remainTotSunSize: getFieldPermission(
              satItemsRoles.fields
                .idFieldSatItemReferenceSizeBarcodeRemainTotSunSize,
              userFields,
            ),
            sizeRange: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeSizeRange,
              userFields,
            ),
            qtyPiSize: getFieldPermission(
              satItemsRoles.fields.idFieldSatItemReferenceSizeBarcodeQtyPiSize,
              userFields,
            ),
          },
        },
        quantityInner: getFieldPermission(
          satItemsRoles.fields.idFieldSatItemQuantityInner,
          userFields,
        ),
        stickerDimensionsPt: getFieldPermission(
          satItemsRoles.fields.idFieldStickerDimensionsPt,
          userFields,
        ),
        centimeterDimensions: getFieldPermission(
          satItemsRoles.fields.idFieldCentimeterDimensions,
          userFields,
        ),
        innerBox: getFieldPermission(
          satItemsRoles.fields.idFieldInnerBox,
          userFields,
        ),
        totalCotCi: getFieldPermission(
          satItemsRoles.fields.idFieldTotalCotCi,
          userFields,
        ),
        nve: getFieldPermission(satItemsRoles.fields.idFieldNve, userFields),
      };
    }, [userFields]);

    const permissions = useMemo(() => {
      return {
        userCanAddSatItem: userHasPermission(
          satsRoles.permissions.idPermissionAddSatItem,
          roleEntityPermissions,
        ),
        userCanRefreshSatItems: userHasPermission(
          satsRoles.permissions.idPermissionRefreshSatItems,
          roleEntityPermissions,
        ),
        userCanCreateClientQuotation: userHasPermission(
          clientQuotationRoles.permissions.idPermissionCreateClientQuotation,
          userPermissionsClientQuotation,
        ),
        userCanEditSatItem: userHasPermission(
          satsRoles.permissions.idPermissionEditSatItems,
          roleEntityPermissions,
        ),
      };
    }, [roleEntityPermissions, userPermissionsClientQuotation]);

    const userCanSeeFashionLine = useMemo(() => {
      const referenceSizeFields: any[] = [];

      Object.keys(fieldsPermissions.referenceSizes).forEach(key => {
        if (
          isInstanceOfFieldPermissionResponse(
            fieldsPermissions.referenceSizes[
              key as keyof SatItemsReferenceSizesFieldsPermissions
            ],
          )
        ) {
          referenceSizeFields.push(
            fieldsPermissions.referenceSizes[
              key as keyof SatItemsReferenceSizesFieldsPermissions
            ],
          );
        }
      });

      const userCanSeeSomeReferenceSizeInfo = referenceSizeFields.some(
        obj => obj.view,
      );

      const userCanSeeSomeReferenceSizeBarcodeInfo = Object.keys(
        fieldsPermissions.referenceSizes.referenceSizeBarcodes,
      ).some(
        key =>
          fieldsPermissions.referenceSizes.referenceSizeBarcodes[
            key as keyof SatItemsReferenceSizeBarcodesFieldsPermissions
          ].view,
      );

      return (
        userCanSeeSomeReferenceSizeInfo ||
        userCanSeeSomeReferenceSizeBarcodeInfo
      );
    }, [fieldsPermissions.referenceSizes]);

    // Colunas da grid
    const gridObjects = useMemo(() => {
      const columnList: ColumnData[] = [];

      if (fieldsPermissions.imageUrl.view) {
        columnList.push(gridColumnsData.imageUrl);
      }

      if (fieldsPermissions.idSupplier.view) {
        columnList.push({
          ...gridColumnsData.idSupplier,
          type: AdvancedFiltersFieldType.NUMBER,
        });
      }

      if (fieldsPermissions.stCode.view) {
        columnList.push(gridColumnsData.stCode);
      }

      if (fieldsPermissions.refCode.view) {
        columnList.push(gridColumnsData.refCode);
      }

      if (fieldsPermissions.name.view) {
        columnList.push(gridColumnsData.name);
      }

      if (fieldsPermissions.description.view) {
        columnList.push(gridColumnsData.description);
      }

      if (fieldsPermissions.namePt.view) {
        columnList.push(gridColumnsData.namePt);
      }

      if (fieldsPermissions.detailedDescriptionPt.view) {
        columnList.push(gridColumnsData.detailedDescriptionPt);
      }

      if (fieldsPermissions.extraInfoEn.view) {
        columnList.push(gridColumnsData.extraInfoEn);
      }

      if (fieldsPermissions.extraInfoPt.view) {
        columnList.push(gridColumnsData.extraInfoPt);
      }

      if (fieldsPermissions.descriptionPt.view) {
        columnList.push(gridColumnsData.descriptionPt);
      }

      if (fieldsPermissions.model.view) {
        columnList.push(gridColumnsData.model);
      }

      if (fieldsPermissions.sku.view) {
        columnList.push(gridColumnsData.sku);
      }

      if (fieldsPermissions.colour.view) {
        columnList.push(gridColumnsData.colour);
      }

      if (fieldsPermissions.colourPt.view) {
        columnList.push(gridColumnsData.colourPt);
      }

      if (fieldsPermissions.colourImageUrl.view) {
        columnList.push(gridColumnsData.colourImageUrl);
      }

      if (fieldsPermissions.materialComposition.view) {
        columnList.push(gridColumnsData.materialComposition);
      }

      if (fieldsPermissions.materialCompositionPt.view) {
        columnList.push(gridColumnsData.materialCompositionPt);
      }

      if (fieldsPermissions.dimensions.view) {
        columnList.push(gridColumnsData.dimensions);
      }

      if (fieldsPermissions.netWeight.view) {
        columnList.push(gridColumnsData.netWeight);
      }

      if (fieldsPermissions.packageDescription.view) {
        columnList.push(gridColumnsData.packageDescription);
      }

      if (fieldsPermissions.packageNotes.view) {
        columnList.push(gridColumnsData.packageNotes);
      }

      if (fieldsPermissions.sellingPackagePcQuantity.view) {
        columnList.push(gridColumnsData.sellingPackagePcQuantity);
      }

      if (fieldsPermissions.sellingPackageDimensions.view) {
        columnList.push(gridColumnsData.sellingPackageDimensions);
      }

      if (fieldsPermissions.sellingPackageGrossWeight.view) {
        columnList.push(gridColumnsData.sellingPackageGrossWeight);
      }

      if (fieldsPermissions.quantityInner.view) {
        columnList.push(gridColumnsData.quantityInner);
      }

      if (fieldsPermissions.unitPerContainer.view) {
        columnList.push(gridColumnsData.unitPerContainer);
      }

      if (fieldsPermissions.packageEan13.view) {
        columnList.push(gridColumnsData.packageEan13);
      }

      if (fieldsPermissions.innerdun14.view) {
        columnList.push(gridColumnsData.innerdun14);
      }

      if (fieldsPermissions.dun14.view) {
        columnList.push(gridColumnsData.dun14);
      }

      if (fieldsPermissions.brand.view) {
        columnList.push(gridColumnsData.brand);
      }

      if (fieldsPermissions.productLine.view) {
        columnList.push(gridColumnsData.productLine);
      }

      if (fieldsPermissions.artworkEn.view) {
        columnList.push(gridColumnsData.artworkEn);
      }

      if (fieldsPermissions.artworkPt.view) {
        columnList.push(gridColumnsData.artworkPt);
      }

      if (fieldsPermissions.techniqueEn.view) {
        columnList.push(gridColumnsData.techniqueEn);
      }

      if (fieldsPermissions.techniquePt.view) {
        columnList.push(gridColumnsData.techniquePt);
      }

      if (fieldsPermissions.typeOfMaterial.view) {
        columnList.push(gridColumnsData.typeOfMaterial);
      }

      if (fieldsPermissions.lining.view) {
        columnList.push(gridColumnsData.lining);
      }

      if (fieldsPermissions.liningComposition.view) {
        columnList.push(gridColumnsData.liningComposition);
      }

      if (fieldsPermissions.liningDescription.view) {
        columnList.push(gridColumnsData.liningDescription);
      }

      if (fieldsPermissions.typePackageDescription.view) {
        columnList.push(gridColumnsData.typePackageDescription);
      }

      if (fieldsPermissions.quantity.view) {
        columnList.push(gridColumnsData.quantity);
      }

      if (fieldsPermissions.unitDescription.view) {
        columnList.push(gridColumnsData.unitDescription);
      }

      if (fieldsPermissions.remainQuantity.view) {
        columnList.push(gridColumnsData.remainQuantity);
      }

      if (fieldsPermissions.moq.view) {
        columnList.push(gridColumnsData.moq);
      }

      if (fieldsPermissions.m2Pc.view) {
        columnList.push(gridColumnsData.m2Pc);
      }

      if (fieldsPermissions.totalMasterM2.view) {
        columnList.push(gridColumnsData.totalMasterM2);
      }

      if (fieldsPermissions.totalSqmCi.view) {
        columnList.push(gridColumnsData.totalSqmCi);
      }

      if (fieldsPermissions.remainSqm.view) {
        columnList.push(gridColumnsData.remainSqm);
      }

      if (fieldsPermissions.cotPriceSqm.view) {
        columnList.push(gridColumnsData.cotPriceSqm);
      }

      if (fieldsPermissions.sunPriceSqm.view) {
        columnList.push(gridColumnsData.sunPriceSqm);
      }

      if (fieldsPermissions.quantityContainer.view) {
        columnList.push(gridColumnsData.quantityContainer);
      }

      if (fieldsPermissions.piecesContainer.view) {
        columnList.push(gridColumnsData.piecesContainer);
      }

      if (fieldsPermissions.masterNetWeight.view) {
        columnList.push(gridColumnsData.masterNetWeight);
      }

      if (fieldsPermissions.masterNetWeightTotal.view) {
        columnList.push(gridColumnsData.masterNetWeightTotal);
      }

      if (fieldsPermissions.grossWeight.view) {
        columnList.push(gridColumnsData.grossWeight);
      }

      if (fieldsPermissions.grossWeightTotal.view) {
        columnList.push(gridColumnsData.grossWeightTotal);
      }

      if (fieldsPermissions.length.view) {
        columnList.push(gridColumnsData.length);
      }

      if (fieldsPermissions.width.view) {
        columnList.push(gridColumnsData.width);
      }

      if (fieldsPermissions.height.view) {
        columnList.push(gridColumnsData.height);
      }

      if (fieldsPermissions.cbm.view) {
        columnList.push(gridColumnsData.cbm);
      }

      if (fieldsPermissions.cbmTotal.view) {
        columnList.push(gridColumnsData.cbmTotal);
      }

      if (fieldsPermissions.purchaseIncotermDescription.view) {
        columnList.push(gridColumnsData.purchaseIncotermDescription);
      }

      if (fieldsPermissions.targetCot.view) {
        columnList.push(gridColumnsData.targetCot);
      }

      if (fieldsPermissions.cotPrice.view) {
        columnList.push(gridColumnsData.cotPrice);
      }

      if (fieldsPermissions.remainTotalCot.view) {
        columnList.push(gridColumnsData.remainTotalCot);
      }

      if (fieldsPermissions.sellingIncotermDescription.view) {
        columnList.push(gridColumnsData.sellingIncotermDescription);
      }

      if (fieldsPermissions.targetSun.view) {
        columnList.push(gridColumnsData.targetSun);
      }

      if (fieldsPermissions.sunPrice.view) {
        columnList.push(gridColumnsData.sunPrice);
      }

      if (fieldsPermissions.totalSunCi.view) {
        columnList.push(gridColumnsData.totalSunCi);
      }

      if (fieldsPermissions.remainTotalSun.view) {
        columnList.push(gridColumnsData.remainTotalSun);
      }

      if (fieldsPermissions.grossProfit.view) {
        columnList.push(gridColumnsData.grossProfit);
      }

      if (fieldsPermissions.grossMargin.view) {
        columnList.push(gridColumnsData.grossMargin);
      }

      if (fieldsPermissions.hsCode.view) {
        columnList.push(gridColumnsData.hsCode);
      }

      if (fieldsPermissions.ncm.view) {
        columnList.push(gridColumnsData.ncm);
      }

      if (fieldsPermissions.ncmDestaque.view) {
        columnList.push(gridColumnsData.ncmDestaque);
      }

      if (fieldsPermissions.foreignTradeReview.view) {
        columnList.push(gridColumnsData.foreignTradeReview);
      }

      if (fieldsPermissions.generalLi.view) {
        columnList.push(gridColumnsData.generalLi);
      }

      if (fieldsPermissions.ncmIi.view) {
        columnList.push(gridColumnsData.ncmIi);
      }

      if (fieldsPermissions.ncmIpi.view) {
        columnList.push(gridColumnsData.ncmIpi);
      }

      if (fieldsPermissions.ncmPis.view) {
        columnList.push(gridColumnsData.ncmPis);
      }

      if (fieldsPermissions.ncmCofins.view) {
        columnList.push(gridColumnsData.ncmCofins);
      }

      if (fieldsPermissions.ncmDumping.view) {
        columnList.push(gridColumnsData.ncmDumping);
      }

      if (fieldsPermissions.containerNumber.view) {
        columnList.push(gridColumnsData.containerNumber);
      }

      if (fieldsPermissions.sample.view) {
        columnList.push(gridColumnsData.sample);
      }

      if (fieldsPermissions.sampleLeadTime.view) {
        columnList.push(gridColumnsData.sampleLeadTime);
      }

      if (fieldsPermissions.samplesApproved.view) {
        columnList.push(gridColumnsData.samplesApproved);
      }

      if (fieldsPermissions.sampleNotes.view) {
        columnList.push(gridColumnsData.sampleNotes);
      }

      if (fieldsPermissions.licensed.view) {
        columnList.push(gridColumnsData.licensed);
      }

      if (fieldsPermissions.licensorName.view) {
        columnList.push(gridColumnsData.licensorName);
      }

      if (fieldsPermissions.royalties.view) {
        columnList.push(gridColumnsData.royalties);
      }

      if (fieldsPermissions.amountCot.view) {
        columnList.push(gridColumnsData.amountCot);
      }

      if (fieldsPermissions.quantityShipped.view) {
        columnList.push(gridColumnsData.quantityShipped);
      }

      if (fieldsPermissions.priceFob.view) {
        columnList.push(gridColumnsData.priceFob);
      }

      if (fieldsPermissions.amount.view) {
        columnList.push(gridColumnsData.amount);
      }

      if (fieldsPermissions.notes.view) {
        columnList.push(gridColumnsData.notes);
      }

      if (fieldsPermissions.estimateProfit.view) {
        columnList.push(gridColumnsData.estimateProfit);
      }

      if (fieldsPermissions.estimateMargin.view) {
        columnList.push(gridColumnsData.estimateMargin);
      }

      if (fieldsPermissions.qtyOfCtnr.view) {
        columnList.push(gridColumnsData.qtyOfCtnr);
      }

      if (fieldsPermissions.stickerDimensionsPt.view) {
        columnList.push(gridColumnsData.stickerDimensionsPt);
      }

      if (fieldsPermissions.centimeterDimensions.view) {
        columnList.push(gridColumnsData.centimeterDimensions);
      }

      if (fieldsPermissions.innerBox.view) {
        columnList.push(gridColumnsData.innerBox);
      }

      if (fieldsPermissions.totalCotCi.view) {
        columnList.push(gridColumnsData.totalCotCi);
      }

      if (fieldsPermissions.nve.view) {
        columnList.push(gridColumnsData.nve);
      }

      return {
        columnList,
      };
    }, [
      fieldsPermissions.amount.view,
      fieldsPermissions.amountCot.view,
      fieldsPermissions.artworkEn.view,
      fieldsPermissions.artworkPt.view,
      fieldsPermissions.brand.view,
      fieldsPermissions.cbm.view,
      fieldsPermissions.cbmTotal.view,
      fieldsPermissions.colour.view,
      fieldsPermissions.colourImageUrl.view,
      fieldsPermissions.colourPt.view,
      fieldsPermissions.containerNumber.view,
      fieldsPermissions.cotPrice.view,
      fieldsPermissions.cotPriceSqm.view,
      fieldsPermissions.description.view,
      fieldsPermissions.descriptionPt.view,
      fieldsPermissions.dimensions.view,
      fieldsPermissions.dun14.view,
      fieldsPermissions.estimateMargin.view,
      fieldsPermissions.estimateProfit.view,
      fieldsPermissions.foreignTradeReview.view,
      fieldsPermissions.generalLi.view,
      fieldsPermissions.grossMargin.view,
      fieldsPermissions.grossProfit.view,
      fieldsPermissions.grossWeight.view,
      fieldsPermissions.grossWeightTotal.view,
      fieldsPermissions.height.view,
      fieldsPermissions.hsCode.view,
      fieldsPermissions.idSupplier.view,
      fieldsPermissions.imageUrl.view,
      fieldsPermissions.innerBox.view,
      fieldsPermissions.innerdun14.view,
      fieldsPermissions.length.view,
      fieldsPermissions.licensed.view,
      fieldsPermissions.licensorName.view,
      fieldsPermissions.lining.view,
      fieldsPermissions.liningComposition.view,
      fieldsPermissions.liningDescription.view,
      fieldsPermissions.m2Pc.view,
      fieldsPermissions.masterNetWeight.view,
      fieldsPermissions.masterNetWeightTotal.view,
      fieldsPermissions.materialComposition.view,
      fieldsPermissions.materialCompositionPt.view,
      fieldsPermissions.model.view,
      fieldsPermissions.moq.view,
      fieldsPermissions.name.view,
      fieldsPermissions.detailedDescriptionPt.view,
      fieldsPermissions.extraInfoEn.view,
      fieldsPermissions.extraInfoPt.view,
      fieldsPermissions.namePt.view,
      fieldsPermissions.ncm.view,
      fieldsPermissions.ncmCofins.view,
      fieldsPermissions.ncmDestaque.view,
      fieldsPermissions.ncmDumping.view,
      fieldsPermissions.ncmIi.view,
      fieldsPermissions.ncmIpi.view,
      fieldsPermissions.ncmPis.view,
      fieldsPermissions.netWeight.view,
      fieldsPermissions.notes.view,
      fieldsPermissions.packageDescription.view,
      fieldsPermissions.packageEan13.view,
      fieldsPermissions.packageNotes.view,
      fieldsPermissions.piecesContainer.view,
      fieldsPermissions.priceFob.view,
      fieldsPermissions.productLine.view,
      fieldsPermissions.purchaseIncotermDescription.view,
      fieldsPermissions.qtyOfCtnr.view,
      fieldsPermissions.quantity.view,
      fieldsPermissions.quantityContainer.view,
      fieldsPermissions.quantityInner.view,
      fieldsPermissions.quantityShipped.view,
      fieldsPermissions.refCode.view,
      fieldsPermissions.remainQuantity.view,
      fieldsPermissions.remainSqm.view,
      fieldsPermissions.remainTotalCot.view,
      fieldsPermissions.remainTotalSun.view,
      fieldsPermissions.royalties.view,
      fieldsPermissions.sample.view,
      fieldsPermissions.sampleLeadTime.view,
      fieldsPermissions.sampleNotes.view,
      fieldsPermissions.samplesApproved.view,
      fieldsPermissions.sellingIncotermDescription.view,
      fieldsPermissions.sellingPackageDimensions.view,
      fieldsPermissions.sellingPackageGrossWeight.view,
      fieldsPermissions.sellingPackagePcQuantity.view,
      fieldsPermissions.sku.view,
      fieldsPermissions.stCode.view,
      fieldsPermissions.stickerDimensionsPt.view,
      fieldsPermissions.centimeterDimensions.view,
      fieldsPermissions.sunPrice.view,
      fieldsPermissions.sunPriceSqm.view,
      fieldsPermissions.targetCot.view,
      fieldsPermissions.targetSun.view,
      fieldsPermissions.techniqueEn.view,
      fieldsPermissions.techniquePt.view,
      fieldsPermissions.totalCotCi.view,
      fieldsPermissions.totalMasterM2.view,
      fieldsPermissions.totalSqmCi.view,
      fieldsPermissions.totalSunCi.view,
      fieldsPermissions.typeOfMaterial.view,
      fieldsPermissions.typePackageDescription.view,
      fieldsPermissions.unitDescription.view,
      fieldsPermissions.unitPerContainer.view,
      fieldsPermissions.width.view,
      fieldsPermissions.nve.view,
    ]);

    // Colunas selecionadas
    const [selectedColumns, setSelectedColumns] =
      useState<MultiSelectGroupData>({
        columns: gridObjects.columnList,
        treeSelectedData: {
          showFields: {
            checked: false,
            partialChecked: true,
          },
        },
      });

    const [satItemsState, satItemsDispatch] = useReducer(
      satItemsReducer,
      satItemsReducerInitialState,
    );

    const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

    const localStorageLazyParams = localStorageLazyParamsData
      ? JSON.parse(localStorageLazyParamsData)
      : undefined;

    const [globalFilter, setGlobalFilter] = useState(
      localStorageLazyParams?.globalFilter || '',
    );

    const initialLazyParams: ISatItemsLazyParams = {
      ...pagination.initialLazyParams,
      sortField: 'order',
    };

    // Parametros de paginacao/backend
    const [lazyParams, setLazyParams] = useState<ISatItemsLazyParams>(
      localStorageLazyParams || initialLazyParams,
    );

    const saveLazyParams = useCallback((newLazyParams: ISatItemsLazyParams) => {
      const lazyParamsToSave = {
        ...newLazyParams,
        ...pagination.initialLazyParams,
      };
      localStorage.setItem(lazyParamsName, JSON.stringify(lazyParamsToSave));
      updateLocalStorageInDb(lazyParamsName, lazyParamsToSave);
    }, []);

    const changeLazyParams = useCallback(
      (newLazyParams: ISatItemsLazyParams) => {
        const userHaveSomethingNotSaved =
          satItemsState.satItemsToUpdate.length ||
          satItemsState.referencesToUpdate.length ||
          satItemsState.referenceSizeBarcodesToUpdate.length;

        if (userHaveSomethingNotSaved) {
          confirmDialog({
            message: 'You will lost any items information not saved',
            header: 'Are you sure you want to proceed?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => setLazyParams(newLazyParams),
          });
        } else {
          setLazyParams(newLazyParams);
        }
        saveLazyParams(newLazyParams);
      },
      [
        satItemsState.referenceSizeBarcodesToUpdate.length,
        satItemsState.referencesToUpdate.length,
        satItemsState.satItemsToUpdate.length,
        saveLazyParams,
      ],
    );

    // Query para listar sat suppliers
    const listSatItemsBySatIdQuery = gql`
    query listSatItemsBySatId($data: ListSatItemsBySatIdInput!) {
      listSatItemsBySatId(data: $data) {
        items
        data {
          idSatItem
          idProduct
          ${fieldsPermissions.totalCotCi.view ? 'totalCotCi' : ''}
          ${
            fieldsPermissions.imageUrl.view
              ? `idImage2 {
                  url
                }`
              : ''
          }
          ${
            fieldsPermissions.stickerDimensionsPt.view
              ? 'stickerDimensionsPt'
              : ''
          }
          ${
            fieldsPermissions.centimeterDimensions.view
              ? 'centimeterDimensions'
              : ''
          }
          ${fieldsPermissions.innerBox.view ? 'innerBox' : ''}
          ${fieldsPermissions.stCode.view ? 'stCode' : ''}
          ${
            fieldsPermissions.idSupplier.view
              ? `idSatSupplier
                idSatSupplier2 {
                    idSupplier
                  }`
              : ''
          }
          ${fieldsPermissions.name.view ? 'name' : ''}
          ${fieldsPermissions.namePt.view ? 'namePt' : ''}
          ${fieldsPermissions.model.view ? 'model' : ''}
          ${fieldsPermissions.sku.view ? 'sku' : ''}
          ${fieldsPermissions.description.view ? 'description' : ''}
          ${fieldsPermissions.colour.view ? 'colour' : ''}
          ${
            fieldsPermissions.colourImageUrl.view
              ? `idColourImage2 {
                  url
                }`
              : ''
          }
          ${
            fieldsPermissions.materialComposition.view
              ? 'materialComposition'
              : ''
          }
          ${fieldsPermissions.dimensions.view ? 'dimensions' : ''}
          ${fieldsPermissions.hsCode.view ? 'hsCode' : ''}
          ${fieldsPermissions.ncm.view ? 'ncm' : ''}
          ${fieldsPermissions.sunPrice.view ? 'sunPrice' : ''}
          ${fieldsPermissions.targetSun.view ? 'targetSun' : ''}
          ${fieldsPermissions.cotPrice.view ? 'cotPrice' : ''}
          ${fieldsPermissions.targetCot.view ? 'targetCot' : ''}
          ${fieldsPermissions.amountCot.view ? 'amountCot' : ''}
          ${fieldsPermissions.brand.view ? 'brand' : ''}
          ${fieldsPermissions.productLine.view ? 'productLine' : ''}
          ${fieldsPermissions.netWeight.view ? 'netWeight' : ''}
          ${
            fieldsPermissions.packageDescription.view
              ? 'packageDescription'
              : ''
          }
          ${
            fieldsPermissions.sellingPackagePcQuantity.view
              ? 'sellingPackagePcQuantity'
              : ''
          }
          ${
            fieldsPermissions.sellingPackageDimensions.view
              ? 'sellingPackageDimensions'
              : ''
          }
          ${
            fieldsPermissions.sellingPackageGrossWeight.view
              ? 'sellingPackageGrossWeight'
              : ''
          }
          ${fieldsPermissions.quantity.view ? 'quantity' : ''}
          ${fieldsPermissions.quantityShipped.view ? 'quantityShipped' : ''}
          ${fieldsPermissions.piecesContainer.view ? 'piecesContainer' : ''}
          ${
            fieldsPermissions.unitDescription.view
              ? `unit
                  unit2 {
                    description
                    }`
              : ''
          }
          ${fieldsPermissions.quantityContainer.view ? 'quantityContainer' : ''}
          ${fieldsPermissions.width.view ? 'width' : ''}
          ${fieldsPermissions.length.view ? 'length' : ''}
          ${fieldsPermissions.cbm.view ? 'cbm' : ''}
          ${fieldsPermissions.height.view ? 'height' : ''}
          ${fieldsPermissions.masterNetWeight.view ? 'masterNetWeight' : ''}
          ${
            fieldsPermissions.masterNetWeightTotal.view
              ? 'masterNetWeightTotal'
              : ''
          }
          ${fieldsPermissions.m2Pc.view ? 'm2Pc' : ''}
          ${fieldsPermissions.totalMasterM2.view ? 'totalMasterM2' : ''}
          ${fieldsPermissions.grossWeight.view ? 'grossWeight' : ''}
          ${fieldsPermissions.grossWeightTotal.view ? 'grossWeightTotal' : ''}
          ${fieldsPermissions.cbmTotal.view ? 'cbmTotal' : ''}
          ${fieldsPermissions.priceFob.view ? 'priceFob' : ''}
          ${fieldsPermissions.amount.view ? 'amount' : ''}
          ${fieldsPermissions.moq.view ? 'moq' : ''}
          ${fieldsPermissions.notes.view ? 'notes' : ''}
          ${fieldsPermissions.generalLi.view ? 'generalLi' : ''}
          ${fieldsPermissions.estimateProfit.view ? 'estimateProfit' : ''}
          ${fieldsPermissions.grossProfit.view ? 'grossProfit' : ''}
          ${fieldsPermissions.estimateMargin.view ? 'estimateMargin' : ''}
          ${fieldsPermissions.grossMargin.view ? 'grossMargin' : ''}
          ${
            fieldsPermissions.package.view
              ? `
              idPackage
              idPackage2 {
                  idProductPackage
                  masterQuantity
                }`
              : ''
          }
          ${
            fieldsPermissions.totalMasterM2Shipped.view
              ? 'totalMasterM2Shipped'
              : ''
          }
          ${fieldsPermissions.sample.view ? 'sample' : ''}
          ${fieldsPermissions.marketSurvey.view ? 'marketSurvey' : ''}
          ${fieldsPermissions.sampleNotes.view ? 'sampleNotes' : ''}
          ${fieldsPermissions.refCode.view ? 'refCode' : ''}
          ${
            fieldsPermissions.detailedDescriptionPt.view
              ? 'detailedDescriptionPt'
              : ''
          }
          ${fieldsPermissions.extraInfoEn.view ? 'extraInfoEn' : ''}
          ${fieldsPermissions.extraInfoEn.view ? 'extraInfoPt' : ''}
          ${fieldsPermissions.descriptionPt.view ? 'descriptionPt' : ''}
          ${fieldsPermissions.colourPt.view ? 'colourPt' : ''}
          ${fieldsPermissions.materialCompositionPt.view ? 'materialPt' : ''}
          ${fieldsPermissions.packageNotes.view ? 'packageNotes' : ''}
          ${fieldsPermissions.packageEan13.view ? 'packageEan13' : ''}
          ${fieldsPermissions.innerdun14.view ? 'innerdun14' : ''}
          ${fieldsPermissions.dun14.view ? 'dun14' : ''}
          ${fieldsPermissions.typeOfMaterial.view ? 'typeOfMaterial' : ''}
          ${fieldsPermissions.lining.view ? 'lining' : ''}
          ${fieldsPermissions.liningComposition.view ? 'liningComposition' : ''}
          ${fieldsPermissions.liningDescription.view ? 'liningDescription' : ''}
          ${fieldsPermissions.remainQuantity.view ? 'remainQuantity' : ''}
          ${fieldsPermissions.totalSqmCi.view ? 'totalSqmCi' : ''}
          ${fieldsPermissions.remainSqm.view ? 'remainSqm' : ''}
          ${fieldsPermissions.cotPriceSqm.view ? 'cotPriceSqm' : ''}
          ${fieldsPermissions.totalSunCi.view ? 'totalSunCi' : ''}
          ${fieldsPermissions.remainTotalCot.view ? 'remainTotalCot' : ''}
          ${fieldsPermissions.remainTotalSun.view ? 'remainTotalSun' : ''}
          ${fieldsPermissions.ncmDestaque.view ? 'ncmDestaque' : ''}
          ${
            fieldsPermissions.foreignTradeReview.view
              ? 'foreignTradeReview'
              : ''
          }
          ${fieldsPermissions.ncmIi.view ? 'ncmIi' : ''}
          ${fieldsPermissions.ncmIpi.view ? 'ncmIpi' : ''}
          ${fieldsPermissions.ncmPis.view ? 'ncmPis' : ''}
          ${fieldsPermissions.ncmCofins.view ? 'ncmCofins' : ''}
          ${fieldsPermissions.ncmDumping.view ? 'ncmDumping' : ''}
          ${fieldsPermissions.containerNumber.view ? 'containerNumber' : ''}
          ${fieldsPermissions.sample.view ? 'sample' : ''}
          ${fieldsPermissions.sampleLeadTime.view ? 'sampleLeadTime' : ''}
          ${fieldsPermissions.sampleNotes.view ? 'sampleNotes' : ''}
          ${fieldsPermissions.licensed.view ? 'licensed' : ''}
          ${fieldsPermissions.royalties.view ? 'royalties' : ''}
          ${fieldsPermissions.samplesApproved.view ? 'samplesApproved' : ''}
          ${fieldsPermissions.artworkPt.view ? 'artworkPt' : ''}
          ${fieldsPermissions.artworkEn.view ? 'artworkEn' : ''}
          ${fieldsPermissions.sunPriceSqm.view ? 'sunPriceSqm' : ''}
          ${fieldsPermissions.unitPerContainer.view ? 'unitPerContainer' : ''}
          ${
            fieldsPermissions.purchaseIncotermDescription.view
              ? `idPurchaseIncoterm2 {
                    description
                  }`
              : ''
          }
          ${
            fieldsPermissions.typePackageDescription.view
              ? `idTypePackage
                idTypePackage2 {
                      description
                    }`
              : ''
          }
          ${
            fieldsPermissions.sellingIncotermDescription.view
              ? `idSellingIncoterm2 {
                    description
                  }`
              : ''
          }
          ${
            fieldsPermissions.licensorName.view
              ? `idLicensor2 {
                    name
                  }`
              : ''
          }
          ${fieldsPermissions.techniqueEn.view ? 'techniqueEn' : ''}
          ${fieldsPermissions.techniquePt.view ? 'techniquePt' : ''}
          satItemReferences {
            idSatItemReference
            ${
              fieldsPermissions.referenceSizes.imageColorUrl.view
                ? `idAttachment2 {
                      url
                      name
                    }`
                : ''
            }
            ${fieldsPermissions.referenceSizes.colourEn.view ? 'colourEn' : ''}
            ${fieldsPermissions.referenceSizes.colourPt.view ? 'colourPt' : ''}
            ${
              fieldsPermissions.referenceSizes.gender.view
                ? `idGender2 {
                      description
                    }`
                : ''
            }
            ${
              fieldsPermissions.referenceSizes.sizeGroup.view
                ? `idSizeGroup2 {
                    description
                  }`
                : ''
            }
            ${
              fieldsPermissions.referenceSizes.totalPiCollor.view
                ? 'totalPiCollor'
                : ''
            }
            ${
              fieldsPermissions.referenceSizes.totalCiCollor.view
                ? 'totalCiCollor'
                : ''
            }
            ${
              fieldsPermissions.referenceSizes.remainCollorVar.view
                ? 'remainCollorVar'
                : ''
            }
            satReferenceSizeBarcodes {
              idSatReferenceSizeBarcode
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.size.view
                  ? `size2 {
                      description
                    }`
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.reference
                  .view
                  ? 'reference'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.unit.view
                  ? `unit2 {
                      description
                    }`
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes
                  .sellingPackage.view
                  ? 'sellingPackage'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes
                  .unitInnerBox.view
                  ? 'unitInnerBox'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.innerBox
                  .view
                  ? 'innerBox'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes
                  .unitMasterBox.view
                  ? 'unitMasterBox'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.masterBox
                  .view
                  ? 'masterBox'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.cotPiSize
                  .view
                  ? 'cotPiSize'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.sunPiSize
                  .view
                  ? 'sunPiSize'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.qtyCiSize
                  .view
                  ? 'qtyCiSize'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes
                  .remainQuantityPiShipment.view
                  ? 'remainQuantityPiShipment'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.cotCiSize
                  .view
                  ? 'cotCiSize'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.sunCiSize
                  .view
                  ? 'sunCiSize'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes
                  .remainTotCotSize.view
                  ? 'remainTotCotSize'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes
                  .remainTotSunSize.view
                  ? 'remainTotSunSize'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.sizeRange
                  .view
                  ? 'sizeRange'
                  : ''
              }
              ${
                fieldsPermissions.referenceSizes.referenceSizeBarcodes.qtyPiSize
                  .view
                  ? 'qtyPiSize'
                  : ''
              }
            }
          }
          ${fieldsPermissions.qtyOfCtnr.view ? 'qtyOfCtnr' : ''}
          ${fieldsPermissions.quantityInner.view ? 'quantityInner' : ''}
          ${fieldsPermissions.nve.view ? 'nve' : ''}
        }
      }
    }
  `;

    /**
     * Busca SAT Items
     */
    const [loadItemsData, { loading: satItemsLoading, data: satItemsData }] =
      useLazyQuery(listSatItemsBySatIdQuery, {
        variables: {
          data: {
            pagination: {
              _page: lazyParams.page + 1,
              _limit: lazyParams.rows,
              _orderBy: lazyParams.sortField,
              _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
            },
            idSat: sat.idSat,
            globalSearch: lazyParams.globalFilter,
            amount: lazyParams.amount,
            stCode: lazyParams.stCode,
            idSupplier: lazyParams.idSupplier,
            name: lazyParams.name,
            namePt: lazyParams.namePt,
            model: lazyParams.model,
            sku: lazyParams.sku,
            description: lazyParams.description,
            colour: lazyParams.colour,
            materialComposition: lazyParams.materialComposition,
            dimensions: lazyParams.dimensions,
            hsCode: lazyParams.hsCode,
            ncm: lazyParams.ncm,
            sunPrice: lazyParams.sunPrice,
            targetSun: lazyParams.targetSun,
            cotPrice: lazyParams.cotPrice,
            targetCot: lazyParams.targetCot,
            amountCot: lazyParams.amountCot,
            brand: lazyParams.brand,
            productLine: lazyParams.productLine,
            netWeight: lazyParams.netWeight,
            packageDescription: lazyParams.packageDescription,
            sellingPackagePcQuantity: lazyParams.sellingPackagePcQuantity,
            sellingPackageDimensions: lazyParams.sellingPackageDimensions,
            sellingPackageGrossWeight: lazyParams.sellingPackageGrossWeight,
            quantity: lazyParams.quantity,
            quantityShipped: lazyParams.quantityShipped,
            piecesContainer: lazyParams.piecesContainer,
            unit: lazyParams.unit,
            quantityContainer: lazyParams.quantityContainer,
            length: lazyParams.length,
            width: lazyParams.width,
            height: lazyParams.height,
            cbm: lazyParams.cbm,
            masterNetWeight: lazyParams.masterNetWeight,
            masterNetWeightTotal: lazyParams.masterNetWeightTotal,
            m2Pc: lazyParams.m2Pc,
            totalMasterM2: lazyParams.totalMasterM2,
            grossWeight: lazyParams.grossWeight,
            grossWeightTotal: lazyParams.grossWeightTotal,
            cbmTotal: lazyParams.cbmTotal,
            priceFob: lazyParams.priceFob,
            moq: lazyParams.moq,
            notes: lazyParams.notes,
            generalLi: lazyParams.generalLi,
            estimateProfit: lazyParams.estimateProfit,
            estimateMargin: lazyParams.estimateMargin,
            grossProfit: lazyParams.grossProfit,
            grossMargin: lazyParams.grossMargin,
            refCode: lazyParams.refCode,
            detailedDescriptionPt: lazyParams.detailedDescriptionPt,
            extraInfoEn: lazyParams.extraInfoEn,
            extraInfoPt: lazyParams.extraInfoPt,
            descriptionPt: lazyParams.descriptionPt,
            colourPt: lazyParams.colourPt,
            materialPt: lazyParams.materialPt,
            packageNotes: lazyParams.packageNotes,
            packageEan13: lazyParams.packageEan13,
            innerdun14: lazyParams.innerdun14,
            dun14: lazyParams.dun14,
            typeOfMaterial: lazyParams.typeOfMaterial,
            lining: lazyParams.lining,
            liningComposition: lazyParams.liningComposition,
            typePackage: lazyParams.typePackage,
            remainQuantity: lazyParams.remainQuantity,
            totalSqmCi: lazyParams.totalSqmCi,
            remainSqm: lazyParams.remainSqm,
            cotPriceSqm: lazyParams.cotPriceSqm,
            sunPriceSqm: lazyParams.sunPriceSqm,
            purchaseIncoterm: lazyParams.purchaseIncoterm,
            remainTotalCot: lazyParams.remainTotalCot,
            sellingIncoterm: lazyParams.sellingIncoterm,
            totalSunCi: lazyParams.totalSunCi,
            remainTotalSun: lazyParams.remainTotalSun,
            ncmDestaque: lazyParams.ncmDestaque,
            foreignTradeReview: lazyParams.foreignTradeReview,
            ncmIi: lazyParams.ncmIi,
            ncmIpi: lazyParams.ncmIpi,
            ncmPis: lazyParams.ncmPis,
            ncmCofins: lazyParams.ncmCofins,
            ncmDumping: lazyParams.ncmDumping,
            containerNumber: lazyParams.containerNumber,
            sample: lazyParams.sample,
            sampleLeadTime: lazyParams.sampleLeadTime,
            sampleNotes: lazyParams.sampleNotes,
            licensed: lazyParams.licensed,
            licensor: lazyParams.licensor,
            royalties: lazyParams.royalties,
            artworkEn: lazyParams.artworkEn,
            artworkPt: lazyParams.artworkPt,
            samplesApproved: lazyParams.samplesApproved,
            unitPerContainer: lazyParams.unitPerContainer,
            liningDescription: lazyParams.liningDescription,
            techniqueEn: lazyParams.techniqueEn,
            techniquePt: lazyParams.techniquePt,
            nve: lazyParams.nve,
          },
        },
        onCompleted: response => {
          if (response.listSatItemsBySatId) {
            satItemsDispatch({
              type: SatItemsReducerActionKind.SET_SAT_ITEMS,
              payload: {
                satItems: response.listSatItemsBySatId.data,
              },
            });
          }
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting SAT Items',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      });

    const [updateSatItemsMutation] = useMutation(updateSatItemsQuery);
    const [reorderSatItemMutation] = useMutation(reorderSatItemQuery);

    const satItemsRefetch = useCallback(() => {
      loadItemsData();
      totalDetailsRef.current?.loadItemsSumData();
    }, [loadItemsData]);

    useImperativeHandle(ref, () => ({
      handleUpdateItems: async () => {
        const nothingToUpdate =
          !satItemsState.satItemsToUpdate.length &&
          !satItemsState.referencesToUpdate.length &&
          !satItemsState.referenceSizeBarcodesToUpdate.length;

        if (nothingToUpdate) return true;

        try {
          const response = await updateSatItemsMutation({
            variables: {
              data: {
                satItems: satItemsState.satItemsToUpdate,
                satItemReferences: satItemsState.referencesToUpdate,
                satReferenceSizeBarcodes:
                  satItemsState.referenceSizeBarcodesToUpdate,
              },
            },
          });

          satItemsDispatch({
            type: SatItemsReducerActionKind.CLEAN_SAT_ITEMS_RELATED_DATA,
          });

          return response.data.updateSatItems;
        } catch (error) {
          return {
            errors: [error.message],
          };
        }
      },
      refreshItems() {
        satItemsRefetch();
      },
    }));

    useEffect(() => {
      if (!satItemsState.satItems.length && selected) {
        satItemsRefetch();
      }
    }, [
      loadItemsData,
      satItemsRefetch,
      satItemsState.satItems.length,
      selected,
    ]);

    // Mutation de exclusao de sat item
    const deleteSatItemQuery = gql`
      mutation DeleteSatItemMutation($idSatItem: [Int]!) {
        deleteSatItem(idSatItem: $idSatItem) {
          errors
        }
      }
    `;

    // cria método para chamar a mutation
    const [deleteSatItemMutation] = useMutation(deleteSatItemQuery);
    const [createClientQuotationMutation] = useMutation(
      createClientQuotationQuery,
    );

    const filteredColumnsHeader = useMemo(() => {
      return gridObjects.columnList.filter(field =>
        Object.keys(lazyParams).find(
          key => lazyParams[key] && key === field.advancedFilterField,
        ),
      );
    }, [gridObjects.columnList, lazyParams]);

    // Ordenacao de rows nao devera ficar disponivel caso usuario possua algum
    // filtro ou ordenacao por colunas ativa. Isso poderia causar bugs/inconsistencias
    const userCanReorderRow =
      satItemsState.satItems.length > 1 &&
      lazyParams.sortField === initialLazyParams.sortField &&
      !lazyParams.globalFilter &&
      !filteredColumnsHeader.length;

    /**
     * Retorna coluna com link para cadastro de Supplier
     * @param rowData Dados da Linha
     * @returns Coluna com link para cadastro de Supplier
     */
    const parseSunColumn = (rowData: any) => {
      if (!rowData) return undefined;

      return (
        rowData.idSatSupplier2 && (
          <Link
            to={`/suppliers/list/${rowData.idSatSupplier2?.idSupplier}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            {`SUN${rowData.idSatSupplier2?.idSupplier
              .toString()
              .padStart(5, '0')}`}
            <FiExternalLink size={15} />
          </Link>
        )
      );
    };

    /**
     * Retorna coluna com valor em moeda
     * @param rowData Dados da Linha
     * @param col Dados da coluna
     * @returns Coluna com valor em moeda
     */
    const parseCurrencyColumn = useCallback(
      (rowData: any, col: any) => {
        if (!rowData) return undefined;

        const isSunPriceOrCotPrice =
          col.field === 'sunPrice' || col.field === 'cotPrice';

        return (
          (rowData[col.field] || rowData[col.field] === 0) && (
            <p>{`${getCurrencySymbol(sat.idCurrency2?.abbreviation)} ${rowData[
              col.field
            ].toLocaleString('pt', {
              minimumFractionDigits: isSunPriceOrCotPrice ? 3 : 2,
              maximumFractionDigits: isSunPriceOrCotPrice ? 3 : 2,
            })}`}</p>
          )
        );
      },
      [sat.idCurrency2?.abbreviation],
    );

    /**
     * Retorna coluna STCode com Link para pagina de produtos
     * @param rowData Dados da linha
     * @returns Coluna ST Code com Link para pagina de produtos
     */
    const parseProductColumn = (rowData: any) => {
      if (!rowData) return undefined;

      return (
        rowData.stCode && (
          <Link
            to={`/products/list/${rowData.stCode.replace('ST', '')}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            {rowData.stCode}
            <FiExternalLink size={15} />
          </Link>
        )
      );
    };

    /**
     * Retorna componentes diferentes dependendo da coluna
     * @param field Coluna atual
     * @returns Respectivo componente
     */
    function handleColumn(field: string) {
      switch (field) {
        case 'idSatSupplier2.idSupplier':
          return parseSunColumn;
        case 'idImage2.url':
        case 'idColourImage2.url':
          return parseImageColumn;
        case 'stCode':
          return parseProductColumn;
        case 'sunPrice':
        case 'sunPriceSqm':
        case 'targetSun':
        case 'cotPrice':
        case 'cotPriceSqm':
        case 'targetCot':
        case 'amountCot':
        case 'loadingAmountCot':
        case 'priceFob':
        case 'amount':
        case 'estimateProfit':
        case 'totalCotCi':
        case 'totalSunCi':
        case 'remainTotalCot':
        case 'remainTotalSun':
        case 'grossProfit':
          return parseCurrencyColumn;
        case 'generalLi':
        case 'samplesApproved':
        case 'foreignTradeReview':
        case 'ncmDumping':
        case 'sample':
        case 'licensed':
          return parseNullableBooleanColumn;
        case 'artworkEn':
        case 'artworkPt':
        case 'techniqueEn':
        case 'techniquePt':
        case 'dimensions':
          return parseFormatedTextColumn;
        case 'estimateMargin':
        case 'grossMargin':
          return parsePercentageColumnPt;
        default:
          return undefined;
      }
    }

    /**
     * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
     * @param headerName Nome do header
     * @returns componente de header
     */
    function handleColumnHeader(headerName: string, tooltip?: string) {
      return (
        <span
          className="custom-header"
          data-pr-tooltip={tooltip}
          data-pr-position="right"
        >
          {headerName}
          {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
          {filteredColumnsHeader.find(
            filter => filter.header === headerName,
          ) ? (
            <i className="pi pi-filter" />
          ) : null}
        </span>
      );
    }

    /**
     * Define o tamanho de cada coluna de acordo com seu nome
     * @param column Nome da coluna
     * @returns estilo da coluna
     */
    function handleColumnSize(column: string) {
      switch (column) {
        case 'incoterm':
          return { width: '70px' };
        case 'productionTime':
          return { width: '100px' };
        case 'idSatSupplier2.idSupplier':
          return { width: '160px' };
        case 'packageEan13':
        case 'innerdun14':
        case 'dun14':
        case 'typeOfMaterial':
        case 'idTypePackage2.description':
        case 'totalSqmCi':
        case 'remainSqm':
        case 'cotPriceSqm':
        case 'sunPriceSqm':
        case 'idSellingIncoterm2.description':
        case 'totalSunCi':
        case 'sampleNotes':
        case 'artworkEn':
        case 'samplesApproved':
        case 'descriptionPt':
        case 'netWeight':
        case 'packageDescription':
        case 'quantityShipped':
        case 'quantityContainer':
        case 'masterNetWeight':
        case 'masterNetWeightTotal':
        case 'totalMasterM2':
        case 'grossWeightTotal':
        case 'generalLi':
        case 'estimateProfit':
        case 'estimateMargin':
        case 'techniqueEn':
        case 'techniquePt':
          return { width: '180px' };
        case 'detailedDescriptionPt':
        case 'extraInfoEn':
        case 'extraInfoPt':
          return { width: '200px', overflow: 'hidden' };
        case 'artworkPt':
        case 'remainTotalSun':
        case 'remainTotalCot':
        case 'remainQuantity':
        case 'lining':
        case 'liningDescription':
        case 'foreignTradeReview':
        case 'sampleLeadTime':
        case 'loadingAmountCot':
          return { width: '190px' };
        case 'idPurchaseIncoterm2.description':
        case 'containerNumber':
          return { width: '200px' };
        case 'sellingPackagePcQuantity':
        case 'stickerDimensionsPt':
        case 'centimeterDimensions':
          return { width: '220px' };
        case 'supplierInfo':
        case 'materialPt':
        case 'liningComposition':
        case 'sellingPackageDimensions':
        case 'dimensions':
          return { width: '240px' };
        case 'description':
        case 'materialComposition':
        case 'packageNotes':
        case 'sellingPackageGrossWeight':
          return { width: '250px', overflow: 'hidden' };
        default:
          return { width: '140px', overflow: 'hidden' };
      }
    }

    const calculateActualRowIndex = useCallback(
      (itemIndex: number) => {
        return itemIndex - lazyParams.rows * lazyParams.page;
      },
      [lazyParams.page, lazyParams.rows],
    );

    const onEditorValueChange = useCallback(
      (options, value) => {
        const itemIndex = calculateActualRowIndex(options.rowIndex);
        const updatedItem = options.props.value[itemIndex];
        updatedItem[options.field] = value;

        satItemsDispatch({
          type: SatItemsReducerActionKind.SET_SAT_ITEMS_TO_UPDATE,
          payload: {
            itemToUpdate: updatedItem,
          },
        });
      },
      [calculateActualRowIndex],
    );

    const validateResultToQuantityOfCtn = useCallback(
      (
        e: React.FocusEvent<HTMLInputElement>,
        piecesContainer?: number,
        stCode?: string,
      ) => {
        const parsedNumber = parseFloat(e.target.ariaValueNow ?? '');
        if (
          piecesContainer &&
          !Number.isNaN(parsedNumber) &&
          parsedNumber % piecesContainer !== 0
        ) {
          // Arredonda a divisao pra cima e para baixo, para encontrar os dois numeros mais proximos
          const roundUp = Math.ceil(parsedNumber / piecesContainer);
          const roundDown = Math.floor(parsedNumber / piecesContainer);

          const detailString = `${stCode} quantity value must be a multiple of ${piecesContainer}, like '${
            roundDown * piecesContainer
          }' or '${roundUp * piecesContainer}'`;

          toastRef.current?.show({
            severity: 'warn',
            summary: 'Quantity PI not compatible with PCS/CTN',
            detail: detailString,
            life: ToastLife.WARN,
          });
        }
      },
      [toastRef],
    );

    const radioEditor = useCallback(
      (props: any, field: string) => {
        return (
          <BooleanRadio
            onValueChange={e => {
              onEditorValueChange(props, !props.rowData[field]);
              e.originalEvent.stopPropagation();
            }}
            initialValue={props.rowData[field]}
          />
        );
      },
      [onEditorValueChange],
    );

    const handleGoToNextField = (
      e: React.KeyboardEvent<HTMLInputElement>,
      field: string,
      index: number,
    ) => {
      if (e.key === 'Enter') {
        document.getElementById(`${field}-${index + 1}`)?.focus();
      }
    };

    const numberEditor = useCallback(
      (props: any, field: string, mode?: string) => {
        return (
          <InputNumber
            inputId={`${field}-${props.rowIndex}`}
            value={props.rowData[field]}
            onChange={e => onEditorValueChange(props, e.value)}
            mode={mode}
            maxFractionDigits={mode ? 3 : undefined}
            onBlur={e =>
              field === 'quantity'
                ? validateResultToQuantityOfCtn(
                    e,
                    props.rowData.piecesContainer,
                    props.rowData.stCode,
                  )
                : undefined
            }
            onKeyDown={e => handleGoToNextField(e, field, props.rowIndex)}
          />
        );
      },
      [onEditorValueChange, validateResultToQuantityOfCtn],
    );

    const textAreaEditor = useCallback(
      (props: any, field: string) => {
        return (
          <InputTextarea
            rows={10}
            cols={20}
            defaultValue={props.rowData[field]}
            onChange={e => onEditorValueChange(props, e.target.value)}
            onClick={e => e.stopPropagation()}
          />
        );
      },
      [onEditorValueChange],
    );

    const handleEditor = useCallback(
      (props: any, column: string) => {
        const itemsStateIndex = calculateActualRowIndex(props.rowIndex);

        switch (true) {
          case column === 'targetCot' && fieldsPermissions.targetCot.edit:
          case column === 'cotPrice' && fieldsPermissions.cotPrice.edit:
          case column === 'sunPrice' && fieldsPermissions.sunPrice.edit:
          case column === 'targetSun' && fieldsPermissions.targetSun.edit:
            return numberEditor(props, column, 'decimal');
          case column === 'quantity' &&
            fieldsPermissions.quantity.edit &&
            !sat.indConfection:
            return numberEditor(props, column);
          case column === 'sample' && fieldsPermissions.sample.edit:
          case column === 'samplesApproved' &&
            fieldsPermissions.samplesApproved.edit:
            return radioEditor(props, column);
          case column === 'sampleNotes' && fieldsPermissions.sampleNotes.edit:
            return textAreaEditor(props, column);
          case column === 'idSatSupplier2.idSupplier':
            return parseSunColumn(props.rowData);
          case column === 'idImage2.url':
          case column === 'idColourImage2.url':
            return parseImageColumn(props.rowData, { field: column });
          case column === 'stCode':
            return parseProductColumn(props.rowData);
          case column === 'foreignTradeReview':
          case column === 'ncmDumping':
          case column === 'licensed':
            return parseNullableBooleanColumn(props.rowData, {
              columnKey: column,
            });
          case column === 'artworkEn':
          case column === 'artworkPt':
          case column === 'techniqueEn':
          case column === 'techniquePt':
            return parseFormatedTextColumn(props.rowData, {
              columnKey: column,
            });
          /**
           * Itens abaixo utilizam o estado de itens ao inves do valor passado
           * pela propria grid, pois o ultimo registro da grid nao era atualizado
           * com os valores modificados pelo reducer. Talvez algum bug no componente?
           */
          case column === 'masterNetWeightTotal':
            return satItemsState.satItems[itemsStateIndex]
              ?.masterNetWeightTotal;
          case column === 'quantityContainer':
            return satItemsState.satItems[itemsStateIndex]?.quantityContainer;
          case column === 'grossWeightTotal':
            return satItemsState.satItems[itemsStateIndex]?.grossWeightTotal;
          case column === 'amountCot':
          case column === 'amount':
          case column === 'estimateProfit':
          case column === 'grossProfit':
          case column === 'totalSunCi':
          case column === 'totalCotCi':
            return parseCurrencyColumn(
              satItemsState.satItems[itemsStateIndex],
              { field: column },
            );
          case column === 'estimateMargin':
          case column === 'grossMargin':
            return parsePercentageColumnPt(
              satItemsState.satItems[itemsStateIndex],
              { field: column },
            );
          default:
            return props.rowData[column];
        }
      },
      [
        calculateActualRowIndex,
        fieldsPermissions.cotPrice.edit,
        fieldsPermissions.quantity.edit,
        fieldsPermissions.sample.edit,
        fieldsPermissions.sampleNotes.edit,
        fieldsPermissions.samplesApproved.edit,
        fieldsPermissions.sunPrice.edit,
        fieldsPermissions.targetCot.edit,
        fieldsPermissions.targetSun.edit,
        numberEditor,
        parseCurrencyColumn,
        radioEditor,
        sat.indConfection,
        satItemsState.satItems,
        textAreaEditor,
      ],
    );

    /**
     * Reproduz as colunas selecionadas na configuracao
     */
    const dynamicColumns = selectedColumns.columns.map(col => {
      return (
        col.header &&
        col.field && (
          <Column
            key={col.field}
            columnKey={col.field}
            field={col.field}
            body={handleColumn(col.field)}
            // Valida necessidade de icone de filtro no header
            header={handleColumnHeader(col.header, col.tooltip)}
            style={handleColumnSize(col.field)}
            sortable={col.header !== 'Image' && col.header !== 'Colour Image'}
            editor={props => handleEditor(props, col.field)}
          />
        )
      );
    });

    /**
     * Ordenacao das colunas
     * @param event
     */
    const onColumnToggle = (event: MultiSelectGroupData) => {
      const orderedSelectedColumns = gridObjects.columnList.filter(col =>
        event.columns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
      );

      const finalObject = {
        columns: orderedSelectedColumns,
        treeSelectedData: event.treeSelectedData,
      };

      // Salva colunas selecionadas no local storage
      localStorage.setItem(gridColumnsName, JSON.stringify(finalObject));
      setSelectedColumns(finalObject);

      updateLocalStorageInDb(gridColumnsName, finalObject);
    };

    /**
     * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
     * @param event Parametros de sort da tabela
     */
    const onSort = (event: DataTableSortParams) => {
      const newLazyParams = {
        ...lazyParams,
        ...event,
        sortField: event.sortField ?? initialLazyParams.sortField,
      };
      changeLazyParams(newLazyParams);
    };

    /**
     * Ao mudar pagina da tabela, muda os parametros de busca no backend
     * @param event Parametros de paginacao da tabela
     */
    const onPage = useCallback(
      (event: DataTablePageParams) => {
        const newLazyParams = { ...lazyParams, ...event };
        changeLazyParams(newLazyParams);
      },
      [changeLazyParams, lazyParams],
    );

    /**
     * Deleta SAT Item
     * @param idSatItem ID do SAT Item
     */
    function handleDeleteItem(idSatItem: number) {
      confirmDialog({
        message: `Are you sure you want to delete this item from ${sat.satNumber}`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: async () => {
          setPageLoading(true);

          try {
            // Deleta Item
            const response = await deleteSatItemMutation({
              variables: {
                idSatItem,
              },
            });

            const { errors } = response.data?.deleteSatItem;

            if (errors.length) {
              toastRef.current?.show({
                severity: 'error',
                summary: 'Error while deleting SAT Item',
                detail: errors[0],
                life: ToastLife.ERROR,
              });
            } else {
              // Exibe toast de sucesso
              toastRef.current?.show({
                severity: 'success',
                summary: 'Deleted',
                detail: 'Item deleted',
                life: ToastLife.SUCCESS,
              });
            }

            loadItemsData();

            setShouldRefetchSatSuppliers(true);

            if (satItemsState.satItems.length === 1) {
              cleanSat();
            }

            await satRefetch();
          } catch (error) {
            // Exibe toast de erro
            toastRef.current?.show({
              severity: 'error',
              summary: 'Error while deleting SAT Item',
              detail: error.message,
              life: ToastLife.ERROR,
            });
          } finally {
            setPageLoading(false);
          }
        },
      });
    }

    /**
     * Deleta SAT Items selecionados
     */
    function handleDeleteSelected() {
      confirmDialog({
        message: `Are you sure you want to delete ${satItemsState.selectedSatItems.length} SAT Items?`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: async () => {
          setPageLoading(true);
          // Extrai apenas o ID de cada sat item
          const idsToDelete = satItemsState.selectedSatItems.map(
            (a: IListSatItem) => a.idSatItem,
          );
          try {
            // Deleta produtos
            const response = await deleteSatItemMutation({
              variables: {
                idSatItem: idsToDelete,
              },
            });

            if (response.data?.deleteSatItem?.errors.length) {
              setDeleteSatItemMutationResponse([
                { ...response.data?.deleteSatItem, moduleChanged: 'SAT Item' },
              ]);
              deleteItemsSummaryRef.current?.toggleDisplaySummary();
            } else {
              // Exibe toast de sucesso
              toastRef.current?.show({
                severity: 'success',
                summary: 'Deleted',
                detail: `You have deleted ${satItemsState.selectedSatItems.length} SAT Items`,
                life: ToastLife.SUCCESS,
              });
            }

            satItemsDispatch({
              type: SatItemsReducerActionKind.CLEAN_SAT_ITEMS_RELATED_DATA,
            });

            loadItemsData();

            setShouldRefetchSatSuppliers(true);

            if (
              satItemsState.satItems.length ===
              satItemsState.selectedSatItems.length
            ) {
              cleanSat();
            }

            await satRefetch();
          } catch (error) {
            // Exibe toast de erro
            toastRef.current?.show({
              severity: 'error',
              summary: 'Error while deleting SAT Items',
              detail: error.message,
              life: ToastLife.ERROR,
            });
          } finally {
            setPageLoading(false);
          }
        },
      });
    }

    function handleActionsBody(rowData: IListSatItem) {
      return (
        <GridActions>
          {permissions.userCanRefreshSatItems && (
            <button
              type="button"
              onClick={() => {
                satItemsDispatch({
                  type: SatItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_SAT_ITEM_SELECT_FIELDS,
                  payload: { selectedSatItems: [rowData] },
                });
              }}
            >
              <IoRefresh size={20} />
            </button>
          )}
          <button
            type="button"
            className="trash-button"
            onClick={() => handleDeleteItem(rowData.idSatItem)}
          >
            <FiTrash2 size={20} />
          </button>
        </GridActions>
      );
    }

    /**
     * Footer da modal de create sat item
     * @returns Footer
     */
    const addSatItemDialogFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button
            label="Save"
            onClick={() => addItemsRef.current?.handleAddItems()}
          />
          <Button
            label="Cancel"
            onClick={() => {
              satItemsDispatch({
                type: SatItemsReducerActionKind.CHANGE_ADD_SAT_ITEM,
              });
            }}
            className="p-button-danger"
          />
        </div>
      );
    };

    /**
     * Cria Client Quotation
     */
    function handleCreateClientQuotation() {
      confirmDialog({
        message: `You have selected (${satItemsState.selectedSatItems.length}) records. Confirm create CQ?`,
        header: 'Create CQ confirmation',
        icon: 'pi pi-info-circle',
        accept: async () => {
          setPageLoading(true);
          // Extrai apenas o ID de cada SAT Item
          const idsToCreate = satItemsState.selectedSatItems.map(
            (a: IListSatItem) => a.idSatItem,
          );
          try {
            // Cria Client Quotation
            const response = await createClientQuotation(
              createClientQuotationMutation,
              undefined,
              idsToCreate,
            );

            // Exibe toast de sucesso
            toastRef.current?.show({
              severity: 'success',
              summary: 'CQ Created',
              detail: (
                <>
                  <p>SAT Items added in the new CQ: </p>
                  <Link
                    to={`/client-quotations/list/${response.data.createClientQuotation.idCq}`}
                    className="transparent-link"
                  >
                    {response.data.createClientQuotation.cqNumber}
                  </Link>
                </>
              ),
              life: ToastLife.SUCCESS,
            });

            satItemsDispatch({
              type: SatItemsReducerActionKind.SET_SELECTED_SAT_ITEMS,
              payload: { selectedSatItems: [] },
            });
          } catch (error) {
            // Exibe toast de erro
            toastRef.current?.show({
              severity: 'error',
              summary: 'Error while creating CQ',
              detail: error.message,
              life: ToastLife.ERROR,
            });
          } finally {
            setPageLoading(false);
          }
        },
      });
    }

    const rowExpansionTemplate = useCallback(
      (data: IListSatItem) => {
        return (
          <ReferenceSizes
            idTypePackage={data.idTypePackage}
            satItemReferences={data.satItemReferences ?? []}
            referenceSizesPermissions={fieldsPermissions.referenceSizes}
            satItemsDispatch={satItemsDispatch}
            idSatItem={data.idSatItem}
            editable={!!satItemsState.editingRows.length}
            satCurrencyAbbreviation={sat.idCurrency2?.abbreviation}
          />
        );
      },
      [
        fieldsPermissions.referenceSizes,
        sat.idCurrency2?.abbreviation,
        satItemsState.editingRows.length,
      ],
    );

    /**
     * Gerencia se expander deve ser exibido
     */
    const handleExpander = useCallback(
      (data: IListSatItem, rowData: any) => {
        // Se produto nao for fashion, nao exibe expander
        if (!userCanSeeFashionLine || !data.satItemReferences?.length) {
          return undefined;
        }

        // Retorna body do expander
        return rowData.expander.element;
      },
      [userCanSeeFashionLine],
    );

    const onRowEditChange = () => {
      // Limpa o estado de itens em edicao da grid, para evitar que itens
      // com valores antigos aparecam ao usuario
      const gridCurrentState = gridRef.current?.state;
      gridRef.current?.setState({
        ...gridCurrentState,
        editingMeta: undefined,
      });

      satItemsDispatch({
        type: SatItemsReducerActionKind.SET_EDITING_ROWS,
        payload: {
          editingRows: satItemsState.satItems,
        },
      });
    };

    /**
     * Header da modal de refresh sat item select items
     * @returns Header
     */
    const refreshSatItemSelectFieldsHeader = () => {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p className="p-dialog-title">Refresh select fields</p>
          <Button
            label={
              satItemsState.allFieldsSelected ? 'Deselect All' : 'Select All'
            }
            onClick={() =>
              refreshItemsSelectFieldsRef.current?.handleSelectAll()
            }
            className={
              satItemsState.allFieldsSelected ? 'p-button-danger' : undefined
            }
          />
        </div>
      );
    };

    /**
     * Footer da modal de refresh SAT item select fields
     * @returns Footer
     */
    const refreshSatItemSelectFieldsFooter = () => {
      return (
        <div style={{ display: 'flex', placeContent: 'end' }}>
          <Button
            label="Continue"
            onClick={() => {
              refreshItemsSelectFieldsRef.current?.submitForm();
            }}
          />
          <Button
            label="Cancel"
            onClick={() => {
              satItemsDispatch({
                type: SatItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_SAT_ITEM_SELECT_FIELDS,
              });
            }}
            className="p-button-danger"
          />
        </div>
      );
    };

    /**
     * Footer da modal de refresh SAT item select items
     * @returns Footer
     */
    const refreshSatItemSelectItemsFooter = () => {
      return (
        <div
          style={{ display: 'flex', placeContent: 'end', marginTop: '12px' }}
        >
          <MainButton
            label="Confirm"
            onClick={() =>
              refreshItemsSelectItemsRef.current?.handleRefreshSelectedItems()
            }
          />
          <Button
            label="Cancel"
            onClick={() => {
              satItemsDispatch({
                type: SatItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_SAT_ITEM_SELECT_ITEMS,
              });
            }}
            className="p-button-danger"
          />
          <Button
            label="Back"
            onClick={() =>
              satItemsDispatch({
                type: SatItemsReducerActionKind.CHANGE_FROM_SELECT_ITEMS_TO_SELECT_FIELDS,
              })
            }
            className="p-button-danger"
          />
        </div>
      );
    };

    const onRowReorder = (e: DataTableRowReorderParams) => {
      satItemsDispatch({
        type: SatItemsReducerActionKind.SET_SAT_ITEMS,
        payload: {
          satItems: e.value,
        },
      });

      // Componente do primeReact acaba colocando um valor a mais no dropIndex
      // quando a linha eh ordenada para baixo. Essa logica remove 1 do dropIndex
      // nesse caso.
      const dropIndex =
        e.dropIndex < e.dragIndex ? e.dropIndex : e.dropIndex - 1;

      // Como a grid pode estar paginada, eh importante que a quantidade paginada
      // tambem seja informada ao backend
      const paginatedOrder = lazyParams.page * lazyParams.first + dropIndex;

      reorderSatItemMutation({
        variables: {
          data: {
            idSatItem: e.value[dropIndex].idSatItem,
            newOrder: paginatedOrder,
          },
        },
      });
    };

    useEffect(() => {
      // Busca preferencias de exibicao de colunas do usuario
      const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

      // Se encontrou, salva as colunas no estado
      if (localStorageSelectedColumns) {
        // Validacao de objeto de colunas no formato 'legado', sem grupos de colunas
        const columnsObject = JSON.parse(localStorageSelectedColumns);
        const allowedColumns = filterOnlyAllowedColumnsFromLocalStorage(
          columnsObject.columns,
          gridObjects.columnList,
        );

        if (columnsObject.treeSelectedData === undefined) {
          const newGroupedColumnsObject = {
            columns: allowedColumns,
            treeSelectedData: {
              showFields: {
                checked: false,
                partialChecked: true,
              },
            },
          };

          setSelectedColumns(newGroupedColumnsObject);
          localStorage.setItem(
            gridColumnsName,
            JSON.stringify(newGroupedColumnsObject),
          );
          updateLocalStorageInDb(gridColumnsName, newGroupedColumnsObject);
        } else {
          setSelectedColumns({ ...columnsObject, columns: allowedColumns });
        }
      }
    }, [gridObjects.columnList]);

    // Ao pesquisar no filtro global
    useEffect(() => {
      // Valida se componente esta montado
      if (isMounted.current) {
        // Define delay na busca para nao bater no backend a cada tecla digitada
        const delayDebounceFn = setTimeout(() => {
          const newLazyParams = { ...lazyParams };
          newLazyParams.first = 0;
          newLazyParams.page = 0;
          newLazyParams.globalFilter = globalFilter;
          changeLazyParams(newLazyParams);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      }
      // Define que componente esta montado
      isMounted.current = true;
      return undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilter]);

    /**
     * Controla exibicao da modal para atualizar itens de acordo com a response de selecao de fields
     */
    useEffect(() => {
      if (refreshSelectedFieldsResponse.length) {
        satItemsDispatch({
          type: SatItemsReducerActionKind.CHANGE_FROM_SELECT_FIELDS_TO_SELECT_ITEMS,
        });
      }
    }, [refreshSelectedFieldsResponse]);

    const [hasFilterApplied, setHasFilterApplied] = useState(false);

    useEffect(() => {
      const filtersApplied = Object.entries(lazyParams).filter(
        ([key, value]) => {
          const isKeyAColumn = Object.values(gridObjects.columnList).some(
            column => column.advancedFilterField === key,
          );
          return isKeyAColumn && value;
        },
      );
      setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
    }, [gridObjects.columnList, lazyParams]);

    return (
      <div>
        <Tooltip
          target=".custom-header"
          mouseTrack
          mouseTrackLeft={10}
          showDelay={100}
        />

        <Dialog
          header="Add SAT Item"
          visible={satItemsState.displayAddSatItemModal}
          onHide={() =>
            satItemsDispatch({
              type: SatItemsReducerActionKind.CHANGE_ADD_SAT_ITEM,
            })
          }
          closable={false}
          footer={addSatItemDialogFooter()}
          style={{ width: '80%', maxWidth: '1670px' }}
        >
          <AddSatItem
            ref={addItemsRef}
            setPageLoading={setPageLoading}
            idSat={sat.idSat}
            satItemsRefetch={satItemsRefetch}
            satItemsDispatch={satItemsDispatch}
            setShouldRefetchSatSuppliers={setShouldRefetchSatSuppliers}
            addItemsSummaryRef={addItemsSummaryRef}
            setAddSatItemMutationResponse={setAddSatItemMutationResponse}
            satItemsState={satItemsState}
            satRefetch={satRefetch}
            cleanSat={cleanSat}
            updateSatVersionLock={updateSatVersionLock}
          />
        </Dialog>

        <Dialog
          header={refreshSatItemSelectFieldsHeader()}
          visible={satItemsState.displayRefreshItemSelectFieldsModal}
          onHide={() =>
            satItemsDispatch({
              type: SatItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_SAT_ITEM_SELECT_FIELDS,
            })
          }
          closable={false}
          footer={refreshSatItemSelectFieldsFooter()}
        >
          <RefreshItemSelectFields
            ref={refreshItemsSelectFieldsRef}
            indConfection={sat.indConfection}
            allFieldsSelected={satItemsState.allFieldsSelected}
            modalsDispatch={satItemsDispatch}
            setRefreshSelectedFieldsResponse={setRefreshSelectedFieldsResponse}
            selectedSatItems={satItemsState.selectedSatItems}
            setItemsFieldsToUpdate={setItemsFieldsToUpdate}
            userFields={userFields}
          />
        </Dialog>
        <Dialog
          header="Update selected items"
          visible={satItemsState.displayRefreshItemSelectItemsModal}
          onHide={() =>
            satItemsDispatch({
              type: SatItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_SAT_ITEM_SELECT_ITEMS,
            })
          }
          closable={false}
          footer={refreshSatItemSelectItemsFooter()}
          style={{ maxWidth: '80%' }}
        >
          <RefreshItemSelectItems
            ref={refreshItemsSelectItemsRef}
            refreshSelectedFieldsResponse={refreshSelectedFieldsResponse}
            itemsFieldsToUpdate={itemsFieldsToUpdate}
            satItemsRefetch={satItemsRefetch}
            satItemsDispatch={satItemsDispatch}
            setShouldRefetchSatSuppliers={setShouldRefetchSatSuppliers}
            foreignTradeRef={foreignTradeRef}
          />
        </Dialog>

        <MutationResponseSummary
          ref={deleteItemsSummaryRef}
          header="Delete SAT Item Summary"
          data={deleteSatItemMutationResponse}
        />

        <MutationResponseSummary
          ref={addItemsSummaryRef}
          header="Add SAT Item Summary"
          data={addSatItemMutationResponse}
        />

        <Container>
          <GridHeader>
            <div className="grid-header-buttons">
              {permissions.userCanAddSatItem && (
                <Button
                  label="Add SAT Item"
                  onClick={() =>
                    satItemsDispatch({
                      type: SatItemsReducerActionKind.CHANGE_ADD_SAT_ITEM,
                    })
                  }
                  disabled={isSatCancelled}
                />
              )}

              {permissions.userCanRefreshSatItems && (
                <Button
                  label="Refresh selected items"
                  onClick={() =>
                    satItemsDispatch({
                      type: SatItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_SAT_ITEM_SELECT_FIELDS,
                    })
                  }
                  disabled={
                    !satItemsState.selectedSatItems.length || isSatCancelled
                  }
                />
              )}

              {permissions.userCanEditSatItem && (
                <MainButton
                  label="Edit"
                  icon="pi pi-pencil"
                  onClick={() => onRowEditChange()}
                  disabled={!satItemsState.satItems.length || isSatCancelled}
                />
              )}

              {permissions.userCanCreateClientQuotation && (
                <Button
                  label="Create CQ"
                  onClick={() => handleCreateClientQuotation()}
                  disabled={!satItemsState.selectedSatItems.length}
                />
              )}
            </div>
            <div className="grid-filters">
              {/* Botao para excluir produtos selecionados */}
              <Button
                label="Delete selected"
                className="p-button-danger"
                severity="danger"
                disabled={
                  !satItemsState.selectedSatItems.length || isSatCancelled
                }
                onClick={handleDeleteSelected}
              />

              {/* Busca global */}
              <InputText
                className="gridSearch"
                type="search"
                value={globalFilter}
                onChange={e => setGlobalFilter(e.target.value)}
                placeholder="Search an Item"
              />
              {/* Advanced Filters */}
              <Button
                className="advanced-filters-button"
                label="Advanced Filters"
                onClick={e => {
                  setShowAppliedFiltersOnly(false);
                  advancedFiltersPanelRef.current?.toggle(e, e.target);
                }}
              />
              <Button
                className="applied-filters-button"
                icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
                onClick={e => {
                  setShowAppliedFiltersOnly(true);
                  advancedFiltersPanelRef.current?.toggle(e, e.target);
                }}
                disabled={!hasFilterApplied}
              />

              {/* Painel com todos os filtros */}
              <AdvancedFiltersPanel
                innerRef={advancedFiltersPanelRef}
                fields={gridObjects.columnList}
                advancedFiltersName={advancedFiltersName}
                appliedFiltersOnly={showAppliedFiltersOnly}
                onApply={e =>
                  changeLazyParams({
                    ...lazyParams,
                    ...e,
                    first: pagination.initialLazyParams.first,
                    page: pagination.initialLazyParams.page,
                    rows: pagination.initialLazyParams.rows,
                  })
                }
                onClear={() =>
                  changeLazyParams({
                    ...initialLazyParams,
                    globalFilter,
                  })
                }
              />

              {/* Multi select de colunas da grid */}

              <MultiSelectGroup
                gridRef={gridRef}
                className="grid-multiselect-panel"
                allColumns={gridObjects.columnList}
                groups={groupedColoumns}
                showFields
                onSelectionChange={e => onColumnToggle(e)}
                initialData={selectedColumns.treeSelectedData}
              />
            </div>
          </GridHeader>
          <Grid
            ref={gridRef}
            className="grid s-internal-datatable"
            name="satItems"
            lazy
            totalRecords={
              !satItemsData ? 0 : satItemsData?.listSatItemsBySatId?.items
            }
            value={satItemsState.satItems}
            globalFilter={globalFilter}
            emptyMessage="No SAT items found."
            reorderableColumns
            removableSort
            rows={lazyParams.rows}
            first={!satItemsData ? undefined : lazyParams.first}
            onSort={onSort}
            onPage={onPage}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            loading={satItemsLoading}
            scrollable
            scrollHeight={gridConstants.internalGridScrollHeight}
            editMode="row"
            selectionAutoFocus={false}
            editingRows={satItemsState.editingRows}
            onRowEditChange={onRowEditChange}
            selection={satItemsState.selectedSatItems}
            onSelectionChange={e =>
              satItemsDispatch({
                type: SatItemsReducerActionKind.SET_SELECTED_SAT_ITEMS,
                payload: { selectedSatItems: e.value },
              })
            }
            selectionMode="checkbox"
            expandedRows={expandedRows}
            onRowToggle={e => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            onRowReorder={onRowReorder}
          >
            {userCanReorderRow && (
              <Column
                rowReorder
                style={{ width: '3em' }}
                reorderable={false}
                key="reorderRow"
                columnKey="reorderRow"
              />
            )}
            <Column
              style={{ width: '4em' }}
              columnKey="expanderColumn"
              reorderable={false}
              body={handleExpander}
              expander
            />
            <Column
              columnKey="multiple"
              selectionMode="multiple"
              style={{ width: '3em' }}
              reorderable={false}
            />
            {!isSatCancelled && (
              <Column
                columnKey="actions"
                field="actions"
                header="Actions"
                body={handleActionsBody}
                style={{ width: '10em' }}
              />
            )}
            {dynamicColumns}
          </Grid>

          <TotalDetails
            ref={totalDetailsRef}
            idSat={sat.idSat}
            userFields={userFields}
            satCurrencyAbbreviation={sat.idCurrency2?.abbreviation}
          />
        </Container>
      </div>
    );
  },
);

export default ItemsGrid;
