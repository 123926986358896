/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-param-reassign: "error" */
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { gql, useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { FaTimes } from 'react-icons/fa';
import { RadioButton } from 'primereact/radiobutton';
import {
  Container,
  Row,
  SupplierPortItem,
  SupplierCategoryItem,
} from './styles';

import MainButton from '../../../../components/MainButton';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import FormInput from '../../../../components/FormInput';
import FormCheckbox from '../../../../components/FormCheckbox';
import FormTreeSelect from '../../../../components/FormTreeSelect';
import { ISupplier, ISupplierPort, ISupplierCategory } from '../interfaces';
import FormInputNumber from '../../../../components/FormInputNumber';
import { useRefHook } from '../../../../hooks/useRefHook';
import FormDropdown from '../../../../components/FormDropdown';
import { lazyParamsPaginationGrid } from '../../../../services/layoutsDefinitions';
import ILazyParams from '../../../../services/lazyParams';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import generateRandomString from '../../../../utils/generateRandomString';
import { supplierRoles } from '../../../../shared/roles/supplier';
import getFieldPermission from '../../../../utils/getFieldPermission';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import ToastLife from '../../../../shared/enums/toastLife';

interface IGeneralInformationProps extends PageTabContainerProps {
  avatar?: File;
  supplier: ISupplier;
  setSupplier: Dispatch<SetStateAction<ISupplier>>;
  roleEntityFields: IRoleEntityField[];
}

interface ICategory {
  key?: string | number;
  label: string;
  data: {
    name: string;
    idCategory: number;
    idParentCategory?: number;
    idCheckList?: number;
  };
  children: ICategory[];
}

const GeneralInformation: React.FC<IGeneralInformationProps> = ({
  selected,
  supplier,
  setSupplier,
  roleEntityFields,
}) => {
  const clientApollo = useApolloClient();

  // Referencia ao toast
  const { toastRef, formRef } = useRefHook();

  // ID do pais selecionado
  const [selectedCountryId, setSelectedCountryId] = useState<any>();

  // ID da regiao selecionada
  const [selectedRegionId, setSelectedRegionId] = useState<any>();

  // ID do factory country selecionado
  const [selectedCountryFactoryId, setSelectedCountryFactoryId] =
    useState<any>();

  // ID da Factory regiao selecionada
  const [selectedRegionFactoryId, setSelectedRegionFactoryId] = useState<any>();

  // Parametros de paginacao/backend
  const [lazyParams] = useState<ILazyParams>(lazyParamsPaginationGrid);

  // Categories
  const [categories, setCategories] = useState<ICategory[]>([]);

  const loadPorts: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await clientApollo.query({
      query: gql`
        query listAllPortsByCountryQuery(
          $listAllPortsInput: ListAllPortsInput!
        ) {
          listAllPortsByCountry(listAllPortsInput: $listAllPortsInput) {
            data {
              idPort
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllPortsInput: {
          _page: 1,
          _limit: 100,
          _orderBy: 'name',
          _sortOrder: 'DESC',
          idCountry: selectedCountryId,
        },
      },
    });
    // Opcoes filtradas
    const filteredOptions = res.data.listAllPortsByCountry.data;
    // Quantidade de itens
    const quantity = res.data.listAllPortsByCountry.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  const loadOptions: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await clientApollo.query({
      query: gql`
        query listAllCurrenciesQuery($data: ListAllCurrenciesInput!) {
          listAllCurrencies(data: $data) {
            data {
              idCurrency
              currency
              abbreviation
            }
            items
          }
        }
      `,
      variables: {
        data: {
          pagination: {
            _page: pageData?.page,
            _limit: 25,
            _orderBy: 'currency',
            _sortOrder: 'DESC',
          },
          globalSearch: search,
        },
      },
    });

    const filteredOptions = res.data.listAllCurrencies.data;
    const quantity = res.data.listAllCurrencies.items;

    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  // Query de listagem de paises
  const listAllCountriesQuery = gql`
    query ListAllCountriesQuery {
      listAllCountries {
        idCountry
        name
      }
    }
  `;

  // Query para listar regioes
  const listRegionsQuery = gql`
    query ListRegionsByCountryIdQuery($id: Int!) {
      listRegionsByCountryId(id: $id) {
        idRegion
        name
      }
    }
  `;

  // Query para listar cidades
  const listCitiesQuery = gql`
    query ListCitiesByRegionIdQuery($id: Int!) {
      listCitiesByRegionId(id: $id) {
        idCity
        name
      }
    }
  `;

  // Query para listar domains
  const listDomainQuery = gql`
    query ListDomainsByGroupIdQuery(
      $id: Int!
      $pagination: Pagination!
      $description: String
    ) {
      listDomainsByGroupId(
        id: $id
        pagination: $pagination
        description: $description
      ) {
        data {
          idDomain
          description
        }
        items
      }
    }
  `;

  /**
   * Busca Incoterm
   */
  const [
    loadIncoterm,
    {
      called: incotermCalled,
      loading: incotermLoading,
      data: incotermData,
      error: incotermError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 4,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting incoterm',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Load paymentLoading
   */
  const [
    loadPayment,
    {
      called: paymentCalled,
      loading: paymentLoading,
      data: paymentData,
      error: paymentError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 13,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting payment',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca Paises
   */
  const {
    loading: countriesLoading,
    data: countriesData,
    error: countriesError,
  } = useQuery(listAllCountriesQuery, {
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting countries',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca regioes
   */
  const [
    loadRegionsFactory,
    {
      called: regionsFactoryCalled,
      loading: regionsFactoryLoading,
      data: regionsFactoryData,
      error: regionsFactoryError,
    },
  ] = useLazyQuery(listRegionsQuery, {
    variables: {
      id: selectedCountryFactoryId,
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting regions',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca regioes
   */
  const [
    loadRegions,
    {
      called: regionsCalled,
      loading: regionsLoading,
      data: regionsData,
      error: regionsError,
    },
  ] = useLazyQuery(listRegionsQuery, {
    variables: {
      id: selectedCountryId,
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting regions',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca cidades
   */
  const [
    loadCities,
    {
      called: citiesCalled,
      loading: citiesLoading,
      data: citiesData,
      error: citiesError,
    },
  ] = useLazyQuery(listCitiesQuery, {
    variables: {
      id: selectedRegionId,
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting cities',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca cidades
   */
  const [
    loadCitiesFactory,
    {
      called: citiesFactoryCalled,
      loading: citiesFactoryLoading,
      data: citiesFactoryData,
      error: citiesFactoryError,
    },
  ] = useLazyQuery(listCitiesQuery, {
    variables: {
      id: selectedRegionFactoryId,
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting cities',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca correios
   */
  const [
    loadCouriers,
    {
      called: couriersCalled,
      loading: couriersLoading,
      data: couriersData,
      error: couriersError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 3,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting couriers',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  function onSelectCountryFactory(country: Record<string, unknown>) {
    setSelectedCountryFactoryId(country.idCountry);
    formRef.current?.setFieldValue('idFactory2.idRegion2', undefined);
    loadRegionsFactory();
    formRef.current?.setFieldValue('idFactory2.idCity2', undefined);
  }

  function onSelectCountry(country: Record<string, unknown>) {
    if (supplier.supplierPorts && supplier.supplierPorts.length > 0) {
      // Exibe toast sobre o erro
      toastRef.current?.show({
        severity: 'warn',
        summary: 'Port/Airport of Loading',
        detail: `Verify Port/Airport of Loading.`,
        life: ToastLife.WARN,
      });
    }

    supplier.supplierPorts = [];
    setSupplier({
      ...supplier,
    });
    setSelectedCountryId(country.idCountry);
    formRef.current?.setFieldValue('region', undefined);
    loadRegions();
    formRef.current?.setFieldValue('cityName', undefined);
  }

  function onSelectRegion(region: Record<string, unknown>) {
    formRef.current?.setFieldValue('cityName', undefined);
    if (region) {
      setSelectedRegionId(region.idRegion);
      loadCities();
    }
  }

  function onSelectRegionFactory(region: Record<string, unknown>) {
    formRef.current?.setFieldValue('idFactory2.idCity2', undefined);
    if (region) {
      setSelectedRegionFactoryId(region.idRegion);
      loadCitiesFactory();
    }
  }

  const changePropertyValue = (property: string, value: any) => {
    if (!value) {
      supplier.supplierPorts = [];
    }

    setSupplier({
      ...supplier,
      [property]: value,
    });
  };

  useEffect(() => {
    if (supplier.idSupplier && supplier.idCountry2) {
      setSelectedCountryId(supplier.idCountry2.idCountry);
      loadRegions();
      if (supplier.idRegion) {
        setSelectedRegionId(supplier.idRegion2.idRegion);
        loadCities();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadRegions, loadCities]);

  useEffect(() => {
    if (supplier.idSupplier && supplier.idFactoryCountry) {
      setSelectedCountryFactoryId(supplier.idFactoryCountry2?.idCountry);
      loadRegionsFactory();
      if (supplier.idFactoryRegion) {
        setSelectedRegionFactoryId(supplier.idFactoryRegion2?.idRegion);
        loadCitiesFactory();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadRegionsFactory, loadCitiesFactory]);

  useEffect(() => {
    loadCouriers();
    loadIncoterm();
    loadPayment();
  }, [loadCouriers, loadIncoterm, loadPayment]);

  function addSupplierCategory() {
    // Cria novo array com novo item
    let newSupplierCategory: ISupplierCategory[];
    if (supplier.supplierCategories) {
      newSupplierCategory = [
        ...supplier.supplierCategories,
        {
          idSupplierCategory: generateRandomString(4),
        } as ISupplierCategory,
      ];
    } else {
      newSupplierCategory = [
        {
          idSupplierCategory: generateRandomString(4),
        } as ISupplierCategory,
      ];
    }

    // Add objetc in array
    setSupplier({
      ...supplier,
      supplierCategories: newSupplierCategory,
    });
  }

  function removeSupplierCategory(index: number) {
    // Copy array
    const newSupplierCategory = [...supplier.supplierCategories];
    // Remove item of array
    newSupplierCategory.splice(index, 1);
    // Update array
    supplier.supplierCategories = newSupplierCategory;

    setSupplier({
      ...supplier,
    });
  }

  function addSupplierPort() {
    // Cria novo array com novo item
    let newSupplierPort: ISupplierPort[];
    if (supplier.supplierPorts) {
      newSupplierPort = [
        ...supplier.supplierPorts,
        {
          idSupplierPort: generateRandomString(4),
        } as ISupplierPort,
      ];
    } else {
      newSupplierPort = [
        {
          idSupplierPort: generateRandomString(4),
        } as ISupplierPort,
      ];
    }

    // Add objetc in array
    setSupplier({
      ...supplier,
      supplierPorts: newSupplierPort,
    });
  }

  function removeSupplierPort(index: number) {
    // Copy array
    const newSupplierPort = [...supplier.supplierPorts];
    // Remove item of array
    newSupplierPort.splice(index, 1);
    // Update array
    supplier.supplierPorts = newSupplierPort;

    setSupplier({
      ...supplier,
    });
  }

  // Query para listar categories
  const listCategoriesQuery = gql`
    query listAllCategoriesQuery {
      listAllCategories {
        data
        items
      }
    }
  `;

  /**
   * Busca Categories
   */
  useQuery(listCategoriesQuery, {
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting categories',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        setCategories(JSON.parse(response.listAllCategories.data));
      }
    },
  });

  function changeProperty(value?: number) {
    supplier.paymentAdvance = value;

    supplier.paymentBalance = 100 - (value ?? 0);

    setSupplier({
      ...supplier,
    });
  }

  const {
    idFieldExporter,
    idFieldIdCity,
    idFieldIdCourier,
    idFieldIdIncoterm,
    idFieldIdIncotermSecond,
    idFieldIdPayment,
    idFieldIdRegion,
    idFieldPaymentAdvance,
    idFieldPaymentBalance,
    idFieldWebsite,
    idFieldZipCode,
    idFieldManufacturer,
    idFieldFactoryAddress,
    idFieldFactoryName,
    idFieldFactoryTax,
    idFieldFactoryZipCode,
    idFieldIdFactoryCity,
    idFieldIdFactoryRegion,
    idFieldName,
    idFieldCompanyName,
    idFieldProductionTime,
    idFieldSamplesLeadTime,
    idFieldTax,
    idFieldIdCountry,
    idFieldAddress,
    idFieldActive,
  } = supplierRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  function disableField(idField: number): boolean {
    return supplier.idSupplier
      ? !getFieldPermission(idField, roleEntityFields).edit
      : !getFieldPermission(idField, roleEntityFields).create;
  }

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <h1>General Information</h1>
        <Row>
          <FormInput
            className="generalInput"
            name="idSupplier"
            label="SUN Number"
            readOnly
          />
          {showField(idFieldName) && (
            <FormInput
              className="generalInput"
              name="name"
              label="Name"
              required
              readOnly={disableField(idFieldName)}
            />
          )}
          {showField(idFieldCompanyName) && (
            <FormInput
              className="generalInput"
              name="companyName"
              label="Formal Company Name"
              required
              readOnly={disableField(idFieldCompanyName)}
            />
          )}
        </Row>
        <Row>
          {showField(idFieldProductionTime) && (
            <FormInputNumber
              className="generalInput"
              name="productionTime"
              type="tel"
              label="Production Time"
              required
              readOnly={disableField(idFieldProductionTime)}
            />
          )}
          {showField(idFieldTax) && (
            <FormInput
              className="generalInput"
              name="tax"
              label="Tax ID/CNPJ"
              readOnly={disableField(idFieldTax)}
            />
          )}
          {showField(idFieldWebsite) && (
            <FormInput
              className="generalInput"
              name="website"
              label="WebSite"
              readOnly={disableField(idFieldWebsite)}
            />
          )}
        </Row>
        <Row>
          {showField(idFieldSamplesLeadTime) && (
            <FormInputNumber
              className="generalInput"
              name="samplesLeadTime"
              label="Samples Lead Time"
              readOnly={disableField(idFieldSamplesLeadTime)}
            />
          )}
          {showField(idFieldIdIncoterm) && (
            <FormDropdown
              className="generalInput"
              name="idIncoterm"
              label="Incoterm"
              placeholder={incotermLoading ? 'Loading' : ''}
              options={
                incotermCalled && !incotermLoading && !incotermError
                  ? incotermData.listDomainsByGroupId.data
                  : undefined
              }
              optionLabel="description"
              initialValue={supplier.idIncoterm2}
              filter
              filterBy="description"
              showClear
              readOnly={disableField(idFieldIdIncoterm)}
            />
          )}
          {showField(idFieldIdIncotermSecond) && (
            <FormDropdown
              className="generalInput"
              name="idIncotermSecond"
              label="Second Incoterm"
              placeholder={incotermLoading ? 'Loading' : ''}
              options={
                incotermCalled && !incotermLoading && !incotermError
                  ? incotermData.listDomainsByGroupId.data
                  : undefined
              }
              optionLabel="description"
              initialValue={supplier.idIncotermSecond2}
              filter
              filterBy="description"
              showClear
              readOnly={disableField(idFieldIdIncotermSecond)}
            />
          )}
        </Row>
        <Row>
          {showField(idFieldPaymentAdvance) && (
            <FormInputNumber
              className="generalInput"
              name="paymentAdvance"
              min={0}
              max={100}
              decimalScale={0}
              onValueChange={e => changeProperty(e.floatValue)}
              label="Payment Term Advance"
              readOnly={disableField(idFieldPaymentAdvance)}
            />
          )}
          {showField(idFieldPaymentBalance) && (
            <FormInputNumber
              className="generalInput"
              name="paymentBalance"
              value={supplier.paymentBalance}
              min={0}
              max={100}
              readOnly
              label="Payment Term Balance"
            />
          )}
          {showField(idFieldIdPayment) && (
            <FormDropdown
              className="generalInput"
              name="idPayment"
              label="Payment Term Condition"
              placeholder={paymentLoading ? 'Loading' : ''}
              options={
                paymentCalled && !paymentLoading && !paymentError
                  ? paymentData.listDomainsByGroupId.data
                  : undefined
              }
              optionLabel="description"
              initialValue={supplier.idPayment2}
              filter
              filterBy="description"
              showClear
              readOnly={disableField(idFieldIdPayment)}
            />
          )}
        </Row>
        <Row>
          {/* Campo supplierCurrencies não está na tabela supplier */}
          <FormAsyncSelect
            className="generalInput"
            name="supplierCurrencies"
            label="Currencies"
            debounceTimeout={1000}
            loadOptions={loadOptions}
            isMulti
            closeMenuOnSelect={false}
            initialValue={() =>
              supplier.supplierCurrencies
                ? supplier.supplierCurrencies.map(supplierCurrency => {
                    return {
                      currency: supplierCurrency.idCurrency2.currency,
                      abbreviation: supplierCurrency.idCurrency2.abbreviation,
                      idCurrency: supplierCurrency.idCurrency2.idCurrency,
                    };
                  })
                : undefined
            }
            getOptionLabel={(option: any) =>
              `${option.abbreviation} - ${option.currency}`
            }
            getOptionValue={(option: any) => option.idCurrency}
            additional={{
              page: 1,
            }}
            noOptionsMessage={() => 'No currencies found'}
          />
          {showField(idFieldIdCourier) && (
            <FormDropdown
              className="generalInput"
              name="idCourier"
              label="Courier"
              placeholder={couriersLoading ? 'Loading' : ''}
              options={
                couriersCalled && !couriersLoading && !couriersError
                  ? couriersData.listDomainsByGroupId.data
                  : undefined
              }
              optionLabel="description"
              initialValue={supplier.idCourier2}
              filter
              filterBy="description"
              showClear
              readOnly={disableField(idFieldIdCourier)}
            />
          )}
          {showField(idFieldExporter) && (
            <FormCheckbox
              name="exporter"
              label="Exporter"
              initialValue={supplier ? supplier.exporter : true}
              readOnly={disableField(idFieldExporter)}
            />
          )}
          {showField(idFieldActive) && (
            <FormCheckbox
              name="active"
              label="Active"
              initialValue={supplier ? supplier.active : true}
              readOnly={disableField(idFieldActive)}
            />
          )}
        </Row>
        <div className="form-div-margin">
          <h1>Address Info</h1>
          <Row>
            {showField(idFieldIdCountry) && (
              <FormDropdown
                className="generalInput"
                name="country"
                label="Country"
                options={
                  countriesLoading || countriesError
                    ? undefined
                    : countriesData.listAllCountries
                }
                optionLabel="name"
                placeholder={countriesLoading ? 'Loading' : ''}
                initialValue={supplier.idCountry2}
                filter
                filterBy="name"
                onValueChange={onSelectCountry}
                required
                readOnly={
                  countriesLoading ||
                  countriesError !== undefined ||
                  disableField(idFieldIdCountry)
                }
              />
            )}
            {showField(idFieldIdRegion) && (
              <FormDropdown
                className="generalInput"
                name="region"
                label="State/Provincy"
                placeholder={regionsLoading ? 'Loading' : ''}
                options={
                  regionsCalled && !regionsLoading && !regionsError
                    ? regionsData.listRegionsByCountryId
                    : undefined
                }
                optionLabel="name"
                initialValue={supplier.idRegion2}
                filter
                filterBy="name"
                onValueChange={onSelectRegion}
                showClear
                readOnly={disableField(idFieldIdRegion)}
              />
            )}
            {showField(idFieldAddress) && (
              <FormInput
                className="generalInput"
                name="address"
                label="Address"
                required
                readOnly={disableField(idFieldAddress)}
              />
            )}
          </Row>

          <Row>
            {showField(idFieldIdCity) && (
              <FormDropdown
                className="generalInput"
                name="cityName"
                label="City"
                placeholder={citiesLoading ? 'Loading' : ''}
                options={
                  citiesCalled && !citiesLoading && !citiesError
                    ? citiesData.listCitiesByRegionId
                    : undefined
                }
                optionLabel="name"
                initialValue={supplier.idCity2}
                filter
                filterBy="name"
                showClear
                readOnly={disableField(idFieldIdCity)}
              />
            )}
            {showField(idFieldZipCode) && (
              <FormInput
                className="generalInput"
                name="zipCode"
                label="Zip Code"
                readOnly={disableField(idFieldZipCode)}
              />
            )}
          </Row>
        </div>

        <div className="form-div-margin">
          <h1>Categories</h1>
          {supplier.supplierCategories &&
            supplier.supplierCategories.map((supplierCategories, index) => {
              return (
                <SupplierCategoryItem
                  key={supplierCategories.idSupplierCategory}
                >
                  <div>
                    <FormTreeSelect
                      className="generalInput"
                      name={`supplierCategories[${index}].idCategory`}
                      label="Category"
                      options={categories}
                      filter
                      placeholder="Select a Category"
                      initialValue={supplierCategories.idCategory}
                    />
                  </div>
                  <div className="button-div">
                    <button
                      type="button"
                      onClick={() => removeSupplierCategory(index)}
                    >
                      <FaTimes size={18} />
                    </button>
                  </div>
                </SupplierCategoryItem>
              );
            })}

          <MainButton
            label="Add Category"
            onClick={() => addSupplierCategory()}
          />
        </div>

        <div className="form-div-margin">
          <h1>Port/Airport of Loading</h1>
          {supplier.supplierPorts &&
            supplier.supplierPorts.map((supplierPort, index) => {
              return (
                <SupplierPortItem key={supplierPort.idSupplierPort}>
                  <div>
                    <FormCheckbox
                      name={`supplierPorts[${index}].isPrimary`}
                      label="Default"
                      className="p-my-2 activeCheckbox"
                      initialValue={supplierPort.isPrimary}
                    />
                    <FormAsyncSelect
                      className="generalInput"
                      name={`supplierPorts[${index}].idPort`}
                      label="Port/Airport"
                      debounceTimeout={1000}
                      loadOptions={loadPorts}
                      initialValue={supplierPort.idPort2}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.idPort}
                      required
                      additional={{
                        page: 1,
                      }}
                      noOptionsMessage={() => 'No port/airport found'}
                    />
                  </div>
                  <div className="button-div">
                    <button
                      type="button"
                      onClick={() => removeSupplierPort(index)}
                    >
                      <FaTimes size={18} />
                    </button>
                  </div>
                </SupplierPortItem>
              );
            })}

          <MainButton
            label="Add Port/Airport of Loading"
            onClick={() => addSupplierPort()}
          />
        </div>
        <div className="form-div-margin">
          <h1>Manufacturer</h1>
          <div>
            <Row>
              {showField(idFieldManufacturer) && (
                <div className="radio-button-div generalInput">
                  <label>Manufacturer:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="manufacturer"
                      checked={supplier.manufacturer !== true}
                      onChange={() =>
                        changePropertyValue('manufacturer', false)
                      }
                    />
                    <label>Same as formal company name</label>
                    <RadioButton
                      inputId="no"
                      name="manufacturer"
                      checked={supplier.manufacturer === true}
                      onChange={() => changePropertyValue('manufacturer', true)}
                    />
                    <label>Other</label>
                  </div>
                </div>
              )}
            </Row>
          </div>
          {supplier.manufacturer && (
            <div>
              <Row>
                {showField(idFieldFactoryName) && (
                  <FormInput
                    className="generalInput"
                    name="factoryName"
                    label="Factory Name"
                    readOnly={disableField(idFieldFactoryName)}
                  />
                )}
                {showField(idFieldFactoryTax) && (
                  <FormInput
                    className="generalInput"
                    name="factoryTax"
                    label="Factory Tax ID"
                    readOnly={disableField(idFieldFactoryTax)}
                  />
                )}
              </Row>
              <Row>
                {showField(idFieldFactoryAddress) && (
                  <FormInput
                    className="generalInput"
                    name="factoryAddress"
                    label="Factory Address"
                    readOnly={disableField(idFieldFactoryAddress)}
                  />
                )}
                {showField(idFieldFactoryZipCode) && (
                  <FormInput
                    className="generalInput"
                    name="factoryZipCode"
                    label="Factory Zip Code"
                    readOnly={disableField(idFieldFactoryZipCode)}
                  />
                )}
              </Row>
              <Row>
                <FormDropdown
                  className="generalInput"
                  name="idFactoryCountry2"
                  label="Factory Country"
                  readOnly={countriesLoading || countriesError !== undefined}
                  options={
                    countriesLoading || countriesError
                      ? undefined
                      : countriesData.listAllCountries
                  }
                  optionLabel="name"
                  placeholder={countriesLoading ? 'Loading' : ''}
                  initialValue={supplier.idFactoryCountry2}
                  filter
                  filterBy="name"
                  onValueChange={onSelectCountryFactory}
                  required
                />
                {showField(idFieldIdFactoryRegion) && (
                  <FormDropdown
                    className="generalInput"
                    name="idFactoryRegion2"
                    label="Factory State/Provincy"
                    placeholder={regionsFactoryLoading ? 'Loading' : ''}
                    options={
                      regionsFactoryCalled &&
                      !regionsFactoryLoading &&
                      !regionsFactoryError
                        ? regionsFactoryData.listRegionsByCountryId
                        : undefined
                    }
                    optionLabel="name"
                    initialValue={supplier.idFactoryRegion2}
                    filter
                    filterBy="name"
                    onValueChange={onSelectRegionFactory}
                    showClear
                    readOnly={disableField(idFieldIdFactoryRegion)}
                  />
                )}
                {showField(idFieldIdFactoryCity) && (
                  <FormDropdown
                    className="generalInput"
                    name="idFactoryCity2"
                    label="Factory City"
                    placeholder={citiesFactoryLoading ? 'Loading' : ''}
                    options={
                      citiesFactoryCalled &&
                      !citiesFactoryLoading &&
                      !citiesFactoryError
                        ? citiesFactoryData.listCitiesByRegionId
                        : undefined
                    }
                    optionLabel="name"
                    initialValue={supplier.idFactoryCity2}
                    filter
                    filterBy="name"
                    showClear
                    readOnly={disableField(idFieldIdFactoryCity)}
                  />
                )}
              </Row>
            </div>
          )}
        </div>
      </Container>
    </PageTabContainer>
  );
};

export default GeneralInformation;
