import React, {
  Dispatch,
  Fragment,
  Ref,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { Checkbox } from 'primereact/checkbox';
import { gql, useMutation } from '@apollo/client';
import {
  DifferenceToRefreshClientQuotationItemsResponse,
  DifferenceToRefreshClientQuotationItemsResponseData,
  IRefreshClientQuotationItemOptions,
} from '../../interfaces';
import imagePlaceholder from '../../../../../assets/imagePlaceholder.svg';
import { Container } from './styles';
import { useRefHook } from '../../../../../hooks/useRefHook';
import ToastLife from '../../../../../shared/enums/toastLife';
import Loading from '../../../../../components/Loading';
import {
  ClientQuotationItemsReducerActionKind,
  IClientQuotationItemsReducerAction,
} from '../interfaces';

/**
 * Interface de Ref do componente de Refresh Item
 */
export interface IRefreshClientQuotationItemSelectItemsRef {
  /**
   * Funcao para atualizar itens selecionados
   */
  handleRefreshSelectedItems(): void;
}

/**
 * Props do componente de RefreshItemSelectItems
 */
interface IRefreshItemSelectItemsProps {
  /**
   * Resposta da busca pela diferenca entre cada field selecionado para refresh
   */
  refreshSelectedFieldsResponse: DifferenceToRefreshClientQuotationItemsResponse[];

  /**
   * Dados dos fields que devem ser atualizados
   */
  itemsFieldsToUpdate: IRefreshClientQuotationItemOptions | undefined;

  /**
   * Referencia do componente de Refresh Item
   */
  ref: Ref<IRefreshClientQuotationItemSelectItemsRef>;

  /**
   * Refetch de Client Quotation Items
   */
  clientQuotationItemsRefetch(): void;

  /**
   * Reducer de client quotation items
   */
  clientQuotationItemsDispatch: Dispatch<IClientQuotationItemsReducerAction>;
}

/**
 * Modal de refresh select items
 * @returns
 */
const RefreshItemSelectItems: React.FC<IRefreshItemSelectItemsProps> =
  React.forwardRef(
    (
      {
        refreshSelectedFieldsResponse,
        itemsFieldsToUpdate,
        clientQuotationItemsRefetch,
        clientQuotationItemsDispatch,
      },
      ref,
    ) => {
      // Estado de loading
      const [loading, setLoading] = useState(false);

      // Estado de itens selecionados
      const [selectedItems, setSelectedItems] = useState<number[]>([]);

      // Referencia ao toast
      const { toastRef } = useRefHook();

      /**
       * Query da mutation para refresh de itens
       */
      const refreshClientQuotationItemsQuery = gql`
        mutation CreateSession($data: RefreshClientQuotationItemsInput!) {
          refreshClientQuotationItems(data: $data)
        }
      `;

      // cria método para chamar a mutation
      const [refreshClientQuotationItemsMutation] = useMutation(
        refreshClientQuotationItemsQuery,
      );

      useImperativeHandle(
        ref,
        () => ({
          handleRefreshSelectedItems: async () => {
            // Valida se usuario selecionou algum item
            if (!selectedItems.length) {
              // Exibe toast de alerta
              toastRef.current?.show({
                severity: 'warn',
                summary: 'You need to select at least one item to refresh',
                life: ToastLife.WARN,
              });
              return;
            }

            try {
              setLoading(true);

              // Chama mutation para atualizar itens
              await refreshClientQuotationItemsMutation({
                variables: {
                  data: {
                    idsClientQuotationItems: selectedItems,
                    ...itemsFieldsToUpdate,
                  },
                },
              });

              // Exibe toast de sucesso
              toastRef.current?.show({
                severity: 'success',
                summary: 'Items refreshed',
                life: ToastLife.SUCCESS,
              });

              clientQuotationItemsDispatch({
                type: ClientQuotationItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_ITEMS,
                payload: {
                  selectedClientQuotationItems: [],
                },
              });

              clientQuotationItemsRefetch();
            } catch (error) {
              toastRef.current?.show({
                severity: 'error',
                summary: 'Error while refreshing CQ items',
                detail: error.message,
                life: ToastLife.ERROR,
              });
            } finally {
              setLoading(false);
            }
          },
        }),
        [
          clientQuotationItemsDispatch,
          clientQuotationItemsRefetch,
          itemsFieldsToUpdate,
          refreshClientQuotationItemsMutation,
          selectedItems,
          toastRef,
        ],
      );

      // Colunas selecionadas
      const [selectedColumns] = useState(
        refreshSelectedFieldsResponse[0].data.map(property => {
          return {
            field: property.property,
            header: property.label,
          };
        }),
      );

      /**
       * Lida com o evento de selecao dos itens
       */
      const handleCheckItem = useCallback(
        (checked: boolean, idItem: number) => {
          // Se um item foi selecionado, o adiciona no estado
          if (checked) {
            setSelectedItems([...selectedItems, idItem]);
          } else {
            // Se nao, o remove do estado
            const filteredItems = selectedItems.filter(id => id !== idItem);
            setSelectedItems(filteredItems);
          }
        },

        [selectedItems],
      );

      /**
       * Lida com o evento de selecao de todos os itens
       */
      const handleCheckAllItems = useCallback(
        (checked: boolean) => {
          // Se a selecao for true, adiciona todos os itens no array
          if (checked) {
            // Busca os IDs de cada item
            const itemsIds = refreshSelectedFieldsResponse.map(
              item => item.idClientQuotationItem,
            );
            setSelectedItems(itemsIds);
          } else {
            setSelectedItems([]);
          }
        },
        [refreshSelectedFieldsResponse],
      );

      /**
       *
       * @param itemData Dados do item
       * @param key Chave
       * @returns
       */
      const handleColumn = (
        itemData: DifferenceToRefreshClientQuotationItemsResponseData,
        key: React.Key,
      ) => {
        switch (itemData.property) {
          case 'image':
          case 'colourImage':
            return (
              <Fragment key={key}>
                <td>
                  <img
                    src={itemData.oldValue?.toString() ?? ''}
                    onError={e => {
                      e.currentTarget.src = imagePlaceholder;
                    }}
                    alt="Old Value"
                    style={{ width: '100%' }}
                  />
                </td>
                <td>
                  <img
                    src={itemData.newValue?.toString() ?? ''}
                    onError={e => {
                      e.currentTarget.src = imagePlaceholder;
                    }}
                    alt="New Value"
                    style={{ width: '100%' }}
                  />
                </td>
              </Fragment>
            );
          default:
            return (
              <Fragment key={key}>
                <td className={itemData.changed ? 'changed' : undefined}>
                  {itemData.oldValue}
                </td>
                <td className={itemData.changed ? 'changed' : undefined}>
                  {itemData.newValue}
                </td>
              </Fragment>
            );
        }
      };

      return (
        <Container>
          <table>
            <thead>
              <tr>
                <th className="sticky-col" rowSpan={3}>
                  <Checkbox
                    onChange={e => handleCheckAllItems(e.checked)}
                    checked={
                      refreshSelectedFieldsResponse.length ===
                      selectedItems.length
                    }
                  />
                  ST Code
                </th>
              </tr>
              <tr>
                {selectedColumns.map(col => {
                  return (
                    <th key={col.header} colSpan={2}>
                      {col.header}
                    </th>
                  );
                })}
              </tr>
              <tr>
                {selectedColumns.map(col => {
                  return (
                    <Fragment key={col.field}>
                      <th>Old Value</th>
                      <th>New Value</th>
                    </Fragment>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {refreshSelectedFieldsResponse.map(item => {
                return (
                  <tr key={item.idClientQuotationItem}>
                    <td className="sticky-col">
                      <Checkbox
                        onChange={e =>
                          handleCheckItem(e.checked, item.idClientQuotationItem)
                        }
                        checked={
                          !!selectedItems.find(
                            id => id === item.idClientQuotationItem,
                          )
                        }
                      />
                      {item.stCode}
                    </td>
                    {item.data.map(itemData => {
                      return handleColumn(
                        itemData,
                        itemData.property + item.idClientQuotationItem,
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {loading && <Loading />}
        </Container>
      );
    },
  );

export default RefreshItemSelectItems;
